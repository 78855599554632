<template v-cloak>
  <div class="select-project-input-wrapper">
    <div class="input-wrapper" @click="showSelect">
      <p class="info">已选项目：</p>
      <p class="value" ref="valueLength" v-if="checkList.length > 0">{{ inputValue }}</p>
      <p class="desc" v-else>请选择项目</p>
      <p class="text-leng" v-if="lengthShow">({{ checkList.length }})</p>
    </div>
    <selectProjectM
      :show.sync="selectProjectShow"
      @result="checkResult"
      ref="selectProjectM"
    ></selectProjectM>
  </div>
</template>

<script type='text/ecmascript-6'>
import selectProjectM from '@/components/selectProjectM'
import { setProjectInfoHandler } from '@/plugins/offlineMixin/offlineApiHandlers/SelectProjectOfflineApi.js'
export default {
  name: '',
  components: { selectProjectM },
  data () {
    return {
      selectProjectShow: false,
      checkList: []
    }
  },
  computed: {
    isOffline () {
      return this.$store.getters.getIsOffline
    },
    inputValue () {
      return this.checkList.reduce((value, item, index) => {
        if (index < this.checkList.length - 1) {
          value += item.deptName + '、'
        } else {
          value += item.deptName
        }
        return value
      }, '')
    },
    lengthShow () {
      return this.checkList.length > 1
    }
  },
  created () {},
  mounted () {
  },
  methods: {
    async showSelect () {
      if (this.isOffline) {
        this.selectProjectShow = true
      } else {
        this.$EB.$emit(this.$EBT.NO_NETWORK_WARN)
      }
    },
    async checkResult (projectInfo) {
      console.log('projectInfo: ', projectInfo)
      console.log('this.checkList: ', this.checkList)
      console.log('this.lodash.isEqual(this.checkList, projectInfo.checkList): ', this.lodash.isEqual(this.checkList, projectInfo.checkList))
      if (this.lodash.isEqual(this.checkList, projectInfo.checkList)) return
      this.checkList = projectInfo.checkList
      const projectCodes = projectInfo.checkList.map((item) => item.deptCode)
      this.$emit('selectProject', projectCodes)
      await setProjectInfoHandler({ // 首页项目选择后保存到手机
        params: projectInfo
      })
    }
  }
}
</script>
<style  lang='less' scoped>
.select-project-input-wrapper {
  margin: 8px 0;
  p {
    margin: 0;
  }
  .input-wrapper {
    display: flex;
    height: 48px;
    align-items: center;
    background-color: #fff;
    padding: 0 16px;
    font-size: 16px;
    font-weight: 500;
    color: #222222;
    line-height: 22px;
    .info {
      flex-shrink: 0;
      flex: 0 0 84px;
    }
    .text-leng {
      flex: 0 0 22px;
      flex-shrink: 0;
    }
    .value {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .desc {
      flex: 1;
      font-size: 16px;
      font-weight: 400;
      color: #8b8f9a;
    }
  }
}
</style>
