var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "orderDetail", staticClass: "order-detail" },
    [
      _c(
        "my-nav",
        {
          attrs: {
            title: _vm.$route.query.checkNo ? "任务抽查结果详情" : "工单详情",
            "self-back": true,
          },
          on: { back: _vm.goBack },
        },
        [
          _c(
            "div",
            {
              staticClass: "icon-wrapper",
              attrs: { slot: "right" },
              slot: "right",
            },
            [
              _vm.getOrderTypeConf.equipmentOrderTypes.includes(
                _vm.detail.order_type
              ) &&
              _vm.detail.order_nature !== 4 &&
              _vm.detail.order_nature !== 2 &&
              !_vm.detail.upgradeOrderNo &&
              _vm.detail.template_node_code === "chulizhong"
                ? _c("van-icon", {
                    attrs: { name: "upgrade" },
                    on: {
                      click: function ($event) {
                        return _vm.intoCirculation(_vm.upgradeButton)
                      },
                    },
                  })
                : _vm._e(),
              _vm.isOffline &&
              _vm.getOrderTypeConf.taskOrderTypes.includes(
                _vm.detail.order_type
              ) &&
              _vm.detail.template_node_code === "chulizhong"
                ? _c("micro-text", {
                    attrs: { ptt: true, black: true },
                    on: { speakRes: _vm.speakRes },
                  })
                : _vm._e(),
              _vm.getOrderTypeConf.taskOrderTypes.includes(
                _vm.detail.order_type
              ) &&
              !(_vm.detail.task_type === 3 || _vm.detail.task_type === 6) &&
              _vm.detail.template_node_code === "chulizhong"
                ? _c("span", {
                    staticClass: "icon-code",
                    on: { click: _vm.searchCode },
                  })
                : _vm._e(),
              _vm.detail.forwardFM &&
              (!_vm.detail.fmOrderNos || !_vm.detail.fmOrderNos.length)
                ? _c("div", {
                    staticClass: "icon-forwardfm",
                    on: { click: _vm.forwardFM },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _c(
        "van-tabs",
        {
          attrs: {
            animated: "",
            color: "#3366FE",
            sticky: "",
            "offset-top": "44",
            "title-active-color": "#3366FE",
            "title-inactive-color": "#7C7F8A",
            "line-height": "2px",
            "line-width": "187px",
          },
          model: {
            value: _vm.active,
            callback: function ($$v) {
              _vm.active = $$v
            },
            expression: "active",
          },
        },
        [
          _c(
            "van-tab",
            {
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "tab-class" }, [
                        _c("div", [_vm._v("工单信息")]),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c(
                "div",
                { staticClass: "scroll-detail-wrapper" },
                [
                  _c("div", { staticClass: "order-cell-title" }, [
                    _c(
                      "div",
                      { staticClass: "flex-box" },
                      [
                        _c("div", { staticClass: "order-number" }, [
                          _vm._v(_vm._s(_vm.detail.order_no)),
                        ]),
                        _c(
                          "van-tag",
                          {
                            staticClass: "copy-tag",
                            attrs: {
                              plain: "",
                              type: "primary",
                              color: "#999",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.copyOrderNumber(_vm.detail.order_no)
                              },
                            },
                          },
                          [_vm._v("复制")]
                        ),
                        _c("div", {
                          staticClass: "star",
                          class: { active: _vm.detail.isattention === true },
                          on: {
                            click: function ($event) {
                              return _vm.attention(_vm.detail)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "status color-blue" }, [
                      _vm._v(_vm._s(_vm.detail.node_name)),
                    ]),
                  ]),
                  _c(
                    "van-row",
                    { staticClass: "order-body-cell", attrs: { gutter: "10" } },
                    [
                      _c(
                        "van-col",
                        { staticClass: "sub-key", attrs: { span: 6 } },
                        [_vm._v("工单属性:")]
                      ),
                      _c(
                        "van-col",
                        { staticClass: "order-sub-flex", attrs: { span: 18 } },
                        [
                          _c(
                            "van-tag",
                            {
                              staticClass: "mr-8",
                              attrs: {
                                type: "success",
                                color:
                                  _vm.getOrderTypeConf.taskOrderTypes.includes(
                                    _vm.detail.order_type
                                  )
                                    ? ""
                                    : "#45A2F4",
                                size: "medium",
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.detail.order_type_name) + " "
                              ),
                            ]
                          ),
                          _c(
                            "van-tag",
                            {
                              staticClass: "mr-8",
                              style: `background:${_vm.orderNatureConf.tagBacColor}`,
                              attrs: {
                                plain: "",
                                type: "primary",
                                size: "medium",
                                color: _vm.orderNatureConf.tagColor,
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.detail.order_nature_name))]
                          ),
                          _vm.detail.grade
                            ? _c(
                                "van-tag",
                                {
                                  staticClass: "mr-8",
                                  attrs: {
                                    plain: "",
                                    type: "primary",
                                    size: "medium",
                                    color: JSON.parse(_vm.detail.grade).color,
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(JSON.parse(_vm.detail.grade).value)
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.detail.order_nature === 5
                    ? _c(
                        "van-row",
                        {
                          staticClass: "order-body-cell",
                          attrs: { gutter: "10" },
                        },
                        [
                          _c(
                            "van-col",
                            { staticClass: "sub-key", attrs: { span: 6 } },
                            [_vm._v("问题原因:")]
                          ),
                          _c(
                            "van-col",
                            { staticClass: "sub-value", attrs: { span: 18 } },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.detail.issueReasonName)),
                              ]),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.getOrderTypeConf.taskOrderTypes.includes(
                    _vm.detail.order_type
                  )
                    ? _c(
                        "van-row",
                        {
                          staticClass: "order-body-cell",
                          attrs: { gutter: "10" },
                        },
                        [
                          _c(
                            "van-col",
                            { staticClass: "sub-key", attrs: { span: 6 } },
                            [_vm._v("处理方式:")]
                          ),
                          _c(
                            "van-col",
                            { attrs: { span: 18 } },
                            [
                              _c(
                                "van-tag",
                                {
                                  staticClass: "mr-8",
                                  attrs: {
                                    size: "medium",
                                    color: "rgb(51 102 254 / 10%)",
                                    "text-color": "#3366FE",
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.detail.hander_type_name)),
                                  ]),
                                ]
                              ),
                              _vm.detail.in_order !== null
                                ? _c(
                                    "van-tag",
                                    {
                                      attrs: {
                                        color: "rgb(51 102 254 / 10%)",
                                        "text-color": "#3366FE",
                                        size: "medium",
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.detail.in_order === 1
                                              ? "有序"
                                              : "无序"
                                          )
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.getOrderTypeConf.normalOrderTypes.includes(
                    _vm.detail.order_type
                  )
                    ? _c(
                        "van-row",
                        {
                          staticClass: "order-body-cell",
                          attrs: { gutter: "10" },
                        },
                        [
                          _c(
                            "van-col",
                            { staticClass: "sub-key", attrs: { span: 6 } },
                            [_vm._v("创建人:")]
                          ),
                          _c(
                            "van-col",
                            {
                              staticClass: "flex-box sub-value",
                              attrs: { span: 18 },
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.detail.create_name)),
                              ]),
                              _vm.detail.create_phone
                                ? _c("span", { staticClass: "hpone-icon" })
                                : _vm._e(),
                              _vm.detail.create_phone
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "color-blue",
                                      on: {
                                        click: function ($event) {
                                          return _vm.takePhone(
                                            _vm.detail.create_phone
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.detail.create_phone))]
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "van-row",
                    { staticClass: "order-body-cell", attrs: { gutter: "10" } },
                    [
                      _c(
                        "van-col",
                        { staticClass: "sub-key", attrs: { span: 6 } },
                        [_vm._v("创建时间:")]
                      ),
                      _c(
                        "van-col",
                        { staticClass: "sub-value", attrs: { span: 18 } },
                        [_c("span", [_vm._v(_vm._s(_vm.detail.create_time))])]
                      ),
                    ],
                    1
                  ),
                  [
                    _c(
                      "van-row",
                      {
                        staticClass: "order-body-cell",
                        attrs: { gutter: "10" },
                      },
                      [
                        _c(
                          "van-col",
                          { staticClass: "sub-key", attrs: { span: 6 } },
                          [_vm._v("数据来源:")]
                        ),
                        _c(
                          "van-col",
                          { staticClass: "sub-value", attrs: { span: 18 } },
                          [_c("span", [_vm._v(_vm._s(_vm.detail.data_source))])]
                        ),
                      ],
                      1
                    ),
                  ],
                  _c(
                    "van-row",
                    { staticClass: "order-body-cell", attrs: { gutter: "10" } },
                    [
                      _c(
                        "van-col",
                        { staticClass: "sub-key", attrs: { span: 6 } },
                        [_vm._v("所在项目:")]
                      ),
                      _c(
                        "van-col",
                        { staticClass: "sub-value", attrs: { span: 18 } },
                        [_c("span", [_vm._v(_vm._s(_vm.detail.project_name))])]
                      ),
                    ],
                    1
                  ),
                  _vm.getOrderTypeConf.normalOrderTypes.includes(
                    _vm.detail.order_type
                  )
                    ? _c(
                        "van-row",
                        {
                          staticClass: "order-body-cell",
                          attrs: { gutter: "10" },
                        },
                        [
                          _c(
                            "van-col",
                            { staticClass: "sub-key", attrs: { span: 6 } },
                            [_vm._v("所在位置:")]
                          ),
                          _c(
                            "van-col",
                            {
                              staticClass: "sub-value",
                              attrs: { span: 18 },
                              on: {
                                click: function ($event) {
                                  return _vm.goDetail(_vm.detail)
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  class: {
                                    "color-blue":
                                      _vm.getOrderTypeConf.equipmentOrderTypes.includes(
                                        _vm.detail.order_type
                                      ),
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.detail.order_type === 17
                                        ? _vm.detail.address
                                        : _vm.detail.addressName
                                    )
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "van-row",
                    { staticClass: "order-body-cell", attrs: { gutter: "10" } },
                    [
                      _c(
                        "van-col",
                        { staticClass: "sub-key", attrs: { span: 6 } },
                        [_vm._v("分派人:")]
                      ),
                      _c(
                        "van-col",
                        { staticClass: "sub-value", attrs: { span: 18 } },
                        [
                          _c("div", { staticClass: "flex-box" }, [
                            _c("span", [
                              _vm._v(_vm._s(_vm.detail.assign_name)),
                            ]),
                            _vm.detail.assign_phone
                              ? _c("span", { staticClass: "hpone-icon" })
                              : _vm._e(),
                            _c(
                              "span",
                              {
                                staticClass: "color-blue",
                                on: {
                                  click: function ($event) {
                                    return _vm.takePhone(
                                      _vm.detail.assign_phone
                                    )
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.detail.assign_phone))]
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "van-row",
                    { staticClass: "order-body-cell", attrs: { gutter: "10" } },
                    [
                      _c(
                        "van-col",
                        { staticClass: "sub-key", attrs: { span: 6 } },
                        [_vm._v("分派时间:")]
                      ),
                      _c(
                        "van-col",
                        { staticClass: "sub-value", attrs: { span: 18 } },
                        [_c("span", [_vm._v(_vm._s(_vm.detail.assign_time))])]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "van-row",
                    { staticClass: "order-body-cell", attrs: { gutter: "10" } },
                    [
                      _c(
                        "van-col",
                        { staticClass: "sub-key", attrs: { span: 6 } },
                        [_vm._v("处理人:")]
                      ),
                      _vm.detail.handler_name &&
                      _vm.getOrderTypeConf.normalOrderTypes.includes(
                        _vm.detail.order_type
                      )
                        ? _c(
                            "van-col",
                            { staticClass: "sub-value", attrs: { span: 18 } },
                            _vm._l(
                              _vm.detail.handler_name.split(","),
                              function (item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticStyle: {
                                      display: "flex",
                                      "align-items": "center",
                                      height: "30px",
                                    },
                                  },
                                  [
                                    _c("span", [_vm._v(_vm._s(item))]),
                                    _vm.detail.handler_phone
                                      ? [
                                          _c("span", {
                                            staticClass: "hpone-icon",
                                          }),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "color-blue",
                                              on: {
                                                click: function ($event) {
                                                  _vm.takePhone(
                                                    _vm.detail.handler_phone.split(
                                                      ","
                                                    )[index]
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.detail.handler_phone.split(
                                                    ","
                                                  )[index]
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                )
                              }
                            ),
                            0
                          )
                        : _vm._e(),
                      _vm.detail.handler_name &&
                      _vm.getOrderTypeConf.taskOrderTypes.includes(
                        _vm.detail.order_type
                      )
                        ? _c(
                            "van-col",
                            { staticClass: "sub-value", attrs: { span: 18 } },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.detail.handler_name)),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.getOrderTypeConf.normalOrderTypes.includes(
                    _vm.detail.order_type
                  )
                    ? _c(
                        "van-row",
                        {
                          staticClass: "order-body-cell",
                          attrs: { gutter: "10" },
                        },
                        [
                          _c(
                            "van-col",
                            { staticClass: "sub-key", attrs: { span: 6 } },
                            [_vm._v("处理时限:")]
                          ),
                          _c(
                            "van-col",
                            { staticClass: "sub-value", attrs: { span: 18 } },
                            [
                              _c(
                                "span",
                                { staticClass: "block process_limit" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$moment.formatMinutes(
                                        _vm.detail.process_limit
                                      )
                                    )
                                  ),
                                ]
                              ),
                              !(
                                _vm.detail.template_node_code ===
                                  "yiwancheng" ||
                                _vm.detail.template_node_code === "yiwanjie"
                              )
                                ? [
                                    _c("br"),
                                    _vm.detail.remainingTime
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "color-green process_limit",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.detail.remainingTime)
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.detail.overdueTime
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "color-red process_limit",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.detail.overdueTime)
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.getOrderTypeConf.taskOrderTypes.includes(
                    _vm.detail.order_type
                  )
                    ? [
                        _c(
                          "van-row",
                          {
                            staticClass: "order-body-cell",
                            attrs: { gutter: "10" },
                          },
                          [
                            _c(
                              "van-col",
                              { staticClass: "sub-key", attrs: { span: 8 } },
                              [_vm._v("计划开始时间:")]
                            ),
                            _c(
                              "van-col",
                              { staticClass: "sub-value", attrs: { span: 16 } },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.detail.plan_start)),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "van-row",
                          {
                            staticClass: "order-body-cell",
                            attrs: { gutter: "10" },
                          },
                          [
                            _c(
                              "van-col",
                              { staticClass: "sub-key", attrs: { span: 8 } },
                              [_vm._v("计划结束时间:")]
                            ),
                            _c(
                              "van-col",
                              { staticClass: "sub-value", attrs: { span: 16 } },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.detail.plan_end)),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "van-row",
                          {
                            staticClass: "order-body-cell",
                            attrs: { gutter: "10" },
                          },
                          [
                            _c(
                              "van-col",
                              { staticClass: "sub-key", attrs: { span: 8 } },
                              [_vm._v("实际开始时间:")]
                            ),
                            _c(
                              "van-col",
                              { staticClass: "sub-value", attrs: { span: 16 } },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.detail.actual_start)),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "van-row",
                          {
                            staticClass: "order-body-cell",
                            attrs: { gutter: "10" },
                          },
                          [
                            _c(
                              "van-col",
                              { staticClass: "sub-key", attrs: { span: 8 } },
                              [_vm._v("实际结束时间:")]
                            ),
                            _c(
                              "van-col",
                              { staticClass: "sub-value", attrs: { span: 16 } },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.detail.actual_end)),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _c(
                    "van-row",
                    { staticClass: "order-body-cell", attrs: { gutter: "10" } },
                    [
                      _c(
                        "van-col",
                        { staticClass: "sub-key", attrs: { span: 6 } },
                        [_vm._v("工单描述:")]
                      ),
                      _c(
                        "van-col",
                        { staticClass: "sub-value", attrs: { span: 18 } },
                        [
                          _vm.detail.order_no &&
                          _vm.detail.order_no.startsWith("C")
                            ? [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.detail.remark.substring(
                                        0,
                                        _vm.detail.remark.indexOf("问题描述")
                                      )
                                    )
                                  ),
                                ]),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.detail.remark.substring(
                                        _vm.detail.remark.indexOf("问题描述"),
                                        _vm.detail.remark.length
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            : _c("span", [_vm._v(_vm._s(_vm.detail.remark))]),
                          _vm.detail.attachment_list &&
                          _vm.detail.attachment_list.length > 0 &&
                          (_vm.isOffline || _vm.showDetailImgAfterLoad)
                            ? _c(
                                "div",
                                { staticClass: "detail_image_list" },
                                _vm._l(
                                  _vm.detail.attachment_list,
                                  function (list, index) {
                                    return _c("van-image", {
                                      key: index,
                                      staticClass: "photo",
                                      attrs: {
                                        width: "40",
                                        height: "40",
                                        radius: "4",
                                        src: _vm.isOffline
                                          ? list.url
                                          : _vm.offlineImageBase64[list.url],
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.imgPreview(
                                            index,
                                            _vm.detail.attachment_list
                                          )
                                        },
                                      },
                                    })
                                  }
                                ),
                                1
                              )
                            : _vm._e(),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _vm.getOrderTypeConf.normalOrderTypes.includes(
                    _vm.detail.order_type
                  )
                    ? [
                        _vm.detail.upgradeOrderNo
                          ? _c(
                              "van-row",
                              {
                                staticClass: "order-body-cell",
                                attrs: { gutter: "10" },
                              },
                              [
                                _c(
                                  "van-col",
                                  {
                                    staticClass: "sub-key",
                                    attrs: { span: 6 },
                                  },
                                  [_c("span", [_vm._v("升级工单:")])]
                                ),
                                _c(
                                  "van-col",
                                  {
                                    staticClass: "order-sub-flex",
                                    attrs: { span: 18 },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "color-blue",
                                        on: {
                                          click: function ($event) {
                                            return _vm.openRelationOrderDetail(
                                              _vm.detail.upgradeOrderId
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.detail.upgradeOrderNo)
                                        ),
                                      ]
                                    ),
                                    [0, 1].includes(_vm.detail.isEfficacy)
                                      ? _c(
                                          "van-tag",
                                          {
                                            staticStyle: {
                                              background:
                                                "rgba(253, 141, 55, 0.1)",
                                              "margin-left": "10px",
                                            },
                                            attrs: {
                                              plain: "",
                                              type: "primary",
                                              size: "medium",
                                              color: "#FD8D37",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.detail.isEfficacy === 1
                                                    ? "有效升级"
                                                    : "无效升级"
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.detail.badOrderId
                          ? _c(
                              "van-row",
                              {
                                staticClass: "order-body-cell",
                                attrs: { gutter: "10" },
                              },
                              [
                                _c(
                                  "van-col",
                                  {
                                    staticClass: "sub-key",
                                    attrs: { span: 6 },
                                  },
                                  [_c("span", [_vm._v("差评工单:")])]
                                ),
                                _c(
                                  "van-col",
                                  {
                                    staticClass: "order-sub-flex",
                                    attrs: { span: 18 },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "color-blue",
                                        on: {
                                          click: function ($event) {
                                            return _vm.openRelationOrderDetail(
                                              _vm.detail.badOrderId
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.detail.badOrderNo))]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.detail.problemOrderNo
                          ? _c(
                              "van-row",
                              {
                                staticClass: "order-body-cell",
                                attrs: { gutter: "10" },
                              },
                              [
                                _c(
                                  "van-col",
                                  {
                                    staticClass: "sub-key",
                                    attrs: { span: 6 },
                                  },
                                  [_c("span", [_vm._v("原工单:")])]
                                ),
                                _c(
                                  "van-col",
                                  {
                                    staticClass: "order-sub-flex",
                                    attrs: { span: 18 },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "color-blue",
                                        on: {
                                          click: function ($event) {
                                            return _vm.openRelationOrderDetail(
                                              _vm.detail.problemOrderId
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.detail.problemOrderNo)
                                        ),
                                      ]
                                    ),
                                    [0, 1].includes(_vm.detail.isEfficacy)
                                      ? _c(
                                          "van-tag",
                                          {
                                            staticStyle: {
                                              background:
                                                "rgba(253, 141, 55, 0.1)",
                                              "margin-left": "10px",
                                            },
                                            attrs: {
                                              plain: "",
                                              type: "primary",
                                              size: "medium",
                                              color: "#FD8D37",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.detail.isEfficacy === 1
                                                    ? "有效升级"
                                                    : "无效升级"
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.detail.forwardFmOrderNo
                          ? _c(
                              "van-row",
                              {
                                staticClass: "order-body-cell",
                                attrs: { gutter: "10" },
                              },
                              [
                                _c(
                                  "van-col",
                                  {
                                    staticClass: "sub-key",
                                    attrs: { span: 6 },
                                  },
                                  [_c("span", [_vm._v("原工单:")])]
                                ),
                                _c(
                                  "van-col",
                                  {
                                    staticClass: "order-sub-flex",
                                    attrs: { span: 18 },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "color-blue",
                                        on: {
                                          click: function ($event) {
                                            return _vm.openRelationOrderDetail(
                                              _vm.detail.forwardFmOrderId
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.detail.forwardFmOrderNo)
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.detail.fmOrderNos &&
                        _vm.detail.fmOrderNos.length > 0
                          ? _c(
                              "van-row",
                              {
                                staticClass: "order-body-cell",
                                attrs: { gutter: "10" },
                              },
                              [
                                _c(
                                  "van-col",
                                  {
                                    staticClass: "sub-key",
                                    attrs: { span: 6 },
                                  },
                                  [_c("span", [_vm._v("FM工单:")])]
                                ),
                                _c(
                                  "van-col",
                                  {
                                    staticClass: "order-sub-flex",
                                    attrs: { span: 18 },
                                  },
                                  _vm._l(
                                    _vm.detail.fmOrderNos,
                                    function (order) {
                                      return _c(
                                        "div",
                                        {
                                          key: order,
                                          staticClass: "color-blue",
                                          on: {
                                            click: function ($event) {
                                              return _vm.openRelationOrderDetail(
                                                order.orderId,
                                                true
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(order.orderNo))]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.getOrderTypeConf.equipmentOrderTypes.includes(
                          _vm.detail.order_type
                        ) &&
                        _vm.detail.problemOrdersMainReason &&
                        _vm.detail.problemOrdersMainReason.length > 0
                          ? _c("MyFieldOfMalfunctionReasonSelect", {
                              staticClass: "malf_reason_select",
                              attrs: {
                                "problem-list":
                                  _vm.detail.problemOrdersMainReason,
                                categoryCode: _vm.detail.addressCategoryCode,
                                "route-name": null,
                                reallyType: _vm.detail.addressType,
                                editable: false,
                                guide: "",
                              },
                            })
                          : _vm._e(),
                      ]
                    : _vm._e(),
                  _vm.taskList && _vm.taskList.length > 0
                    ? _c(
                        "div",
                        { staticClass: "other-list-wrapper" },
                        [
                          _c(
                            "p",
                            { staticClass: "title_content" },
                            [
                              _c("span", { staticClass: "title" }, [
                                _vm._v(_vm._s(_vm.taskTitle())),
                              ]),
                              _vm.hasGradeOption
                                ? _c("score", {
                                    attrs: {
                                      score: _vm.finalScore,
                                      total: _vm.totalScore,
                                      "show-txt": true,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._l(_vm.taskList, function (item, index) {
                            return _c(
                              "div",
                              {
                                key: item.itemId,
                                staticClass: "task-list",
                                on: {
                                  click: function ($event) {
                                    return _vm.goList(item, index)
                                  },
                                  touchstart: function ($event) {
                                    return _vm.taskItemListTouchStart(
                                      item,
                                      index
                                    )
                                  },
                                  touchmove: function ($event) {
                                    return _vm.taskItemListTouchmove(item)
                                  },
                                  touchend: function ($event) {
                                    return _vm.taskItemListTouchEnd(item, index)
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "task-info",
                                    class: { disabled: item.gradeStatus === 2 },
                                  },
                                  [
                                    _c("p", { staticClass: "task-name" }, [
                                      _vm._v(_vm._s(item.itemName)),
                                    ]),
                                    item.taskType !== 3 && item.taskType !== 6
                                      ? _c("p", { staticClass: "task-code" }, [
                                          _vm._v(
                                            " " + _vm._s(item.qrCode) + " "
                                          ),
                                        ])
                                      : _vm._e(),
                                    item.taskType !== 3 && item.taskType !== 6
                                      ? _c(
                                          "p",
                                          { staticClass: "task-address" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(item.addressName) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                                _vm.$route.query.checkNo
                                  ? [
                                      _c(
                                        "span",
                                        {
                                          class: [
                                            item.checkResult === 1
                                              ? "text-[#28C469]"
                                              : "text-[#F44A45]",
                                          ],
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.calcQualified(
                                                  item.checkResult
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  : [
                                      item.gradeStatus === 2
                                        ? _c("van-icon", {
                                            attrs: { name: "cross" },
                                          })
                                        : item.status === 1
                                        ? [
                                            item.finalScore ||
                                            item.finalScore === 0
                                              ? _c(
                                                  "span",
                                                  [
                                                    _c("score", {
                                                      attrs: {
                                                        score: item.finalScore,
                                                        total: item.totalScore,
                                                      },
                                                    }),
                                                    _vm.editOrderAuth
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "proofread",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.stopPropagation()
                                                                return _vm.handleProofread(
                                                                  item,
                                                                  index
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("校对")]
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                )
                                              : [
                                                  item.offlineSubmit === 1
                                                    ? _c("span", {
                                                        staticClass:
                                                          "default-icon active offline",
                                                      })
                                                    : _c("span", {
                                                        staticClass:
                                                          "default-icon active online",
                                                      }),
                                                ],
                                          ]
                                        : _c("span", {
                                            staticClass: "default-icon",
                                          }),
                                    ],
                              ],
                              2
                            )
                          }),
                        ],
                        2
                      )
                    : _vm._e(),
                ],
                2
              ),
            ]
          ),
          _c(
            "van-tab",
            {
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "tab-class" }, [
                        _c("div", [_vm._v("跟进信息")]),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c(
                "div",
                { staticClass: "scroll-detail-wrapper" },
                [
                  _c(
                    "van-steps",
                    {
                      staticClass: "stpe-component",
                      attrs: {
                        direction: "vertical",
                        "active-color": "#969799",
                      },
                    },
                    _vm._l(_vm.logList, function (item, index) {
                      return _c(
                        "van-step",
                        { key: index, staticClass: "step" },
                        [
                          _c(
                            "div",
                            { staticClass: "step-cell" },
                            [
                              item.currNodeId !== item.preNodeId
                                ? [
                                    _c("span", { staticClass: "color-blue" }, [
                                      _vm._v(_vm._s(item.preNodeName)),
                                    ]),
                                    item.preNodeName
                                      ? _c("em", {
                                          staticClass: "icon-arrow-blue",
                                        })
                                      : _vm._e(),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "color-blue",
                                        class: { "ml-24": item.preNodeName },
                                      },
                                      [_vm._v(_vm._s(item.curNodeName))]
                                    ),
                                  ]
                                : [
                                    _c("span", { staticClass: "color-blue" }, [
                                      _vm._v(_vm._s(item.curNodeName)),
                                    ]),
                                  ],
                              _c("span", { staticClass: "color-gray" }, [
                                _vm._v("「" + _vm._s(item.handlerName)),
                                item.handleMobile
                                  ? _c("span", [
                                      _vm._v(
                                        "(" + _vm._s(item.handleMobile) + ")"
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v("」"),
                              ]),
                            ],
                            2
                          ),
                          item.cuiban
                            ? _c(
                                "div",
                                { staticStyle: { margin: "4px 0" } },
                                [
                                  _c(
                                    "van-tag",
                                    {
                                      staticStyle: {
                                        background: "rgba(253, 141, 55, 0.1)",
                                      },
                                      attrs: {
                                        plain: "",
                                        color: "#FD8D37",
                                        size: "medium",
                                      },
                                    },
                                    [
                                      _c("span", { staticClass: "font-bold" }, [
                                        _vm._v("催办"),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            { staticClass: "step-cell time color-gray" },
                            [_vm._v(" " + _vm._s(item.createTime) + " ")]
                          ),
                          _c(
                            "div",
                            { staticClass: "step-cell color-black" },
                            [
                              !item.create
                                ? [
                                    _vm._v(" " + _vm._s(item.device)),
                                    item.device !== "APP"
                                      ? _c("span", [_vm._v("端")])
                                      : _vm._e(),
                                    _vm._v("处理： "),
                                  ]
                                : _vm._e(),
                              _vm._v(" " + _vm._s(item.content) + " "),
                            ],
                            2
                          ),
                          _vm.isOffline || _vm.showLogImgAfterLoad
                            ? _c(
                                "div",
                                { staticClass: "order-photos" },
                                _vm._l(item.imgList, function (list, index) {
                                  return _c("van-image", {
                                    key: index,
                                    staticClass: "photo",
                                    attrs: {
                                      width: "40",
                                      height: "40",
                                      radius: "4",
                                      src: _vm.isOffline
                                        ? list.url
                                        : _vm.offlineImageBase64[list.url],
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.imgPreview(
                                          index,
                                          item.imgList
                                        )
                                      },
                                    },
                                  })
                                }),
                                1
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            { staticClass: "file-list" },
                            _vm._l(item.fileList, function (file, index) {
                              return _c(
                                "div",
                                { key: file.id + index, staticClass: "list" },
                                [
                                  _c("i", { staticClass: "file-icon" }),
                                  _c("span", { staticClass: "file-name" }, [
                                    _vm._v(_vm._s(file.name)),
                                  ]),
                                ]
                              )
                            }),
                            0
                          ),
                          _vm.detail.order_type === 16 &&
                          _vm.isOffline &&
                          item.record &&
                          item.record.length
                            ? _c(
                                "div",
                                { staticClass: "order-photos" },
                                _vm._l(item.record, function (list, index) {
                                  return _c("van-image", {
                                    key: index,
                                    staticClass: "photo",
                                    attrs: {
                                      width: "40",
                                      height: "40",
                                      radius: "4",
                                      src: list.url,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.imgPreview(
                                          index,
                                          item.record
                                        )
                                      },
                                    },
                                  })
                                }),
                                1
                              )
                            : _vm._e(),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm.buttonList && _vm.buttonList.length > 0
        ? _c(
            "div",
            { staticClass: "bottom-area" },
            _vm._l(_vm.buttonList, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "button",
                  class: {
                    blue: item.buttonType === 1,
                    green: item.buttonType === 2 || item.buttonType === 100002,
                    yellow: item.buttonType === 3 || item.buttonType === 100001,
                    disabled: item.buttonType === 2 && _vm.setNextStatus(),
                    "two-button": _vm.buttonList.length === 2,
                    "one-button": _vm.buttonList.length === 1,
                    "has-two-row": _vm.buttonList.length > 3,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.intoCirculation(item)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(item.buttonName) + " ")]
              )
            }),
            0
          )
        : _vm._e(),
      _c(
        "van-overlay",
        {
          attrs: {
            show: _vm.phoneShow,
            "class-name": "overlay",
            "z-index": "9999",
          },
          on: {
            click: function ($event) {
              _vm.phoneShow = false
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "wrapper",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                },
              },
            },
            [
              _c("div", { staticClass: "phone-lay" }, [
                _c(
                  "div",
                  {
                    staticClass: "phone-cell",
                    on: {
                      click: function ($event) {
                        return _vm.phoneOperate(1)
                      },
                    },
                  },
                  [_vm._v(" 拨打" + _vm._s(_vm.stashPhone) + " ")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "phone-cell",
                    on: {
                      click: function ($event) {
                        return _vm.phoneOperate(2)
                      },
                    },
                  },
                  [_vm._v("取消")]
                ),
              ]),
            ]
          ),
        ]
      ),
      _c(
        "van-dialog",
        {
          attrs: {
            title: "得分校对",
            "show-cancel-button": "",
            getContainer: ".order-detail",
            "before-close": (action, done) =>
              _vm.beforeProofreadDialogClose(action, done),
          },
          model: {
            value: _vm.proofreadShow,
            callback: function ($$v) {
              _vm.proofreadShow = $$v
            },
            expression: "proofreadShow",
          },
        },
        [
          _c(
            "van-form",
            {
              ref: "proofreadForm",
              staticClass: "proofread_content",
              attrs: { "validate-first": "", "validate-trigger": "onSubmit" },
            },
            [
              _c("div", { staticClass: "notice" }, [
                _vm._v(
                  " 可对最终得分进行校对，调整后会记录在工单详情中，请合理使用！ "
                ),
              ]),
              _c(
                "div",
                { staticClass: "proofreadItem_wrapper" },
                _vm._l(_vm.scoreTaskListOptions, function (item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "proofreadItem" },
                    [
                      _c("div", { staticClass: "checkItemName" }, [
                        _vm._v(_vm._s(item.name)),
                      ]),
                      _c("van-field", {
                        staticClass: "proofreadValue",
                        attrs: {
                          name: `proofreadValue${index}`,
                          label: "",
                          rules: [
                            { required: true, message: "请输入最终得分" },
                          ],
                          center: "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "input",
                              fn: function () {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "stepper_content" },
                                    [
                                      _c("van-stepper", {
                                        attrs: {
                                          step: "0.5",
                                          max: item.proofreadMaxScore,
                                          min: -9999,
                                          theme: "round",
                                          "allow-empty": "",
                                          "decimal-length": 1,
                                        },
                                        model: {
                                          value: item.scoreModel,
                                          callback: function ($$v) {
                                            _vm.$set(item, "scoreModel", $$v)
                                          },
                                          expression: "item.scoreModel",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                    ],
                    1
                  )
                }),
                0
              ),
              _c("van-field", {
                staticClass: "proofreadExplain",
                attrs: {
                  name: "proofreadExplain",
                  type: "textarea",
                  label: "",
                  placeholder: "输入调整说明，必填",
                  clearable: "",
                  rules: [{ required: true, message: "" }],
                },
                model: {
                  value: _vm.proofreadExplain,
                  callback: function ($$v) {
                    _vm.proofreadExplain = $$v
                  },
                  expression: "proofreadExplain",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }