<template>
  <van-popup v-model="_show" position="bottom" :style="{ width: '100%', height: '85%' }">
    <div class="select-position-header clear">
      所在位置
      <div @click="_show = false" class="btn-close pull-right">
        <van-icon name="cross" style="vertical-align: top" color="#000" size="16px" />
      </div>
    </div>
    <div class="select-positon-content">
      <vuescroll :ops="ops" ref="vsposition">
        <van-collapse v-model="activeNames">
          <van-collapse-item :title="`${item.name}(${item.pointTreePointResps.length})`"
            v-for="item in list" :key="item.code">
            <div class="select-item" @click="selectPosition(position, item.name)"
              v-for="position in item.pointTreePointResps" :key="position.code">
              {{ position.name }}
              <van-icon v-if="selectPostion === position.name" name="success" size="24px"
                class="success-icon pull-right" />
            </div>
          </van-collapse-item>
        </van-collapse>
        <div class="no-data" v-if="list.length === 0">暂无数据</div>
      </vuescroll>
    </div>
  </van-popup>
</template>
<script>
import vuescroll from 'vuescroll/dist/vuescroll-slide'
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    projectCode: {
      type: String,
      default: ''
    },
    dataList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      loading: false,
      selectPostion: '',
      activeNames: [],
      list: [],
      ops: {
        vuescroll: {
          mode: 'slide'
        },
        scrollPanel: {
          scrollingX: false,
          scrollingY: true
        },
        rail: {
          size: '4px',
          keepShow: false
        },
        bar: {
          size: '4px',
          background: '#eee'
        }
      }
    }
  },
  components: {
    vuescroll
  },
  computed: {
    _show: {
      get () {
        return this.show
      },
      set (val) {
        !val && this.$emit('update:show', false)
      }
    }
  },
  methods: {
    selectPosition (item, parent) {
      this.selectPostion = item.title
      this.$emit('result', item, parent)
      this._show = false
    },
    async getPosition () {
      console.log('this.projectCode: ', this.projectCode)
      if (!this.projectCode) {
        this.$toast('请先选择项目')
        return
      }
      // 根据项目请求点位数据
      const pointTree = await this.$http.get(`${this.$api.pointTreeByProjectCode}/${this.projectCode}`)
      console.log('pointTree: ', pointTree)
      this.list = pointTree
    }
  },
  watch: {
    _show (val) {
      if (val) { // 如果打开时 prop没有传入数据就http加载数据
        this.dataList.length === 0 ? this.getPosition() : this.list = this.dataList
      }
    }
  }
}
</script>
<style scoped lang="less">
.select-position-header {
  padding: 15px 16px;
  font-size: 16px;
  // font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #222222;
  line-height: 22px;
  border-bottom: 1px solid #e8e8e8;
}
.select-positon-content {
  height: calc(100% - 55px);
  padding-bottom: 16px;
  box-sizing: border-box;
  .select-item {
    line-height: 48px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #222222;
    .success-icon {
      color: #3d69f5;
      margin-top: 14px;
    }
  }
}
.btn-close {
  padding: 5px 0px 5px 5px;
  margin-top: -2px;
  height: 26px;
  box-sizing: border-box;
}
</style>
