<template>
  <div class="create-work">
    <my-nav title="创建工单" :self-back="true" @back="goBack"></my-nav>
    <div class="separator_block" />
    <van-tabs animated color="#3366FE" sticky offset-top="44" title-active-color="#3366FE" title-inactive-color="#7C7F8A" line-height="2px" line-width="70px" v-model="tabActive" @click="clickTabs">
      <van-tab title="自查报事"></van-tab>
      <van-tab title="业主报事"></van-tab>
    </van-tabs>
    <van-field v-if="tabActive === 1" readonly :value="form.reportUserName" label="报事业主" class="form-input" required right-icon="arrow" input-align="right" placeholder="请选择" @click="clickReportUser"/>
    <van-field v-if="tabActive === 1" :value="form.reportUserPhone" label="报事业主电话" class="form-input" required input-align="right" placeholder="请输入业主电话" />
    <van-field readonly :value="form.incidentPlaceName" label="报事位置" class="form-input" required right-icon="arrow" input-align="right" placeholder="请选择" @click="clickIncidetPlace"/>
    <van-field name="remark" v-model="form.remark" label="报事描述" rows="3" class="form-textarea" required type="textarea" placeholder="请描述报事内容，如1单元楼栋单元门异响">
      <template v-slot:right-icon v-if="isOffline">
        <micro-text
          @speakRes="speakRes"
        />
      </template>
    </van-field>
    <div style="padding: 0 16px 16px; background: #fff">
      <!-- <van-upload-offline
        :file.sync="form.attachmentList"
        :maxCount="6"
      >
      </van-upload-offline> -->
      <common-upload
        :max-count="6"
        :file.sync="form.attachmentList"
        :project-name="form.projectName"
        :required="false"
        ref="offlineUpload"
        :enable-album="true"
        :enable-edit="true"
        :enable-watermark="false"
      ></common-upload>
    </div>
    <van-field v-if="tabActive === 1" readonly v-model="form.isTouSuName" label="是否投诉" class="form-input" required right-icon="arrow"  input-align="right" placeholder="请选择" @click="() => (showIsTouSu = true)"/>
    <van-popup v-model="showIsTouSu" position="bottom">
      <van-picker
        show-toolbar
        :columns="isTouSuList"
        value-key="name"
        @confirm="showIsTouSuOK"
        @cancel="showIsTouSu = false"
      />
    </van-popup>
    <van-field readonly clickable v-model="form.drClassName" label="报事类型" class="form-input" required  right-icon="arrow" input-align="right" placeholder="请选择" @click="() => (showReportType = true)"/>
    <van-popup v-model="showReportType" position="bottom">
      <van-picker
        show-toolbar
        :columns="reportTypeList"
        value-key="name"
        @confirm="showReportTypeOK"
        @cancel="showReportType = false"
      />
    </van-popup>
    <van-field readonly clickable :value="form.bigCategoryName" label="报事类别" class="form-input"  required right-icon="arrow" input-align="right" placeholder="请选择" @click="bigCategoryClick"/>
    <van-field readonly clickable :value="form.smallCategoryName"  label="报事细类"  class="form-input"  :required="form.drClass === 2" right-icon="arrow" input-align="right" placeholder="请选择"  @click="smallCorpTypeClick"/>
    <van-field readonly clickable :value="form.infoSource" label="报事方式" class="form-input"  required right-icon="arrow" input-align="right" placeholder="请选择" @click="() => (showReportWay = true)"/>
    <van-popup v-model="showReportWay" position="bottom">
      <van-picker
        show-toolbar
        :columns="reportWayList"
        @confirm="showReportWayOK"
        @cancel="showReportWay = false"
      />
    </van-popup>
    <van-field readonly clickable :value="form.handlerName" label="处理人" class="form-input" :required="form.drClass === 2" :right-icon="form.handlerName ? 'clear' : 'arrow'" input-align="right" placeholder="请选择" @click="handlerClick" clearable @click-right-icon="clearHandlerName"/>
    <van-popup v-model="showHandler" position="bottom">
      <van-picker
        show-toolbar
        value-key="userName"
        :columns="handlerList"
        @confirm="showHandlerOK"
        @cancel="showHandler = false"
      />
    </van-popup>
    <van-field  readonly name="grade" :value="form.bookingTime" label="预约时间" class="form-input"  @click="bookingTimeShow = true" required right-icon="arrow" input-align="right" placeholder="请选择"/>
    <van-popup v-model="bookingTimeShow" position="bottom" safe-area-inset-bottom>
      <van-datetime-picker
        v-model="bookingTime"
        type="datetime"
        title="选择预约时间"
        :min-date="minBookingTimeDate"
        :columns-order="['year','month', 'day','hour','minute','second']"
        :formatter="formatter"
        @cancel="bookingTimeShow = false"
        @confirm="bookingTimeConfirm"
      />
    </van-popup>
    <div class="button_row">
      <van-button
        class="button"
        type="primary"
        @click="createSubmit"
        loading-type="spinner"
        loading-text="提交中..."
        >提交</van-button
      >
    </div>
    <SelectReportPosition
      :incidentSource="form.incidentSource"
      :show.sync="selectProjectShow"
      @result="selectProjectConfim"
      ref="selectProject"
    ></SelectReportPosition>
    <SelectReasonTree
      :title="selectReasonTitle"
      :show.sync="selectReasonShow"
      :projectCode="form.projectCode"
      @result="selectReasonConfim"
      ref="SelectReasonTreeRef"
      :isBigType="isBigType"
      :duty="form.duty"
      :typeCode="typeCode"
      :incidentPlace="form.incidentPlace"
      :drClass="form.drClass"
    ></SelectReasonTree>
    <SelectIncidetPlace
      :show.sync="selectIncidetPlaceShow"
      :projectCode="form.projectCode"
      :projectName="form.projectName"
      @result="selectIncidetPlaceConfim"
      ref="SelectIncidetPlace"
    ></SelectIncidetPlace>
  </div>
</template>

<script>

import mobile from '@/utils/mobile'
import SelectReportPosition from './components/selectReportPosition.vue'
import SelectReasonTree from './components/selectReasonTree.vue'
import SelectIncidetPlace from './components/selectIncidetPlace.vue'
import moment from 'moment'
import { getTWIncidentDrClassReq, getTWIncidentModeReq, getTWHandlersList, createReportEventOrder } from '@/api/report'
import {
  uploadPhotoByImgKeys
} from '@/plugins/offlineMixin/utils/offlineImgTool'

export default {
  name: 'CreateProblemRecord',
  components: {
    SelectReportPosition,
    SelectReasonTree,
    SelectIncidetPlace
  },
  data () {
    return {
      tabActive: 0,
      bookingTime: '',
      minBookingTimeDate: new Date(),
      bookingTimeShow: false,
      form: {
        projectName: undefined,
        projectCode: undefined,
        incidentSource: '自查报事',
        drClass: 1, // 报事类型（1：书面；2：口派）
        drClassName: undefined,
        remark: '', // 工单描述
        isTouSu: 0, // 是否投诉（否：0；是：1）
        isTouSuName: '否',
        infoSource: undefined, // 报事方式
        attachmentList: [], // 附件
        bookingTime: null, // 预约时间
        orderHandle: 2, // 工单处理：1-内部 2-天问
        bigCategoryCode: undefined,
        bigCategoryName: undefined,
        smallCategoryCode: undefined,
        smallCategoryName: undefined,
        incidentPlace: '', // 报事位置
        incidentPlaceName: '', // 报事位置名称展示
        problemRecordList: [
          { problemList: [{ rectifyPeopls: '' }] } // 处理人
        ]
      },
      selectProjectShow: false,
      // ossParams: {}, // 阿里云oss参数
      reportCustomerRouterList: [],
      showIsTouSu: false, // 是否投诉
      showReportType: false, // 报事类型
      isBigType: false, // 判断点击的是大类还是小类
      showReportWay: false, // 报事方式
      showHandler: false, // 处理人
      isTouSuList: [ // 是否投诉
        { name: '是', value: 1 },
        { name: '否', value: 0 }
      ],
      reportTypeList: [], // 报事类型 list
      reportWayList: [], // 报事方式 list
      handlerList: [], // 处理人 list
      chooseHouse: null,
      selectReasonTitle: '',
      typeCode: undefined,
      selectReasonShow: false, // 原型大类 原因细类
      selectIncidetPlaceShow: false
    }
  },
  computed: {
    isOffline () {
      return this.$store.getters.getIsOffline
    }
  },
  watch: {
    'form.drClass' (val) {
      this.clearSomeData()
    }
  },
  mounted () {
    this.getReportTypeList()
    this.getReportWayList()
    // this.getHandlersList()
    console.log(1234)
    console.log('this.form~~mounted: ', this.form)
    this.bookingTimeConfirm(new Date())
  },
  methods: {
    clickTabs (name, title) {
      this.form = this.$options.data().form
      this.form.isTouSu = 0
      this.form.drClass = this.reportTypeList?.length ? this.reportTypeList[0].drClass : ''
      this.form.drClassName = this.reportTypeList?.length ? this.reportTypeList[0].name : ''
      this.bookingTimeConfirm(new Date())
      this.form.incidentSource = name === 0 ? '自查报事' : '客户报事'
      this.form.infoSource = name === 0 ? '现场' : '' // 默认选择
    },
    clearSomeData () { // 清空 报事大类报事细类和处理人
      this.form.bigCategoryCode = undefined
      this.form.bigCategoryName = undefined
      this.form.smallCategoryCode = undefined
      this.form.smallCategoryName = undefined
      this.form.handlerName = undefined
      this.form.problemRecordList = [
        { problemList: [{ rectifyPeopls: '' }] } // 处理人
      ]
      console.log(222222)
    },
    formatter (type, val) {
      if (type === 'year') {
        return val + '年'
      }
      if (type === 'month') {
        return val + '月'
      }
      if (type === 'day') {
        return val + '日'
      }
      if (type === 'hour') {
        return val + '时'
      }
      if (type === 'minute') {
        return val + '分'
      }
      if (type === 'second') {
        return val + '秒'
      }
      return val
    },
    bookingTimeConfirm (value) {
      this.bookingTimeShow = false
      this.form.bookingTime = moment(value).format('yyyy-MM-DD HH:mm')
    },
    showIsTouSuOK (value) { // 是否投诉 选择
      this.form.isTouSu = value.value
      this.form.isTouSuName = value.name
      this.showIsTouSu = false
    },
    async getReportTypeList () { // 获取报事类型下拉
      const res = await getTWIncidentDrClassReq()
      this.reportTypeList = res
      this.form.drClass = this.reportTypeList?.length ? this.reportTypeList[0].drClass : ''
      this.form.drClassName = this.reportTypeList?.length ? this.reportTypeList[0].name : ''
    },
    showReportTypeOK (value) { // 报事类型选择
      this.form.drClass = value.drClass
      this.form.drClassName = value.name
      this.showReportType = false
    },
    async getReportWayList () { // 获取报事方式下拉
      const res = await getTWIncidentModeReq()
      this.reportWayList = res
      this.form.infoSource = '现场'
    },
    showReportWayOK (value) { // 报事方式选择
      this.form.infoSource = value
      this.showReportWay = false
    },
    bigCategoryClick () { // 点击报事类别
      if (!this.form.projectCode) {
        this.$toast.fail('请先选择报事位置')
        return
      }
      this.isBigType = true
      this.selectReasonShow = true
      this.selectReasonTitle = '选择报事类别'
    },
    smallCorpTypeClick () { // 点击报事细类
      if (!this.form.bigCategoryCode) {
        this.$toast.fail('请先选择报事大类')
        return
      }
      this.isBigType = false
      this.selectReasonShow = true
      this.selectReasonTitle = '选择报事细类'
    },
    clickReportUser () {
      this.selectProjectShow = true
    },
    clickIncidetPlace () { // 点击报事位置输入框
      console.log('this.tabActive: ', this.tabActive)
      if (this.tabActive === 0) { // 自查报事
        this.selectProjectShow = true
      } else { // 业主报事
        if (!this.form.projectCode) {
          this.$toast.fail('请先选择所在项目')
          return
        }
        this.selectIncidetPlaceShow = true
      }
    },
    async selectProjectConfim (projectInfo) { // 报事位置选择 result
      const obj = this.lodash.cloneDeep(projectInfo)
      console.log('obj: ', obj)
      this.form.projectCode = obj.checkList[0].inPopedom
      this.form.projectName = obj.checkList[0].organName
      this.form.incidentPlace = obj.incidentPlace
      if (obj.incidentPlace === '户内') {
        this.form.incidentPlaceName = obj.checkPositionList[0].roomName
        this.form.reportHouseCode = obj.checkPositionList[0].roomID
        this.form.reportHouseName = obj.checkPositionList[0].roomName
        this.form.reportUserPhone = obj.checkPositionList[0].custMobilePhone
        this.form.reportUserName = obj.checkPositionList[0].custName
        this.form.holdID = obj.checkPositionList[0].holdID
        this.form.custID = obj.checkPositionList[0].custID
        this.form.custPhone = obj.checkPositionList[0].custMobilePhone
      } else {
        this.form.incidentPlaceName = obj.checkPositionList[0].regionalPlace
        this.form.regionalID = obj.checkPositionList[0].regionalID
      }
      // 清空 报事大类报事细类和处理人
      this.clearSomeData()
      console.log('this.form~~！！: ', this.form)
    },
    async selectIncidetPlaceConfim (incidetPlace) { // 业主报事报事位置选择 result
      this.form.incidentPlace = incidetPlace.incidentPlace
      if (incidetPlace.incidentPlace === '户内') {
        this.form.incidentPlaceName = incidetPlace.checkPositionList[0].roomName
        this.form.reportHouseCode = incidetPlace.checkPositionList[0].roomID
        this.form.reportHouseName = incidetPlace.checkPositionList[0].roomName
        this.form.reportUserPhone = incidetPlace.checkPositionList[0].custMobilePhone
        this.form.reportUserName = incidetPlace.checkPositionList[0].custName
        this.form.holdID = incidetPlace.checkPositionList[0].holdID
        this.form.custID = incidetPlace.checkPositionList[0].custID
      } else {
        this.form.incidentPlaceName = incidetPlace.checkPositionList[0].regionalPlace
        this.form.regionalID = incidetPlace.checkPositionList[0].regionalID
      }
      this.clearSomeData()
    },
    async selectReasonConfim (data, name) { // 原因大类 原因细类选择 result
      console.log('name: ', name)
      console.log('data: ', data)
      if (this.isBigType) {
        this.form.bigCategoryCode = data.corpTypeID
        this.form.duty = data.duty
        this.form.bigCategoryName = name
        this.typeCode = data.typeCode
        this.form.smallCategoryName = ''
        this.form.smallCategoryCode = ''
        this.getHandlersList(data.corpTypeID)
      } else {
        this.form.smallCategoryName = name
        this.form.smallCategoryCode = data.corpTypeID
      }
    },
    clearHandlerName (event) { // 清空处理人
      event.stopPropagation()
      if (this.form.handlerName) {
        this.form.handlerName = undefined
        this.form.problemRecordList[0].problemList[0].rectifyPeopls = ' '
        this.$forceUpdate()
      } else {
        this.handlerClick()
      }
    },
    handlerClick () { // 点击处理人
      // 需增加判断是否选择项目和原因大类
      if (!this.form.bigCategoryCode) {
        this.$toast.fail('请先选择报事大类')
        return
      }
      this.showHandler = true
    },
    async getHandlersList (corpTypeID) { // 获取责任人下拉
      const obj = {
        corpTypeId: corpTypeID, // 原因大类CorpTypeID
        projectCode: this.form.projectCode
      }
      const res = await getTWHandlersList(obj)
      this.handlerList = res
      this.form.handlerName = undefined // 清空处理人
      this.form.problemRecordList = [
        { problemList: [{ rectifyPeopls: '' }] }
      ]
      console.log('this.handlerList: ', this.handlerList)
    },
    showHandlerOK (value) { // 处理人选择
      console.log('value: ', value)
      const obj = {
        code: value.userCode,
        name: value.userName,
        phone: value.mobilePhone
      }
      this.form.problemRecordList[0].problemList[0].rectifyPeopls = JSON.stringify(obj)
      this.form.handlerName = value.userName
      this.showHandler = false
    },
    goBack () {
      mobile.backWeb()
    },
    speakRes (text) {
      this.form.remark += text
    },
    formValidation () {
      if (this.tabActive === 1) {
        if (!this.form.projectCode) {
          this.$toast.fail('请选择报事业主')
          return true
        }
        if (!this.form.reportUserPhone) {
          this.$toast.fail('请选择报事电话')
          return true
        }
      } else {
        if (!this.form.projectCode || !this.form.incidentPlaceName) {
          this.$toast.fail('请选择报事位置')
          return true
        }
      }

      if (!this.form.remark) {
        this.$toast.fail('请输入报事描述')
        return true
      }
      if (!this.form.bigCategoryCode) {
        this.$toast.fail('请选择报事类别')
        return true
      }
      if (!this.form.infoSource) {
        this.$toast.fail('请选择报事方式')
        return true
      }
      if (this.form.drClass === 2) {
        if (!this.form.smallCategoryCode) {
          this.$toast.fail('请选择报事细类')
          return true
        }
        if (!this.form.handlerName) {
          this.$toast.fail('请选择处理人')
          return true
        }
      }
    },
    async createSubmit () {
      const submitToast = this.$toast.loading({
        message: '正在提交',
        duration: 0, // 持续展示 toast
        forbidClick: true,
        overlay: true
      })
      try {
        if (this.formValidation()) return
        const obj = this.lodash.cloneDeep(this.form)
        if (obj.attachmentList?.length > 0) {
          const images = await uploadPhotoByImgKeys(obj.attachmentList)
          obj.attachmentList = images
        }
        const res = await createReportEventOrder(obj)
        console.log('res: ', res)
        submitToast.clear()
        this.$toast.success({
          message: '创建成功',
          duration: 2000,
          forbidClick: true,
          onClose: this.goBack
        })
      } catch (error) {
        submitToast.clear()
        console.log('error: ', error)
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import url("./index.less");
</style>
