<template>
  <div class="OrderSpotCheck">
    <my-nav blue title="工单抽查" :self-back="true" @back="goBack"></my-nav>
    <van-sticky :offset-top="navBarHeight">
      <DashboardProjectSelect
        ref="DashboardProjectSelect"
        @selectProject="selectProject"
      ></DashboardProjectSelect>
      <van-tabs
        animated
        color="#3366FE"
        title-active-color="#3366FE"
        title-inactive-color="#7C7F8A"
        line-height="2px"
        line-width="56px"
        @change="tabChange"
        v-model="searchParams.status"
        class="orderStatusTab"
      >
        <van-tab :name="1">
          <template v-slot:title>
            <div class="tab-class">
              <div>全部</div>
              <div>{{ `（${count["total"]}）` }}</div>
            </div>
          </template>
        </van-tab>
        <van-tab :name="2">
          <template v-slot:title>
            <div class="tab-class">
              <div>待办</div>
              <div>{{ `（${count["toCheck"]}）` }}</div>
            </div>
          </template>
        </van-tab>
        <van-tab :name="3">
          <template v-slot:title>
            <div class="tab-class">
              <div>已抽查</div>
              <div>{{ `（${count["spotChecked"]}）` }}</div>
            </div>
          </template>
        </van-tab>
        <van-tab :name="4">
          <template v-slot:title>
            <div class="tab-class">
              <div>已过期</div>
              <div>{{ `（${count["expired"]}）` }}</div>
            </div>
          </template>
        </van-tab>
      </van-tabs>
    </van-sticky>

    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        :immediate-check="false"
        :offset="50"
        v-model="loading"
        :finished="finished"
        :finished-text="
          list.length > 0 ? '- 没有更多数据了 -' : '您没有处理的工单'
        "
        @load="onLoad('list')"
      >
        <div class="order" v-for="(item, index) in list" :key="index">
          <div class="order_header">
            <div class="left">
              <span>{{ item.orderNo }}</span>
              <span
                class="copy"
                @click.prevent.stop="copyOrderNumber(item.orderNo)"
              >
                复制
              </span>
            </div>
            <span class="status">{{ orderStatusName(item.status) }}</span>
          </div>
          <div class="order_main">
            <van-row>
              <van-col span="6">抽查名称：</van-col>
              <van-col span="18">
                <span>{{ item.ruleName }}</span>
              </van-col>
            </van-row>
            <van-row>
              <van-col span="6">抽查项分类：</van-col>
              <van-col span="18">{{ item.classifyName }}</van-col>
            </van-row>
            <van-row>
              <van-col span="6">抽查项目：</van-col>
              <van-col span="18">{{ item.projectName }}</van-col>
            </van-row>
            <van-row>
              <van-col span="6">开始时间：</van-col>
              <van-col span="18">{{ item.planStart }}</van-col>
            </van-row>
            <van-row>
              <van-col span="6">结束时间：</van-col>
              <van-col span="18">{{ item.planEnd }}</van-col>
            </van-row>
            <van-row>
              <van-col span="6">执行人：</van-col>
              <van-col span="18">{{ item.executorName }}</van-col>
            </van-row>
          </div>
          <van-collapse v-model="item.activeNames">
            <van-collapse-item name="1">
              <template #right-icon>
                <van-icon name="arrow-down" v-if="!item.loading  && !item.activeNames.length"/>
                <van-icon name="arrow-up" v-if="!item.loading && item.activeNames.length"/>
                <img :src="require('@/asset/loading.svg')" class="loading-svg" v-if="item.loading" alt="">
              </template>
              <template #title>
                <div class="collapse-title" @click="loadTaskList(item, index)">
                  <div class="count-list">
                    <span class="count-label">任务数量:</span>
                    <span class="count">{{ item.orderNum }}</span>
                  </div>
                  <div class="count-list">
                    <span class="count-label">已抽查:</span>
                    <span class="count">{{ item.checkedNum }}</span>
                  </div>
                </div>
              </template>
              <div class="collapse-content" v-for="(task,i) in item.taskList" :key="i">
                <div class="header">
                  <span class="text-[#3366fe]" @click="routerToOrderDetail(task)">{{task.orderNo}}</span>
                  <span
                    class="copy"
                    @click.prevent.stop="copyOrderNumber(task.orderNo)"
                  >
                    复制
                  </span>
                </div>
                <van-row class="mb-8px">
                  <van-col span="8">作业方式：</van-col>
                  <van-col class="text-[#222222]" span="16">
                    <span>{{task.operateType}}</span>
                  </van-col>
                </van-row>
                <van-row class="mb-8px">
                  <van-col span="8">任务处理人：</van-col>
                  <van-col class="text-[#222222]" span="16">
                    <span>{{task.handlerName}}</span>
                  </van-col>
                </van-row>
                <van-row>
                  <van-col span="24">抽查点位/设施设备列表：</van-col>
                </van-row>
                <table
                  cellspacing="0"
                  border="0"
                  style="width: 100%"
                  class="table"
                >
                  <tr
                    v-for="(position, pi) in task.taskItems.slice(0,task.taskShowNum)"
                    :key="pi"
                    class="list-tr"
                    :class="{ lastrow: index === tableData.length - 1 }"
                  >
                    <td>
                      {{position.itemName}}
                    </td>
                    <td style="width:120px" class="lastCol button" @click="handleClickTaskItem(position, task, item)">
                      {{ position.status === 2 ? (position.isPass === 1 ? '合格': '不合格' ) : (position.status === 1 ? '抽查' : '') }}
                    </td>
                  </tr>
                </table>
                <div class="viewMore" @click="viewMore(task)" v-if="task.taskShowNum < task.taskItems.length">
                  <span>查看更多</span>
                  <van-icon name="arrow-down"/>
                </div>
              </div>
            </van-collapse-item>
          </van-collapse>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import mobile from '@/utils/mobile'
import {
  getSpotCheckOrderList,
  getSpotCheckOrderCount,
  getTaskOrderListBySpotCheck
} from '@/api/orderSpotCheck'

import DashboardProjectSelect from '@/pages/Dashboard/components/DashboardProjectSelect.vue'
export default {
  name: 'OrderSpotCheck',
  components: { DashboardProjectSelect },
  data () {
    return {
      selectProjectShow: false,
      searchParams: {
        status: 1,
        page: 1,
        limit: 10,
        projectCodes: []
      },
      tableData: [],
      count: {
        expired: 0,
        noChecked: 0,
        spotCheck: 0,
        spotChecked: 0,
        toCheck: 0,
        total: 0
      },
      refreshing: false,
      loading: false,
      finished: false,
      list: [],
      navBarHeight: 0
    }
  },
  computed: {
    orderStatusName () {
      return function (status) {
        switch (status) {
          case 1:
            return '待抽查'
          case 2:
            return '抽查中'
          case 3:
            return '已抽查'
          case 4:
            return '已过期'
          default:
            return ''
        }
      }
    }
  },
  beforeRouteLeave (to, from, next) {
    mobile.setHeaderBackColor('0xFFFFFFFF', '0x00000000')
    next()
  },
  beforeRouteEnter (to, from, next) {
    next((vm) => {
      mobile.setHeaderBackColor('0x3366FEFF', '0xFFFFFFFF')
    })
  },
  mounted () {
    this.$nextTick(async () => {
      this.navBarHeight = document.querySelector('.van-nav-bar')?.offsetHeight
      this.getInitList()
      this.$EB.$on(this.$EBT.ORDER_SPOTCHECK_REFRESH, this.getInitList)
    })
  },
  methods: {
    routerToOrderDetail (taskOrder) {
      this.$router.push({
        path: '/OrderDetail',
        query: {
          orderId: taskOrder.orderId
        }
      })
    },
    viewMore (task) {
      task.taskShowNum += 4
    },
    async loadTaskList (item, index) {
      if (item.orderNum === 0) return
      if (item.taskList && item.taskList.length) return
      item.loading = true
      const list = this.lodash.cloneDeep(this.list)
      const data = await getTaskOrderListBySpotCheck({
        spotOrderNo: item.orderNo
      })
      console.log('data: ', data)
      if (data && data.records.length) {
        data.records.forEach(record => {
          record.taskShowNum = 4
        })
        list[index].taskList = data.records
      }
      list[index].activeNames = ['1']
      list[index].loading = false
      this.list = list
      console.log(this.list)
    },
    async selectProject (list) {
      // 初始化参数
      this.searchParams.projectCodes = list.length > 0 ? list : []
      this.getInitList()
    },
    goBack () {
      mobile.backWeb()
    },
    tabChange () {
      this.getInitList()
    },
    onRefresh () {
      this.getInitList()
    },
    getInitList () {
      this.searchParams.page = 1
      this.finished = false
      this.list = []
      this.onLoad()
      this.getListCount()
    },
    async getListCount () {
      const result = await getSpotCheckOrderCount(this.searchParams)
      console.log('result: ', result)
      if (result) {
        this.count = result
      }
    },
    async onLoad () {
      try {
        this.loading = true
        const searchParams = JSON.parse(JSON.stringify(this.searchParams))
        searchParams.belongProfessional = (
          searchParams.belongProfessionals || []
        ).join(',')
        if (searchParams.status !== '未分派') {
          delete searchParams.belongProfessional
        }
        const result = await getSpotCheckOrderList(searchParams)
        console.log('result: ', result)
        if (result.records && result.records.length > 0) {
          result.records.forEach((item) => {
            item.taskList = []
            item.activeNames = []
          })
          this.list = this.list.concat(result.records)
        }
        if (result.records.length === 0) {
          this.finished = true
        }
        this.searchParams.page++
      } catch (error) {
        console.error('onLoad error: ', error)
        this.finished = true
      } finally {
        this.refreshing = false
        this.loading = false
      }
    },
    handleClickTaskItem (item, task, checkItem) {
      console.log('item: ', item)
      this.$router.push({
        name: 'OrderSpotCheckDetail',
        query: {
          itemId: item.itemId,
          orderId: task.id,
          taskOrderNo: task.orderNo,
          spotOrderNo: checkItem.orderNo
        },
        params: {}
      })
    },
    /* 复制订单号 */
    copyOrderNumber (orderNo) {
      this.$copyText(orderNo).then(
        (e) => {
          this.$notify({
            type: 'success',
            message: '复制成功',
            background: '#13CE66'
          })
        },
        (e) => {
          this.$notify({
            type: 'warning',
            message: '复制成功',
            background: '#FFC82C'
          })
        }
      )
    }
  }
}
</script>

<style lang="less" scoped>
@import url("./index.less");
</style>
