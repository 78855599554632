<template>
  <div class="select-project-wrapper">
      <div class="flex flex-col h-full">
      <my-nav title="点位列表" :self-back="true" @back="goBack">
          <div class="flex items-center" @click="batchSelection" slot="right">
              <i class="multi-icon" :class="checkIcon"></i>
              <div class="title ml-4px">全选</div>
              <div></div>
          </div>
      </my-nav>
        <div class="container_main flex flex-1 h-full">
          <div class="class_main w-100px bg-light-500 h-full overflow-y-auto">
            <div class="
                flex
                justify-center
                items-center
                py-16px
                px-4px
                font-normal
                text-14px text-center
              " :class="{ class_active: item.code === search.aimCategory }"
              v-for="item in classList" :key="item.code" @click="selectClass(item)">
              {{ item.value }}
            </div>
          </div>
          <div class="flex-1 bg-white h-full overflow-y-auto">
            <van-search v-model.trim="search.pointCodeOrName" placeholder="请输入点位名称、编码关键字"
              @input="searchPoint" @clear="clearSearch" class="search-wrapper_point" />
            <div class="point_main overflow-y-auto">
              <van-list class="mb-8px" v-model="loading" :finished="finished"
                :immediate-check="false" @load="onLoad">
                <!-- 每一个点位 -->
                <div class="px-16px py-10px flex" v-for="item in childrenData" :key="item.code"
                  @click="childrenSelect(item)">
                  <div class="flex-1 point_left">
                    <div class="
                        point_name
                        text-18px
                        font-normal
                        text-black
                        truncate
                      ">
                      {{ item.name }}
                    </div>
                    <div class="point_subtitle point_code text-16px truncate">
                      {{ item.code }}
                    </div>
                    <div class="point_subtitle point_code text-16px truncate">
                      {{ item.location || "-" }}
                    </div>
                  </div>
                  <div class="w-24px flex justify-end items-center">
                    <img v-if="item.select" width="24px" src="../../asset/icon_right_blue@2x.png"
                      alt="" />
                  </div>
                </div>
              </van-list>
              <div class="text-center text-gray-300" v-if="!childrenData.length">
                —— 无数据 ——
              </div>
            </div>
          </div>
        </div>
      </div>
      <transition name="translateY">
        <div v-if="this.$store.state.pointStorage.pointList.length" class="
            confirm_icon
            fixed
            right-16px
            bottom-80px
            flex flex-col
            justify-center
            items-center
            rounded-full
            w-64px
            h-64px
          " @click.prevent="confirmBtn">
          <div class="text-white text-14px leading-20px">
            ({{ this.$store.state.pointStorage.pointList.length }})
          </div>
          <div class="text-white text-16px leading-24px">确定</div>
        </div>
      </transition>
  </div>
</template>

<script>
import api from '@/api/index'
// import { initoffline } from '@/utils/offline'
import { getPointListOfflineApiHandler } from '@/plugins/offlineMixin/offlineApiHandlers/GetPointOfflineListHandlers'
import _ from 'lodash'

export default {
  name: 'PointList',
  data () {
    return {
      onloadLock: false,
      loading: false,
      finished: false,
      projectCodes: [],
      navBarHeight: 0,
      searchHeight: 0,
      showPointList: false,
      classList: [], // 分类列表
      childrenData: [], // 右侧点位列表
      search: {
        aimCategory: '', // 左侧分类
        pointCodeOrName: '', // 搜索框输入内容
        projectCodes: [], // dashboard页面选中的项目
        limit: 100,
        page: 1
      },
      childrenDataBak: [], // 右侧数据备份
      offlineData: [],
      checkIcon: 'multi-default'
    }
  },
  computed: {
    isOffline () {
      return this.$store.getters.getIsOffline
    }
  },
  created () {},
  async mounted () {
    this.getProjectCodes()
    if (this.isOffline) {
      // 在线
      this.getClass()
    } else {
      this.offlineData = await this.$apiOffline({ offlineApiHandlers: getPointListOfflineApiHandler, forceOffline: true })
      console.log('this.offlineData: ', this.offlineData)
      if (this.offlineData && this.offlineData.length > 0) {
        this.calcOfflineData()
      }
    }
    this.clacHeight()
  },
  methods: {
    getProjectCodes () {
      const { projectCodes } = this.$route.params
      this.search.projectCodes = projectCodes
    },
    async onLoad () {
      if (this.isOffline) {
        if (this.onloadLock) {
          this.loading = false
          return
        }
        // 在线获取右侧点位
        this.search.page++
        const data = await this.$http.post(api.findPointInfoReq, this.search, false)

        this.childrenTotal = data.total
        // vuex中的数据code组成的arr
        const vuexCodeArr = (this.$store.state.pointStorage.pointList || []).map(i => { return i.code })
        ;(data.data || []).forEach(i => { i.select = vuexCodeArr.includes(i.code) })
        this.childrenData.push(...data.data)
        this.loading = false
        // 当加载完全部数据时，下滑load时不再继续加载数据
        if (this.childrenData.length >= this.childrenTotal) {
          this.finished = true
        }
      } else {
        this.finished = true
      }
    },
    calcOfflineData () { // 离线状态获取数据
      this.classList = this.lodash.cloneDeep(this.offlineData)
      if (!this.search.aimCategory) {
        this.search.aimCategory = ''
        this.search.aimCategory = this.offlineData[0].code
      } else {
        let old = this.lodash.cloneDeep(this.search.aimCategory)
        this.search.aimCategory = ''
        this.search.aimCategory = old
      }
    },
    async confirmBtn () { // 确定处理数据
      let res = this.lodash.cloneDeep(this.$store.state.pointStorage.pointList)
      res = res.map(i => { return i.code })
      this.$router.push({
        path: '/CodeOrderList',
        query: {
          codes: res.join(','),
          qrCode: res.join(',')
        }
      })
    },
    clacHeight () { // 计算右侧点位部分高度
      this.$nextTick(() => {
        this.navBarHeight = document.querySelector('.van-nav-bar')?.offsetHeight
        this.searchHeight = document.querySelector('.search-wrapper_point')?.offsetHeight
        if (document.querySelector('.point_main')) {
          document.querySelector('.point_main').style.height = `calc(100vh - ${this.navBarHeight}px - ${this.searchHeight}px)`
        }
        if (document.querySelector('.class_main')) {
          document.querySelector('.class_main').style.height = `calc(100vh - ${this.navBarHeight}px)`
        }
      })
    },
    async getClass () { // 获取左侧分类
    // 影响IOS在订单详情时切换到有网时卡顿滑不动
      this.$parent.stepShow === 0 && this.$toast.loading({
        message: '加载中...',
        forbidClick: true
      })
      const obj = { projectCodes: this.search.projectCodes }
      const data = await this.$http.post(api.getAuditListReq, obj, false)
      this.classList = data
      if (data?.length) {
        if (this.search.aimCategory) {
          let old = this.lodash.cloneDeep(this.search.aimCategory)
          this.search.aimCategory = ''
          this.search.aimCategory = old
        } else {
          this.search.aimCategory = ''
          this.search.aimCategory = data[0].code
        }
      }
    },
    async getPointInfo () { // 获取右侧点位
      if (this.isOffline) {
        // 在线获取右侧点位
        const { data } = await this.$http.post(api.findPointInfoReq, this.search, false)

        // vuex中的数据code组成的arr
        const vuexCodeArr = (this.$store.state.pointStorage.pointList || []).map(i => { return i.code })
      ;(data || []).forEach(i => { i.select = vuexCodeArr.includes(i.code) })
        this.childrenData = data
        this.onloadLock = false
      } else {
        // 离线获取右侧点位,离线清空输入框会进入到这里
        // getOfflinPointInfo在切换分类时触发，将对应分类的点位完整备份在data中(childrenDataBak)
        // 清空时将childrenDataBak还原给childrenData用于渲染
        this.childrenData = this.lodash.cloneDeep(this.childrenDataBak)
      }
      this.calcCheckImg()
    },
    getOfflinPointInfo (code = this.search.aimCategory) { // 离线右侧点位
      ;(this.classList || []).forEach(i => {
        if (i.code === code) {
          // 渲染用的给一份，备份一份用于还原数据
          this.childrenData = i.pointAreaResps || []
          this.childrenDataBak = this.childrenData
        }
      })
    },
    goBack () { // 返回上一级
      this.$store.commit('setPointList', [])
      // 清除当前分类的勾选，其他分类勾的会在用户切换过去的时候，因为vuex没有值而自动取消
      ;(this.childrenData || []).forEach(i => { i.select = false })
      Object.assign(this, this.$options.data())
      this.$router.back()
    },
    selectClass (item) { // 左侧点击高亮
      this.onloadLock = true
      this.loading = false
      this.finished = false
      this.search.page = 1
      this.childrenData = []
      this.search.pointCodeOrName = this.$options.data().search.pointCodeOrName
      this.search.aimCategory = item.code
    },
    childrenSelect (item) { // 点击二级数据
      item.select = !item.select
      const arr = this.$store.state.pointStorage.pointList || []
      if (item.select) {
        arr.push(item)
        this.$store.commit('setPointList', arr)
      } else {
        this.$store.commit('setPointList', arr.filter(i => i.code !== item.code))
      }
      this.calcCheckImg()
    },
    batchSelection () { // 全选按钮
      if (!this.childrenData?.length) return
      // 是否已经全选当前的子节点
      const allCheck = (this.childrenData || []).every(i => i.select)
      // vuex中的列表
      const vuexData = this.lodash.cloneDeep(this.$store.state.pointStorage.pointList)
      // 要给vuex提交的最终数组
      let arr = []
      if (allCheck) {
        // 如果已经全选，就把当前页的全取消勾选，
        (this.childrenData || []).forEach(i => {
          i.select = false
          this.lodash.remove(vuexData, (n) => { return n.code === i.code })
          arr = vuexData
        })
      } else {
        (this.childrenData || []).forEach(i => { i.select = true })
        // 合并vuex列表和当前页面上的二级菜单
        const mergeArr = vuexData.concat(this.childrenData || [])
        // 合并后去重
        arr = this.lodash.uniqBy(mergeArr, 'code')
      }
      this.$store.commit('setPointList', arr)
      this.calcCheckImg()
    },
    // loadsh this调用会报错所以需要引入进来，里面不能用箭头函数不然this会有问题
    searchPoint: _.debounce(async function () {
      console.log(this.search.pointCodeOrName)
      if (!this.search.pointCodeOrName.trim()) {
        this.clearSearch()
      } else {
        // 进入这里代表要查找
        if (this.isOffline) {
          // 在线查
          const obj = this.lodash.cloneDeep(this.search)
          console.log('obj: ', obj)
          obj.page = 1
          obj.limit = 99999
          const { data } = await this.$http.post(api.findPointInfoReq, obj)
          this.childrenData = data
          this.finished = true
        } else {
          // 离线查
          // 查的时候不操作bak数据，保证数据完整，操作深拷贝出来的
          const arr = this.lodash.cloneDeep(this.childrenDataBak)
          this.childrenData = (arr || []).filter(i => {
            return (i.code.toLowerCase().includes(this.search.pointCodeOrName.toLowerCase()) || i.name.toLowerCase().includes(this.search.pointCodeOrName.toLowerCase()))
          })
        }
      }
    }, 1000),
    clearSearch () { // 清空输入框
      this.finished = false
      this.search.page = 1
      this.getPointInfo()
    },
    calcCheckImg () { // 判断全选左侧的图标
      const allCheck = (this.childrenData || []).every(i => i.select)
      const allNotCheck = (this.childrenData || []).every(i => !i.select)
      if (!this.childrenData.length) {
        this.checkIcon = 'multi-default'
      } else if (allCheck) {
        this.checkIcon = 'multi-all'
      } else if (allNotCheck) {
        this.checkIcon = 'multi-default'
      } else {
        this.checkIcon = 'multi-check'
      }
    }
  },
  watch: {
    /**
     * 监听分类发生变化
     * 数据无论在线离线
     * 这个值默认都是空，后赋值的
     * 实现自动查询右侧数据
     */
    'search.aimCategory': {
      handler (newVal) {
        if (this.isOffline) {
          // 在线：分类发生变化
          newVal && this.getPointInfo()
        } else {
          // 离线：分类发生变化
          newVal && this.getOfflinPointInfo()
        }
        this.calcCheckImg()
      },
      deep: true
    },
    async isOffline (val) {
      if (!val) {
        this.offlineData = await this.$apiOffline({ methods: 'post', api: api.getOrderDetail, params: { offline: false }, offlineApiHandlers: getPointListOfflineApiHandler })
        if (this.offlineData && this.offlineData.length > 0) {
          this.calcOfflineData()
        } else {
          this.classList = []
          this.childrenData = []
        }
      } else {
        this.search.page = 1
        this.getClass()
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import url("./index.less");
</style>
