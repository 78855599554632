<!--工单类型 目录 查看 <东E管-工单类型-特殊类型.md>-->
<template>
  <div
    class="order-detail"
    ref="orderDetail"
  >
    <my-nav
      :title="$route.query.checkNo ? '任务抽查结果详情' : '工单详情'"
      :self-back="true"
      @back="goBack"
    >
      <div class="icon-wrapper" slot="right">
        <!-- v-if 意为 升级工单展示逻辑
            1、工单属性为自查设施设备3 或 客户设施设备6
            2、工单属性不是升级工单 或 差评工单
            3、没有关联升级工单
            3、在处理中 -->
        <van-icon
          name="upgrade"
          @click="intoCirculation(upgradeButton)"
          v-if="
            getOrderTypeConf.equipmentOrderTypes.includes(detail.order_type) &&
            detail.order_nature !== 4 && detail.order_nature !== 2 &&
            !detail.upgradeOrderNo &&
            detail.template_node_code === 'chulizhong'
          "
        />
        <micro-text
          :ptt="true"
          v-if="isOffline && getOrderTypeConf.taskOrderTypes.includes(detail.order_type) && detail.template_node_code === 'chulizhong'"
          @speakRes="speakRes"
          :black="true"
        />
        <!-- v-if 意为 扫一扫展示逻辑
            1、任务工单
            2、作业方式【标准、分类&标准】不适用
             -->
        <span
          class="icon-code"
          @click="searchCode"
          v-if="
            getOrderTypeConf.taskOrderTypes.includes(detail.order_type) &&
            !(detail.task_type === 3 || detail.task_type === 6) &&
            detail.template_node_code === 'chulizhong'
          "
        ></span>
        <div v-if="detail.forwardFM && (!detail.fmOrderNos || !detail.fmOrderNos.length)" class="icon-forwardfm" @click="forwardFM"></div>
      </div>
    </my-nav>
    <van-tabs
      animated
      color="#3366FE"
      sticky
      offset-top="44"
      title-active-color="#3366FE"
      title-inactive-color="#7C7F8A"
      line-height="2px"
      line-width="187px"
      v-model="active"
    >
      <van-tab>
        <template v-slot:title>
          <div class="tab-class">
            <div>工单信息</div>
          </div>
        </template>
        <div
          class="scroll-detail-wrapper"
        >
          <div class="order-cell-title">
            <div class="flex-box">
              <div class="order-number">{{ detail.order_no }}</div>
              <van-tag
                plain
                type="primary"
                color="#999"
                @click="copyOrderNumber(detail.order_no)"
                class="copy-tag"
                >复制</van-tag
              >
              <div
                class="star"
                :class="{ active: detail.isattention === true }"
                @click="attention(detail)"
              ></div>
            </div>
            <div class="status color-blue">{{ detail.node_name }}</div>
          </div>
          <van-row class="order-body-cell" gutter="10">
            <van-col :span="6" class="sub-key">工单属性:</van-col>
            <van-col :span="18" class="order-sub-flex">
              <!-- 工单类型Tag -->
              <van-tag
                type="success"
                :color="getOrderTypeConf.taskOrderTypes.includes(detail.order_type)? '': '#45A2F4'"
                size="medium"
                class="mr-8"
              >
                {{ detail.order_type_name }}
              </van-tag>
              <!-- 工单性质Tag -->
              <van-tag
                :style="`background:${orderNatureConf.tagBacColor}`"
                plain
                type="primary"
                size="medium"
                class="mr-8"
                :color="orderNatureConf.tagColor"
              >
                {{ detail.order_nature_name }}</van-tag
              >
              <!-- 问题分级Tag -->
              <van-tag
                plain
                type="primary"
                size="medium"
                :color="JSON.parse(detail.grade).color"
                v-if="detail.grade"
                class="mr-8"
              >
                {{ JSON.parse(detail.grade).value }}</van-tag
              >
            </van-col>
          </van-row>
                  <!-- 问题原因展示 -->
                <van-row class="order-body-cell" gutter="10" v-if="detail.order_nature === 5">
                  <van-col :span="6" class="sub-key">问题原因:</van-col>
                  <van-col :span="18" class="sub-value">
                    <span>{{ detail.issueReasonName }}</span>
                  </van-col>
                </van-row>
                <!-- v-if 意为 只任务工单显示处理方式 -->
                <van-row
                  class="order-body-cell"
                  gutter="10"
                  v-if="
                    getOrderTypeConf.taskOrderTypes.includes(detail.order_type)
                  "
                >
                  <van-col :span="6" class="sub-key">处理方式:</van-col>
                  <van-col :span="18">
                    <van-tag
                      size="medium"
                      color="rgb(51 102 254 / 10%)"
                      text-color="#3366FE"
                class="mr-8"
                ><span>{{ detail.hander_type_name }}</span></van-tag
              >
              <van-tag
                color="rgb(51 102 254 / 10%)"
                text-color="#3366FE"
                size="medium"
                v-if="detail.in_order !== null"
                ><span>{{
                  detail.in_order === 1 ? "有序" : "无序"
                }}</span>
              </van-tag>
            </van-col>
          </van-row>
          <!-- v-if 意为 普通工单normalOrderTypes的才显示创建人信息 -->
          <van-row
            class="order-body-cell"
            gutter="10"
            v-if="
              getOrderTypeConf.normalOrderTypes.includes(
                detail.order_type
              )
            "
          >
            <van-col :span="6" class="sub-key">创建人:</van-col>
            <van-col :span="18" class="flex-box sub-value">
              <span>{{ detail.create_name }}</span>
              <span class="hpone-icon" v-if="detail.create_phone"></span>
              <span
                class="color-blue"
                v-if="detail.create_phone"
                @click="takePhone(detail.create_phone)"
                >{{ detail.create_phone }}</span
              >
            </van-col>
          </van-row>
          <van-row class="order-body-cell" gutter="10">
            <van-col :span="6" class="sub-key">创建时间:</van-col>
            <van-col :span="18" class="sub-value">
              <span>{{ detail.create_time }}</span>
            </van-col>
          </van-row>
          <template>
            <van-row class="order-body-cell" gutter="10">
              <van-col :span="6" class="sub-key">数据来源:</van-col>
              <van-col :span="18" class="sub-value">
                <span>{{ detail.data_source }}</span>
              </van-col>
            </van-row>
          </template>
          <van-row class="order-body-cell" gutter="10">
            <van-col :span="6" class="sub-key">所在项目:</van-col>
            <van-col :span="18" class="sub-value">
              <span>{{ detail.project_name }}</span>
            </van-col>
          </van-row>
          <!-- v-if 意为 是普通工单就显示所在位置 -->
          <van-row
            v-if="
              getOrderTypeConf.normalOrderTypes.includes(
                detail.order_type
              )
            "
            class="order-body-cell"
            gutter="10"
          >
            <van-col :span="6" class="sub-key">所在位置:</van-col>
            <van-col
              :span="18"
              class="sub-value"
              @click="goDetail(detail)"
            >
              <!-- color-blue 意为 设施设备的普通工单,坐在位置显示为蓝色 且可以点击跳转 -->
              <span
                :class="{
                  'color-blue':
                    getOrderTypeConf.equipmentOrderTypes.includes(
                      detail.order_type
                    ),
                }"
                >{{ detail.order_type === 17 ? detail.address : detail.addressName }}</span
              >
            </van-col>
          </van-row>
          <van-row class="order-body-cell" gutter="10">
            <van-col :span="6" class="sub-key">分派人:</van-col>
            <van-col :span="18" class="sub-value">
              <div class="flex-box">
                <span>{{ detail.assign_name }}</span>
                <span
                  class="hpone-icon"
                  v-if="detail.assign_phone"
                ></span>
                <span
                  class="color-blue"
                  @click="takePhone(detail.assign_phone)"
                  >{{ detail.assign_phone }}</span
                >
              </div>
            </van-col>
          </van-row>
          <van-row class="order-body-cell" gutter="10">
            <van-col :span="6" class="sub-key">分派时间:</van-col>
            <van-col :span="18" class="sub-value">
              <span>{{ detail.assign_time }}</span>
            </van-col>
          </van-row>
          <van-row class="order-body-cell" gutter="10">
            <van-col :span="6" class="sub-key">处理人:</van-col>
            <!-- v-if 意为 handler_name不为空显示 by loup annie-->
            <van-col
              :span="18"
              class="sub-value"
              v-if="
                detail.handler_name &&
                getOrderTypeConf.normalOrderTypes.includes(
                  detail.order_type
                )
              "
            >
              <div
                style="display: flex; align-items: center; height: 30px"
                v-for="(item, index) in detail.handler_name.split(',')"
                :key="index"
              >
                <span>{{ item }}</span>
                <!-- v-if 意为 handler_phone不为空显示 by annie-->
                <template v-if="detail.handler_phone">
                  <span class="hpone-icon"></span>
                  <span
                    class="color-blue"
                    @click="
                      takePhone(detail.handler_phone.split(',')[index])
                    "
                    >{{ detail.handler_phone.split(",")[index] }}</span
                  >
                </template>
              </div>
            </van-col>
            <van-col
              :span="18"
              class="sub-value"
              v-if="
                detail.handler_name &&
                getOrderTypeConf.taskOrderTypes.includes(
                  detail.order_type
                )
              "
            >
              <span>{{ detail.handler_name }}</span>
            </van-col>
          </van-row>
          <!-- 处理时限 -->
          <van-row
            class="order-body-cell"
            gutter="10"
            v-if="
              getOrderTypeConf.normalOrderTypes.includes(
                detail.order_type
              )
            "
          >
            <van-col :span="6" class="sub-key">处理时限:</van-col>
            <!-- v-if 意为 问题工单 by annie-->
            <van-col :span="18" class="sub-value">
              <span class="block process_limit">{{
                $moment.formatMinutes(detail.process_limit)
              }}</span>
              <template
                v-if="
                  !(
                    detail.template_node_code === 'yiwancheng' ||
                    detail.template_node_code === 'yiwanjie'
                  )
                "
              >
                <br />
                <span
                  class="color-green process_limit"
                  v-if="detail.remainingTime"
                  >{{ detail.remainingTime }}</span
                >
                <span
                  class="color-red process_limit"
                  v-if="detail.overdueTime"
                  >{{ detail.overdueTime }}</span
                >
              </template>
            </van-col>
          </van-row>
          <!-- v-if 意为 任务工单显示 四个时间 -->
          <template
            v-if="
              getOrderTypeConf.taskOrderTypes.includes(detail.order_type)
            "
          >
            <van-row class="order-body-cell" gutter="10">
              <van-col :span="8" class="sub-key">计划开始时间:</van-col>
              <van-col :span="16" class="sub-value">
                <span>{{ detail.plan_start }}</span>
              </van-col>
            </van-row>
            <van-row class="order-body-cell" gutter="10">
              <van-col :span="8" class="sub-key">计划结束时间:</van-col>
              <van-col :span="16" class="sub-value">
                <span>{{ detail.plan_end }}</span>
              </van-col>
            </van-row>
            <van-row class="order-body-cell" gutter="10">
              <van-col :span="8" class="sub-key">实际开始时间:</van-col>
              <van-col :span="16" class="sub-value">
                <span>{{ detail.actual_start }}</span>
              </van-col>
            </van-row>
            <van-row class="order-body-cell" gutter="10">
              <van-col :span="8" class="sub-key">实际结束时间:</van-col>
              <van-col :span="16" class="sub-value">
                <span>{{ detail.actual_end }}</span>
              </van-col>
            </van-row>
          </template>

          <van-row class="order-body-cell" gutter="10">
            <van-col :span="6" class="sub-key">工单描述:</van-col>
            <van-col :span="18" class="sub-value">
              <!-- 意为差评工单需要特殊显示:问题描述换行显示 -->
              <template
                v-if="detail.order_no && detail.order_no.startsWith('C')"
              >
                <span>{{
                  detail.remark.substring(
                    0,
                    detail.remark.indexOf("问题描述")
                  )
                }}</span>
                <span>{{
                  detail.remark.substring(
                    detail.remark.indexOf("问题描述"),
                    detail.remark.length
                  )
                }}</span>
              </template>
              <span v-else>{{ detail.remark }}</span>
              <!-- v-if 意为 attachment_list存在且长度大于0 就显示图片 -->
              <div
                v-if="
                detail.attachment_list &&
                detail.attachment_list.length > 0 &&
                (isOffline || showDetailImgAfterLoad)"
                class="detail_image_list"
              >
                <van-image
                  width="40"
                  height="40"
                  radius="4"
                  class="photo"
                  v-for="(list, index) of detail.attachment_list"
                  @click="imgPreview(index, detail.attachment_list)"
                  :key="index"
                  :src="isOffline ? list.url : offlineImageBase64[list.url]"
                />
              </div>
            </van-col>
          </van-row>

          <!-- v-if 意为 普通工单显示关联的工单 -->
          <template
            v-if="
              getOrderTypeConf.normalOrderTypes.includes(
                detail.order_type
              )
            "
          >
            <van-row
              class="order-body-cell"
              gutter="10"
              v-if="detail.upgradeOrderNo"
            >
              <van-col :span="6" class="sub-key">
                <span>升级工单:</span>
              </van-col>
              <van-col :span="18" class="order-sub-flex">
                <span
                  class="color-blue"
                  @click="openRelationOrderDetail(detail.upgradeOrderId)"
                  >{{ detail.upgradeOrderNo }}</span
                >
                <van-tag
                  v-if="[0, 1].includes(detail.isEfficacy)"
                  style="background: rgba(253, 141, 55, 0.1);margin-left: 10px;"
                  plain
                  type="primary"
                  size="medium"
                  color="#FD8D37"
                >
                  {{ detail.isEfficacy === 1 ? "有效升级" : "无效升级" }}
                </van-tag>
              </van-col>
            </van-row>
            <van-row
              class="order-body-cell"
              gutter="10"
              v-if="detail.badOrderId"
            >
              <van-col :span="6" class="sub-key">
                <span>差评工单:</span>
              </van-col>
              <van-col :span="18" class="order-sub-flex">
                <span
                  class="color-blue"
                  @click="openRelationOrderDetail(detail.badOrderId)"
                  >{{ detail.badOrderNo }}</span
                >
              </van-col>
            </van-row>
            <van-row
              class="order-body-cell"
              gutter="10"
              v-if="detail.problemOrderNo"
            >
              <van-col :span="6" class="sub-key">
                <span>原工单:</span>
              </van-col>
              <van-col :span="18" class="order-sub-flex">
                <span
                  class="color-blue"
                  @click="openRelationOrderDetail(detail.problemOrderId)"
                  >{{ detail.problemOrderNo }}</span
                >
                <van-tag
                  v-if="[0, 1].includes(detail.isEfficacy)"
                  style="background: rgba(253, 141, 55, 0.1);margin-left: 10px;"
                  plain
                  type="primary"
                  size="medium"
                  color="#FD8D37"
                >
                  {{ detail.isEfficacy === 1 ? "有效升级" : "无效升级" }}
                </van-tag>
              </van-col>
            </van-row>
            <!-- FM转发工单显示原工单 -->
            <van-row
              class="order-body-cell"
              gutter="10"
              v-if="detail.forwardFmOrderNo"
            >
              <van-col :span="6" class="sub-key">
                <span>原工单:</span>
              </van-col>
              <van-col :span="18" class="order-sub-flex">
                <span
                  class="color-blue"
                  @click="openRelationOrderDetail(detail.forwardFmOrderId)"
                  >{{ detail.forwardFmOrderNo }}</span
                >
              </van-col>
            </van-row>
            <!-- 有转发的FM工单显示FM工单 -->
            <van-row
              class="order-body-cell"
              gutter="10"
              v-if="detail.fmOrderNos && detail.fmOrderNos.length > 0"
            >
              <van-col :span="6" class="sub-key">
                <span>FM工单:</span>
              </van-col>
              <van-col :span="18" class="order-sub-flex">
                <div
                  class="color-blue"
                  v-for="order in detail.fmOrderNos"
                  :key="order"
                  @click="openRelationOrderDetail(order.orderId, true)"
                  >{{ order.orderNo }}</div
                >
              </van-col>
            </van-row>
            <!-- v-if 意为 设施设备工单 才会显示主要部件故障原因 -->
            <MyFieldOfMalfunctionReasonSelect
              v-if="
                getOrderTypeConf.equipmentOrderTypes.includes(
                  detail.order_type
                ) &&
                detail.problemOrdersMainReason &&
                detail.problemOrdersMainReason.length > 0
              "
              class="malf_reason_select"
              :problem-list="detail.problemOrdersMainReason"
              :categoryCode="detail.addressCategoryCode"
              :route-name="null"
              :reallyType="detail.addressType"
              :editable="false"
              guide
            />
          </template>
          <!-- 执行项 -->
          <!-- v-if 意为 taskList存在且长度大于0 就显示 -->
          <div
            class="other-list-wrapper"
            v-if="taskList && taskList.length > 0"
          >
            <p class="title_content">
              <span class="title">{{ taskTitle() }}</span>
              <score
                :score="finalScore"
                :total="totalScore"
                :show-txt="true"
                v-if="hasGradeOption"
              ></score>
            </p>
            <div
              class="task-list"
              v-for="(item, index) of taskList"
              :key="item.itemId"
              @click="goList(item, index)"
              @touchstart="taskItemListTouchStart(item, index)"
              @touchmove="taskItemListTouchmove(item)"
              @touchend="taskItemListTouchEnd(item, index)"
            >
              <div
                class="task-info"
                :class="{ disabled: item.gradeStatus === 2 }"
              >
                <p class="task-name">{{ item.itemName }}</p>
                <p
                  class="task-code"
                  v-if="item.taskType !== 3 && item.taskType !== 6"
                >
                  {{ item.qrCode }}
                </p>
                <p
                  class="task-address"
                  v-if="item.taskType !== 3 && item.taskType !== 6"
                >
                  {{ item.addressName }}
                </p>
              </div>
              <template v-if="$route.query.checkNo">
                <span
                  :class="[
                    item.checkResult === 1
                      ? 'text-[#28C469]'
                      : 'text-[#F44A45]',
                  ]"
                >
                  {{ calcQualified(item.checkResult) }}
                </span>
              </template>
              <template v-else>
                <!-- 不适用 -->
                <van-icon name="cross" v-if="item.gradeStatus === 2" />
                <!-- 已完成的任务项 -->
                <template v-else-if="item.status === 1">
                  <!-- 有得分 -->
                  <span v-if="item.finalScore || item.finalScore === 0">
                    <score
                      :score="item.finalScore"
                      :total="item.totalScore"
                    ></score>
                    <!-- 只有处理中的，没有不适用的，且已完成有打分项的，会有校对按钮 -->
                    <span @click.stop="handleProofread(item,index)" v-if="editOrderAuth" class="proofread">校对</span>
                  </span>
                  <!-- 没有得分 -->
                  <template v-else>
                    <span v-if="item.offlineSubmit === 1" class="default-icon active offline"></span>
                    <span v-else class="default-icon active online"></span>
                  </template>
                  <!-- 是否有过打分操作 -->
                  <!-- 没有过打分 -->
                </template>
                <!-- 未完成的任务项 -->
                <span v-else class="default-icon"></span>
              </template>
            </div>
          </div>
        </div>
      </van-tab>
      <van-tab>
        <template v-slot:title>
          <div class="tab-class">
            <div>跟进信息</div>
          </div>
        </template>
        <div
          class="scroll-detail-wrapper"
        >
          <van-steps
            direction="vertical"
            active-color="#969799"
            class="stpe-component"
          >
            <van-step
              class="step"
              v-for="(item, index) of logList"
              :key="index"
            >
              <div class="step-cell">
                <template v-if="item.currNodeId !== item.preNodeId">
                  <span class="color-blue">{{ item.preNodeName }}</span>
                  <em v-if="item.preNodeName" class="icon-arrow-blue"></em>
                  <span
                    class="color-blue"
                    :class="{ 'ml-24': item.preNodeName }"
                    >{{ item.curNodeName }}</span
                  >
                </template>
                <template v-else>
                  <span class="color-blue">{{ item.curNodeName }}</span>
                </template>
                <span class="color-gray"
                  >「{{ item.handlerName
                  }}<span v-if="item.handleMobile"
                    >({{ item.handleMobile }})</span
                  >」</span
                >
              </div>
              <div v-if="item.cuiban" style="margin: 4px 0">
                <van-tag
                  plain
                  color="#FD8D37"
                  size="medium"
                  style="background: rgba(253, 141, 55, 0.1)"
                >
                  <span class="font-bold">催办</span>
                </van-tag>
              </div>
              <div class="step-cell time color-gray">
                {{ item.createTime }}
              </div>
              <div class="step-cell color-black">
                <template v-if="!item.create">
                  {{ item.device
                  }}<span v-if="item.device !== 'APP'">端</span>处理：
                </template>
                {{ item.content }}
              </div>
              <div class="order-photos" v-if="isOffline || showLogImgAfterLoad">
                <van-image
                  width="40"
                  height="40"
                  radius="4"
                  class="photo"
                  v-for="(list, index) of item.imgList"
                  @click="imgPreview(index, item.imgList)"
                  :key="index"
                  :src="isOffline ? list.url : offlineImageBase64[list.url]"
                />
              </div>
              <div class="file-list">
                <div
                  class="list"
                  v-for="(file, index) of item.fileList"
                  :key="file.id + index"
                >
                  <i class="file-icon"></i>
                  <span class="file-name">{{ file.name }}</span>
                </div>
              </div>
              <div class="order-photos" v-if="detail.order_type === 16 && isOffline && item.record && item.record.length">
                <van-image
                  width="40"
                  height="40"
                  radius="4"
                  class="photo"
                  v-for="(list, index) of item.record"
                  @click="imgPreview(index, item.record)"
                  :key="index"
                  :src="list.url"
                />
              </div>
            </van-step>
          </van-steps>
        </div>
      </van-tab>
    </van-tabs>
    <div class="bottom-area" v-if="buttonList && buttonList.length > 0">
      <div
        class="button"
        :class="{
          blue: item.buttonType === 1,
          green: item.buttonType === 2 || item.buttonType === 100002,
          yellow: item.buttonType === 3 || item.buttonType === 100001,
          disabled: item.buttonType === 2 && setNextStatus(),
          'two-button': buttonList.length === 2,
          'one-button': buttonList.length === 1,
          'has-two-row': buttonList.length > 3
        }"
        @click="intoCirculation(item)"
        v-for="(item, index) of buttonList"
        :key="index"
      >
        {{ item.buttonName }}
      </div>
    </div>
    <van-overlay
      :show="phoneShow"
      @click="phoneShow = false"
      class-name="overlay"
      z-index="9999"
    >
      <div class="wrapper" @click.stop>
        <div class="phone-lay">
          <div class="phone-cell" @click="phoneOperate(1)">
            拨打{{ stashPhone }}
          </div>
          <div class="phone-cell" @click="phoneOperate(2)">取消</div>
        </div>
      </div>
    </van-overlay>
    <van-dialog
      v-model="proofreadShow"
      title="得分校对"
      show-cancel-button
      getContainer=".order-detail"
      :before-close="(action, done) => beforeProofreadDialogClose(action, done)"
    >
      <van-form
        ref="proofreadForm"
        validate-first
        class="proofread_content"
        validate-trigger="onSubmit"
      >
        <div class="notice">
          可对最终得分进行校对，调整后会记录在工单详情中，请合理使用！
        </div>
        <div class="proofreadItem_wrapper">
          <div class="proofreadItem" v-for="(item,index) in scoreTaskListOptions" :key="index">
            <div class="checkItemName">{{item.name}}</div>
            <van-field
              :name="`proofreadValue${index}`"
              label=""
              class="proofreadValue"
              :rules="[{ required: true, message: '请输入最终得分' }]"
              center
            >
              <template #input>
                <div class="stepper_content">
                  <van-stepper
                    v-model="item.scoreModel"
                    step="0.5"
                    :max="item.proofreadMaxScore"
                    :min="-9999"
                    theme="round"
                    allow-empty
                    :decimal-length="1"
                  />
                </div>
              </template>
            </van-field>
          </div>
        </div>
        <van-field
          name="proofreadExplain"
          class="proofreadExplain"
          v-model="proofreadExplain"
          type="textarea"
          label=""
          placeholder="输入调整说明，必填"
          clearable
          :rules="[{ required: true, message: '' }]"
        />
      </van-form>
    </van-dialog>
  </div>
</template>

<script>
import { ImagePreview } from 'vant'
import api from '@/api/index'
import { setDetailStar } from '@/utils/offline.js'
import { OssConversionBase64 } from '@/plugins/offlineMixin/utils/offlineImgTool'
import mobile from '@/utils/mobile'
import moment from 'moment'
import MyFieldOfMalfunctionReasonSelect from '@/components/MyFieldOfMalfunctionReasonSelect/index'
import {
  getOrderDetailOfflineApiHandler,
  saveServerOrderDetailOfflineApiHandler,
  setGradeStatusHandler,
  setScoreAdjustsHandler
} from '@/plugins/offlineMixin/offlineApiHandlers/OrderInfoOfflineApiHandlers'
import {
  cleanOrderAfterOfflineSubmitHandler,
  cleanOrderAfterServerSubmitHandler,
  standardOrderSubmitOfflineApiHandler,
  catchOssUploadErrorHandler,
  cleanOrderAfterReCirculation,
  questionOrderSubmitOfflineApiHandler
} from '@/plugins/offlineMixin/offlineApiHandlers/OrderSubmitOfflineApiHandlers'
import { OrderDetailSubmitWrapper } from '@/plugins/offlineMixin/onlineSubmitDataWrappers/OrderSubmitWrapper'
import {
  getCacheCodeHandler,
  setCacheCodeHandler
} from '@/plugins/offlineMixin/offlineApiHandlers/CacheCodeHandler.js'
import { OFFLINE_REQUEST_SUCCESS, ONLINE_REQUEST_SUCCESS } from '@/plugins/offlineMixin/utils/StaticConstantCode'
import { setHistoryListDataHandler } from '@/plugins/offlineMixin/offlineApiHandlers/HistoryOrderListOfflineApiHandlers'
import { mapMutations, mapGetters } from 'vuex'
import score from '@/pages/OrderDetail/component/score'

export default {
  name: 'OrderDetail',
  components: { MyFieldOfMalfunctionReasonSelect, score },
  data () {
    return {
      query: {},
      checkNo: undefined,
      phoneShow: false,
      imagesGroup: [], // 图片查看器需要加载的图片数组
      detail: '', // 实际是model结构
      actualDetail: '', // 实际是detail结构, 包含了model结构
      submitDisable: false,
      upgradeButton: {
        // 升级工单是死数据
        nodeName: '处理中',
        curTemplateNodeCode: 'chulizhong',
        disposeNodeName: '待处理',
        disposeTemplateNodeCode: 'chulizhong',
        buttonName: '升级',
        hiddenState: true,
        buttonType: 100000,
        fieldConfig: [
          { id: 1, ischeck: true, name: 'chuliren' },
          { id: 2, ischeck: true, name: 'sheshishebei' },
          { id: 3, ischeck: true, name: 'zhuyaobujianguzhangyuanyin' }
        ],
        forbidden: true,
        skipPage: 1
      },
      backReportSysButton: {
        nodeName: '处理中',
        curTemplateNodeCode: 'chulizhong',
        disposeNodeName: '完成',
        disposeTemplateNodeCode: 'yijiedan',
        buttonName: '非设备',
        hiddenState: true,
        buttonType: 100001,
        fieldConfig: [{ id: 1, ischeck: true, name: 'backReason' }],
        forbidden: true,
        skipPage: 1
      },
      recheckButton: {
        buttonName: '复核',
        buttonType: 100002,
        skipPage: 1
      },
      logList: [],
      stashPhone: '', // 暂存拨号电话号码
      probleTags: [], // 详情里已经被处理过的问题标签
      taskList: [],
      active: 0,
      totalScore: 0,
      finalScore: 0,
      hasGradeOption: false, // 整个工单的任务项中是否有打分操作
      proofreadShow: false, // 得分校对弹窗show
      proofreadTaskItemIndex: -1, // 得分校对对应的任务项index
      proofreadExplain: '', // 调整说明
      timeOutEvent: 0,
      needAutoToPatrolList: false,
      proofreadMaxScore: null, // 调整分数的最大值
      scoreTaskListOptions: [],
      buttonList: [],
      isRouterToFM: false,
      offlineImageBase64: {},
      showLogImgAfterLoad: false,
      showDetailImgAfterLoad: false
    }
  },
  created () {
    console.log('getOrderTypeConf: ', this.getOrderTypeConf)
    console.log('getOrderNatureDic: ', this.getOrderNatureDic)
  },
  mounted () {
    this.init()
  },
  beforeRouteEnter (to, from, next) {
    next((vm) => {
      console.log('from: ', from)
      if (from.name === 'CodeOrderList' && from.query.isFromScan) {
        // 只有从扫码页面 且 是扫一扫 过来才会自动进执行页
        vm.needAutoToPatrolList = true
      }
    })
  },
  computed: {
    ...mapGetters(['getOrderTypeConf', 'getOrderNatureDic']),
    isOffline () {
      return this.$store.getters.getIsOffline
    },
    orderNatureConf () {
      return (
        this.getOrderNatureDic.find(
          (item) => item.order_nature === this.detail.order_nature
        ) || {}
      )
    },
    // 能处理工单的条件
    editOrderAuth () {
      return (this.detail.handler_id === this.detail.login_user_id) && this.detail.template_node_code === 'chulizhong'
    }
  },
  methods: {
    ...mapMutations(['setDetail', 'setPatroItem']),
    init () {
      this.getOrderDetail()
      console.log(this.$router.history, 'this.$router.history')
      const { isRouterToFM } = this.$route.query
      if (isRouterToFM) {
        this.isRouterToFM = true
      }
    },
    forwardFM () {
      this.$router.push({
        name: 'ReportForward',
        query: {
          attachmentList: this.detail.attachment_list,
          twIncidentId: this.detail.id,
          IncidentNum: this.detail.order_no,
          remark: this.detail.remark,
          CommID: this.detail.project_code
        }
      })
    },
    async getOrderDetail (query) {
      let { orderId, checkNo } = query || this.$route.query
      this.query = { orderId, checkNo }
      if (!orderId) {
        throw new Error('缺少订单ID查询参数')
      }
      console.log('开始在线请求工单详情')
      const data = await this.$apiOffline({
        methods: 'get',
        api: api.getOrderDetail,
        params: { id: orderId, checkNo },
        offlineApiHandlers: getOrderDetailOfflineApiHandler
      })
      console.log('工单详情 data: ', this.lodash.cloneDeep(data))
      console.log('结束在线请求工单详情')
      console.log('开始在线保存工单详情到APP')
      // 在线时工单状态是处理中 / 已接单才缓存
      if (this.isOffline && ['yijiedan', 'chulizhong'].includes(data.model.template_node_code)) {
        await this.$apiOffline({
          params: data,
          offlineApiHandlers: saveServerOrderDetailOfflineApiHandler,
          forceOffline: true
        })
      }
      console.log('结束在线保存工单详情到APP')
      this.initOrderDetail(data)
    },
    initOrderDetail (result) {
      console.log('开始显示工单详情')
      const _this = this
      this.detail = result.model
      this.actualDetail = result
      this.setDetail(result)
      this.taskList = result.orderTaskItemExtModelList || []
      console.log('this.taskList: ', this.taskList);
      (this.taskList.length > 0) && this.getTotalScore()
      this.mergeFMProbleOrderList(result.problemOrders)
      this.logList = result.trace_info
      // FM转发工单需要处理一下，被转发FM过的工单，只显示跟进
      if (this.detail.fmOrderNos && this.detail.fmOrderNos.length > 0) {
        this.buttonList = this.detail.route_list.filter(item => {
          return item.buttonName === '跟进'
        })
      } else {
        this.buttonList = this.detail.route_list || []
      }
      this.addBackButtonOfReportSystem()
      this.addBackButtonOfFMReport()
      this.addRecheckButton()
      if (this.detail.problem_tags) {
        this.probleTags = this.detail.problem_tags.split(',')
      }
      this._initOfflineData()
      this.getDetailProcessList()
      console.log('结束显示工单详情')

      this.$nextTick(() => {
        const orderDetail = document.querySelector('.order-detail')
        const bottomArea = document.querySelector('.bottom-area')
        orderDetail.style.paddingBottom = `${bottomArea.clientHeight}px`
        console.log(
          '_this.$route.query.qrCode: ',
          _this.$route.query.qrCode,
          _this.needAutoToPatrolList
        )
        // order中包含扫码 并且 从扫码页过来 并且 是任务工单
        if (_this.$route.query.qrCode && _this.needAutoToPatrolList && _this.getOrderTypeConf.taskOrderTypes.includes(_this.detail.order_type)) {
          _this.autoOpenTask(_this.$route.query.qrCode)
        }
      })
    },
    // 处理中的工单计算总分和总得分
    getTotalScore () {
      // 任务总分 遍历任务项，取每项打分的总和
      let totalScore = 0
      // 任务得分
      let finalScore = 0
      this.taskList.forEach((taskItem) => {
        let taskItemTotalScore = 0
        let taskHasGradeOption = false
        taskItem.options.forEach((option) => {
          option.typeOptions.forEach((typeOption) => {
            if (typeOption.type === 10) {
              // 是否有打分项
              this.hasGradeOption = true
              taskHasGradeOption = true
              taskItem.gradeStatus !== 2 && (totalScore += typeOption.singleScore * 10)
              taskItemTotalScore += typeOption.singleScore * 10
            }
          })
        })
        // 没有标准不适用的时候
        if (taskItem.gradeStatus !== 2) {
          // 总得分只相加已完成的任务项
          if (taskItem.status === 1) {
            finalScore += taskItem.finalScore * 10
          }
        }
        if (taskHasGradeOption) {
          taskItem.totalScore = taskItemTotalScore / 10
        }
      })
      this.totalScore = totalScore / 10
      this.finalScore = finalScore / 10
      if (this.detail.template_node_code === 'yiwancheng') {
        this.totalScore = this.detail.totalScore
        this.finalScore = this.detail.finalScore
      }
      console.log('this.totalScore: ', this.totalScore)
      console.log('this.finalScore : ', this.finalScore)
      console.log('计算分taskList: ', this.taskList)
    },
    // 如果有带上qrCode,说明需要自动跳转到执行页
    autoOpenTask (qrCode) {
      if (!qrCode) return
      console.log('qrCode: ', qrCode)
      const taskItemIndex = this.taskList.findIndex((item) => {
        return item['itemCode'] === qrCode || item['qrCode'] === qrCode
      })
      console.log('taskItemIndex: ', taskItemIndex)
      if (taskItemIndex === -1) return this.$toast('无匹配二维码，请确认二维码后再试！')
      taskItemIndex > -1 &&
        this.goList(this.taskList[taskItemIndex], taskItemIndex)
    },
    /* 处理工单的处理时限 */
    getDetailProcessList () {
      if (
        this.getOrderTypeConf.normalOrderTypes.includes(
          this.detail.order_type
        ) &&
        !(
          this.detail.template_node_code === 'yiwancheng' ||
          this.detail.template_node_code === 'yiwanjie'
        )
      ) {
        if (!this.detail.plan_end) return
        const m1 = moment(this.detail.plan_end) // 计划结束时间
        const m2 = moment() // 现在的时间
        var duration = moment.duration(m1.diff(m2)) // 现在-计划结束时间
        var minutes = duration.asMinutes()
        if (minutes >= 0) {
          this.detail.remainingTime = `「剩余${this.$moment.formatMinutes(
            Math.floor(minutes)
          )}」`
        } else {
          this.detail.overdueTime = `「超期${this.$moment.formatMinutes(
            Math.floor(minutes * -1)
          )}」`
        }
      }
    },
    openRelationOrderDetail (relationOrderId, isRouterToFM = false) {
      if (!this.isOffline) {
        this.$EB.$emit(this.$EBT.NO_NETWORK_WARN)
        return
      }
      if (this.isRouterToFM) {
        this.$router.back()
        return
      }
      console.log(this.$router.history, 'this.$router.history')
      // this.getOrderDetail({ orderId: relationOrderId })
      // 该方式为了更新查询参数同时防止页面刷新，防止当前页有升级工单切换时流转页返回不到对应的工单详情
      this.$router.push({
        path: '/OrderDetail',
        query: { orderId: relationOrderId, isRouterToFM }
      })
    },
    calcQualified (checkResult) {
      const m = new Map([
        [1, '合格'],
        [0, '不合格']
      ])
      return m.get(checkResult) || ''
    },
    async _initOfflineData () {
      if (this.detail.problem_tags) {
        this.probleTags = this.detail.problem_tags.split(',')
      }
      const IMAGE_TYPE = [
        'jpg',
        'jpeg',
        'png',
        'gif',
        'webp',
        'bmp',
        'svg'
      ]
      // 跟进信息图片数据处理
      for (let item of this.logList) {
        let imgList = []
        let fileList = []
        if (item.attachments && item.attachments.length) {
          for (let list of item.attachments) {
            let UrlSplit = list.url.split('.')
            let type = UrlSplit[UrlSplit.length - 1]
            if (IMAGE_TYPE.includes(type)) {
              imgList.push(list)
              if (!this.isOffline) {
                this.offlineImageBase64[list.url] = await OssConversionBase64(list.url)
              }
            } else {
              fileList.push(list)
            }
          }
        }
        this.$set(item, 'imgList', imgList)
        this.$set(item, 'fileList', fileList)
      }
      this.showLogImgAfterLoad = true
      // 工单详情推按处理
      if (this.detail.attachment_list && this.detail.attachment_list instanceof Array && this.detail.attachment_list.length > 0) {
        for (let list of this.detail.attachment_list) {
          let UrlSplit = list.url.split('.')
          let type = UrlSplit[UrlSplit.length - 1]
          if (IMAGE_TYPE.includes(type)) {
            list.url.replace('http://', 'https://')
            if (!this.isOffline) {
              this.offlineImageBase64[list.url] = await OssConversionBase64(list.url)
            }
          }
        }
        this.showDetailImgAfterLoad = true
      }
    },
    addRecheckButton () {
      if (this.detail.order_type === 17 && this.detail.isRecheck === 1) {
        this.buttonList = [this.recheckButton]
      }
    },
    addBackButtonOfFMReport () {
      if (this.detail.forwardFmOrderNo && (this.detail.template_node_code === 'chulizhong' || this.detail.template_node_code === 'yijiedan')) {
        const backReportSysButton = this.lodash.cloneDeep(this.backReportSysButton)
        backReportSysButton.FM_ORDER_BACK = true
        this.buttonList.push(backReportSysButton)
      }
    },
    addBackButtonOfReportSystem () {
      if (
        this.detail.data_source !== '天问客服报事' ||
        this.detail.template_node_code !== 'yijiedan' ||
        this.detail.order_nature === 4
      ) {
        return
      }
      this.buttonList.push(this.backReportSysButton)
    },
    /* 复制订单号 */
    copyOrderNumber (orderNo) {
      this.$copyText(orderNo).then(
        (e) => {
          this.$notify({
            type: 'success',
            message: '复制成功',
            background: '#13CE66'
          })
        },
        (e) => {
          this.$notify({
            type: 'warning',
            message: '复制成功',
            background: '#FFC82C'
          })
        }
      )
    },
    /* 收藏订单 */
    attention (item) {
      let isAtt = false
      if (item.isattention === true) {
        isAtt = false
      } else {
        isAtt = true
      }
      if (this.isOffline) {
        this.$http
          .post(api.attention, {
            isAttention: isAtt,
            order_no: item.order_no
          })
          .then((res) => {
            const message = isAtt ? '关注成功' : '已取消关注'
            item.isattention = isAtt
            this.$notify({ type: 'success', message })
          })
      } else {
        this.detail.isattention = isAtt
      }
      setDetailStar(this.detail.id, isAtt)
    },
    /* 唤起手机拨号 */
    takePhone (phoneNum) {
      this.stashPhone = phoneNum
      if (mobile.platform === 'IOS') {
        window.location.href = 'tel:' + this.stashPhone
      } else {
        this.phoneShow = true
      }
    },
    /* 拨号调用 */
    phoneOperate (type) {
      if (type === 1) {
        window.location.href = 'tel:' + this.stashPhone
      } else {
        this.phoneShow = false
      }
    },
    /* 图片查看器 */
    imgPreview (index, attachmentList) {
      const imagesGroup = []
      attachmentList && attachmentList.forEach((item) => {
        if (this.isOffline) {
          imagesGroup.push(item.url)
        } else {
          imagesGroup.push(this.offlineImageBase64[item.url])
        }
      })
      ImagePreview({
        images: imagesGroup,
        startPosition: index
      })
    },
    /* 获取跟进记录 */
    getLog () {
      return this.$http
        .get(api.log, {
          orderNo: this.detail.order_no
        })
        .then((res) => {
          this.logList = res
          this.logList.forEach((item) => {
            const IMGTYPE = [
              'png',
              'jpg',
              'jpeg',
              'bmp',
              'gif',
              'webp',
              'PNG',
              'JPG',
              'JPEG',
              'BPM',
              'GIF'
            ]
            let imgList = []
            let fileList = []
            item.attachments &&
              item.attachments.forEach((list) => {
                let typeList = list.url.split('.')
                let type = typeList[typeList.length - 1]
                if (IMGTYPE.includes(type)) {
                  imgList.push(list)
                } else {
                  fileList.push(list)
                }
              })
            item.imgList = imgList
            item.fileList = fileList
          })
        })
    },
    /* 去流转配置 */
    async intoCirculation (buttonInfo, forceOffline = false) {
      const _this = this
      // 当任务未完成时且按钮是提交按钮时不处理，返回
      if (
        (this.setNextStatus() && buttonInfo.buttonType === 2) ||
        this.submitDisable
      ) {
        return
      }
      // 意为 处理中 且 按钮类型为2
      if (
        buttonInfo.buttonType === 2 &&
        buttonInfo.curTemplateNodeCode === 'chulizhong'
      ) {
        // 任务工单需要走提交逻辑
        if (
          this.getOrderTypeConf.taskOrderTypes.includes(this.detail.order_type)
        ) {
          try {
            const result = await this.$apiOffline({
              api: api.submitOffline,
              methods: 'post',
              params: { orderId: this.detail.id, buttonInfo },
              offlineApiHandlers: standardOrderSubmitOfflineApiHandler,
              onlineSubmitDataWrapper: OrderDetailSubmitWrapper,
              forceOffline
            })
            const handler =
              result.code === OFFLINE_REQUEST_SUCCESS
                ? cleanOrderAfterOfflineSubmitHandler
                : cleanOrderAfterServerSubmitHandler
            if (result.code === ONLINE_REQUEST_SUCCESS) {
              await mobile.delImgsByOrderId({ orderId: this.detail.id })
            }
            const submitOrderList = [{
              orderId: this.detail.id,
              isComplete: true,
              orderType: this.detail.order_type
            }]
            await this.$apiOffline({
              params: submitOrderList,
              offlineApiHandlers: handler,
              forceOffline: true
            })
            this.$store.commit('changeDashBoardRefreshMixin')
            this.submitDisable = true
            if (result.code === OFFLINE_REQUEST_SUCCESS) {
              this.$router.back()
            } else {
              this.$toast.success({
                message: '提交成功',
                duration: 1000,
                forbidClick: true,
                onClose: () => {
                  _this.$router.back()
                }
              })
            }
            return
          } catch (error) {
            const { code = '' } = error
            console.log('code: ', code)
            if (code) {
              await this.$apiOffline({
                params: {
                  errorCode: code,
                  buttonInfo,
                  func: this.intoCirculation
                },
                offlineApiHandlers: catchOssUploadErrorHandler,
                forceOffline: true
              })
            }
            // 阻止后面的操作
            throw new Error(error)
          }
        } else {
          // 完成 + 问题工单 不流转到流转页
          if (buttonInfo.skipPage === 1) {
            // 意为 处理中且buttonType===2(去向前流转) 除开任务工单的所有工单
            this.$storage.set('orderDetail', this.detail)
            this.$storage.set('buttonInfo', buttonInfo)
            this.$storage.set('probleTags', this.probleTags)
            this.$router.push({
              name: 'OrderCirculation',
              query: {
                nodeName: buttonInfo.disposeNodeName,
                title: buttonInfo.buttonType === 100000 ? '工单升级' : undefined
              },
              params: { projectCodes: this.detail.project_code }
            })
            return
          } else {
            this.flowOrderHandler(buttonInfo)
            return
          }
        }
      }
      // 离线提示
      if (!this.isOffline) {
        this.$EB.$emit(this.$EBT.NO_NETWORK_WARN)
        return
      }
      // skipPage 1 默认跳转 0 不跳转
      if (buttonInfo.skipPage === 1) { // 20231205加的新需求 允许后台配置不跳转流转页直接流转（到这家公司后遇到的最傻逼最垃圾的需求，没有之一！！运行3年的项目不结合用户，仅仅是产品自己的要求！！）
        this.$storage.set('orderDetail', this.detail)
        this.$storage.set('trace_info', this.actualDetail.trace_info)
        this.$storage.set('buttonInfo', buttonInfo)
        this.$storage.set('probleTags', this.probleTags)
        if (buttonInfo.buttonType === 1) {
          this.$router.push({
            path: '/OrderUrge',
            query: this.query
          })
        } else if (buttonInfo.buttonType === 100002) {
        // 复核
          this.$router.push({
            path: '/RemoteMonitorOrderRecheck',
            query: {
              orderNo: this.detail.order_no
            }
          })
        } else {
          if (
            buttonInfo.curTemplateNodeCode === 'pingjiazhong' &&
          buttonInfo.buttonType !== 3
          ) {
            this.$router.push({
              path: '/OrderUrge',
              query: this.query
            })
          } else {
            if (
              buttonInfo.curTemplateNodeCode === 'pingjiazhong' &&
            buttonInfo.buttonType !== 3
            ) {
              this.$router.push({
                path: '/Evaluation'
              })
            } else {
              this.$router.push({
                name: 'OrderCirculation',
                query: {
                  nodeName: buttonInfo.disposeNodeName,
                  title: buttonInfo.buttonType === 100000 ? '工单升级' : undefined
                },
                params: { projectCodes: this.detail.project_code }
              })
            }
          }
        }
      } else {
        this.flowOrderHandler(buttonInfo)
      }
    },
    async flowOrderHandler (buttonInfo) {
      let flowApi = api.OrderFlow
      let reqSuccessMsg = buttonInfo.buttonType === 1 ? '跟进成功' : '工单流转成功'
      console.log('flowOrderHandler buttonInfo: ', buttonInfo)
      if (buttonInfo.buttonName === '非设备') {
        flowApi = api.orderBackReportSys
        if (buttonInfo.FM_ORDER_BACK) flowApi = api.fmOrderBackReportSys
        reqSuccessMsg = '工单退回成功'
      }
      const _this = this
      const canOfflineSubmit = buttonInfo.buttonType === 2 && buttonInfo.disposeTemplateNodeCode === 'yiwancheng'
      if (!this.isOffline && !canOfflineSubmit) {
        this.$EB.$emit(this.$EBT.NO_NETWORK_WARN)
        return
      }
      const loading = this.$toast.loading({
        duration: 0,
        message: '处理中...',
        forbidClick: true
      })
      // 不到流转页流转，区分按钮（之前是到orderUrge还是orderCirculation）
      const orderFlowForm = {
        handleEnpoint: 'APP',
        buttonName: buttonInfo.buttonName,
        curNodeId: buttonInfo.nodeId,
        commitTime: moment().format('YYYY-MM-DD HH:mm:ss'),
        orderNo: this.detail.order_no,
        orderId: this.detail.id,
        buttonInfo: buttonInfo
      }
      orderFlowForm.bigCategoryCode = this.detail.big_category_code
      orderFlowForm.bigCategoryName = this.detail.big_category_name
      orderFlowForm.smallCategoryCode = this.detail.small_category_code
      orderFlowForm.smallCategoryName = this.detail.small_category_name
      try {
        const result = await this.$apiOffline({
          api: flowApi,
          methods: 'post',
          params: { ...orderFlowForm },
          showLoading: false,
          offlineApiHandlers: canOfflineSubmit
            ? questionOrderSubmitOfflineApiHandler
            : null
        })
        console.log('>>>>>flowOrderHandler 中 流转完成返回result：', result)
        if (!canOfflineSubmit) {
          await this.$apiOffline({
            params: this.detail,
            offlineApiHandlers: setHistoryListDataHandler,
            forceOffline: true
          })
          // 逆扭转 处理中--->待处理
          if (buttonInfo.buttonType === 3) {
            await this.$apiOffline({
              params: [this.detail.id],
              offlineApiHandlers: cleanOrderAfterReCirculation,
              forceOffline: true
            })
          }
        } else {
          // 问题工单和升级工单完成需要删除缓存和近三天记录
          const handler =
          result.code === OFFLINE_REQUEST_SUCCESS
            ? cleanOrderAfterOfflineSubmitHandler
            : cleanOrderAfterServerSubmitHandler
          await this.$apiOffline({
            params: [this.detail.id],
            offlineApiHandlers: handler,
            forceOffline: true
          })
        }
        if (result.code === ONLINE_REQUEST_SUCCESS) {
          await mobile.delImgsByOrderId({ orderId: this.detail.id })
        }
        // 返回刷新主页
        this.$store.commit('changeDashBoardRefreshMixin')
        this.$store.commit('changeDashBoardRefreshMixin')
        if (result.code === OFFLINE_REQUEST_SUCCESS) {
          setTimeout(() => {
            this.$router.go(-1)
          }, 500)
        } else {
          console.log('reqSuccessMsg: ', reqSuccessMsg)
          this.$toast.success({
            message: reqSuccessMsg,
            duration: 1500,
            forbidClick: true,
            onClose: () => {
              _this.init()
            }
          })
        }
      } catch (error) {
        console.error('error: ', error)
        loading && loading.clear()
        this.submitLoading = false
      }
    },
    // 设置任务工单标题
    taskTitle () {
      let text = ''
      let obj = {
        1: '点位/设施设备列表',
        2: '点位/设施设备列表',
        3: '标准列表',
        4: '点位/设施设备列表',
        5: '点位/设施设备列表',
        // 【分类&标准】标准作业程序task_type为6
        6: '点位/设施设备分类'
      }
      if (!this.detail.task_type) {
        text = '暂无'
      } else {
        text = obj[this.detail.task_type]
      }
      return text
    },
    setNextStatus () {
      let status = false
      if (!this.isOffline) return true
      if (
        this.taskList.length > 0 &&
        this.detail.template_node_code === 'chulizhong'
      ) {
        for (let item of this.taskList) {
          if (item.status === 0 && item.gradeStatus !== 2) {
            status = true
            break
          }
        }
      }
      return status
    },
    async goList (item, index) {
      if (this.detail.is_before_handler === 1) {
        if (
          this.detail.template_node_code === 'yijiedan' ||
          this.detail.template_node_code === 'daichuli'
        ) {
          let preId = [] // 处理预处理人数据
          if (this.detail.pre_handlers) {
            preId = this.detail.pre_handlers.split(',')
          } // 将预处理人改为数组
          if (
            preId.includes(String(this.detail.login_user_id)) ||
            this.detail.handler_id === this.detail.login_user_id
          ) {
            // 预处理人中包含本人或处理人等于本人时 则跳转到开始处理流转
            if (!this.isOffline) {
              // 离线状态下不可进行工单流转
              this.$EB.$emit(this.$EBT.NO_NETWORK_WARN)
              return
            }
            const startHandlerButton = this.detail.route_list.find(i => i.disposeTemplateNodeCode === 'chulizhong') // 下一步是开始处理
            if (startHandlerButton && startHandlerButton.skipPage === 0) {
              return this.$toast('请点击开始处理后再操作！')
            }
            this.$storage.set('orderDetail', this.detail)
            const toHandleButton = this.buttonList.find(item => item.buttonType === 2 && item.disposeTemplateNodeCode === 'chulizhong')
            console.log('toHandleButton: ', toHandleButton)
            if (toHandleButton) {
              this.$storage.set('buttonInfo', toHandleButton) // buttonList[1]就是开始处理的数据要传到流转页面
              this.$router.push({
                name: 'OrderCirculation',
                query: { nodeName: '开始处理' },
                params: { projectCodes: this.detail.project_code }
              })
            } else {
              this.$toast('无法开始处理，请联系管理员！')
            }
            return
          }
          if (this.detail.handler_id !== this.detail.login_user_id) {
            // 当为已接单状态，但是处理人不是本人时查看详情页
            this._setOrderDetai(item, index)
            return
          }
        }
        if (this.detail.template_node_code === 'daifenpai') {
          this._setOrderDetai(item, index)
          return
        }
      }
      // 非处理人需要检查列表完成后才可查看
      if (
        this.detail.handler_id !== this.detail.login_user_id &&
        item.status === 0 // 同待分配
      ) {
        this._setOrderDetai(item, index)
        return
      }
      if (
        index === 0 ||
        this.taskList[index - 1].status === 1 ||
        this.detail.in_order !== 1
      ) {
        if (
          moment().valueOf() >
          this.$moment.timeToTimestamp(this.detail.plan_start)
        ) {
          if (this.detail.template_node_code !== 'chulizhong') {
            // 只有处理中才要扫码进入扫码操作
            this._setOrderDetai(item, index)
            return
          }
          // 点位完成不需要调取二维码操作
          if (item.status === 1) {
            this._setOrderDetai(item, index)
          } else {
            if (this.detail.hander_type === '2') {
              this._setOrderDetai(item, index)
            } else {
              // 当该点位在30分钟之类再次被扫码就不需要进行扫码操作
              const { order_type } = this.detail
              // 设施设备类的工单必须用qrCode
              let qrCode = this.getOrderTypeConf.taskOrderTypes_FM.includes(
                order_type
              )
                ? item.qrCode
                : item.itemCode
              const cacheCode = await this.$apiOffline({
                offlineApiHandlers: getCacheCodeHandler,
                forceOffline: true
              })
              console.log('cacheCode: ', cacheCode)
              const isNoNeedScan =
                cacheCode[qrCode] &&
                30 * 60 * 1000 >
                  moment().valueOf() -
                    moment(cacheCode[qrCode], 'YYYY-MM-DD HH:mm:ss').valueOf()
              console.log('isNoNeedScan: ', isNoNeedScan)
              if (isNoNeedScan) {
                this._setOrderDetai(item, index)
                return
              }
              const res = await mobile.getQRCodeInfo()
              if (res === qrCode) {
                await setCacheCodeHandler({
                  code: res,
                  time: this.$moment.timestampToYMDHms()
                })
                this._setOrderDetai(item, index)
              } else {
                this.$toast('二维码不匹配，请确认匹配后再试！')
              }
            }
          }
        } else {
          this._setOrderDetai(item, index)
        }
      } else {
        // 当是有序时，执行时按顺序执行工单，计划开始时间没到时可查看详情
        if (
          moment().valueOf() <
          this.$moment.timeToTimestamp(this.detail.plan_start)
        ) {
          this._setOrderDetai(item, index)
        } else {
          if (this.detail.template_node_code !== 'chulizhong') {
            this._setOrderDetai(item, index)
          } else {
            this.$toast(
              `当前任务要求有序执行，请按照${this.taskTitle()}顺序依次执行`
            )
          }
        }
        console.log(
          'this.detail.template_node_code: ',
          this.detail.template_node_code
        )
      }
    },
    routerToPatroList (patroItem, object = {}) {
      this.setPatroItem(patroItem)
      // 跳转执行页面
      const query = {
        checkNo: this.$route.query.checkNo,
        patroItemId: patroItem.itemId,
        orderId: this.detail.id,
        template_node_code: this.detail.template_node_code,
        status: patroItem.status
      }
      this.$router.push({
        path: '/PatrolList',
        query: Object.assign(query, object)
      })
    },
    // 设置一些信息 用于跳转到执行页
    async _setOrderDetai (item) {
      console.log('item: ', item)
      console.log('this.detail: ', this.detail)
      // 点击任务项，进任务项前清空vuex的任务项，避免串任务！
      this.setPatroItem({})
      // 设置近三天数据
      await this.$apiOffline({
        params: this.detail,
        offlineApiHandlers: setHistoryListDataHandler,
        forceOffline: true
      })
      console.log('设置进三天成功')
      if (!(this.detail.template_node_code === 'chulizhong')) {
        this.setPatroItem(item)
      }
      if (this.detail.order_type === 16) {
        if (this.isOffline) {
          if (this.detail.template_node_code === 'chulizhong' && item.status === 0) {
            this.handleGetIsCaptureSuccess(item)
          } else {
            this.routerToPatroList(item)
          }
        } else {
          this.$EB.$emit(this.$EBT.NO_NETWORK_WARN)
        }
      } else {
        this.routerToPatroList(item)
      }
    },
    async handleGetIsCaptureSuccess (taskItem) {
      let result = await this.$http.post(this.$api.getIsCaptureSuccess, {
        orderNo: this.detail.order_no,
        pointCode: taskItem.itemCode
      })
      console.log(result)
      if (result) {
        this.routerToPatroList(taskItem, { captureImageUrl: result })
      } else {
        this.handleErrorCaptureDialog(taskItem)
      }
    },
    handleErrorCaptureDialog (taskItem) {
      this.$dialog.confirm({
        title: '抓图失败',
        message: '请点击下方【重新抓图】按钮进行抓拍。若多次抓拍失败，请检查监控点位是否离线。',
        confirmButtonText: '重新抓图',
        beforeClose: (action, done) => {
          if (action === 'confirm') {
            this.$http.post(this.$api.orderTaskCapture, {
              orderNo: this.detail.order_no,
              pointCode: taskItem.itemCode
            }, false).then(result => {
              console.log(result)
              if (result) {
                this.$toast('抓取成功')
                // 跳转执行页面
                this.routerToPatroList(taskItem, { captureImageUrl: result })
                done()
              } else {
                this.$toast('抓取失败')
                done(false)
              }
            }).catch(() => {
              done(false)
            })
          } else {
            done()
          }
        }
      })
    },
    mergeFMProbleOrderList (problemOrders) {
      // 故障原因显示
      console.log('---problemOrders23333: ', problemOrders)
      console.log(typeof problemOrders)
      if (
        problemOrders &&
        problemOrders.length > 0 &&
        problemOrders[0].problemOrderLabels
      ) {
        this.$set(
          this.detail,
          'problemOrders',
          problemOrders.reduce((arr, item) => {
            return arr.concat(item.problemOrderLabels || [])
          }, [])
        )
      }
    },
    goDetail (value) {
      // 意为 公区工单不可以点击跳转
      if (
        this.getOrderTypeConf.publicAreaOrderTypes.includes(
          this.detail.order_type
        )
      ) {
        return
      }
      // 无网不能点击
      if (!this.isOffline) {
        this.$EB.$emit(this.$EBT.NO_NETWORK_WARN)
        return
      }
      this.$router.push({
        path: '/QRCodeScan/QRCodeRegister',
        query: {
          type: value.addressType,
          qrCode: value.address_id,
          isOrder: true
        }
      })
    },
    goBack () {
      if (this.$route.query.link) {
        window.location.href =
          this.$route.query.link +
          '/#' +
          '?jump=' +
          this.$route.query.jump +
          '&beforeJump=' +
          this.$route.query.beforeJump +
          '&level=' +
          this.$route.query.level
      } else {
        this.$router.back()
      }
    },
    // 二维码扫码搜索点位信息
    async searchCode () {
      const res = await mobile.getQRCodeInfo()
      console.log('res: ', res)
      await setCacheCodeHandler({
        code: res,
        time: this.$moment.timestampToYMDHms()
      })
      this.autoOpenTask(res)
    },
    speakRes (text) {
      console.log('语音识别text: ', text)
      // 通过匹配名称，打开执行页
      const taskItemIndex = this.taskList.findIndex((item) => {
        // 设施设备分类仅匹配最后一级名称，
        const lastItemName = item.itemName.split('-')
        return lastItemName[lastItemName.length - 1] === text
      })
      if (taskItemIndex === -1) return this.$toast('无匹配任务名称，请重新再试！')
      taskItemIndex > -1 &&
        this.goList(this.taskList[taskItemIndex], taskItemIndex)
    },
    async beforeProofreadDialogClose (action, done) {
      if (action === 'confirm') {
        try {
          const validateNames = this.scoreTaskListOptions.map((item, index) => {
            return `proofreadValue${index}`
          })
          validateNames.push('proofreadExplain')
          const result = await this.$refs.proofreadForm.validate(validateNames)
          console.log('result: ', result)
          // 做相同判断,如果没有检查项的分数改变，就提示请调整分数
          const adjustScoreOptions = this.scoreTaskListOptions.filter(item => {
            return item.scoreModel * 1 !== item.score * 1
          })
          console.log('adjustScoreOptions: ', adjustScoreOptions)
          if (adjustScoreOptions.length === 0) {
            done(false)
            return this.$toast('未做分数调整，请调整分数！')
          }
          const scoreAdjust = {
            adjustContent: this.proofreadExplain,
            adjustTime: this.$moment.timestampToYMDHms(),
            checkScoreAdjusts: adjustScoreOptions.map(option => {
              return {
                checkName: option.name,
                beforeScore: option.score * 1,
                adjustScore: option.scoreModel * 1,
                optionIndex: option.index
              }
            })
          }
          const data = await this.$apiOffline({
            forceOffline: true,
            params: { orderId: this.detail.id, scoreAdjust, itemIndex: this.proofreadTaskItemIndex },
            offlineApiHandlers: setScoreAdjustsHandler
          })
          if (data) {
            this.proofreadExplain = undefined
            this.taskList = data
            this.getTotalScore()
            done()
          }
        } catch (error) {
          console.log('error: ', error)
          done(false)
        }
      } else {
        this.scoreTaskListOptions = []
        this.proofreadExplain = undefined
        done()
      }
    },
    taskItemListTouchStart (taskItem, index) {
      // 作业方式【标准、分类&标准】支持长按任务项，弹出不适用确认框
      let _this = this
      this.timeOutEvent = setTimeout(function () {
        if (_this._longPressValidate(taskItem)) return
        _this.changeTaskItemGradeStatus(taskItem, index)
      }, 800) // 这里设置定时器，定义长按500毫秒触发长按事件
      return false
    },
    taskItemListTouchmove (taskItem) {
      clearTimeout(this.timeOutEvent) // 清除定时器
      this.timeOutEvent = 0
    },
    taskItemListTouchEnd (taskItem, index) {
      clearTimeout(this.timeOutEvent)
      return false
    },
    _longPressValidate (taskItem) {
      // return true 代表不能长按
      // 有处理权限才能长按不适用
      if (!this.editOrderAuth) {
        return true
      }
      // 作业方式【标准、分类&标准】支持长按任务项，弹出不适用确认框
      if (taskItem.taskType !== 3 && taskItem.taskType !== 6) {
        this.$toast('该作业方式不允许不适用操作！')
        return true
      }
      // 已经不适用的不能再长按
      if (taskItem.gradeStatus === 2) {
        this.$toast('该项已不适用，无需再次操作！')
        return true
      }
      // 如果当前任务项已经校对过,不能再不适用操作
      if (taskItem.scoreAdjusts) {
        this.$toast('该项已校对分数，不能进行不适用操作!')
        return true
      }
      return false
    },
    changeTaskItemGradeStatus (taskItem, index) {
      this.timeOutEvent = 0
      const _this = this
      console.log('taskItem,index: ', taskItem, index)
      this.$dialog
        .confirm({
          title: '标准不适用',
          message: `确定「${taskItem.itemName}」在「${this.detail.project_name}」不适用，确定后不计入最终得分，请合理操作！`,
          confirmButtonText: '确定'
        })
        .then(async () => {
          const data = await this.$apiOffline({
            forceOffline: true,
            params: { orderId: _this.$route.query.orderId, taskIndex: index },
            offlineApiHandlers: setGradeStatusHandler
          })
          _this.$set(_this, 'taskList', data)
          console.log('this.taskList: ', _this.taskList)
          _this.getTotalScore()
        })
    },
    handleProofread (taskItem, taskItemIndex) {
      const taskItemCopy = this.lodash.cloneDeep(taskItem)
      const scoreTaskListOptions = taskItemCopy.options.filter((option, index) => {
        option.index = index
        return option.typeOptions.some(typeOption => typeOption.type === 10)
      })
      scoreTaskListOptions.forEach(option => {
        const scoreTypeOption = option.typeOptions.find(typeOption => typeOption.type === 10)
        option.score = scoreTypeOption.val
        option.scoreModel = scoreTypeOption.val
        option.proofreadMaxScore = scoreTypeOption.singleScore
      })
      this.scoreTaskListOptions = scoreTaskListOptions
      this.proofreadTaskItemIndex = taskItemIndex
      this.proofreadShow = true
    }
  }
}
</script>

<style lang="less" scoped>
@import url("./index.less");
</style>
