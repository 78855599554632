var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ReportOrderPool" },
    [
      _c("my-nav", {
        attrs: { blue: "", title: "工单池", "self-back": true },
        on: { back: _vm.goBack },
      }),
      _c(
        "van-sticky",
        { attrs: { "offset-top": _vm.navBarHeight } },
        [
          _vm.searchParams.incidentDealStateName !== "未分派"
            ? _c(
                "div",
                { staticClass: "filter-wrapper" },
                [
                  _c(
                    "van-search",
                    {
                      attrs: { placeholder: "报事位置", "read-only": "" },
                      on: { click: _vm.onSearch },
                      model: {
                        value: _vm.searchParams.positionName,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "positionName", $$v)
                        },
                        expression: "searchParams.positionName",
                      },
                    },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "right-icon" }, slot: "right-icon" },
                        [
                          _vm.searchParams.positionName
                            ? _c("van-icon", {
                                attrs: { name: "clear" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.onSearchClear.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "filter-tag" }, [
                    _c(
                      "div",
                      {
                        staticClass: "tag",
                        class: { active: _vm.searchParams.isHandler === 1 },
                        on: {
                          click: function ($event) {
                            return _vm.changeHandleOrSupervise("isHandler")
                          },
                        },
                      },
                      [_vm._v(" 我处理 ")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "tag",
                        class: { active: _vm.searchParams.isSuperVise === 1 },
                        on: {
                          click: function ($event) {
                            return _vm.changeHandleOrSupervise("isSuperVise")
                          },
                        },
                      },
                      [_vm._v(" 我监管 ")]
                    ),
                  ]),
                  _c("van-badge", { attrs: { dot: _vm.hasFilter } }, [
                    _c("div", {
                      staticClass: "filter-entry",
                      on: {
                        click: function ($event) {
                          _vm.filterPopupShow = true
                        },
                      },
                    }),
                  ]),
                ],
                1
              )
            : _c(
                "section",
                { staticClass: "tag-wrapper" },
                _vm._l(_vm.checkTypes, function (item, index) {
                  return _c(
                    "span",
                    {
                      key: index,
                      staticClass: "ordercheck-type",
                      class: {
                        active:
                          _vm.searchParams.belongProfessionals &&
                          _vm.searchParams.belongProfessionals.indexOf(item) >
                            -1,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.changeProfession(item)
                        },
                      },
                    },
                    [_vm._v(_vm._s(item))]
                  )
                }),
                0
              ),
          _c(
            "van-tabs",
            {
              staticClass: "orderStatusTab",
              attrs: {
                animated: "",
                color: "#3366FE",
                "title-active-color": "#3366FE",
                "title-inactive-color": "#7C7F8A",
                "line-height": "2px",
                "line-width": "56px",
              },
              on: { change: _vm.tabChange },
              model: {
                value: _vm.searchParams.incidentDealStateName,
                callback: function ($$v) {
                  _vm.$set(_vm.searchParams, "incidentDealStateName", $$v)
                },
                expression: "searchParams.incidentDealStateName",
              },
            },
            [
              _c("van-tab", {
                attrs: { name: "未分派" },
                scopedSlots: _vm._u([
                  {
                    key: "title",
                    fn: function () {
                      return [
                        _c("div", { staticClass: "tab-class" }, [
                          _c("div", [_vm._v("待分派")]),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c("van-tab", {
                attrs: { name: "处理中" },
                scopedSlots: _vm._u([
                  {
                    key: "title",
                    fn: function () {
                      return [
                        _c("div", { staticClass: "tab-class" }, [
                          _c("div", [_vm._v("处理中")]),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c("van-tab", {
                attrs: { name: "已完成" },
                scopedSlots: _vm._u([
                  {
                    key: "title",
                    fn: function () {
                      return [
                        _c("div", { staticClass: "tab-class" }, [
                          _c("div", [_vm._v("已完成")]),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c("van-tab", {
                attrs: { name: "已关闭" },
                scopedSlots: _vm._u([
                  {
                    key: "title",
                    fn: function () {
                      return [
                        _c("div", { staticClass: "tab-class" }, [
                          _c("div", [_vm._v("已关闭")]),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c("van-tab", {
                attrs: { name: "已回访" },
                scopedSlots: _vm._u([
                  {
                    key: "title",
                    fn: function () {
                      return [
                        _c("div", { staticClass: "tab-class" }, [
                          _c("div", [_vm._v("已回访")]),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "van-pull-refresh",
        {
          on: { refresh: _vm.onRefresh },
          model: {
            value: _vm.refreshing,
            callback: function ($$v) {
              _vm.refreshing = $$v
            },
            expression: "refreshing",
          },
        },
        [
          _c(
            "van-list",
            {
              attrs: {
                "immediate-check": false,
                offset: 50,
                finished: _vm.finished,
                "finished-text":
                  _vm.list.length > 0
                    ? "- 没有更多数据了 -"
                    : "您没有处理的工单",
              },
              on: {
                load: function ($event) {
                  return _vm.onLoad("list")
                },
              },
              model: {
                value: _vm.loading,
                callback: function ($$v) {
                  _vm.loading = $$v
                },
                expression: "loading",
              },
            },
            _vm._l(_vm.list, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "order",
                  on: {
                    click: function ($event) {
                      return _vm.handleClickOrderItem(item)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "order_header" }, [
                    _c("div", { staticClass: "left" }, [
                      _c("span", {
                        staticClass: "order_type",
                        class: {
                          zhixu: item.category_name === "秩序",
                          huanjing: item.category_name === "环境",
                          gongcheng: item.category_name === "工程",
                          kefu: item.category_name === "客服",
                        },
                      }),
                      _c("span", [_vm._v(_vm._s(item.category_name))]),
                    ]),
                    _c("span", { staticClass: "status" }, [
                      _vm._v(_vm._s(item.orderStatusName)),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "order_main" },
                    [
                      _c(
                        "van-row",
                        [
                          _c("van-col", { attrs: { span: "5" } }, [
                            _vm._v("工单编号："),
                          ]),
                          _c(
                            "van-col",
                            { attrs: { span: "19" } },
                            [
                              _c("span", [_vm._v(_vm._s(item.orderNo))]),
                              item.isTouSu === 1
                                ? _c(
                                    "van-tag",
                                    {
                                      staticClass: "ml-4px",
                                      attrs: { type: "danger" },
                                    },
                                    [_vm._v("投诉")]
                                  )
                                : _vm._e(),
                              !!item.reminderCount
                                ? _c(
                                    "van-tag",
                                    {
                                      staticClass: "ml-4px",
                                      attrs: {
                                        color: "#FD8D37",
                                        type: "danger",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "催办" +
                                          _vm._s(item.reminderCount) +
                                          "次"
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "van-row",
                        [
                          _c("van-col", { attrs: { span: "5" } }, [
                            _vm._v(
                              _vm._s(
                                item.incidentSource === "自查报事"
                                  ? "报事员工"
                                  : "报事业主"
                              ) + "："
                            ),
                          ]),
                          _c("van-col", { attrs: { span: "19" } }, [
                            _vm._v(_vm._s(item.reportUserName)),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "van-row",
                        [
                          _c("van-col", { attrs: { span: "5" } }, [
                            _vm._v("报事位置："),
                          ]),
                          _c("van-col", { attrs: { span: "19" } }, [
                            _vm._v(_vm._s(item.addressName)),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "van-row",
                        [
                          _c("van-col", { attrs: { span: "5" } }, [
                            _vm._v("报事时间："),
                          ]),
                          _c("van-col", { attrs: { span: "19" } }, [
                            _vm._v(_vm._s(item.incidentDate)),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "van-row",
                        [
                          _c("van-col", { attrs: { span: "5" } }, [
                            _vm._v("预约时间："),
                          ]),
                          _c("van-col", { attrs: { span: "19" } }, [
                            _vm._v(_vm._s(item.bookingTime)),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "van-row",
                        [
                          _c("van-col", { attrs: { span: "5" } }, [
                            _vm._v("报事描述："),
                          ]),
                          _c(
                            "van-col",
                            {
                              staticClass: "order_disc",
                              attrs: { span: "19" },
                            },
                            [_vm._v(_vm._s(item.remark))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.routeListComp(item.routeList).length
                    ? _c(
                        "div",
                        { staticClass: "order_footer" },
                        _vm._l(
                          _vm.routeListComp(item.routeList),
                          function (button, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "button",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.intoCirculation(button, item)
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(button.buttonName) + " ")]
                            )
                          }
                        ),
                        0
                      )
                    : _vm._e(),
                ]
              )
            }),
            0
          ),
        ],
        1
      ),
      _c("filter-popup", {
        ref: "filterPopup",
        attrs: { show: _vm.filterPopupShow },
        on: {
          "update:show": function ($event) {
            _vm.filterPopupShow = $event
          },
          confirm: _vm.filterPopupConfirm,
        },
      }),
      _vm.circulationShow
        ? _c("circulation", {
            attrs: {
              order: _vm.circulationOrder,
              title: _vm.circulationTitle,
              "field-config": _vm.currentFieldConfig,
              show: _vm.circulationShow,
            },
            on: {
              ok: _vm.getInitList,
              "update:show": function ($event) {
                _vm.circulationShow = $event
              },
            },
          })
        : _vm._e(),
      _c("SelectReportPosition", {
        ref: "selectProject",
        attrs: { show: _vm.selectPositionShow },
        on: {
          "update:show": function ($event) {
            _vm.selectPositionShow = $event
          },
          result: _vm.selectPositionConfim,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }