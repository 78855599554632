<template v-cloak>
    <div class="patrol-wrapper" v-if="infoData && infoData.itemName">
      <my-nav :title="navTitle" :self-back="true" @back="getBack">
          <div slot="right" class="flex" v-if="isFMTask">
            <MalfunctionGuideIcon @goToMalfunctionGuide="goToMalfunctionGuide" :code="infoData.itemCode" :pointCode="infoData.categoryCode" :isDisplayParts="isDisplayParts" :reallyType="infoData.reallyType"/>
          </div>
      </my-nav>
      <div class="patrol-scroll-wrapper">
        <div class="scroll-box">
          <div class="title-wrapper">
            <p class="title">{{ infoData.itemName }}</p>
            <p class="desc" v-if="infoData.taskType === 4">
              {{ infoData.qualityStandards }}
            </p>
            <p class="desc" v-else-if="infoData.taskType !== 3 && infoData.taskType !== 6">
              {{ infoData.itemCode }}
            </p>
            <p class="desc second-desc" v-if="infoData.taskType === 4">
              {{orderDetail.model.project_name}}
            </p>
            <p class="desc second-desc" v-if="infoData.taskType === 3 || infoData.taskType === 6">
              {{infoData.itemSecondTitle}}
            </p>
            <div class="order-photos" v-if="
                infoData.itemAttachments &&
                infoData.itemAttachments.length > 0 &&
                (isOffline || showImgAfterLoad)
              ">
              <van-image width="40" height="40" radius="4" class="photo"
                v-for="(list, index) of infoData.itemAttachments"
                @click="imgPreview(index, infoData.itemAttachments)" :key="index"
                :src="isOffline ? list.url : offlineImageBase64[list.url]" />
            </div>
          </div>
          <div v-if="orderDetail.model.order_type === 16 && infoData.status === 0">
            <van-swipe ref="remoteMonitorImgSwiperRef" class="my-swipe" :loop="false" indicator-color="white">
              <van-swipe-item v-for="(item, index) in remoteMonitorCaptureImages" :key="index">
                <div class="remoteMonitorImg">
                  <span class="remoteMonitorImg-tag">{{index === 0 ? '抓拍图片' : '实时图片'}}</span>
                  <img @click="remoteMonitorImgPreview(index)" :src="item" style="width: 100vw; object-fit: contain"/>
                </div>
              </van-swipe-item>
            </van-swipe>
            <div class="my-10px bg-white p-10px checkStandards-wrapper">
              <div style="color: #222; font-size: 16px" @click="collapse = !collapse">检查标准<van-icon class="ml-10px" name="arrow-down" /></div>
              <div class="checkStandards" :class="{'collapse': collapse}">{{infoData.checkStandards}}</div>
            </div>
          </div>
          <div class="content-box">
            <div class="content-wrapper relative min-h-56px" :class="{
                marginb:
                  !infoData.questionList || infoData.questionList.length === 0,
              }">
              <div class="content-box">
                <TypeList :order-detail="orderDetail" :info-data="infoData" v-if="infoData.options && infoData.options.length > 0" :detail="nonEditable"
                  :project-name="orderDetail.model.project_name"
                  :typeData.sync="infoData.options" :status="infoData.status === 1"
                  :taskType="infoData.taskType" :nodeCode="orderDetail.model.template_node_code"
                  @handleProblem="handleProblem" @clickImg="clickImgCb" @capture="remoteMonitorCapture" :order-id="orderDetail.model.id">
                </TypeList>
              </div>
            </div>
            <div class="problem-list-wrapper"
              v-if="infoData.questionList && infoData.questionList.length > 0">
              <p class="problem-title">问题列表</p>
              <div class="list-box" v-for="(item, index) in infoData.questionList" :key="index">
                <div class="list-title">
                  <p class="num">{{ item.orderNo }}</p>
                  <div class="list-title-btn">
                    <p class="status">{{item.nodeName}}</p>
                    <p class="submit-btn record-btn"
                       v-if="isOffline && item.orderNo.indexOf('T')===-1 && item.orderNo.indexOf('JK')===-1"
                       @click="handleProblemRecordSubmit(item, index)">
                      提交
                    </p>
                    <p
                      class="del-btn record-btn"
                      v-if="item.orderNo.indexOf('T')===-1 && item.orderNo.indexOf('JK')===-1"
                      @click="handleDelete(item, index)"
                    >
                      删除
                    </p>
                  </div>
                </div>
                <div class="info-list">
                  <p class="label">记录人:</p>
                  <p class="value">{{ item.createName }}</p>
                </div>
                <div class="info-list">
                  <p class="label">记录时间:</p>
                  <p class="value">{{ item.createTime }}</p>
                </div>
                <div class="info-list">
                  <p class="label">整改时限:</p>
                  <p class="value">{{ minuteFormatComp(item.processLimit)}}</p>
                </div>
                <div class="info-list" v-if="isFMTask">
                  <p class="label">整改人:</p>
                  <p class="value">{{ item.handlerName }}</p>
                </div>
                <div class="info-list">
                  <p class="label">问题分级:</p>
                  <p class="value">{{ item.gradeName }}</p>
                </div>
                <div class="info-list">
                  <p class="label">问题描述:</p>
                  <p class="value">{{ item.remark }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="btn-box">
        <div class="btn-wrapper">
          <van-button v-if="nonEditable" class="w-343px h-40px rounded-4px font-semibold" :color="forbidTitleColor" type="primary" @click="getBack">{{timeTitle}}</van-button>
          <van-button type="warning" @click="goProblem(0)" v-if="
              orderDetail.model.template_node_code === 'chulizhong' &&
              orderDetail.model.handler_id === orderDetail.model.login_user_id &&
              (infoData.taskType === 2 || infoData.taskType === 3) &&
              !nonEditable
            ">问题记录</van-button>
          <van-button  type="info" @click="handleTemporary" v-if="infoData.status !== 1 && orderDetail.model.template_node_code === 'chulizhong' &&!nonEditable">暂存
          </van-button>
          <van-button v-if="orderDetail.model.template_node_code === 'chulizhong' && !nonEditable" type="primary" @click="handleSubmit">{{
            infoData.status === 1 ? "返回" : "完成"
          }}</van-button>
        </div>
      </div>
    </div>
</template>

<script type='text/ecmascript-6'>
import TypeList from './components/TypeList.vue'
import { ImagePreview } from 'vant'
import {
  OssConversionBase64
} from '@/plugins/offlineMixin/utils/offlineImgTool'
import { cloneDeep } from 'lodash'
import moment from 'moment'
import MalfunctionGuideIcon from '@/components/MyFieldOfMalfunctionReasonSelect/MalfunctionGuideIcon'
import { setPatroItemOfflineApiHandler, deleteProblem, submitProblem, handleOrderAfterServerSubmitTaskHandler } from '@/plugins/offlineMixin/offlineApiHandlers/PatroListOfflineApiHandler'
import { TaskItemSubmitWrapper } from '@/plugins/offlineMixin/onlineSubmitDataWrappers/OrderSubmitWrapper'
import {
  getOrderDetailOfflineApiHandler
} from '@/plugins/offlineMixin/offlineApiHandlers/OrderInfoOfflineApiHandlers'
import { apiOffline } from '@/plugins/offlineMixin/apiOfflineGetway'
import { setHistoryListDataHandler } from '@/plugins/offlineMixin/offlineApiHandlers/HistoryOrderListOfflineApiHandlers'
import { ONLINE_REQUEST_SUCCESS, OFFLINE_REQUEST_SUCCESS } from '@/plugins/offlineMixin/utils/StaticConstantCode'

import { mapGetters } from 'vuex'
import api from '@/api/index'

export default {
  name: '',
  components: { MalfunctionGuideIcon, TypeList },
  data () {
    return {
      infoData: {},
      offline: true,
      isDisplayParts: true, // 是否展示主要部件页面
      nonEditable: true,
      timeTitle: '返回', // 距离计划开始时间剩余的时间按钮,
      orderDetail: null,
      forbidTitleColor: '',
      scorllTimer: null,
      orderId: null,
      offlineImageBase64: {},
      showImgAfterLoad: false,
      collapse: true,
      remoteMonitorCaptureImages: []
    }
  },
  computed: {
    ...mapGetters(['getPatroItem', 'getDetail']),
    isFM () {
      return this.$store.getters.getIsFM
    },
    isOffline () {
      return this.$store.getters.getIsOffline
    },
    navTitle () {
      if (this.orderDetail.model.template_node_code === 'yiwancheng' || this.orderDetail.model.template_node_code === 'yiwanjie') {
        if (this.isFM) return '任务抽查结果详情'
        if (this.$route.query.checkNo) return '设施设备巡检'
        return '执行任务工单'
      } else {
        if (this.$route.query.checkNo) return '任务抽查结果详情'
        return '执行任务工单'
      }
    },
    isFMTask () {
      return this.infoData.reallyType === '2' || this.infoData.reallyType === '3'
    },
    minuteFormatComp () {
      return function (minute) {
        return this.$moment.formatMinutes(minute)
      }
    }
  },
  mounted () {
    this._getListData()
  },
  methods: {
    remoteMonitorCapture () {
      this.$http.post(this.$api.orderTaskCapture, {
        orderNo: this.orderDetail.model.order_no,
        pointCode: this.infoData.itemCode
      }).then(result => {
        if (result) {
          this.$toast('获取实时监控图片成功')
          const remoteMonitorCaptureImages = JSON.parse(JSON.stringify(this.remoteMonitorCaptureImages))
          remoteMonitorCaptureImages[1] = result
          this.remoteMonitorCaptureImages = remoteMonitorCaptureImages
          this.$refs.remoteMonitorImgSwiperRef.swipeTo(1)
        } else {
          this.$toast('获取实时图片失败，请重试')
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    // 判断这张是否在问题记录里
    hasPicInProblemList (imgKey) {
      if (!this.orderDetail.pinkongProblemReq || !this.orderDetail.pinkongProblemReq.problemRecordList || this.orderDetail.pinkongProblemReq.problemRecordList === 0) return false
      return this.orderDetail.pinkongProblemReq.problemRecordList.some(problemRecord => {
        if (problemRecord.attachmentList && problemRecord.attachmentList.length > 0 && problemRecord.attachmentList.includes(imgKey)) {
          return true
        }
      })
    },
    clickImgCb (item) {
      // 点击了【合格】或者【不合格】
      this.$router.push({
        path: '/ResultDetails',
        query: {
          pageData: item
        }
      })
    },
    /* 图片查看器 */
    imgPreview (index, attachmentList) {
      const imagesGroup = []
      attachmentList &&
        attachmentList.forEach((item) => {
          imagesGroup.push(this.isOffline ? item.url : this.offlineImageBase64[item.url])
        })
      ImagePreview({
        images: imagesGroup,
        startPosition: index
      })
    },
    remoteMonitorImgPreview (index) {
      ImagePreview({
        images: this.remoteMonitorCaptureImages,
        startPosition: index
      })
    },
    // 获取巡检列表
    async _getListData () {
      console.log('开始获取任务项详情')
      // 从工单详情点击跳转时通过query传列表id
      // 获取model
      const { patroItemId, orderId, template_node_code, captureImageUrl = '', status } = this.$route.query
      console.log('patroItemId, orderId, template_node_code,status: ', patroItemId, orderId, template_node_code, status, typeof status)
      this.captureImageUrl = captureImageUrl
      this.orderId = orderId
      let patroItem = {}
      // 不是处理中的直接取vuex的信息
      if (!(template_node_code === 'chulizhong') || (status && status * 1 === 1)) {
        patroItem = this.getPatroItem
        this.orderDetail = this.getDetail
      } else {
        const orderDetail = await apiOffline({
          params: { id: orderId },
          offlineApiHandlers: getOrderDetailOfflineApiHandler,
          forceOffline: true
        })
        console.log('orderDetail: ', this.lodash.cloneDeep(orderDetail))
        this.orderDetail = orderDetail
        patroItem = orderDetail.orderTaskItemExtModelList.find(i => i.itemId === parseInt(patroItemId))
      }
      this.infoData = patroItem
      // 将打分选项的最终得分默认为单项得分 操作类型【打分】，默认加载单项得分（即为满分）
      this.setDefaultFinalScore(this.infoData)
      this.setOfflineUrl(this.infoData)
      this.taskPatrolSort(this.infoData) // 排序
      // 分类不适用点击进入详情也为不可编辑状态
      console.log('this.infoData: ', this.infoData, this.nonEditable)
      if ((this.orderDetail.model.handler_id === this.orderDetail.model.login_user_id) && this.orderDetail.model.template_node_code === 'chulizhong') {
        if (new Date().getTime() > this.$moment.timeToTimestamp(this.orderDetail.model.plan_start)) {
          this.nonEditable = false // 只有为处理中，且处理人为本人，到了计划开始时间的时候才可以进行操作，剩余状态或时间下都为详情不可编辑
        } else {
          this.timeTitle = this.friendlyDate(this.$moment.timeToTimestamp(this.orderDetail.model.plan_start)) // 当为详情时判断按钮文案
          this.forbidTitleColor = '#ddd'
        }
      }
      if (this.infoData.gradeStatus === 2) {
        this.nonEditable = true
      }
      if (this.orderDetail.model.order_type === 16) {
        this.remoteMonitorCaptureImages = [this.captureImageUrl]
      }
      console.log(this.remoteMonitorCaptureImages, 'this.remoteMonitorCaptureImages')
      console.log('结束获取任务项详情')
      // 滚动监听
      const _this = this
      this.$nextTick(() => {
        let patrolScrollWrapper = document.querySelector('.patrol-scroll-wrapper')
        patrolScrollWrapper.addEventListener('scroll', function (e) {
          _this.boxScorll(e)
        })
        const lastPatroItemId = localStorage.getItem('patroItemId') // 现在localStorage存的patroItemId
        // 如果是相同的patroItemId.就回到之前滚动的距离
        if (lastPatroItemId === patroItemId) {
          const historyPatrolScrollWrapper = localStorage.getItem('patrolBoxScrollTop')
          if (historyPatrolScrollWrapper) {
            const historyPatrolScrollWrapperNumber = historyPatrolScrollWrapper * 1
            if (typeof historyPatrolScrollWrapperNumber === 'number') patrolScrollWrapper.scrollTo(0, historyPatrolScrollWrapper)
          }
        }
        localStorage.setItem('patroItemId', patroItemId)
        localStorage.removeItem('patrolBoxScrollTop')
      })
    },
    boxScorll (box) {
      if (this.scorllTimer !== null) clearTimeout(this.scorllTimer)
      this.scorllTimer = setTimeout(() => {
        localStorage.setItem('patrolBoxScrollTop', box.target.scrollTop)
      }, 500)
    },
    setDefaultFinalScore (taskItem) {
      taskItem.options.forEach((option) => {
        option.typeOptions.forEach((typeOption) => {
          if (typeOption.type === 10) {
            if (!typeOption.val && typeOption.val !== 0) {
              typeOption.val = typeOption.singleScore
            }
          }
        })
      })
    },
    taskPatrolSort (data) { // 事前拍照排最前，事后拍照排最后
      data.options.forEach(option => {
        option.typeOptions.sort((x, y) => {
          if (x.type === 8 || y.type === 9) {
            return -1
          }
          if (x.type === 9 || y.type === 8) {
            return 1
          }
          return 0
        })
      })
    },
    // 获取距离计划开始时间还剩余多久
    friendlyDate (timestamp) {
      const now = Date.now()
      const seconds = Math.floor((timestamp - now) / 1000)
      const minutes = Math.floor(seconds / 60)
      const hours = Math.floor(minutes / 60)
      const days = Math.floor(hours / 24)
      const months = Math.floor(days / 30)
      const m = minutes - hours * 60
      const h = hours - days * 24
      const d = days - months * 30
      return `距离计划开始时间还剩${d === 0 ? '' : `${d}天`}${h === 0 ? '' : `${h}小时`}${m}分钟`
    },
    // 处理离线副标题图片
    async setOfflineUrl (data) {
      if (data.itemAttachments && data.itemAttachments.length > 0 && !this.isOffline) {
        for (let item of data.itemAttachments) {
          this.offlineImageBase64[item.url] = await OssConversionBase64(item.url)
        }
        this.showImgAfterLoad = true
      }
    },
    // 返回
    getBack () {
      this.$router.back()
    },
    // 单选框选择异常的时候
    handleProblem (index) {
      this.goProblem(index, true)
    },
    async _setHistoryListData () {
      // 设置近三天数据
      await apiOffline({
        params: this.orderDetail.model,
        offlineApiHandlers: setHistoryListDataHandler,
        forceOffline: true
      })
    },
    // 去问题记录页
    async goProblem (index, problemBackPhone = true) {
      setTimeout(() => {
        this.$router.push({
          name: 'problemRecord',
          query: {
            orderId: this.$route.query.orderId,
            patroItemId: this.$route.query.patroItemId,
            problemIndex: index,
            problemBackPhone: problemBackPhone
          }
        })
      }, 400)
    },
    // 暂存按钮
    async handleTemporary () {
      try {
        // 不用在存一次，因为该页面只要修改就在存
        const infoData = cloneDeep(this.infoData)
        await apiOffline({
          forceOffline: true,
          params: { orderDetail: this.orderDetail, patroItem: infoData },
          offlineApiHandlers: setPatroItemOfflineApiHandler
        })
        await this._setHistoryListData()
        const _this = this
        this.$toast({
          message: '暂存成功，正在返回上一页',
          duration: 1000,
          onClose: function () {
            _this.getBack()
          }
        })
      } catch (error) {
        this.$toast({
          message: '暂存失败，请重试',
          forbidClick: true
        })
      }
    },
    async goToMalfunctionGuide () {
      const infoData = cloneDeep(this.infoData)
      await apiOffline({
        forceOffline: true,
        params: { orderDetail: this.orderDetail, patroItem: infoData },
        offlineApiHandlers: setPatroItemOfflineApiHandler
      })
    },
    // 完成按钮
    async handleSubmit () {
      console.log('开始完成任务项')
      // 未开始直接返回
      if (this.infoData.status === 1) {
        this.getBack()
        return
      }
      // 设置近三天历史记录
      await this._setHistoryListData()
      let { options } = this.infoData
      let status = false
      let text = ''
      let number = false
      // 先判断必填项
      let remoteMonitorQualified = true // 合格
      if (options && options.length > 0) {
        for (const item of options) {
          for (const typeList of item.typeOptions) {
            if (this.orderDetail.model.order_type === 16 && item.name === '结果记录' && typeList.val === 'code0') {
              remoteMonitorQualified = false // 不合格
            }
            console.log('remoteMonitorQualified: ', remoteMonitorQualified)
            if (this.orderDetail.model.order_type === 16 && item.name === '是否已及时整改' && typeList.type === 6) {
              typeList.required = remoteMonitorQualified ? 0 : 1
              console.log('typeList.required: ', typeList.required)
            }
            if (
              typeList.required === 1 &&
              ((!typeList.val || typeList.val.length === 0))
            ) {
              status = true
              text = `${item.name ? item.name : ''}必填项未填完`
              number = true
              break
            }
            if (typeList.type === 5 && typeList.val) {
              // 判断区间 如果是区间
              if (typeList.szbegin && typeList.szend) {
                if (
                  typeList.val < typeList.szbegin || typeList.val > typeList.szend
                ) {
                  status = true
                  number = true
                  text = `${item.name}请输入${typeList.szbegin}~${typeList.szend}之间`
                  typeList.val = ''
                }
              } else if (typeList.szbegin) { // 如果只有开始
                if (typeList.val < typeList.szbegin) {
                  status = true
                  number = true
                  text = `${item.name}请输入大于或等于${typeList.szbegin}的数字`
                  typeList.val = ''
                }
              } else if (typeList.szend) { // 如果只有结束
                if (typeList.val > typeList.szend) {
                  status = true
                  number = true
                  text = `${item.name}请输入小于或等于${typeList.szend}的数字`
                  typeList.val = ''
                }
              }
            }
          }
          if (status) {
            break
          }
        }
        // 给没填必填的设置error键和上传附件重新赋值
        if (status) {
          this._setListError()
          if (number) {
            return this.$toast(`${text}`)
          }
          return this.$toast(`必填项未填完`)
        }
      }
      if (this.hasNoProblemRecordInEveryTypeOption()) return
      this.infoData.device = 'APP'
      delete this.infoData.createTime
      // 给上传数据根据后台要求转换数据
      let infoData = cloneDeep(this.infoData)
      this._setGetData(infoData)
      infoData.status = 1
      infoData.finishTime = moment().format('YYYY-MM-DD HH:mm:ss')
      if (!this.isOffline) {
        infoData.offlineSubmit = 1
      }
      const result = await apiOffline({
        api: api.taskItemBatchSubmit,
        methods: 'post',
        params: { orderDetail: this.orderDetail, patroItem: infoData },
        offlineApiHandlers: setPatroItemOfflineApiHandler,
        onlineSubmitDataWrapper: TaskItemSubmitWrapper
      })
      console.log('任务提交 result: ', result)
      const _this = this
      if (result.code === 1053) {
        this.$dialog.confirm({
          title: '提示',
          message: result.result,
          cancelButtonText: '返回上一页',
          confirmButtonText: '取消'
        }).then(() => {

        }).catch(async () => {
          _this.getBack()
        })
      } else {
        if (result.code === ONLINE_REQUEST_SUCCESS) {
          await this.$apiOffline({
            params: { orderId: this.orderDetail.model.id, patroItemId: infoData.itemId },
            offlineApiHandlers: handleOrderAfterServerSubmitTaskHandler,
            forceOffline: true
          })
          this.$toast({
            message: '完成提交成功，正在返回上一页',
            duration: 1000,
            onClose: function () {
              _this.getBack()
            }
          })
        }
        if (result.code === OFFLINE_REQUEST_SUCCESS) {
          this.$dialog.alert({
            title: '提示',
            message: '您的网络状态不好，任务数据已离线保存，请在网络情况好的状态下在待上传列表进行上传。',
            confirmButtonColor: '#3366FE',
            confirmButtonText: '知道了'
          }).then(() => {
            _this.getBack()
          })
        }
      }
    },
    // 给没填必填的设置error键
    _setListError () {
      let { options } = this.infoData
      options.forEach((item) => {
        item.typeOptions.forEach((typeList) => {
          if (
            typeList.required === 1 &&
            (!typeList.val || typeList.val.length === 0)
          ) {
            this.$set(item, 'isError', true)
          }
        })
      })
    },
    // 给上传数据根据后台要求转换数据
    _setGetData (infoData) {
      let totalScore = 0
      let finalScore = 0
      let hasGradeOption = false
      infoData.options.forEach((item) => {
        item.typeOptions.forEach((typeList) => {
          if ((typeList.type === 7 || typeList.type === 8 || typeList.type === 9) && typeList.val && typeList.val.length === 0) {
            typeList.val = null
          }
          if (typeList.type === 2 && typeList.val) {
            typeList.val = typeList.val.replace(/\n|\r\n/g, '<br>')
          }
          // 将打分转为数字
          if (typeList.type === 10) {
            hasGradeOption = true // 有打分项
            totalScore += typeList.singleScore
            if (typeList.val || typeList.val === 0) {
              const score = Number(typeList.val)
              if (!isNaN(score)) {
                typeList.val = score
                finalScore += score
              }
            }
          }
        })
      })
      if (hasGradeOption) {
        infoData.gradeStatus = 1
        infoData.totalScore = totalScore
        infoData.finalScore = finalScore
      }
    },
    /**
     * 验证选择了异常后是否有问题记录 打分后是否有问题记录  （只自查对设施设备任务项有效）
     * @returns true 没有问题记录 false 不需要校验/有问题记录
     */
    hasNoProblemRecordInEveryTypeOption () {
      if (this.isFMTask) {
        return false
      }
      let remoteMonitorQualified = true // 合格
      for (let item of this.infoData.options) {
        for (let typeOption of item.typeOptions) {
          if (typeOption.type === 6 && typeOption.val === 'code0') {
            if (this.orderDetail.model.order_type === 16 && item.name === '结果记录') {
              remoteMonitorQualified = false
              break
            }
            console.log(remoteMonitorQualified, 'remoteMonitorQualified')
            if (!this.hasListCode(this.infoData.questionList, item.code)) {
              if (this.orderDetail.model.order_type === 16 && item.name === '是否已及时整改' && remoteMonitorQualified) {
                break
              }
              this.$toast(`「${item.name}」选择了异常，请至少提交一条问题记录`)
              return true
            }
          }
          /* 最终得分小于单项分值情况，需强制校验问题记录，与操作类型【判断】选择【异常】后逻辑一致； */
          if (typeOption.type === 10 && typeOption.val && (typeOption.val < typeOption.singleScore)) {
            if (!this.hasListCode(this.infoData.questionList, item.code)) {
              this.$toast(`「${item.name}」最终得分小于单项得分，请至少提交一条问题记录`)
              return true
            }
          }
        }
      }
    },
    // 删除问题项
    handleDelete (item, index) {
      this.$dialog
        .confirm({
          message: '确定删除该问题记录？',
          confirmButtonColor: '#3366FE',
          cancelButtonColor: '#3366FE'
        })
        .then(async () => {
          await apiOffline({
            params: {
              item, index, orderDetail: this.orderDetail, orderId: this.orderId
            },
            offlineApiHandlers: deleteProblem,
            forceOffline: true
          })
          this._getListData()
        })
        .catch(() => {
          // on cancel
        })
    },
    async handleProblemRecordSubmit (item, index) {
      console.log('item, index: ', item, index)
      await apiOffline({
        params: {
          item, index, orderId: this.orderId
        },
        offlineApiHandlers: submitProblem,
        forceOffline: true
      })
      this._getListData()
    },
    hasListCode (list, code) {
      for (const item of list) {
        if (item.checkItemId === code) {
          return true
        }
      }
    }
  }
}
</script>
<style  lang='less' scoped>
@import url("./index.less");
</style>
