<template>
  <van-popup v-model="_show" :overlay="false" position="right"
    :style="{ height: '100%', width: '100%' }" get-container="#app">
    <div class="area-classify-content">
      <my-nav :title="titlePop" :self-back="true" @back="goBack">
      </my-nav>
      <!-- 区域类型列表 -->
      <div class="area-list-wrapper" v-if="!chooseItem">
        <div class="area-list-item"
          @click="chooseByFacOrEqp(item)" v-for="(item,index) in areaClassifyList" :key="index">
          <span class="name">{{item.name}}</span>
          <i class="right-icon" v-if="item.name !== '总坪'"></i>
        </div>
      </div>
      <template v-else>
        <div class="search_wrapper py-6px px-16px">
          <van-search v-model="searchValue" placeholder="请输入关键字" @search="handleSearch" @clear="handleClearSearch"  />
        </div>
        <!-- 楼栋（树形结构） -->
        <div class="area-list-wrapper" v-if="chooseItem.code === '0'">
            <!-- default-expand-all -->
          <el-tree
            :data="treeData"
            show-checkbox
            node-key="code"
            ref="tree"
            :filter-node-method="filterNode"
            @check-change="checkChange"
            check-strictly
            :props="defaultProps">
          </el-tree>
          <div class="submit-wrapper"
            @click="submitTree">
            <p class="count">({{ selectTreeNum }})</p>
            <p class="text">确定</p>
          </div>
        </div>
        <div class="area-list-wrapper" v-else-if="areaList.length > 0">
          <div class="area-list-item"
            @click="choose(item)" v-for="(item,index) in areaList" :key="index">
            <span class="name">{{item.name}}</span>
            <div class="check-icon" v-if="selectCodes.includes(item.name)"></div>
          </div>
          <div class="submit-wrapper"
            @click="submitList">
            <p class="count">({{ selectCodes.length }})</p>
            <p class="text">确定</p>
          </div>
        </div>
        <!-- 暂无 -->
        <div class="area-list-wrapper empty" v-else>
          暂无数据
        </div>
      </template>
      <div class="bread_crumbs">
        <div class="bread_item" v-for="(item, index) in breadCrumbsList" :key="index"
          @click="breadClick(item, index)">
          <span class="bread_text" :class="index === breadCrumbsList.length - 1 && 'disabled'">{{item}}</span>
          <van-icon v-if="index < breadCrumbsList.length - 1" class="bread_arrow" name="arrow" />
        </div>
      </div>
    </div>
  </van-popup>
</template>

<script>
import api from '@/api/index'
import { getDictionaryAllOfflineHandler } from '@/plugins/offlineMixin/offlineApiHandlers/DictionaryOfflineApiHandlers'

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    projectCode: {
      type: String,
      default: ''
    }
  },
  components: {
  },
  data () {
    return {
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      titlePop: '筛选',
      areaClassifyList: [],
      areaList: [],
      areaListCopy: [],
      treeData: [],
      tabList: [],
      checkList: [],
      level: 0,
      active: '',
      chooseItem: null,
      selectCodes: [],
      breadCrumbsList: ['区域类型'],
      bprop: {
        label: 'name',
        children: 'children',
        multiple: true,
        checkStrictly: true,
        hasPath: false
      },
      selectTreeNum: 0,
      searchValue: ''
    }
  },
  computed: {
    _show: {
      get () {
        return this.show
      },
      set (val) {
        console.log('val: ', val)
        this.$emit('update:show', val)
      }
    }
  },
  mounted () {
    this.getDictionaryitemReq()
  },
  methods: {
    init () {
      Object.assign(this.$data, this.$options.data())
      this.getDictionaryitemReq()
    },
    handleSearch () {
      if (this.chooseItem.code === '0') {
        this.$refs.tree.filter(this.searchValue)
      } else {
        this.areaList = this.areaListCopy.filter(item => item.name.indexOf(this.searchValue) !== -1)
      }
    },
    filterNode (value, data) {
      if (!value) return true
      return data.name.indexOf(value) !== -1
    },
    hasOwnName (item) {
      this.searchValue = ''
      return item.name.indexOf(this.searchValue) !== -1
    },
    getFilterTreeBySearh (tree, func) {
      return tree.map(node => ({ ...node })).filter(node => {
        node.children = node.children && this.getFilterTreeBySearh(node.children, func)
        return func(node) || (node.children && node.children.length)
      })
    },
    handleClearSearch () {
      if (this.chooseItem.code === '0') {
        this.$refs.tree.filter('')
      } else {
        this.areaList = this.areaListCopy
      }
    },
    checkChange (node, isCheck) {
      this.treeForeach(node.children, (data) => {
        this.$refs.tree.setChecked(data.code, isCheck)
      })
      const checkNodes = this.$refs.tree.getCheckedNodes()
      console.log('checkNodes: ', checkNodes)
      this.selectTreeNum = checkNodes.length
    },
    treeForeach (tree, func) {
      tree.forEach(data => {
        func(data)
        data.children && this.treeForeach(data.children, func)
      })
    },
    handleCheck (index, secondIndex, thirdIndex) {
      console.log('1111index, secondIndex, thirdIndex: ', index, secondIndex, thirdIndex)
      const treeData = this.lodash.cloneDeep(this.treeData)
      if (thirdIndex !== undefined) { // 楼层
        console.log('楼层')
        treeData[index].children[secondIndex].children[thirdIndex].check = treeData[index].children[secondIndex].children[thirdIndex].check ? '' : 'all'
        if (treeData[index].children[secondIndex].children[thirdIndex].check) {
          treeData[index].children[secondIndex].check = treeData[index].children[secondIndex].children.every(item => item.check === 'all') ? 'all' : (treeData[index].children[secondIndex].children.every(item => !item.check) ? '' : 'check')
          treeData[index].check = treeData[index].children.every(item => item.check === 'all') ? 'all' : (treeData[index].children.every(item => !item.check) ? '' : 'check')
        }
      } else if (secondIndex !== undefined) {
        console.log('单元')
        treeData[index].children[secondIndex].children.forEach(element => {
          element.check = treeData[index].children[secondIndex].check ? '' : 'all'
        })
        treeData[index].children[secondIndex].check = treeData[index].children[secondIndex].check ? '' : 'all'
        if (treeData[index].children[secondIndex].check) {
          treeData[index].check = treeData[index].children.every(item => item.check === 'all') ? 'all' : (treeData[index].children.every(item => !item.check) ? '' : 'check')
        }
      } else {
        console.log('楼栋')
        treeData[index].children.forEach(element => {
          element.check = treeData[index].check ? '' : 'all'
          element.children.forEach(i => {
            i.check = treeData[index].check ? '' : 'all'
          })
        })
        treeData[index].check = treeData[index].check ? '' : 'all'
      }
      console.log('treeData: ', treeData)
      this.selectTreeNum = this.getNum(treeData)
      this.treeData = treeData
    },
    getNum (array) {
      return array.reduce((num, item) => {
        if (item.children && item.children.length > 0) {
          num = num + this.getNum(item.children)
        } else {
          if (item.check === 'all') {
            ++num
          }
        }
        return num
      }, 0)
    },
    // // 二级选择显示隐藏
    collapse (index, secondIndex) {
      console.log('index, secondIndex): ', index, secondIndex)
      const treeData = this.lodash.cloneDeep(this.treeData)
      if (secondIndex !== undefined) {
        treeData[index].children[secondIndex].show = !treeData[index].children[secondIndex].show
      } else {
        treeData[index].show = !treeData[index].show
      }
      this.treeData = treeData
    },
    setFirstCount (item) {
      let text = ''
      if (item.children && item.children.length > 0) {
        let count = 0
        for (const list of item.children) {
          if (list.check) {
            count += 1
          }
        }
        text = `(${count}/${item.children.length})`
      }
      return text
    },
    getCodes (array) {
      return array.reduce((arr, item) => {
        if (item.children && item.children.length > 0) {
          arr = arr.concat(this.getCodes(item.children))
        } else {
          if (item.check === 'all') {
            arr = arr.concat(item.codes)
          }
        }
        return arr
      }, [])
    },
    submitTree () {
      // const getCodes = this.getCodes(this.treeData)
      const checkNodes = this.$refs.tree.getCheckedNodes()
      if (checkNodes.length === 0) {
        return this.$toast('请选择至少一项')
      }
      console.log('checkNodes: ', checkNodes)
      const codes = checkNodes.reduce((arr, item) => {
        arr = arr.concat(item.codes)
        return arr
      }, [])
      console.log('codes: ', codes)
      this.confirmAndBack(this.lodash.uniq(codes))
    },
    submitList () {
      console.log(this.selectCodes, 'selectCodes')
      if (!this.areaList || this.areaList.length === 0) {
        return this.$toast('请选择至少一项')
      }
      const selectPointCodes = this.areaList.reduce((array, item) => {
        if (this.selectCodes.includes(item.name)) {
          array = array.concat(item.pointCodes)
        }
        return array
      }, [])
      this.confirmAndBack(this.lodash.uniq(selectPointCodes))
    },
    breadClick (item, index) {
      if (index === 0) {
        this.chooseItem = null
        this.selectCodes = []
        this.breadCrumbsList = ['区域类型']
      }
    },
    /* 获取关闭类型和关闭类型的具体原因 */
    async getDictionaryitemReq () {
      const dictionaryList = await this.$apiOffline({
        methods: 'get',
        params: { dic_code: '' },
        api: this.$api.dictionaryitem,
        offlineApiHandlers: getDictionaryAllOfflineHandler
      })
      this.areaClassifyList = dictionaryList.filter(
        (item) => item.dicCode === 'areaClassify'
      )
      console.log('this.areaClassifyList: ', this.areaClassifyList)
    },
    choose (area) {
      const findIndex = this.selectCodes.findIndex(item => item === area.name)
      if (findIndex === -1) {
        this.selectCodes.push(area.name)
      } else {
        this.selectCodes.splice(findIndex, 1)
      }
    },
    confirmAndBack (pointCodes) {
      this.$emit('confirm', pointCodes)
      this._show = false
    },
    async chooseByFacOrEqp (item) {
      console.log('item: ', item)
      this.titlePop = item.name
      if (item.code === '0') {
        this.chooseItem = JSON.parse(JSON.stringify(item))
        if (this.treeData.length > 0) {
          return
        }
        // 楼栋的tree
        const data = await this.getCheckInitAreaTree(item.code)
        this.treeData = data[0] ? data[0].children : []
      } else {
        const data = await this.getCheckInitAreaList(item.code)
        if (item.name === '总坪') {
          if (data[0] && data[0].pointCodes.length === 0) {
            return this.$toast('无相关设施设备')
          } else {
            this.confirmAndBack(data[0].pointCodes)
          }
        } else {
          this.chooseItem = JSON.parse(JSON.stringify(item))
          this.areaList = data
          this.areaListCopy = data
          this.selectCodes = []
        }
      }
      this.breadCrumbsList.push(item.name)
      console.log('this.areaList: ', this.areaList)
    },
    async getCheckInitAreaList (areaClassificationCode, searchName) {
      return new Promise(async resolve => {
        const data = await this.$http.post(api.checkInitAreaList, {
          areaClassificationCode: areaClassificationCode,
          searchName: searchName,
          projectCode: this.projectCode
        })
        resolve(data)
      })
    },
    async getCheckInitAreaTree (areaClassificationCode, searchName) {
      return new Promise(async resolve => {
        const data = await this.$http.get(api.checkInitAreaTree, {
          areaClassificationCode: areaClassificationCode,
          searchName: searchName,
          projectCode: this.projectCode
        })
        this.traverseTreeAddShow(data)
        resolve(data)
      })
    },
    traverseTreeAddShow (array) {
      array.forEach(item => {
        if (item.children && item.children.length > 0) {
          item.show = true
          this.traverseTreeAddShow(item.children)
        } else {
          item.show = true
        }
      })
    },
    goBack () {
      this._show = false
    }

  }
}
</script>

<style lang="less" scoped>
.area-classify-content{
  display: flex;
  height: 100%;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}
.area-list-wrapper {
  overflow-y: scroll;
  width: 100%;
  flex: 1;
  &.empty{
    font-size: 32px;
    color: #c5c5c5;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
  }
  .area-list-item {
    height: 48px;
    display: flex;
    padding: 0 16px;
    align-items: center;
    justify-content: space-between;
    .name {
      font-size: 16px;
      font-weight: 400;
      color: #222222;
      line-height: 24px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .right-icon {
      width: 16px;
      height: 16px;
      background: url("../asset/right-icon.png") no-repeat;
      background-size: 100% 100%;
    }
    .check-icon {
      width: 24px;
      height: 24px;
      background: url("../asset/icon_right_blue@2x.png") no-repeat;
      background-size: 100% 100%;
    }
  }
  .check-list {
    &.child{
      padding-left: 24px;
    }
    .check-header{
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 48px;
      padding: 0px 12px;
      .check-icon {
          flex: 0 0 22px;
          flex-shrink: 0;
          height: 24px;
          background: url("../asset/multi-default.png") no-repeat;
          background-size: 100%;
          &.multi-check {
            background: url("../asset/multi-check.png") no-repeat;
            background-size: 100% 100%;
          }
          &.multi-all {
            background: url("../asset/multi-all.png") no-repeat;
            background-size: 100% 100%;
          }
      }
      .name {
        flex: 1;
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        box-sizing: border-box;
        padding: 0 8px;
      }
      .check-right {
        flex: 0 0 22px;
        flex-shrink: 0;
        height: 22px;
        background: url("../asset/check-up.png") no-repeat;
        background-size: 100% 100%;
        transform: rotate(0deg);
        transition: all 0.3s;
        &.down {
          transform: rotate(180deg);
          transition: all 0.3s;
        }
      }
    }
  }
}
//   .check-list {
//       padding: 0 16px;
//       background-color: #fff;
//       & + .check-list {
//         margin-top: 8px;
//       }
//       .first-check-wrapper {
//         // height: 48px;
//         .check-left {
//           display: flex;
//           flex: 1;
//           box-sizing: border-box;
//           overflow: hidden;
//           .multi-check {
//             background: url("../asset/multi-check.png") no-repeat;
//             background-size: 100% 100%;
//           }
//           .multi-all {
//             background: url("../asset/multi-all.png") no-repeat;
//             background-size: 100% 100%;
//           }
//         }
//         .check-right {
//           flex: 0 0 22px;
//           flex-shrink: 0;
//           height: 22px;
//           background: url("../asset/check-up.png") no-repeat;
//           background-size: 100% 100%;
//           transform: rotate(0deg);
//           transition: all 0.3s;
//           &.down {
//             transform: rotate(180deg);
//             transition: all 0.3s;
//           }
//         }
//       }
//       .secon-transition {
//         overflow: hidden;
//       }
//       .second-check-wrapper {
//         padding-left: 32px;
//         // overflow: hidden;
//         .second-check-list {
//           // display: flex;
//           // height: 48px;
//           // align-items: center;
//           // justify-content: space-between;
//         }
//       }
//       .check-wrapper {
//         .check-icon {
//           flex: 0 0 24px;
//           flex-shrink: 0;
//           height: 24px;
//           background: url("../asset/multi-default.png") no-repeat;
//           background-size: 100%;
//           &.second-check {
//             background: url("../asset/multi-all.png") no-repeat;
//             background-size: 100% 100%;
//           }
//         }
//         .name {
//           flex: 1;
//           font-size: 16px;
//           font-weight: 400;
//           color: #333333;
//           overflow: hidden;
//           text-overflow: ellipsis;
//           white-space: nowrap;
//           box-sizing: border-box;
//           padding: 0 8px;
//         }
//       }
//     }

// }
.search_wrapper {
  width: 100%;
  ::v-deep {
    .van-search__content {
      background-color: #f5f6f6 !important;
    }
    .van-search {
      padding: 0;
    }
  }
}
::v-deep .el-tree-node__content{
  height: 36px;
  .el-tree-node__label{
    font-size: 16px;
  }
  .el-checkbox__inner {
    width: 16px;
    height: 16px;
  }
}
.submit-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 64px;
  height: 64px;
  background: linear-gradient(90deg, #62a9ff 0%, #3370fe 100%);
  box-shadow: 0px 2px 6px 0px rgba(51, 102, 254, 0.4);
  border-radius: 50%;
  text-align: center;
  overflow: hidden;
  position: fixed;
  right: 16px;
  bottom: 76px;

  .count {
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    line-height: 1;
    margin-top: 13px;
  }

  .text {
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
    line-height: 1;
    margin-top: 5px;
  }
}
.bread_crumbs {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  padding: 10px;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  .bread_item {
    display: flex;
    align-items: center;

    .bread_text {
      font-size: 16px;
      padding: 0 10px;
      color: #3366fe;
      &.disabled{
        color: #c5c5c5;
      }
    }

    .bread_arrow {
      font-size: 12px;
      color: #c5c5c5;
    }
  }
}
</style>
