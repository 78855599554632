<template v-cloak>
    <div class="offline-list-wrapper">
      <my-nav title="待上传离线任务列表">
          <p slot="right" class="submit-btn" :class="{'disabled':submitLoading}" @click="handleSubmitAll">全部提交</p>
      </my-nav>
      <div class="list-wrapper" v-if="list.length > 0">
        <div v-for="(item, index) in list" :key="index">
          <div class="order-cell" >
            <div class="order-cell-title">
              <div class="flex-box">
                <div class="order-number">{{ item.model.order_no }}</div>
                <van-tag
                  plain
                  type="primary"
                  color="#999"
                  data-clipboard-action="copy"
                  :class="'copyOrderNo' + index"
                  :data-clipboard-text="item.model.order_no"
                  @click.stop="copyOrderNumber(item.model.order_no)"
                  class="copy-tag"
                  >复制</van-tag
                >
              </div>
              <div class="btn-wrapper">
                <p class="btn del" :class="{'disabled':submitLoading}" @click="handleDelete(item,index)">删除</p>
                <p class="btn" :class="{'disabled':submitLoading}" @click="handleSubmit(item,index)">提交</p>
              </div>
            </div>
            <div class="order-body">
              <van-row class="order-body-cell">
                <van-col :span="6" class="sub-key">工单属性:</van-col>
                <van-col :span="18" class="order-sub-flex">
                  <van-tag
                    type="success"
                    size="medium"
                    class="mr-8"
                    :color="getOrderTypeConf.taskOrderTypes.includes(item.model.order_type)? '': '#45A2F4'"
                    >
                      {{ item.model.order_type_name }}
                  </van-tag>
                  <van-tag
                    style="background: rgba(190, 69, 244, 0.1)"
                    plain
                    type="primary"
                    size="medium"
                    color="#BE45F4"
                    class="mr-8"
                    >{{ item.model.order_nature_name }}</van-tag
                  >
                  <!-- 问题分级Tag -->
                  <van-tag
                    plain
                    type="primary"
                    size="medium"
                    :color="JSON.parse(item.model.grade).color"
                    v-if="item.model.grade"
                    class="mr-8"
                  >
                    {{ JSON.parse(item.model.grade).value }}</van-tag
                  >
                </van-col>
              </van-row>
              <van-row
                class="order-body-cell"
                gutter="10"
                v-if="getOrderTypeConf.taskOrderTypes.includes(item.model.order_type)"
              >
                <van-col :span="6" class="sub-key">处理方式:</van-col>
                <van-col :span="18" class="order-sub-flex">
                  <van-tag
                    size="medium"
                    color="rgb(51 102 254 / 10%)"
                    text-color="#3366FE"
                    class="mr-8"
                  >
                    <span class="font-bold">{{
                      item.model.hander_type_name
                    }}</span></van-tag
                  >
                  <van-tag
                    color="rgb(51 102 254 / 10%)"
                    text-color="#3366FE"
                    size="medium"
                    ><span class="font-bold" >{{  item.model.in_order === 1 ? "有序" : "无序" }}</span></van-tag
                  >
                </van-col>
              </van-row>
              <van-row class="order-body-cell">
                <van-col :span="6" class="sub-key">所在项目:</van-col>
                <van-col :span="18" class="sub-value">
                  <span>{{ item.model.project_name }}</span>
                </van-col>
              </van-row>
              <van-row class="order-body-cell">
                <van-col :span="8" class="sub-key">计划开始时间:</van-col>
                <van-col :span="16" class="sub-value">
                  <span>{{ item.model.plan_start }}</span>
                </van-col>
              </van-row>
              <van-row class="order-body-cell">
                <van-col :span="8" class="sub-key">计划结束时间:</van-col>
                <van-col :span="16" class="sub-value">
                  <span>{{ item.model.plan_end }}</span>
                </van-col>
              </van-row>
              <van-row class="order-body-cell">
                <van-col :span="6" class="sub-key">工单描述:</van-col>
                <van-col :span="18" class="sub-value">
                  <span>{{ item.model.remark }}</span>
                </van-col>
              </van-row>
            </div>
            <div v-if="getOrderTypeConf.taskOrderTypes.includes(item.model.order_type)" class="offlineSubmitNum">
              点位/设施设备数量：<b>{{item.orderTaskItemExtModelList.length}}</b> &nbsp;&nbsp;|&nbsp;&nbsp;已离线完成：<b>{{offlineSubmitPatroItemNum(item.orderTaskItemExtModelList)}}</b>
            </div>
            <div v-if="item.model.offlineSubmitMessage" class="status_info"><van-icon name="info-o" size="20" color="red"/><span class="messege">{{item.model.offlineSubmitMessage}}</span></div>
          </div>
        </div>
      </div>
      <no-data v-else tex="暂无上传的离线数据"></no-data>
    </div>
</template>

<script type='text/ecmascript-6'>
import { apiOffline } from '@/plugins/offlineMixin/apiOfflineGetway'
import { getWaitingUploadListOfflineApiHandler } from '@/plugins/offlineMixin/offlineApiHandlers/WaitingUploadOfflineApiHandlers'
import api from '@/api'
import {
  cleanOrderAfterServerSubmitHandler
} from '@/plugins/offlineMixin/offlineApiHandlers/OrderSubmitOfflineApiHandlers'
import {
  OrderDetailSubmitWrapper,
  AllOfflineOrdersSubmitWrapper
} from '@/plugins/offlineMixin/onlineSubmitDataWrappers/OrderSubmitWrapper'
import {
  deleteOrderCacheOfflineApiHandler
} from '@/plugins/offlineMixin/offlineApiHandlers/OrderInfoOfflineApiHandlers'
import { keys } from 'lodash'
import { mapGetters } from 'vuex'
import mobile from '@/utils/mobile'
import { Dialog } from 'vant'
import dashboardMixin from '@/plugins/offlineMixin/dashboardMixin'

export default {
  name: '',
  components: {},
  mixins: [dashboardMixin],
  data () {
    return {
      list: [],
      submitLoading: false
    }
  },
  computed: {
    ...mapGetters(['getOrderTypeConf']),
    isOffline () {
      return this.$store.getters.getIsOffline
    },
    offlineSubmitPatroItemNum () {
      return function (task_list) {
        return task_list.filter(i => i.offlineSubmit === 1).length
      }
    }
  },
  async created () {
    const allToBeUploadOrderList = await getWaitingUploadListOfflineApiHandler()
    this.list = allToBeUploadOrderList.map(item => item.orderData.detail)
    console.log('created---this.list: ', this.list)
  },
  methods: {
    /* 复制订单号 */
    copyOrderNumber (orderNo) {
      this.$copyText(orderNo).then(
        (e) => {
          this.$notify({
            type: 'success',
            message: '复制成功',
            background: '#13CE66'
          })
        },
        (e) => {
          this.$notify({
            type: 'warning',
            message: '复制成功',
            background: '#FFC82C'
          })
        }
      )
    },
    // 删除 直接删除APP工单缓存
    async handleDelete (item, index) {
      console.log('数据item: ', item)
      if (this.submitLoading) return
      this.$dialog
        .confirm({
          message: '确定删除该离线数据嘛？',
          confirmButtonColor: '#3366FE',
          cancelButtonColor: '#3366FE'
        })
        .then(async () => {
          // await this.getOrderImageKeysAndDel([item])
          await mobile.delImgsByOrderId({ 'orderId': item.model.id })
          await apiOffline({
            offlineApiHandlers: deleteOrderCacheOfflineApiHandler,
            params: [item.model.id],
            forceOffline: true
          })
          this.list.splice(index, 1)
          this.$toast('删除成功')
          console.log('this.list: ', this.list)
          this.$store.commit('changeDashBoardRefreshMixin')// 返回刷新主页
        })
        .catch((err) => {
          console.log('err: ', err)
        })
    },
    // 提交
    async handleSubmit (detail, index) {
      if (this.submitLoading) return
      this.submitLoading = true
      if (!this.isOffline) {
        this.$EB.$emit(this.$EBT.NO_NETWORK_WARN)
        this.submitLoading = false
        return
      }
      try {
        const result = await apiOffline({
          api: api.submitOffline,
          methods: 'post',
          params: { orderId: detail.model.id, buttonInfo: detail.buttonInfo },
          onlineSubmitDataWrapper: OrderDetailSubmitWrapper
        })
        console.log('result: ', result)
        if (!result[detail.model.order_no] && result[detail.model.order_no] !== 0) throw new Error('系统异常，请联系相关人员处理！')
        await mobile.delImgsByOrderId({ 'orderId': detail.model.id })
        const submitOrderList = [{
          orderId: detail.model.id,
          isComplete: result[detail.model.order_no] === 1,
          orderType: detail.model.order_type
        }]
        await apiOffline({
          params: submitOrderList,
          offlineApiHandlers: cleanOrderAfterServerSubmitHandler,
          forceOffline: true
        })
        this.$store.commit('changeDashBoardRefreshMixin')
        if (result[detail.model.order_no] === 1) {
          this.$toast('提交成功')
          // 返回刷新主页
          this.$store.commit('changeDashBoardRefreshMixin')
        } else {
          Dialog.alert({
            title: '提示',
            message: `您的${detail.model.order_no}任务有点位/设施设备未完成，请继续到任务页完成点位/设施设备`,
            confirmButtonColor: '#3366FE',
            confirmButtonText: '知道了'
          })
        }
        this.list.splice(index, 1)
      } catch (error) {
        console.log('error: ', error)
        error && this.$toast({
          type: 'fail',
          message: '提交异常'
        })
        this.submitLoading = false
      } finally {
        this.submitLoading = false
      }
    },
    // 全部提交
    async handleSubmitAll () {
      if (this.submitLoading) return
      this.submitLoading = true
      if (!this.isOffline) {
        this.$EB.$emit(this.$EBT.NO_NETWORK_WARN)
        this.submitLoading = false
        return
      }
      try {
        if (this.list.length === 0) {
          this.$toast('无待上传工单')
          return
        }
        console.log('>>>>>this.list', this.list)
        const result = await apiOffline({
          api: api.submitAllOffline,
          methods: 'post',
          params: this.list,
          onlineSubmitDataWrapper: AllOfflineOrdersSubmitWrapper
        })
        console.log('>>>>>上传失败的工单及原因: ', result)
        const { failList = {}, orderFlowStatus = {} } = result
        let errorSubmitList = keys(failList)
        const submitSuccessDetailList = []
        this.list = this.list.reduce((arr, detail) => {
          if (errorSubmitList.includes(detail.model.order_no)) {
            detail.model.offlineSubmitMessage = failList[detail.model.order_no].message
            arr.push(detail)
          } else {
            submitSuccessDetailList.push(detail)
          }
          return arr
        }, [])
        console.log('>>>>>submitSuccessIdList: ', submitSuccessDetailList)
        const submitOrderList = submitSuccessDetailList.map(order => {
          return {
            orderId: order.model.id,
            isComplete: orderFlowStatus[order.model.order_no] === 1,
            orderType: order.model.order_type
          }
        })
        await apiOffline({
          params: submitOrderList,
          offlineApiHandlers: cleanOrderAfterServerSubmitHandler,
          forceOffline: true
        })
        this.$store.commit('changeDashBoardRefreshMixin')
        if (submitSuccessDetailList.length > 0) {
          const flowSuccessOrderNos = submitSuccessDetailList.filter(detail => orderFlowStatus[detail.model.order_no] === 1).map(detail => detail.model.order_no)
          console.log('flowSuccessOrderNos: ', flowSuccessOrderNos)
          const flowFailOrderNos = submitSuccessDetailList.filter(detail => orderFlowStatus[detail.model.order_no] === 0).map(detail => detail.model.order_no)
          console.log('flowFailOrderNos: ', flowFailOrderNos)
          let message = ''
          if (flowSuccessOrderNos.length > 0) message += `您的工单${flowSuccessOrderNos.join('，')}已流转成功，`
          if (flowFailOrderNos.length > 0) message += `您的${flowFailOrderNos.join('，')}任务有点位/设施设备未完成，请继续到任务页完成点位/设施设备`
          Dialog.alert({
            title: '提示',
            message,
            confirmButtonColor: '#3366FE',
            confirmButtonText: '知道了'
          })
        } else {
          this.$toast(`暂无工单提交成功`)
        }
      } catch (error) {
        console.error('error: ', error)
        this.submitLoading = false
      } finally {
        this.submitLoading = false
      }
    }
  }
}
</script>
<style  lang='less' scoped>
@import url("./index.less");
</style>
