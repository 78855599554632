var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.infoData && _vm.infoData.itemName
    ? _c(
        "div",
        { staticClass: "patrol-wrapper" },
        [
          _c(
            "my-nav",
            {
              attrs: { title: _vm.navTitle, "self-back": true },
              on: { back: _vm.getBack },
            },
            [
              _vm.isFMTask
                ? _c(
                    "div",
                    {
                      staticClass: "flex",
                      attrs: { slot: "right" },
                      slot: "right",
                    },
                    [
                      _c("MalfunctionGuideIcon", {
                        attrs: {
                          code: _vm.infoData.itemCode,
                          pointCode: _vm.infoData.categoryCode,
                          isDisplayParts: _vm.isDisplayParts,
                          reallyType: _vm.infoData.reallyType,
                        },
                        on: { goToMalfunctionGuide: _vm.goToMalfunctionGuide },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
          _c("div", { staticClass: "patrol-scroll-wrapper" }, [
            _c("div", { staticClass: "scroll-box" }, [
              _c("div", { staticClass: "title-wrapper" }, [
                _c("p", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.infoData.itemName)),
                ]),
                _vm.infoData.taskType === 4
                  ? _c("p", { staticClass: "desc" }, [
                      _vm._v(" " + _vm._s(_vm.infoData.qualityStandards) + " "),
                    ])
                  : _vm.infoData.taskType !== 3 && _vm.infoData.taskType !== 6
                  ? _c("p", { staticClass: "desc" }, [
                      _vm._v(" " + _vm._s(_vm.infoData.itemCode) + " "),
                    ])
                  : _vm._e(),
                _vm.infoData.taskType === 4
                  ? _c("p", { staticClass: "desc second-desc" }, [
                      _vm._v(
                        " " + _vm._s(_vm.orderDetail.model.project_name) + " "
                      ),
                    ])
                  : _vm._e(),
                _vm.infoData.taskType === 3 || _vm.infoData.taskType === 6
                  ? _c("p", { staticClass: "desc second-desc" }, [
                      _vm._v(" " + _vm._s(_vm.infoData.itemSecondTitle) + " "),
                    ])
                  : _vm._e(),
                _vm.infoData.itemAttachments &&
                _vm.infoData.itemAttachments.length > 0 &&
                (_vm.isOffline || _vm.showImgAfterLoad)
                  ? _c(
                      "div",
                      { staticClass: "order-photos" },
                      _vm._l(
                        _vm.infoData.itemAttachments,
                        function (list, index) {
                          return _c("van-image", {
                            key: index,
                            staticClass: "photo",
                            attrs: {
                              width: "40",
                              height: "40",
                              radius: "4",
                              src: _vm.isOffline
                                ? list.url
                                : _vm.offlineImageBase64[list.url],
                            },
                            on: {
                              click: function ($event) {
                                return _vm.imgPreview(
                                  index,
                                  _vm.infoData.itemAttachments
                                )
                              },
                            },
                          })
                        }
                      ),
                      1
                    )
                  : _vm._e(),
              ]),
              _vm.orderDetail.model.order_type === 16 &&
              _vm.infoData.status === 0
                ? _c(
                    "div",
                    [
                      _c(
                        "van-swipe",
                        {
                          ref: "remoteMonitorImgSwiperRef",
                          staticClass: "my-swipe",
                          attrs: { loop: false, "indicator-color": "white" },
                        },
                        _vm._l(
                          _vm.remoteMonitorCaptureImages,
                          function (item, index) {
                            return _c("van-swipe-item", { key: index }, [
                              _c("div", { staticClass: "remoteMonitorImg" }, [
                                _c(
                                  "span",
                                  { staticClass: "remoteMonitorImg-tag" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        index === 0 ? "抓拍图片" : "实时图片"
                                      )
                                    ),
                                  ]
                                ),
                                _c("img", {
                                  staticStyle: {
                                    width: "100vw",
                                    "object-fit": "contain",
                                  },
                                  attrs: { src: item },
                                  on: {
                                    click: function ($event) {
                                      return _vm.remoteMonitorImgPreview(index)
                                    },
                                  },
                                }),
                              ]),
                            ])
                          }
                        ),
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "my-10px bg-white p-10px checkStandards-wrapper",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                color: "#222",
                                "font-size": "16px",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.collapse = !_vm.collapse
                                },
                              },
                            },
                            [
                              _vm._v("检查标准"),
                              _c("van-icon", {
                                staticClass: "ml-10px",
                                attrs: { name: "arrow-down" },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "checkStandards",
                              class: { collapse: _vm.collapse },
                            },
                            [_vm._v(_vm._s(_vm.infoData.checkStandards))]
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c("div", { staticClass: "content-box" }, [
                _c(
                  "div",
                  {
                    staticClass: "content-wrapper relative min-h-56px",
                    class: {
                      marginb:
                        !_vm.infoData.questionList ||
                        _vm.infoData.questionList.length === 0,
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "content-box" },
                      [
                        _vm.infoData.options && _vm.infoData.options.length > 0
                          ? _c("TypeList", {
                              attrs: {
                                "order-detail": _vm.orderDetail,
                                "info-data": _vm.infoData,
                                detail: _vm.nonEditable,
                                "project-name":
                                  _vm.orderDetail.model.project_name,
                                typeData: _vm.infoData.options,
                                status: _vm.infoData.status === 1,
                                taskType: _vm.infoData.taskType,
                                nodeCode:
                                  _vm.orderDetail.model.template_node_code,
                                "order-id": _vm.orderDetail.model.id,
                              },
                              on: {
                                "update:typeData": function ($event) {
                                  return _vm.$set(
                                    _vm.infoData,
                                    "options",
                                    $event
                                  )
                                },
                                "update:type-data": function ($event) {
                                  return _vm.$set(
                                    _vm.infoData,
                                    "options",
                                    $event
                                  )
                                },
                                handleProblem: _vm.handleProblem,
                                clickImg: _vm.clickImgCb,
                                capture: _vm.remoteMonitorCapture,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                ),
                _vm.infoData.questionList &&
                _vm.infoData.questionList.length > 0
                  ? _c(
                      "div",
                      { staticClass: "problem-list-wrapper" },
                      [
                        _c("p", { staticClass: "problem-title" }, [
                          _vm._v("问题列表"),
                        ]),
                        _vm._l(
                          _vm.infoData.questionList,
                          function (item, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "list-box" },
                              [
                                _c("div", { staticClass: "list-title" }, [
                                  _c("p", { staticClass: "num" }, [
                                    _vm._v(_vm._s(item.orderNo)),
                                  ]),
                                  _c("div", { staticClass: "list-title-btn" }, [
                                    _c("p", { staticClass: "status" }, [
                                      _vm._v(_vm._s(item.nodeName)),
                                    ]),
                                    _vm.isOffline &&
                                    item.orderNo.indexOf("T") === -1 &&
                                    item.orderNo.indexOf("JK") === -1
                                      ? _c(
                                          "p",
                                          {
                                            staticClass:
                                              "submit-btn record-btn",
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleProblemRecordSubmit(
                                                  item,
                                                  index
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(" 提交 ")]
                                        )
                                      : _vm._e(),
                                    item.orderNo.indexOf("T") === -1 &&
                                    item.orderNo.indexOf("JK") === -1
                                      ? _c(
                                          "p",
                                          {
                                            staticClass: "del-btn record-btn",
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleDelete(
                                                  item,
                                                  index
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(" 删除 ")]
                                        )
                                      : _vm._e(),
                                  ]),
                                ]),
                                _c("div", { staticClass: "info-list" }, [
                                  _c("p", { staticClass: "label" }, [
                                    _vm._v("记录人:"),
                                  ]),
                                  _c("p", { staticClass: "value" }, [
                                    _vm._v(_vm._s(item.createName)),
                                  ]),
                                ]),
                                _c("div", { staticClass: "info-list" }, [
                                  _c("p", { staticClass: "label" }, [
                                    _vm._v("记录时间:"),
                                  ]),
                                  _c("p", { staticClass: "value" }, [
                                    _vm._v(_vm._s(item.createTime)),
                                  ]),
                                ]),
                                _c("div", { staticClass: "info-list" }, [
                                  _c("p", { staticClass: "label" }, [
                                    _vm._v("整改时限:"),
                                  ]),
                                  _c("p", { staticClass: "value" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.minuteFormatComp(item.processLimit)
                                      )
                                    ),
                                  ]),
                                ]),
                                _vm.isFMTask
                                  ? _c("div", { staticClass: "info-list" }, [
                                      _c("p", { staticClass: "label" }, [
                                        _vm._v("整改人:"),
                                      ]),
                                      _c("p", { staticClass: "value" }, [
                                        _vm._v(_vm._s(item.handlerName)),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _c("div", { staticClass: "info-list" }, [
                                  _c("p", { staticClass: "label" }, [
                                    _vm._v("问题分级:"),
                                  ]),
                                  _c("p", { staticClass: "value" }, [
                                    _vm._v(_vm._s(item.gradeName)),
                                  ]),
                                ]),
                                _c("div", { staticClass: "info-list" }, [
                                  _c("p", { staticClass: "label" }, [
                                    _vm._v("问题描述:"),
                                  ]),
                                  _c("p", { staticClass: "value" }, [
                                    _vm._v(_vm._s(item.remark)),
                                  ]),
                                ]),
                              ]
                            )
                          }
                        ),
                      ],
                      2
                    )
                  : _vm._e(),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "btn-box" }, [
            _c(
              "div",
              { staticClass: "btn-wrapper" },
              [
                _vm.nonEditable
                  ? _c(
                      "van-button",
                      {
                        staticClass: "w-343px h-40px rounded-4px font-semibold",
                        attrs: { color: _vm.forbidTitleColor, type: "primary" },
                        on: { click: _vm.getBack },
                      },
                      [_vm._v(_vm._s(_vm.timeTitle))]
                    )
                  : _vm._e(),
                _vm.orderDetail.model.template_node_code === "chulizhong" &&
                _vm.orderDetail.model.handler_id ===
                  _vm.orderDetail.model.login_user_id &&
                (_vm.infoData.taskType === 2 || _vm.infoData.taskType === 3) &&
                !_vm.nonEditable
                  ? _c(
                      "van-button",
                      {
                        attrs: { type: "warning" },
                        on: {
                          click: function ($event) {
                            return _vm.goProblem(0)
                          },
                        },
                      },
                      [_vm._v("问题记录")]
                    )
                  : _vm._e(),
                _vm.infoData.status !== 1 &&
                _vm.orderDetail.model.template_node_code === "chulizhong" &&
                !_vm.nonEditable
                  ? _c(
                      "van-button",
                      {
                        attrs: { type: "info" },
                        on: { click: _vm.handleTemporary },
                      },
                      [_vm._v("暂存 ")]
                    )
                  : _vm._e(),
                _vm.orderDetail.model.template_node_code === "chulizhong" &&
                !_vm.nonEditable
                  ? _c(
                      "van-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.handleSubmit },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.infoData.status === 1 ? "返回" : "完成")
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }