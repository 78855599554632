var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "picker-select-wrapper" },
    [
      _c("van-field", {
        attrs: {
          readonly: "",
          clickable: "",
          value: _vm.value,
          placeholder: "请选择",
          "right-icon": "right-icon",
          disabled: _vm.detail,
        },
        on: {
          click: function ($event) {
            _vm.showPicker = true
          },
        },
      }),
      _c(
        "van-popup",
        {
          attrs: { round: "", position: "bottom", disabled: _vm.detail },
          model: {
            value: _vm.showPicker,
            callback: function ($$v) {
              _vm.showPicker = $$v
            },
            expression: "showPicker",
          },
        },
        [
          _c(
            "van-picker",
            {
              attrs: { "show-toolbar": "", columns: _vm.columns },
              on: {
                cancel: function ($event) {
                  _vm.showPicker = false
                },
                confirm: _vm.onConfirm,
              },
            },
            [
              _c("div", {
                staticClass: "blank-top",
                attrs: { slot: "columns-top" },
                slot: "columns-top",
              }),
              _c("div", {
                staticClass: "blank-bottom",
                attrs: { slot: "columns-bottom" },
                slot: "columns-bottom",
              }),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }