<template>
  <div class="dashboard">
    <!-- 导航栏 -->
    <my-nav :title="navTitle" :self-back="true" @back="toBack">
      <div class="icon-wrapper" slot="right">
        <!-- 问题整改-筛选条件 -->
        <van-popover get-container=".dashboard" trigger="click" v-model="queryIssueZGShow" placement="bottom-end">
          <div class="queryOrderZGPop">
            <van-cell-group>
              <span class="label">报事人</span>
              <van-field v-model="searchParams.reportUserName" label="" placeholder="请输入报事人" />
              <span class="label">所在位置</span>
              <van-field v-model="searchParams.address" label="" placeholder="请输入所在位置" />
              <span class="label">原因大类</span>
              <van-field name="bigCategoryName" @click="selectReasonShow = true" readonly clickable right-icon="arrow" v-model="searchParams.bigCategoryName" label="" placeholder="请选择原因大类" />
              <span class="label">原因细类</span>
              <van-field name="smallCategoryName" @click="selectFineReason" readonly clickable right-icon="arrow" v-model="searchParams.smallCategoryName" label="" placeholder="请选择原因细类" />
              <span class="label">工单编号</span>
              <van-field v-model="searchParams.orderNo" label="" placeholder="请输入工单编号" />
            </van-cell-group>
            <div class="btn">
              <van-button style="height:30px" @click="resetZGQueryParams">重置</van-button>
              <van-button style="height:30px;margin-left:10px" @click="getInitList" type="primary">查询</van-button>
            </div>
          </div>
          <template #reference>
            <div class="icon-screen" v-if="isZG"></div>
          </template>
        </van-popover>
        <div class="icon-code" v-if="isPK || isFM || isZG" @click="searchCode"></div>
        <micro-text
          :ptt="true"
          v-if="isOffline && isCJ"
          @speakRes="speakRes"
          :black="true"
          :style-prop="{'marginRight':'18px'}"
        />
        <!-- 点位列表 -->
        <div class="icon-screen" v-if="isFM || isPK" @click="showPoint"></div>
        <!-- 承接查验筛选 -->
        <van-popover trigger="click" v-model="filterEquipAndAreaPopShow" placement="bottom-end" :actions="filterEquipAndAreaActions" @select="handleFilterEquipAndArea">
          <template #reference>
            <div class="icon-screen" v-if="isCJ"></div>
          </template>
        </van-popover>
        <div class="icon_history" v-if="isFM || isPK  || isCJ" @click="showHistory"></div>
        <!-- 问题整改 新建弹窗 -->
        <van-popover trigger="click" v-model="createIssueShow" placement="bottom-end" :actions="createIssueActions" @select="handleCreateFMProgramRecord">
          <template #reference>
            <div class="icon_create" v-if="isZG"></div>
          </template>
        </van-popover>
      </div>
    </my-nav>
    <DashboardProjectSelect ref="DashboardProjectSelect" @selectProject="selectProject"></DashboardProjectSelect>
    <!-- 非FM —— 品控的日期信息部分 -->
    <Calendar v-if="isPK && calendarRenderAble" :searchParams.sync="searchParams" ref="Calendar"/>
    <!-- 品控才有一条线 -->
    <p v-if="isPK" class="bg-line"></p>
    <template>
      <van-sticky :offset-top="+navBarHeight + calendarInfoHeight - 1">
        <!-- FM -->
        <template v-if="isFM">
          <section class="bg-white flex items-center justify-between workorder_wrapper">
            <div class="py-12px px-16px">

              <van-popover v-model="showPopover" trigger="click">
                <div class="divide-x">
                  <div class="py-16px pl-17px space-x-16px relative" @click="changeOrderType(item)" v-for="(item, index) in actionsOptionsFM"
                    :key="index">
                    <span> {{item.text}}</span>
                    <van-icon class="absolute text-[#3366fe] right-12px top-1/2 transform -translate-y-1/2"
                      v-if="orderTypeSelect(item.id)" name="success" />
                  </div>
                </div>
                <template #reference>
                  <span class="text-16px font-medium leading-22px text-[#222]">工单类型</span>
                  <van-icon name="arrow-down" />
                </template>
              </van-popover>
            </div>
            <div class="space-x-12px flex pr-12px">
              <!-- 我处理、同岗位... -->
              <div class="rounded-4px bg-[#f5f5f5] text-13px">
                <span class="switch_bth" :class="{switch_bth_active:item.value === searchParams.myHandle}" v-for="(item, index) in filterOptionsFM"
                  :key="index" @click="changeFilter(item)">{{item.label}}</span>
              </div>
              <!-- 当天按钮 设施设备 -->
              <div class="currentBtn rounded-4px bg-[#f5f5f5] text-13px p-6px" :class="{switch_bth_active:!!searchParams.today}"
                @click="changeToday">
                当天
              </div>
            </div>
          </section>
        </template>
          <!-- 承接查验 -->
        <template v-if="isCJ">
          <section class="bg-white flex items-center justify-between workorder_wrapper">
            <div class="py-12px px-16px">

              <van-popover v-model="showPopover" trigger="click">
                <div class="divide-x">
                  <div class="py-16px pl-17px space-x-16px relative" @click="changeOrderType(item)" v-for="(item, index) in actionsOptionsCJ"
                    :key="index">
                    <span> {{item.text}}</span>
                    <van-icon class="absolute text-[#3366fe] right-12px top-1/2 transform -translate-y-1/2"
                      v-if="orderTypeSelect(item.id)" name="success" />
                  </div>
                </div>
                <template #reference>
                  <span class="text-16px font-medium leading-22px text-[#222]">工单类型</span>
                  <van-icon name="arrow-down" />
                </template>
              </van-popover>
            </div>
            <div class="space-x-12px flex pr-12px">
              <!-- 我处理、同岗位... -->
              <div class="rounded-4px bg-[#f5f5f5] text-13px">
                <span class="switch_bth" :class="{switch_bth_active:item.value === searchParams.myHandle}" v-for="(item, index) in filterOptionsCJ"
                  :key="index" @click="changeFilter(item)">{{item.label}}</span>
              </div>
              <!-- 当天按钮 设施设备、承接查验页面 -->
              <div class="currentBtn rounded-4px bg-[#f5f5f5] text-13px p-6px" :class="{switch_bth_active:!!searchParams.today}"
                @click="changeToday">
                当天
              </div>
            </div>
          </section>
        </template>
        <!-- 问题整改 -->
        <template v-if="isZG">
          <section class="bg-white flex items-center justify-between workorder_wrapper">
            <div class="py-12px px-16px">
              <van-popover v-model="showPopover" trigger="click">
                <div class="divide-x">
                  <div class="py-16px pl-17px space-x-16px relative" @click="changeOrderType(item)" v-for="(item, index) in actionsOptionsZG"
                    :key="index">
                    <span> {{item.text}}</span>
                    <van-icon class="absolute text-[#3366fe] right-12px top-1/2 transform -translate-y-1/2"
                      v-if="orderTypeSelect(item.id)" name="success" />
                  </div>
                </div>
                <template #reference>
                  <span class="text-16px font-medium leading-22px text-[#222]">工单类型</span>
                  <van-icon name="arrow-down" />
                </template>
              </van-popover>
            </div>
            <div class="space-x-12px flex pr-12px">
              <!-- 我处理、同岗位... -->
              <div class="rounded-4px bg-[#f5f5f5] text-13px">
                <span class="switch_bth" :class="{switch_bth_active:item.value === searchParams.dataScope}" v-for="(item, index) in filterOptionsZG"
                  :key="index" @click="changeFilter(item)">{{item.label}}</span>
              </div>
            </div>
          </section>
        </template>
        <van-tabs class="state-count-tab shadow-md" animated color="#3366FE" title-active-color="#3366FE" title-inactive-color="#7C7F8A" line-height="2px" line-width="56px"
          @change="tabChange" v-model="searchParams.status" @disabled="tabDisabledHandler">
          <van-tab :disabled="!isOffline || loading" :name="1">
            <template v-slot:title>
              <div class="tab-class">
                <div>全部</div>
                <div>{{ `（${count.allCount}）` }}</div>
              </div>
            </template>
          </van-tab>
          <van-tab :disabled="!isOffline || loading" :name="2" v-if="!isJK">
            <template v-slot:title>
              <div class="tab-class">
                <div>待领取</div>
                <div>{{ `（${count.toReceiveCount}）` }}</div>
              </div>
            </template>
          </van-tab>
          <!-- 品控/FM 未完成 和 已完成 开始 -->
          <van-tab :disabled="loading" v-if="isPK || isFM || isCJ" :name="3">
            <template v-slot:title>
              <div class="tab-class">
                <div>未完成</div>
                <div>{{`（${isOffline? count.noFinishCount: offlineCount}）`}}</div>
              </div>
            </template>
          </van-tab>
          <!-- 远程监控 待检查 -->
          <van-tab :disabled="loading" v-if="isJK" :name="3">
            <template v-slot:title>
              <div class="tab-class">
                <div>待检查</div>
                <div>{{`（${count.toCheckCount}）`}}</div>
              </div>
            </template>
          </van-tab>
          <van-tab :disabled="!isOffline || loading" v-if="isPK || isFM || isCJ" :name="4">
            <template v-slot:title>
              <div class="tab-class">
                <div>已完成</div>
                <div>{{ `（${count.finishedCount}）` }}</div>
              </div>
            </template>
          </van-tab>
          <!-- 品控/FM 未完成 和 已完成  完 -->
          <!-- 问题整改开始 -->
          <van-tab :disabled="!isOffline || loading" v-if="isZG" :name="3">
            <template v-slot:title>
              <div class="tab-class">
                <div>待处理</div>
                <div>{{ `（${count.toHandleCount}）` }}</div>
              </div>
            </template>
          </van-tab>
          <van-tab :disabled="loading" v-if="isZG" :name="4">
            <template v-slot:title>
              <div class="tab-class">
                <div>处理中</div>
                <div>{{ `（${isOffline? count.noFinishCount: offlineCount}）` }}</div>
              </div>
            </template>
          </van-tab>
          <!-- 问题整改完 -->
          <van-tab :disabled="!isOffline || loading" :name="5" v-if="isPK || isFM || isCJ || isZG">
            <template v-slot:title>
              <div class="tab-class">
                <div>{{ isZG ? "已完成" : "已关闭" }}</div>
                <div>{{ `（${isZG ? count.finishedCount : count.closedCount}）` }}</div>
              </div>
            </template>
          </van-tab>
          <!-- 远程监控 -->
          <van-tab :disabled="!isOffline || loading" :name="6" v-if="isJK">
            <template v-slot:title>
              <div class="tab-class">
                <div>{{ "待复核" }}</div>
                <div>{{ `（${count.toRecheckCount}）` }}</div>
              </div>
            </template>
          </van-tab>
        </van-tabs>
      </van-sticky>
      <!-- <template v-if="getIsOfflineStatus"> -->
      <template>
          <!-- 离线时禁止下拉列表 -->
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list
          v-model="loading"
          :finished="finished"
          :finished-text="list.length > 0 ? '- 没有更多数据了 -' : '您没有处理的工单'"
          @load="onLoad('list')"
          :offset="50"
          :immediate-check="false">
            <CardList ref="CardList" :list="list" @expandMore="cardListExpandMore"/>
          </van-list>
        </van-pull-refresh>
      </template>
    </template>
    <div class="fix-btn">
      <!-- FM 意为 问题记录页面 自查设施设备 -->
      <i class="created-icon icon" @click="handleCreateFMProgramRecord({type:'ZC_SB'})" v-if="isFM || isCJ"></i>
      <i class="upload-icon icon" @click="$router.push('/OfflineList')"></i>
      <i class="download-icon icon" @click="downLoadOfflineData"></i>
    </div>
    <van-popup v-model="downloadShow" class="circle-wrapper" :close-on-click-overlay="false">
      <p class="title">正在同步数据，请不要离开!</p>
      <van-circle v-model="donloadCircle" :rate="30" :speed="100" color="#3366FE" layer-color="#EBECF0" stroke-width="70" text="下载数据中" />
      <p class="desc">正在下载数据…</p>
    </van-popup>
    <van-popup v-model="downloadSuccessShow" class="circle-wrapper success-wrapper" :close-on-click-overlay="false">
      <p class="title">恭喜您，下载完成!!</p>
      <div class="success-icon"></div>
      <p class="desc">下载完成</p>
    </van-popup>
    <FifterEquipmentPop :show.sync="FifterEquipmentShow" :projectCodes="searchParams.projectCodes" v-if="FifterEquipmentShow" :isAssembly="true">
    </FifterEquipmentPop>
    <!-- 选择原因类型 -->
    <select-reason
      v-model="selectReasonShow"
      :data-val="searchParams"
      IsSmall
      @result="setReason"
    ></select-reason>
    <!-- 选择原因细类 -->
    <select-reason
      v-if="selectSmallReasonShow"
      v-model="selectSmallReasonShow"
      :data-val="searchParams"
      :bigReasonId="bigCategoryId"
      IsSmall
      noShowBtn
      @result="setSmallReason"
    ></select-reason>
    <AcceptCheckGroupPop ref="AcceptCheckGroupPop" @confirm="AcceptCheckGroupPopConfirm" :show.sync="AcceptCheckGroupPopShow" :project-code="searchParams.projectCodes[0]">
    </AcceptCheckGroupPop>
    <!-- <div class="test-line" v-if="processEnv === 'development'">
      <van-button type="primary" @click="setOffline(2)">在线</van-button>
      <van-button type="success" @click="setOffline(1)">弱网</van-button>
      <van-button type="default" @click="setOffline(0)">离线</van-button>
      <van-popover v-model="showHandlerBtn" trigger="click" :actions="btnActions" @select="btnSelect">
        <template #reference>
          <van-button type="default">list</van-button>
        </template>
      </van-popover>
    </div> -->
  </div>
</template>
<script>
import api from '@/api/index'
import mobile from '@/utils/mobile'
import moment from 'moment'
import SelectReason from '../OrderCirculation/components/SelectReason.vue'
import {
  setAllCausesByPoint, // 下载后的
  setAllMalfunctionGuideList // 下载后的
} from '@/utils/offline.js'
import { setScanCodeDataAll } from '@/plugins/offlineMixin/offlineApiHandlers/scanCodeOfflineProcessingHandlers'

import Calendar from './components/Calendar' // 日历组件
import DashboardProjectSelect from './components/DashboardProjectSelect' // 项目选择组件
import FifterEquipmentPop from '@/components/MyFieldOfEquipmentSelect/FifterEquipmentPop'
import AcceptCheckGroupPop from '@/components/AcceptCheckGroupPop'
import CardList from '@/components/CardList' // 列表Item组件
import { setDictionaryAll } from '@/plugins/offlineMixin/offlineApiHandlers/DictionaryOfflineApiHandlers'
import {
  getOrderListOfflineApiHandler,
  saveOrderAfterDownloadOrderPackageHandler
} from '@/plugins/offlineMixin/offlineApiHandlers/OrderInfoOfflineApiHandlers'
import { delProjectInfoHandler, getProjectInfoHandler } from '@/plugins/offlineMixin/offlineApiHandlers/SelectProjectOfflineApi.js'

import {
  getOfflineInterfaceListHandler,
  getOfflineDataHandler,
  setOfflineInterfaceListHandler,
  dashboardOfflineListHandler
} from '@/plugins/offlineMixin/offlineApiHandlers/DashBoardOfflineApiHandler.js'
import {
  setCacheCodeHandler
} from '@/plugins/offlineMixin/offlineApiHandlers/CacheCodeHandler.js'
import OfflinePluginMixin from '@/plugins/offlineMixin'
import dashboardMixin from '@/plugins/offlineMixin/dashboardMixin'
import {
  getWaitingUploadListOfflineApiHandler
} from '@/plugins/offlineMixin/offlineApiHandlers/WaitingUploadOfflineApiHandlers'
import {
  setCategoryTreeHandler,
  setBigCategoryTreeHandler
} from '@/plugins/offlineMixin/offlineApiHandlers/CatogoryTreeHandler'
import {
  getHistoryOrderListOfflineApiHandler
} from '@/plugins/offlineMixin/offlineApiHandlers/HistoryOrderListOfflineApiHandlers'
import { savePointListOfflineApiHandler } from '@/plugins/offlineMixin/offlineApiHandlers/GetPointOfflineListHandlers'
import {
  setHomeEquipmentTree,
  setHomeEquipmentTreeGetGroup,
  saveOfflineAreaDataOfflineApiHandler } from '@/plugins/offlineMixin/offlineApiHandlers/FifterEquipment/KeepDataOfflineApiHandlers'
import { mapGetters } from 'vuex'
import tools from '@/utils/tools'

export default {
  name: 'Dashboard',
  components: {
    Calendar,
    DashboardProjectSelect,
    CardList,
    FifterEquipmentPop,
    SelectReason,
    AcceptCheckGroupPop
  },
  mixins: [OfflinePluginMixin, dashboardMixin],
  watch: {
    selectReasonShow (value) {
      if (!value) this.queryIssueZGShow = true
    },
    selectSmallReasonShow (value) {
      if (!value) this.queryIssueZGShow = true
    }
  },
  data () {
    return {
      selectReasonShow: false,
      selectSmallReasonShow: false,
      bigCategoryId: undefined,
      queryIssueZGShow: false,
      // 调试按钮
      btnActions: [
        { text: '打印orderList' },
        { text: '清空orderList' },
        { text: '清空cacheCode' },
        { text: '打印上传缓存' },
        { text: '打印近三天' },
        { text: '清空所有缓存' }
      ],
      showHandlerBtn: false,
      // 工单类型弹窗popover v-model
      showPopover: false,
      // FM的工单类型配置
      actionsOptionsFM: [
        { text: '巡检工单', id: ['FM01'] },
        { text: '保养工单', id: ['FM02'] },
        { text: '问题工单', id: ['FM03'] }, // 问题工单包含
        { text: '升级工单', id: ['FM04'] }
      ],
      // 承接查验的工单类型配置
      actionsOptionsCJ: [
        { text: '查验工单', id: ['CC01'] },
        { text: '问题工单', id: ['CC02'] }
      ],
      // 问题整改的工单类型配置
      actionsOptionsZG: [
        { text: '客户工单', id: ['WT01'] },
        { text: '自查工单', id: ['WT02'] },
        { text: '设备工单', id: ['WT03'] },
        { text: '升级工单', id: ['WT04'] },
        { text: '差评工单', id: ['WT05'] },
        { text: '投诉工单', id: ['WT06'] },
        { text: '品检工单', id: ['WT07'] }
      ],
      // FM的myHandle配置
      filterOptionsFM: [
        { label: '全部', value: 0 },
        { label: '我处理', value: 1 },
        { label: '同岗位', value: 2 }
      ],
      // 承接查验 配置
      filterOptionsCJ: [
        { label: '全部', value: 0 },
        { label: '我处理', value: 1 },
        { label: '同岗位', value: 2 }
      ],
      // FM的dataScope配置
      filterOptionsZG: [
        { label: '我处理的', value: 1 },
        { label: '我创建的', value: 2 }
      ],
      // ==========品控相关==========
      currentPage: 1, // 当前页
      list: [], // van-list展示的列表
      count: { // 数量展示
        allCount: 0,
        toReceiveCount: 0,
        noFinishCount: 0,
        finishedCount: 0,
        closedCount: 0,
        toHandleCount: 0,
        toCheckCount: 0,
        toRecheckCount: 0
      },
      loading: false, // van-list加载动态
      finished: false, // van-list是否加载完
      refreshing: false, // 下拉刷新动态
      downloadShow: false,
      donloadCircle: 20,
      searchParams: {
        projectCodes: [], // 项目编码数组
        myHandle: 1, // 工单范围 【品控/FM】
        dataScope: 0, // 工单范围 【问题整改】
        nowFlag: 0, // 当前时段
        status: 3, // 工单状态
        pageType: 1, // 模块
        page: 1, // 页数
        limit: 10, // 页码
        checkDate: '', // 日期 【品控】
        today: 0, // 当天
        reportUserName: '', // 报事人
        address: '', // 所在位置
        bigCategoryCode: '', // 原因类型
        bigCategoryName: '', // 原因类型
        smallCategoryCode: '', // 原因细类
        smallCategoryName: '', // 原因细类
        orderNo: '' // 工单编号
      },
      downloadSuccessShow: false,
      downloadStatus: false,
      // 1.3之后
      activeNames: [],
      oldOrderHeight: '',
      offlineCount: 0,
      navBarHeight: 0,
      calendarInfoHeight: 0,
      listOldgetParams: '',
      checkNo: null,
      FifterEquipmentShow: false, // 选择设施设备
      AcceptCheckGroupPopShow: false, // 选择区域类型
      saveOrderList: [], // 离线list数据
      createIssueShow: false, // 问题整改：创建工单
      createIssueActions: [
        { text: '客户房间', type: 'KH_FJ' },
        { text: '客户公区', type: 'KH_GQ' },
        { text: '自查房间', type: 'ZC_FJ' },
        { text: '自查公区', type: 'ZC_GQ' },
        { text: '设备问题', type: 'ZC_SB' }
      ],
      filterEquipAndAreaActions: [
        { text: '按设施设备', type: 'device' },
        { text: '按区域分类', type: 'areaType' }
      ],
      // navTitle: '',
      calendarRenderAble: false, // 拿到本地存储的项目后 才渲染 因为日历查询的接口需要带上项目参数
      projectInfo: {},
      titleClickLastTime: 0,
      titleClickCount: 0,
      titleClickMaxCount: 5,
      titleClickMaxInterval: 500,
      isMounted: false,
      dashboardScrollTop: 0,
      filterEquipAndAreaPopShow: false,
      titleMap: new Map([
        [1, '品质管控'],
        [2, '设施设备'],
        [3, '问题整改'],
        [4, '承接查验'],
        [5, '远程监控']
      ])
    }
  },
  computed: {
    ...mapGetters(['getOrderTypeConf']),
    isFM () {
      return this.$store.getters.getIsFM
    },
    isPK () {
      return this.$store.getters.getIsPK
    },
    isZG () {
      return this.$store.getters.getIsZG
    },
    isCJ () {
      return this.$store.getters.getIsCJ
    },
    isJK () {
      return this.$store.getters.getIsJK
    },
    pageType () {
      return this.$store.getters.getPageType
    },
    offlineStatus () {
      return this.$store.getters.getOfflineStatus
    },
    processEnv () {
      return process.env.NODE_ENV
    },
    orderTypeSelect () {
      return function (id) {
        if (this.lodash.intersection(this.searchParams.orderTypeSelects, id).length === id.length) {
          return true
        } else {
          return false
        }
      }
    },
    navTitle () {
      return this.titleMap.get(this.pageType)
    }
  },
  activated () {
    console.log(this.dashBoardRefreshMixin, this.isOffline, this.tempSearchParams)
    if (this.dashBoardRefreshMixin) { // 如果需要刷新列表
      if (this.isOffline && this.tempSearchParams) {
        console.log('this.tempSearchParams: ', this.tempSearchParams)
        this.searchParams = this.tempSearchParams // 如果是在线，取上一次离开此页面的请求参数
        this.dashboardScrollTop = 0
      }
      console.log('this.isMounted: ', this.isMounted)
      if (!this.isMounted) this.initPage() // 根据mixin的变量判断是否需要刷新列表
    } else {
    }
    const _this = this
    setTimeout(() => {
      const dashboard = document.querySelector('.dashboard')
      dashboard.scrollTop = _this.dashboardScrollTop
      console.log('_this.dashboardScrollTop: ', _this.dashboardScrollTop)
    })
    this.isMounted = false
  },
  async mounted () {
    const _this = this
    console.log('Dashboard mounted')
    this.isMounted = true

    // this.navTitle = this.isPK ? '品质管控' : this.isFM ? '设施设备' : this.isZG ? '问题整改' : this.isCJ ? '承接查验' : '' // 设置顶部名称
    this.initSearchParams(this.searchParams) // 初始化查询参数
    await this._getProjectInfoCache() // 拿本地APP存的项目数据 赋值给查询参数
    // 初始化时间
    this.getInitList()
    // 判断是FM还是品控，粘性布局的高度不同,FM不用减日历信息，所以直接是0，具体看van-sticky的包裹关系
    this.$nextTick(() => {
      this.navBarHeight = document.querySelector('.van-nav-bar')?.offsetHeight
      this.calendarInfoHeight = this.isPK ? document.querySelector('.calendar-info')?.offsetHeight : 0
    })
    // 异步请求主要部件故障原因缓存数据
    this.$store.dispatch('GetBreakDownDataPackage')
    const orderId = tools.getQueryString('orderId')
    const msgNo = tools.getQueryString('msgNo')
    console.log('orderId: ', orderId)
    if (this.isJK) {
      this.$http.get(this.$api.getJKAuth).then(res => {
        if (res === false) {
          this.$router.replace('noAuth')
        }
      })
    }
    setTimeout(() => {
      if (orderId && msgNo) {
        _this.$router.push({
          path: '/OrderDetail',
          query: { orderId: parseInt(orderId) }
        })
      }
    }, 1500)
  },
  methods: {
    /* 选择原因操作 */
    setReason (item) {
      if (this.searchParams.bigCategoryCode !== item.bigCategoryCode) {
        this.searchParams.smallCategoryName = ''
        this.searchParams.smallCategoryCode = ''
      }
      this.searchParams.bigCategoryName = item.bigCategoryName
      this.searchParams.bigCategoryCode = item.bigCategoryCode
      this.bigCategoryId = item.bigCategoryId
      // this.getInitList()
      console.log('this.bigCategoryId: ', this.bigCategoryId)
      console.log('this.searchParams: ', this.searchParams)
    },
    // 选择原因细类
    setSmallReason (item) {
      this.searchParams.smallCategoryName = item.smallCategoryName
      this.searchParams.smallCategoryCode = item.smallCategoryCode
      // this.getInitList()
      console.log('this.searchParams: ', this.searchParams)
    },
    // 选择原因细类
    selectFineReason () {
      if (!this.searchParams.bigCategoryCode) {
        this.$toast('请先选择原因大类')
        return
      }
      this.selectSmallReasonShow = true
    },
    AcceptCheckGroupPopConfirm (codes) {
      console.log('codes: ', codes)
      this.searchParams.isRegion = true
      this.searchParams.pointCodes = codes
      this.getInitList()
    },
    cardListExpandMore (index) {
      const list = this.lodash.cloneDeep(this.list)
      list[index].expandLevel++
      this.$set(this, 'list', list)
    },
    // 初始化listFM接口查询参数
    initSearchParams (params) {
      params.pageType = this.pageType // 模块参数
      params.pointCodes = []
      params.isRegion = false
      // 品控
      if (this.isPK) {
        params.orderTypeSelects = ['PK01'] // 选择的类型
        delete params.dataScope // 品控不需要dataScope
        delete params.today //
        params.checkDate = moment().format('YYYY-MM-DD') // 日期默认为当天时间
        params.myHandle = 1
        params.nowFlag = 0
        params.status = 3
      } else if (this.isFM) {
        // FM
        params.orderTypeSelects = this.actionsOptionsFM.reduce((arr, item) => {
          arr = arr.concat(item.id)
          return arr
        }, [])
        delete params.dataScope // FM不需要dataScope
        delete params.checkDate // FM不需要checkDate
        delete params.nowFlag // FM不需要nowFlag 改为today
        params.myHandle = 1
        params.today = 0
        params.status = 3
      } else if (this.isZG) {
        // 整改
        params.orderTypeSelects = this.actionsOptionsZG.reduce((arr, item) => {
          arr = arr.concat(item.id)
          return arr
        }, [])
        params.status = 1 // 问题整改【工单状态】默认全部
        params.dataScope = 0
        delete params.checkDate // ZG不需要checkDate
        delete params.myHandle // ZG不需要myHandle
        delete params.nowFlag // ZG不需要nowFlag
        delete params.today
      } else if (this.isCJ) {
        // FM
        params.orderTypeSelects = this.actionsOptionsCJ.reduce((arr, item) => {
          arr = arr.concat(item.id)
          return arr
        }, [])
        delete params.dataScope // FM不需要dataScope
        delete params.checkDate // FM不需要checkDate
        delete params.nowFlag // FM不需要nowFlag 改为today
        params.myHandle = 1
        params.today = 0
        params.status = 3
      } else if (this.isJK) {
        // FM
        params.orderTypeSelects = ['JK01']
        params.status = 3
        params.myHandle = 1
        delete params.dataScope // FM不需要dataScope
        delete params.checkDate // FM不需要checkDate
        delete params.nowFlag // FM不需要nowFlag 改为today
      }
      console.log('params', params)
    },
    // 当在线离线切换的时候执行initPage
    initPage () {
      if (!this.isOffline) { // 如果是切换到离线
        if (this.isFM || this.isPK || this.isCJ) {
          this.searchParams.status = 3
          this.clearProjectInfo()
        } // PK或者FM 选择未完成
        if (this.isZG) {
          this.searchParams.status = 4
          this.searchParams.dataScope = 1
        }// 问题整改 选择处理中
        if (this.isPK) this.searchParams.checkDate = moment().format('YYYY-MM-DD') // 离线情况下，恢复当前日期
      }
      this.getInitList()
    },
    clearProjectInfo () {
      this.projectInfo = {} // PK和FM 切换到无网时，清空项目
      this.searchParams.projectCodes = []
      this.$refs.DashboardProjectSelect.checkList = []// 去掉文字
    },
    getListCount () {
      let searchParams = this.lodash.cloneDeep(this.searchParams)
      if (this.isJK) {
        searchParams = {
          projectCodes: searchParams.projectCodes,
          pageType: this.pageType,
          myHandle: 1,
          isRegion: false
        }
      }
      if (this.isOffline) {
        this.$http.post(api.getTypeCount, searchParams, false).then((res) => {
          this.count = res
        })
      } else {
        this.getOfflineListCount()
      }
    },
    /**
     * 计算离线列表的数量，在无网或者下载离线包需要计算
     */
    getOfflineListCount () {
      this.offlineCount = 0
      this.offlineCount = this.list.reduce((result, item) => {
        if (item.id) {
          result += 1
        } else if (item && item.orders && item.orders.length > 0) {
          result += item.orders.length
        }
        return result
      }, 0)
      this.count.noFinishCount = this.offlineCount
    },
    async btnSelect (action, idx) {
      if (idx === 0) {
        getOrderListOfflineApiHandler().then((res) => {
          console.log('appOrderList: ', res)
        })
      } else if (idx === 1) {

      } else if (idx === 2) {
        mobile.deleteSaveData('cacheCode')
      } else if (idx === 3) {
        const res = await getWaitingUploadListOfflineApiHandler()
        console.log('打印待上传： ', res)
      } else if (idx === 4) {
        const res = await getHistoryOrderListOfflineApiHandler()
        console.log('打印近三天： ', res)
      } else if (idx === 5) {
        console.log('--------清空所有缓存开始-------')
        await delProjectInfoHandler()
        console.log('--------清空所有缓存完-------')
      }
    },
    changeFilter (item) {
      console.log(this.isFM, this.isPK, this.isZG)
      // 切换“全部”、“我处理”……
      if (this.isFM || this.isPK || this.isCJ) {
        this.searchParams.myHandle = item.value
      }
      if (this.isZG) {
        if (this.searchParams.dataScope === item.value) { // 如果是点击同一个就取消选择，恢复默认
          this.searchParams.dataScope = 0
        } else {
          this.searchParams.dataScope = item.value
        }
      }
      this.getInitList()
    },
    changeNowFlag () {
      // 当前时段按钮
      this.searchParams.nowFlag = this.searchParams.nowFlag ? 0 : 1
      this.getInitList()
    },
    changeToday () {
      // 当天按钮
      this.searchParams.today = this.searchParams.today ? 0 : 1
      this.getInitList()
    },
    changeOrderType (item) {
      let existData
      console.log(this.lodash.intersection(this.searchParams.orderTypeSelects, item.id))
      if (this.lodash.intersection(this.searchParams.orderTypeSelects, item.id).length === item.id.length) {
        existData = true
      } else {
        existData = false
      }
      // 勾选工单类型
      // 存在则删除,否则添加
      console.log('existData: ', existData)
      if (existData) {
        item.id.forEach(id => {
          this.searchParams.orderTypeSelects.splice((this.searchParams.orderTypeSelects || []).findIndex((i) => i === id), 1)
        })
      } else {
        this.searchParams.orderTypeSelects = this.searchParams.orderTypeSelects.concat(item.id)
      }
      this.getInitList()
    },
    setOffline (val) {
      this.$store.dispatch('NetStateHandler', val)
    },
    showPoint () {
      // 需要区分FM进入和品控进入
      if (this.isFM) {
        this.FifterEquipmentShow = true
      } else {
        this.$router.push({
          name: 'PointList',
          params: { projectCodes: this.searchParams.projectCodes }
        })
      }
    },
    showHistory () {
      this.$router.push('/HistoryRecord')
    },
    handleProcessLimit (list, needExpandStandrad = false) {
      try {
        list.forEach(record => {
          /* 意为 是否需要设置为展开标准卡片 */
          if (needExpandStandrad) {
            /* 意为 标准工单 */
            if (this.getOrderTypeConf.taskOrderTypes.includes(record.orderType) && record.orders.length > 10) {
              record.expandLevel = 1
            }
          }
          /* 意为 普通工单 && 不是已完成和已完结 && planEndTime存在 */
          if (this.getOrderTypeConf.normalOrderTypes.includes(record.orderType) && !(record.templateNodeCode === 'yiwancheng' || record.templateNodeCode === 'yiwanjie') && record.planEndTime) {
            const m1 = moment(record.planEndTime) // 计划结束时间
            const m2 = moment() // 现在的时间
            var duration = moment.duration(m1.diff(m2)) // 现在-计划结束时间
            var minutes = duration.asMinutes()
            if (minutes >= 0) {
              console.log('剩余')
              record.remainingTime = `「剩余${this.$moment.formatMinutes(Math.floor(minutes))}」`
            } else {
              console.log('超期')
              record.overdueTime = `「超期${this.$moment.formatMinutes(Math.floor(minutes * -1))}」`
            }
          }
        })
      } catch (error) {
        console.erro('error: ', error)
      }
    },
    /* 加载列表数据 */
    async onLoad () {
      this.loading = true
      try {
        const searchParams = this.lodash.cloneDeep(this.searchParams)
        if (!this.isOffline) { // 如果是离线
          searchParams.isPK = this.isPK
          searchParams.isFM = this.isFM
          searchParams.isZG = this.isZG
          searchParams.isCJ = this.isCJ
          this.refreshing = false
          this.finished = true // 切换到离线直接列表到底
        }
        if (this.isJK) {
          if (searchParams.status === 6) searchParams.status = 4
        }
        searchParams.page = this.currentPage
        const res = await this.$apiOffline({
          methods: 'post',
          api: api.getOrderList,
          params: searchParams,
          offlineApiHandlers: dashboardOfflineListHandler,
          showLoading: false
        })
        // 离线和在线返回的字段不一样 bugfix
        // 根据返回的records来判断是否到底  或者 离线时直接设置为到底
        if (!res.records || res.records.length === 0) {
          this.finished = true
          return
        }
        this.currentPage++

        this.list = this.list.concat(res.records)
        console.log('onloadList', this.list)
        this.handleProcessLimit(this.list, this.searchParams.status === 4 && this.isFM)
        this.$refs.CardList.resetContanierRef()
      } catch (error) {
        console.log('onLoad error', error)
        this.finished = true// 当列表接口有异常时，不让触底再加载
      } finally {
        this.refreshing = false
        this.loading = false
      }
    },
    onClickDisabled () {
      this.$EB.$emit(this.$EBT.NO_NETWORK_WARN)
    },
    /* tab切换 */
    tabChange (index) {
      console.log('index: ', index)
      if (!this.isOffline && (((this.isFM || this.isPK) && index === 3) || (this.isZG && index === 4))) {
        // 状态 ：离线 且 FM时点“未完成” ZG时点“处理中”
        // 离线状态下，FM PK只有“未完成”能点 ZG只有“处理中”能点
        return
      }
      if (this.isJK) {
        if (index === 3) {
          this.searchParams.orderTypeSelects = ['JK01']
        } else if (index === 6) {
          this.searchParams.orderTypeSelects = ['JK02']
        } else {
          this.searchParams.orderTypeSelects = ['JK01', 'JK02']
          console.log('this.searchParams.orderTypeSelects: ', this.searchParams.orderTypeSelects)
        }
      }
      this.getInitList()
    },
    // mounted
    /*
      1\mounted
      2\initPage
      4\selectDay
      5\tabChange
      6\onRefresh
      7\selectProject

      noGetListCount false-需要重新获取数量  true-不需要重新获取数量
    */
    async getInitList () {
      console.log('getInitList')
      this.currentPage = 1
      this.finished = false
      this.list = []
      await this.onLoad('getInitList') // 加await是因为离线下getListCount需要在onLoad执行完才执行
      this.getListCount()
      this.queryIssueZGShow = false
    },
    resetZGQueryParams () {
      this.searchParams.reportUserName = ''
      this.searchParams.address = ''
      this.searchParams.orderNo = ''
      this.searchParams.bigCategoryCode = ''
      this.searchParams.bigCategoryName = ''
      this.searchParams.smallCategoryCode = ''
      this.searchParams.smallCategoryName = ''
      this.bigCategoryId = ''
      this.getInitList()
    },
    /* 下滑刷新 */
    onRefresh () {
      if (!this.isOffline || this.offlineStatus === 0) {
        this.getInitList()
        return
      }
      this.refreshing = false
      console.log('this.downloadStatus: ', this.downloadStatus)
      console.log('this.isOffline: ', this.isOffline)
      if (
        this.downloadStatus &&
        this.isOffline &&
        (((this.isFM || this.isPK || this.isCJ) && this.searchParams.status === 3) || (this.isZG && this.searchParams.status === 4))
      ) {
        this.$dialog
          .confirm({
            title: '提醒',
            // width: '370px',
            message:
              '您即将刷新任务列表，刷新后当前下载的离线数据将会失效，是否继续',
            confirmButtonColor: '#3366FE',
            cancelButtonColor: '#3366FE'
          })
          .then(() => {
            // on confirm
            this.downloadStatus = false
            this.onRefresh()
          })
          .catch(() => {
            // on cancel
            this.refreshing = false
            this.finished = true
          })
        return
      }
      this.getInitList()
    },
    /* 复制订单号 */
    copyOrderNumber (orderNo) {
      this.$copyText(orderNo).then(
        (e) => {
          this.$notify({
            type: 'success',
            message: '复制成功',
            background: '#13CE66'
          })
        },
        (e) => {
          this.$notify({
            type: 'warning',
            message: '复制成功',
            background: '#FFC82C'
          })
        }
      )
    },
    async selectProject (list) {
      // 初始化参数
      this.initSearchParams(this.searchParams)
      this.searchParams.projectCodes = list.length > 0 ? list : []
      this.getInitList()
      this.$refs.AcceptCheckGroupPop && this.$refs.AcceptCheckGroupPop.init()
    },
    handleCreateFMProgramRecord (action) {
      this.createIssueShow = false
      if (!this.isOffline) {
        this.onClickDisabled()
        return
      }
      /* 直接跳转会先执行跳转再执行this.createIssueShow = false，转为异步队列执行 */
      const routerTimer = setTimeout(() => {
        clearTimeout(routerTimer)
        this.$router.push({ name: 'CreateProblemRecord', params: { type: action.type } })
      }, 0)
    },
    handleFilterEquipAndArea (action) {
      if (action.type === 'areaType') {
        if (!this.isOffline) {
          this.$EB.$emit(this.$EBT.NO_NETWORK_WARN)
          return
        }
        if (!this.searchParams.projectCodes || this.searchParams.projectCodes.length === 0) {
          this.$toast('请先选择项目')
          return
        }
        if (this.searchParams.projectCodes.length > 1) {
          this.$toast('筛选区域类型仅支持选择单个项目')
          return
        }
        this.AcceptCheckGroupPopShow = true
      } else {
        this.FifterEquipmentShow = true
      }
    },
    filterOfflineData (data, offlineTypeInterfaceName) {
      // 目前只维护offlineAreaData
      switch (offlineTypeInterfaceName) {
        case 'offlineAreaData':
          data.forEach((offlineData) => {
            offlineData.list = offlineData.list.filter((item) => {
              return item.deleted !== '1' && item.state !== '0'
            })
          })
          return data
        default:
          break
      }
    },
    // 根据接口名下载离线数据
    async downloadofflineDataByInterface (offlineTypeInterfaceName) {
      const initParams = [{}]
      const resOfflineData = await this.$http.post(
        api[offlineTypeInterfaceName],
        initParams
      )
      let offlineAreaDataFilter = this.filterOfflineData(
        resOfflineData,
        offlineTypeInterfaceName
      )
      saveOfflineAreaDataOfflineApiHandler({ // 保存到手机 根据分类+组团+项目编码查询空间资产信息
        offlineDataInterfaceName: offlineTypeInterfaceName,
        value: offlineAreaDataFilter
      })
    },
    async downLoadOfflineData () {
      // 无网提示
      if (!this.isOffline) {
        this.$EB.$emit(this.$EBT.NO_NETWORK_WARN)
        return
      }
      this.donloadCircle = 0
      this.downloadShow = true
      // 模拟假的数据条
      let time = setInterval(() => {
        if (this.donloadCircle < 90) {
          this.donloadCircle += 5
        } else if (this.donloadCircle > 90 <= 95) {
          this.donloadCircle += 2
          clearInterval(time)
        }
      }, 200)
      // 取初始化查询参数，因为存在下载不成功的情况，所以需要复制一个参数
      let params = this.lodash.cloneDeep(this.$options.data().searchParams)
      console.log('params: ', params)
      this.initSearchParams(params)
      if (this.isZG) { // 【问题整改】 处理人是我且状态为处理中的工单
        params.status = 4
        params.dataScope = 1
      }
      console.log('下载离线包FM ZG params: ', params)
      try {
        let [
          // orderlistPackage,
          allCausesByPoint,
          allMalfunctionGuideList,
          homeEquipmentTree,
          homeEquipmentTreeGetGroup /*,  homeEquipmentTreeGetLast */,
          scanCodeDataAll,
          dictionaryAll,
          pointListDataRes,
          categoryTreeDataRes,
          bigReasonListDataRes
        ] = await Promise.all([
          // this.$http.post(api.getOfflineList, params, false),
          this.$http.get(api.getAllCausesByPoint, undefined, false),
          this.$http.get(api.findListByPoint, undefined, false),
          this.$http.get(api.homeEquipmentTree, { isEnterFM: (this.isFM || this.isZG) ? 1 : 0 }, false),
          this.$http.get(api.homeEquipmentTreeGetGroup, { isEnterFM: (this.isFM || this.isZG) ? 1 : 0 }, false),
          this.$http.post(api.getScanQrCode, { types: ['1', '2', '3'] }, false), // ['2','3']是设备编码 ['1']是点位编码
          this.$http.post(api.dictionaryitem, { dic_code: '' }, false),
          this.$http.post(api.getPointOfflineList, false),
          this.$http.get(api.categoryTree, false),
          this.$http.get(api.getBigReasonList, undefined, false)
        ])
        await setAllCausesByPoint({ allCausesByPoint })
        await setAllMalfunctionGuideList({ allMalfunctionGuideList })
        await setHomeEquipmentTree({ homeEquipmentTree })
        await setHomeEquipmentTreeGetGroup({ homeEquipmentTreeGetGroup })
        await setScanCodeDataAll({ scanCodeDataAll }) // 保存设备/点位编码
        await setDictionaryAll({ dictionaryAll })
        await setCategoryTreeHandler({ categoryTreeDataRes })
        await setBigCategoryTreeHandler({ bigReasonListDataRes })
        // ========== 点位离线数据 ==========
        await savePointListOfflineApiHandler({
          pointList: pointListDataRes || []
        })
        await setDictionaryAll({ dictionaryAll })
        // ========== 离线包分页下载 ==========
        let orderlistPackage = []
        let tempOrderlistPackage = []
        let listParamsPage = 1
        const listParamsLimit = 10
        do {
          params.page = listParamsPage
          params.limit = listParamsLimit
          tempOrderlistPackage = await this.$http.post(api.getOfflineList, params, false)
          console.log('tempOrderlistPackage: ', tempOrderlistPackage)
          orderlistPackage = orderlistPackage.concat(tempOrderlistPackage)
          listParamsPage = listParamsPage + 1
        } while (tempOrderlistPackage && tempOrderlistPackage.length > 0)
        console.log('下载完成后总的工单list', this.lodash.cloneDeep(orderlistPackage))
        console.log('设置完成')
        // 将下载的离线包存入orderList
        await this.$apiOffline({
          params: orderlistPackage,
          offlineApiHandlers: saveOrderAfterDownloadOrderPackageHandler,
          forceOffline: true
        })
        params.isPK = this.isPK
        params.isFM = this.isFM
        params.isZG = this.isZG
        params.isCJ = this.isCJ
        this.isZG && (params.projectCodes = this.searchParams.projectCodes)
        const list = await this.$apiOffline({
          params: params,
          forceOffline: true,
          offlineApiHandlers: dashboardOfflineListHandler,
          showLoading: false
        })
        this.list = list.records
        console.log('this.list: ', this.list)
        this.handleProcessLimit(this.list)
        // FM和问题整改 才有增量下载
        // sprint20221125 品控也有设施设备列表
        await this.downLoadOfflineDataInc()
        // 下载完成界面的参数
        this.initSearchParams(this.searchParams)
        this.currentPage = 1
        this.getOfflineListCount() // 需求：下载离线包后需要显示离线包的数据
        if (this.isFM || this.isPK || this.isCJ) { // 【FM/品控】下载离线包清空选择的项目
          this.projectInfo = {}
          this.searchParams.projectCodes = []
          this.$refs.DashboardProjectSelect.checkList = []// 去掉文字
        }
        if (this.isZG) { // 【问题整改】处理人是我且状态为处理中的工单
          this.searchParams.status = 4
          this.searchParams.dataScope = 1
        }
        // 下载状态
        this.downloadStatus = true
        // 关闭触底load
        this.finished = true
        // 下载完成的动画
        this.donloadCircle = 100
        let closeDownloadShowTimer = setTimeout(() => {
          this.downloadShow = false
          // 显示下载成功
          this.downloadSuccessShow = true
          let downloadSuccessShowTimer = setTimeout(() => {
            this.downloadSuccessShow = false
            clearTimeout(downloadSuccessShowTimer)
          }, 1500)
          clearTimeout(closeDownloadShowTimer)
        }, 400)
      } catch (error) {
        console.error(error, 'error')
      } finally {
        this.downloadShow = false
        clearInterval(time)
      }
    },
    async downLoadOfflineDataInc () {
      const _this = this
      /* 1.15.0  FM离线数据包关于设施设备数据增量处理 */
      let offlineInterfaceList = await _this.$apiOffline({
        offlineApiHandlers: getOfflineInterfaceListHandler,
        forceOffline: true
      })
      console.log(offlineInterfaceList, 'offlineInterfaceList --- local')
      const resOfflineInterfaceList = await this.$http.post(
        this.$api.findByOffline,
        offlineInterfaceList
      )
      console.log(resOfflineInterfaceList, 'resOfflineInterfaceList')
      // 如果本地没有接口版本数据,需要保存,且直接全量下载
      if (offlineInterfaceList.length === 0) {
        await _this.$apiOffline({
          offlineApiHandlers: setOfflineInterfaceListHandler,
          forceOffline: true,
          params: { offlineInterfaceList: resOfflineInterfaceList }
        })
        resOfflineInterfaceList.forEach(async (item) => {
          this.downloadofflineDataByInterface(item.offlineTypeInterfaceName)
        })
      } else {
        // 如果本地有接口版本数据,需要作比较
        offlineInterfaceList.forEach(async (item) => {
          const interfaceName = item.offlineTypeInterfaceName
          const versionChanged = resOfflineInterfaceList.find(
            (i) => i.offlineTypeInterfaceName === interfaceName
          )
          const localOfflineData = await _this.$apiOffline({
            params: { interfaceName },
            offlineApiHandlers: getOfflineDataHandler,
            forceOffline: true
          })
          if (versionChanged || localOfflineData === undefined) {
            // 说明接口变化 或者 当前接口本地离线数据为空，全量加载
            _this.downloadofflineDataByInterface(interfaceName)
            versionChanged && (item.version = versionChanged.version)
          } else {
            // 以下暂时只适配offlineAreaData接口
            const reqOfflineVersion = this.lodash.cloneDeep(localOfflineData)
            reqOfflineVersion.forEach((i) => delete i.list)
            const resOffineDataInc = await this.$http.post(
              api[interfaceName],
              reqOfflineVersion
            )
            // 删除本地无权限的项目
            const offlineData = localOfflineData.filter((i) => {
              if (
                resOffineDataInc.find((it) => i.projectCode === it.projectCode)
              ) {
                return i
              }
            })
            // 添加新增项目
            resOffineDataInc.forEach((it) => {
              // 如果请求数据中的项目，本地没有，需要添加
              if (!offlineData.find((i) => i.projectCode === it.projectCode)) {
                offlineData.push(it)
              }
            })
            const offlineDataListMap = []
            offlineData.forEach((prj) => {
              prj.list.forEach((list) => {
                offlineDataListMap.push(list)
              })
            })
            // 通过本地和增量请求的数据对比整理得到最终想要的设施设备离线数据
            offlineData.forEach((prj) => {
              let localPrjList = prj.list
              let findProject = resOffineDataInc.find(
                (i) => i.projectCode === prj.projectCode
              )
              // 如果该项目的list不为空，则该项目的数据有变动，list为空则Do Nothing
              if (
                findProject &&
                findProject.list &&
                findProject.list.length > 0
              ) {
                findProject.list.forEach((proJectList) => {
                  let targetFacIdx = localPrjList.findIndex(
                    (i) => i.code === proJectList.code
                  )
                  if (
                    proJectList.deleted === '1' ||
                    proJectList.state === '0'
                  ) {
                    targetFacIdx !== -1 && localPrjList.splice(targetFacIdx, 1)
                  } else {
                    if (targetFacIdx !== -1) {
                      localPrjList[targetFacIdx] = proJectList
                    } else {
                      // 考虑设施换项目后，原项目的List进行清除
                      const reProjectList = offlineDataListMap.find((item) => {
                        return item.code === proJectList.code
                      })
                      if (reProjectList) {
                        const reProject = offlineData.find(
                          (i) => i.projectCode === reProjectList.projectCode
                        )
                        const reProjectLitIdx = reProject.list.findIndex(
                          (i) => i.code === reProjectList.code
                        )
                        reProject.list.splice(reProjectLitIdx, 1)
                      }
                      localPrjList.push(proJectList)
                    }
                  }
                })
                // 必须更新version
              }
              prj.version = findProject.version
            })
            saveOfflineAreaDataOfflineApiHandler({
              offlineDataInterfaceName: interfaceName,
              value: offlineData
            })
          }
        })
        await _this.$apiOffline({
          offlineApiHandlers: setOfflineInterfaceListHandler,
          forceOffline: true,
          params: { offlineInterfaceList }
        })
      }
    },
    // 获取本地数据
    async _getProjectInfoCache () {
      try {
        // 拿本地存的项目信息
        const projectInfo = await getProjectInfoHandler()
        console.log('projectInfo _getProjectInfoCache: ', projectInfo)
        // 赋值给查询参数
        if (projectInfo && projectInfo.checkList && projectInfo.checkList.length > 0) {
          this.searchParams.projectCodes = projectInfo.checkList.map((item) => item.deptCode) || []
          console.log('this.$refs.DashboardProjectSelect.$refs.selectProjectM', this.$refs.DashboardProjectSelect.$refs.selectProjectM)
          if (this.$refs.DashboardProjectSelect) {
            this.$refs.DashboardProjectSelect.checkList = this.lodash.cloneDeep(projectInfo.checkList || [])
            if (this.$refs.DashboardProjectSelect.$refs.selectProjectM) {
              this.$refs.DashboardProjectSelect.$refs.selectProjectM.setInitData(this.lodash.cloneDeep(projectInfo))
            }
          }
        }
      } catch (error) {
        console.error('_getProjectInfoCache error: ', error)
      } finally {
        this.calendarRenderAble = true // 因为日历查询的接口需要带上项目参数
      }
    },
    // 二维码扫码搜索点位信息
    async searchCode () {
      const res = await mobile.getQRCodeInfo()
      await setCacheCodeHandler({ code: res, time: this.$moment.timestampToYMDHms() })
      this.$router.push({
        path: '/CodeOrderList',
        query: {
          codes: res,
          qrCode: res,
          isFromScan: true
        }
      })
    },
    // 首页回页面
    toBack () {
      if (this.isOffline) {
        console.log('退回九宫格')
        mobile.backWeb()
      } else {
        this.$dialog
          .confirm({
            title: '返回提醒',
            message: '返回主界面后将不支持离线任务工单处理，是否确认返回？',
            confirmButtonColor: '#3366FE',
            cancelButtonColor: '#3366FE'
          })
          .then(() => {
            mobile.backWeb()
          })
          .catch(() => {})
      }
    },
    tabDisabledHandler () {
      if (!this.isOffline) {
        this.onClickDisabled()
      }
    },
    // 语音回调
    speakRes (text) {
      console.log('语音识别text: ', text)
      if (text.trim() === '') return this.$toast('语音无内容，请重试！')
      this.$router.push({
        path: '/CodeOrderList',
        query: {
          voiceTxt: text,
          isFromScan: true
        }
      })
    }
  },
  beforeRouteLeave (to, from, next) {
    // 保存此时的请求参数
    this.tempSearchParams = this.lodash.cloneDeep(this.searchParams)
    console.log('this.tempSearchParams: ', this.tempSearchParams)
    this.$store.commit('changeDashBoardRefreshMixin', false)
    // 保存日历滚动高度
    this.$refs.Calendar && this.$refs.Calendar.saveScrollTop() // 存储日历滚动位置

    const dashboard = document.querySelector('.dashboard')
    dashboard && (this.dashboardScrollTop = dashboard.scrollTop)
    next()
  }
}
</script>
<style lang="less" scoped>
@import url("./index.less");
</style>
