var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "van-popup",
    {
      style: { width: "100%", height: "100%" },
      attrs: {
        position: "right",
        overlay: false,
        duration: _vm.animateDuration,
      },
      on: {
        open: _vm.selectProjectOpenHandler,
        close: _vm.selectProjectCloseHandler,
      },
      model: {
        value: _vm._show,
        callback: function ($$v) {
          _vm._show = $$v
        },
        expression: "_show",
      },
    },
    [
      _c(
        "div",
        { staticClass: "select-project-wrapper" },
        [
          _c("my-nav", {
            attrs: { title: "报事位置", "self-back": true },
            on: { back: _vm.goBack },
          }),
          !_vm.isProject
            ? _c(
                "div",
                [
                  _c(
                    "van-tabs",
                    {
                      attrs: {
                        animated: "",
                        color: "#3366FE",
                        sticky: "",
                        "offset-top": "44",
                        "title-active-color": "#3366FE",
                        "title-inactive-color": "#7C7F8A",
                        "line-height": "2px",
                        "line-width": "70px",
                      },
                      on: { click: _vm.clickTabs },
                      model: {
                        value: _vm.tabActive,
                        callback: function ($$v) {
                          _vm.tabActive = $$v
                        },
                        expression: "tabActive",
                      },
                    },
                    [
                      _c("van-tab", {
                        attrs: {
                          title: "公区",
                          disabled: _vm.incidentSource === "客户报事",
                        },
                      }),
                      _c("van-tab", { attrs: { title: "业主房屋" } }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c("van-search", {
            staticClass: "search-wrapper",
            attrs: { placeholder: "请输入关键字" },
            on: { search: _vm.searchProject, clear: _vm.clearSearch },
            model: {
              value: _vm.searchValue,
              callback: function ($$v) {
                _vm.searchValue = $$v
              },
              expression: "searchValue",
            },
          }),
          _vm.isProject
            ? _c("div", [
                !_vm.searchList.length
                  ? _c(
                      "div",
                      { staticClass: "project-list-wrapper" },
                      _vm._l(_vm.projectList, function (item) {
                        return _c(
                          "div",
                          {
                            key: item.organCode,
                            staticClass: "project-list",
                            on: {
                              click: function ($event) {
                                return _vm.changeProjectCheck(item)
                              },
                            },
                          },
                          [
                            _c("p", { staticClass: "name" }, [
                              _vm._v(_vm._s(item.organName)),
                            ]),
                            item.children.length !== 0
                              ? _c("i", { staticClass: "right-icon" })
                              : _vm._e(),
                            _vm.showCheck(item)
                              ? _c("i", { staticClass: "check-icon" })
                              : _vm._e(),
                          ]
                        )
                      }),
                      0
                    )
                  : _c(
                      "div",
                      { staticClass: "project-list-wrapper" },
                      _vm._l(_vm.searchList, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "project-list",
                            on: {
                              click: function ($event) {
                                return _vm.changeProjectCheck(item)
                              },
                            },
                          },
                          [
                            _c("p", { staticClass: "name" }, [
                              _vm._v(_vm._s(item.organName)),
                            ]),
                            _vm.showCheck(item)
                              ? _c("i", { staticClass: "check-icon" })
                              : _vm._e(),
                          ]
                        )
                      }),
                      0
                    ),
              ])
            : _vm._e(),
          !_vm.isProject
            ? _c("div", { staticClass: "list_content" }, [
                _c("div", { staticClass: "router-wrapper" }, [
                  _c(
                    "div",
                    { staticClass: "list-wrapper" },
                    _vm._l(_vm.reportRouterList, function (item, index) {
                      return _c(
                        "div",
                        { key: item.organCode, staticClass: "router-list" },
                        [
                          index === 0
                            ? _c("i", {
                                staticClass: "first-icon",
                                on: {
                                  click: function ($event) {
                                    return _vm.checkReportRouter(
                                      item,
                                      index,
                                      "firstIcon"
                                    )
                                  },
                                },
                              })
                            : _vm._e(),
                          _c(
                            "p",
                            {
                              staticClass: "name",
                              class: {
                                last: index === _vm.reportRouterList.length - 1,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.checkReportRouter(item, index)
                                },
                              },
                            },
                            [
                              _c("span", [_vm._v(_vm._s(item.organName))]),
                              item.level === 1
                                ? _c("span", [_vm._v(_vm._s(item.buildName))])
                                : _vm._e(),
                              item.level === 2
                                ? _c("span", [_vm._v(_vm._s(item.unitSNum))])
                                : _vm._e(),
                              item.level === 3
                                ? _c("span", [_vm._v(_vm._s(item.floorName))])
                                : _vm._e(),
                            ]
                          ),
                          index < _vm.reportRouterList.length - 1
                            ? _c("i", { staticClass: "router-icon" })
                            : _vm._e(),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "Position-list-wrapper" },
                  _vm._l(_vm.positionList, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "project-list",
                        on: {
                          click: function ($event) {
                            return _vm.changePositionCheck(item)
                          },
                        },
                      },
                      [
                        item.buildName
                          ? _c("p", { staticClass: "name" }, [
                              _vm._v(_vm._s(item.buildName)),
                            ])
                          : _vm._e(),
                        item.unitSNum
                          ? _c("p", { staticClass: "name" }, [
                              _vm._v(_vm._s(item.unitSNum)),
                            ])
                          : _vm._e(),
                        item.floorName
                          ? _c("p", { staticClass: "name" }, [
                              _vm._v(_vm._s(item.floorName)),
                            ])
                          : _vm._e(),
                        item.level === 4
                          ? _c("p", { staticClass: "name" }, [
                              _vm.tabActive === 1
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        item.roomName +
                                          "(" +
                                          item.memberName +
                                          ")"
                                      )
                                    ),
                                  ])
                                : _vm._e(),
                              _vm.tabActive === 0
                                ? _c("span", [
                                    _vm._v(_vm._s(item.regionalPlace)),
                                  ])
                                : _vm._e(),
                            ])
                          : _vm._e(),
                        item.level !== 4
                          ? _c("i", { staticClass: "right-icon" })
                          : _vm._e(),
                        _vm.showPositionCheck(item)
                          ? _c("i", { staticClass: "check-icon" })
                          : _vm._e(),
                      ]
                    )
                  }),
                  0
                ),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "submit-wrapper", on: { click: _vm.submit } },
            [_c("p", { staticClass: "text" }, [_vm._v("确定")])]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }