<template>
  <div class="create-work">
    <my-nav title="问题记录" :self-back="true" @back="goBack">
      <ScanIcon
        slot="right"
        :code-type="type === 1 ? 'DW_code' : 'SB_code'"
        @scanSuccess="scanSuccess"
      />
    </my-nav>
    <div class="problem-scroll-wrapper">
      <div class="scroll-box">
        <div class="header">
          <div class="header-title">
            {{ type === 1 ? '自查公区': '自查设施设备' }}
          </div>
          <div class="header-text">
            {{
              type === 1
                ? "用于公司内部员工自查时记录公共区域的问题，并进行相关流转处理及跟踪。"
                : "用于公司内部员工自查时记录设施设备的问题，并进行相关流转处理及跟踪"
            }}
          </div>
        </div>
        <van-form class="form-container">
          <div class="subtitle">工单位置</div>
          <van-field
            readonly
            clickable
            name="projectName"
            :value="form.projectName"
            label="所在项目"
            class="form-input"
            required
            right-icon="arrow"
            input-align="right"
            placeholder="请选择"
          />
          <MyFieldOfEquipmentSelect
            @confirm="myFieldOfEquipmentSelectConfirm"
            v-if="type === 3"
            :address.sync="form.address"
            :addressId.sync="form.addressId"
            :reallyType.sync="form.reallyType"
            :projectCode="form.projectCode"
            :categoryCode.sync="form.categoryCode"
            :editable="addressEditable"
          />
          <van-field
            v-else
            readonly
            clickable
            @click="selectPosition"
            name="address"
            :value="form.address"
            label="所在位置"
            class="form-input"
            required
            right-icon="arrow"
            input-align="right"
            placeholder="请选择"
          />
          <div class="subtitle">工单内容</div>
          <MyFieldOfMalfunctionReasonSelect
            required
            @confirm="malfunctionReasonSelectConfirm"
            @goToMalfunctionGuide="goToMalfunctionGuide"
            :point-code="form.addressId"
            :problem-list="form.problemList"
            v-if="type === 3"
            :categoryCode="form.categoryCode"
            :reallyType="form.reallyType"
            :guide="true"
            :patrolItemName="patrolItemName"
          />
          <template v-if="type === 1">
            <van-field
              readonly
              clickable
              label="问题项"
              label-width="150px"
              class="form-input"
              required
              @click="problemItemsShow = true"
              right-icon="arrow"
              input-align="right"
              placeholder="请选择"
            />
            <div class="problem-list" v-if="form.problemList.length > 0">
              <div
                class="item"
                v-for="(item, index) of this.form.problemList"
                :key="index"
              >
                <p class="problem-title">
                  {{
                    `${item.parentName ? item.parentName + "/" : ""}${
                      item.problemName || item.mainPartName
                    }`
                  }}
                </p>
                <p class="problem-info" v-if="item.questLabel.length > 0">
                  <span
                    v-for="(item2, index2) of item.questLabel"
                    :key="index2"
                  >
                    <i v-if="index2 !== 0">|</i
                    >{{ item2.questLabelName || item2 }}</span
                  >
                </p>
                <p
                  class="problem-person"
                  v-if="item.rectifyPeopls && item.rectifyPeopls.name"
                >
                  整改人：{{ item.rectifyPeopls.name }}
                </p>
              </div>
            </div>
          </template>
          <!--
            在记录问题时可自定义选择问题分级（「所属专业不为品质」的不可选择），如有多个问题项则默认取第一个问题项的问题分级；
            禁用条件：patrolItemOptionItem.profession !== 'quality'
            有多个问题项则默认取第一个问题项的问题分级
          -->
          <van-field
            readonly
            :clickable="isQualityTaskOrder"
            @click="selectGrade"
            name="grade"
            :value="gradeValue"
            label="问题分级"
            class="form-input"
            required
            :right-icon="isQualityTaskOrder ? 'arrow' : ''"
            input-align="right"
            :placeholder="`请选择${type === 1? '问题项': '主要部件故障原因'}`"
          />
          <van-field
            name="remark"
            v-model="form.remark"
            label="问题描述"
            rows="3"
            class="form-textarea"
            type="textarea"
            placeholder="请输入问题描述"
          >
            <template v-slot:right-icon v-if="isOffline">
            <micro-text
              @speakRes="speakRes"
            />
            </template>
          </van-field>
          <div style="padding: 0 16px 16px; background: #fff" v-if="patrolItem.reallyType !== '4'">
            <common-upload
              :file.sync="form.attachmentList"
              :project-name="form.projectName"
              required
              ref="offlineUpload"
              :enable-album="true"
              :enable-edit="true"
              :order-id="orderDetail.model.id"
            ></common-upload>
          </div>
        </van-form>
      </div>
    </div>
    <!-- 因为弹窗打开后ios的按钮会显示在顶部（原因未知） 所以根据弹窗判断按钮是否显示 -->
    <div class="btn-container">
      <van-button
        class="btn-submit"
        @click="onSubmit"
        type="info"
        >提交</van-button
      >
    </div>
    <!-- 选择位置(自查公区) -->
    <select-position
      :project-code="form.projectCode"
      :show.sync="selectPositionShow"
      @result="setPosition"
      :data-list="this.orderDetail ? this.orderDetail.pointTreeResps : []"
    ></select-position>
    <!-- 选择问题项 -->
    <problem-items
      :show.sync="problemItemsShow"
      :really-type="patrolItem.reallyType"
      :project-code-prop="form.projectCode"
      @result="setProblemList"
      :order-detail="orderDetail"
      ref="problemItems"
    ></problem-items>
    <!-- 问题分级选择器 -->
    <van-popup v-model="gradeCodeShow" position="bottom">
      <van-picker
        show-toolbar
        title="选择问题分级"
        :columns="gradeCodeColumns"
        :default-index="0"
        value-key="name"
        @cancel="gradeCodeShow = false"
        @confirm="gradeCodeSelectConfirm"
      />
    </van-popup>
  </div>
</template>
<script>
import SelectPosition from './components/SelectPosition'
import ProblemItems from './components/ProblemItems'
import MyFieldOfEquipmentSelect from '@/components/MyFieldOfEquipmentSelect/index'
import MyFieldOfMalfunctionReasonSelect from '@/components/MyFieldOfMalfunctionReasonSelect/index'
import ScanIcon from '@/components/ScanIcon'
import { getOrderDetailOfflineApiHandler } from '@/plugins/offlineMixin/offlineApiHandlers/OrderInfoOfflineApiHandlers'
import { setProblemItem } from '@/plugins/offlineMixin/offlineApiHandlers/PatroListOfflineApiHandler'
import { setHistoryListDataHandler } from '@/plugins/offlineMixin/offlineApiHandlers/HistoryOrderListOfflineApiHandlers'
import { getDictionaryAllOfflineHandler } from '@/plugins/offlineMixin/offlineApiHandlers/DictionaryOfflineApiHandlers'
import { mapState } from 'vuex'

export default {
  name: 'ProblemRecord',
  data () {
    return {
      dataSource: '东e管', // 工单来源
      loading: false,
      selectPositionShow: false, // 显示选择位置(自查公区)弹窗
      selectReasonShow: false, // 显示选择原因类型
      selectHandlerShow: false, // 显示选择处理人
      problemItemsShow: false,
      uploadIndex: 0,
      relationOrderNo: '', // 关联工单编号
      itemId: '', // 任务项ID
      form: {
        reallyType: undefined,
        categoryCode: undefined,
        orderType: 3,
        projectName: '', // 项目名称
        projectCode: '', // 项目编码
        address: '', // 所在位置
        addressId: '', // 所在位置编号
        remark: '', // 工单描述
        problemList: [],
        relationOrderNo: '',
        attachmentList: [],
        grade: '',
        profession: null
      },
      patrolItem: {},
      orderDetail: {},
      type: 3,
      gradeCodeColumns: [],
      gradeCodeShow: false,
      gradeValue: '',
      isQualityTaskOrder: false, // 任务分类是品质
      addressEditable: true, // 是否可编辑设施设备 带过来的不可编辑
      patrolItemName: undefined // 用于故障原因列表锚点定位
    }
  },
  components: {
    ScanIcon,
    MyFieldOfMalfunctionReasonSelect,
    MyFieldOfEquipmentSelect,
    SelectPosition,
    ProblemItems
  },
  beforeRouteEnter (to, from, next) {
    console.log('to, from,: ', to, from)
    next(async vm => {
      if (from.name === 'processingList' && vm.isBackFromProcessingListByIgnore) {
        console.log('vm.closeOrderNos: ', vm.closeOrderNos)
        if (vm.closeOrderNos && vm.closeOrderNos.length > 0) {
          vm.form.close = true
          vm.form.closeOrderNos = vm.closeOrderNos
        }
        await vm.submitOrderInfo()
      }
      // 不是从待处理工单回来的 要重新加载
      if (from.name !== 'processingList') {
        // 返回主页时，清空图片
        vm.$refs.offlineUpload && vm.$refs.offlineUpload.clearAllFile()
        // 清空问题项选择的项目
        vm.$refs.problemItems && vm.$refs.problemItems.clearAllCheckProblem()
        // 返回主页时，清空data数据
        Object.assign(vm.$data, vm.$options.data())
        vm.initPageData()
      }
      vm.$store.commit('setCloseOrderNos', [])
      vm.$store.commit('setIsBackFromProcessingListByIgnore', false)
    })
  },
  beforeRouteLeave (to, from, next) {
    if (to.name === 'PatrolList') {
      this.paramsCopy = null
    }
    this.$store.commit('setCloseOrderNos', [])
    this.$store.commit('setIsBackFromProcessingListByIgnore', false)
    next()
  },
  computed: {
    ...mapState({
      isBackFromProcessingListByIgnore: state => state.pageFormCache.isBackFromProcessingListByIgnore,
      closeOrderNos: state => state.pageFormCache.closeOrderNos
    }),
    isOffline () {
      return this.$store.getters.getIsOffline
    },
    getIsCJ () {
      return this.$store.getters.getIsCJ
    }
  },
  methods: {
    async initPageData () {
      this.addressEditable = true
      const { patroItemId, orderId, problemIndex } = this.$route.query
      console.log('patroItemId1, orderId1: ', patroItemId, orderId)
      const orderDetail = await this.$apiOffline({
        params: { id: orderId },
        offlineApiHandlers: getOrderDetailOfflineApiHandler,
        forceOffline: true
      })
      console.log('orderDetail: ', orderDetail)
      this.orderDetail = orderDetail
      this.form.projectName = this.orderDetail.model.project_name
      this.form.projectCode = this.orderDetail.model.project_code
      const patrolItem = orderDetail.orderTaskItemExtModelList.find(
        (i) => i.itemId === parseInt(patroItemId)
      )
      this.patrolItem = patrolItem
      console.log('this.patrolItem : ', this.patrolItem)
      console.log('patrolItem.reallyType: ', patrolItem.reallyType)
      if (patrolItem.reallyType === '1' || patrolItem.reallyType === '4') { // '4'是监控设备
        this.type = 1 // 自查公区工单
      } else {
        this.type = 3 // 自查设施设备工单
      }
      this.form.reallyType = patrolItem.reallyType

      if (this.orderDetail.model.task_type !== 3 && this.orderDetail.model.task_type !== 6) {
        this.form.addressId = patrolItem.addressId
        this.form.address = `${patrolItem.projectGroupName ? patrolItem.projectGroupName + '/' : ''}` + patrolItem.itemName
        this.form.categoryCode = patrolItem.categoryCode
        this.addressEditable = false
        console.log('this.form11: ', this.form)
        if (this.type === 1 && this.orderDetail.pointTreeResps && this.orderDetail.pointTreeResps.length > 0) {
          console.log('this.orderDetail.pointTreeResps: ', this.orderDetail)
          this.orderDetail.pointTreeResps.forEach((item) => {
            if (item.pointTreePointResps && item.pointTreePointResps.length > 0) {
              item.pointTreePointResps.forEach((list) => {
                console.log(patrolItem.addressId + '--' + list.code)
                if (list.code === patrolItem.addressId) {
                  this.form.address = `${item.name}/${list.name}`
                }
              })
            }
          })
        }
      }
      if (this.getIsCJ && patrolItem.reallyType === '1') { // 如果是承接查验,并且是点位，清空设施设备，重新选
        this.type = 3 // 统一为设施设备
        this.form.reallyType = undefined
        this.form.addressId = ''
        this.form.address = ''
        this.form.categoryCode = undefined
      }
      this.form.relationOrderNo =
      this.orderDetail.model.order_no || this.orderDetail.model.orderNo
      console.log('this.form: ', this.lodash.cloneDeep(this.form))
      this.getDictionaryitemReq()
      console.log('problemIndex: ', problemIndex)
      // 保存点击的步骤item（从分类的options来）
      this.patrolItemOptionItem = patrolItem.options[problemIndex]
      this.patrolItemName = undefined
      console.log('this.patrolItemOptionItem: ', this.patrolItemOptionItem)
      if (this.patrolItemOptionItem) {
        this.isQualityTaskOrder = this.orderDetail.model.categoryProfession === 'quality'
        console.log('this.isQualityTaskOrder: ', this.isQualityTaskOrder)
        this.form.profession = this.patrolItemOptionItem.profession
        this.patrolItemName = this.patrolItemOptionItem.name
      }
    },
    /* 获取关闭类型和关闭类型的具体原因 */
    async getDictionaryitemReq () {
      const dictionaryList = await this.$apiOffline({
        methods: 'get',
        params: { dic_code: 'problemClassify' },
        api: this.$api.dictionaryitem,
        offlineApiHandlers: getDictionaryAllOfflineHandler
      })
      this.gradeCodeColumns = dictionaryList.filter(
        (item) => item.dicCode === 'problemClassify'
      )
      console.log('this.gradeCodeColumns: ', this.gradeCodeColumns)
    },
    goToMalfunctionGuide () {
      this.$store.commit(
        'setProblemRecordForm',
        this.lodash.cloneDeep(this.form)
      )
    },
    myFieldOfEquipmentSelectConfirm () {
      this.form.problemList = []
    },
    scanSuccess (res) {
      if (!res || !res.length) return
      const scanInfo = res[0]
      this.form.projectCode = scanInfo.projectCode
      this.form.projectName = scanInfo.projectName
      if (!scanInfo.pointTreePointResps || !scanInfo.pointTreePointResps.length) {
        return
      }
      this.form.addressId = scanInfo.pointTreePointResps[0].code
      this.form.categoryCode = scanInfo.pointTreePointResps[0].categoryCode // 主要部件原因过滤字段
      this.form.address = `${scanInfo.name}/${scanInfo.pointTreePointResps[0].name}`
      this.form.problemList = []
    },
    validateattachmentList (val) {
      return val.length > 0
    },
    setProject (item) {
      this.form.projectName = item.deptName
      this.form.projectCode = item.deptCode
    },
    // 选择所在位置-自查公区
    selectPosition () {
      if (!this.form.projectCode) {
        this.$toast('请先选择所在项目')
        return
      }
      this.selectPositionShow = true
    },
    // 下面的问题项是和项目关联的，所以选择位置后不用清空问题项
    setPosition (item, parent) {
      this.form.address = parent + '/' + item.name
      this.form.addressId = item.code
    },
    /* 获取到组件传递过来的考评项信息，并且进行处理和渲染 */
    setProblemList (list) {
      console.log('list: ', list)
      this.form.problemList = list
      this.changeGrade(list)
    },
    changeGrade (list) {
      /*
       一、list.length >= 1
        1、取选的问题项第一个

        二、list.length = 0
        清空问题项分级
        1、为【品质】专业的步骤 yes ：不动
        2、为【品质】专业的步骤 no  ：清空form和value

        为【品质】专业的步骤 可以选择
        不为【品质】专业的步骤 不可以选择
      */
      if (list.length === 0) {
        if (!this.isQualityTaskOrder) {
          this.form.grade = ''
          this.gradeValue = ''
        }
      } else {
        const { grade } = list[0]
        const gradeParse = JSON.parse(grade)
        if (gradeParse && Array.isArray(gradeParse)) {
          this.form.grade = JSON.stringify(gradeParse[0])
          this.gradeValue = gradeParse[0].value
        }
      }
    },
    goBack () {
      this.$router.back()
    },
    malfunctionReasonSelectConfirm (problemList) {
      console.log('problemList: ', problemList)
      this.form.problemList = problemList
      this.changeGrade(problemList)
    },
    async onSubmit () {
      if (!this.form.projectCode) {
        this.$toast('请选择所在项目')
        return
      }
      if (!this.form.addressId) {
        const toastTxt = this.type === 1 ? '所在位置' : '设施设备'
        this.$toast(`请选择${toastTxt}`)
        return
      }
      if (this.form.problemList.length === 0) {
        if (this.type === 3) {
          this.$toast('请选择主要部件故障原因')
          return
        } else {
          this.$toast('请选择问题项')
          return
        }
      }
      if (this.patrolItem.reallyType !== '4') { // 远程监控不需要上传图片
        if (!this.form.attachmentList || this.form.attachmentList.length === 0) {
          this.$toast('请上传图片')
          return
        }
      }
      // 如果是在线状态 并且 是品质任务工单
      if (this.isOffline && this.isQualityTaskOrder) {
        // 在线会先查询是否有同位置工单
        const form = this.lodash.cloneDeep(this.form)
        const submitToast = this.$toast.loading({
          message: '正在提交',
          duration: 0, // 持续展示 toast
          forbidClick: true,
          overlay: true
        })
        // 封装查询处理中工单接口参数
        const processingOrderParams = {
          addressId: form.addressId,
          addressType: form.reallyType
        }
        console.log('form.reallyType: ', form.reallyType)
        if (form.reallyType === '1') {
          processingOrderParams.problemIds = form.problemList.map(i => i.problemId)
        } else {
          processingOrderParams.mainPartCodes = form.problemList.map(i => i.mainPartCode)
        }
        console.log('processingOrderParams: ', processingOrderParams)
        const data = await this.$http.post(
          this.$api.orderFindProcessing,
          processingOrderParams, false
        )
        console.log('data: ', data)
        if (data && data.length > 0) {
          this.$dialog.confirm({
            title: '提示',
            message: '该位置存在相同问题正在处理中，确定进入查看详情！',
            cancelButtonText: '继续提交',
            confirmButtonText: '确定'
          }).then(() => {
          // 点确认
            this.$router.push({
              name: 'processingList',
              params: {
                list: data
              }
            })
          }).catch(async () => {
          // 点关闭
          // 弹出提示框点击取消后自动触发提交事件；
            await this.submitOrderInfo()
          })
        } else {
          await this.submitOrderInfo()
        }
        submitToast.clear()
      } else {
        // 离线不变
        await this.submitOrderInfo()
      }
    },
    async submitOrderInfo () {
      try {
        await this.$apiOffline({
          params: this.orderDetail.model,
          offlineApiHandlers: setHistoryListDataHandler,
          forceOffline: true
        })
        const form = this.lodash.cloneDeep(this.form)
        console.log('form: ', form)
        const setProblemItemParams = {
          ...form,
          itemId: this.itemId,
          orderType: this.orderDetail.model.order_type === 16 ? 17 : this.type,
          dataSource: this.dataSource,
          orderDetail: this.orderDetail,
          patroItem: this.patrolItem,
          problemIndex: this.$route.query.problemIndex,
          problemBackPhone: this.$route.query.problemBackPhone
        }
        await this.$apiOffline({
          params: setProblemItemParams,
          offlineApiHandlers: setProblemItem,
          forceOffline: true
        })
        this.goBack()
      } catch (error) {
        console.log('问题记录提交错误：', error)
      } finally {
      }
    },
    speakRes (text) {
      this.form.remark += text
    },
    // 选择问题分级
    selectGrade () {
      // 在记录问题时可自定义选择问题分级（「所属专业不为品质」的不可选择），如有多个问题项则默认取第一个问题项的问题分级；
      if (!this.isQualityTaskOrder) return
      this.gradeCodeShow = true
    },
    gradeCodeSelectConfirm (grade) {
      console.log('grade: ', grade)
      const { code, name, common } = grade
      this.form.grade = JSON.stringify({ code, value: name, color: common })
      this.gradeValue = name
      this.gradeCodeShow = false
    }
  }
}
</script>
<style scoped lang="less">
@import url("./index.less");
</style>
