<template>
  <div>
    <van-field
      readonly
      class="van-field-picker"
      :clickable="isLink"
      :input-align="align"
      :is-link="!disabled && isLink"
      :right-icon="rightIcon"
      :required="required"
      :name="name"
      :label="label"
      :value="value"
      :placeholder="placeholder"
      :rules="rules"
      @click="openPopup"
    >
     <template slot="label">
      <slot name="label"></slot>
     </template>
    </van-field>
    <van-popup
      get-container="#app"
      v-model="showPicker"
      round
      position="bottom"
      :style="{ height: height }"
    >
      <van-picker
        show-toolbar
        :columns="columns"
        @cancel="showPicker = false"
        @confirm="onConfirm"
        :visible-item-count="count"
      />
    </van-popup>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    realValue: {
      type: [String, Number]
    },
    count: {
      type: Number,
      default: 6
    },
    required: {
      type: Boolean,
      default: false
    },
    label: {
      required: true,
      type: String
    },
    name: {
      required: true,
      type: String
    },
    placeholder: {
      type: String,
      default: '请选择'
    },
    columns: {
      required: true,
      type: Array
    },
    height: {
      type: String,
      default: '40vh'
    },
    align: {
      type: String,
      default: 'right'
    },
    rules: {
      typeof: Array,
      default: () => []
    },
    disabled: {
      typeof: Boolean,
      default: false
    },
    rightIcon: {
      type: String,
      default: ''
    },
    isLink: {
      typeof: Boolean,
      default: true
    }
  },
  data () {
    return {
      showPicker: false
    }
  },
  methods: {
    onConfirm (result) {
      console.log(result)
      this.$emit('input', result.text)
      this.$emit('update:realValue', result.value)
      this.showPicker = false
    },
    openPopup () {
      if (this.disabled) return
      this.showPicker = true
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep .van-cell__right-icon {
  padding-top: 4px;
}

</style>
