var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "select-project-input-wrapper" },
    [
      _c(
        "div",
        { staticClass: "input-wrapper", on: { click: _vm.showSelect } },
        [
          _c("p", { staticClass: "info" }, [_vm._v("已选项目：")]),
          _vm.checkList.length > 0
            ? _c("p", { ref: "valueLength", staticClass: "value" }, [
                _vm._v(_vm._s(_vm.inputValue)),
              ])
            : _c("p", { staticClass: "desc" }, [_vm._v("请选择项目")]),
          _vm.lengthShow
            ? _c("p", { staticClass: "text-leng" }, [
                _vm._v("(" + _vm._s(_vm.checkList.length) + ")"),
              ])
            : _vm._e(),
        ]
      ),
      _c("selectProjectM", {
        ref: "selectProjectM",
        attrs: { show: _vm.selectProjectShow },
        on: {
          "update:show": function ($event) {
            _vm.selectProjectShow = $event
          },
          result: _vm.checkResult,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }