<template>
  <van-dialog
    v-model="_show"
    show-cancel-button
    confirm-button-text="确认添加"
    :close-on-popstate="false"
    title="新增费项"
    :style="{ width: '92vw' }"
    getContainer="#app"
    @open="dialogOpen"
    :before-close="(action, done) => beforeDialogClose(action, done)"
  >
    <van-form
      ref="FeeForm"
      validate-first
      class="FeeForm circulation_field"
      validate-trigger="onSubmit"
      :show-error-message="false"
    >
      <van-field
        readonly
        :required="true"
        name="costName"
        right-icon="arrow-down"
        :rules="[{ required: true, message: '请选择费项' }]"
        :value="formData.costName"
        label="费项"
        placeholder="请选择费项"
        @click="selectFeeShow = true"
      />
      <van-popup
        get-container="#app"
        v-model="selectFeeShow"
        round
        position="bottom"
        :style="{ height: '50%' }"
      >
        <van-picker
          show-toolbar
          value-key="costName"
          :columns="feeTree"
          @cancel="selectFeeShow = false"
          @confirm="onFeeConfirm"
        />
      </van-popup>
      <van-field
        readonly
        right-icon="arrow-down"
        :required="true"
        name="stanId"
        :rules="[{ required: true, message: '请选择费项标准' }]"
        :value="formData.stanName"
        label="费项标准"
        placeholder="请选择费项标准"
        @click="openSelectFeeStan"
      />
      <van-popup
        get-container="#app"
        v-model="selectFeeStanShow"
        round
        position="bottom"
        :style="{ height: '50%' }"
      >
        <van-picker
          show-toolbar
          value-key="stanName"
          :columns="feeStan"
          @cancel="selectFeeStanShow = false"
          @confirm="onFeeStanConfirm"
        />
      </van-popup>
      <div class="fee-count">
        <!-- 允许输入数字，调起带符号的纯数字键盘 -->
        <van-field
          v-model="formData.labourAmount"
          :rules="[{ validator: labourAmountValidator, message: '请输入费用金额'}]"
          :formatter="formatter"
          type="number"
          name="labourAmount"
          label="费用金额(元)"
          placeholder="人工费用"
          :show-error-message="false"
        >
        <template slot="label"> <span class="text-[#4e5969]"> <span class="text-[#ee0a24]">* </span>费用金额（元）</span></template>
        </van-field>
        <van-field
          v-model="formData.materialAmount"
          :rules="[{ validator: materialAmountValidator, message: '请输入费用金额'}]"
          :formatter="formatter"
          name="materialAmount"
          type="number"
          label=""
          placeholder="耗材费用"
          :show-error-message="false"
        />
      </div>

      <!-- 文本域 -->
      <van-field
        label="费项备注"
        name="feesMemo"
        show-word-limit
        v-model="formData.feesMemo"
        type="textarea"
        :rows="4"
        placeholder="请输入备注"
        auto-size
      ></van-field>
    </van-form>
  </van-dialog>
</template>

<script>
import { orderfeesAdd, getFees, getFeesStan } from '@/api/report'
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    order: {
      type: Object,
      required: true
    }
  },
  computed: {
    _show: {
      get () {
        return this.show
      },
      set () {
        this.$emit('update:show', false)
      }
    }
  },
  data () {
    return {
      feeTree: [],
      formData: {
        costId: '', // 费项ID,
        costName: '', // 费项名称
        custId: '', // 客户ID,
        feesMemo: '', // 备注,
        projectCode: '', // 项目id,
        stanId: '', // 费用标准ID,
        stanName: '', // 费用标准name,
        twOrderId: '', // 报事ID
        labourAmount: '', // 人工费用
        materialAmount: '', // 耗材费用
        twOrderNumber: ''
      },
      selectFeeShow: false,
      selectFeeStanShow: false,
      feeStan: []
    }
  },
  methods: {
    labourAmountValidator (val) {
      if (val || val === 0) return true
      if (this.formData.materialAmount) return true
      return false
    },
    materialAmountValidator (val) {
      if (val || val === 0) return true
      if (this.formData.labourAmount) return true
      return false
    },
    formatter (value) {
      value = value.replace('-', '')
      if (value.indexOf('.') < 0) {
        return value
      } else {
        return value.replace(/^()*(\d+)\.(\d\d).*$/, '$1$2.$3')
      }
    },
    openSelectFeeStan () {
      if (!this.formData.costId) {
        this.$toast('请先选择费项')
        return
      }
      this.selectFeeStanShow = true
    },
    onFeeConfirm (result, indexResult) {
      console.log('result: ', result)
      let target = this.feeTree[indexResult[0]]
      for (let index = 1; index < indexResult.length; index++) {
        target = target.children[indexResult[index]]
      }
      console.log('target: ', target)

      if (target) {
        if (this.formData.costId !== target.costID) {
          this.formData.stanId = ''
          this.getFeesStan(target.costID)
        }
        this.formData.costId = target.costID
        this.formData.costName = target.costName
      }
      this.selectFeeShow = false
    },
    onFeeStanConfirm (result, index) {
      console.log('result: ', result)
      const stan = this.feeStan[index]
      this.formData.stanId = stan.stanID
      this.formData.stanName = stan.stanName
      this.selectFeeStanShow = false
    },
    async beforeDialogClose (action, done) {
      console.log(this.formData, 'this.formData')
      if (action === 'confirm') {
        try {
          this.$refs.FeeForm.submit()
          await this.$refs.FeeForm.validate()
          const formData = JSON.parse(JSON.stringify(this.formData))
          formData.projectCode = this.order.project_code
          formData.projectName = this.order.project_name
          formData.twOrderId = this.order.twOrderId
          formData.twOrderNumber = this.order.order_no
          formData.custId = this.order.custID
          console.log('formData: ', formData)
          if (!formData.labourAmount && parseInt(formData.labourAmount) !== 0) delete formData.labourAmount
          if (!formData.materialAmount && parseInt(formData.materialAmount) !== 0) delete formData.materialAmount
          const result = await orderfeesAdd(formData)
          console.log('result: ', result)
          this.$toast('添加成功')
          this.$emit('success', result)
          setTimeout(() => {
            done()
          }, 1000)
        } catch (error) {
          if (error && error.length) {
            error[0].message && this.$toast(error[0].message)
          }
          console.log('error: ', error)
          done(false)
        }
      } else {
        done()
      }
    },
    async getFeesReq () {
      const result = await getFees({
        projectCode: this.order.project_code
      })
      const feeTree = this.arrayToTree(result, 'costCode', 'prentCostCode')
      this.feeTree = feeTree
    },
    async getFeesStan (costId) {
      const result = await getFeesStan({
        costId,
        projectCode: this.order.project_code
      })
      this.feeStan = result
    },
    arrayToTree (array, id_name, parentId_name) {
      const result = []
      const itemsMap = {}
      for (const item of array) {
        itemsMap[item[id_name]] = { ...item }
      }
      this.feeTreeMap = itemsMap
      for (const item of array) {
        const id = item[id_name]
        const parentId = item[parentId_name]
        const treeItem = itemsMap[id]
        if (!parentId) {
          result.push(treeItem)
        } else {
          if (!itemsMap[parentId]) {
            itemsMap[parentId] = { children: [] }
          }
          if (!itemsMap[parentId].children) {
            itemsMap[parentId].children = []
          }
          itemsMap[parentId].children.push(treeItem)
        }
      }
      return result
    },
    dialogOpen () {
      Object.assign(this.formData, this.$options.data().formData)
      this.getFeesReq()
    }
  }
}
</script>

<style lang="less" scoped>
.circulation_field {
  ::v-deep .van-cell--required .van-cell__title.van-field__label span:after{
    display: none;
  }
  ::v-deep .van-cell--required .van-cell__title.van-field__label span::before {
    background: url('~@/asset/icon_star_red@2x.png') no-repeat;
    background-size: contain;
    width: 8px;
    height: 8px;
    display: inline-block;
    content: "";
    top: -2px;
    position: relative;
    margin-right: 4px;
  }
  ::v-deep & .van-field {
    padding: 4px 16px;
    display: block;
    &.readonly {
      .van-field__control {
        color: #989898;
      }
    }
    .van-field__label {
      width: 100%;
      color: #4e5969;
    }
    & > .van-field__value {
      margin-top: 4px;
      padding: 4px 8px;
      background-color: #f7f8fa;
      border-radius: 4px;
    }
  }
  .fee-count{
    display: flex;
    align-items: flex-end;
  }
  ::v-deep & > .circulation_upload {
    .van-field__value {
      background-color: #ffffff;
      padding: 0px;
    }
  }
}
</style>
