var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "no-auth" },
    [
      _c("my-nav", {
        attrs: { title: "远程监控", "self-back": true },
        on: { back: _vm.toBack },
      }),
      _vm._m(0),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "img-content" }),
      _c("span", [_vm._v("暂无该板块权限")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }