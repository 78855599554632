var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "processingList" },
    [
      _c("my-nav", { attrs: { title: "处理中工单" } }),
      _c(
        "div",
        { staticClass: "list-wrapper" },
        _vm._l(_vm.list, function (item, index) {
          return _c(
            "div",
            { key: index, staticClass: "list-content" },
            [
              _c("problem-order-card", {
                attrs: { order: item },
                on: { click: _vm.routerToOrderDetail },
              }),
              !item.isChoosed
                ? _c("van-icon", {
                    staticClass: "choose-icon",
                    attrs: { name: "circle", color: "#1989fa", size: "30px" },
                    on: {
                      click: function ($event) {
                        return _vm.chooseOrder(index)
                      },
                    },
                  })
                : _c("van-icon", {
                    staticClass: "ml-6px",
                    attrs: { name: "passed", color: "#1989fa", size: "30px" },
                    on: {
                      click: function ($event) {
                        return _vm.chooseOrder(index)
                      },
                    },
                  }),
            ],
            1
          )
        }),
        0
      ),
      _c("div", { staticClass: "oprateBtnWrapper" }, [
        _c(
          "div",
          { staticClass: "oprateBtn blue", on: { click: _vm.ignore } },
          [_vm._v("忽略")]
        ),
        _c(
          "div",
          { staticClass: "oprateBtn yellow", on: { click: _vm.cancelOrder } },
          [_vm._v("关闭")]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }