<template>
  <van-popup v-model="show" position="bottom" :style="{ width: '100%', height: '85%' }">
    <div class="select-position-header clear">
      {{title}}
      <div @click="show = false" class="btn-close pull-right">
        <van-icon name="cross" style="vertical-align: top" color="#000" size="16px" />
      </div>
    </div>
    <div v-if="dataList && dataList.length > 0" class="select-positon-content">
      <div class="action-cell" v-for="(item,index) of dataList" @click="selectItems(item,index)"
        :key="item.code">
        <div>{{ item.name }}</div>
        <div :class="{'icon-fork':item.isforked}"></div>
      </div>
    </div>
    <div v-else class="select-positon-content no-data">
      <span v-if="isOffline">无{{title}}</span>
      <span v-else>当前无网络，请提前在有网络的情况下下载离线包！</span>
    </div>
  </van-popup>
</template>
<script>
export default {
  name: 'usualSelect',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '选择框标题'
    },
    /* 需要渲染的选项 */
    list: {
      type: Array,
      require: true
    }
  },
  data () {
    return {
      loading: false,
      show: false,
      selectHandlerName: '',
      activeNames: [],
      dataList: [],
      dataResult: {},
      ops: {
        vuescroll: {
          mode: 'slide'
        },
        scrollPanel: {
          scrollingX: false,
          scrollingY: true
        },
        rail: {
          size: '4px',
          keepShow: false
        },
        bar: {
          size: '4px',
          background: '#eee'
        }
      }
    }
  },
  computed: {
    isOffline () {
      return this.$store.getters.getIsOffline
    }
  },
  methods: {
    /* 渲染列表 */
    renderList () {
    },
    selectItems (item, index) {
      this.dataList[index].isforked = true
      this.show = false
      this.$emit('result', item)
    }
  },
  watch: {
    value (val) {
      this.show = val
      if (val) {
        this.dataList = this.list.map(item => {
          this.$set(item, 'isforked', false)
          return item
        })
      }
    },
    show (val) {
      this.$emit('input', val)
    }
  }
}
</script>
<style scoped lang="less">
.select-position-header {
  padding: 15px 16px;
  font-size: 16px;
  // font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 601;
  color: #222222;
  line-height: 22px;
  border-bottom: 1px solid #e8e8e8;
}
.select-positon-content {
  height: calc(100% - 55px);
  padding-bottom: 16px;
  box-sizing: border-box;
  .select-item {
    line-height: 48px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #222222;
    .success-icon {
      color: #3d69f5;
      margin-top: 14px;
    }
  }
}
.btn-close {
  padding: 5px;
  margin-top: -2px;
  height: 26px;
  box-sizing: border-box;
}
.action-cell {
  padding: 0 12px 0 16px;
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  .icon-fork {
    width: 24px;
    height: 24px;
    background: url("../../../asset/icon_right_blue@2x.png") no-repeat;
    background-size: contain;
  }
}
</style>
