import { render, staticRenderFns } from "./ProblemItems.vue?vue&type=template&id=1405c772&scoped=true"
import script from "./ProblemItems.vue?vue&type=script&lang=js"
export * from "./ProblemItems.vue?vue&type=script&lang=js"
import style0 from "./ProblemItems.vue?vue&type=style&index=0&id=1405c772&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1405c772",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/app/jenkins/data/workspace/TEST测试环境/品控SIT环境/radish-mobile-ui-test/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1405c772')) {
      api.createRecord('1405c772', component.options)
    } else {
      api.reload('1405c772', component.options)
    }
    module.hot.accept("./ProblemItems.vue?vue&type=template&id=1405c772&scoped=true", function () {
      api.rerender('1405c772', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/problemRecord/components/ProblemItems.vue"
export default component.exports