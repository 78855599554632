<template>
  <div class="RemoteMonitorOrderRecheck">
    <my-nav title="整改复核"></my-nav>
    <div class="title-wrapper">
      <p class="title">{{ recheckDetail.itemName }}</p>
      <p class="desc">
        {{ recheckDetail.qualityStandards }}
      </p>
      <p class="desc">
        {{ recheckDetail.projectName }}
      </p>
    </div>
    <div class="img-swiper">
      <van-swipe
        ref="remoteMonitorImgSwiperRef"
        class="my-swipe"
        :loop="false"
        indicator-color="white"
      >
        <van-swipe-item
          v-for="(item, index) in remoteMonitorCaptureImages"
          :key="index"
        >
          <div class="remoteMonitorImg">
            <span
              class="remoteMonitorImg-tag"
              :class="{ realtime: index === 0 }"
              >{{ index === 0 ? "实时" : "整改前" }}</span
            >
            <!-- <img @click="remoteMonitorImgPreview(index)" :src="item" style="width: 100vw; object-fit: contain"/> -->
            <van-image width="98vw" height="200px" :src="item" fit="contain">
              <template v-slot:loading>
                <van-loading type="spinner" size="20" />
                加载中
              </template>
            </van-image>
          </div>
        </van-swipe-item>
      </van-swipe>
      <div class="my-10px checkStandards-wrapper">
        <div style="color: #222; font-size: 16px" @click="collapse = !collapse">
          检查标准<van-icon class="ml-10px" name="arrow-down" />
        </div>
        <div class="checkStandards" :class="{ collapse: collapse }">
          {{ recheckDetail.checkStandards }}
        </div>
      </div>
    </div>
    <div class="init-issue">初始问题：{{ recheckDetail.originMemo }}</div>
    <div class="result-record" v-if="captureSuccess">
      <van-field label="结果记录：" input-align="right" border>
        <template #input>
          <van-radio-group
            v-model="recheckDetail.isPass"
            direction="horizontal"
            class="mt10"
          >
            <van-radio
              v-for="item in isPassOption"
              :key="item.code"
              :name="item.code"
              >{{ item.name }}
              <template #icon="props">
                <img
                  class="img-icon"
                  width="22px"
                  height="22px"
                  :src="
                    props.checked
                      ? require('@/asset/radio-active.png')
                      : require('@/asset/radio-default.png')
                  "
                />
              </template>
            </van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field
        v-if="recheckDetail.isPass === 0"
        readonly
        clickable
        is-link
        name="picker"
        :value="chooseProblem.name"
        label="考评标签："
        placeholder="点击选择问题项"
        @click="showProblemItemPicker = true"
      />
      <van-popup v-model="showProblemItemPicker" position="bottom">
        <van-picker
          show-toolbar
          value-key="name"
          :columns="remoteProblemList"
          @confirm="onProblemConfirm"
          @cancel="showProblemItemPicker = false"
        />
      </van-popup>
      <van-field
        v-if="recheckDetail.isPass === 0"
        readonly
        clickable
        name="picker"
        :value="chooseProblemLabelStr"
        label="问题标签："
        is-link
        placeholder="点击选择问题标签"
        @click="openChooseProblemLabel"
      />
      <van-popup v-model="showProblemLabelPicker" position="bottom">
        <!-- <van-picker
              show-toolbar
              value-key="name"
              :columns="remoteProblemList"
              @confirm="onProblemLabelConfirm"
              @cancel="showProblemLabelPicker = false"
            /> -->
        <div class="checkbox-con" style="overflow-y: auto; max-height: 464px">
          <div class="van-picker__toolbar">
            <button
              type="button"
              class="van-picker__cancel"
              @click="onProblemLabelCancel"
            >
              取消
            </button>
            <div class="van-ellipsis van-picker__title">问题标签</div>
            <button
              type="button"
              class="van-picker__confirm"
              @click="onProblemLabelConfirm"
            >
              确认
            </button>
          </div>
          <van-checkbox-group v-model="checkboxValue" ref="checkboxGroup">
            <van-cell-group>
              <van-cell
                v-for="(item, index) in problemLabelData"
                clickable
                :key="item"
                :title="item"
                @click="toggle(index, item)"
              >
                <template #right-icon>
                  <van-checkbox :name="item" shape="square" ref="checkboxes" />
                </template>
              </van-cell>
            </van-cell-group>
          </van-checkbox-group>
        </div>
      </van-popup>
      <van-field
        v-if="recheckDetail.isPass === 0"
        v-model="recheckMemo"
        label="备注："
        placeholder="请输入备注"
        type="textarea"
        rows="3"
      />
    </div>
    <div v-if="!captureSuccess" class="capture-fail">
      请点击下方【重新抓拍】按钮进行抓拍，抓拍成功后方可进行复核
    </div>
    <div class="footer">
      <van-button class="foot-btn" plain @click="routerBack">取消</van-button>
      <van-button
        v-if="captureSuccess"
        class="foot-btn"
        type="primary"
        @click="handleSubmit"
        >确定</van-button
      >
      <van-button
        v-if="!captureSuccess"
        class="foot-btn"
        type="primary"
        @click="handleOrderTaskCapture"
        >重新抓拍</van-button
      >
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  data () {
    return {
      collapse: true,
      loading: true,
      orderNo: '',
      remoteMonitorCaptureImages: [],
      recheckDetail: {},
      isPassOption: [
        { code: 1, name: '合格' },
        { code: 0, name: '不合格' }
      ],
      showProblemItemPicker: false,
      showProblemLabelPicker: false,
      remoteProblemList: [],
      chooseProblem: {},
      checkboxValue: [],
      checkboxValueResult: [],
      problemLabelData: [],
      captureSuccess: true,
      recheckMemo: ''
    }
  },
  mounted () {
    this.orderNo = this.$route.query.orderNo
    this.getDetail()
    this.getRemoteProblemList()
  },
  computed: {
    chooseProblemLabelStr () {
      return this.checkboxValueResult.length > 1
        ? `${this.checkboxValueResult.join('，').slice(0, 10)}...(${
          this.checkboxValueResult.length
        })`
        : this.checkboxValueResult.join('，')
    }
  },
  methods: {
    async getDetail () {
      const result = await this.$http.get(
        this.$api.getRemoteRecheckQuery + this.orderNo
      )
      console.log('result: ', result)
      result.isPass = undefined
      this.recheckDetail = result
      this.remoteMonitorCaptureImages[0] = undefined
      this.remoteMonitorCaptureImages[1] = this.recheckDetail.rectifyUrl
      this.handleOrderTaskCapture()
    },
    async handleOrderTaskCapture () {
      try {
        const result = await this.$http.post(
          this.$api.orderTaskCapture,
          {
            orderNo: this.recheckDetail.relationOrderNo,
            pointCode: this.recheckDetail.itemCode
          },
          false
        )
        if (result) {
          // 抓拍成功
          this.realTimeUrl = result
          const remoteMonitorCaptureImages = JSON.parse(
            JSON.stringify(this.remoteMonitorCaptureImages)
          )
          remoteMonitorCaptureImages[0] = this.realTimeUrl
          this.remoteMonitorCaptureImages = remoteMonitorCaptureImages
          this.captureSuccess = true
        } else {
          this.$toast('抓取失败')
          this.$refs.remoteMonitorImgSwiperRef.swipeTo(1)
          this.captureSuccess = false
        }
      } catch (error) {
        console.error('error: ', error)
      }
    },
    openChooseProblemLabel () {
      if (!this.chooseProblem || !this.chooseProblem.id) {
        return this.$toast('请先选择问题项')
      }
      this.showProblemLabelPicker = true
    },
    toggle (index) {
      this.$refs.checkboxes[index].toggle()
    },
    onProblemConfirm (e) {
      console.log(e)
      if (e.id !== this.chooseProblem.id) this.checkboxValue = []
      this.chooseProblem = e
      this.showProblemItemPicker = false
      this.problemLabelData = e.questions
      console.log(this.problemLabelData, 'this.problemLabelData')
    },
    onProblemLabelConfirm () {
      this.checkboxValueResult = this.checkboxValue
      this.showProblemLabelPicker = false
    },
    onProblemLabelCancel () {
      this.checkboxValue = this.checkboxValueResult
      this.showProblemLabelPicker = false
    },
    async getRemoteProblemList () {
      const result = await this.$http.get(this.$api.getProblemList, {
        code: 'questionCategory14'
      })
      this.remoteProblemList = result
    },
    routerBack () {
      this.$router.back()
    },
    async handleSubmit () {
      if (!this.recheckDetail.isPass && this.recheckDetail.isPass !== 0) {
        return this.$toast('请选择是否合格')
      }
      if (
        this.recheckDetail.isPass === 0 &&
        (!this.chooseProblem || !this.chooseProblem.id)
      ) {
        return this.$toast('请选择考评标签')
      }
      if (
        this.recheckDetail.isPass === 0 &&
        (!this.checkboxValueResult || !this.checkboxValueResult.length)
      ) {
        return this.$toast('请至少选择一个及以上问题标签')
      }
      const submitLoading = this.$toast.loading({
        message: '提交中...',
        forbidClick: true
      })
      try {
        const formData = JSON.parse(JSON.stringify(this.recheckDetail))
        formData.fileUrl = this.realTimeUrl
        formData.memo = this.recheckMemo
        if (formData.isPass === 0) {
          // 不合格并且未整改
          formData.problemReq = {
            problemRecordList: [
              {
                createTime: moment().format('YYYY-MM-DD HH:mm:ss'),
                problemList: [
                  {
                    problemId: this.chooseProblem.id,
                    // processLimit: this.orderDetail.process_limit,
                    questLabel: JSON.stringify(this.checkboxValueResult)
                  }
                ]
              }
            ]
          }
        }
        const result = await this.$http.post(
          this.$api.remoteRecheckApply,
          formData
        )
        console.log('result: ', result)
        this.$store.commit('changeDashBoardRefreshMixin')
        this.$toast('提交成功')
        setTimeout(() => {
          this.$router.go(-2)
        }, 1200)
      } catch (error) {
        console.log(error)
      } finally {
        submitLoading.clear()
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import url("./index.less");
</style>
