import http from './base/index'
import store from '@/store'

const addTwUserCodeToParams = async (params = {}) => {
  return new Promise(async (resolve, reject) => {
    if (store.getters.getTwUserCode) {
      resolve(Object.assign(params, {
        userCode: store.getters.getTwUserCode
      }))
    } else {
      store.dispatch('GetTwUserCode').then(userCode => {
        resolve(Object.assign(params, {
          userCode: userCode
        }))
      })
    }
  })
}

// 获取工单池列表
const radishOrderTWList = async function (params) {
  return http.post('/woadmin/order/radishOrderTWList', await addTwUserCodeToParams(params))
}
// 列表count
const radishOrderTWListCount = async function (data) {
  return http.post(`/woadmin/order/radishOrderTWListCount`, await addTwUserCodeToParams(data))
}
// 获取工单详情
const findOrderDetail = async function (twOrderId, isFollowup) {
  return http.get('/woadmin/order/detail/findOrderDetail', await addTwUserCodeToParams({ twOrderId, id: 0, isFollowup }))
}
// 获取公区列表
const twGetRegions = async function (projectCode) {
  return http.get('/woadmin/tianwen/api/getRegions', await addTwUserCodeToParams({ projectCode }), false)
}
// 投诉还原新增
const incidentTousuAdd = async function (data) {
  return http.post('/woadmin/incident/tousu/add', await addTwUserCodeToParams(data))
}
// 投诉还原列表
const incidentTousuList = async function (data) {
  return http.post('/woadmin/incident/tousu/list', await addTwUserCodeToParams(data))
}
// 工单流转
const orderFlow = async function (data) {
  return http.post('/woadmin/flow/handle/order', await addTwUserCodeToParams(data))
}
// 工单审核列表
const getApproveList = async function (data) {
  return http.post('/woadmin/approve/list', await addTwUserCodeToParams(data))
}

// 报事类型
const getTWIncidentDrClassReq = async function () {
  return http.post('/woadmin/tianwen/api/getIncidentDrClass', await addTwUserCodeToParams())
}
// 报事方式
const getTWIncidentModeReq = async function () {
  return http.post('/woadmin/tianwen/api/getIncidentMode', await addTwUserCodeToParams())
}
// 责任人列表
const getTWHandlersList = async function (data) {
  return http.post('/woadmin/tianwen/api/getHandlers', await addTwUserCodeToParams(data))
}
// 报事类别（原因大类）
const getTWIncidentBigCategory = async function (data) {
  return http.post('/woadmin/tianwen/api/getIncidentBigCategory', await addTwUserCodeToParams(data))
}
// 报事类别（原因细类）
const getTWIncidentSmallCategory = async function (data) {
  return http.post('/woadmin/tianwen/api/getIncidentSmallCategory', await addTwUserCodeToParams(data))
}
// 获取组织树
const getTWProjectTree = async function () {
  return http.get('/woadmin/incident/org', await addTwUserCodeToParams())
}
// 房屋楼栋
const getTWBuildings = async function (data) {
  return http.post('/woadmin/tianwen/api/getBuildings', await addTwUserCodeToParams(data))
}
// 房屋单元
const getTWUnits = async function (data) {
  return http.post('/woadmin/tianwen/api/getUnits', await addTwUserCodeToParams(data))
}
// 房屋楼层
const getTWFloors = async function (data) {
  return http.post('/woadmin/tianwen/api/getFloors', await addTwUserCodeToParams(data))
}
// 所在位置
const getTWRooms = async function (data) {
  return http.post('/woadmin/tianwen/api/getRooms', await addTwUserCodeToParams(data))
}
// 公区列表
const getTWRegions = async function (data) {
  return http.post('/woadmin/tianwen/api/getRegions', await addTwUserCodeToParams(data))
}
// 创建工单
const createReportEventOrder = async function (data) {
  return http.post('/woadmin/order/create/createReportEventOrder', await addTwUserCodeToParams(data))
}
// 创建工单
const getArrivedSelects = async function (data) {
  return http.post('/woadmin/tianwen/api/getArrivedSelects', await addTwUserCodeToParams(data))
}
// 工单详情 - simple版
const findOrderDetailSimple = async function (twOrderId) {
  return http.get(`/woadmin/incident/order/pool/flowDetail/${twOrderId}`, await addTwUserCodeToParams())
}
// 工单详情 - simple版
const orderfeesAdd = async function (data) {
  return http.post(`/woadmin/orderfees/add`, await addTwUserCodeToParams(data))
}
// 费用项目列表
const getFees = async function (data) {
  return http.post(`/woadmin/tianwen/api/getFees`, await addTwUserCodeToParams(data))
}
// 费用项目列表
const getFeesStan = async function (data) {
  return http.post(`/woadmin/tianwen/api/getFeesStan`, await addTwUserCodeToParams(data))
}
// 费用项目列表
const isSupervise = async function (data) {
  return http.post(`/woadmin/incident/user/isSupervise`, await addTwUserCodeToParams(data))
}

export {
  radishOrderTWList,
  findOrderDetail,
  twGetRegions,
  getApproveList,
  incidentTousuAdd,
  orderFlow,
  incidentTousuList,
  getTWIncidentDrClassReq,
  getTWIncidentModeReq,
  getTWHandlersList,
  getTWIncidentBigCategory,
  getTWIncidentSmallCategory,
  getTWProjectTree,
  getTWBuildings,
  getTWUnits,
  getTWFloors,
  getTWRooms,
  getTWRegions,
  createReportEventOrder,
  getArrivedSelects,
  findOrderDetailSimple,
  orderfeesAdd,
  getFees,
  getFeesStan,
  isSupervise,
  radishOrderTWListCount
}
