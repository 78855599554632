<template>
    <van-popup v-model="_show" position="right" :overlay="false"
      :style="{ width: '100%', height: '100%' }" :duration="animateDuration" @open="selectProjectOpenHandler" @close="selectProjectCloseHandler">
      <div class="select-project-wrapper">
        <my-nav :title="confirmType === 'member' ? '选择报事客户' : '选择所在位置'" :self-back="true" @back="goBack">
        </my-nav>
        <van-search v-model="searchValue" placeholder="请输入关键字" @search="customerSearch"
          @clear="clearSearch" class="search-wrapper" />
        <!-- 项目 -->
        <div class="project-list-wrapper" v-if="showLevel === 'project'">
          <div class="project-list" v-for="item in projectList" :key="item.deptCode"
            @click="changeProjectCheck(item)">
            <p class="name">{{ item.deptName }}</p>
            <i class="right-icon"></i>
          </div>
        </div>
        <!-- 期 -->
        <div class="project-list-wrapper" v-if="showLevel === 'groops'">
          <div class="project-list" v-for="item in buildingCodeOptions" :key="item.buildNo"
            @click="changeGroopCheck(item)">
            <p class="name">{{ item.buildName }}</p>
            <i class="right-icon"></i>
          </div>
        </div>
        <!-- 楼栋 -->
        <div class="project-list-wrapper" v-if="showLevel === 'builds'">
          <div class="project-list" v-for="item in buildingCodeOptions" :key="item.buildNo"
            @click="changeBuildCheck(item)">
            <p class="name">{{ item.buildName }}</p>
            <i class="right-icon"></i>
          </div>
        </div>
        <!-- 单元 -->
        <div class="project-list-wrapper" v-if="showLevel === 'units'">
          <div class="project-list" v-for="item in unitsList" :key="item.unitNo"
            @click="changeUnitCheck(item)">
            <p class="name">{{ item.unitName }}</p>
            <i class="right-icon"></i>
          </div>
        </div>
        <!-- 楼层 -->
        <div class="project-list-wrapper" v-if="showLevel === 'floors'">
          <div class="project-list" v-for="item in floorsList" :key="item.floorNo"
            @click="changeFloorCheck(item)">
            <p class="name">{{ item.floorName }}</p>
            <i class="right-icon"></i>
          </div>
        </div>
        <!-- 房间 -->
        <div class="project-list-wrapper" v-if="showLevel === 'house'">
          <div class="project-list" v-for="item in houseList" :key="item.houseId"
            @click="changeHouseCheck(item)">
            <p class="name">{{ item.houseName }}</p>
            <i class="right-icon" v-if="confirmType === 'member'"></i>
            <i class="check-icon" v-if="confirmType === 'house' && selectHouse && selectHouse.houseId === item.houseId"></i>
          </div>
        </div>
        <!-- 人员 -->
        <div class="project-list-wrapper" v-if="showLevel === 'member'">
          <van-tabs >
            <van-tab title="客户列表">
              <div class="project-list" v-for="item in memberList" :key="item.memberName+item.houseCode+item.houseId+Math.random()"
                @click="changeMemberCheck(item)">
                <p class="name">{{ `${item.memberName} ${item.linkmanMobile} ${item.relationShip}` }}</p>
                <i class="check-icon" v-if="selectMember && (selectMember.memberName === item.memberName && selectMember.houseId === item.houseId)"></i>
              </div>
            </van-tab>
            <van-tab title="近期报事工单">
              <van-list
                v-model="threeMonthReportEventOrdersLoading"
                :finished="threeMonthReportEventOrdersLoadAll"
                finished-text="没有更多了"
                @load="threeMonthReportEventOrdersLoadingOnLoad"
              >
              <div class="order-list">
                <problemOrderCardVue @click="clickOrder" style="margin-top:8px" v-for="order in threeMonthReportEventOrders" :order="order" :key="order.id"></problemOrderCardVue>
              </div>
              </van-list>
            </van-tab>
          </van-tabs>

        </div>
        <div class="router-wrapper" v-if="routerListShow">
          <div class="list-wrapper">
            <div class="router-list" v-for="(item, index) in routerList" :key="index">
              <p class="name" :class="{ last: index === routerList.length - 1 }"
                @click="checkRouter(index < routerList.length - 1, item)">
                {{ item.label }}
              </p>
              <i class="router-icon" v-if="index < routerList.length - 1"></i>
            </div>
          </div>
        </div>
        <div class="submit-wrapper" v-show="confirmType === 'member' && showLevel === 'member'" @click="submit">
          <p class="text">确定</p>
        </div>
        <div class="submit-wrapper" v-show=" confirmType === 'house' && showLevel === 'house'" @click="submit">
          <p class="text">确定</p>
        </div>
      </div>
    </van-popup>
</template>

<script type='text/ecmascript-6'>
import api from '@/api'
import problemOrderCardVue from '../problemOrderCard.vue'
export default {
  name: '',
  components: { problemOrderCardVue },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    selectType: {
      type: String,
      default: 'checkbox'
    },
    confirmType: {
      type: String,
      default: 'member'
    },
    routerListProp: {
      type: Array,
      default: () => []
    },
    chooseHouse: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      showLevel: 'project', // project 项目  'builds' 楼栋 units 单元 floors 楼层 house 房间 member 人员
      searchValue: '',
      projectList: [],
      checkCode: '',
      routerList: [],
      routerListShow: true,
      animateDuration: 0.3, // 默认弹窗动画时间0.3s
      buildingCodeOptions: [], // 楼栋list
      unitsList: [], // 单元list
      floorsList: [], // 楼层list
      houseList: [], // 房屋list
      memberList: [], // 人员List
      selectMember: null, // 选择的人员
      selectHouse: null,

      threeMonthReportEventOrders: [], // 近三个月工单
      threeMonthReportEventOrdersLoadAll: false, // 近三个月工单 加载完
      threeMonthReportEventOrdersLoading: false,
      threeMonthReportEventOrdersPage: 1,
      threeMonthReportEventOrdersHouseId: undefined,
      tempProjectCode: undefined
    }
  },
  computed: {
    isOffline () {
      return this.$store.getters.getIsOffline
    },
    _show: {
      get () {
        return this.show
      },
      set (val) {
        console.log('val: ', val)
        this.$emit('update:show', val)
      }
    }
  },
  watch: {
    _show (val) {
      console.log('_show watch val: ', val)
      val && this._getProjectList()
    }
  },
  methods: {
    async customerSearch () {
      console.log(this.searchValue)
      if (!this.searchValue) {
        this.clearSearch()
      } else {
        this.routerListShow = false
        this.routerList = []
        const res = await this.$http.post(api.houseSearchList, {
          param: this.searchValue,
          page: 1,
          limit: 999,
          scope: this.confirmType === 'house' ? 1 : 0
        })
        console.log('res: ', res)
        if (res.total === 0) {
          this.$toast('查询为空')
        }
        if (res.records && res.records.length > 0) {
          if (res.records[0].dataType === 1) {
            this.showLevel = 'house'
            this.houseList = res.records
          } else {
            this.showLevel = 'member'
            this.memberList = res.records
          }
        }
      }
    },
    clickOrder (order) {
      const query = { orderId: order.orderId, orderNo: order.orderNo }
      this.$router.push({
        path: '/OrderDetail', query
      })
    },
    // popup打开
    selectProjectOpenHandler () {
      console.log(this.routerListProp, 'this.routerListProp')
      console.log(this.confirmType, 'this.confirmType')
      if (this.confirmType === 'house') {
        if (this.routerListProp.length > 0) {
          const houseRouterIndex = this.routerListProp.findIndex(i => i.nextType === 'house')
          const memberRouterIndex = this.routerListProp.findIndex(i => i.nextType === 'member')
          if (houseRouterIndex !== -1 && memberRouterIndex !== 1) {
            this.showLevel = 'house'
            this.getHouseListReq(this.routerListProp[houseRouterIndex])
            this.routerList = this.routerListProp.slice(0, houseRouterIndex + 1)
            this.selectHouse = this.routerListProp[memberRouterIndex]
          }
          console.log('this.selectHouse: ', this.selectHouse)
        } else {
          this.routerList = []
          this.selectHouse = this.chooseHouse
          console.log('this.selectHouse11111: ', this.selectHouse)
        }
      }
      // window.history.pushState(null, null, '#') // 模拟新的一页history记录
      // window.addEventListener('popstate', this.popstateHandler)
    },
    popstateHandler (e) {
      this.animateDuration = 0 // 防止侧滑出现多次动画
      this._show = false
    },
    // popup关闭
    selectProjectCloseHandler () {
      // window.removeEventListener('popstate', this.popstateHandler, false)
    },
    _getProjectList () {
      if (!this.isOffline) return
      this.$http
        .get(api.getOrg, {
          parent_code: this.checkCode
        })
        .then((res) => {
          this.projectList = res
        })
    },
    goBack () {
      // window.history.back()
      this._show = false
    },
    clearSearch () {
      this.checkCode = ''
      this.showLevel = 'project'
      this.projectList = []
      this.routerList = []
      this.routerListShow = true // 搜索的时候不显示
      this._getProjectList()
    },
    addRouer (nextType, label, item) {
      console.log('nextType: ', nextType)
      const router = this.lodash.cloneDeep(item)
      console.log('router: ', router)
      router.nextType = nextType
      router.label = label
      this.routerList.push(router)
      console.log(this.routerList, 'this.routerList')
    },
    // 点击项目
    changeProjectCheck (item) {
      console.log(item, 'changeProjectCheck')
      if (!this.isOffline) {
        this.$EB.$emit(this.$EBT.NO_NETWORK_WARN)
        return
      }
      if (item.deptType !== '3') {
        this.checkCode = item.deptCode
        this._clearSearchValue()
        this._getProjectList()
        // this.routerList.push(item)
        this.addRouer('project', item.deptName, item)
      } else {
        this.tempProjectCode = item.deptCode
        this.projectSource = item.source
        console.log('this.projectSource: ', this.projectSource)
        // 获取项目下的楼栋
        this.getProjectBuildsReq(item.deptCode)
        if (this.projectSource === 'tw') {
          this.showLevel = 'builds'
          this.addRouer('builds', item.deptName, item)
        }
        if (this.projectSource === 'saas') {
          this.showLevel = 'groops'
          this.addRouer('groops', item.deptName, item)
        }
      }
    },
    // 获取项目下的楼栋
    getProjectBuildsReq (projectCode, parentCode) {
      console.log('projectCode, parentCode: ', projectCode, parentCode)
      this.buildingCodeOptions = []
      if (!projectCode) return
      this.$http
        .get(api.getProjectBuilds + `/${projectCode}`, {
          parentCode
        })
        .then(res => {
          this.buildingCodeOptions = res
        })
    },
    // 点击期、只有saas项目才会有期
    changeGroopCheck (build) {
      console.log('changeGroopCheck build: ', build)
      // this.getBuildUnitsReq(build)
      // this.addRouer('units', build.buildName, build)
      // this.showLevel = 'units'
      this.handleClickSaasNode(build)
    },
    // 点击楼栋
    changeBuildCheck (build) {
      console.log('changeBuildCheck build: ', build)
      if (this.projectSource === 'tw') {
        this.getBuildUnitsReq(build)
        this.addRouer('units', build.buildName, build)
        this.showLevel = 'units'
      } else {
        this.handleClickSaasNode(build)
      }
    },
    handleClickSaasNode (build) {
      console.log('handleClickSaasNode build: ', build)
      if (build.type === 'builds') {
        this.getProjectBuildsReq(build.projectCode, build.parentCode)
        this.showLevel = 'builds'
        this.addRouer('builds', build.buildName, build)
      }
      if (build.type === 'unit') {
        this.getBuildUnitsReq(build)
        this.addRouer('units', build.buildName, build)
        this.showLevel = 'units'
      }
      if (build.type === 'floor') {
        console.log('-----unit: ', build)
        this.getUnitFloorsReq(build)
        this.addRouer('floors', build.buildName, build)
        this.showLevel = 'floors'
      }
      if (!build.type) {
        this.changeFloorCheck(build)
      }
    },
    // 点击单元
    changeUnitCheck (unit) {
      console.log('unit: ', unit)
      this.getUnitFloorsReq(unit)
      this.addRouer('floors', unit.unitName, unit)
      this.showLevel = 'floors'
    },
    // 点击楼层
    changeFloorCheck (floor) {
      console.log('floor: ', floor)
      this.getHouseListReq(floor)
      this.addRouer('house', floor.floorName, floor)
      this.showLevel = 'house'
    },
    // 点击房屋
    changeHouseCheck (house) {
      if (this.confirmType === 'member') {
        console.log('house: ', house)
        this.getMemberListReq(house)
        this.threeMonthReportEventOrdersHouseId = house.houseId
        this.threeMonthReportEventOrdersPage = 1
        this.threeMonthReportEventOrders = []
        this.getThreeMonthReportEventOrdersReq(house.houseId)
        this.addRouer('member', house.houseCode, house)
        this.routerListShow && this.addRouer('member', house.houseCode, house)
        this.showLevel = 'member'
      }
      if (this.confirmType === 'house') {
        this.selectHouse = house
      }
    },
    threeMonthReportEventOrdersLoadingOnLoad () {
      this.threeMonthReportEventOrdersPage++
      this.getThreeMonthReportEventOrdersReq()
    },
    getThreeMonthReportEventOrdersReq () {
      console.log(this.threeMonthReportEventOrdersHouseId)
      this.threeMonthReportEventOrdersLoading = true
      if (!this.threeMonthReportEventOrdersHouseId) return
      this.$http
        .get(api.getThreeMonthReportEventOrders + `/${this.threeMonthReportEventOrdersHouseId}/${this.threeMonthReportEventOrdersPage}/5`, {
          projectCode: this.tempProjectCode
        })
        .then((result) => {
          this.threeMonthReportEventOrders = this.threeMonthReportEventOrders.concat(result.records)
          this.threeMonthReportEventOrdersLoadAll = this.threeMonthReportEventOrders.length >= result.total
          console.log('this.threeMonthReportEventOrders: ', this.threeMonthReportEventOrders)
        }).finally(() => {
          this.threeMonthReportEventOrdersLoading = false
        })
    },
    changeMemberCheck (member) {
      console.log('member: ', member)
      this.selectMember = member
      // 搜索状态下，点击用户要重新加载报事工单
      if (!this.routerListShow && this.threeMonthReportEventOrdersHouseId !== member.houseId) {
        // 重置报事工单
        this.threeMonthReportEventOrdersHouseId = member.houseId
        this.threeMonthReportEventOrders = []
        this.threeMonthReportEventOrdersLoadAll = false
        this.threeMonthReportEventOrdersPage = 1
        this.getThreeMonthReportEventOrdersReq()
      }
    },
    //  获取房间人员列表
    getMemberListReq (house) {
      console.log('house: ', house)
      this.memberList = []
      if (!house.houseId) return
      this.$http
        .get(api.getMembers + `/${house.houseId}`, {
          projectCode: this.tempProjectCode
        })
        .then((result) => {
          this.memberList = result
          console.log('this.memberList : ', this.memberList)
        })
    },
    //  获取房屋列表
    getHouseListReq (unit) {
      this.houseList = []
      unit.page = 1
      unit.limit = 999
      if (!unit) return
      this.$http
        .post(api.getHouseList, unit)
        .then((result) => {
          this.houseList = result.records
          console.log('this.houseList: ', this.houseList)
        })
    },
    //  获取单元下的楼层
    getUnitFloorsReq (unit) {
      this.floorsList = []
      if (!unit) return
      const obj = {}
      if (unit.assetCode) (obj.assetCode = unit.assetCode)
      this.$http
        .get(api.getUnitFloors + `/${unit.projectCode}/${unit.buildNo}/${unit.unitNo}`, obj)
        .then((result) => {
          this.floorsList = result
          console.log('this.floorsList: ', this.floorsList)
        })
    },
    //  获取楼栋下的单元
    getBuildUnitsReq (build) {
      this.unitsList = []
      if (!build) return
      const obj = {}
      if (build.assetCode) {
        obj.assetCode = build.assetCode
      }
      this.$http
        .get(api.getBuildUnits + `/${build.projectCode}/${build.buildNo}`, obj)
        .then((result) => {
          this.unitsList = result
          console.log('this.unitsList: ', this.unitsList)
        })
    },
    checkRouter (boolean, item) {
      console.log('boolean: ', boolean)
      console.log('item: ', item)
      console.log('this.routerList: ', this.routerList)
      if (boolean) {
        this.routerList.splice(this.routerList.indexOf(item) + 1)
        this.showLevel = item.nextType
        if (item.nextType === 'project') {
          this.checkCode = item.deptCode
          this._clearSearchValue()
          this._getProjectList()
        } else if (item.nextType === 'groops') {
          this.getProjectBuildsReq(item.deptCode)
        } else if (item.nextType === 'builds') {
          if (this.projectSource === 'tw') {
            this.getProjectBuildsReq(item.deptCode)
          }
          if (this.projectSource === 'saas') {
            this.getProjectBuildsReq(item.projectCode, item.parentCode)
          }
        } else if (item.nextType === 'units') {
          this.getBuildUnitsReq(item)
        } else if (item.nextType === 'floors') {
          this.getUnitFloorsReq(item)
        } else if (item.nextType === 'house') {
          this.getHouseListReq(item)
        } else if (item.nextType === 'member') {
          this.getMemberListReq(item)
        }
      }
    },
    _clearSearchValue () {
      this.searchValue = ''
    },
    /**
     * 因为存在有保存的默认项目，初始化时会执行这一步
     * routerBack 是否返回上一页
     */
    async submit () {
      // const obj = {
      //   searchValue: this.searchValue,
      //   checkCode: this.checkCode,
      //   checkList: this.lodash.cloneDeep(this.checkList), // 避免变量污染
      //   routerList: this.routerList
      // }
      console.log('submit', this.selectMember)
      if (this.confirmType === 'member') {
        if (!this.selectMember) return this.$toast('请选择报事客户')
        this.$emit('result', this.lodash.cloneDeep(this.selectMember))
      }
      if (this.confirmType === 'house') {
        if (!this.selectHouse) return this.$toast('请选择位置')
        this.$emit('result', this.lodash.cloneDeep(this.selectHouse))
      }
      const routerList = this.lodash.cloneDeep(this.routerList)
      if (this.confirmType === 'house') {
        const selectHouse = this.lodash.cloneDeep(this.selectHouse)
        selectHouse.nextType = 'member'
        routerList.push(selectHouse)
      }
      this.$emit('router', routerList)
      this.goBack()
    }
  }
}
</script>
<style lang='less' scoped>
.select-project-wrapper {
  height: 100%;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  p {
    margin: 0;
  }
  .icon-back {
    height: 24px;
    width: 24px;
    background: url("../../asset/icon_back_black@2x.png") no-repeat;
    background-size: cover;
  }
  .multi-wrapper {
    display: flex;
    .multi-icon {
      width: 24px;
      height: 24px;
    }
    .multi-default {
      background: url("../../asset/multi-default.png") no-repeat;
      background-size: 100% 100%;
    }
    .multi-check {
      background: url("../../asset/multi-check.png") no-repeat;
      background-size: 100% 100%;
    }
    .multi-all {
      background: url("../../asset/multi-all.png") no-repeat;
      background-size: 100% 100%;
    }
    .title {
      font-size: 16px;
      font-weight: 400;
      color: #222222;
      line-height: 22px;
    }
  }
  .search-wrapper {
    width:100%;
    padding: 6px 16px;
    /deep/ .van-search__content {
      background-color: #f5f6f6;
    }
  }
  .project-list-wrapper {
    // max-height: calc(100vh - 44px -46px);
    overflow-y: scroll;
    width: 100%;
    // padding-bottom: 30px;
    flex: 1;
    .project-list {
      height: 48px;
      display: flex;
      padding: 0 16px;
      align-items: center;
      justify-content: space-between;
      .name {
        font-size: 16px;
        font-weight: 400;
        color: #222222;
        line-height: 24px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      .right-icon {
        width: 16px;
        height: 16px;
        background: url("../../asset/right-icon.png") no-repeat;
        background-size: 100% 100%;
      }
      .check-icon {
        width: 24px;
        height: 24px;
        background: url("../../asset/icon_right_blue@2x.png") no-repeat;
        background-size: 100% 100%;
      }
    }
    .order-list{
      padding: 6px 10px 0px;
      background-color: #ccc;
      ::v-deep .van-list__finished-text{
        line-height: 20px;
      }
    }
  }
  .router-wrapper {
    // position: fixed;
    // bottom: 0px;
    // left: 0;
    width: 100%;
    background-color: #fff;
    padding-bottom: 10px;
    // padding-bottom: env(safe-area-inset-bottom);
    .list-wrapper {
      display: flex;
      padding: 0 16px;
      // width: 100%;
      flex-wrap: wrap;
      // overflow-x: scroll;
    }
    .router-list {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      margin-top: 8px;
      .name {
        font-size: 16px;
        font-weight: 400;
        color: #3366fe;
        line-height: 16px;
        &.last {
          color: #8b8f9a;
        }
      }
      .router-icon {
        width: 16px;
        height: 16px;
        margin: 0 6px;
        background: url("../../asset/right-icon.png") no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .submit-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 64px;
    height: 64px;
    background: linear-gradient(90deg, #62a9ff 0%, #3370fe 100%);
    box-shadow: 0px 2px 6px 0px rgba(51, 102, 254, 0.4);
    border-radius: 50%;
    text-align: center;
    overflow: hidden;
    position: fixed;
    right: 16px;
    bottom: 76px;
    .count {
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      line-height: 1;
    }
    .text {
      font-size: 16px;
      font-weight: 400;
      color: #ffffff;
      line-height: 1;
      margin-top: 5px;
    }
  }
}
</style>
