var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "select-project-wrapper" },
    [
      _c(
        "div",
        { staticClass: "flex flex-col h-full" },
        [
          _c(
            "my-nav",
            {
              attrs: { title: "点位列表", "self-back": true },
              on: { back: _vm.goBack },
            },
            [
              _c(
                "div",
                {
                  staticClass: "flex items-center",
                  attrs: { slot: "right" },
                  on: { click: _vm.batchSelection },
                  slot: "right",
                },
                [
                  _c("i", { staticClass: "multi-icon", class: _vm.checkIcon }),
                  _c("div", { staticClass: "title ml-4px" }, [_vm._v("全选")]),
                  _c("div"),
                ]
              ),
            ]
          ),
          _c("div", { staticClass: "container_main flex flex-1 h-full" }, [
            _c(
              "div",
              {
                staticClass:
                  "class_main w-100px bg-light-500 h-full overflow-y-auto",
              },
              _vm._l(_vm.classList, function (item) {
                return _c(
                  "div",
                  {
                    key: item.code,
                    staticClass:
                      "flex justify-center items-center py-16px px-4px font-normal text-14px text-center",
                    class: {
                      class_active: item.code === _vm.search.aimCategory,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.selectClass(item)
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(item.value) + " ")]
                )
              }),
              0
            ),
            _c(
              "div",
              { staticClass: "flex-1 bg-white h-full overflow-y-auto" },
              [
                _c("van-search", {
                  staticClass: "search-wrapper_point",
                  attrs: { placeholder: "请输入点位名称、编码关键字" },
                  on: { input: _vm.searchPoint, clear: _vm.clearSearch },
                  model: {
                    value: _vm.search.pointCodeOrName,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.search,
                        "pointCodeOrName",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "search.pointCodeOrName",
                  },
                }),
                _c(
                  "div",
                  { staticClass: "point_main overflow-y-auto" },
                  [
                    _c(
                      "van-list",
                      {
                        staticClass: "mb-8px",
                        attrs: {
                          finished: _vm.finished,
                          "immediate-check": false,
                        },
                        on: { load: _vm.onLoad },
                        model: {
                          value: _vm.loading,
                          callback: function ($$v) {
                            _vm.loading = $$v
                          },
                          expression: "loading",
                        },
                      },
                      _vm._l(_vm.childrenData, function (item) {
                        return _c(
                          "div",
                          {
                            key: item.code,
                            staticClass: "px-16px py-10px flex",
                            on: {
                              click: function ($event) {
                                return _vm.childrenSelect(item)
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "flex-1 point_left" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "point_name text-18px font-normal text-black truncate",
                                },
                                [_vm._v(" " + _vm._s(item.name) + " ")]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "point_subtitle point_code text-16px truncate",
                                },
                                [_vm._v(" " + _vm._s(item.code) + " ")]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "point_subtitle point_code text-16px truncate",
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(item.location || "-") + " "
                                  ),
                                ]
                              ),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "w-24px flex justify-end items-center",
                              },
                              [
                                item.select
                                  ? _c("img", {
                                      attrs: {
                                        width: "24px",
                                        src: require("../../asset/icon_right_blue@2x.png"),
                                        alt: "",
                                      },
                                    })
                                  : _vm._e(),
                              ]
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                    !_vm.childrenData.length
                      ? _c(
                          "div",
                          { staticClass: "text-center text-gray-300" },
                          [_vm._v(" —— 无数据 —— ")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c("transition", { attrs: { name: "translateY" } }, [
        this.$store.state.pointStorage.pointList.length
          ? _c(
              "div",
              {
                staticClass:
                  "confirm_icon fixed right-16px bottom-80px flex flex-col justify-center items-center rounded-full w-64px h-64px",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.confirmBtn.apply(null, arguments)
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "text-white text-14px leading-20px" },
                  [
                    _vm._v(
                      " (" +
                        _vm._s(
                          this.$store.state.pointStorage.pointList.length
                        ) +
                        ") "
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "text-white text-16px leading-24px" },
                  [_vm._v("确定")]
                ),
              ]
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }