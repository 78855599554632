<template>
  <div class="ReportTousuList">
    <my-nav title="投诉还原详情"></my-nav>
    <div class="empty" v-if="!incidentTouSu || incidentTouSu.length === 0">
      <div class="img"></div>
      <span>暂无数据</span>
    </div>
    <div v-else class="ReportTousuList-content">
      <div
        class="tousu-item"
        v-for="(item, index) in incidentTouSu"
        :key="index"
      >
        <div class="mt-4px">
          <span class="label">投诉性质：</span>
          <span>{{ item.complaintType }}投诉</span>
        </div>
        <div class="mt-4px">
          <span class="label">投诉等级：</span>
          <span>{{ item.touSuDegree }}</span>
        </div>
        <div class="mt-4px">
          <span class="label">操作人：</span>
          <span>{{ item.userName }}</span>
        </div>
        <div class="mt-4px">
          <span class="label">操作时间：</span>
          <span>{{ item.dateTime }}</span>
        </div>
        <div class="mt-4px">
          <div class="label">投诉还原内容：</div>
          <div>{{ item.touSuReasons }}</div>
        </div>
        <div class="mt-4px" v-if="item.touSuImg && item.touSuImg.length >0">
          <div class="label">图片：</div>
          <div>
            <van-image
              width="40"
              height="40"
              radius="4"
              class="photo mr-6px mt-6px"
              v-for="(img, index) of item.touSuImg"
              @click="imgPreview(index, item.touSuImg)"
              :key="index"
              :src="img"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="ReportTousuList-footer" v-if="addAble">
      <div class="add-btn" @click="routerToAdd">新增投诉还原</div>
    </div>
  </div>
</template>

<script>
import { incidentTousuList } from '@/api/report'
import { ImagePreview } from 'vant'
export default {
  data () {
    return {
      incidentTouSu: [],
      projectCode: '',
      twOrderId: '',
      addAble: false
    }
  },
  mounted () {
    const { projectCode, twOrderId, addAble } = this.$route.query
    this.$set(this, 'projectCode', projectCode)
    this.$set(this, 'twOrderId', twOrderId)
    this.$set(this, 'addAble', addAble === '1')
    this.getIncidentTousuList()
  },
  methods: {
    async getIncidentTousuList () {
      const result = await incidentTousuList({
        projectCode: this.projectCode,
        twOrderId: this.twOrderId
      })
      if (result && result.length > 0) {
        result.forEach(item => {
          item.touSuImg = item.touSuImg && item.touSuImg.split(',').filter(item => !!item)
        })
      }
      this.incidentTouSu = result
    },
    /* 图片查看器 */
    imgPreview (index, attachmentList) {
      ImagePreview({
        images: attachmentList,
        startPosition: index
      })
    },
    routerToAdd () {
      this.$router.push({
        name: 'ReportTousuAdd',
        query: {
          projectCode: this.projectCode,
          twOrderId: this.twOrderId
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import url("./index.less");
</style>
