<template>
  <div class="ReportOrderPool">
    <my-nav blue title="工单池" :self-back="true" @back="goBack"></my-nav>
    <van-sticky :offset-top="navBarHeight">
      <div class="filter-wrapper" v-if="searchParams.incidentDealStateName !== '未分派'">
        <van-search
          v-model="searchParams.positionName"
          placeholder="报事位置"
          @click="onSearch"
          read-only
        >
          <div slot="right-icon" >
            <van-icon name="clear" v-if="searchParams.positionName" @click.stop="onSearchClear"></van-icon>
          </div>
        </van-search>
        <div class="filter-tag">
          <div
            class="tag"
            :class="{ active: searchParams.isHandler === 1 }"
            @click="changeHandleOrSupervise('isHandler')"
          >
            我处理
          </div>
          <div
            class="tag"
            :class="{ active: searchParams.isSuperVise === 1 }"
            @click="changeHandleOrSupervise('isSuperVise')"
          >
            我监管
          </div>
        </div>
        <van-badge :dot="hasFilter">
          <div class="filter-entry" @click="filterPopupShow = true"></div>
        </van-badge>
      </div>
      <section class="tag-wrapper" v-else>
        <span
          class="ordercheck-type"
          :class="{ active: searchParams.belongProfessionals && searchParams.belongProfessionals.indexOf(item) > -1 }"
          @click="changeProfession(item)"
          v-for="(item, index) in checkTypes"
          :key="index"
          >{{ item }}</span
        >
      </section>
      <van-tabs
        animated
        color="#3366FE"
        title-active-color="#3366FE"
        title-inactive-color="#7C7F8A"
        line-height="2px"
        line-width="56px"
        @change="tabChange"
        v-model="searchParams.incidentDealStateName"
        class="orderStatusTab"
      >
        <van-tab name="未分派">
          <template v-slot:title>
            <div class="tab-class">
              <div>待分派</div>
              <!-- <div>{{ `（${count['待分派']}）` }}</div> -->
            </div>
          </template>
        </van-tab>
        <van-tab name="处理中">
          <template v-slot:title>
            <div class="tab-class">
              <div>处理中</div>
              <!-- <div>{{ `（${count['处理中']}）` }}</div> -->
            </div>
          </template>
        </van-tab>
        <van-tab name="已完成">
          <template v-slot:title>
            <div class="tab-class">
              <div>已完成</div>
              <!-- <div>{{ `（${count['已完成']}）` }}</div> -->
            </div>
          </template>
        </van-tab>
        <van-tab name="已关闭">
          <template v-slot:title>
            <div class="tab-class">
              <div>已关闭</div>
              <!-- <div>{{ `（${count['已关闭']}）` }}</div> -->
            </div>
          </template>
        </van-tab>
        <van-tab name="已回访">
          <template v-slot:title>
            <div class="tab-class">
              <div>已回访</div>
              <!-- <div>{{ `（${count['已回访']}）` }}</div> -->
            </div>
          </template>
        </van-tab>
      </van-tabs>
    </van-sticky>

    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        :immediate-check="false"
        :offset="50"
        v-model="loading"
        :finished="finished"
        :finished-text="
          list.length > 0 ? '- 没有更多数据了 -' : '您没有处理的工单'
        "
        @load="onLoad('list')"
      >
        <div
          class="order"
          @click="handleClickOrderItem(item)"
          v-for="(item, index) in list"
          :key="index"
        >
          <div class="order_header">
            <div class="left">
              <span
                class="order_type"
                :class="{
                  zhixu: item.category_name === '秩序',
                  huanjing: item.category_name === '环境',
                  gongcheng: item.category_name === '工程',
                  kefu: item.category_name === '客服',
                }"
              ></span>
              <span>{{ item.category_name }}</span>
            </div>
            <span class="status">{{ item.orderStatusName }}</span>
          </div>
          <div class="order_main">
            <van-row>
              <van-col span="5">工单编号：</van-col>
              <van-col span="19">
                <span>{{ item.orderNo }}</span>
                <van-tag class="ml-4px" v-if="item.isTouSu === 1" type="danger">投诉</van-tag>
                <van-tag class="ml-4px" v-if="!!item.reminderCount" color="#FD8D37" type="danger">催办{{item.reminderCount}}次</van-tag>
              </van-col>
            </van-row>
            <van-row>
              <van-col span="5"
                >{{
                  item.incidentSource === "自查报事" ? "报事员工" : "报事业主"
                }}：</van-col
              >
              <van-col span="19">{{ item.reportUserName }}</van-col>
            </van-row>
            <van-row>
              <van-col span="5">报事位置：</van-col>
              <van-col span="19">{{ item.addressName }}</van-col>
            </van-row>
            <van-row>
              <van-col span="5">报事时间：</van-col>
              <van-col span="19">{{ item.incidentDate }}</van-col>
            </van-row>
            <van-row>
              <van-col span="5">预约时间：</van-col>
              <van-col span="19">{{ item.bookingTime }}</van-col>
            </van-row>
            <van-row>
              <van-col span="5">报事描述：</van-col>
              <van-col class="order_disc" span="19">{{ item.remark }}</van-col>
            </van-row>
          </div>
          <div class="order_footer" v-if="routeListComp(item.routeList).length">
            <div
              v-for="(button, index) in routeListComp(item.routeList)"
              :key="index"
              class="button"
              @click.stop="intoCirculation(button, item)"
            >
              {{ button.buttonName }}
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
    <filter-popup
      :show.sync="filterPopupShow"
      @confirm="filterPopupConfirm"
      ref="filterPopup"
    ></filter-popup>
    <circulation
      v-if="circulationShow"
      @ok="getInitList"
      :order="circulationOrder"
      :title="circulationTitle"
      :field-config="currentFieldConfig"
      :show.sync="circulationShow"
    ></circulation>
    <SelectReportPosition
      :show.sync="selectPositionShow"
      @result="selectPositionConfim"
      ref="selectProject"
    ></SelectReportPosition>
  </div>
</template>

<script>
import mobile from '@/utils/mobile'
import filterPopup from './components/filter-popup.vue'
import { radishOrderTWList, findOrderDetailSimple, radishOrderTWListCount } from '@/api/report'
import {
  setReportQueryFormHandler,
  getReportQueryFormHandler
} from '@/plugins/offlineMixin/offlineApiHandlers/SelectProjectOfflineApi.js'
import circulation from '../components/circulation.vue'
import SelectReportPosition from '../CreateOrder/components/selectReportPosition.vue'
export default {
  name: 'ReportOrderPool',
  components: { filterPopup, circulation, SelectReportPosition },
  data () {
    return {
      circulationTitle: '',
      circulationOrder: {},
      circulationShow: false,
      currentFieldConfig: '',
      searchParams: {
        roomSign: '', // 房屋编号
        isHandler: 1,
        isSuperVise: 0,
        incidentDealStateName: '未分派',
        pageIndex: 1,
        pageSize: 10,
        belongProfessional: '',
        commID: '',
        positionName: '',
        regionalID: undefined,
        belongProfessionals: []
      },
      checkTypes: ['客服', '工程', '秩序', '环境', '管理'],
      count: {
        '待分派': 0,
        '处理中': 0,
        '已完成': 0,
        '已关闭': 0,
        '已回访': 0
      },
      refreshing: false,
      loading: false,
      finished: false,
      list: [],
      navBarHeight: 0,
      filterPopupShow: false,
      hasFilter: false,
      selectPositionShow: false
    }
  },
  computed: {
    routeListComp () {
      return function (array) {
        return (array && array.length) ? array.filter(i => !i.hidden) : []
      }
    }
  },
  beforeRouteLeave (to, from, next) {
    mobile.setHeaderBackColor('0xFFFFFFFF', '0x00000000')
    next()
  },
  beforeRouteEnter (to, from, next) {
    next((vm) => {
      mobile.setHeaderBackColor('0x3366FEFF', '0xFFFFFFFF')
    })
  },
  mounted () {
    this.$nextTick(async () => {
      this.navBarHeight = document.querySelector('.van-nav-bar')?.offsetHeight
      await this.getReportQueryFormHandlerAndSet()
      this.getCurrentProjectAndSet()
      // this.getInitList()
    })
  },
  methods: {
    selectPositionConfim (result) {
      console.log('result: ', result)
      const position = result.checkPositionList[0]
      console.log('position: ', position)
      if (position.regionalID) {
        this.searchParams.regionalID = position.regionalID
        this.searchParams.roomSign = ''
        this.searchParams.positionName = position.regionalPlace
      }
      if (position.roomID) {
        this.searchParams.roomSign = position.roomSign
        this.searchParams.regionalID = undefined
        this.searchParams.positionName = position.roomSign
      }
      this.getInitList()
    },
    changeProfession (belongProfessional) {
      if (!this.searchParams.belongProfessionals) this.searchParams.belongProfessionals = []
      const index = this.searchParams.belongProfessionals.indexOf(belongProfessional)
      if (index > -1) {
        this.searchParams.belongProfessionals.splice(index, 1)
      } else {
        this.searchParams.belongProfessionals.push(belongProfessional)
      }
      this.getInitList()
    },
    async getReportQueryFormHandlerAndSet () {
      const result = await getReportQueryFormHandler()
      console.log('result: ', result)
      if (result) {
        if (!result.belongProfessionals) result.belongProfessionals = []
        this.searchParams = result
        this.$refs.filterPopup &&
          this.$refs.filterPopup.initParams(this.searchParams)
        console.log(
          'getReportQueryFormHandlerAndSet this.searchParams: ',
          this.searchParams
        )
      }
    },
    openVoiceMic () {
      mobile.voiceInput((resultText) => {
        if (resultText) {
          // 判断识别结果是否以中文符号结尾
          const reg =
            /(.+(?=[|\u3002|\uff1f|\uff01|\uff0c|\u3001|\uff1b|\uff1a|\u201c|\u201d|\u2018|\u2019|\uff08|\uff09|\u300a|\u300b|\u3008|\u3009|\u3010|\u3011|\u300e|\u300f|\u300c|\u300d|\ufe43|\ufe44|\u3014|\u3015|\u2026|\u2014|\uff5e|\ufe4f|\uffe5]$))/
          if (reg.test(resultText)) {
            resultText = resultText.substring(0, resultText.length - 1)
          }
          console.log('resultText: ', resultText)
          this.searchParams.roomSign += resultText
          this.getInitList()
        }
      })
    },
    async getCurrentProjectAndSet () {
      try {
        const result = await mobile.getCurrentProject()
        console.log('getCurrentProject result: ', result)
        if (result && result.CommID && result.CommID !== '0') {
          this.searchParams.commID = result.CommID
          this.getInitList()
        } else {
          this.alertNoProjectAndBack()
        }
      } catch (error) {
        this.$notify({
          type: 'warning',
          message: error.message
        })
        this.alertNoProjectAndBack()
      }
    },
    alertNoProjectAndBack () {
      this.$dialog
        .alert({
          title: '提示',
          message: '请返回首页选择项目！',
          confirmButtonColor: '#3366FE',
          confirmButtonText: '知道了'
        })
        .then(() => {
          this.goBack()
        })
    },
    changeHandleOrSupervise (mode) {
      this.searchParams[mode] = this.searchParams[mode] === 1 ? 0 : 1
      const otherMode = mode === 'isHandler' ? 'isSuperVise' : 'isHandler'
      if (this.searchParams[mode] && this.searchParams[otherMode]) {
        this.searchParams[otherMode] = 0
      }
      if (mode === 'isSuperVise') {
        this.searchParams.incidentDealStateName = '处理中'
      }
      this.getInitList()
    },
    goBack () {
      mobile.backWeb()
    },
    onSearch () {
      this.selectPositionShow = true
      // this.getInitList()
    },
    onSearchClear () {
      this.searchParams.positionName = ''
      this.searchParams.regionalID = undefined
      this.searchParams.roomSign = ''
      this.getInitList()
    },
    tabChange () {
      this.getInitList()
    },
    onRefresh () {
      this.getInitList()
    },
    getInitList () {
      this.searchParams.pageIndex = 1
      this.finished = false
      this.list = []
      this.setReportQueryFormHandler()
      this.onLoad()
      this.getListCount()
    },
    async getListCount () {
      const result = await radishOrderTWListCount(this.searchParams)
      console.log('result: ', result)
      if (result) {
        this.count = result
      }
    },
    async onLoad () {
      try {
        this.loading = true
        const searchParams = JSON.parse(JSON.stringify(this.searchParams))
        searchParams.belongProfessional = (searchParams.belongProfessionals || []).join(',')
        if (searchParams.incidentDealStateName !== '未分派') delete searchParams.belongProfessional
        const result = await radishOrderTWList(searchParams)
        console.log('result: ', result)
        if (result.records && result.records.length > 0) {
          result.records.forEach((item) => {
            item.routeList = (item.routeList || []).reduce((arr, button) => {
              if (!button.forbidden) {
                if (this.searchParams.incidentDealStateName === '未分派' && (button.buttonName !== '派单' && button.buttonName !== '抢单')) {
                  button.hidden = true
                }
                arr.push(button)
                if (button.buttonName === '完成' && item.isBeoverdue === 1) {
                  button.buttonName = '超时完成'
                }
              }
              return arr
            }, [])
          })
          this.list = this.list.concat(result.records)
        }
        if (result.records.length === 0) {
          this.finished = true
        }
        this.searchParams.pageIndex++
      } catch (error) {
        console.error('onLoad error: ', error)
        this.finished = true
      } finally {
        this.refreshing = false
        this.loading = false
      }
    },
    async intoCirculation (button, order) {
      const result = await findOrderDetailSimple(order.id)
      if (
        result.isTouSu === 1 &&
        (button.buttonName === '完成' || button.buttonName === '超时完成') &&
        !result.incidentTouSu
      ) {
        this.$dialog
          .confirm({
            title: '提示',
            message: '投诉类工单，请先添加投诉还原!',
            confirmButtonText: '去添加',
            cancelButtonText: '取消',
            type: 'warning'
          })
          .then(() => {
            this.$router.push({
              name: 'ReportTousuAdd',
              query: {
                projectCode: result.project_code,
                twOrderId: result.twOrderId
              }
            })
          })
        return
      }
      this.circulationTitle = button.buttonName
      this.currentFieldConfig = button.fieldConfig
      this.circulationShow = true
      this.circulationOrder = result
    },
    filterPopupConfirm (formData) {
      console.log('formData: ', formData)
      Object.assign(this.searchParams, formData)
      this.getInitList()
    },
    async setReportQueryFormHandler () {
      await setReportQueryFormHandler({
        // 首页项目选择后保存到手机
        params: this.searchParams
      })
    },
    handleClickOrderItem (item) {
      this.$router.push({
        name: 'ReportOrderDetail',
        query: {
          id: item.id,
          isFollowup: item.isFollowup
        },
        params: {
          buttons: this.searchParams.incidentDealStateName === '未分派' ? item.routeList : undefined
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import url("./index.less");
</style>
