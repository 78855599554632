var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "van-collapse",
    {
      attrs: { accordion: "" },
      on: { change: _vm.handleChangeCollapse },
      model: {
        value: _vm.activeNames,
        callback: function ($$v) {
          _vm.activeNames = $$v
        },
        expression: "activeNames",
      },
    },
    [
      _c(
        "div",
        { staticClass: "standard-list-wrapper block" },
        [
          _vm._l(_vm.list, function (item, index) {
            return [
              item.id
                ? _c("problem-order-card", {
                    key: index,
                    ref: "container",
                    refInFor: true,
                    attrs: { order: item },
                    on: { click: _vm.handleClick },
                  })
                : _c(
                    "div",
                    {
                      key: index + "R",
                      ref: "container",
                      refInFor: true,
                      staticClass: "block standard-list relative",
                    },
                    [
                      _c(
                        "van-collapse-item",
                        {
                          attrs: { name: index },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "title",
                                fn: function () {
                                  return [
                                    _c(
                                      "van-sticky",
                                      {
                                        attrs: {
                                          container: _vm.container[index],
                                          "offset-top":
                                            _vm.activeNames === index
                                              ? _vm.stickyTop
                                              : 0,
                                          "z-index":
                                            _vm.activeNames === index
                                              ? 1
                                              : -990,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "title-wrappper order-item-cal",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "standrad-head",
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return (() => {}).apply(
                                                      null,
                                                      arguments
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "block absolute right-20px top-66px",
                                                  },
                                                  [
                                                    _vm.isFM
                                                      ? _c("img", {
                                                          attrs: {
                                                            src: _vm.calcCardImg(
                                                              item
                                                            ),
                                                            width: "32",
                                                            alt: "",
                                                          },
                                                        })
                                                      : _vm._e(),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "block standard-list-title",
                                                  },
                                                  [
                                                    _c(
                                                      "p",
                                                      { staticClass: "name" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.standardName
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "block title-fast",
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "fast-list",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.handleType
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "fast-list",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.inOrder
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "block info-list",
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "info-label",
                                                      },
                                                      [_vm._v("任务属性:")]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass: "info-val",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.attributeName
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "block info-list",
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "info-label",
                                                      },
                                                      [_vm._v("任务分类:")]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass: "info-val",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.classifyName
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "block info-list",
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "info-label",
                                                      },
                                                      [_vm._v("所在项目:")]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass: "info-val",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.projectName
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "block bg-white count-wrapper",
                                                on: {
                                                  "!click": function ($event) {
                                                    return (() => {}).apply(
                                                      null,
                                                      arguments
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "count-wrapper-left",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "count-list",
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "count-label",
                                                          },
                                                          [_vm._v("任务数量:")]
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "count",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.taskNum
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "count-list",
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "count-label",
                                                          },
                                                          [_vm._v("即将到期:")]
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "count mature",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.matureNum
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c("van-icon", {
                                                  staticClass:
                                                    "count-wrapper-arrow",
                                                  class: {
                                                    expand:
                                                      index === _vm.activeNames,
                                                  },
                                                  attrs: { name: "arrow-down" },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _vm._l(
                            _vm.ordersSliceComp(item),
                            function (orderItem, orderIndex) {
                              return _c(
                                "span",
                                {
                                  key: orderIndex,
                                  staticClass: "block order-list-wrapper",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleClick(orderItem)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "block order-list-title" },
                                    [
                                      _c("div", { staticClass: "title-info" }, [
                                        _c("p", { staticClass: "num" }, [
                                          _vm._v(_vm._s(orderItem.orderNo)),
                                        ]),
                                        _c(
                                          "p",
                                          {
                                            staticClass: "copy",
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                $event.stopPropagation()
                                                return _vm.copyOrderNumber(
                                                  orderItem.orderNo
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(" 复制 ")]
                                        ),
                                        _vm.isCJ && orderItem.isExpiredName
                                          ? _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "margin-left": "8px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "van-tag",
                                                  {
                                                    attrs: { type: "warning" },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        orderItem.isExpiredName
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]),
                                      _c("p", { staticClass: "status" }, [
                                        _vm._v(
                                          _vm._s(orderItem.templateNodeName)
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "block info-list order-list",
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "info-label" },
                                        [_vm._v("处理人:")]
                                      ),
                                      _c("span", { staticClass: "info-val" }, [
                                        _vm._v(_vm._s(orderItem.handlerName)),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "block info-list" },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "info-label" },
                                        [_vm._v("计划开始时间:")]
                                      ),
                                      _c("span", { staticClass: "info-val" }, [
                                        _vm._v(_vm._s(orderItem.planStart)),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "block info-list" },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "info-label" },
                                        [_vm._v("计划结束时间:")]
                                      ),
                                      _c("span", { staticClass: "info-val" }, [
                                        _vm._v(_vm._s(orderItem.planEnd)),
                                      ]),
                                    ]
                                  ),
                                  orderItem.taskAddr
                                    ? _c(
                                        "span",
                                        { staticClass: "block info-list" },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "info-label" },
                                            [_vm._v("任务位置:")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "info-val" },
                                            [_vm._v(_vm._s(orderItem.taskAddr))]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              )
                            }
                          ),
                          item.expandLevel
                            ? [
                                item.expandLevel * _vm.expandCount <
                                item.orders.length
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "show_more",
                                        on: {
                                          click: function ($event) {
                                            return _vm.expandMore(index)
                                          },
                                        },
                                      },
                                      [
                                        _c("span", [_vm._v("点击加载更多")]),
                                        _c("span", {
                                          staticClass:
                                            "iconfont icon-arrow-down",
                                        }),
                                      ]
                                    )
                                  : _c("span", { staticClass: "show_more" }, [
                                      _c("span", [_vm._v("没有更多数据了")]),
                                    ]),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
            ]
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }