<template v-cloak>
  <div class="history-list-wrapper">
    <my-nav :title="isFM?'近3天处理记录':'近3天处理品控任务记录'">
      <div class="icon-sampleResultsList" @click="toSampleResultsList" slot="right"></div>
    </my-nav>
    <div class="list-wrapper" v-if="historyList.length > 0">
      <div class="card-list" v-for="item in historyList" :key="item.id" @click="goDetail(item)"  :class="{'disabled':!isOffline && !item.hasOfflineData}">
        <div class="card-title">
          <div class="title-info">
            <p class="num">{{ item.order_no }}</p>
            <p class="copy" @click.prevent.stop="copyOrderNumber(item.order_no)">复制</p>
          </div>
          <van-tag type="primary" v-if="!isOffline && !item.hasOfflineData" color="#666666">离线包无工单数据</van-tag>
          <!-- <p class="status">{{ item.templateNodeName }}</p> -->
        </div>
        <p class="card-desc">{{ item.remark }}</p>
        <div class="card-time">
          <p class="label">最后处理时间:</p>
          <p class="val">{{ item.lastChangeTime }}</p>
        </div>
      </div>
    </div>
    <no-data v-else tex="暂无近三天的操作数据"></no-data>
  </div>
</template>

<script type='text/ecmascript-6'>
import { getHistoryOrderListOfflineApiHandler } from '@/plugins/offlineMixin/offlineApiHandlers/HistoryOrderListOfflineApiHandlers'
import {
  getOrderCacheOfflineApiHandler
} from '@/plugins/offlineMixin/offlineApiHandlers/OrderInfoOfflineApiHandlers'
export default {
  props: {
    historyListShow: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      useOfflinePlugin: true,
      historyList: []
    }
  },
  watch: {
    isOffline () {
      this.getHistoryList()
    }
  },
  mounted () {
    this.getHistoryList()
  },
  computed: {
    isOffline () {
      return this.$store.getters.getIsOffline
    },
    isFM () {
      return this.$store.getters.getIsFM
    }
  },
  methods: {
    async getHistoryList () {
      const toast = this.$toast.loading({
        message: '正在获取近三天历史...'
      })
      try {
        let historyList = await getHistoryOrderListOfflineApiHandler() || []
        console.log('离线包取出的近三天历史记录: ', this.lodash.cloneDeep(historyList))
        if (!this.isOffline && historyList.length > 0) {
          for (const history of historyList) {
            const detail = await getOrderCacheOfflineApiHandler({
              params: { id: history.id }
            })
            history['hasOfflineData'] = !!detail
          }
        }
        this.historyList = historyList
      } catch (error) {
        console.log('getHistoryList error: ', error)
      } finally {
        toast.clear()
      }
    },
    async toSampleResultsList () {
      if (!this.isOffline) {
        this.$EB.$emit(this.$EBT.NO_NETWORK_WARN)
      } else {
        this.$router.push('/SampleResultsList')
      }
    },
    /* 复制订单号 */
    copyOrderNumber (orderNo) {
      this.$copyText(orderNo).then(
        (e) => {
          this.$notify({
            type: 'success',
            message: '复制成功',
            background: '#13CE66'
          })
        },
        (e) => {
          this.$notify({
            type: 'warning',
            message: '复制成功',
            background: '#FFC82C'
          })
        }
      )
    },
    /**
     * 去详情
     */
    goDetail (item) {
      if (!this.isOffline && !item.hasOfflineData) {
        this.$notify({
          type: 'warning',
          message: '当前网络状态不好，并离线包无此工单数据，请在网络状态好时重试！'
        })
        return
      }
      this.$router.push({
        path: '/OrderDetail',
        query: { orderId: item.id }
      })
    }
  }
}
</script>
<style  lang='less' scoped>
@import url("./index.less");
</style>
