var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "orderCheck" },
    [
      _c("my-nav", {
        attrs: { color: "blue", title: "工单审核", "self-back": true },
        on: { back: _vm.exitWeb },
      }),
      _c("div", { staticClass: "select-project-input-wrapper" }, [
        _c(
          "div",
          {
            staticClass: "input-wrapper",
            on: {
              click: function ($event) {
                _vm.selectProjectShow = true
              },
            },
          },
          [
            _c("p", { staticClass: "info" }, [_vm._v("已选项目：")]),
            _c("p", { ref: "valueLength", staticClass: "value" }, [
              _vm._v(
                " " + _vm._s(_vm.searchParams.searchProjectCodeName) + " "
              ),
            ]),
          ]
        ),
      ]),
      _c("selectReportPosition", {
        ref: "selectProject",
        attrs: {
          mode: "project",
          incidentSource: "公区",
          show: _vm.selectProjectShow,
        },
        on: {
          "update:show": function ($event) {
            _vm.selectProjectShow = $event
          },
          result: _vm.selectProjectConfim,
        },
      }),
      _c("van-sticky", { attrs: { "offset-top": _vm.navBarHeight - 3 } }, [
        _c(
          "section",
          { staticClass: "tag-wrapper" },
          _vm._l(_vm.checkTypes, function (item, index) {
            return _c(
              "span",
              {
                key: index,
                staticClass: "ordercheck-type",
                class: { active: _vm.searchParams.profession === item },
                on: {
                  click: function ($event) {
                    return _vm.changeProfession(item)
                  },
                },
              },
              [_vm._v(_vm._s(item))]
            )
          }),
          0
        ),
      ]),
      [
        _c(
          "van-pull-refresh",
          {
            on: { refresh: _vm.onRefresh },
            model: {
              value: _vm.refreshing,
              callback: function ($$v) {
                _vm.refreshing = $$v
              },
              expression: "refreshing",
            },
          },
          [
            _c(
              "van-list",
              {
                attrs: {
                  finished: _vm.finished,
                  "finished-text":
                    _vm.list.length > 0
                      ? "- 没有更多数据了 -"
                      : "您没有处理的工单",
                  offset: 50,
                  "immediate-check": false,
                },
                on: {
                  load: function ($event) {
                    return _vm.onLoad("list")
                  },
                },
                model: {
                  value: _vm.loading,
                  callback: function ($$v) {
                    _vm.loading = $$v
                  },
                  expression: "loading",
                },
              },
              _vm._l(_vm.list, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "order",
                    on: {
                      click: function ($event) {
                        return _vm.handleClickOrderItem(item)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "order_header" }, [
                      _c("div", { staticClass: "left" }, [
                        _c("span", {
                          staticClass: "order_type",
                          class: {
                            zhixu: item.belongProfessional === "秩序",
                            huanjing: item.belongProfessional === "环境",
                            gongcheng: item.belongProfessional === "工程",
                            kefu: item.belongProfessional === "客服",
                          },
                        }),
                        _c("span", [_vm._v(_vm._s(item.belongProfessional))]),
                      ]),
                      _c("span", { staticClass: "status" }, [
                        _vm._v(_vm._s(item.orderStatusName)),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "order_main" },
                      [
                        _c(
                          "van-row",
                          [
                            _c("van-col", { attrs: { span: "5" } }, [
                              _vm._v("工单编号："),
                            ]),
                            _c(
                              "van-col",
                              {
                                staticClass: "text-[#3366FE]",
                                attrs: { span: "19" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.routerToReportOrderDetail(
                                      item.incidentID
                                    )
                                  },
                                },
                              },
                              [_vm._v(_vm._s(item.incidentNum))]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "van-row",
                          [
                            _c("van-col", { attrs: { span: "5" } }, [
                              _vm._v("报事位置："),
                            ]),
                            _c("van-col", { attrs: { span: "19" } }, [
                              _vm._v(_vm._s(item.roomSign)),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "van-row",
                          [
                            _c("van-col", { attrs: { span: "5" } }, [
                              _vm._v("报事描述："),
                            ]),
                            _c(
                              "van-col",
                              {
                                staticClass: "order_disc",
                                attrs: { span: "19" },
                              },
                              [_vm._v(_vm._s(item.incidentContent))]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "van-row",
                          [
                            _c("van-col", { attrs: { span: "5" } }, [
                              _vm._v("报事时间："),
                            ]),
                            _c("van-col", { attrs: { span: "19" } }, [
                              _vm._v(_vm._s(item.incidentDate)),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "van-row",
                          [
                            _c("van-col", { attrs: { span: "5" } }, [
                              _vm._v("申请人："),
                            ]),
                            _c("van-col", { attrs: { span: "19" } }, [
                              _vm._v(_vm._s(item.userName)),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "tag",
                        class: {
                          green: item.approvalType === "协助",
                          orange:
                            item.approvalType === "转派" ||
                            item.approvalType === "改派",
                          red: item.approvalType === "废弃",
                          grey: item.approvalType === "非关",
                          blue: item.approvalType === "延期",
                        },
                      },
                      [_vm._v(" " + _vm._s(item.approvalType) + " ")]
                    ),
                  ]
                )
              }),
              0
            ),
          ],
          1
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }