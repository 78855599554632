var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "van-field",
        {
          staticClass: "van-field-picker",
          attrs: {
            readonly: "",
            clickable: _vm.isLink,
            "input-align": _vm.align,
            "is-link": !_vm.disabled && _vm.isLink,
            "right-icon": _vm.rightIcon,
            required: _vm.required,
            name: _vm.name,
            label: _vm.label,
            value: _vm.value,
            placeholder: _vm.placeholder,
            rules: _vm.rules,
          },
          on: { click: _vm.openPopup },
        },
        [_c("template", { slot: "label" }, [_vm._t("label")], 2)],
        2
      ),
      _c(
        "van-popup",
        {
          style: { height: _vm.height },
          attrs: { "get-container": "#app", round: "", position: "bottom" },
          model: {
            value: _vm.showPicker,
            callback: function ($$v) {
              _vm.showPicker = $$v
            },
            expression: "showPicker",
          },
        },
        [
          _c("van-picker", {
            attrs: {
              "show-toolbar": "",
              columns: _vm.columns,
              "visible-item-count": _vm.count,
            },
            on: {
              cancel: function ($event) {
                _vm.showPicker = false
              },
              confirm: _vm.onConfirm,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }