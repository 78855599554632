var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "RemoteMonitorOrderRecheck" },
    [
      _c("my-nav", { attrs: { title: "整改复核" } }),
      _c("div", { staticClass: "title-wrapper" }, [
        _c("p", { staticClass: "title" }, [
          _vm._v(_vm._s(_vm.recheckDetail.itemName)),
        ]),
        _c("p", { staticClass: "desc" }, [
          _vm._v(" " + _vm._s(_vm.recheckDetail.qualityStandards) + " "),
        ]),
        _c("p", { staticClass: "desc" }, [
          _vm._v(" " + _vm._s(_vm.recheckDetail.projectName) + " "),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "img-swiper" },
        [
          _c(
            "van-swipe",
            {
              ref: "remoteMonitorImgSwiperRef",
              staticClass: "my-swipe",
              attrs: { loop: false, "indicator-color": "white" },
            },
            _vm._l(_vm.remoteMonitorCaptureImages, function (item, index) {
              return _c("van-swipe-item", { key: index }, [
                _c(
                  "div",
                  { staticClass: "remoteMonitorImg" },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "remoteMonitorImg-tag",
                        class: { realtime: index === 0 },
                      },
                      [_vm._v(_vm._s(index === 0 ? "实时" : "整改前"))]
                    ),
                    _c("van-image", {
                      attrs: {
                        width: "98vw",
                        height: "200px",
                        src: item,
                        fit: "contain",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "loading",
                            fn: function () {
                              return [
                                _c("van-loading", {
                                  attrs: { type: "spinner", size: "20" },
                                }),
                                _vm._v(" 加载中 "),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                  ],
                  1
                ),
              ])
            }),
            1
          ),
          _c("div", { staticClass: "my-10px checkStandards-wrapper" }, [
            _c(
              "div",
              {
                staticStyle: { color: "#222", "font-size": "16px" },
                on: {
                  click: function ($event) {
                    _vm.collapse = !_vm.collapse
                  },
                },
              },
              [
                _vm._v(" 检查标准"),
                _c("van-icon", {
                  staticClass: "ml-10px",
                  attrs: { name: "arrow-down" },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "checkStandards",
                class: { collapse: _vm.collapse },
              },
              [_vm._v(" " + _vm._s(_vm.recheckDetail.checkStandards) + " ")]
            ),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "init-issue" }, [
        _vm._v("初始问题：" + _vm._s(_vm.recheckDetail.originMemo)),
      ]),
      _vm.captureSuccess
        ? _c(
            "div",
            { staticClass: "result-record" },
            [
              _c("van-field", {
                attrs: {
                  label: "结果记录：",
                  "input-align": "right",
                  border: "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "input",
                      fn: function () {
                        return [
                          _c(
                            "van-radio-group",
                            {
                              staticClass: "mt10",
                              attrs: { direction: "horizontal" },
                              model: {
                                value: _vm.recheckDetail.isPass,
                                callback: function ($$v) {
                                  _vm.$set(_vm.recheckDetail, "isPass", $$v)
                                },
                                expression: "recheckDetail.isPass",
                              },
                            },
                            _vm._l(_vm.isPassOption, function (item) {
                              return _c(
                                "van-radio",
                                {
                                  key: item.code,
                                  attrs: { name: item.code },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "icon",
                                        fn: function (props) {
                                          return [
                                            _c("img", {
                                              staticClass: "img-icon",
                                              attrs: {
                                                width: "22px",
                                                height: "22px",
                                                src: props.checked
                                                  ? require("@/asset/radio-active.png")
                                                  : require("@/asset/radio-default.png"),
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [_vm._v(_vm._s(item.name) + " ")]
                              )
                            }),
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  645627058
                ),
              }),
              _vm.recheckDetail.isPass === 0
                ? _c("van-field", {
                    attrs: {
                      readonly: "",
                      clickable: "",
                      "is-link": "",
                      name: "picker",
                      value: _vm.chooseProblem.name,
                      label: "考评标签：",
                      placeholder: "点击选择问题项",
                    },
                    on: {
                      click: function ($event) {
                        _vm.showProblemItemPicker = true
                      },
                    },
                  })
                : _vm._e(),
              _c(
                "van-popup",
                {
                  attrs: { position: "bottom" },
                  model: {
                    value: _vm.showProblemItemPicker,
                    callback: function ($$v) {
                      _vm.showProblemItemPicker = $$v
                    },
                    expression: "showProblemItemPicker",
                  },
                },
                [
                  _c("van-picker", {
                    attrs: {
                      "show-toolbar": "",
                      "value-key": "name",
                      columns: _vm.remoteProblemList,
                    },
                    on: {
                      confirm: _vm.onProblemConfirm,
                      cancel: function ($event) {
                        _vm.showProblemItemPicker = false
                      },
                    },
                  }),
                ],
                1
              ),
              _vm.recheckDetail.isPass === 0
                ? _c("van-field", {
                    attrs: {
                      readonly: "",
                      clickable: "",
                      name: "picker",
                      value: _vm.chooseProblemLabelStr,
                      label: "问题标签：",
                      "is-link": "",
                      placeholder: "点击选择问题标签",
                    },
                    on: { click: _vm.openChooseProblemLabel },
                  })
                : _vm._e(),
              _c(
                "van-popup",
                {
                  attrs: { position: "bottom" },
                  model: {
                    value: _vm.showProblemLabelPicker,
                    callback: function ($$v) {
                      _vm.showProblemLabelPicker = $$v
                    },
                    expression: "showProblemLabelPicker",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "checkbox-con",
                      staticStyle: {
                        "overflow-y": "auto",
                        "max-height": "464px",
                      },
                    },
                    [
                      _c("div", { staticClass: "van-picker__toolbar" }, [
                        _c(
                          "button",
                          {
                            staticClass: "van-picker__cancel",
                            attrs: { type: "button" },
                            on: { click: _vm.onProblemLabelCancel },
                          },
                          [_vm._v(" 取消 ")]
                        ),
                        _c(
                          "div",
                          { staticClass: "van-ellipsis van-picker__title" },
                          [_vm._v("问题标签")]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "van-picker__confirm",
                            attrs: { type: "button" },
                            on: { click: _vm.onProblemLabelConfirm },
                          },
                          [_vm._v(" 确认 ")]
                        ),
                      ]),
                      _c(
                        "van-checkbox-group",
                        {
                          ref: "checkboxGroup",
                          model: {
                            value: _vm.checkboxValue,
                            callback: function ($$v) {
                              _vm.checkboxValue = $$v
                            },
                            expression: "checkboxValue",
                          },
                        },
                        [
                          _c(
                            "van-cell-group",
                            _vm._l(
                              _vm.problemLabelData,
                              function (item, index) {
                                return _c("van-cell", {
                                  key: item,
                                  attrs: { clickable: "", title: item },
                                  on: {
                                    click: function ($event) {
                                      return _vm.toggle(index, item)
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "right-icon",
                                        fn: function () {
                                          return [
                                            _c("van-checkbox", {
                                              ref: "checkboxes",
                                              refInFor: true,
                                              attrs: {
                                                name: item,
                                                shape: "square",
                                              },
                                            }),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm.recheckDetail.isPass === 0
                ? _c("van-field", {
                    attrs: {
                      label: "备注：",
                      placeholder: "请输入备注",
                      type: "textarea",
                      rows: "3",
                    },
                    model: {
                      value: _vm.recheckMemo,
                      callback: function ($$v) {
                        _vm.recheckMemo = $$v
                      },
                      expression: "recheckMemo",
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      !_vm.captureSuccess
        ? _c("div", { staticClass: "capture-fail" }, [
            _vm._v(
              " 请点击下方【重新抓拍】按钮进行抓拍，抓拍成功后方可进行复核 "
            ),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "footer" },
        [
          _c(
            "van-button",
            {
              staticClass: "foot-btn",
              attrs: { plain: "" },
              on: { click: _vm.routerBack },
            },
            [_vm._v("取消")]
          ),
          _vm.captureSuccess
            ? _c(
                "van-button",
                {
                  staticClass: "foot-btn",
                  attrs: { type: "primary" },
                  on: { click: _vm.handleSubmit },
                },
                [_vm._v("确定")]
              )
            : _vm._e(),
          !_vm.captureSuccess
            ? _c(
                "van-button",
                {
                  staticClass: "foot-btn",
                  attrs: { type: "primary" },
                  on: { click: _vm.handleOrderTaskCapture },
                },
                [_vm._v("重新抓拍")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }