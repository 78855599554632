var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "OrderSpotCheckHandle" },
    [
      _c("my-nav", { attrs: { title: "抽查结果" } }),
      _c(
        "div",
        { staticClass: "content" },
        [
          _vm._l(_vm.spotCheckItems, function (item, index) {
            return _c(
              "div",
              { key: index, staticClass: "checkItem" },
              [
                _c(
                  "div",
                  { staticClass: "title" },
                  [
                    _c("div", { staticClass: "name" }, [
                      _vm._v(
                        " " +
                          _vm._s(index + 1) +
                          "、" +
                          _vm._s(item.spotCheckConfigName) +
                          " "
                      ),
                    ]),
                    !_vm.checked
                      ? _c(
                          "van-radio-group",
                          {
                            staticClass: "radio-group",
                            attrs: { direction: "horizontal" },
                            model: {
                              value: item.checkResult,
                              callback: function ($$v) {
                                _vm.$set(item, "checkResult", $$v)
                              },
                              expression: "item.checkResult",
                            },
                          },
                          [
                            _c(
                              "van-radio",
                              {
                                attrs: { "checked-color": "#07c160", name: 1 },
                              },
                              [_vm._v("合格")]
                            ),
                            _c(
                              "van-radio",
                              {
                                attrs: { "checked-color": "#ee0a24", name: 0 },
                              },
                              [_vm._v("不合格")]
                            ),
                          ],
                          1
                        )
                      : _c(
                          "div",
                          [
                            item.checkResult === 1
                              ? _c(
                                  "van-tag",
                                  {
                                    staticStyle: { height: "24px" },
                                    attrs: { type: "success" },
                                  },
                                  [_vm._v("合格")]
                                )
                              : item.checkResult === 0
                              ? _c(
                                  "van-tag",
                                  {
                                    staticStyle: { height: "24px" },
                                    attrs: { type: "danger" },
                                  },
                                  [_vm._v("不合格")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                  ],
                  1
                ),
                _c("van-field", {
                  staticStyle: {
                    "background-color": "#f7f8fa",
                    "border-radius": "4px",
                    "margin-top": "6px",
                  },
                  attrs: {
                    rows: "3",
                    autosize: "",
                    readonly: _vm.checked,
                    type: "textarea",
                    placeholder: "请输入抽查结论",
                    "show-word-limit": "",
                  },
                  model: {
                    value: item.checkConclusion,
                    callback: function ($$v) {
                      _vm.$set(item, "checkConclusion", $$v)
                    },
                    expression: "item.checkConclusion",
                  },
                }),
                !_vm.checked &&
                _vm.showImgAfterLoad &&
                item.spotCheckAttachments &&
                item.spotCheckAttachments.length
                  ? _c(
                      "div",
                      { staticClass: "mt-10px mb-10px" },
                      _vm._l(
                        item.spotCheckAttachments,
                        function (image, imgIndex) {
                          return _c("van-image", {
                            key: image,
                            staticClass: "mr-10px mt-10px",
                            attrs: {
                              width: "40",
                              height: "40",
                              radius: "4",
                              src: _vm.offlineImageBase64[image],
                            },
                            on: {
                              click: function ($event) {
                                return _vm.imgPreview(
                                  item.spotCheckAttachments,
                                  imgIndex
                                )
                              },
                            },
                          })
                        }
                      ),
                      1
                    )
                  : _vm._e(),
                _vm.checked
                  ? _c(
                      "div",
                      _vm._l(
                        item.spotCheckAttachments,
                        function (image, imgIndex) {
                          return _c("van-image", {
                            key: image,
                            staticClass: "mr-10px mt-10px",
                            attrs: {
                              width: "40",
                              height: "40",
                              radius: "4",
                              src: image.url,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.imgPreview(
                                  item.spotCheckAttachments,
                                  imgIndex
                                )
                              },
                            },
                          })
                        }
                      ),
                      1
                    )
                  : _vm._e(),
              ],
              1
            )
          }),
          _c(
            "div",
            { staticClass: "pt-10px pb-10px flex items-center" },
            [
              _vm._v(" 结果： "),
              _vm.checkResultComp === "合格"
                ? _c(
                    "van-tag",
                    {
                      staticStyle: { height: "24px" },
                      attrs: { type: "success" },
                    },
                    [_vm._v("合格")]
                  )
                : _vm.checkResultComp === "不合格"
                ? _c(
                    "van-tag",
                    {
                      staticStyle: { height: "24px" },
                      attrs: { type: "danger" },
                    },
                    [_vm._v("不合格")]
                  )
                : _c("span", [_vm._v("-")]),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "footer" },
        [
          _c(
            "van-button",
            { staticClass: "btn cancel", on: { click: _vm.routerBack } },
            [_vm._v("取消")]
          ),
          !_vm.checked
            ? _c(
                "van-button",
                {
                  staticClass: "btn save",
                  attrs: { loading: _vm.submitLoading },
                  on: { click: _vm.handleSave },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.isFinalNofinishOption
                        ? _vm.nextNofinishItemId
                          ? "保存并继续"
                          : "保存"
                        : "保存"
                    )
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }