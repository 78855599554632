<template>
  <van-popup
    v-model="show"
    position="right"
    :overlay="false"
    :style="{ width: '100%', height: '100%' }"
  >
    <van-nav-bar :title="title" left-arrow @click-left="goBack" />
    <van-search
      v-model="keywords"
      placeholder="请输入关键字"
      @search="handleSearch"
    />
    <van-list class="list-container" :class="{ footer: btnShow }">
      <van-cell
        :is-link="item.flowCategorySelList.length !== 0"
        :value="item.name"
        @click="selectReason(item)"
        v-for="(item, index) in reasonList"
        :key="index"
      >
        <template
          #right-icon
          v-if="
            selectVal.bigCategoryCode === item.code ||
            selectVal.smallCategoryCode === item.code
          "
        >
          <!-- <van-icon name="success" class="success-icon" /> -->
          <div class="fork"></div>
        </template>
      </van-cell>
      <div class="no-data" v-if="reasonList.length === 0">
        <div class="img"></div>
        <div class="text">未搜到“<span>{{ keywords }}</span>”相关结果</div>
      </div>
    </van-list>
    <div class="project-footer" v-show="btnShow || routeList.length > 1" v-if="!noShowBtn">
      <vuescroll :ops="ops" ref="vsreason" class="scroll-router" v-show="routeList.length > 1">
        <div class="no-wrap">
          <span
            v-for="(item, index) in routeList"
            @click="routeBack(item, index)"
            :key="index"
            :class="{ 'text-blue': index < routeList.length - 1 }"
            >{{ item.name }}
            <van-icon
              style="vertical-align: -2px"
              v-if="index < routeList.length - 1"
              name="arrow"
              size="16px"
              color="#c5c5c5" /></span
          ><span id="last-item"></span>
        </div>
      </vuescroll>
      <div class="btns-container clear" v-show="btnShow" >
        <van-button
          class="btn pull-left"
          plain
          type="info"
          @click="continueReasonSmallData"
          >继续选择原因细类</van-button
        >
        <van-button
          class="btn pull-right"
          @click="confirm"
          style="background-color: #3366fe; color: #fff"
          type="info"
          >确定</van-button
        >
      </div>
    </div>
  </van-popup>
</template>
<script>
import api from '@/api'
import vuescroll from 'vuescroll/dist/vuescroll-slide'
import { getSmallCategoryTreeHandler, getBigCategoryTreeHandler } from '@/plugins/offlineMixin/offlineApiHandlers/CatogoryTreeHandler'
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    dataVal: {
      type: Object,
      default: () => {
        return {}
      }
    },
    bigReasonId: {
      type: Number
    },
    IsSmall: {
      type: Boolean,
      default: false
    },
    noShowBtn: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false,
      show: false,
      btnShow: false,
      title: '原因类型列表',
      keywords: '', // 搜索关键字
      selectVal: {
        bigCategoryName: '',
        bigCategoryCode: '',
        bigCategoryId: '',
        smallCategoryName: '',
        smallCategoryCode: '',
        smallCategoryId: ''
      },
      reasonType: 1,
      reasonList: [],
      routeList: [{ flowCategorySelList: [], name: '原因类型列表' }],
      ops: {
        vuescroll: {
          mode: 'slide'
        },
        scrollPanel: {
          scrollingX: true,
          scrollingY: false
        },
        rail: {
          size: '4px',
          keepShow: false
        },
        bar: {
          size: '4px',
          background: '#eee'
        }
      },
      noShowSmall: false
    }
  },
  components: {
    vuescroll
  },
  computed: {
    isOffline () {
      return this.$store.getters.getIsOffline
    }
  },
  mounted () {
    this.show = this.value
    console.log('this.bigReasonId: ', this.bigReasonId)
    if (this.bigReasonId) {
      setTimeout(() => {
        this.selectVal.bigCategoryId = this.bigReasonId
        this.selectVal.smallCategoryCode = this.dataVal.smallCategoryCode
        this.getSmallReasonList()
      }, 400)
    } else {
      this.getBigReasonList()
    }
  },
  methods: {
    async getBigReasonList () {
      this.reasonType = 1
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true
      })
      const res = await this.$apiOffline({
        methods: 'get',
        api: api.getBigReasonList,
        params: { search: this.keywords },
        offlineApiHandlers: getBigCategoryTreeHandler
      })
      console.log('getBigReasonList res: ', res)
      this.$toast.clear()
      this.reasonList = res
      this.routeList[0].flowCategorySelList = res
    },
    transFormSmallReasonList (arr) {
      const _this = this
      return (arr && arr.length > 0) ? arr.map(item => {
        return {
          id: item.code * 1,
          code: item.extCode,
          name: item.title,
          parentId: item.parent * 1,
          flowCategorySelList: _this.transFormSmallReasonList(item.children)
        }
      }) : []
    },
    async getSmallReasonList (isSearch) {
      this.reasonType = 2
      this.btnShow = false
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true
      })
      const res = await this.$apiOffline({
        methods: 'get',
        api: api.getSmallReasonList + this.selectVal.bigCategoryId + '/tree',
        params: { search: this.keywords, bigCategoryId: this.bigReasonId },
        offlineApiHandlers: getSmallCategoryTreeHandler
      })
      console.log('getSmallReasonList res: ', res)
      this.$toast.clear()
      if (this.isOffline) {
        this.reasonList = res
      } else {
        this.reasonList = res ? this.transFormSmallReasonList(res) : []
      }
      if (!isSearch) {
        this.routeList.push({
          name: this.selectVal.bigCategoryName,
          flowCategorySelList: res
        })
      }
      // this.$http
      //   .get(api.getSmallReasonList + this.selectVal.bigCategoryId + '/tree', {
      //     params: {
      //       search: this.keywords || undefined
      //     }
      //   })
      //   .then((res) => {
      //     this.$toast.clear()
      //     this.reasonList = res
      //     if (!isSearch) {
      //       this.routeList.push({
      //         name: this.selectVal.bigCategoryName,
      //         flowCategorySelList: res
      //       })
      //     }
      //   })
    },
    continueReasonSmallData () {
      this.keywords = ''
      this.getSmallReasonList()
    },
    handleSearch () {
      if (this.reasonType === 1) {
        this.getBigReasonList()
      } else if (this.reasonType === 2) {
        this.getSmallReasonList(true)
      }
    },
    selectReason (item) {
      if (item.flowCategorySelList.length) {
        // 进入该原因大类
        this.btnShow = false
        this.routeList.push(item)
        this.reasonList = item.flowCategorySelList
        setTimeout(() => {
          this.$refs['vsreason'].scrollIntoView('#last-item', 500)
        }, 0)
      } else {
        if (item.type === 0) {
          // 选择原因大类 或 查询原因细类
          this.btnShow = true
          this.selectVal.bigCategoryName = item.name
          this.selectVal.bigCategoryCode = item.code
          this.selectVal.bigCategoryId = item.id
          this.IsSmall && this.confirm1()
        } else {
          this.selectVal.smallCategoryName = item.name
          this.selectVal.smallCategoryCode = item.code
          this.selectVal.smallCategoryId = item.id
          this.confirm1()
        }
      }
    },
    confirm () {
      this.show = false
      this.selectVal.smallCategoryName = ''
      this.selectVal.smallCategoryCode = ''
      this.$emit('result', this.selectVal)
      // this.btnShow = false
      this.selectVal = {
        bigCategoryName: '',
        bigCategoryCode: '',
        bigCategoryId: '',
        smallCategoryName: '',
        smallCategoryCode: '',
        smallCategoryId: ''
      }
    },
    confirm1 () {
      this.show = false
      this.$emit('result', this.selectVal)
      this.btnShow = false
      this.selectVal = {
        bigCategoryName: '',
        bigCategoryCode: '',
        bigCategoryId: '',
        smallCategoryName: '',
        smallCategoryCode: '',
        smallCategoryId: ''
      }
    },
    routeBack (item, index) {
      this.routeList = this.routeList.slice(0, index + 1)
      this.reasonList = item.flowCategorySelList
      setTimeout(() => {
        this.$refs['vsreason'].scrollIntoView('#last-item', 500)
      }, 0)
    },
    goBack () {
      this.show = false
    }
  },
  watch: {
    value (val) {
      this.show = val
      this.selectVal = {
        bigCategoryName: this.dataVal.bigCategoryName,
        bigCategoryCode: this.dataVal.bigCategoryCode,
        bigCategoryId: this.dataVal.bigCategoryId,
        smallCategoryName: this.dataVal.smallCategoryName,
        smallCategoryCode: this.dataVal.smallCategoryCode,
        smallCategoryId: this.dataVal.smallCategoryId
      }
      if (val && !this.selectVal.bigCategoryCode) {
        this.keywords = ''
        this.routeList = [{ flowCategorySelList: [], name: '原因类型列表' }]
        this.getBigReasonList()
      }
    },
    show (val) {
      this.$emit('input', val)
    }
  }
}
</script>
<style scoped lang="less">
.success-icon {
  margin-top: 5px;
  color: #3366fe;
}
.fork{
  background: url('../../../asset/icon_right_blue@2x.png') no-repeat;
  background-size:contain ;
  width: 24px;
  height: 24px;
}
.list-container {
  height: calc(100vh - 94px);
  overflow-y: auto;
}
.list-container.footer {
  height: calc(100vh - 180px);
}
.project-footer {
  width: 100%;
  background-color: #fff;
  color: #8b8f9a;
  position: fixed;
  bottom: 0;
  left: 0;
  line-height: 32px;
  padding: 16px 16px;
  box-sizing: border-box;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  .scroll-router{
    padding-bottom: 8px !important;
  }
  .text-blue {
    color: #3366fe;
  }
  .no-wrap {
    white-space: nowrap;
    height: 32px;
    line-height: 32px;
  }
  .btns-container .btn {
    width: calc((100% - 16px) / 2);
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #3366fe;
    border-radius: 4px;
    &.van-button--info.van-button--plain {
      color: #3366fe;
      border: 1px solid #3366fe;
    }
  }
}
.no-data {
  .img {
    background: url("../../../asset/empty_sea@2x.png") no-repeat;
    background-size: contain;
    width: 142px;
    height: 100px;
    margin: 120px auto 32px;
  }
  .text {
    width: 240px;
    margin: 0 auto;
    text-align: center;
    font-size: 16px;
    color: #12141c;
    span{
      color: #3366FE;
    }
  }
}
</style>
