var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "px-10px pb-16px mt-16px problemOrder order-item-cal",
      class: { isOverdueTime: _vm.order.overdueTime },
      on: { click: _vm.handleClick },
    },
    [
      _c(
        "span",
        {
          staticClass: "block absolute right-20px top-66px",
          staticStyle: { position: "absolute", right: "22px", top: "66px" },
        },
        [
          _c("img", {
            attrs: {
              src: _vm._f("calcCardImg")(_vm.order),
              width: "32",
              alt: "",
            },
          }),
        ]
      ),
      _c(
        "span",
        { staticClass: "block py-12px flex justify-between items-center" },
        [
          _c(
            "div",
            {
              staticClass: "space-x-12px",
              staticStyle: { display: "flex", "align-items": "center" },
            },
            [
              _c(
                "span",
                { staticClass: "font-semibold text-18px text-[#222]" },
                [_vm._v(_vm._s(_vm.order.orderNo))]
              ),
              _c(
                "span",
                {
                  staticClass: "copy_btn",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      $event.stopPropagation()
                      return _vm.copyTNum(_vm.order.orderNo)
                    },
                  },
                },
                [_vm._v("复制")]
              ),
            ]
          ),
          _c("span", { staticClass: "text-[#3366FE]" }, [
            _vm._v(" " + _vm._s(_vm.order.orderStatusName) + " "),
          ]),
        ]
      ),
      _c("van-divider", { staticClass: "!m-0", attrs: { dashed: "" } }),
      _c("span", { staticClass: "block info-list" }, [
        _c("span", { staticClass: "info-label" }, [_vm._v("所在项目:")]),
        _c("span", { staticClass: "info-val" }, [
          _vm._v(_vm._s(_vm.order.projectName)),
        ]),
      ]),
      _c("span", { staticClass: "block flex text-16px leading-24px" }, [
        _c("span", { staticClass: "info-label flex-shrink-0 text-[#8b8f9a]" }, [
          _vm._v("所在位置:"),
        ]),
        _c(
          "span",
          {
            staticClass: "leading-24px text-[#222] !ml-16px",
            staticStyle: { "margin-right": "35px" },
          },
          [_vm._v(_vm._s(_vm.addressNameComp(_vm.order)))]
        ),
      ]),
      _c("span", { staticClass: "block info-list" }, [
        _c("span", { staticClass: "info-label" }, [_vm._v("创建时间:")]),
        _c("span", { staticClass: "info-val" }, [
          _vm._v(_vm._s(_vm.order.createTime)),
        ]),
      ]),
      _c("span", { staticClass: "block flex text-16px leading-24px" }, [
        _c("span", { staticClass: "info-label flex-shrink-0 text-[#8b8f9a]" }, [
          _vm._v("工单描述:"),
        ]),
        _c("span", { staticClass: "leading-24px text-[#222] !ml-16px" }, [
          _vm._v(_vm._s(_vm.order.remark)),
        ]),
      ]),
      _vm.order.handlerName
        ? _c("span", { staticClass: "block info-list" }, [
            _c("span", { staticClass: "info-label" }, [_vm._v("处理人:")]),
            _c(
              "span",
              {
                staticClass: "info-val",
                staticStyle: { "margin-left": "30px" },
              },
              [_vm._v(_vm._s(_vm.order.handlerName))]
            ),
          ])
        : _vm._e(),
      _c("p", { staticClass: "block info-list-two" }, [
        _c("span", { staticClass: "block info-label" }, [_vm._v("处理时限:")]),
        _c(
          "span",
          { staticClass: "block info-val" },
          [
            _c("span", [
              _vm._v(_vm._s(_vm.$moment.formatMinutes(_vm.order.processLimit))),
            ]),
            !(
              _vm.order.templateNodeCode === "yiwancheng" ||
              _vm.order.templateNodeCode === "yiwanjie"
            )
              ? [
                  _c("br"),
                  _vm.order.remainingTime
                    ? _c("span", { staticClass: "color-green" }, [
                        _vm._v(_vm._s(_vm.order.remainingTime)),
                      ])
                    : _vm._e(),
                  _vm.order.overdueTime
                    ? _c("span", { staticClass: "color-red" }, [
                        _vm._v(_vm._s(_vm.order.overdueTime)),
                      ])
                    : _vm._e(),
                ]
              : _vm._e(),
          ],
          2
        ),
      ]),
      _vm.order.grade
        ? _c(
            "span",
            {
              staticClass: "gradeTagBac",
              style: {
                "background-color": JSON.parse(_vm.order.grade).color,
                width: _vm.gradeTagSize(_vm.order.grade),
                height: _vm.gradeTagSize(_vm.order.grade),
              },
            },
            [_vm._v(" " + _vm._s(JSON.parse(_vm.order.grade).value) + " ")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }