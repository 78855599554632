import { render, staticRenderFns } from "./TypeList.vue?vue&type=template&id=0d924710&scoped=true&v-cloak=true"
import script from "./TypeList.vue?vue&type=script&lang=js"
export * from "./TypeList.vue?vue&type=script&lang=js"
import style0 from "./TypeList.vue?vue&type=style&index=0&id=0d924710&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d924710",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/app/jenkins/data/workspace/TEST测试环境/品控SIT环境/radish-mobile-ui-test/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0d924710')) {
      api.createRecord('0d924710', component.options)
    } else {
      api.reload('0d924710', component.options)
    }
    module.hot.accept("./TypeList.vue?vue&type=template&id=0d924710&scoped=true&v-cloak=true", function () {
      api.rerender('0d924710', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/PatrolList/components/TypeList.vue"
export default component.exports