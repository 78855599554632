<template v-cloak>
  <div class="preview-wrapper">
    <template v-if="imgList.length > 0">
      <div>{{title}}</div>
      <van-image width="40" height="40" radius="4" class="photo" v-for="(list, index) of imgList"
        @click="imgPreview(index)" :key="index" :src="list" />
    </template>
  </div>
</template>

<script type='text/ecmascript-6'>
import { isJsonString, loadAsyncImgByQueue } from '@/plugins/offlineMixin/utils/offlineImgTool'
import { ImagePreview } from 'vant'
export default {
  name: 'OfflinePreviewImg',
  components: {},
  props: {
    list: {
      type: [Array, String],
      default: () => []
    },
    title: {
      type: String,
      default: undefined
    }
  },
  data () {
    return {
      imgList: [],
      copyList: []
    }
  },
  mounted () {
    this._setData()
  },
  computed: {
    isOffline () {
      return this.$store.getters.getIsOffline
    }
  },
  methods: {
    _setData () {
      if (isJsonString(this.list)) {
        const copyList = (JSON.parse(this.list) || []).map(item => item.url)
        console.log('copyList: ', copyList)
        if (this.isOffline) {
          this.imgList = copyList
        } else {
          for (const item of copyList) {
            loadAsyncImgByQueue(item, res => {
              this.imgList.push(res)
            })
          }
        }
      } else {
        const copyList = this.list || []
        for (const item of copyList) {
          loadAsyncImgByQueue(item, res => {
            this.imgList.push(res)
          })
        }
      }
    },
    imgPreview (index) {
      this.imagesGroup = []
      this.imgList &&
        this.imgList.forEach((item) => {
          this.imagesGroup.push(item)
        })
      ImagePreview({
        images: this.imagesGroup,
        startPosition: index
      })
    }
  }
}
</script>
<style  lang='less' scoped>
.preview-wrapper {
  width: calc(80% + 8px);
  margin-top: 10px;
  .van-image {
    margin-right: 8px;
  }
}
</style>
