var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ReportOrderDetail" },
    [
      _c("my-nav", { attrs: { title: "工单详情" } }),
      _c(
        "van-tabs",
        {
          attrs: {
            animated: "",
            color: "#3366FE",
            sticky: "",
            "offset-top": "44",
            "title-active-color": "#3366FE",
            "title-inactive-color": "#7C7F8A",
            "line-height": "2px",
            "line-width": "187px",
          },
          model: {
            value: _vm.active,
            callback: function ($$v) {
              _vm.active = $$v
            },
            expression: "active",
          },
        },
        [
          _c(
            "van-tab",
            {
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "tab-class" }, [
                        _c("div", [_vm._v("工单信息")]),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c(
                "div",
                { staticClass: "scroll-detail-wrapper mb-10px" },
                [
                  _c("div", { staticClass: "order-cell-title" }, [
                    _c(
                      "div",
                      { staticClass: "flex-box" },
                      [
                        _c("div", { staticClass: "order-number" }, [
                          _vm._v(_vm._s(_vm.detail.order_no)),
                        ]),
                        _c(
                          "van-tag",
                          {
                            staticClass: "copy-tag mr-8px",
                            attrs: {
                              plain: "",
                              type: "primary",
                              color: "#999",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.copyOrderNumber(_vm.detail.order_no)
                              },
                            },
                          },
                          [_vm._v("复制")]
                        ),
                        _vm.detail.isTouSu === 1
                          ? _c("van-tag", { attrs: { type: "danger" } }, [
                              _vm._v("投诉"),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "status color-blue" }, [
                      _vm._v(_vm._s(_vm.detail.node_name)),
                    ]),
                  ]),
                  _c("div", { staticClass: "sub-title" }, [
                    _vm._v("报事人信息"),
                  ]),
                  _c(
                    "van-row",
                    { staticClass: "order-body-cell" },
                    [
                      _c(
                        "van-col",
                        { staticClass: "sub-key", attrs: { span: 6 } },
                        [_vm._v("报事人：")]
                      ),
                      _c(
                        "van-col",
                        {
                          staticClass: "flex-box sub-value",
                          attrs: { span: 18 },
                        },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.detail.reportUserName)),
                          ]),
                          _vm.detail.reportUserPhone
                            ? _c("span", { staticClass: "hpone-icon" })
                            : _vm._e(),
                          _vm.detail.reportUserPhone
                            ? _c(
                                "span",
                                {
                                  staticClass: "color-blue",
                                  on: {
                                    click: function ($event) {
                                      return _vm.takePhone(
                                        _vm.detail.reportUserPhone
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.detail.reportUserPhone))]
                              )
                            : _vm._e(),
                          _vm.detail.reportUserLevel
                            ? _c(
                                "van-tag",
                                {
                                  staticClass: "ml-8px",
                                  attrs: {
                                    plain: "",
                                    type: "primary",
                                    color: "#F47A45",
                                  },
                                },
                                [_vm._v(_vm._s(_vm.detail.reportUserLevel))]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "van-row",
                    { staticClass: "order-body-cell", attrs: { gutter: "10" } },
                    [
                      _c(
                        "van-col",
                        { staticClass: "sub-key", attrs: { span: 6 } },
                        [_vm._v("项目名称：")]
                      ),
                      _c(
                        "van-col",
                        { staticClass: "sub-value", attrs: { span: 18 } },
                        [_c("span", [_vm._v(_vm._s(_vm.detail.project_name))])]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "van-row",
                    { staticClass: "order-body-cell", attrs: { gutter: "10" } },
                    [
                      _c(
                        "van-col",
                        { staticClass: "sub-key", attrs: { span: 6 } },
                        [_vm._v("报事位置：")]
                      ),
                      _c(
                        "van-col",
                        { staticClass: "sub-value", attrs: { span: 18 } },
                        [_c("span", [_vm._v(_vm._s(_vm.detail.addressName))])]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "van-row",
                    { staticClass: "order-body-cell", attrs: { gutter: "10" } },
                    [
                      _c(
                        "van-col",
                        { staticClass: "sub-key", attrs: { span: 6 } },
                        [_vm._v("报事时间：")]
                      ),
                      _c(
                        "van-col",
                        { staticClass: "sub-value", attrs: { span: 18 } },
                        [_c("span", [_vm._v(_vm._s(_vm.detail.create_time))])]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "van-row",
                    { staticClass: "order-body-cell", attrs: { gutter: "10" } },
                    [
                      _c(
                        "van-col",
                        { staticClass: "sub-key", attrs: { span: 6 } },
                        [_vm._v("预约时间：")]
                      ),
                      _c(
                        "van-col",
                        { staticClass: "sub-value", attrs: { span: 18 } },
                        [_c("span", [_vm._v(_vm._s(_vm.detail.bookingTime))])]
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "sub-title" }, [_vm._v("报事信息")]),
                  _c(
                    "van-row",
                    { staticClass: "order-body-cell", attrs: { gutter: "10" } },
                    [
                      _c(
                        "van-col",
                        { staticClass: "sub-key", attrs: { span: 6 } },
                        [_vm._v("报事来源：")]
                      ),
                      _c(
                        "van-col",
                        { staticClass: "sub-value", attrs: { span: 18 } },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.detail.incidentSource)),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "van-row",
                    { staticClass: "order-body-cell", attrs: { gutter: "10" } },
                    [
                      _c(
                        "van-col",
                        { staticClass: "sub-key", attrs: { span: 6 } },
                        [_vm._v("报事责任：")]
                      ),
                      _c(
                        "van-col",
                        { staticClass: "sub-value", attrs: { span: 18 } },
                        [_c("span", [_vm._v(_vm._s(_vm.detail.duty))])]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "van-row",
                    { staticClass: "order-body-cell", attrs: { gutter: "10" } },
                    [
                      _c(
                        "van-col",
                        { staticClass: "sub-key", attrs: { span: 6 } },
                        [_vm._v("报事类型：")]
                      ),
                      _c(
                        "van-col",
                        {
                          staticClass: "flex-box sub-value",
                          attrs: { span: 18 },
                        },
                        [
                          _c("span", [_vm._v(_vm._s(_vm.detail.drClassName))]),
                          _vm.reverseButton
                            ? _c(
                                "van-tag",
                                {
                                  staticClass: "ml-8px",
                                  attrs: {
                                    type: "primary",
                                    color: "#ECF1FF",
                                    "text-color": "#3366FE",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.intoCirculation(
                                        _vm.reverseButton,
                                        true
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.reverseButton.buttonName))]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "van-row",
                    { staticClass: "order-body-cell", attrs: { gutter: "10" } },
                    [
                      _c(
                        "van-col",
                        { staticClass: "sub-key", attrs: { span: 6 } },
                        [_vm._v("报事方式：")]
                      ),
                      _c(
                        "van-col",
                        { staticClass: "sub-value", attrs: { span: 18 } },
                        [_c("span", [_vm._v(_vm._s(_vm.detail.infoSource))])]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "van-row",
                    { staticClass: "order-body-cell" },
                    [
                      _c(
                        "van-col",
                        { staticClass: "sub-key", attrs: { span: 6 } },
                        [_vm._v("接待人：")]
                      ),
                      _c(
                        "van-col",
                        {
                          staticClass: "flex-box sub-value",
                          attrs: { span: 18 },
                        },
                        [
                          _c("span", [_vm._v(_vm._s(_vm.detail.admiMan))]),
                          _vm.detail.admiPhone
                            ? _c("span", { staticClass: "hpone-icon" })
                            : _vm._e(),
                          _vm.detail.admiPhone
                            ? _c(
                                "span",
                                {
                                  staticClass: "color-blue",
                                  on: {
                                    click: function ($event) {
                                      return _vm.takePhone(_vm.detail.admiPhone)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.detail.admiPhone))]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "van-row",
                    { staticClass: "order-body-cell" },
                    [
                      _c(
                        "van-col",
                        { staticClass: "sub-key", attrs: { span: 6 } },
                        [_vm._v("处理人：")]
                      ),
                      _c(
                        "van-col",
                        {
                          staticClass: "flex-box sub-value",
                          attrs: { span: 18 },
                        },
                        [
                          _c("span", [_vm._v(_vm._s(_vm.detail.handler_name))]),
                          _vm.detail.handler_phone
                            ? _c("span", { staticClass: "hpone-icon" })
                            : _vm._e(),
                          _vm.detail.handler_phone
                            ? _c(
                                "span",
                                {
                                  staticClass: "color-blue",
                                  on: {
                                    click: function ($event) {
                                      return _vm.takePhone(
                                        _vm.detail.handler_phone
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.detail.handler_phone))]
                              )
                            : _vm._e(),
                          _vm.detail.isTouSu === 1 &&
                          _vm.detail.node_name === "处理中" &&
                          _vm.detail.arriveData
                            ? _c(
                                "van-tag",
                                {
                                  staticClass: "ml-8px",
                                  attrs: { type: "primary" },
                                  on: { click: _vm.handleRouterToTousuList },
                                },
                                [_vm._v("投诉还原")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "van-row",
                    { staticClass: "order-body-cell", attrs: { gutter: "10" } },
                    [
                      _c(
                        "van-col",
                        { staticClass: "sub-key", attrs: { span: 6 } },
                        [_vm._v("报事类别：")]
                      ),
                      _c(
                        "van-col",
                        { staticClass: "sub-value", attrs: { span: 18 } },
                        [_c("span", [_vm._v(_vm._s(_vm.detail.category_name))])]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "van-row",
                    { staticClass: "order-body-cell", attrs: { gutter: "10" } },
                    [
                      _c(
                        "van-col",
                        { staticClass: "sub-key", attrs: { span: 6 } },
                        [_vm._v("报事细类：")]
                      ),
                      _c(
                        "van-col",
                        { staticClass: "sub-value", attrs: { span: 18 } },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.detail.small_category_name)),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "sub-title" }, [_vm._v("报事内容")]),
                  _c(
                    "van-row",
                    { staticClass: "order-body-cell", attrs: { gutter: "10" } },
                    [
                      _c(
                        "van-col",
                        { staticClass: "sub-key", attrs: { span: 6 } },
                        [_vm._v("报事描述：")]
                      ),
                      _c(
                        "van-col",
                        { staticClass: "sub-value", attrs: { span: 18 } },
                        [
                          _c("div", [_vm._v(_vm._s(_vm.detail.remark))]),
                          _vm.detail.attachment_list &&
                          _vm.detail.attachment_list.length > 0
                            ? _c(
                                "div",
                                { staticClass: "detail_image_list" },
                                _vm._l(
                                  _vm.detail.attachment_list,
                                  function (list, index) {
                                    return _c("van-image", {
                                      key: index,
                                      staticClass: "photo",
                                      attrs: {
                                        width: "40",
                                        height: "40",
                                        radius: "4",
                                        src: list.url,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.imgPreview(
                                            index,
                                            _vm.detail.attachment_list
                                          )
                                        },
                                      },
                                    })
                                  }
                                ),
                                1
                              )
                            : _vm._e(),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.detail.incidentFree && _vm.detail.incidentFree.length
                ? _c(
                    "div",
                    { staticClass: "scroll-detail-wrapper mb-10px" },
                    [
                      _c("div", { staticClass: "sub-title" }, [
                        _vm._v("收费信息"),
                      ]),
                      _c(
                        "van-row",
                        {
                          staticClass: "order-body-cell",
                          attrs: { gutter: "10" },
                        },
                        [
                          _c(
                            "van-col",
                            { staticClass: "sub-key", attrs: { span: 6 } },
                            [_vm._v("是否收费：")]
                          ),
                          _c(
                            "van-col",
                            { staticClass: "sub-value", attrs: { span: 18 } },
                            [_c("span", [_vm._v("是")])]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "van-row",
                        {
                          staticClass: "order-body-cell",
                          attrs: { gutter: "10" },
                        },
                        [
                          _c(
                            "van-col",
                            { staticClass: "sub-key", attrs: { span: 6 } },
                            [_vm._v("收费金额：")]
                          ),
                          _c(
                            "van-col",
                            { staticClass: "sub-value", attrs: { span: 18 } },
                            [
                              _c("div", { staticClass: "text-[#3366FE]" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.detail.feesAmount) + "元 "
                                ),
                              ]),
                              _vm._l(
                                _vm.detail.incidentFree,
                                function (item, index) {
                                  return _c("div", { key: index }, [
                                    _c("span", [_vm._v(_vm._s(item.costName))]),
                                    _c("span", [
                                      _vm._v("-" + _vm._s(item.stanName)),
                                    ]),
                                    _c(
                                      "span",
                                      { staticClass: "text-[#3366FE] ml-4px" },
                                      [_vm._v("¥" + _vm._s(item.dueAmount))]
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "text-[#3366FE] ml-4px" },
                                      [_vm._v(_vm._s(item.paysItem))]
                                    ),
                                  ])
                                }
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.detail.incidentTouSu
                ? _c(
                    "div",
                    { staticClass: "scroll-detail-wrapper mb-10px" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "sub-title flex justify-between items-center",
                        },
                        [
                          _c("span", [_vm._v("投诉信息")]),
                          _c(
                            "span",
                            {
                              staticClass: "text-[#3366FE] text-[16px]",
                              on: { click: _vm.handleRouterToTousuList },
                            },
                            [_vm._v("更多>")]
                          ),
                        ]
                      ),
                      _c(
                        "van-row",
                        {
                          staticClass: "order-body-cell",
                          attrs: { gutter: "10" },
                        },
                        [
                          _c(
                            "van-col",
                            { staticClass: "sub-key", attrs: { span: 6 } },
                            [_vm._v("是否投诉：")]
                          ),
                          _c(
                            "van-col",
                            { staticClass: "sub-value", attrs: { span: 18 } },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.detail.isTouSu === 1 ? "是" : "否")
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "van-row",
                        {
                          staticClass: "order-body-cell",
                          attrs: { gutter: "10" },
                        },
                        [
                          _c(
                            "van-col",
                            { staticClass: "sub-key", attrs: { span: 6 } },
                            [_vm._v("投诉性质：")]
                          ),
                          _c(
                            "van-col",
                            { staticClass: "sub-value", attrs: { span: 18 } },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.detail.incidentTouSu.complaintType)
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "van-row",
                        {
                          staticClass: "order-body-cell",
                          attrs: { gutter: "10" },
                        },
                        [
                          _c(
                            "van-col",
                            { staticClass: "sub-key", attrs: { span: 6 } },
                            [_vm._v("投诉等级：")]
                          ),
                          _c(
                            "van-col",
                            { staticClass: "sub-value", attrs: { span: 18 } },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.detail.incidentTouSu.touSuDegree)
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "van-row",
                        {
                          staticClass: "order-body-cell",
                          attrs: { gutter: "10" },
                        },
                        [
                          _c(
                            "van-col",
                            { staticClass: "sub-key", attrs: { span: 6 } },
                            [_vm._v("投诉还原：")]
                          ),
                          _c(
                            "van-col",
                            { staticClass: "sub-value", attrs: { span: 18 } },
                            [
                              _c("div", [
                                _vm._v(
                                  _vm._s(_vm.detail.incidentTouSu.touSuReasons)
                                ),
                              ]),
                              _vm.detail.incidentTouSu.touSuImg &&
                              _vm.detail.incidentTouSu.touSuImg.length > 0
                                ? _c(
                                    "div",
                                    { staticClass: "detail_image_list" },
                                    _vm._l(
                                      _vm.detail.incidentTouSu.touSuImg,
                                      function (img, index) {
                                        return _c("van-image", {
                                          key: index,
                                          staticClass: "photo",
                                          attrs: {
                                            width: "40",
                                            height: "40",
                                            radius: "4",
                                            src: img,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.imgPreview(
                                                index,
                                                _vm.detail.incidentTouSu
                                                  .touSuImg
                                              )
                                            },
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.detail.incidentProcessList &&
              _vm.detail.incidentProcessList.length
                ? _c(
                    "div",
                    { staticClass: "scroll-detail-wrapper" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "sub-title flex justify-between items-center",
                        },
                        [_c("span", [_vm._v("审核信息")])]
                      ),
                      _c("myTableVue", {
                        attrs: {
                          columns: _vm.tableColumns,
                          tableData: _vm.detail.incidentProcessList,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
          _c(
            "van-tab",
            {
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "tab-class" }, [
                        _c("div", [_vm._v("跟进信息")]),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c(
                "div",
                { staticClass: "scroll-detail-wrapper" },
                [
                  _c(
                    "van-steps",
                    {
                      staticClass: "stpe-component",
                      attrs: {
                        direction: "vertical",
                        "active-color": "#3366fe",
                      },
                    },
                    _vm._l(_vm.logList, function (item, index) {
                      return _c(
                        "van-step",
                        { key: index, staticClass: "step" },
                        [
                          _c("div", {
                            staticClass: "step-active-dot",
                            attrs: { slot: "active-icon" },
                            slot: "active-icon",
                          }),
                          _c(
                            "div",
                            { staticClass: "step-cell" },
                            [
                              item.currNodeId !== item.preNodeId
                                ? [
                                    _c("span", { staticClass: "color-blue" }, [
                                      _vm._v(_vm._s(item.preNodeName)),
                                    ]),
                                    item.preNodeName
                                      ? _c("em", {
                                          staticClass: "icon-arrow-blue",
                                        })
                                      : _vm._e(),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "color-blue",
                                        class: { "ml-24": item.preNodeName },
                                      },
                                      [_vm._v(_vm._s(item.curNodeName))]
                                    ),
                                  ]
                                : [
                                    _c("span", { staticClass: "color-blue" }, [
                                      _vm._v(_vm._s(item.curNodeName)),
                                    ]),
                                  ],
                              _c("span", { staticClass: "color-gray" }, [
                                _vm._v("「" + _vm._s(item.handlerName)),
                                item.handleMobile
                                  ? _c("span", [
                                      _vm._v(
                                        "(" + _vm._s(item.handleMobile) + ")"
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v("」"),
                              ]),
                            ],
                            2
                          ),
                          item.cuiban
                            ? _c(
                                "div",
                                { staticStyle: { margin: "4px 0" } },
                                [
                                  _c(
                                    "van-tag",
                                    {
                                      staticStyle: {
                                        background: "rgba(253, 141, 55, 0.1)",
                                      },
                                      attrs: {
                                        plain: "",
                                        color: "#FD8D37",
                                        size: "medium",
                                      },
                                    },
                                    [
                                      _c("span", { staticClass: "font-bold" }, [
                                        _vm._v("催办"),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            { staticClass: "step-cell time color-gray" },
                            [_vm._v(" " + _vm._s(item.createTime) + " ")]
                          ),
                          _c("div", { staticClass: "step-cell color-black" }, [
                            _vm._v(" " + _vm._s(item.content) + " "),
                          ]),
                          item.attachments && item.attachments.length
                            ? _c(
                                "div",
                                { staticClass: "order-photos" },
                                _vm._l(
                                  item.attachments,
                                  function (list, index) {
                                    return _c("van-image", {
                                      key: index,
                                      staticClass: "photo",
                                      attrs: {
                                        width: "40",
                                        height: "40",
                                        radius: "4",
                                        src: list.url,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.imgPreview(
                                            index,
                                            item.attachments
                                          )
                                        },
                                      },
                                    })
                                  }
                                ),
                                1
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            { staticClass: "file-list" },
                            _vm._l(item.fileList, function (file, index) {
                              return _c(
                                "div",
                                { key: file.id + index, staticClass: "list" },
                                [
                                  _c("i", { staticClass: "file-icon" }),
                                  _c("span", { staticClass: "file-name" }, [
                                    _vm._v(_vm._s(file.name)),
                                  ]),
                                ]
                              )
                            }),
                            0
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      !_vm.readonly && _vm.buttonList && _vm.buttonList.length > 0
        ? _c(
            "div",
            {
              staticClass: "bottom-area",
              style: `--column:${Math.min(_vm.buttonList.length, 3)} `,
            },
            _vm._l(_vm.buttonList, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "button",
                  on: {
                    click: function ($event) {
                      return _vm.intoCirculation(item)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(item.buttonName) + " ")]
              )
            }),
            0
          )
        : _vm._e(),
      _c(
        "van-overlay",
        {
          attrs: {
            show: _vm.phoneShow,
            "class-name": "overlay",
            "z-index": "9999",
          },
          on: {
            click: function ($event) {
              _vm.phoneShow = false
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "wrapper",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                },
              },
            },
            [
              _c("div", { staticClass: "phone-lay" }, [
                _c(
                  "div",
                  {
                    staticClass: "phone-cell",
                    on: {
                      click: function ($event) {
                        return _vm.phoneOperate(1)
                      },
                    },
                  },
                  [_vm._v(" 拨打" + _vm._s(_vm.stashPhone) + " ")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "phone-cell",
                    on: {
                      click: function ($event) {
                        return _vm.phoneOperate(2)
                      },
                    },
                  },
                  [_vm._v("取消")]
                ),
              ]),
            ]
          ),
        ]
      ),
      _vm.circulationShow
        ? _c("circulation", {
            attrs: {
              "is-reverse": _vm.isReverseButton,
              order: _vm.detail,
              title: _vm.circulationTitle,
              "field-config": _vm.currentFieldConfig,
              show: _vm.circulationShow,
            },
            on: {
              refresh: _vm.getOrderDetail,
              ok: _vm.getOrderDetail,
              "update:show": function ($event) {
                _vm.circulationShow = $event
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }