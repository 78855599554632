var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "orderFlow common-page" },
    [
      _c(
        "my-nav",
        { attrs: { title: _vm.title } },
        [
          _vm.getOrderTypeConf.equipmentOrderTypes.includes(
            _vm.orderDetail.order_type
          ) &&
          (_vm.buttonInfo.disposeTemplateNodeCode === "yiwancheng" ||
            _vm.buttonInfo.buttonName === "升级") &&
          !_vm.orderDetail.problemOrderId
            ? _c("ScanIcon", {
                attrs: { slot: "right" },
                on: { scanSuccess: _vm.scanSuccess },
                slot: "right",
              })
            : _vm._e(),
        ],
        1
      ),
      !_vm.buttonInfo.hiddenState
        ? _c("div", { staticClass: "group-title color-blue" }, [
            _c("span", [_vm._v(_vm._s(_vm.buttonInfo.nodeName) + " ")]),
            _vm.buttonInfo.disposeNodeName
              ? _c("span", { staticClass: "icon-arrow-blue" })
              : _vm._e(),
            _c("span", [_vm._v(_vm._s(_vm.buttonInfo.disposeNodeName))]),
          ])
        : _vm._e(),
      _c(
        "van-form",
        { staticClass: "orderFlow-form" },
        [
          _vm.fieldState.equipType.show
            ? _c("MyFieldOfEquipmentSelect", {
                attrs: {
                  address: _vm.form.itemName,
                  "address-id": _vm.form.itemCode,
                  "really-type": _vm.form.itemType,
                  "show-location": true,
                  projectCode: _vm.projectCode,
                  required: _vm.fieldState.equipType.required,
                  editable: true,
                  "route-name": "OrderCirculation",
                  categoryCode: _vm.form.categoryCode,
                  reallyType: _vm.form.reallyType,
                },
                on: {
                  "update:address": function ($event) {
                    return _vm.$set(_vm.form, "itemName", $event)
                  },
                  "update:addressId": function ($event) {
                    return _vm.$set(_vm.form, "itemCode", $event)
                  },
                  "update:address-id": function ($event) {
                    return _vm.$set(_vm.form, "itemCode", $event)
                  },
                  "update:reallyType": [
                    function ($event) {
                      return _vm.$set(_vm.form, "itemType", $event)
                    },
                    function ($event) {
                      return _vm.$set(_vm.form, "reallyType", $event)
                    },
                  ],
                  "update:really-type": [
                    function ($event) {
                      return _vm.$set(_vm.form, "itemType", $event)
                    },
                    function ($event) {
                      return _vm.$set(_vm.form, "reallyType", $event)
                    },
                  ],
                  "update:categoryCode": function ($event) {
                    return _vm.$set(_vm.form, "categoryCode", $event)
                  },
                  "update:category-code": function ($event) {
                    return _vm.$set(_vm.form, "categoryCode", $event)
                  },
                },
              })
            : _vm._e(),
          _vm.fieldState.equipMalfReasonType.show
            ? _c("MyFieldOfMalfunctionReasonSelect", {
                attrs: {
                  "route-name": "OrderCirculation",
                  "problem-list":
                    _vm.form.problemOrders || _vm.orderDetail.problemOrders,
                  guide: false,
                  categoryCode: _vm.form.categoryCode,
                  reallyType: _vm.form.itemType,
                  required: _vm.fieldState.equipMalfReasonType.required,
                },
                on: { confirm: _vm.malfunctionReasonSelect },
              })
            : _vm._e(),
          _vm.fieldState.selectProblemShow.show
            ? _c("van-field", {
                staticClass: "form-input",
                attrs: {
                  readonly: "",
                  clickable: "",
                  label: "问题项",
                  "label-width": "150px",
                  required: _vm.fieldState.selectProblemShow.required,
                  "right-icon": "arrow",
                  "input-align": "right",
                  placeholder: "请选择",
                },
                on: { click: _vm.selectProblemItem },
              })
            : _vm._e(),
          _vm.fieldState.selectProblemShow.show &&
          _vm.form.problemItemReqs &&
          _vm.form.problemItemReqs.length > 0
            ? _c(
                "div",
                { staticClass: "problem-list" },
                _vm._l(_vm.form.problemItemReqs, function (item, index) {
                  return _c("div", { key: index, staticClass: "item" }, [
                    _c("p", { staticClass: "problem-title" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            `${item.parentName ? item.parentName + "/" : ""}${
                              item.problemName || item.mainPartName
                            }`
                          ) +
                          " "
                      ),
                    ]),
                    item.questLabel.length > 0
                      ? _c(
                          "p",
                          { staticClass: "problem-info" },
                          _vm._l(item.questLabel, function (item2, index2) {
                            return _c("span", { key: index2 }, [
                              index2 !== 0 ? _c("i", [_vm._v("|")]) : _vm._e(),
                              _vm._v(_vm._s(item2.questLabelName || item2)),
                            ])
                          }),
                          0
                        )
                      : _vm._e(),
                    item.rectifyPeopls && item.rectifyPeopls.name
                      ? _c("p", { staticClass: "problem-person" }, [
                          _vm._v(
                            " 整改人：" + _vm._s(item.rectifyPeopls.name) + " "
                          ),
                        ])
                      : _vm._e(),
                  ])
                }),
                0
              )
            : _vm._e(),
          _vm.fieldState.selectProblemShow.show
            ? _c("problem-items", {
                ref: "problemItems",
                attrs: {
                  show: _vm.problemItemsShow,
                  "project-code-prop": _vm.projectCode,
                  radio: true,
                  "problem-data": _vm.form.problemItemReqs,
                  "order-detail": _vm.getDetail,
                },
                on: {
                  "update:show": function ($event) {
                    _vm.problemItemsShow = $event
                  },
                  result: _vm.setProblemList,
                },
              })
            : _vm._e(),
          _vm.fieldState.reasonType.show
            ? _c("van-field", {
                staticClass: "form-input",
                attrs: {
                  readonly: "",
                  clickable: "",
                  required: _vm.fieldState.reasonType.required,
                  name: "bigCategoryName",
                  label: "原因大类",
                  "right-icon": "arrow",
                  "input-align": "right",
                  placeholder: "请选择",
                  value: _vm.form.bigCategoryName,
                },
                on: { click: _vm.selectReason },
              })
            : _vm._e(),
          _vm.fieldState.effectiveType.show
            ? _c("van-field", {
                attrs: {
                  readonly: "",
                  clickable: "",
                  required: _vm.fieldState.effectiveType.required,
                  name: "effectiveType",
                  label: "有效升级",
                  "input-align": "left",
                  placeholder: "请选择",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "input",
                      fn: function () {
                        return [
                          _c(
                            "van-radio-group",
                            {
                              attrs: { direction: "horizontal" },
                              model: {
                                value: _vm.form.isEfficacy,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "isEfficacy", $$v)
                                },
                                expression: "form.isEfficacy",
                              },
                            },
                            [
                              _c("van-radio", { attrs: { name: "1" } }, [
                                _vm._v("是"),
                              ]),
                              _c("van-radio", { attrs: { name: "0" } }, [
                                _vm._v("否"),
                              ]),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  1171888195
                ),
              })
            : _vm._e(),
          _vm.fieldState.backReason.show
            ? _c("van-field", {
                staticClass: "mt-8",
                attrs: {
                  readonly: "",
                  clickable: "",
                  required: _vm.fieldState.backReason.required,
                  value: _vm.form.codeName,
                  name: "effectiveType",
                  "right-icon": "arrow",
                  label: "退回原因",
                  "input-align": "right",
                  placeholder: "请选择",
                },
                on: {
                  click: function ($event) {
                    _vm.backReportSysReasonSelectShow = true
                  },
                },
              })
            : _vm._e(),
          _vm.fieldState.reasonFineType.show
            ? _c("van-field", {
                staticClass: "form-input",
                attrs: {
                  readonly: "",
                  clickable: "",
                  required: _vm.fieldState.reasonFineType.required,
                  name: "bigCategoryName",
                  label: "原因细类",
                  "right-icon": "arrow",
                  "input-align": "right",
                  placeholder: "请选择",
                  value: _vm.form.smallCategoryName,
                },
                on: { click: _vm.selectFineReason },
              })
            : _vm._e(),
          _vm.fieldState.staff.show
            ? _c("van-field", {
                staticClass: "form-input mt-8",
                attrs: {
                  readonly: "",
                  clickable: "",
                  required: _vm.fieldState.staff.required,
                  name: "handler",
                  label: "处理人",
                  "right-icon": "arrow",
                  "input-align": "right",
                  placeholder: "请选择",
                  value: _vm.form.handlerName,
                },
                on: { click: _vm.selectHandler },
              })
            : _vm._e(),
          _vm.fieldState.problemTags.show
            ? _c("div", { staticClass: "question-tags mt-8" }, [
                _c("div", { staticClass: "label" }, [
                  _c(
                    "div",
                    {
                      staticClass: "text",
                      class: { required: _vm.fieldState.problemTags.required },
                    },
                    [_vm._v(" 问题标签 ")]
                  ),
                  !_vm.questionTags.length
                    ? _c(
                        "div",
                        [
                          _c("span", [_vm._v("无")]),
                          _c("van-icon", {
                            staticStyle: {
                              "margin-left": "8px",
                              visibility: "hidden",
                            },
                            attrs: { name: "arrow", color: "#969799" },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
                _vm.questionTags.length
                  ? _c(
                      "div",
                      { staticClass: "question-tags-cell" },
                      _vm._l(_vm.questionTags, function (item) {
                        return _c(
                          "div",
                          {
                            key: item.id,
                            staticClass: "my-tag",
                            class: { active: item.select },
                            on: {
                              click: function ($event) {
                                return _vm.selectTags(item)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(item.name) + " ")]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm.fieldState.closeType.show
            ? _c("van-field", {
                staticClass: "form-input mt-8",
                attrs: {
                  readonly: "",
                  clickable: "",
                  required: _vm.fieldState.closeType.required,
                  name: "handler",
                  label: "关闭类型",
                  "right-icon": "arrow",
                  "input-align": "right",
                  placeholder: "请选择",
                  value: _vm.form.closeTypeName,
                },
                on: { click: _vm.selectCloseType },
              })
            : _vm._e(),
          _vm.closeReasonCellShow
            ? _c("van-field", {
                attrs: {
                  readonly: "",
                  clickable: "",
                  required: true,
                  name: "handler",
                  label: "非正常关闭原因",
                  "label-width": "auto",
                  "right-icon": "arrow",
                  "input-align": "right",
                  placeholder: "请选择",
                  value: _vm.form.closeReasonName,
                },
                on: { click: _vm.selectCloseReason },
              })
            : _vm._e(),
          _vm.timeoutSelectAble
            ? _c("van-field", {
                staticClass: "form-input mt-8",
                attrs: {
                  readonly: "",
                  clickable: "",
                  required: true,
                  name: "timeoutReason",
                  label: "超时原因",
                  "right-icon": "arrow",
                  "input-align": "right",
                  placeholder: "请选择",
                  value: _vm.timeoutReasonValue,
                },
                on: {
                  click: function ($event) {
                    _vm.timeoutReasonShow = true
                  },
                },
              })
            : _vm._e(),
          _c(
            "van-popup",
            {
              attrs: { position: "bottom" },
              model: {
                value: _vm.timeoutReasonShow,
                callback: function ($$v) {
                  _vm.timeoutReasonShow = $$v
                },
                expression: "timeoutReasonShow",
              },
            },
            [
              _c("van-picker", {
                attrs: {
                  title: "选择超时原因",
                  "show-toolbar": "",
                  columns: _vm.timeoutReasonList,
                  "value-key": "name",
                },
                on: {
                  cancel: function ($event) {
                    _vm.timeoutReasonShow = false
                  },
                  confirm: _vm.timeoutReasonSelect,
                },
                scopedSlots: _vm._u([
                  {
                    key: "option",
                    fn: function (item) {
                      return [_c("span", [_vm._v(_vm._s(item.name))])]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm.issueReasonIF
            ? _c("van-field", {
                staticClass: "form-input",
                attrs: {
                  readonly: "",
                  clickable: "",
                  name: "grade",
                  value: _vm.issueReasonValue,
                  label: "问题原因",
                  "right-icon": "arrow",
                  "input-align": "right",
                  placeholder: "请选择问题原因",
                  required: _vm.issueReasonRequired,
                },
                on: {
                  click: function ($event) {
                    _vm.issueReasonShow = true
                  },
                },
              })
            : _vm._e(),
          _vm.orderDetail.order_nature === 6 &&
          _vm.buttonInfo.curTemplateNodeCode === "yiwancheng" &&
          _vm.buttonInfo.disposeTemplateNodeCode === "yiwanjie"
            ? _c("div", { staticClass: "rectify-swiper" }, [
                _c(
                  "div",
                  { staticClass: "item" },
                  [
                    _c("div", { staticClass: "title" }, [_vm._v("整改前")]),
                    _c(
                      "swiper",
                      {
                        ref: "beforeSwiper",
                        staticClass: "swiper-container",
                        attrs: { options: _vm.swiperOption },
                        on: { slideChange: _vm.onSlideChange_before },
                      },
                      _vm._l(
                        _vm.beforeHandleAttachments,
                        function (image, index) {
                          return _c(
                            "swiper-slide",
                            { key: index, staticClass: ".swiper-slide" },
                            [
                              _c("van-image", {
                                staticClass: "photo",
                                attrs: {
                                  width: "100%",
                                  height: "100%",
                                  src: image.url,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleClickSwiper(
                                      index,
                                      _vm.beforeHandleAttachments,
                                      "before"
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        }
                      ),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "item" },
                  [
                    _c("div", { staticClass: "title" }, [_vm._v("整改后")]),
                    _c(
                      "swiper",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.afterHandleAttachments.length > 0,
                            expression: "afterHandleAttachments.length > 0",
                          },
                        ],
                        ref: "afterSwiper",
                        staticClass: "swiper-container",
                        attrs: { options: _vm.swiperOption },
                        on: { slideChange: _vm.onSlideChange_after },
                      },
                      _vm._l(
                        _vm.afterHandleAttachments,
                        function (image, index) {
                          return _c(
                            "swiper-slide",
                            { key: index, staticClass: ".swiper-slide" },
                            [
                              _c("van-image", {
                                staticClass: "photo",
                                attrs: {
                                  width: "100%",
                                  height: "100%",
                                  src: image.url,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleClickSwiper(
                                      index,
                                      _vm.afterHandleAttachments,
                                      "after"
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        }
                      ),
                      1
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.buttonInfo.buttonName !== "升级" &&
          _vm.buttonInfo.buttonName !== "非设备"
            ? _c("van-field", {
                staticClass: "mt-8",
                attrs: {
                  readonly: "",
                  clickable: "",
                  name: "picker",
                  value: _vm.quickInput,
                  "right-icon": "arrow",
                  label: "快捷输入",
                  placeholder: "点击选择进行快捷输入备注",
                },
                on: { click: _vm.openQuickInput },
              })
            : _vm._e(),
          _c(
            "van-popup",
            {
              attrs: { position: "bottom" },
              model: {
                value: _vm.quickInputPickerShow,
                callback: function ($$v) {
                  _vm.quickInputPickerShow = $$v
                },
                expression: "quickInputPickerShow",
              },
            },
            [
              _c("van-picker", {
                attrs: { "show-toolbar": "", columns: _vm.quickInputColumns },
                on: {
                  confirm: _vm.onQuickInputConfirm,
                  cancel: function ($event) {
                    _vm.quickInputPickerShow = false
                  },
                },
              }),
            ],
            1
          ),
          _c("van-field", {
            staticClass: "form-textarea",
            attrs: {
              name: "remark",
              label: "备注",
              rows: "3",
              type: "textarea",
              placeholder: "请输入备注",
            },
            scopedSlots: _vm._u(
              [
                _vm.isOffline
                  ? {
                      key: "right-icon",
                      fn: function () {
                        return [
                          _c("micro-text", { on: { speakRes: _vm.speakRes } }),
                        ]
                      },
                      proxy: true,
                    }
                  : null,
              ],
              null,
              true
            ),
            model: {
              value: _vm.form.desc,
              callback: function ($$v) {
                _vm.$set(_vm.form, "desc", $$v)
              },
              expression: "form.desc",
            },
          }),
          _c(
            "div",
            { staticClass: "photoUpload mt-8" },
            [
              _c("common-upload", {
                attrs: {
                  file: _vm.form.attachments,
                  "enable-watermark": false,
                  "enable-album": _vm.enableAlbum,
                  "enable-edit": false,
                  "order-id": _vm.orderDetail.id,
                  required: _vm.fieldState.attachmentsShow.required,
                },
                on: {
                  "update:file": function ($event) {
                    return _vm.$set(_vm.form, "attachments", $event)
                  },
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "bottom-mat" }),
          _c(
            "div",
            { staticClass: "bottom-area" },
            [
              [
                _c(
                  "van-button",
                  {
                    staticClass: "button blue-reverse",
                    attrs: { disabled: _vm.submitLoading },
                    on: { click: _vm.flowCancel },
                  },
                  [_vm._v("取消")]
                ),
                _c(
                  "van-button",
                  {
                    staticClass: "button blue",
                    attrs: { disabled: _vm.submitLoading },
                    on: { click: _vm.flowSubmit },
                  },
                  [_vm._v("确定")]
                ),
              ],
            ],
            2
          ),
        ],
        1
      ),
      _c("div", { staticClass: "space" }),
      _vm.selectHandlerShow
        ? _c("select-handler", {
            attrs: {
              "order-detail": _vm.form,
              "button-info": _vm.buttonInfo,
              listData: _vm.handlerData.roleUserSels,
            },
            on: { result: _vm.setHandler },
            model: {
              value: _vm.selectHandlerShow,
              callback: function ($$v) {
                _vm.selectHandlerShow = $$v
              },
              expression: "selectHandlerShow",
            },
          })
        : _vm._e(),
      _vm.fieldState.reasonType.show
        ? _c("select-reason", {
            attrs: { "data-val": _vm.form, IsSmall: "" },
            on: { result: _vm.setReason },
            model: {
              value: _vm.selectReasonShow,
              callback: function ($$v) {
                _vm.selectReasonShow = $$v
              },
              expression: "selectReasonShow",
            },
          })
        : _vm._e(),
      _vm.selectSmallReasonShow
        ? _c("select-reason", {
            attrs: {
              "data-val": _vm.form,
              bigReasonId: _vm.bigCategoryId,
              IsSmall: "",
              noShowBtn: "",
            },
            on: { result: _vm.setSmallReason },
            model: {
              value: _vm.selectSmallReasonShow,
              callback: function ($$v) {
                _vm.selectSmallReasonShow = $$v
              },
              expression: "selectSmallReasonShow",
            },
          })
        : _vm._e(),
      _c("usual-select", {
        attrs: { list: _vm.closeType, title: "关闭类型" },
        on: { result: _vm.getCloseType },
        model: {
          value: _vm.closeTypeShow,
          callback: function ($$v) {
            _vm.closeTypeShow = $$v
          },
          expression: "closeTypeShow",
        },
      }),
      _c("usual-select", {
        attrs: { list: _vm.improperReason, title: "关闭原因" },
        on: { result: _vm.getCloseReason },
        model: {
          value: _vm.closeReasonShow,
          callback: function ($$v) {
            _vm.closeReasonShow = $$v
          },
          expression: "closeReasonShow",
        },
      }),
      _vm.fieldState.backReason.show
        ? _c("BackToReportSysReasonSelect", {
            attrs: { show: _vm.backReportSysReasonSelectShow },
            on: {
              "update:show": function ($event) {
                _vm.backReportSysReasonSelectShow = $event
              },
              confirm: _vm.backReasonConfirm,
            },
          })
        : _vm._e(),
      _c(
        "van-popup",
        {
          attrs: { position: "bottom" },
          model: {
            value: _vm.issueReasonShow,
            callback: function ($$v) {
              _vm.issueReasonShow = $$v
            },
            expression: "issueReasonShow",
          },
        },
        [
          _c("van-picker", {
            attrs: {
              "show-toolbar": "",
              title: "选择问题原因",
              columns: _vm.issueReasonList,
              "default-index": 0,
              "value-key": "name",
            },
            on: {
              cancel: function ($event) {
                _vm.issueReasonShow = false
              },
              confirm: _vm.issueReasonSelectConfirm,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }