var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "van-dialog",
    {
      class: `${_vm.fields && _vm.fields.length > 0 ? "" : "empty-dialog"}`,
      style: { width: "92vw" },
      attrs: {
        "show-cancel-button": "",
        "confirm-button-text": "确定",
        "close-on-popstate": false,
        title: _vm.title,
        getContainer: "#app",
        theme: _vm.fields && _vm.fields.length > 0 ? "default" : "round-button",
        "before-close": (action, done) => _vm.beforeDialogClose(action, done),
      },
      model: {
        value: _vm.circulation_show,
        callback: function ($$v) {
          _vm.circulation_show = $$v
        },
        expression: "circulation_show",
      },
    },
    [
      _vm.fields && _vm.fields.length > 0
        ? _c(
            "van-form",
            {
              ref: "ReportOrderCirculationForm",
              staticClass: "ReportOrderCirculationForm",
              attrs: { "validate-first": "", "validate-trigger": "onSubmit" },
            },
            _vm._l(_vm.fields, function (field, index) {
              return _c(
                "div",
                { key: index, staticClass: "circulation_field" },
                [
                  field.type === "input"
                    ? _c("van-field", {
                        class: { readonly: field.readonly },
                        attrs: {
                          name: field.name,
                          readonly: field.readonly,
                          label: field.label,
                          placeholder: field.placeholder
                            ? field.placeholder
                            : `请输入${field.label}`,
                        },
                        model: {
                          value: _vm.formData[field.name],
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, field.name, $$v)
                          },
                          expression: "formData[field.name]",
                        },
                      })
                    : _vm._e(),
                  field.type === "textarea" && !field.hidden
                    ? _c("van-field", {
                        attrs: {
                          label: field.label,
                          name: field.name,
                          rules: [
                            {
                              required: field.required,
                              message: `请输入${field.label}`,
                            },
                          ],
                          readonly: field.readonly,
                          required: field.required,
                          "show-word-limit": "",
                          type: "textarea",
                          rows: field.readonly ? null : 4,
                          placeholder: `请输入${field.label}`,
                          "auto-size": "",
                        },
                        model: {
                          value: _vm.formData[field.name],
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, field.name, $$v)
                          },
                          expression: "formData[field.name]",
                        },
                      })
                    : _vm._e(),
                  field.type === "datetime"
                    ? _c(
                        "div",
                        [
                          _c("van-field", {
                            attrs: {
                              readonly: "",
                              clickable: "",
                              required: field.required,
                              name: field.name,
                              value: _vm.formData[field.name],
                              label: field.label,
                              placeholder: `请选择${field.label}`,
                            },
                            on: {
                              click: function ($event) {
                                _vm.showDateTimePicker = true
                              },
                            },
                          }),
                          _c(
                            "van-popup",
                            {
                              attrs: {
                                "get-container": "#app",
                                round: "",
                                position: "bottom",
                              },
                              model: {
                                value: _vm.showDateTimePicker,
                                callback: function ($$v) {
                                  _vm.showDateTimePicker = $$v
                                },
                                expression: "showDateTimePicker",
                              },
                            },
                            [
                              field.type === "datetime"
                                ? _c("van-datetime-picker", {
                                    attrs: {
                                      type: "datetime",
                                      title: `选择${field.label}`,
                                      "min-date": _vm.timeMinDate,
                                    },
                                    on: {
                                      confirm: (time) =>
                                        _vm.chooseDelayTime(time, field),
                                      cancel: function ($event) {
                                        _vm.showDateTimePicker = false
                                      },
                                    },
                                    model: {
                                      value: _vm.dateTime,
                                      callback: function ($$v) {
                                        _vm.dateTime = $$v
                                      },
                                      expression: "dateTime",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  field.type === "stepper"
                    ? _c("van-field", {
                        attrs: {
                          required: field.required,
                          name: field.name,
                          integer: "",
                          label: field.label,
                          rules: [
                            {
                              required: field.required,
                              message: `请选择${field.label}`,
                            },
                          ],
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "input",
                              fn: function () {
                                return [
                                  _c("van-stepper", {
                                    attrs: { min: field.min },
                                    model: {
                                      value: _vm.formData[field.name],
                                      callback: function ($$v) {
                                        _vm.$set(_vm.formData, field.name, $$v)
                                      },
                                      expression: "formData[field.name]",
                                    },
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    : _vm._e(),
                  field.type === "select" && !field.hidden
                    ? _c("vanFieldPicker", {
                        attrs: {
                          "is-link": false,
                          required: field.required,
                          "right-icon": "arrow-down",
                          rules: [
                            {
                              required: field.required,
                              message: `请选择${field.label}`,
                            },
                          ],
                          count: field.count,
                          height: field.height,
                          label: field.label,
                          columns: field.columns,
                          align: "left",
                          name: field.name,
                          value: _vm.pickerValueComp(
                            field.columns,
                            _vm.formData[field.name]
                          ),
                          "real-value": _vm.formData[field.name],
                        },
                        on: {
                          "update:realValue": function ($event) {
                            return _vm.$set(_vm.formData, field.name, $event)
                          },
                          "update:real-value": function ($event) {
                            return _vm.$set(_vm.formData, field.name, $event)
                          },
                        },
                      })
                    : _vm._e(),
                  _vm.order.category_name &&
                  _vm.order.category_name.indexOf("工程") > -1 &&
                  field.name === "hasFees"
                    ? _c(
                        "div",
                        [
                          _c(
                            "vanFieldPicker",
                            {
                              attrs: {
                                disabled: field.disabled || false,
                                required: field.required,
                                label: field.label,
                                "is-link": false,
                                align: "left",
                                columns: field.columns,
                                name: field.name,
                                value: _vm.pickerValueComp(
                                  field.columns,
                                  _vm.formData[field.name]
                                ),
                                "real-value": _vm.formData[field.name],
                              },
                              on: {
                                "update:realValue": function ($event) {
                                  return _vm.$set(
                                    _vm.formData,
                                    field.name,
                                    $event
                                  )
                                },
                                "update:real-value": function ($event) {
                                  return _vm.$set(
                                    _vm.formData,
                                    field.name,
                                    $event
                                  )
                                },
                              },
                            },
                            [
                              _c("template", { slot: "label" }, [
                                _c("span", [_vm._v("是否收费")]),
                                _c(
                                  "span",
                                  {
                                    staticClass: "add-fee-button",
                                    on: { click: _vm.openAddFeeDialogShow },
                                  },
                                  [_vm._v("+费用输入")]
                                ),
                              ]),
                            ],
                            2
                          ),
                          _vm.incidentFree && _vm.incidentFree.length
                            ? _c(
                                "div",
                                { staticClass: "incidentFree" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "incidentFree-label" },
                                    [_c("span", [_vm._v("收费信息")])]
                                  ),
                                  _vm._l(
                                    _vm.incidentFree,
                                    function (item, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass: "fee-info-item",
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(item.costName)),
                                          ]),
                                          _c("span", [
                                            _vm._v("-" + _vm._s(item.stanName)),
                                          ]),
                                          _c(
                                            "span",
                                            { staticClass: "dueAmount" },
                                            [
                                              _vm._v(
                                                "¥" + _vm._s(item.dueAmount)
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "dueAmount" },
                                            [_vm._v(_vm._s(item.paysItem))]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                ],
                                2
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  field.name === "bigCorpTypeID"
                    ? _c(
                        "div",
                        [
                          _c("van-field", {
                            attrs: {
                              readonly: "",
                              clickable: "",
                              required: field.required,
                              name: field.name,
                              "right-icon": "arrow-down",
                              value: _vm.formData.bigCorpTypeName,
                              label: field.label,
                              placeholder: `请选择${field.label}`,
                            },
                            on: {
                              click: function ($event) {
                                _vm.selectCorpTypeShow = true
                              },
                            },
                          }),
                          _c("SelectReasonTree", {
                            attrs: {
                              title: "选择报事类别",
                              show: _vm.selectCorpTypeShow,
                              isBigType: true,
                              drClass: _vm.drClass,
                              incidentPlace: _vm.order.incidentPlace,
                              duty: _vm.order.duty,
                              "project-code":
                                _vm.order.project_code || _vm.order.projectCode,
                            },
                            on: {
                              "update:show": function ($event) {
                                _vm.selectCorpTypeShow = $event
                              },
                              result: _vm.selectCorpTypeConfirm,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  field.name === "smallCorpTypeID"
                    ? _c(
                        "div",
                        [
                          _c("van-field", {
                            attrs: {
                              readonly: "",
                              "right-icon": "arrow-down",
                              clickable: "",
                              required: field.required,
                              name: field.name,
                              value: _vm.formData.smallCorpTypeName,
                              label: field.label,
                              placeholder: `请选择${field.label}`,
                            },
                            on: {
                              click: function ($event) {
                                _vm.selectSmallCorpTypeShow = true
                              },
                            },
                          }),
                          _c("SelectReasonTree", {
                            attrs: {
                              title: "选择原因细类",
                              incidentPlace: _vm.order.incidentPlace,
                              show: _vm.selectSmallCorpTypeShow,
                              isBigType: false,
                              duty: _vm.order.duty,
                              drClass: _vm.drClass,
                              typeCode: _vm.formData.typeCode,
                              "project-code":
                                _vm.order.project_code || _vm.order.projectCode,
                            },
                            on: {
                              "update:show": function ($event) {
                                _vm.selectSmallCorpTypeShow = $event
                              },
                              result: _vm.selectSmallCorpTypeConfirm,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  field.name === "reporterSign"
                    ? _c(
                        "div",
                        { staticClass: "px-16px mb-20px" },
                        [
                          _c("div", { staticClass: "mb-10px" }, [
                            _vm._v("报事人签字"),
                          ]),
                          !_vm.formData.reporterSign
                            ? _c(
                                "div",
                                {
                                  staticClass: "reporterSignEmpty",
                                  on: {
                                    click: function ($event) {
                                      _vm.showReporterSignPicker = true
                                    },
                                  },
                                },
                                [_vm._v(" 报  事  人  签  字 ")]
                              )
                            : _c("van-image", {
                                staticClass: "reporterSignImage",
                                attrs: { src: _vm.formData.reporterSign },
                                on: {
                                  click: function ($event) {
                                    _vm.showReporterSignPicker = true
                                  },
                                },
                              }),
                          _c(
                            "van-popup",
                            {
                              staticStyle: {
                                width: "100%",
                                "background-color": "transparent",
                              },
                              attrs: {
                                position: "center",
                                "get-container": "#app",
                              },
                              model: {
                                value: _vm.showReporterSignPicker,
                                callback: function ($$v) {
                                  _vm.showReporterSignPicker = $$v
                                },
                                expression: "showReporterSignPicker",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "esign-popup" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "esign-title" },
                                    [
                                      _c("span"),
                                      _c("span", [_vm._v("签字")]),
                                      _c("van-icon", {
                                        attrs: { name: "cross" },
                                        on: {
                                          click: function ($event) {
                                            _vm.showReporterSignPicker = false
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("vueEsign", {
                                    ref: "esign",
                                    refInFor: true,
                                    attrs: {
                                      bgColor: "#ffffff",
                                      width: 375,
                                      height: 200,
                                    },
                                  }),
                                  _c("div", { staticClass: "button-wrapper" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "btn reset",
                                        on: { click: _vm.handleReset },
                                      },
                                      [_vm._v("重签")]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "btn confirm",
                                        on: { click: _vm.handleGenerate },
                                      },
                                      [_vm._v("确定")]
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  field.type === "upload" && !field.hidden
                    ? _c(
                        "div",
                        { staticClass: "circulation_upload" },
                        [
                          _c("common-upload", {
                            attrs: {
                              "max-count": 6,
                              text: field.label,
                              file: _vm.formData[field.name],
                              "enable-watermark": false,
                              "enable-album": true,
                              "enable-edit": true,
                              required: !!field.required,
                            },
                            on: {
                              "update:file": function ($event) {
                                return _vm.$set(
                                  _vm.formData,
                                  field.name,
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            }),
            0
          )
        : _c("div", { staticClass: "text-center text-[#12151C] mb-32px" }, [
            _vm._v(" 请确认是否" + _vm._s(_vm.title) + "？ "),
          ]),
      _c("add-fee", {
        attrs: { order: _vm.order, show: _vm.showAddFeeDialog },
        on: {
          success: _vm.addFeeSuccess,
          "update:show": function ($event) {
            _vm.showAddFeeDialog = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }