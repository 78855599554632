<template>
  <div>
  <van-field
      readonly
      @click="selectReportCustomer"
      name="houseName"
      :label="label"
      class="form-input"
      :required="required"
      right-icon="arrow"
      input-align="right"
      :placeholder="confirmType === 'member' ? '按房间或姓名查找' : '选择问题房间（可非当前客户）'"
      v-model="houseName"
    >
    </van-field>
    <ReportCustomerSelectPop ref="ReportCustomerSelectPop" :choose-house="house"  :confirm-type='confirmType' :router-list-prop="routerList" @router="routerConfirm" select-type="radio" @result="reportCustomerConfirm" :show.sync="ReportCustomerPopShow"></ReportCustomerSelectPop>
  </div>
</template>

<script>
import ReportCustomerSelectPop from './ReportCustomerSelectPop'

export default {
  name: 'MyFieldOfEquipmentSelect',
  components: {
    ReportCustomerSelectPop
  },
  props: {
    required: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: '报事客户'
    },
    confirmType: {
      type: String,
      default: 'member'
    },
    routerList: {
      type: Array,
      default: () => []
    },
    houseName: {
      type: String,
      default: ''
    },
    house: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      ReportCustomerPopShow: false
      // selectedItem: {},
      // houseCode: ''
    }
  },
  methods: {
    clearInit () {
      console.log('this.$refs.ReportCustomerSelectPop: ', this.$refs.ReportCustomerSelectPop)
      if (this.$refs.ReportCustomerSelectPop) {
        this.$refs.ReportCustomerSelectPop.clearSearch()
      }
    },
    selectReportCustomer () {
      // if (this.confirmType === 'house' && (!this.routerList || !this.routerList.length)) {
      //   return this.$toast('请选择报事客户')
      // }
      this.ReportCustomerPopShow = true
    },
    reportCustomerConfirm (params) {
      console.log('params: ', params)
      // this.selectedItem = params
      // this.houseCode = this.selectedItem.houseCode
      this.$emit('confirm', params)
    },
    routerConfirm (routerList) {
      this.$emit('confirmRouter', routerList)
    }
  }
}
</script>
<style scoped lang='less'>
.reportValue{
  &.placeHolder{
    color: #eee;
    font-size: 20px;
  }
}
</style>
