<template>
  <van-dialog
    v-model="circulation_show"
    show-cancel-button
    confirm-button-text="确定"
    :close-on-popstate="false"
    :title="title"
    :class="`${fields && fields.length > 0 ? '' : 'empty-dialog'}`"
    :style="{ width: '92vw' }"
    getContainer="#app"
    :theme="fields && fields.length > 0 ? 'default' : 'round-button'"
    :before-close="(action, done) => beforeDialogClose(action, done)"
  >
    <van-form
      ref="ReportOrderCirculationForm"
      validate-first
      class="ReportOrderCirculationForm"
      validate-trigger="onSubmit"
      v-if="fields && fields.length > 0"
    >
      <div
        v-for="(field, index) in fields"
        :key="index"
        class="circulation_field"
      >
        <!-- 文本输入框 -->
        <van-field
          :class="{ readonly: field.readonly }"
          :name="field.name"
          v-if="field.type === 'input'"
          :readonly="field.readonly"
          :label="field.label"
          :placeholder="
            field.placeholder ? field.placeholder : `请输入${field.label}`
          "
          v-model="formData[field.name]"
        >
        </van-field>
        <!-- 文本域 -->
        <van-field
          :label="field.label"
          :name="field.name"
          :rules="[
            { required: field.required, message: `请输入${field.label}` },
          ]"
          :readonly="field.readonly"
          :required="field.required"
          v-if="field.type === 'textarea' && !field.hidden"
          show-word-limit
          v-model="formData[field.name]"
          type="textarea"
          :rows="field.readonly ? null : 4"
          :placeholder="`请输入${field.label}`"
          auto-size
        ></van-field>
        <!-- 时间选择器 -->
        <div v-if="field.type === 'datetime'">
          <van-field
            readonly
            clickable
            :required="field.required"
            :name="field.name"
            :value="formData[field.name]"
            :label="field.label"
            :placeholder="`请选择${field.label}`"
            @click="showDateTimePicker = true"
          />
          <van-popup
            get-container="#app"
            v-model="showDateTimePicker"
            round
            position="bottom"
          >
            <van-datetime-picker
              v-model="dateTime"
              v-if="field.type === 'datetime'"
              type="datetime"
              :title="`选择${field.label}`"
              :min-date="timeMinDate"
              @confirm="(time) => chooseDelayTime(time, field)"
              @cancel="showDateTimePicker = false"
            />
          </van-popup>
        </div>

        <!-- 步进器 -->
        <van-field
          :required="field.required"
          v-if="field.type === 'stepper'"
          :name="field.name"
          integer
          :label="field.label"
          :rules="[
            { required: field.required, message: `请选择${field.label}` },
          ]"
        >
          <template #input>
            <van-stepper :min="field.min" v-model="formData[field.name]" />
          </template>
        </van-field>
        <!-- 选择器 -->
        <vanFieldPicker
          :is-link="false"
          :required="field.required"
          right-icon="arrow-down"
          :rules="[
            { required: field.required, message: `请选择${field.label}` },
          ]"
          :count="field.count"
          :height="field.height"
          :label="field.label"
          :columns="field.columns"
          :align="'left'"
          :name="field.name"
          v-if="field.type === 'select' && !field.hidden"
          :value="pickerValueComp(field.columns, formData[field.name])"
          :real-value.sync="formData[field.name]"
        ></vanFieldPicker>
        <!-- 收费 -->
        <div
          v-if="
            order.category_name &&
            order.category_name.indexOf('工程') > -1 &&
            field.name === 'hasFees'
          "
        >
          <vanFieldPicker
            :disabled="field.disabled || false"
            :required="field.required"
            :label="field.label"
            :is-link="false"
            :align="'left'"
            :columns="field.columns"
            :name="field.name"
            :value="pickerValueComp(field.columns, formData[field.name])"
            :real-value.sync="formData[field.name]"
          >
            <template slot="label">
              <span>是否收费</span>
              <span class="add-fee-button" @click="openAddFeeDialogShow"
                >+费用输入</span
              >
            </template>
          </vanFieldPicker>
          <div class="incidentFree" v-if="incidentFree && incidentFree.length">
            <div class="incidentFree-label">
              <span>收费信息</span>
            </div>
            <div
              class="fee-info-item"
              v-for="(item, index) in incidentFree"
              :key="index"
            >
              <span>{{ item.costName }}</span>
              <span>-{{ item.stanName }}</span>
              <span class="dueAmount">¥{{ item.dueAmount }}</span>
              <span class="dueAmount">{{ item.paysItem }}</span>
            </div>
          </div>
        </div>
        <!-- 原因类型 -->
        <div v-if="field.name === 'bigCorpTypeID'">
          <van-field
            readonly
            clickable
            :required="field.required"
            :name="field.name"
            right-icon="arrow-down"
            :value="formData.bigCorpTypeName"
            :label="field.label"
            :placeholder="`请选择${field.label}`"
            @click="selectCorpTypeShow = true"
          />
          <SelectReasonTree
            title="选择报事类别"
            :show.sync="selectCorpTypeShow"
            @result="selectCorpTypeConfirm"
            :isBigType="true"
            :drClass="drClass"
            :incidentPlace="order.incidentPlace"
            :duty="order.duty"
            :project-code="order.project_code || order.projectCode"
          ></SelectReasonTree>
        </div>
        <!-- 原因细类 -->
        <div v-if="field.name === 'smallCorpTypeID'">
          <van-field
            readonly
            right-icon="arrow-down"
            clickable
            :required="field.required"
            :name="field.name"
            :value="formData.smallCorpTypeName"
            :label="field.label"
            :placeholder="`请选择${field.label}`"
            @click="selectSmallCorpTypeShow = true"
          />
          <SelectReasonTree
            title="选择原因细类"
            :incidentPlace="order.incidentPlace"
            :show.sync="selectSmallCorpTypeShow"
            @result="selectSmallCorpTypeConfirm"
            :isBigType="false"
            :duty="order.duty"
            :drClass="drClass"
            :typeCode="formData.typeCode"
            :project-code="order.project_code || order.projectCode"
          ></SelectReasonTree>
        </div>
        <div v-if="field.name === 'reporterSign'" class="px-16px mb-20px">
          <div class="mb-10px">报事人签字</div>
          <div
            v-if="!formData.reporterSign"
            class="reporterSignEmpty"
            @click="showReporterSignPicker = true"
          >
            报&nbsp;&nbsp;事&nbsp;&nbsp;人&nbsp;&nbsp;签&nbsp;&nbsp;字
          </div>
          <van-image
            v-else
            class="reporterSignImage"
            :src="formData.reporterSign"
            @click="showReporterSignPicker = true"
          ></van-image>
          <van-popup
            position="center"
            style="width: 100%; background-color: transparent"
            get-container="#app"
            v-model="showReporterSignPicker"
          >
            <div class="esign-popup">
              <div class="esign-title">
                <span></span><span>签字</span>
                <van-icon
                  @click="showReporterSignPicker = false"
                  name="cross"
                />
              </div>
              <vueEsign
                ref="esign"
                bgColor="#ffffff"
                :width="375"
                :height="200"
              ></vueEsign>
              <div class="button-wrapper">
                <div class="btn reset" @click="handleReset">重签</div>
                <div class="btn confirm" @click="handleGenerate">确定</div>
              </div>
            </div>
          </van-popup>
        </div>

        <!-- 图片上传 -->
        <div
          class="circulation_upload"
          v-if="field.type === 'upload' && !field.hidden"
        >
          <common-upload
            :max-count="6"
            :text="field.label"
            :file.sync="formData[field.name]"
            :enable-watermark="false"
            :enable-album="true"
            :enable-edit="true"
            :required="!!field.required"
          ></common-upload>
        </div>
      </div>
    </van-form>
    <div v-else class="text-center text-[#12151C] mb-32px">
      请确认是否{{ title }}？
    </div>
    <add-fee
      @success="addFeeSuccess"
      :order="order"
      :show.sync="showAddFeeDialog"
    ></add-fee>
  </van-dialog>
</template>

<script>
import vueEsign from 'vue-esign'
import vanFieldPicker from './van-field-picker.vue'
import {
  orderFlow,
  getTWHandlersList,
  getArrivedSelects,
  isSupervise
} from '@/api/report'
import { mapGetters, mapState } from 'vuex'
import moment from 'moment'
import SelectReasonTree from '../CreateOrder/components/selectReasonTree.vue'
import addFee from './add-fee.vue'

import {
  getOssParams,
  uploadPhotoByImgKeys,
  dataURLtoBlobtoFile,
  submitPhoto
} from '@/plugins/offlineMixin/utils/offlineImgTool'
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    fieldConfig: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    order: {
      typeof: Object,
      default: () => {}
    },
    isReverse: {
      type: Boolean,
      default: false
    }
  },
  components: {
    vanFieldPicker,
    SelectReasonTree,
    vueEsign,
    addFee
  },
  watch: {
    'formData.handlerNames': {
      handler () {
        this.formData.handlerNames = this.formData.handlerNames.replace(
          /,/g,
          '，'
        )
      }
    },
    'formData.followType': {
      handler (value) {
        const isAbnormal = this.fields.find((i) => i.name === 'isAbnormal')
        if (isAbnormal) {
          isAbnormal.hidden = value === '责任人跟进'
        }
      }
    },
    'formData.closeType': {
      handler (value) {
        const abnormalShutdownImage = this.fields.find(
          (i) => i.name === 'abnormalShutdownImage'
        )
        if (abnormalShutdownImage) {
          abnormalShutdownImage.hidden = value === 0
        }
        const closeReason = this.fields.find((i) => i.name === 'closeReason')
        if (closeReason) {
          closeReason.hidden = value === 0
        }
      }
    },
    'formData.delayDay': {
      handler () {
        this.formData.delayTime = moment(this.order.delayLimitTime)
          .add(this.formData.delayDay, 'days')
          .format('yyyy-MM-DD HH:mm:ss')
      }
    },
    show (newValue, oldValue) {
      console.log('show newValue: ', newValue)
    }
  },
  data () {
    return {
      drClass: undefined,
      showReporterSignPicker: false,
      selectCorpTypeShow: false,
      selectSmallCorpTypeShow: false,
      showDateTimePicker: false,
      followTypeColumns: [
        { text: '责任人跟进', value: '责任人跟进' },
        { text: '监管跟进', value: '监管跟进' }
      ],
      isAbnormalColumns: [
        { text: '正常跟进', value: 0 },
        { text: '异常跟进', value: 1 }
      ],
      dateTime: null,
      FIELD_CONFIG: {
        followType: {
          type: 'input',
          columns: [
            { text: '责任人跟进', value: '责任人跟进' },
            { text: '监管跟进', value: '监管跟进' }
          ]
        },
        isAbnormal: {
          type: 'select',
          columns: [
            { text: '正常跟进', value: 0 },
            { text: '异常跟进', value: 1 }
          ],
          hidden: true
        },
        description: {
          type: 'textarea'
        },
        url: {
          type: 'upload'
        },
        project_name: {
          type: 'input',
          readonly: true
        },
        order_no: {
          type: 'input',
          readonly: true
        },
        admiMan: {
          type: 'input',
          readonly: true
        },
        create_time: {
          type: 'input',
          readonly: true
        },
        complaintType: {
          type: 'input',
          readonly: true
        },
        touSuDegree: {
          type: 'input',
          readonly: true
        },
        title: {
          type: 'textarea',
          readonly: true
        },
        assistDescription: {
          type: 'textarea'
        },
        assistNums: {
          type: 'stepper'
        },
        handlerNames: {
          type: 'input',
          placeholder: '请输入，以逗号（，）分隔'
        },
        delayLimitTime: {
          type: 'input',
          readonly: true
        },
        delayDescription: {
          type: 'textarea'
        },
        delayDay: {
          type: 'stepper',
          min: 30
        },
        delayTime: {
          type: 'input',
          placeholder: '自动计算，请选择延期天数'
        },
        transferDescription: {
          type: 'textarea'
        },
        bigCorpTypeID: {},
        handlerId: {
          type: 'select',
          columns: [],
          height: '50vh',
          count: 10
        },
        currentUser: {
          type: 'input',
          readonly: true
        },
        currentTime: {
          type: 'datetime'
        },
        smallCorpTypeID: {},
        discardDescription: {
          type: 'textarea'
        },
        closeType: {
          type: 'select',
          columns: [
            { text: '是', value: 1 },
            { text: '否', value: 0 }
          ]
        },
        hasFees: {
          disabled: true,
          columns: [
            { text: '是', value: 1 },
            { text: '否', value: 0 }
          ]
        },
        overdueReason: {
          type: 'textarea'
        },
        reporterSign: {},
        closeReason: {
          type: 'textarea',
          hidden: true
        },
        abnormalShutdownImage: {
          label: '非正常关闭图片',
          type: 'upload',
          hidden: true
        }
      },
      formData: {
        followType: '', // 跟进类型
        isAbnormal: 0, // 跟进状态
        description: '', // 跟进内容
        url: '', // 照片
        project_name: '', // 项目名称
        order_no: '', // 报事编号
        admiMan: '', // 发起人
        create_time: '', // 发起时间
        title: '', // 标题
        assistDescription: '', // 协助原因
        assistNums: 0, // 协助人数
        handlerNames: '', // 协助人
        delayLimitTime: '', // 延期前限时间
        delayDescription: '', // 延期原因
        delayDay: 30, // 延期天数
        delayTime: '', // 延期时间
        transferDescription: '', // 改派原因
        bigCorpTypeID: '', // 报事类别 id
        bigCorpTypeName: '', // 报事类型 name
        smallCorpTypeID: '', // 原因细类 id
        smallCorpTypeName: '', // 原因细类 name
        handlerId: '', // 责任人
        currentUser: '', // 改派人
        transferTime: '', // 转发时间
        closeType: 0, // 是否非关
        closeTypeName: undefined,
        hasFees: 0, // 是否收费
        overdueReason: '', // 逾期原因
        reporterSign: undefined, // 客户签字
        closeReason: undefined, // 非正常关闭原因
        abnormalShutdownImage: undefined // 非正常关闭图片
      },
      timeMinDate: null,
      fields: [],
      showAddFeeDialog: false
    }
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo
    }),
    ...mapGetters(['getTwUserCode']),
    pickerValueComp () {
      return function (columns, value) {
        console.log('value: ', value)
        return value || value === 0
          ? columns.find((item) => item.value === value).text
          : ''
      }
    },
    circulation_show: {
      get () {
        return this.show
      },
      set (val) {
        this.$emit('update:show', val)
      }
    },

    isAbnormalhideCondition () {
      return this.formData.followType !== '监管跟进'
    }
  },
  mounted () {
    this.dialogOpen()
  },
  methods: {
    addFeeSuccess (result) {
      this.incidentFree = result
      this.formData.hasFees = 1
      this.$emit('refresh')
    },
    openAddFeeDialogShow () {
      this.showAddFeeDialog = true
    },
    handleReset () {
      this.$refs.esign[0].reset()
    },
    handleGenerate () {
      this.$refs.esign[0]
        .generate({ format: 'png', quality: 0.8 })
        .then((res) => {
          this.formData.reporterSign = res
          this.showReporterSignPicker = false
        })
        .catch((err) => {
          console.error(err)
        })
    },
    selectCorpTypeConfirm (data, name) {
      // 原因大类 原因细类选择 result
      this.formData.bigCorpTypeID = data.corpTypeID
      this.formData.typeCode = data.typeCode
      this.formData.bigCorpTypeName = name
      this.formData.smallCorpTypeID = ''
      this.formData.smallCorpTypeName = ''
      this.getHandlersList(data.corpTypeID)
    },
    selectSmallCorpTypeConfirm (data, name) {
      console.log('data, name: ', data, name)
      // 原因大类 原因细类选择 result
      this.formData.smallCorpTypeID = data.corpTypeID
      this.formData.smallCorpTypeName = name
    },
    async getHandlersList (corpTypeID) {
      // 获取责任人下拉
      const obj = {
        corpTypeId: corpTypeID, // 原因大类CorpTypeID
        projectCode: this.order.project_code || this.order.projectCode
      }
      const res = await getTWHandlersList(obj)
      const handlerId = this.fields.find((field) => field.name === 'handlerId')
      if (handlerId) {
        handlerId.columns = (res || []).map((item) => {
          return {
            text: item.userName,
            value: item.userCode
          }
        })
      }
    },
    chooseDelayTime (date, field) {
      this.formData[field.name] = moment(date).format('yyyy-MM-DD HH:mm')
      this.showDateTimePicker = false
    },
    async beforeDialogClose (action, done) {
      console.log(this.formData, 'this.formData')
      if (action === 'confirm') {
        try {
          const formData = {}
          formData.project_code =
            this.order.project_code || this.order.projectCode
          formData.project_name =
            this.order.project_name || this.order.projectName
          formData.regionalId = this.order.regionalId
          formData.incidentContent = this.order.remark
          formData.addressName = this.order.addressName
          formData.addressName = this.order.addressName
          formData.bigCorpTypeID = this.order.big_category_id
          formData.smallCorpTypeID = this.order.small_category_id
          formData.duty = this.order.duty
          let _able = true
          if (this.fields && this.fields.length > 0) {
            this.$refs.ReportOrderCirculationForm.submit()
            await this.$refs.ReportOrderCirculationForm.validate()
            for await (let field of this.fields) {
              formData[field.name] = this.formData[field.name]
              if (
                field.type === 'upload' &&
                this.formData[field.name] &&
                this.formData[field.name].length > 0
              ) {
                const images = await uploadPhotoByImgKeys(
                  this.formData[field.name]
                )
                formData[field.name] = images.map((i) => i.url).join(',')
              }
              if (field.name === 'reporterSign' && this.formData.reporterSign) {
                const ossParams = await getOssParams()
                const reporterSign = await submitPhoto(
                  dataURLtoBlobtoFile(this.formData.reporterSign),
                  ossParams
                )
                formData[field.name] = reporterSign.url
              }
              if (field.name === 'handlerId' && this.formData.handlerId) {
                const handlerId = this.fields.find((field) => field.name === 'handlerId')
                const handler = handlerId.columns.find((item) => item.value === this.formData.handlerId)
                if (handler) formData.handlerName = handler.text
              }
              if (field.name === 'abnormalShutdownImage' && this.formData.closeType === 1 && !this.formData.abnormalShutdownImage) {
                this.$toast('请上传非正常关闭图片')
                _able = false
                break
              }
              if (field.name === 'smallCorpTypeID' && field.required && !this.formData.smallCorpTypeID) {
                this.$toast('请选择原因细类')
                _able = false
                break
              }
            }
          }
          console.log('_able: ', _able)
          if (!_able) {
            done(false)
            return
          }
          console.log('formData: ', formData)
          const form = {
            flowRouteTW: formData,
            orderNo: this.order.order_no || this.order.orderNo,
            buttonName: this.title,
            twOrderId: this.order.id
          }
          console.log('form: ', form)
          const result = await orderFlow(form)
          let toast = ''
          if (this.title === '跟进' || this.title === '完成') {
            toast = `${this.title}成功`
            if (this.formData.closeType === 1) {
              toast = '提交成功'
            }
          } else {
            toast = '提交成功'
          }
          console.log('result: ', result)
          this.$emit('ok')
          done()
          this.$toast({
            message: toast,
            className: 'success-alert',
            icon: require('@/asset/success-notification.png')
          })
        } catch (error) {
          if (error && error.length) {
            this.$toast(error[0].message)
          }
          console.log('error: ', error)
          done(false)
        }
      } else {
        done()
      }
    },
    async getArrivedSelectsReq () {
      const result = await getArrivedSelects({
        projectCode: this.order.project_code || this.order.projectCode
      })
      console.log('result: ', result)
      const description = this.fields.find(
        (field) => field.name === 'description'
      )
      if (description) {
        description.columns = (result || []).map((item) => {
          return {
            text: item.infocontent,
            value: item.infocontent
          }
        })
      }
    },
    async getIsSupervise () {
      const result = await isSupervise({
        pageIndex: 1,
        pageSize: 99999,
        twOrderId: this.order.twOrderId
      })
      return result
    },
    dialogOpen () {
      console.log('this.order.: ', this.order)
      this.incidentFree = this.order.incidentFree
      this.formData.hasFees = this.incidentFree && this.incidentFree.length ? 1 : 0
      this.drClass = this.isReverse
        ? this.order.drClass === '1'
          ? '2'
          : '1'
        : this.order.drClass
      this.formData.bigCorpTypeName =
        this.order.big_category_name || this.order.bigCategoryName
      this.formData.bigCorpTypeID =
        this.order.big_category_id || this.order.bigCategoryId
      this.formData.typeCode =
        this.order.big_category_code || this.order.bigCategoryCode
      this.formData.smallCorpTypeName =
        this.order.small_category_name || this.order.smallCategoryName
      this.formData.smallCorpTypeID =
        this.order.small_category_id || this.order.smallCategoryId
      if (!this.fieldConfig) return
      const fieldConfig = JSON.parse(this.fieldConfig) || []
      if (this.title === '完成' && this.order.isTouSu === 1 && this.order.incidentTouSu) {
        fieldConfig.splice(5, 0, {
          label: '投诉性质',
          name: 'complaintType',
          auto: true
        })
        fieldConfig.splice(5, 0, {
          label: '投诉等级',
          name: 'touSuDegree',
          auto: true
        })
      }
      this.fields = fieldConfig.filter((field) => {
        let normal = true
        if (this.FIELD_CONFIG[field.name]) {
          Object.assign(field, this.FIELD_CONFIG[field.name])
        }
        if (field.auto) {
          this.formData[field.name] = this.order[field.name]
          if (field.name === 'create_time') {
            this.formData[field.name] = moment().format('yyyy-MM-DD HH:mm:ss')
          }
        }
        if (field.label === '响应说明' && field.name === 'description') {
          field.type = 'select'
          field.columns = []
          this.getArrivedSelectsReq()
        }

        if (field.name === 'handlerId' && this.formData.bigCorpTypeID) {
          this.getHandlersList(this.formData.bigCorpTypeID)
        }
        if (field.name === 'overdueReason' && this.order.isBeoverdue !== 1) {
          normal = false
        }
        if (field.name === 'followType') {
          field.readonly = true
          if (this.order.handler_id * 1 === this.getTwUserCode * 1) {
            this.formData.followType = '责任人跟进'
          } else {
            this.getIsSupervise().then((result) => {
              if (result) {
                this.formData.followType = '监管跟进'
              }
            })
          }
        }
        if (field.name === 'delayTime') {
          this.formData.delayTime = moment(this.order.delayLimitTime)
            .add(this.formData.delayDay, 'days')
            .format('yyyy-MM-DD HH:mm:ss')
        }
        return normal
      })

      this.formData.title = `${this.order.admiMan}提交的报事${this.title}（${this.order.order_no}）申请`
      this.formData.currentUser = `${this.userInfo.name}（${this.userInfo.username}）`
      this.timeMinDate = new Date()
      console.log('this.fields: ', this.fields)
    }
  }
}
</script>
<style lang="less">
.van-dialog{
  border-radius: 16px;
  &.empty-dialog{
    .van-dialog__header{
      border-bottom: none;
    }
    .van-dialog__footer{
      .van-button{
        border-radius: 4px;
        height: 45px;
      }
      .van-dialog__cancel{
        background: #ffffff;
        color: #12151C;
        border:1px solid #C5C5C5;
        margin-right: 8px;
      }
      .van-dialog__confirm{
        margin-left: 8px;
        background: #3366fe !important;
      }
    }

  }
  .van-dialog__header{
    height: 64px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 4px;
  }
  .van-dialog__footer .van-button{
    line-height: 48px !important;
    &:after{
      border-color: #EBEDF0 !important;
    }
    &::before{
      border-color: #EBEDF0 !important;
    }
  }
  .van-hairline--top:after{
    border-color: #EBEDF0 !important;
  }
}
</style>

<style lang="less" scoped>
.ReportOrderCirculationForm {
  max-height: 74vh;
  overflow: auto;
  ::v-deep .van-field__control {
    color: #222222;
  }
  ::v-deep .van-cell--required .van-cell__title.van-field__label span:after{
    display: none;
  }
  ::v-deep .van-cell--required .van-cell__title.van-field__label span::before {
    background: url('~@/asset/icon_star_red@2x.png') no-repeat;
    background-size: contain;
    width: 8px;
    height: 8px;
    display: inline-block;
    content: "";
    top: -2px;
    position: relative;
    margin-right: 4px;
  }
  .circulation_field {
    ::v-deep & .van-field {
      padding: 4px 16px;
      display: block;
      &.readonly {
        .van-field__control {
          color: #989898;
        }
      }
      .van-field__label {
        width: 100%;
        color: #4e5969;
      }
      & > .van-field__value {
        margin-top: 4px;
        padding: 4px 8px;
        background-color: #f7f8fa;
        border-radius: 4px;
      }
    }
    ::v-deep & > .circulation_upload {
      .van-field__value {
        background-color: #ffffff;
        padding: 0px;
      }
    }
  }
  .reporterSignEmpty {
    height: 72px;
    border-radius: 6px;
    background-color: #f5f5f5;
    border: 0.5px dashed #d8d8d8;
    color: #999999;
    font-size: 20px;
    line-height: 72px;
    text-align: center;
    margin-bottom: 20px;
  }
  .reporterSignImage {
    width: 100%;
    height: 72px;
    object-fit: contain;
  }
}
.add-fee-button {
  color: #3366fe;
  margin-left: 12px;
  font-size: 12px;
  padding: 3px 6px;
}
.incidentFree {
  padding-left: 16px;
  color: #222222;
  &-label {
    color: #4e5969;
    margin-bottom: 10px;
  }
  .fee-info-item {
    font-size: 12px;
    .dueAmount {
      color: #3366fe;
      font-size: 12px;
      margin-left: 6px;
    }
  }
}
</style>
<style lang="less">
.success-alert{
  // background-color: #ffffff;
  // width: 250px;
  // height: 88px;
  // color: #333333;
  // font-size: 20px;
  // border-radius: 12px;
  // display: flex !important;
  // flex-direction: row;
  // align-items: center;
  & .van-icon__image{
    width: 36px;
    height: 36px;
    // margin-right: 8px;
  }
  // &>.van-toast__text{
  //   margin-top: 0px;
  // }
}
.esign-popup {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #ffffff;
  & > .esign-title {
    display: flex;
    justify-content: space-between;
    height: 48px;
    font-size: 24px;
    align-items: center;
    border-bottom: 1px solid #eeeeee;
  }
  & > canvas {
    margin: 0 auto;
  }
  & > .button-wrapper {
    display: flex;
    justify-content: center;
    padding: 10px 0px;
    border-top: 1px solid #eeeeee;

    .btn {
      width: 100px;
      height: 40px;
      border-radius: 6px;
      text-align: center;
      line-height: 40px;
      &.reset {
        color: #3366fe;
        border: 1px solid #3366fe;
        margin-right: 20px;
      }
      &.confirm {
        color: #ffffff;
        background-color: #3366fe;
      }
    }
  }
}
</style>
