var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "van-popup",
    {
      style: { width: "100%", height: "85%" },
      attrs: { position: "bottom" },
      model: {
        value: _vm._show,
        callback: function ($$v) {
          _vm._show = $$v
        },
        expression: "_show",
      },
    },
    [
      _c("div", { staticClass: "select-position-header clear" }, [
        _vm._v(" 所在位置 "),
        _c(
          "div",
          {
            staticClass: "btn-close pull-right",
            on: {
              click: function ($event) {
                _vm._show = false
              },
            },
          },
          [
            _c("van-icon", {
              staticStyle: { "vertical-align": "top" },
              attrs: { name: "cross", color: "#000", size: "16px" },
            }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "select-positon-content" },
        [
          _c(
            "vuescroll",
            { ref: "vsposition", attrs: { ops: _vm.ops } },
            [
              _c(
                "van-collapse",
                {
                  model: {
                    value: _vm.activeNames,
                    callback: function ($$v) {
                      _vm.activeNames = $$v
                    },
                    expression: "activeNames",
                  },
                },
                _vm._l(_vm.list, function (item) {
                  return _c(
                    "van-collapse-item",
                    {
                      key: item.code,
                      attrs: {
                        title: `${item.name}(${item.pointTreePointResps.length})`,
                      },
                    },
                    _vm._l(item.pointTreePointResps, function (position) {
                      return _c(
                        "div",
                        {
                          key: position.code,
                          staticClass: "select-item",
                          on: {
                            click: function ($event) {
                              return _vm.selectPosition(position, item.name)
                            },
                          },
                        },
                        [
                          _vm._v(" " + _vm._s(position.name) + " "),
                          _vm.selectPostion === position.name
                            ? _c("van-icon", {
                                staticClass: "success-icon pull-right",
                                attrs: { name: "success", size: "24px" },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    }),
                    0
                  )
                }),
                1
              ),
              _vm.list.length === 0
                ? _c("div", { staticClass: "no-data" }, [_vm._v("暂无数据")])
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }