var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "create-work" },
    [
      _c(
        "my-nav",
        {
          attrs: { title: "问题记录", "self-back": true },
          on: { back: _vm.goBack },
        },
        [
          _c("ScanIcon", {
            attrs: {
              slot: "right",
              "code-type": _vm.type === 1 ? "DW_code" : "SB_code",
            },
            on: { scanSuccess: _vm.scanSuccess },
            slot: "right",
          }),
        ],
        1
      ),
      _c("div", { staticClass: "problem-scroll-wrapper" }, [
        _c(
          "div",
          { staticClass: "scroll-box" },
          [
            _c("div", { staticClass: "header" }, [
              _c("div", { staticClass: "header-title" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.type === 1 ? "自查公区" : "自查设施设备") +
                    " "
                ),
              ]),
              _c("div", { staticClass: "header-text" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.type === 1
                        ? "用于公司内部员工自查时记录公共区域的问题，并进行相关流转处理及跟踪。"
                        : "用于公司内部员工自查时记录设施设备的问题，并进行相关流转处理及跟踪"
                    ) +
                    " "
                ),
              ]),
            ]),
            _c(
              "van-form",
              { staticClass: "form-container" },
              [
                _c("div", { staticClass: "subtitle" }, [_vm._v("工单位置")]),
                _c("van-field", {
                  staticClass: "form-input",
                  attrs: {
                    readonly: "",
                    clickable: "",
                    name: "projectName",
                    value: _vm.form.projectName,
                    label: "所在项目",
                    required: "",
                    "right-icon": "arrow",
                    "input-align": "right",
                    placeholder: "请选择",
                  },
                }),
                _vm.type === 3
                  ? _c("MyFieldOfEquipmentSelect", {
                      attrs: {
                        address: _vm.form.address,
                        addressId: _vm.form.addressId,
                        reallyType: _vm.form.reallyType,
                        projectCode: _vm.form.projectCode,
                        categoryCode: _vm.form.categoryCode,
                        editable: _vm.addressEditable,
                      },
                      on: {
                        confirm: _vm.myFieldOfEquipmentSelectConfirm,
                        "update:address": function ($event) {
                          return _vm.$set(_vm.form, "address", $event)
                        },
                        "update:addressId": function ($event) {
                          return _vm.$set(_vm.form, "addressId", $event)
                        },
                        "update:address-id": function ($event) {
                          return _vm.$set(_vm.form, "addressId", $event)
                        },
                        "update:reallyType": function ($event) {
                          return _vm.$set(_vm.form, "reallyType", $event)
                        },
                        "update:really-type": function ($event) {
                          return _vm.$set(_vm.form, "reallyType", $event)
                        },
                        "update:categoryCode": function ($event) {
                          return _vm.$set(_vm.form, "categoryCode", $event)
                        },
                        "update:category-code": function ($event) {
                          return _vm.$set(_vm.form, "categoryCode", $event)
                        },
                      },
                    })
                  : _c("van-field", {
                      staticClass: "form-input",
                      attrs: {
                        readonly: "",
                        clickable: "",
                        name: "address",
                        value: _vm.form.address,
                        label: "所在位置",
                        required: "",
                        "right-icon": "arrow",
                        "input-align": "right",
                        placeholder: "请选择",
                      },
                      on: { click: _vm.selectPosition },
                    }),
                _c("div", { staticClass: "subtitle" }, [_vm._v("工单内容")]),
                _vm.type === 3
                  ? _c("MyFieldOfMalfunctionReasonSelect", {
                      attrs: {
                        required: "",
                        "point-code": _vm.form.addressId,
                        "problem-list": _vm.form.problemList,
                        categoryCode: _vm.form.categoryCode,
                        reallyType: _vm.form.reallyType,
                        guide: true,
                        patrolItemName: _vm.patrolItemName,
                      },
                      on: {
                        confirm: _vm.malfunctionReasonSelectConfirm,
                        goToMalfunctionGuide: _vm.goToMalfunctionGuide,
                      },
                    })
                  : _vm._e(),
                _vm.type === 1
                  ? [
                      _c("van-field", {
                        staticClass: "form-input",
                        attrs: {
                          readonly: "",
                          clickable: "",
                          label: "问题项",
                          "label-width": "150px",
                          required: "",
                          "right-icon": "arrow",
                          "input-align": "right",
                          placeholder: "请选择",
                        },
                        on: {
                          click: function ($event) {
                            _vm.problemItemsShow = true
                          },
                        },
                      }),
                      _vm.form.problemList.length > 0
                        ? _c(
                            "div",
                            { staticClass: "problem-list" },
                            _vm._l(
                              this.form.problemList,
                              function (item, index) {
                                return _c(
                                  "div",
                                  { key: index, staticClass: "item" },
                                  [
                                    _c("p", { staticClass: "problem-title" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            `${
                                              item.parentName
                                                ? item.parentName + "/"
                                                : ""
                                            }${
                                              item.problemName ||
                                              item.mainPartName
                                            }`
                                          ) +
                                          " "
                                      ),
                                    ]),
                                    item.questLabel.length > 0
                                      ? _c(
                                          "p",
                                          { staticClass: "problem-info" },
                                          _vm._l(
                                            item.questLabel,
                                            function (item2, index2) {
                                              return _c(
                                                "span",
                                                { key: index2 },
                                                [
                                                  index2 !== 0
                                                    ? _c("i", [_vm._v("|")])
                                                    : _vm._e(),
                                                  _vm._v(
                                                    _vm._s(
                                                      item2.questLabelName ||
                                                        item2
                                                    )
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        )
                                      : _vm._e(),
                                    item.rectifyPeopls &&
                                    item.rectifyPeopls.name
                                      ? _c(
                                          "p",
                                          { staticClass: "problem-person" },
                                          [
                                            _vm._v(
                                              " 整改人：" +
                                                _vm._s(
                                                  item.rectifyPeopls.name
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                )
                              }
                            ),
                            0
                          )
                        : _vm._e(),
                    ]
                  : _vm._e(),
                _c("van-field", {
                  staticClass: "form-input",
                  attrs: {
                    readonly: "",
                    clickable: _vm.isQualityTaskOrder,
                    name: "grade",
                    value: _vm.gradeValue,
                    label: "问题分级",
                    required: "",
                    "right-icon": _vm.isQualityTaskOrder ? "arrow" : "",
                    "input-align": "right",
                    placeholder: `请选择${
                      _vm.type === 1 ? "问题项" : "主要部件故障原因"
                    }`,
                  },
                  on: { click: _vm.selectGrade },
                }),
                _c("van-field", {
                  staticClass: "form-textarea",
                  attrs: {
                    name: "remark",
                    label: "问题描述",
                    rows: "3",
                    type: "textarea",
                    placeholder: "请输入问题描述",
                  },
                  scopedSlots: _vm._u(
                    [
                      _vm.isOffline
                        ? {
                            key: "right-icon",
                            fn: function () {
                              return [
                                _c("micro-text", {
                                  on: { speakRes: _vm.speakRes },
                                }),
                              ]
                            },
                            proxy: true,
                          }
                        : null,
                    ],
                    null,
                    true
                  ),
                  model: {
                    value: _vm.form.remark,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "remark", $$v)
                    },
                    expression: "form.remark",
                  },
                }),
                _vm.patrolItem.reallyType !== "4"
                  ? _c(
                      "div",
                      {
                        staticStyle: {
                          padding: "0 16px 16px",
                          background: "#fff",
                        },
                      },
                      [
                        _c("common-upload", {
                          ref: "offlineUpload",
                          attrs: {
                            file: _vm.form.attachmentList,
                            "project-name": _vm.form.projectName,
                            required: "",
                            "enable-album": true,
                            "enable-edit": true,
                            "order-id": _vm.orderDetail.model.id,
                          },
                          on: {
                            "update:file": function ($event) {
                              return _vm.$set(
                                _vm.form,
                                "attachmentList",
                                $event
                              )
                            },
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "btn-container" },
        [
          _c(
            "van-button",
            {
              staticClass: "btn-submit",
              attrs: { type: "info" },
              on: { click: _vm.onSubmit },
            },
            [_vm._v("提交")]
          ),
        ],
        1
      ),
      _c("select-position", {
        attrs: {
          "project-code": _vm.form.projectCode,
          show: _vm.selectPositionShow,
          "data-list": this.orderDetail ? this.orderDetail.pointTreeResps : [],
        },
        on: {
          "update:show": function ($event) {
            _vm.selectPositionShow = $event
          },
          result: _vm.setPosition,
        },
      }),
      _c("problem-items", {
        ref: "problemItems",
        attrs: {
          show: _vm.problemItemsShow,
          "really-type": _vm.patrolItem.reallyType,
          "project-code-prop": _vm.form.projectCode,
          "order-detail": _vm.orderDetail,
        },
        on: {
          "update:show": function ($event) {
            _vm.problemItemsShow = $event
          },
          result: _vm.setProblemList,
        },
      }),
      _c(
        "van-popup",
        {
          attrs: { position: "bottom" },
          model: {
            value: _vm.gradeCodeShow,
            callback: function ($$v) {
              _vm.gradeCodeShow = $$v
            },
            expression: "gradeCodeShow",
          },
        },
        [
          _c("van-picker", {
            attrs: {
              "show-toolbar": "",
              title: "选择问题分级",
              columns: _vm.gradeCodeColumns,
              "default-index": 0,
              "value-key": "name",
            },
            on: {
              cancel: function ($event) {
                _vm.gradeCodeShow = false
              },
              confirm: _vm.gradeCodeSelectConfirm,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }