var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "OrderChargeDetail" },
    [
      _c("my-nav", { attrs: { title: "收费详情" } }),
      _c("div", { staticClass: "detail-wrapper" }, [
        _c("div", { staticClass: "charge-fee" }, [_vm._v("应收合计：300元")]),
        _c(
          "div",
          { staticClass: "charge-list" },
          _vm._l(_vm.chargeList, function (item, index) {
            return _c(
              "div",
              { key: index, staticClass: "charge-item" },
              [
                _c("div", { staticClass: "charge-head" }, [
                  _c("span", [_vm._v("费用项目：" + _vm._s(item.project))]),
                  _vm._m(0, true),
                ]),
                _c(
                  "van-row",
                  [
                    _c("van-col", { attrs: { span: 6 } }, [
                      _vm._v("费用标准："),
                    ]),
                    _c("van-col", { attrs: { span: 18 } }, [
                      _vm._v(_vm._s(item.standrad)),
                    ]),
                  ],
                  1
                ),
                _c(
                  "van-row",
                  [
                    _c("van-col", { attrs: { span: 6 } }, [_vm._v("费用：")]),
                    _c("van-col", { attrs: { span: 18 } }, [
                      _vm._v(_vm._s(item.fee + "元")),
                    ]),
                  ],
                  1
                ),
                _c(
                  "van-row",
                  [
                    _c("van-col", { attrs: { span: 6 } }, [
                      _vm._v("费用备注："),
                    ]),
                    _c("van-col", { attrs: { span: 18 } }, [
                      _vm._v(_vm._s(item.remark)),
                    ]),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          0
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("span", [_vm._v("编辑")]),
      _c("span", [_vm._v("删除")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }