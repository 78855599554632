<template>
    <van-popup v-model="_show" position="right" :overlay="false"
      :style="{ width: '100%', height: '100%' }" :duration="animateDuration" @open="selectProjectOpenHandler" @close="selectProjectCloseHandler">
      <div class="select-project-wrapper">
        <my-nav title="报事位置" :self-back="true" @back="goBack">
        </my-nav>
        <div v-if="!isProject">
          <van-tabs animated color="#3366FE" sticky offset-top="44" title-active-color="#3366FE" title-inactive-color="#7C7F8A" line-height="2px" line-width="70px" v-model="tabActive" @click="clickTabs">
            <van-tab title="公区" :disabled="incidentSource === '客户报事'"></van-tab>
            <van-tab title="业主房屋"></van-tab>
          </van-tabs>
        </div>
        <van-search v-model="searchValue" placeholder="请输入关键字" @search="searchProject"
          @clear="clearSearch" class="search-wrapper" />
        <div v-if="isProject">
          <!-- <div class="router-wrapper">
            <div class="list-wrapper">
              <div class="router-list" v-for="(item, index) in routerList" :key="item.organCode">
                <p class="name" :class="{ last: index === routerList.length - 1 }"
                  @click="checkRouter(index < routerList.length - 1, item)">
                  {{ item.organName }}
                </p>
                <i class="router-icon" v-if="index < routerList.length - 1"></i>
              </div>
            </div>
          </div> -->
          <div class="project-list-wrapper" v-if="!searchList.length">
            <div class="project-list" v-for="item in projectList" :key="item.organCode"
              @click="changeProjectCheck(item)">
              <p class="name">{{ item.organName }}</p>
              <i class="right-icon" v-if="item.children.length !== 0"></i>
              <i class="check-icon" v-if="showCheck(item)"></i>
            </div>
          </div>
          <div class="project-list-wrapper" v-else>
            <div class="project-list" v-for="(item, index) in searchList" :key="index"
              @click="changeProjectCheck(item)">
              <p class="name">{{ item.organName }}</p>
              <i class="check-icon" v-if="showCheck(item)"></i>
            </div>
          </div>
        </div>
        <div v-if="!isProject" class="list_content">
          <div class="router-wrapper">
            <div class="list-wrapper">
              <div class="router-list" v-for="(item, index) in reportRouterList" :key="item.organCode">
                <i class="first-icon" v-if="index === 0" @click="checkReportRouter(item,index,'firstIcon')"></i>
                <p class="name" :class="{ last: index === reportRouterList.length - 1 }"
                  @click="checkReportRouter(item,index)">
                  <span>{{item.organName}}</span>
                  <span v-if="item.level === 1">{{ item.buildName }}</span>
                  <span v-if="item.level === 2">{{ item.unitSNum }}</span>
                  <span v-if="item.level === 3">{{ item.floorName }}</span>
                </p>
                <i class="router-icon" v-if="index < reportRouterList.length - 1"></i>
              </div>
            </div>
          </div>
          <div class="Position-list-wrapper">
            <div class="project-list" v-for="(item,index) in positionList" :key="index"
              @click="changePositionCheck(item)">
              <p class="name" v-if="item.buildName">{{ item.buildName }}</p>
              <p class="name" v-if="item.unitSNum">{{ item.unitSNum }}</p>
              <p class="name" v-if="item.floorName">{{ item.floorName }}</p>
              <p class="name" v-if="item.level === 4">
                <span v-if="tabActive === 1">{{ item.roomName + "(" + item.memberName + ")" }}</span>
                <span v-if="tabActive === 0">{{ item.regionalPlace }}</span>
              </p>
              <i class="right-icon" v-if="item.level !== 4"></i>
              <i class="check-icon" v-if="showPositionCheck(item)"></i>
            </div>
          </div>
        </div>
        <div class="submit-wrapper" @click="submit">
          <p class="text">确定</p>
        </div>
      </div>
    </van-popup>
</template>

<script type='text/ecmascript-6'>
import { getTWProjectTree, getTWBuildings, getTWUnits, getTWFloors, getTWRooms, getTWRegions } from '@/api/report'
export default {
  name: '',
  components: {},
  props: {
    show: {
      type: Boolean,
      default: false
    },
    projectCode: {
      type: String,
      default: ''
    },
    incidentSource: {
      type: String,
      default: ''
    },
    mode: {
      type: String,
      default: 'position' // 'position'能选下面的位置 'project' 只到项目一层
    }
  },
  data () {
    return {
      tabActive: 0,
      isProject: true,
      searchValue: '',
      searchList: [], // 搜索后过滤的list
      checkList: [],
      routerList: [], // 项目选择路由
      projectList: [], // 项目列表
      ctprojectList: [], // copy一份项目列表
      reportRouterList: [], // 报事位置路由list
      positionList: [], // 报事位置列表
      ctPositionList: [], // copy一份公区list // 用以搜索还原
      checkPositionList: [], // 选择的位置
      checkReportCode: '', // 选择的code
      buildSNum: undefined, // 选择的楼宇
      buildName: undefined,
      unitSNum: undefined, // 选择的单元
      floorSNum: undefined, // 选择的楼层
      floorName: undefined,
      animateDuration: 0.3 // 默认弹窗动画时间0.3s
    }
  },
  computed: {
    isOffline () {
      return this.$store.getters.getIsOffline
    },
    _show: {
      get () {
        return this.show
      },
      set (val) {
        this.$emit('update:show', val)
      }
    }
  },
  watch: {
    _show (val) {
      this.tabActive = this.incidentSource === '客户报事' ? 1 : 0
      this.isProject = true
      this.checkList = []
      this.checkPositionList = []
      this.reportRouterList = []
      this.routerList = []
      val && this._getProjectList()
    }
  },
  methods: {
    clickTabs (name, title) { // 切换tab
      this.reportRouterList = [{ organCode: this.checkList[0].organCode, organName: this.checkList[0].organName }]
      this.positionList = []
      if (name === 0) { this.getRegionList() }
      if (name === 1) { this.getBuildList() }
    },
    // popup打开
    selectProjectOpenHandler () {
      window.history.pushState(null, null, '#') // 模拟新的一页history记录
      window.addEventListener('popstate', this.popstateHandler)
    },
    popstateHandler (e) {
      this.animateDuration = 0 // 防止侧滑出现多次动画
      this._show = false
    },
    // popup关闭
    selectProjectCloseHandler () {
      window.removeEventListener('popstate', this.popstateHandler, false)
    },
    async _getProjectList () {
      if (!this.isOffline) return
      const res = await getTWProjectTree()
      this.projectList = res
      this.ctprojectList = this.lodash.cloneDeep(res)
    },
    goBack () {
      window.history.back()
      this._show = false
    },
    searchProject (val) {
      console.log('val: ', val)
      this.searchList = []
      if (!val) {
        if (this.isProject) {
          this._getProjectList()
        } else {
          if (this.tabActive === 0) { // 公区
            this.positionList = this.ctPositionList
          } else { // 业主报事
            this.getRoomList(val)
          }
        }
      } else {
        this.isSearch = true
        if (this.isProject) { // 选择项目
          this.routerList = []
          this.filterTree(this.ctprojectList, val)
        } else { // 选择报事位置
          this.reportRouterList = [{ organCode: this.checkList[0].organCode, organName: this.checkList[0].organName }]
          if (this.tabActive === 0) { // 公区
            this.positionList = this.ctPositionList.filter(item => {
              if (item.regionalPlace) { // 有为空的会报错
                return item.regionalPlace.includes(val)
              }
            })
            console.log('this.positionList: ', this.positionList)
          } else { // 业主报事
            console.log(222222)
            this.getRoomList(val)
          }
        }
      }
    },
    filterTree (list, val) { // 搜索过滤
      list.forEach((item) => {
        if (item.organName.includes(val)) {
          this.searchList.push(item)
        } else {
          if (item.children?.length) {
            this.filterTree(item.children, val)
          }
        }
      })
    },
    clearSearch () {
      this._getProjectList()
    },
    changeProjectCheck (item) {
      console.log('item: ', item)
      if (item.children?.length) {
        this.projectList = item.children
        this.routerList.push(item)
      } else {
        if (this.checkList?.length && item.organCode === this.checkList[0].organCode) {
          this.checkList = []
        } else {
          this.checkList = [item]
        }
      }
    },
    showCheck (item) {
      if (this.checkList?.length && item.organCode === this.checkList[0].organCode) {
        return true
      }
    },
    checkRouter (boolean, item) {
      console.log('item~~~: ', item)
      if (boolean) {
        this.routerList.splice(this.routerList.indexOf(item) + 1)
        this.projectList = item.children
      }
    },
    checkReportRouter (item, index, type) { // 报事位置路由
      console.log('index: ', index)
      console.log('item: ', item)
      if (type === 'firstIcon') {
        this.tabActive = 0
        this.isProject = !this.isProject
      }
      this.reportRouterList.splice(this.reportRouterList.indexOf(item) + 1)
      if (!item.level) {
        if (this.tabActive === 1) {
          this.getBuildList()
        } else {
          this.getRegionList()
        }
      }
      if (item.level === 1) {
        this.getUnitList()
      }
      if (item.level === 2) {
        this.getFloorList()
      }
      if (item.level === 3) {
        this.getRoomList()
      }
    },
    showPositionCheck (item) { // 报事位置列表 是否 展示勾选
      if (this.tabActive === 1) {
        if (this.checkPositionList?.length && item.holdID === this.checkPositionList[0].holdID) {
          return true
        }
      } else {
        if (this.checkPositionList?.length && item.regionalID === this.checkPositionList[0].regionalID) {
          return true
        }
      }
    },
    async getRegionList () { // 获取公区列表
      const obj = { projectCode: this.checkList[0].inPopedom }
      const res = await getTWRegions(obj)
      console.log('res~~~: ', res)
      res.forEach((item) => {
        this.$set(item, 'level', 4)
      })
      this.positionList = res
      this.ctPositionList = this.lodash.cloneDeep(res)
    },
    async getBuildList () { // 获取所在楼栋  1
      const obj = {
        // projectCode: this.checkList[0].organCode
        projectCode: this.checkList[0].inPopedom
      }
      const res = await getTWBuildings(obj)
      console.log('res~~~: ', res)
      res.forEach((item) => {
        this.$set(item, 'level', 1)
      })
      this.positionList = res
    },
    async getUnitList () { // 获取单元列表  2
      const obj = {
        buildSNum: this.buildSNum,
        projectCode: this.checkList[0].inPopedom
      }
      const res = await getTWUnits(obj)
      res.forEach((item) => {
        this.$set(item, 'level', 2)
      })
      this.positionList = res
    },
    async getFloorList () { // 获取楼层列表  3
      const obj = {
        projectCode: this.checkList[0].inPopedom,
        buildSNum: this.buildSNum,
        unitSNum: this.unitSNum
      }
      const res = await getTWFloors(obj)
      console.log('res~~~: ', res)
      res.forEach((item) => {
        this.$set(item, 'level', 3)
      })
      this.positionList = res
    },
    async getRoomList (name) { // 获取房屋列表  4
      const obj = {
        projectCode: this.checkList[0].inPopedom,
        buildSNum: this.buildSNum,
        unitSNum: this.unitSNum,
        floorSNum: this.floorSNum,
        roomName: name
      }
      const res = await getTWRooms(obj)
      console.log('res~~~: ', res)
      res.forEach((item) => {
        this.$set(item, 'level', 4)
      })
      this.positionList = res
    },
    changePositionCheck (item) { // 选择报事位置
      if (item.level === 1) {
        this.reportRouterList.push(item)
        this.buildSNum = item.buildSNum
        this.buildName = item.buildName
        this.getUnitList(item)
      }
      if (item.level === 2) {
        this.reportRouterList.push(item)
        this.unitSNum = item.unitSNum
        this.getFloorList(item)
      }
      if (item.level === 3) {
        this.reportRouterList.push(item)
        this.floorSNum = item.floorSNum
        this.floorName = item.floorName
        this.getRoomList()
      }
      if (item.level === 4) {
        if (this.tabActive === 1) { // tabs选择公区
          if (this.checkPositionList?.length && item.holdID === this.checkPositionList[0].holdID) {
            this.checkPositionList = []
          } else {
            this.checkPositionList = [item]
          }
        } else { // tabs选择业主报事
          if (this.checkPositionList?.length && item.regionalID === this.checkPositionList[0].regionalID) {
            this.checkPositionList = []
          } else {
            this.checkPositionList = [item]
          }
        }
      }
      console.log('this.checkPositionList: ', this.checkPositionList)
    },
    /**
     * 因为存在有保存的默认项目，初始化时会执行这一步
     * routerBack 是否返回上一页
     */
    async submit (routerBack = true) {
      console.log('this.isProject: ', this.isProject)
      if (this.isProject) {
        if (this.checkList.length === 0) {
          this.$toast('请选择项目')
          return
        }
        if (this.mode === 'position') {
          this.reportRouterList = [{ organCode: this.checkList[0].organCode, organName: this.checkList[0].organName }]
          console.log('this.reportRouterList: ', this.reportRouterList)
          this.incidentSource === '客户报事' ? this.getBuildList() : this.getRegionList()
          this.isProject = !this.isProject
        }
        if (this.mode === 'project') {
          this.$emit('result', {
            organCode: this.checkList[0].inPopedom,
            organName: this.checkList[0].organName
          })
          routerBack && this.goBack()
        }
      } else {
        if (this.checkPositionList.length === 0) {
          this.incidentSource === '客户报事' ? this.$toast('请选择报事业主') : this.$toast('请选择报事位置')
          return
        }
        const incidentPlace = this.tabActive === 0 ? '公区' : '户内'
        const obj = {
          searchValue: this.searchValue,
          checkList: this.lodash.cloneDeep(this.checkList), // 避免变量污染
          routerList: this.routerList,
          checkPositionList: this.lodash.cloneDeep(this.checkPositionList),
          reportRouterList: this.reportRouterList,
          buildSNum: this.buildSNum,
          buildName: this.buildName,
          unitSNum: this.buildSNum,
          floorSNum: this.floorSNum,
          floorName: this.floorName,
          incidentPlace: incidentPlace
        }
        console.log('submit~~~~', obj)
        this.$emit('result', obj)
        routerBack && this.goBack()
      }
    }
  }
}
</script>
<style lang='less' scoped>
.select-project-wrapper {
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  p {
    margin: 0;
  }
  .icon-back {
    height: 24px;
    width: 24px;
    background: url("~@/asset/icon_back_black@2x.png") no-repeat;
    background-size: cover;
  }
  .multi-wrapper {
    display: flex;
    .multi-icon {
      width: 24px;
      height: 24px;
    }
    .multi-default {
      background: url("~@/asset/multi-default.png") no-repeat;
      background-size: 100% 100%;
    }
    .multi-check {
      background: url("~@/asset/multi-check.png") no-repeat;
      background-size: 100% 100%;
    }
    .multi-all {
      background: url("~@/asset/multi-all.png") no-repeat;
      background-size: 100% 100%;
    }
    .title {
      font-size: 16px;
      font-weight: 400;
      color: #222222;
      line-height: 22px;
    }
  }
  .search-wrapper {
    margin-top: 8px;
    padding: 6px 16px;
    /deep/ .van-search__content {
      background-color: #f5f6f6;
    }
  }
  .project-list-wrapper {
    max-height: calc(100vh - 44px - 100px);
    overflow-y: scroll;
    padding-bottom: 15px;
    .project-list {
      height: 48px;
      display: flex;
      padding: 0 16px;
      align-items: center;
      justify-content: space-between;
      .name {
        font-size: 16px;
        font-weight: 400;
        color: #222222;
        line-height: 24px;
      }
      .right-icon {
        width: 16px;
        height: 16px;
        background: url("~@/asset/right-icon.png") no-repeat;
        background-size: 100% 100%;
      }
      .check-icon {
        width: 24px;
        height: 24px;
        background: url("~@/asset/icon_right_blue@2x.png") no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .Position-list-wrapper {
    max-height: calc(100vh - 88px - 100px);
    overflow-y: scroll;
    padding-bottom: 15px;
    .project-list {
      height: 48px;
      display: flex;
      padding: 0 16px;
      align-items: center;
      justify-content: space-between;
      .name {
        font-size: 16px;
        font-weight: 400;
        color: #222222;
        line-height: 24px;
      }
      .right-icon {
        width: 16px;
        height: 16px;
        background: url("~@/asset/right-icon.png") no-repeat;
        background-size: 100% 100%;
      }
      .check-icon {
        width: 24px;
        height: 24px;
        background: url("~@/asset/icon_right_blue@2x.png") no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .router-wrapper {
    margin: 10px 0px;
    background-color: #fff;
    // padding-bottom: constant(safe-area-inset-bottom);
    // padding-bottom: env(safe-area-inset-bottom);
    .list-wrapper {
      height: 22px;
      display: flex;
      padding: 0 16px;
      width: 100%;
      overflow-x: auto;
    }
    .router-list {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      .name {
        font-size: 16px;
        font-weight: 400;
        color: #3366fe;
        line-height: 16px;
        &.last {
          color: #8b8f9a;
        }
      }
      .router-icon {
        width: 16px;
        height: 16px;
        margin: 0 6px;
        background: url("~@/asset/right-icon.png") no-repeat;
        background-size: 100% 100%;
      }
      .first-icon {
        margin-right: 8px;
        width: 16px;
        height: 16px;
        background: url("~@/asset/exchange.svg") no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .submit-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 64px;
    height: 64px;
    background: linear-gradient(90deg, #62a9ff 0%, #3370fe 100%);
    box-shadow: 0px 2px 6px 0px rgba(51, 102, 254, 0.4);
    border-radius: 50%;
    text-align: center;
    overflow: hidden;
    position: fixed;
    right: 16px;
    bottom: 76px;
    .count {
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      line-height: 1;
    }
    .text {
      font-size: 16px;
      font-weight: 400;
      color: #ffffff;
      line-height: 1;
      margin-top: 5px;
    }
  }
}
::v-deep .van-tabs {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  .van-tab__text--ellipsis {
    font-size: 16px;
  }
  & > div:first-of-type {
    flex-shrink: 0;
  }
  .van-tabs__content {
    flex: 1;
    overflow: hidden;
    font-size: 16px;
    .van-tabs__track {
      max-height: 100%;
      height: 100%;
      .van-tab__pane-wrapper {
        max-height: 100%;
        height: 100%;
        .van-tab__pane {
          max-height: 100%;
          height: 100%;
          overflow-y: auto !important;
        }
      }
    }
  }
}
</style>
