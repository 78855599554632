var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "OrderCheckDetail" },
    [
      _c("my-nav", { attrs: { title: "审核详情" } }),
      _c(
        "van-tabs",
        {
          staticClass: "OrderCheckDetailTabs",
          attrs: {
            animated: "",
            color: "#3366FE",
            sticky: "",
            swipeable: "",
            "offset-top": "44",
            "title-active-color": "#3366FE",
            "title-inactive-color": "#7C7F8A",
            "line-height": "2px",
            "line-width": "90px",
          },
          model: {
            value: _vm.active,
            callback: function ($$v) {
              _vm.active = $$v
            },
            expression: "active",
          },
        },
        [
          _c("van-tab", { attrs: { title: "审核详情" } }, [
            _c("div", { staticClass: "ordercheck-iframe bg-red px-16px" }, [
              _c("iframe", {
                attrs: {
                  src: "https://tw02.dongyuanwuye.com/MobilePropertyNew/Web/OaAuditPages/Incident/Incident_Transmit_DY.html?IID=051e7a5c191b498fa4eae63e7753b0b5&IncidentID=100093000000000198&CommID=100093&CorpID=1000&LoginCode=601781&Url=http://crm.dongyuanwuye.com/HM/M_Main/HC/Service.ashx",
                  frameborder: "0",
                },
              }),
            ]),
          ]),
          _c("van-tab", { attrs: { title: "工单详情" } }, [
            _c(
              "div",
              { staticClass: "scroll-detail-wrapper bg-white px-16px" },
              [
                _c("div", { staticClass: "order-cell-title" }, [
                  _c(
                    "div",
                    { staticClass: "flex-box" },
                    [
                      _c("div", { staticClass: "order-number" }, [
                        _vm._v(_vm._s(_vm.orderDetail.order_no)),
                      ]),
                      _c(
                        "van-tag",
                        {
                          staticClass: "nature-tag ml-4px",
                          attrs: { plain: "", type: "primary", color: "#999" },
                        },
                        [_vm._v("协助")]
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "status color-blue" }, [
                    _vm._v(_vm._s(_vm.orderDetail.node_name)),
                  ]),
                ]),
                _c("div", { staticClass: "order-body-title" }, [
                  _vm._v("报事信息"),
                ]),
                _c(
                  "van-row",
                  { staticClass: "order-body-cell", attrs: { gutter: "10" } },
                  [
                    _c(
                      "van-col",
                      { staticClass: "sub-key", attrs: { span: 6 } },
                      [_vm._v("工单属性:")]
                    ),
                    _c(
                      "van-col",
                      { staticClass: "order-sub-flex", attrs: { span: 18 } },
                      [
                        _c(
                          "van-tag",
                          {
                            staticClass: "mr-8",
                            attrs: {
                              type: "success",
                              color: "#45A2F4",
                              size: "medium",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.orderDetail.order_type_name) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "van-tag",
                          {
                            staticClass: "mr-8",
                            style: `background:${_vm.orderNatureConf.tagBacColor}`,
                            attrs: {
                              plain: "",
                              type: "primary",
                              size: "medium",
                              color: _vm.orderNatureConf.tagColor,
                            },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.orderDetail.order_nature_name)
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "van-row",
                  { staticClass: "order-body-cell", attrs: { gutter: "10" } },
                  [
                    _c(
                      "van-col",
                      { staticClass: "sub-key", attrs: { span: 6 } },
                      [_vm._v("所在项目:")]
                    ),
                    _c(
                      "van-col",
                      { staticClass: "sub-value", attrs: { span: 18 } },
                      [_c("span", [_vm._v("重庆D7")])]
                    ),
                  ],
                  1
                ),
                _c(
                  "van-row",
                  { staticClass: "order-body-cell", attrs: { gutter: "10" } },
                  [
                    _c(
                      "van-col",
                      { staticClass: "sub-key", attrs: { span: 6 } },
                      [_vm._v("报事来源:")]
                    ),
                    _c(
                      "van-col",
                      { staticClass: "sub-value", attrs: { span: 18 } },
                      [_c("span", [_vm._v("重庆D7")])]
                    ),
                  ],
                  1
                ),
                _c(
                  "van-row",
                  { staticClass: "order-body-cell", attrs: { gutter: "10" } },
                  [
                    _c(
                      "van-col",
                      { staticClass: "sub-key", attrs: { span: 6 } },
                      [_vm._v("报事责任:")]
                    ),
                    _c(
                      "van-col",
                      { staticClass: "sub-value", attrs: { span: 18 } },
                      [_c("span", [_vm._v("重庆D7")])]
                    ),
                  ],
                  1
                ),
                _c(
                  "van-row",
                  { staticClass: "order-body-cell", attrs: { gutter: "10" } },
                  [
                    _c(
                      "van-col",
                      { staticClass: "sub-key", attrs: { span: 6 } },
                      [_vm._v("所在项目:")]
                    ),
                    _c(
                      "van-col",
                      { staticClass: "sub-value", attrs: { span: 18 } },
                      [_c("span", [_vm._v("重庆D7")])]
                    ),
                  ],
                  1
                ),
                _c(
                  "van-row",
                  { staticClass: "order-body-cell", attrs: { gutter: "10" } },
                  [
                    _c(
                      "van-col",
                      { staticClass: "sub-key", attrs: { span: 6 } },
                      [_vm._v("报事渠道:")]
                    ),
                    _c(
                      "van-col",
                      { staticClass: "sub-value", attrs: { span: 18 } },
                      [_c("span", [_vm._v("重庆D7")])]
                    ),
                  ],
                  1
                ),
                _c(
                  "van-row",
                  { staticClass: "order-body-cell", attrs: { gutter: "10" } },
                  [
                    _c(
                      "van-col",
                      { staticClass: "sub-key", attrs: { span: 6 } },
                      [_vm._v("报事方式:")]
                    ),
                    _c(
                      "van-col",
                      { staticClass: "sub-value", attrs: { span: 18 } },
                      [_c("span", [_vm._v("重庆D7")])]
                    ),
                  ],
                  1
                ),
                _c(
                  "van-row",
                  { staticClass: "order-body-cell", attrs: { gutter: "10" } },
                  [
                    _c(
                      "van-col",
                      { staticClass: "sub-key", attrs: { span: 6 } },
                      [_vm._v("创建人:")]
                    ),
                    _c(
                      "van-col",
                      { staticClass: "sub-value", attrs: { span: 18 } },
                      [
                        _c("span", [_vm._v("重庆D7")]),
                        _vm.orderDetail.create_phone
                          ? _c("span", { staticClass: "hpone-icon" })
                          : _vm._e(),
                        _vm.orderDetail.create_phone
                          ? _c(
                              "span",
                              {
                                staticClass: "color-blue",
                                on: {
                                  click: function ($event) {
                                    return _vm.takePhone(
                                      _vm.orderDetail.create_phone
                                    )
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.orderDetail.create_phone))]
                            )
                          : _vm._e(),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "van-row",
                  { staticClass: "order-body-cell", attrs: { gutter: "10" } },
                  [
                    _c(
                      "van-col",
                      { staticClass: "sub-key", attrs: { span: 6 } },
                      [_vm._v("报事人:")]
                    ),
                    _c(
                      "van-col",
                      { staticClass: "sub-value", attrs: { span: 18 } },
                      [
                        _c("span", [_vm._v("重庆D7")]),
                        _vm.orderDetail.create_phone
                          ? _c("span", { staticClass: "hpone-icon" })
                          : _vm._e(),
                        _vm.orderDetail.create_phone
                          ? _c(
                              "span",
                              {
                                staticClass: "color-blue",
                                on: {
                                  click: function ($event) {
                                    return _vm.takePhone(
                                      _vm.orderDetail.create_phone
                                    )
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.orderDetail.create_phone))]
                            )
                          : _vm._e(),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "van-row",
                  { staticClass: "order-body-cell", attrs: { gutter: "10" } },
                  [
                    _c(
                      "van-col",
                      { staticClass: "sub-key", attrs: { span: 6 } },
                      [_vm._v("客户名称:")]
                    ),
                    _c(
                      "van-col",
                      { staticClass: "sub-value", attrs: { span: 18 } },
                      [
                        _c("span", [_vm._v("重庆D7")]),
                        _vm.orderDetail.create_phone
                          ? _c("span", { staticClass: "hpone-icon" })
                          : _vm._e(),
                        _vm.orderDetail.create_phone
                          ? _c(
                              "span",
                              {
                                staticClass: "color-blue",
                                on: {
                                  click: function ($event) {
                                    return _vm.takePhone(
                                      _vm.orderDetail.create_phone
                                    )
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.orderDetail.create_phone))]
                            )
                          : _vm._e(),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "van-row",
                  { staticClass: "order-body-cell", attrs: { gutter: "10" } },
                  [
                    _c(
                      "van-col",
                      { staticClass: "sub-key", attrs: { span: 6 } },
                      [_vm._v("报事时间:")]
                    ),
                    _c(
                      "van-col",
                      { staticClass: "sub-value", attrs: { span: 18 } },
                      [_c("span", [_vm._v("重庆D7")])]
                    ),
                  ],
                  1
                ),
                _c(
                  "van-row",
                  { staticClass: "order-body-cell", attrs: { gutter: "10" } },
                  [
                    _c(
                      "van-col",
                      { staticClass: "sub-key", attrs: { span: 6 } },
                      [_vm._v("报事位置:")]
                    ),
                    _c(
                      "van-col",
                      { staticClass: "sub-value", attrs: { span: 18 } },
                      [_c("span", [_vm._v("重庆D7")])]
                    ),
                  ],
                  1
                ),
                _c(
                  "van-row",
                  { staticClass: "order-body-cell", attrs: { gutter: "10" } },
                  [
                    _c(
                      "van-col",
                      { staticClass: "sub-key", attrs: { span: 6 } },
                      [_vm._v("报事类别:")]
                    ),
                    _c(
                      "van-col",
                      { staticClass: "sub-value", attrs: { span: 18 } },
                      [_c("span", [_vm._v("重庆D7")])]
                    ),
                  ],
                  1
                ),
                _c(
                  "van-row",
                  { staticClass: "order-body-cell", attrs: { gutter: "10" } },
                  [
                    _c(
                      "van-col",
                      { staticClass: "sub-key", attrs: { span: 6 } },
                      [_vm._v("原因细类:")]
                    ),
                    _c(
                      "van-col",
                      { staticClass: "sub-value", attrs: { span: 18 } },
                      [_c("span", [_vm._v("重庆D7")])]
                    ),
                  ],
                  1
                ),
                _c(
                  "van-row",
                  { staticClass: "order-body-cell", attrs: { gutter: "10" } },
                  [
                    _c(
                      "van-col",
                      { staticClass: "sub-key", attrs: { span: 6 } },
                      [_vm._v("预约处理时间:")]
                    ),
                    _c(
                      "van-col",
                      { staticClass: "sub-value", attrs: { span: 18 } },
                      [_c("span", [_vm._v("重庆D7")])]
                    ),
                  ],
                  1
                ),
                _c(
                  "van-row",
                  { staticClass: "order-body-cell", attrs: { gutter: "10" } },
                  [
                    _c(
                      "van-col",
                      { staticClass: "sub-key", attrs: { span: 6 } },
                      [_vm._v("关闭类型:")]
                    ),
                    _c(
                      "van-col",
                      { staticClass: "sub-value", attrs: { span: 18 } },
                      [_c("span", [_vm._v("重庆D7")])]
                    ),
                  ],
                  1
                ),
                _c(
                  "van-row",
                  { staticClass: "order-body-cell", attrs: { gutter: "10" } },
                  [
                    _c(
                      "van-col",
                      { staticClass: "sub-key", attrs: { span: 6 } },
                      [_vm._v("工单描述:")]
                    ),
                    _c(
                      "van-col",
                      { staticClass: "sub-value", attrs: { span: 18 } },
                      [
                        _c("span", [_vm._v("重庆D7")]),
                        _vm.orderDetail.attachment_list &&
                        _vm.orderDetail.attachment_list.length > 0
                          ? _c(
                              "div",
                              { staticClass: "detail_image_list" },
                              _vm._l(
                                _vm.orderDetail.attachment_list,
                                function (list, index) {
                                  return _c("van-image", {
                                    key: index,
                                    staticClass: "photo",
                                    attrs: {
                                      width: "40",
                                      height: "40",
                                      radius: "4",
                                      src: list.url,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.imgPreview(
                                          index,
                                          _vm.orderDetail.attachment_list
                                        )
                                      },
                                    },
                                  })
                                }
                              ),
                              1
                            )
                          : _vm._e(),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("van-tab", { attrs: { title: "跟进进程" } }, [
            _c(
              "div",
              { staticClass: "scroll-detail-wrapper bg-white" },
              [
                _c(
                  "van-steps",
                  {
                    staticClass: "stpe-component",
                    attrs: { direction: "vertical", "active-color": "#969799" },
                  },
                  _vm._l(_vm.logList, function (item, index) {
                    return _c("van-step", { key: index, staticClass: "step" }, [
                      _c(
                        "div",
                        { staticClass: "step-cell" },
                        [
                          item.currNodeId !== item.preNodeId
                            ? [
                                _c("span", { staticClass: "color-blue" }, [
                                  _vm._v(_vm._s(item.preNodeName)),
                                ]),
                                item.preNodeName
                                  ? _c("em", { staticClass: "icon-arrow-blue" })
                                  : _vm._e(),
                                _c(
                                  "span",
                                  {
                                    staticClass: "color-blue",
                                    class: { "ml-24": item.preNodeName },
                                  },
                                  [_vm._v(_vm._s(item.curNodeName))]
                                ),
                              ]
                            : [
                                _c("span", { staticClass: "color-blue" }, [
                                  _vm._v(_vm._s(item.curNodeName)),
                                ]),
                              ],
                          _c("span", { staticClass: "color-gray" }, [
                            _vm._v("「" + _vm._s(item.handlerName)),
                            item.handleMobile
                              ? _c("span", [
                                  _vm._v("(" + _vm._s(item.handleMobile) + ")"),
                                ])
                              : _vm._e(),
                            _vm._v("」"),
                          ]),
                        ],
                        2
                      ),
                      item.cuiban
                        ? _c(
                            "div",
                            { staticStyle: { margin: "4px 0" } },
                            [
                              _c(
                                "van-tag",
                                {
                                  staticStyle: {
                                    background: "rgba(253, 141, 55, 0.1)",
                                  },
                                  attrs: {
                                    plain: "",
                                    color: "#FD8D37",
                                    size: "medium",
                                  },
                                },
                                [
                                  _c("span", { staticClass: "font-bold" }, [
                                    _vm._v("催办"),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("div", { staticClass: "step-cell time color-gray" }, [
                        _vm._v(" " + _vm._s(item.createTime) + " "),
                      ]),
                      _c(
                        "div",
                        { staticClass: "step-cell color-black" },
                        [
                          !item.create
                            ? [
                                _vm._v(" " + _vm._s(item.device)),
                                item.device !== "APP"
                                  ? _c("span", [_vm._v("端")])
                                  : _vm._e(),
                                _vm._v("处理： "),
                              ]
                            : _vm._e(),
                          _vm._v(" " + _vm._s(item.content) + " "),
                        ],
                        2
                      ),
                      _c(
                        "div",
                        { staticClass: "order-photos" },
                        _vm._l(item.imgList, function (list, index) {
                          return _c("van-image", {
                            key: index,
                            staticClass: "photo",
                            attrs: {
                              width: "40",
                              height: "40",
                              radius: "4",
                              src: list.url,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.imgPreview(index, item.imgList)
                              },
                            },
                          })
                        }),
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "file-list" },
                        _vm._l(item.fileList, function (file, index) {
                          return _c(
                            "div",
                            { key: file.id + index, staticClass: "list" },
                            [
                              _c("i", { staticClass: "file-icon" }),
                              _c("span", { staticClass: "file-name" }, [
                                _vm._v(_vm._s(file.name)),
                              ]),
                            ]
                          )
                        }),
                        0
                      ),
                    ])
                  }),
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }