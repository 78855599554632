var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "OrderSpotCheckDetail" },
    [
      _c("my-nav", { attrs: { title: "抽查" } }),
      _vm._l(_vm.detail.options, function (option, index) {
        return _c(
          "div",
          { key: index, staticClass: "wrapper" },
          [
            _c(
              "van-row",
              { staticClass: "mb-8px" },
              [
                _c(
                  "van-col",
                  { staticClass: "text-[#8B8F9A]", attrs: { span: "6" } },
                  [_vm._v("点位名称：")]
                ),
                _c(
                  "van-col",
                  { staticClass: "text-[#222222]", attrs: { span: "18" } },
                  [_vm._v(" " + _vm._s(_vm.detail.itemName) + " ")]
                ),
              ],
              1
            ),
            _c(
              "van-row",
              { staticClass: "mb-8px" },
              [
                _c(
                  "van-col",
                  { staticClass: "text-[#8B8F9A]", attrs: { span: "6" } },
                  [_vm._v("名称：")]
                ),
                _c(
                  "van-col",
                  { staticClass: "text-[#222222]", attrs: { span: "18" } },
                  [_vm._v(" " + _vm._s(option.name) + " ")]
                ),
              ],
              1
            ),
            _c(
              "van-row",
              { staticClass: "mb-8px" },
              [
                _c(
                  "van-col",
                  { staticClass: "text-[#8B8F9A]", attrs: { span: "24" } },
                  [_vm._v("说明：")]
                ),
                _c(
                  "van-col",
                  { staticClass: "text-[#222222]", attrs: { span: "24" } },
                  [_vm._v(" " + _vm._s(option.optionSecondTitle) + " ")]
                ),
              ],
              1
            ),
            _c(
              "van-row",
              { staticClass: "mb-8px" },
              [
                _c(
                  "van-col",
                  { staticClass: "text-[#8B8F9A]", attrs: { span: "24" } },
                  [_vm._v("示例图片：")]
                ),
                option.secondTitleAttachments &&
                option.secondTitleAttachments.length
                  ? _c(
                      "van-col",
                      { staticClass: "text-[#222222]", attrs: { span: "24" } },
                      _vm._l(
                        option.secondTitleAttachments,
                        function (image, imgIndex) {
                          return _c("van-image", {
                            key: imgIndex,
                            staticClass: "mr-10px mt-10px",
                            attrs: {
                              width: "40",
                              height: "40",
                              radius: "4",
                              src: image.url,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.imgPreview(
                                  option.secondTitleAttachments,
                                  imgIndex
                                )
                              },
                            },
                          })
                        }
                      ),
                      1
                    )
                  : _c(
                      "van-col",
                      { staticClass: "text-[#222222]", attrs: { span: "24" } },
                      [_vm._v("-")]
                    ),
              ],
              1
            ),
            _vm._l(option.typeOptions, function (typeOption, optionIndex) {
              return _c(
                "van-row",
                { key: optionIndex, staticClass: "mb-8px" },
                [
                  [3, 4, 5, 6, 10].includes(typeOption.type)
                    ? [
                        _c(
                          "van-col",
                          {
                            staticClass: "text-[#8B8F9A]",
                            attrs: { span: "6" },
                          },
                          [_vm._v("执行结果：")]
                        ),
                        _c(
                          "van-col",
                          {
                            staticClass: "text-[#222222]",
                            attrs: { span: "18" },
                          },
                          [_vm._v(" " + _vm._s(typeOption.val || "-") + " ")]
                        ),
                      ]
                    : _vm._e(),
                  typeOption.type === 2
                    ? [
                        _c(
                          "van-col",
                          {
                            staticClass: "text-[#8B8F9A]",
                            attrs: { span: "6" },
                          },
                          [_vm._v("执行备注：")]
                        ),
                        _c(
                          "van-col",
                          {
                            staticClass: "text-[#222222]",
                            attrs: { span: "18" },
                          },
                          [_vm._v(" " + _vm._s(typeOption.val || "-") + " ")]
                        ),
                      ]
                    : _vm._e(),
                  [7, 8, 9].includes(typeOption.type)
                    ? [
                        _c(
                          "van-col",
                          {
                            staticClass: "text-[#8B8F9A]",
                            attrs: { span: "24" },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                typeOption.type === 7
                                  ? "执行"
                                  : typeOption.type === 8
                                  ? "事前"
                                  : "事后"
                              ) + "图片："
                            ),
                          ]
                        ),
                        _c(
                          "van-col",
                          { attrs: { span: "24" } },
                          _vm._l(
                            typeOption.optionAttachments,
                            function (image, imgIndex) {
                              return _c("van-image", {
                                key: imgIndex,
                                staticClass: "mr-10px mt-10px",
                                attrs: {
                                  width: "40",
                                  height: "40",
                                  radius: "4",
                                  src: image.url,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleClickImage(
                                      typeOption,
                                      imgIndex,
                                      option
                                    )
                                  },
                                },
                              })
                            }
                          ),
                          1
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              )
            }),
            option.questionList && option.questionList.length
              ? _c(
                  "van-row",
                  { staticClass: "mb-8px" },
                  [
                    _c(
                      "van-col",
                      { staticClass: "text-[#8B8F9A]", attrs: { span: "24" } },
                      [_vm._v("问题整改：")]
                    ),
                    _c(
                      "van-col",
                      { staticClass: "text-[#3366FE]", attrs: { span: "24" } },
                      _vm._l(option.questionList, function (question, qIndex) {
                        return _c(
                          "div",
                          {
                            key: qIndex,
                            on: {
                              click: function ($event) {
                                return _vm.routerToOrderDetail(question)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(question.orderNo) + " ")]
                        )
                      }),
                      0
                    ),
                  ],
                  1
                )
              : _vm._e(),
            option.spotCheckItems
              ? _c(
                  "div",
                  { staticClass: "btn-wrapper flex justify-end" },
                  [
                    option.status
                      ? _c(
                          "van-button",
                          {
                            staticStyle: {
                              width: "80px",
                              height: "24px",
                              "border-radius": "4px",
                            },
                            attrs: {
                              type: "primary",
                              plain: "",
                              color: "#3366fe",
                              size: "mini",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleOption(option)
                              },
                            },
                          },
                          [_vm._v("查看")]
                        )
                      : _c(
                          "van-button",
                          {
                            staticStyle: {
                              width: "80px",
                              height: "24px",
                              "border-radius": "4px",
                            },
                            attrs: {
                              type: "primary",
                              color: "#3366fe",
                              size: "mini",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleOption(option)
                              },
                            },
                          },
                          [_vm._v("填写结果")]
                        ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          2
        )
      }),
      _c(
        "van-dialog",
        {
          staticStyle: { width: "90vw" },
          attrs: {
            title: "查看图片",
            "show-cancel-button": "",
            getContainer: ".OrderSpotCheckDetail",
            "before-close": (action, done) =>
              _vm.beforeAddEditPicktureDialogClose(action, done),
          },
          model: {
            value: _vm.addEditPicktureDialogShow,
            callback: function ($$v) {
              _vm.addEditPicktureDialogShow = $$v
            },
            expression: "addEditPicktureDialogShow",
          },
        },
        [
          _vm.editPictureOfflineImageBase
            ? _c("img", {
                staticStyle: { "object-fit": "contain", "max-height": "400px" },
                attrs: {
                  src: _vm.editPictureOfflineImageBase,
                  width: "100%",
                  alt: "",
                },
              })
            : _vm._e(),
          _c("van-field", {
            staticClass: "form-input",
            attrs: {
              readonly: "",
              clickable: "",
              required: true,
              label: "添加抽查项",
              "right-icon": "arrow",
              "input-align": "right",
              placeholder: "请选择",
              value: _vm.spotCheckItemPickerValue,
            },
            on: {
              click: function ($event) {
                _vm.spotCheckItemPickerShow = true
              },
            },
          }),
          _c(
            "van-popup",
            {
              attrs: { position: "bottom" },
              model: {
                value: _vm.spotCheckItemPickerShow,
                callback: function ($$v) {
                  _vm.spotCheckItemPickerShow = $$v
                },
                expression: "spotCheckItemPickerShow",
              },
            },
            [
              _c("van-picker", {
                attrs: {
                  title: "选择抽查项",
                  "show-toolbar": "",
                  columns: _vm.spotCheckItems,
                  "value-key": "spotCheckConfigName",
                },
                on: {
                  cancel: function ($event) {
                    _vm.spotCheckItemPickerShow = false
                  },
                  confirm: _vm.spotCheckItemPickerSelect,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }