var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "offline-list-wrapper" },
    [
      _c("my-nav", { attrs: { title: "工单查找结果" } }),
      _vm.list.length > 0 || _vm.codeLoading
        ? _c(
            "van-pull-refresh",
            {
              staticClass: "list-wrapper",
              attrs: { disabled: !_vm.isOffline },
              on: { refresh: _vm.onRefreshCode },
              model: {
                value: _vm.codeRefreshing,
                callback: function ($$v) {
                  _vm.codeRefreshing = $$v
                },
                expression: "codeRefreshing",
              },
            },
            [
              _c(
                "van-list",
                {
                  attrs: {
                    finished: _vm.codeFinished,
                    "finished-text": _vm.codeRefreshing
                      ? ""
                      : _vm.list.length > 0
                      ? "- 没有更多数据了 -"
                      : "您没有处理的工单",
                    offset: 50,
                    "immediate-check": false,
                  },
                  on: { load: _vm.getList },
                  model: {
                    value: _vm.codeLoading,
                    callback: function ($$v) {
                      _vm.codeLoading = $$v
                    },
                    expression: "codeLoading",
                  },
                },
                [
                  _c("CardList", {
                    attrs: { list: _vm.list, "qr-code": _vm.qrCode[0] },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _c("no-data", { attrs: { tex: "暂无任务" } }, [
            !_vm.isOffline
              ? _c("p", { staticClass: "tex desc" }, [
                  _vm._v(
                    " 当前暂无网络，请确认是否已经提前下载数据，或在有网络后再次确认是否无任务。 "
                  ),
                ])
              : _vm._e(),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }