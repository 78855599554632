var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "create-work" },
    [
      _c(
        "my-nav",
        {
          attrs: { title: "问题记录", "self-back": true },
          on: { back: _vm.goBack },
        },
        [
          _vm.config[_vm.type].showScan
            ? _c("ScanIcon", {
                attrs: {
                  slot: "right",
                  "code-type": _vm.config[_vm.type].codeType,
                },
                on: { scanSuccess: _vm.scanSuccess },
                slot: "right",
              })
            : _vm._e(),
        ],
        1
      ),
      _c("div", { staticClass: "separator_block" }),
      _c("div", { staticClass: "page_des" }, [
        _c("p", [_vm._v(_vm._s(_vm.config[_vm.type].head))]),
        _c("div", [_vm._v(_vm._s(_vm.config[_vm.type].desc))]),
      ]),
      _vm._m(0),
      _vm.config[_vm.type].selectProject
        ? _c("van-field", {
            staticClass: "form-input",
            attrs: {
              readonly: "",
              clickable: "",
              name: "projectName",
              value: _vm.form.projectName,
              label: "所在项目",
              required: "",
              "right-icon": "arrow",
              "input-align": "right",
              placeholder: "请选择",
            },
            on: { click: () => (_vm.selectProjectShow = true) },
          })
        : _vm._e(),
      _vm.config[_vm.type].inputMobile
        ? _c("van-field", {
            staticClass: "form-input",
            attrs: {
              name: "reportUserPhone",
              label: "联系方式",
              required: "",
              "right-icon": "",
              "input-align": "right",
              placeholder: "请输入联系方式",
            },
            model: {
              value: _vm.form.reportUserPhone,
              callback: function ($$v) {
                _vm.$set(_vm.form, "reportUserPhone", $$v)
              },
              expression: "form.reportUserPhone",
            },
          })
        : _vm._e(),
      _vm.config[_vm.type].selectReportCustomer
        ? _c("ReportCustomerSelect", {
            ref: "ReportCustomerSelect_1",
            attrs: {
              "house-name": _vm.form.reportHouseName,
              "confirm-type": "member",
              label: "报事客户",
            },
            on: {
              confirmRouter: _vm.reportCustomerSelectRouterConfirm,
              confirm: _vm.reportCustomerSelectConfirm,
            },
          })
        : _vm._e(),
      _vm.config[_vm.type].selectReportCustomer
        ? _c("van-field", {
            staticClass: "form-input",
            attrs: {
              name: "reportUserName",
              label: "报事人姓名",
              required: "",
              "right-icon": "",
              "input-align": "right",
              placeholder: "请输入",
            },
            model: {
              value: _vm.form.reportUserName,
              callback: function ($$v) {
                _vm.$set(_vm.form, "reportUserName", $$v)
              },
              expression: "form.reportUserName",
            },
          })
        : _vm._e(),
      _vm.config[_vm.type].selectReportCustomer
        ? _c("van-field", {
            staticClass: "form-input",
            attrs: {
              clickable: "",
              name: "reportUserPhone",
              label: "报事人电话",
              required: "",
              "right-icon": "",
              "input-align": "right",
              placeholder: "请输入",
            },
            model: {
              value: _vm.form.reportUserPhone,
              callback: function ($$v) {
                _vm.$set(_vm.form, "reportUserPhone", $$v)
              },
              expression: "form.reportUserPhone",
            },
          })
        : _vm._e(),
      _vm.config[_vm.type].selectHouse
        ? _c("ReportCustomerSelect", {
            ref: "ReportCustomerSelect_2",
            attrs: {
              "house-name": _vm.form.address,
              house: _vm.chooseHouse,
              "confirm-type": "house",
              "router-list": _vm.reportCustomerRouterList,
              label: "所在位置",
            },
            on: {
              confirmRouter: _vm.reportCustomerSelectRouterConfirm,
              confirm: _vm.reportHouseSelectConfirm,
            },
          })
        : _vm._e(),
      _vm.config[_vm.type].selectEquipment
        ? _c("MyFieldOfEquipmentSelect", {
            attrs: {
              "disabled-click": !_vm.form.projectCode,
              address: _vm.form.problemRecordList.address,
              "address-id": _vm.form.problemRecordList.addressId,
              "project-code": _vm.form.projectCode,
              categoryCode: _vm.form.categoryCode,
              reallyType: _vm.form.problemRecordList.addressType,
              required: "",
              "route-name": "ProblemRecord",
            },
            on: {
              "update:address": function ($event) {
                return _vm.$set(_vm.form.problemRecordList, "address", $event)
              },
              "update:addressId": function ($event) {
                return _vm.$set(_vm.form.problemRecordList, "addressId", $event)
              },
              "update:address-id": function ($event) {
                return _vm.$set(_vm.form.problemRecordList, "addressId", $event)
              },
              "update:categoryCode": function ($event) {
                return _vm.$set(_vm.form, "categoryCode", $event)
              },
              "update:category-code": function ($event) {
                return _vm.$set(_vm.form, "categoryCode", $event)
              },
              "update:reallyType": function ($event) {
                return _vm.$set(
                  _vm.form.problemRecordList,
                  "addressType",
                  $event
                )
              },
              "update:really-type": function ($event) {
                return _vm.$set(
                  _vm.form.problemRecordList,
                  "addressType",
                  $event
                )
              },
              confirm: _vm.myFieldOfEquipmentSelect,
            },
          })
        : _vm._e(),
      _vm.config[_vm.type].selectPos
        ? _c("van-field", {
            staticClass: "form-input",
            attrs: {
              readonly: "",
              clickable: "",
              name: "address",
              value: _vm.form.problemRecordList.address,
              label: "所在位置",
              required: "",
              "right-icon": "arrow",
              "input-align": "right",
              placeholder: "扫码或选择",
            },
            on: { click: _vm.selectPosition },
          })
        : _vm._e(),
      _vm._m(1),
      _vm.config[_vm.type].selectMalfunctionReason
        ? _c("MyFieldOfMalfunctionReasonSelect", {
            attrs: {
              "disabled-click": !_vm.form.problemRecordList.addressId,
              "route-name": "ProblemRecord",
              pointCode: _vm.form.problemRecordList.addressId,
              categoryCode: _vm.form.categoryCode,
              "problem-list": _vm.form.problemRecordList.problemList,
              reallyType: _vm.form.problemRecordList.addressType,
              guide: true,
              required: "",
            },
            on: { confirm: _vm.malfunctionReasonSelectConfirm },
          })
        : _vm._e(),
      _vm.config[_vm.type].selectProblem
        ? _c("van-field", {
            staticClass: "form-input",
            attrs: {
              readonly: "",
              clickable: "",
              label: "问题项",
              "label-width": "150px",
              required: "",
              "right-icon": "arrow",
              "input-align": "right",
              placeholder: "请选择",
            },
            on: { click: _vm.selectProblemItem },
          })
        : _vm._e(),
      _vm.config[_vm.type].selectProblem &&
      _vm.form.problemRecordList.problemList.length > 0
        ? _c(
            "div",
            { staticClass: "problem-list" },
            _vm._l(
              this.form.problemRecordList.problemList,
              function (item, index) {
                return _c("div", { key: index, staticClass: "item" }, [
                  _c("p", { staticClass: "problem-title" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          `${item.parentName ? item.parentName + "/" : ""}${
                            item.problemName || item.mainPartName
                          }`
                        ) +
                        " "
                    ),
                  ]),
                  item.questLabel.length > 0
                    ? _c(
                        "p",
                        { staticClass: "problem-info" },
                        _vm._l(item.questLabel, function (item2, index2) {
                          return _c("span", { key: index2 }, [
                            index2 !== 0 ? _c("i", [_vm._v("|")]) : _vm._e(),
                            _vm._v(_vm._s(item2.questLabelName || item2)),
                          ])
                        }),
                        0
                      )
                    : _vm._e(),
                  item.rectifyPeopls && item.rectifyPeopls.name
                    ? _c("p", { staticClass: "problem-person" }, [
                        _vm._v(
                          " 整改人：" + _vm._s(item.rectifyPeopls.name) + " "
                        ),
                      ])
                    : _vm._e(),
                ])
              }
            ),
            0
          )
        : _vm._e(),
      _vm.config[_vm.type].selectProblem
        ? _c("van-field", {
            staticClass: "form-input",
            attrs: {
              readonly: "",
              name: "grade",
              value: _vm.gradeValue,
              label: "问题分级",
              required: "",
              "input-align": "right",
              placeholder: "",
            },
          })
        : _vm._e(),
      _c("van-field", {
        staticClass: "form-textarea",
        attrs: {
          name: "remark",
          label: "问题描述",
          rows: "3",
          type: "textarea",
          placeholder: "请输入问题描述",
        },
        scopedSlots: _vm._u(
          [
            _vm.isOffline
              ? {
                  key: "right-icon",
                  fn: function () {
                    return [
                      _c("micro-text", { on: { speakRes: _vm.speakRes } }),
                    ]
                  },
                  proxy: true,
                }
              : null,
          ],
          null,
          true
        ),
        model: {
          value: _vm.form.problemRecordList.remark,
          callback: function ($$v) {
            _vm.$set(_vm.form.problemRecordList, "remark", $$v)
          },
          expression: "form.problemRecordList.remark",
        },
      }),
      _c(
        "div",
        { staticStyle: { padding: "0 16px 16px", background: "#fff" } },
        [
          _c("common-upload", {
            ref: "offlineUpload",
            attrs: {
              file: _vm.form.problemRecordList.attachmentList,
              "project-name": _vm.form.projectName,
              required: "",
              "enable-album": true,
              "enable-edit": true,
              "oss-params": _vm.ossParams,
            },
            on: {
              "update:file": function ($event) {
                return _vm.$set(
                  _vm.form.problemRecordList,
                  "attachmentList",
                  $event
                )
              },
            },
          }),
        ],
        1
      ),
      _vm.config[_vm.type].selectBookTime
        ? _c("van-field", {
            staticClass: "form-input",
            attrs: {
              readonly: "",
              name: "grade",
              value: _vm.form.bookingTime,
              label: "预约时间",
              "input-align": "right",
              placeholder: "非必需，客户希望上门时间",
            },
            on: {
              click: function ($event) {
                _vm.bookingTimeShow = true
              },
            },
          })
        : _vm._e(),
      _c(
        "van-popup",
        {
          attrs: { position: "bottom", "safe-area-inset-bottom": "" },
          model: {
            value: _vm.bookingTimeShow,
            callback: function ($$v) {
              _vm.bookingTimeShow = $$v
            },
            expression: "bookingTimeShow",
          },
        },
        [
          _c("van-datetime-picker", {
            attrs: {
              type: "datetime",
              title: "选择预约时间",
              "min-date": _vm.minBookingTimeDate,
              "columns-order": ["year", "month", "day", "hour", "minute"],
              formatter: _vm.formatter,
            },
            on: {
              cancel: function ($event) {
                _vm.bookingTimeShow = false
              },
              confirm: _vm.bookingTimeConfirm,
            },
            model: {
              value: _vm.bookingTime,
              callback: function ($$v) {
                _vm.bookingTime = $$v
              },
              expression: "bookingTime",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "button_row" },
        [
          _c(
            "van-button",
            {
              staticClass: "button",
              attrs: {
                type: "primary",
                "loading-type": "spinner",
                "loading-text": "提交中...",
              },
              on: { click: _vm.submitOnline },
            },
            [_vm._v("提交")]
          ),
        ],
        1
      ),
      _c("select-project-m", {
        ref: "selectProject",
        attrs: { show: _vm.selectProjectShow, "select-type": "radio" },
        on: {
          "update:show": function ($event) {
            _vm.selectProjectShow = $event
          },
          result: _vm.selectProjectConfim,
        },
      }),
      _vm.config[_vm.type].selectPos
        ? _c("select-position", {
            attrs: {
              "project-code": _vm.form.projectCode,
              show: _vm.selectPositionShow,
            },
            on: {
              "update:show": function ($event) {
                _vm.selectPositionShow = $event
              },
              result: _vm.setPosition,
            },
          })
        : _vm._e(),
      _vm.config[_vm.type].selectProblem
        ? _c("problem-items", {
            ref: "problemItems",
            attrs: {
              show: _vm.problemItemsShow,
              "project-code-prop": _vm.form.projectCode,
            },
            on: {
              "update:show": function ($event) {
                _vm.problemItemsShow = $event
              },
              result: _vm.setProblemList,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "separator_block" }, [
      _c("p", [_vm._v("工单位置")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "separator_block" }, [
      _c("p", [_vm._v("工单内容")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }