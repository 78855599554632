<template v-cloak>
  <div class="type-list-wrapper">
    <div class="type-list" :class="{ 'more-form': item.typeOptions && item.typeOptions.length > 1 }"
      v-for="(item, index) of listData" :key="item.code">
      <template v-if="!item.isHidden">
        <div v-if="$route.query.checkNo" @click="goDetails(item)"
          class="absolute right-10px top-4px z-10">
          <img :src="calcImg(item)" alt="" width="62px" />
        </div>
        <div class="line-wrapper" v-if="listData.length > 1">
          <p class="default-icon" :class="{ error: item.isError }" v-if="setActive(item)"></p>
          <p class="active-icon" v-else></p>
          <p class="line" :class="{
              active: !setActive(item),
              'hide-line': index === listData.length - 1 || orderDetail.model.order_type === 16,
            }"></p>
        </div>
        <div class="type-detail" :class="{ 'no-line': listData.length <= 1 }">
          <template v-if="item.showName">
            <div class="list-title">
              <p class="title" :class="{ error: item.isError }">
                {{ item.name }}
              </p>
              <p class="title-btn" v-if="nodeCode === 'chulizhong' && taskType !== 2 && taskType !== 3 && !detail && !(orderDetail.model.order_type === 16 && item.name === '结果记录')" @click="goProblem(index)">
                问题记录
              </p>
            </div>

            <div class="list-title"></div>
            <p class="desc">
              {{ item.optionSecondTitle }}
            </p>
            <div class="order-photos" v-if="showImgAfterLoad && item.secondTitleAttachments && item.secondTitleAttachments.length > 0">
              <!-- <span v-for="(secondTitleImg, secondTitleImgIndex) of item.secondTitleAttachments" :key="secondTitleImgIndex">{{offlineImageBase[secondTitleImg.url].slice(0, 5)}}</span> -->
              <van-image width="40" height="40" radius="4" class="photo"
                v-for="(secondTitleImg, secondTitleImgIndex) of item.secondTitleAttachments" @click="imgPreview(secondTitleImgIndex, item.secondTitleAttachments)" :key="secondTitleImgIndex"
                :src="isOffline ? secondTitleImg.url : offlineImageBase[secondTitleImg.url] " />
            </div>
          </template>
          <div class="type-conent" v-for="(typeList, typeIndex) of item.typeOptions"
            :key="typeList.type+typeIndex">
            <template v-if="!status">
              <van-field @blur="onlyUpdate(index,typeIndex)" v-model="typeList.val" type="text" placeholder="请输入"
                v-if="typeList.type === 1" class="mt10" :class="{
                  'type-required': typeList.required === 1,
                  mt8: typeIndex > 0,
                }" :disabled = detail />
              <van-field @blur="onlyUpdate(index,typeIndex)" v-model="typeList.val" rows="4" type="textarea" placeholder="请输入"
                show-word-limit v-if="typeList.type === 2" class="textarea mt10 noBorder"
                :class="{
                  'type-required': typeList.required === 1,
                  mt8: typeIndex > 0,
                }" :disabled = detail >
                <template v-slot:right-icon v-if="isOffline && !detail">
                  <micro-text
                    :self-click="true"
                    @click="microClick(index,typeIndex)"
                    @speakRes="speakRes"
                  />
                </template>
              </van-field>
              <PIckerSelect v-if="typeList.type === 3" :selectValue.sync="typeList.val" :detail="detail"
                :selectList="typeList.options" @confirm="changTime(index, typeIndex)" class="mt10"
                :class="{
                  'type-required': typeList.required === 1,
                  mt8: typeIndex > 0,
                }"></PIckerSelect>
              <MultiSelect v-if="typeList.type === 4" :selectValue.sync="typeList.val" :detail="detail"
                :selectList="typeList.options" @confirm="changTime(index, typeIndex)" class="mt10"
                :class="{
                  'type-required': typeList.required === 1,
                  mt8: typeIndex > 0,
                }"></MultiSelect>
              <van-field @blur="onlyUpdate(index,typeIndex)" v-model="typeList.val" type="number" placeholder="请输入数字"
              v-if="typeList.type === 5 && !detail" class="mt10" :class="{
                  'type-required': typeList.required === 1,
                  mt8: typeIndex > 0,
                }" :formatter="floatNum" format-trigger="onBlur"/>
              <van-field  v-model="typeList.val" type="number" placeholder="请输入数字" v-if="typeList.type === 5 && detail" class="mt10 bg-[#f7f8fa]" :disabled = detail />  <!-- 详情时需要置灰不可编辑 -->
              <van-radio-group v-model="typeList.val" direction="horizontal"
                @change="changTime(index, typeIndex, typeList)" v-if="typeList.type === 6"
                class="mt10" :class="{
                  'type-required': typeList.required === 1,
                  mt8: typeIndex > 0,
                }" :disabled = detail>
                <van-radio v-for="item in typeList.options" :key="item.code" :name="item.code" :class="{
                    'type-required': typeList.required === 1,
                    mt8: typeIndex > 0,
                  }">{{ item.name }}
                  <template #icon="props" v-if="!detail">
                    <img class="img-icon" width="22px" height="22px" :src="
                        props.checked
                          ? require('@/asset/radio-active.png')
                          : require('@/asset/radio-default.png')
                      " />
                  </template>
                </van-radio>
              </van-radio-group>
              <!-- 打分 -->
              <van-stepper
                v-model="typeList.val"
                v-if="typeList.type === 10"
                theme="round"
                button-size="22"
                :disabled="detail"
                class="mt-10px mb-10px"
                :max="typeList.singleScore"
                :min="typeList.lowestScore"
                :step="typeList.kjld"
                input-width="60px"
                @change="changTime(index,typeIndex)"
                :decimal-length="1"
              />
              <!-- 事前图片 -->
              <div class="upload-box mt10" v-if="typeList.type === 8" :class="{
                  'type-required': typeList.required === 1,
                  mt8: typeIndex > 0,
                }">
                <common-upload :disabled="detail" :project-name="projectName"
                  :file.sync="typeList.val" @change="changTime(index, typeIndex)"
                  text="事前图片" :order-id="orderId"></common-upload>
              </div>

              <div class="upload-box mt10" v-if="typeList.type === 7" :class="{
                  'type-required': typeList.required === 1,
                  mt8: typeIndex > 0,
                }">
                <common-upload :order-id="orderId" :disabled="detail" :file.sync="typeList.val" :project-name="projectName"
                @change="changTime(index, typeIndex)">
                </common-upload>
              </div>

              <!-- 事后图片 -->
              <div class="upload-box mt10" v-if="typeList.type === 9" :class="{
                  'type-required': typeList.required === 1,
                  mt8: typeIndex > 0,
                }">
                <common-upload :order-id="orderId" :disabled="detail" :project-name="projectName"
                  :file.sync="typeList.val" @change="changTime(index, typeIndex)"
                  text="事后图片"></common-upload>
              </div>
            </template>
            <!-- 已完成 -->
            <template v-else>
              <!-- type不是2,3,4,6,7,8,9的时候显示字 -->
              <p class="name" v-if="![2,3,4,6,7,8,9].includes(typeList.type)">
                {{ typeList.val }}
              </p>
              <p class="name" v-if="typeList.type === 6 || typeList.type === 3"
                v-html="formatArrKey(typeList.options, typeList.val)"></p>
              <p class="name" v-if="typeList.type === 2" v-html="typeList.val"></p>
              <div class="order-photos" v-if="[7,8,9].includes(typeList.type)">
                <OfflinePreviewImg :title="getTitle(typeList.type)"
                :list="typeList.val" />
              </div>
              <div class="tag-wrapper" v-if="typeList.type === 4">
                <div class="tag-list" v-for="(tagItem, tagIndex) of typeList.val" :key="tagIndex">
                  {{ formatArrKey(typeList.options, tagItem) }}
                </div>
              </div>
            </template>
          </div>
        </div>
      </template>

    </div>
  </div>
</template>

<script type='text/ecmascript-6'>
import PIckerSelect from './PIckerSelect'
import MultiSelect from './MultiSelect'
import { ImagePreview } from 'vant'
import { setPatroItemOfflineApiHandler } from '@/plugins/offlineMixin/offlineApiHandlers/PatroListOfflineApiHandler'
import OfflinePreviewImg from './OfflinePreviewImg.vue'
import {
  OssConversionBase64
} from '@/plugins/offlineMixin/utils/offlineImgTool'
import { cloneDeep } from 'lodash'

export default {
  name: 'TypeList',
  components: { PIckerSelect, MultiSelect, OfflinePreviewImg },
  props: {
    typeData: {
      type: Array,
      required: true,
      default: () => []
    },
    status: {
      type: Boolean,
      default: false
    },
    taskType: {
      type: Number,
      default: 0
    },
    nodeCode: {
      type: String,
      default: ''
    },
    detail: { // 判断是否禁用 详情时禁用 除处理中状态 外均为详情不可编辑状态
      type: Boolean,
      default: false
    },
    projectName: {
      type: String,
      default: ''
    },
    orderId: {
      type: Number,
      default: () => null
    },
    orderDetail: {
      type: Object,
      default: () => {}
    },
    infoData: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      listData: [],
      showPicker: false,
      offLine: true,
      delayTime: 0,
      microIndex: 0,
      microTypeIndex: 0,
      disabled: true,
      offlineImageBase: {},
      showImgAfterLoad: false
    }
  },
  computed: {
    isOffline () {
      return this.$store.getters.getIsOffline
    }
  },
  async mounted () {
    this.listData = cloneDeep(this.typeData)
    console.log('this.listData: ', this.listData)
    // 处理多选回显数据库值得处理
    for (let optionIndex = 0; optionIndex < this.listData.length; optionIndex++) {
      const item = this.listData[optionIndex]
      if (item.typeOptions) {
        item.typeOptions.forEach((list) => {
          if (list.type === 4 && typeof list.val === 'string') {
            if (list.val.includes(',')) {
              list.val = list.val.split(',')
            } else {
              list.val = [list.val]
            }
          }
          // 远程监控修改显示的文字 + 合格时不显示是否已整改
          if (this.orderDetail.model.order_type === 16 && list.type === 6 && list.options) {
            list.options = this.lodash.reverse(list.options)
            if (optionIndex === 0) {
              list.options.forEach(op => {
                if (op.code === 'code0') op.name = '不合格'
                if (op.code === 'code1') op.name = '合格'
              })
              if (!list.val) {
                list.val = 'code1'
                list.operTime = this.$moment.timestampToYMDHms(new Date().getTime())
              }

              this.listData[1].isHidden = list.val === 'code1'
            }
            if (optionIndex === 1) {
              list.options.forEach(op => {
                if (op.code === 'code0') op.name = '未整改'
                if (op.code === 'code1') op.name = '已整改'
              })
            }
          }
        })
      }
      if (!this.isOffline) {
        // 标题示例图
        if (item.secondTitleAttachments && item.secondTitleAttachments.length > 0) {
          for (const urlItem of item.secondTitleAttachments) {
            this.offlineImageBase[urlItem.url] = await OssConversionBase64(urlItem.url)
          }
        }
      }
    }
    this.$emit('update:typeData', this.listData)
    console.log(Object.keys(this.offlineImageBase), 'this.offlineImageBase')
    setTimeout(() => {
      this.showImgAfterLoad = true // 加载完后再显示
    })
  },
  methods: {
    calcImg (item) {
      // 返回合格印章或不合格印章
      if (item.checkResult === null) {
        return ''
      } else {
        return item.checkResult
          ? require('@/asset/qualifi.png')
          : require('@/asset/unqualifi.png')
      }
    },
    goDetails (item) {
      // 打开抽查结果详情
      if (!this.$route.query.checkNo) {
        return
      }
      // 发射事件，外面接收打开窗口
      this.$emit('clickImg', item)
    },
    onConfirm () {},
    onCancel () {},
    onChange () {},
    // 设置线的active效果
    setActive (item) {
      let status = false
      let required = false
      // 获取该检查项是否有必填项
      for (const list of item.typeOptions) {
        if (list.required === 1) {
          required = true
          break
        }
      }
      for (const list of item.typeOptions) {
        // 如果有必填项必须填完否则err
        if (required) {
          if (list.required === 1 && (!list.val || list.val.length === 0)) {
            status = false
            break
          } else {
            status = true
          }
        } else {
          // 如果没有必填项则做一个就active
          if (list.val) {
            status = true
          }
        }
      }
      if (status) {
        item.isError = false
      }
      return !status
    },
    formatArrKey (list, key) {
      let text = key
      list.forEach((item) => {
        if (item.code === key) {
          text = item.name
        }
      })
      return text
    },
    /* 图片查看器 */
    imgPreview (index, attachmentList) {
      const imagesGroup = []
      attachmentList &&
        attachmentList.forEach((item) => {
          // imagesGroup.push(typeof item === 'string' ? this.offlineImageBase[item] : (this.isOffline ? (item.content || item.url) : this.offlineImageBase[item.url]))
          imagesGroup.push(this.isOffline ? (item.content || item.url) : this.offlineImageBase[item.url])
        })
      ImagePreview({
        images: imagesGroup,
        startPosition: index
      })
    },
    onlyUpdate (index, typeIndex) {
      this.listData[index].typeOptions[typeIndex].operTime =
        this.$moment.timestampToYMDHms(new Date().getTime())
      this.$emit('update:typeData', this.listData)
      if (this.listData.length === 1) {
        setTimeout(() => {
          this.setActive(this.listData[0])
        }, 500)
      }
    },
    async changTime (index, typeIndex, item) {
      console.log(index, typeIndex, item, item.val, '--------')
      this.listData[index].typeOptions[typeIndex].operTime = this.$moment.timestampToYMDHms(new Date().getTime())
      this.$emit('update:typeData', this.listData)
      if (this.listData.length === 1) {
        setTimeout(() => {
          this.setActive(this.listData[0])
        }, 500)
      }
      // 如果是单选并且值为异常的code跳转到问题记录
      // this.$emit('change')
      if (this.orderDetail.model.order_type === 16 && this.orderDetail.model.template_node_code === 'chulizhong') {
        if (item && item.type === 6 && index === 0) {
          const listData = this.lodash.cloneDeep(this.listData)
          listData[1].isHidden = item.val === 'code1' // code0 不合格 显示 code1 合格 隐藏
          this.listData = listData
          console.log(this.listData[1].isHidden, 'this.listData[1].isHidden')
          if (item.val === 'code0' && this.listData[index].name === '结果记录') {
            // 不合格 需要抓取新的实时图片9
            this.$emit('capture')
            return
          }
        }
      }
      if (item && item.type === 6 && item.val === 'code0') {
        this.goProblem(index)
      }
      this.$apiOffline({
        forceOffline: true,
        params: { orderDetail: this.orderDetail, patroItem: this.infoData },
        offlineApiHandlers: setPatroItemOfflineApiHandler
      })
    },
    goProblem (index) {
      // 问题记录也需要把照片更新到执行项
      this.$emit('handleProblem', index) // 异常单选问题记录处理
    },
    getTitle (type) {
      if (type === 8) {
        return '事前'
      }
      if (type === 9) {
        return '事后'
      }
    },
    microClick (index, typeIndex) {
      this.microIndex = index
      this.microTypeIndex = typeIndex
    },
    speakRes (text) {
      this.listData[this.microIndex].typeOptions[this.microTypeIndex].val += text
    },
    floatNum (val) {
      return val ? (val * 1).toFixed(2) : val
    }
  }
}
</script>
<style  lang='less' scoped>
.type-list-wrapper {
  // overflow: hidden;
  padding-bottom: 20px;
  .mt10 {
    margin-top: 10px;
  }
  .mt8 {
    margin-top: 8px !important;
  }
  p {
    margin: 0;
  }
  .type-required {
    position: relative;
    &::before {
      background: url("~@/asset/icon_star_red@2x.png") no-repeat;
      background-size: 100%;
      width: 8px;
      height: 8px;
      display: inline-block;
      content: "";
      position: absolute;
      top: 4px;
      left: -12px;
      z-index: 0;
    }
  }
  .type-list {
    position: relative;
    display: flex;
    .line-wrapper {
      position: relative;
      top: 28px;
      width: 1.5px;
      min-height: 107px;
      padding-left: 11px;
      .round {
        display: flex;
        justify-items: center;
        width: 100%;
        min-height: 8px;
      }
      .default-icon {
        width: 8px;
        height: 8px;
        border-radius: 8px;
        background-color: #e5e5e5;
        margin-left: -3px;
        position: relative;
        z-index: 1;
        &.error {
          position: relative;
          left: -7px;
          width: 22px;
          height: 22px;
          background: url("~@/asset/type-error-icon.png") no-repeat;
          background-size: 100% 100%;
        }
      }
      .active-icon {
        position: relative;
        left: -10px;
        width: 22px;
        height: 22px;
        top: -8px;
        background: url("~@/asset/type-line-icon.png") no-repeat;
        background-size: 100% 100%;
        z-index: 1;
      }
      .line {
        position: absolute;
        top: 8px;
        width: 1.5px;
        // height: 134px;
        height: 100%;
        background-color: #e5e5e5;
        &.active {
          background-color: #3366fe;
        }
        &.hide-line {
          background-color: #fff;
        }
      }
    }
  }
  .type-detail {
    padding-left: 29px;
    padding-top: 20px;
    flex: 1;
    .list-title {
      display: flex;
      justify-content: space-between;
      .title-btn {
        flex: 0 0 64px;
        flex-shrink: 0;
        height: 24px;
        font-size: 13px;
        font-weight: 500;
        color: #ffffff;
        background-color: #fd8d37;
        border-radius: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    &.no-line {
      padding-left: 0;
      padding-top: 15px;
    }
    .van-field {
      padding: 0;
      /deep/ .van-field__body {
        input,
        textarea {
          &::-webkit-input-placeholder {
            color: #999;
            font-size: 16px;
          }
          :-moz-placeholder {
            /* Firefox 18- */
            color: #999;
            font-size: 16px;
          }
          ::-moz-placeholder {
            /* Firefox 19+ */
            color: #999;
            font-size: 16px;
          }
          &:-ms-input-placeholder {
            color: #999;
            font-size: 16px;
          }
        }
      }

      /deep/ .van-field__control {
        height: 40px;
        border: 0.5px solid #c5c5c5;
        border-radius: 2px;
        padding: 5px 12px;
      }
      &.textarea {
        /deep/ .van-field__control {
          height: auto;
        }
      }
    }
    /deep/ .van-icon-right-icon {
      width: 16px;
      height: 16px;
      background: url("~@/asset/right-icon.png") no-repeat;
      background-size: 100% 100%;
    }
    .title {
      font-size: 17px;
      color: #222222;
      line-height: 24px;
      &.error {
        color: #f44a45;
      }
    }
    .desc {
      margin-top: 2px;
      font-size: 16px;
      color: #666666;
      line-height: 24px;
      margin-right: 64px;
    }
    .name {
      font-size: 16px;
      color: #222;
      line-height: 22px;
    }

    .tag-wrapper {
      display: flex;
      width: calc(100% + 10px);
      flex-wrap: wrap;
      .tag-list {
        // width: 52px;
        padding: 0 12px;
        height: 28px;
        background: #f5f5f5;
        border-radius: 4px;
        margin-right: 10px;
        font-size: 14px;
        color: #222;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
      }
    }
    .order-photos {
      width: calc(100% + 8px);
      margin-top: 10px;
      .van-image {
        margin-right: 8px;
      }
    }
    .van-radio-group {
      &::before {
        top: 6px;
      }
      /deep/ .van-radio__icon {
        height: 22px;
      }
      /deep/ .van-radio__label {
        margin-left: 8px;
        font-size: 16px;
        color: #222;
        line-height: 22px;
      }
      margin-top: 6px;
      .van-radio {
        margin-right: 32px;
      }
    }
    .upload-box {
      margin-top: 4px;
      box-sizing: border-box;
      min-height: 110px;
      border-radius: 2px;
      border: 0.5px solid #c5c5c5;
      padding: 15px 12px;
      /deep/ .form-upload {
        &::after {
          border: none;
        }
        .van-cell__title {
          margin-bottom: 2px;
          font-size: 14px;
          line-height: 20px;
          color: #8b8f9a;
        }
      }
    }
    .van-cell {
      overflow: visible;
    }
  }
  /deep/ .noBorder .van-field__control{
    border: none !important;
  }
  /deep/ .noBorder{
    border: 0.5px solid #c5c5c5;
  }
}
</style>
