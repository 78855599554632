<template>
  <!-- 此页面为新增问题 type 用来区分创建的工单类型  -->
  <div class="create-work">
    <my-nav title="问题记录" :self-back="true" @back="goBack">
      <ScanIcon
        slot="right"
        v-if="config[type].showScan"
        :code-type="config[type].codeType"
        @scanSuccess="scanSuccess"
      />
    </my-nav>
    <div class="separator_block" />
    <div class="page_des">
      <p>{{ config[type].head }}</p>
      <div>{{ config[type].desc }}</div>
    </div>
    <div class="separator_block">
      <p>工单位置</p>
    </div>
    <van-field
      v-if="config[type].selectProject"
      readonly
      clickable
      name="projectName"
      :value="form.projectName"
      label="所在项目"
      class="form-input"
      required
      right-icon="arrow"
      input-align="right"
      placeholder="请选择"
      @click="() => (selectProjectShow = true)"
    />
    <van-field
      v-if="config[type].inputMobile"
      name="reportUserPhone"
      v-model="form.reportUserPhone"
      label="联系方式"
      class="form-input"
      required
      right-icon=""
      input-align="right"
      placeholder="请输入联系方式"
    />
    <!-- 选择报事客户 -->
    <ReportCustomerSelect
      :house-name="form.reportHouseName"
      confirm-type='member'
      label="报事客户"
      @confirmRouter="reportCustomerSelectRouterConfirm"
      @confirm="reportCustomerSelectConfirm"
      v-if="config[type].selectReportCustomer"
      ref="ReportCustomerSelect_1"
    ></ReportCustomerSelect>
    <van-field
      v-if="config[type].selectReportCustomer"
      name="reportUserName"
      v-model="form.reportUserName"
      label="报事人姓名"
      class="form-input"
      required
      right-icon=""
      input-align="right"
      placeholder="请输入"
    />
      <van-field
      v-if="config[type].selectReportCustomer"
      clickable
      name="reportUserPhone"
      v-model="form.reportUserPhone"
      label="报事人电话"
      class="form-input"
      required
      right-icon=""
      input-align="right"
      placeholder="请输入"
    />
    <!-- 选择位置（房间） -->
    <ReportCustomerSelect
      :house-name="form.address"
      :house="chooseHouse"
      confirm-type='house'
      @confirmRouter="reportCustomerSelectRouterConfirm"
      :router-list="reportCustomerRouterList"
      label="所在位置"
      @confirm="reportHouseSelectConfirm"
      v-if="config[type].selectHouse"
      ref="ReportCustomerSelect_2"
    ></ReportCustomerSelect>
    <!-- 选择设施设备 -->
    <MyFieldOfEquipmentSelect
      v-if="config[type].selectEquipment"
      :disabled-click="!form.projectCode"
      :address.sync="form.problemRecordList.address"
      :address-id.sync="form.problemRecordList.addressId"
      :project-code="form.projectCode"
      :categoryCode.sync="form.categoryCode"
      :reallyType.sync="form.problemRecordList.addressType"
      @confirm="myFieldOfEquipmentSelect"
      required
      route-name="ProblemRecord"
    />
    <!-- 所在位置  公区 选择点位 -->
    <van-field
      v-if="config[type].selectPos"
      readonly
      clickable
      @click="selectPosition"
      name="address"
      :value="form.problemRecordList.address"
      label="所在位置"
      class="form-input"
      required
      right-icon="arrow"
      input-align="right"
      placeholder="扫码或选择"
    />
    <div class="separator_block">
      <p>工单内容</p>
    </div>
    <!-- 主要部件故障原因 -->
    <MyFieldOfMalfunctionReasonSelect
      v-if="config[type].selectMalfunctionReason"
      :disabled-click="!form.problemRecordList.addressId"
      route-name="ProblemRecord"
      @confirm="malfunctionReasonSelectConfirm"
      :pointCode="form.problemRecordList.addressId"
      :categoryCode="form.categoryCode"
      :problem-list="form.problemRecordList.problemList"
      :reallyType="form.problemRecordList.addressType"
      :guide="true"
      required
    />
    <van-field
      v-if="config[type].selectProblem"
      readonly
      clickable
      label="问题项"
      label-width="150px"
      class="form-input"
      required
      @click="selectProblemItem"
      right-icon="arrow"
      input-align="right"
      placeholder="请选择"
    />
    <div
      class="problem-list"
      v-if="config[type].selectProblem && form.problemRecordList.problemList.length > 0"
    >
      <div
        class="item"
        v-for="(item, index) of this.form.problemRecordList.problemList"
        :key="index"
      >
        <p class="problem-title">
          {{
            `${item.parentName ? item.parentName + "/" : ""}${
              item.problemName || item.mainPartName
            }`
          }}
        </p>
        <p class="problem-info" v-if="item.questLabel.length > 0">
          <span v-for="(item2, index2) of item.questLabel" :key="index2">
            <i v-if="index2 !== 0">|</i
            >{{ item2.questLabelName || item2 }}</span
          >
        </p>
        <p
          class="problem-person"
          v-if="item.rectifyPeopls && item.rectifyPeopls.name"
        >
          整改人：{{ item.rectifyPeopls.name }}
        </p>
      </div>
    </div>
    <!--
            在记录问题时可自定义选择问题分级
          -->
    <van-field
      readonly
      name="grade"
      :value="gradeValue"
      label="问题分级"
      class="form-input"
      required
      input-align="right"
      placeholder=""
      v-if="config[type].selectProblem"
    />
    <van-field
      name="remark"
      v-model="form.problemRecordList.remark"
      label="问题描述"
      rows="3"
      class="form-textarea"
      type="textarea"
      placeholder="请输入问题描述"
    >
      <template v-slot:right-icon v-if="isOffline">
        <micro-text
          @speakRes="speakRes"
        />
      </template>
    </van-field>
    <div style="padding: 0 16px 16px; background: #fff">
      <common-upload
          :file.sync="form.problemRecordList.attachmentList"
          :project-name="form.projectName"
          required
          ref="offlineUpload"
          :enable-album="true"
          :enable-edit="true"
          :oss-params="ossParams"
        ></common-upload>
    </div>
    <van-field
      readonly
      name="grade"
      :value="form.bookingTime"
      label="预约时间"
      class="form-input"
      @click="bookingTimeShow = true"
      input-align="right"
      placeholder="非必需，客户希望上门时间"
      v-if="config[type].selectBookTime"
    />
    <van-popup v-model="bookingTimeShow" position="bottom" safe-area-inset-bottom>
      <van-datetime-picker
        v-model="bookingTime"
        type="datetime"
        title="选择预约时间"
        :min-date="minBookingTimeDate"
        :columns-order="['year','month', 'day','hour','minute']"
        :formatter="formatter"
        @cancel="bookingTimeShow = false"
        @confirm="bookingTimeConfirm"
      />
    </van-popup>
    <div class="button_row">
      <van-button
        class="button"
        type="primary"
        @click="submitOnline"
        loading-type="spinner"
        loading-text="提交中..."
        >提交</van-button
      >
    </div>
    <select-project-m
      :show.sync="selectProjectShow"
      @result="selectProjectConfim"
      select-type="radio"
      ref="selectProject"
    ></select-project-m>
    <!-- 选择位置(新建公区工单) -->
    <select-position
      v-if="config[type].selectPos"
      :project-code="form.projectCode"
      :show.sync="selectPositionShow"
      @result="setPosition"
    ></select-position>
    <!-- 选择问题项(新建设备工单) -->
    <problem-items
      v-if="config[type].selectProblem"
      :show.sync="problemItemsShow"
      :project-code-prop="form.projectCode"
      @result="setProblemList"
      ref="problemItems"
    ></problem-items>
  </div>
</template>

<script>
import SelectPosition from '@/pages/problemRecord/components/SelectPosition'
import selectProjectM from '@/components/selectProjectM'
import ProblemItems from '@/pages/problemRecord/components/ProblemItems'
import MyFieldOfEquipmentSelect from '@/components/MyFieldOfEquipmentSelect/index'
import ReportCustomerSelect from '@/components/ReportCustomerSelect/index'
import MyFieldOfMalfunctionReasonSelect from '@/components/MyFieldOfMalfunctionReasonSelect/index'

import api from '@/api/index'
import ScanIcon from '@/components/ScanIcon'
import moment from 'moment'
import { getOssParams } from '@/plugins/offlineMixin/utils/offlineImgTool'
import {
  _fmWrapProblemList
} from '@/plugins/offlineMixin/offlineApiHandlers/PatroListOfflineApiHandler'
import ProblemRecordCreateOfflineApiHandlers from '@/plugins/offlineMixin/offlineApiHandlers/ProblemRecordCreateOfflineApiHandlers'
import { getCreateProblemProjectInfoHandler, setCreateProblemProjectInfoHandler } from '@/plugins/offlineMixin/offlineApiHandlers/SelectProjectOfflineApi.js'
import { mapState } from 'vuex'

export default {
  name: 'CreateProblemRecord',
  components: {
    ProblemItems,
    ScanIcon,
    MyFieldOfMalfunctionReasonSelect,
    MyFieldOfEquipmentSelect,
    selectProjectM,
    SelectPosition,
    ReportCustomerSelect
  },
  data () {
    return {
      bookingTime: '',
      minBookingTimeDate: new Date(),
      bookingTimeShow: false,
      type: 'ZC_SB', // 新建工单的类型
      config: {
        // 区分首页点的是公区问题还是设备问题
        KH_FJ: { // 客户房间
          dataSource: '报事工单',
          orderType: 5,
          head: '客户房间',
          desc: '用于受理客户反馈记录所在房间的问题，并进行相关流转处理及跟踪。',
          showScan: false,
          selectProject: false, // 选择项目
          selectEquipment: false, // 选择设备
          selectHouse: true, // 选择位置（选择房间）
          selectProblem: true, // 选择问题项
          selectBookTime: true, // 选择预约时间
          selectReportCustomer: true, // 选择报事客户
          createApi: api.createCustomReportEventOrder, // 创建工单接口路径
          acceptChannel: '当面'
        },
        KH_GQ: { // 客户公区
          dataSource: '报事工单',
          orderType: 4,
          head: '客户公区',
          desc: '用于受理客户反馈记录公共区域的问题，并进行相关流转处理及跟踪。',
          showScan: true,
          codeType: 'DW_code', // 公区问题只能扫点位二维码,
          selectProject: false, // 选择项目
          selectEquipment: false, // 选择设备
          selectPos: true, // 选择位置 (选择点位)
          selectProblem: true, // 选择问题项
          selectReportCustomer: true, // 选择报事客户
          createApi: api.createCustomReportEventOrder, // 创建工单接口路径
          acceptChannel: '当面'
        },
        ZC_FJ: { // 自查房间
          dataSource: '报事工单',
          orderType: 2,
          head: '自查房间',
          desc: '用于公司内部员工自查时记录所在房间的问题，并进行相关流转处理及跟踪。',
          showScan: false,
          codeType: 'SB_code', // 设备问题只能扫设备二维码；
          selectProject: false, // 选择项目
          selectEquipment: false, // 选择设备
          selectHouse: true, // 选择位置（选择房间）
          selectProblem: true, // 选择问题项
          inputMobile: true,
          createApi: api.createSelfReportEventOrder // 创建工单接口路径
        },
        ZC_GQ: { // 自查公区
          dataSource: '报事工单',
          orderType: 1,
          head: '自查公区',
          desc: '用于公司内部员工自查时记录公共区域的问题，并进行相关流转处理及跟踪。',
          showScan: true,
          codeType: 'DW_code', // 公区问题只能扫点位二维码
          selectProject: true, // 选择项目
          selectEquipment: false, // 选择设备
          selectPos: true, // 选择位置 (选择点位)
          selectProblem: true, // 选择问题项
          createApi: api.pinkongProblemCreate // 创建工单接口路径
        },
        ZC_SB: { // 自查设施设备
          dataSource: '设备管理',
          orderType: 3,
          head: '自查设施设备',
          desc: '用于公司内部员工自查时记录设施设备的问题，并进行相关流转处理及跟踪。',
          showScan: true,
          codeType: 'SB_code', // 公区问题只能扫点位二维码
          selectProject: true, // 选择项目
          selectEquipment: true, // 选择设备
          selectMalfunctionReason: true, // 选择主要部件故障原因
          selectProblem: false, // 选择问题项
          createApi: api.pinkongProblemCreate // 创建工单接口路径
        }
      },
      form: {
        projectCode: '',
        projectName: '',
        dataSource: '',
        orderType: 3,
        categoryCode: undefined,
        problemRecordList: {
          address: undefined,
          addressId: undefined,
          attachmentList: [],
          problemList: [],
          remark: ''
        },
        grade: '',
        bookingTime: '',
        reportHouseCode: '', // 报事人房间编号(物业编码)
        reportHouseName: '', // 报事人房间名称(房屋编号)
        reportUserName: '', // 报事人姓名
        reportUserPhone: '' // 报事人电话
      },
      selectProjectShow: false,
      selectPositionShow: false,
      problemItemsShow: false, // 选择问题项显示隐藏
      gradeValue: '', // 问题分级field值,
      paramsCopy: null, // 当前页面是缓存，把要提交的参数保存起来
      ossParams: {}, // 阿里云oss参数
      reportCustomerRouterList: [],
      chooseHouse: null
    }
  },
  beforeRouteEnter (to, from, next) {
    console.log('to, from,: ', to, from)
    next(async (vm) => {
      if (
        from.name === 'processingList' &&
        vm.isBackFromProcessingListByIgnore
      ) {
        console.log('vm.closeOrderNos: ', vm.closeOrderNos)
        console.log('vm.paramsCopy: ', vm.paramsCopy)
        if (vm.closeOrderNos && vm.closeOrderNos.length > 0 && vm.paramsCopy.problemRecordList[0]) {
          vm.paramsCopy.problemRecordList[0].close = true
          vm.paramsCopy.problemRecordList[0].closeOrderNos = vm.closeOrderNos
        }
        vm.paramsCopy && vm.submitOrderInfo(vm.paramsCopy)
      }
      if (from.name === 'Dashboard') {
        // 返回主页时，清空项目选择器的搜索框
        // vm.$refs.selectProject && vm.$refs.selectProject._clearSearchValue()
        // 返回主页时，清空图片
        vm.$refs.offlineUpload && vm.$refs.offlineUpload.clearAllFile()
        // 清空问题项选择的项目
        vm.$refs.problemItems && vm.$refs.problemItems.clearAllCheckProblem()
        vm.$refs.ReportCustomerSelect_1 && vm.$refs.ReportCustomerSelect_1.clearInit()
        vm.$refs.ReportCustomerSelect_2 && vm.$refs.ReportCustomerSelect_2.clearInit()
        // 从主页进来时，清空data数据
        Object.assign(vm.$data, vm.$options.data())
        // 从主页进来时获取缓存项目
        // await vm._getProjectInfoCache()
        const { type } = vm.$route.params
        console.log('type: ', type)
        console.log('vm.userInfo: ', vm.userInfo)
        if (type === 'ZC_FJ' && vm.userInfo) { // 自查房间，要塞入当前用户信息
          vm.form.reportUserName = vm.userInfo.username
          vm.form.reportUserPhone = vm.userInfo.mobile
        }
        vm.type = type
      }
      vm.$store.commit('setCloseOrderNos', [])
      vm.$store.commit('setIsBackFromProcessingListByIgnore', false)
      vm.ossParams = await getOssParams()
    })
  },
  beforeRouteLeave (to, from, next) {
    if (to.name === 'DashBoard') {
      this.paramsCopy = null
    }
    this.$store.commit('setCloseOrderNos', [])
    this.$store.commit('setIsBackFromProcessingListByIgnore', false)
    next()
  },
  computed: {
    ...mapState({
      isBackFromProcessingListByIgnore: (state) =>
        state.pageFormCache.isBackFromProcessingListByIgnore,
      closeOrderNos: (state) => state.pageFormCache.closeOrderNos,
      userInfo: state => state.user.userInfo
    }),
    isOffline () {
      return this.$store.getters.getIsOffline
    },
    isZG () {
      return this.$store.getters.getIsZG
    },
    isCJ () {
      return this.$store.getters.getIsCJ
    }
  },
  methods: {
    // 报事人选择
    reportCustomerSelectConfirm (member) {
      console.log('member: ', member)
      this.chooseHouse = member
      this.form.reportHouseName = member.houseName
      this.form.reportHouseCode = member.houseId
      this.form.reportUserName = member.memberName
      this.form.reportUserPhone = member.linkmanMobile
      this.form.projectCode = member.projectCode
      this.form.projectName = member.projectName
      this.form.buildNo = member.buildNo
      this.form.buildName = member.buildName
      if (this.type === 'KH_FJ') { // 如果当前是客户房间，把报事人房间信息带给位置
        this.form.address = member.houseName
        this.form.addressId = member.houseId
        this.form.problemRecordList.address = member.houseName
        this.form.problemRecordList.addressId = member.houseId
      }
      if (this.type === 'KH_GQ') { // 客户公区 清空所选位置
        this.form.address = ''
        this.form.addressId = ''
        this.form.problemRecordList.address = ''
        this.form.problemRecordList.addressId = ''
      }
      this.form.problemRecordList.problemList = [] // 重新选择报事人后要清空问题项
      this.form.grade = ''
      this.gradeValue = ''
    },

    reportCustomerSelectRouterConfirm (routerList) {
      console.log('routerList: ', routerList)
      this.reportCustomerRouterList = routerList
    },
    // 所在位置（房间）选择
    reportHouseSelectConfirm (house) {
      console.log('house: ', house)
      this.form.address = house.houseName
      this.form.addressId = house.houseId
      this.form.problemRecordList.address = house.houseName
      this.form.problemRecordList.addressId = house.houseId
      this.form.projectCode = house.projectCode
      this.form.projectName = house.projectName
      this.form.buildNo = house.buildNo
      this.form.buildName = house.buildName
      this.form.problemRecordList.problemList = [] // 重新选择报事人后要清空问题项
      this.form.grade = ''
      this.gradeValue = ''
    },
    formatter (type, val) {
      if (type === 'year') {
        return val + '年'
      }
      if (type === 'month') {
        return val + '月'
      }
      if (type === 'day') {
        return val + '日'
      }
      if (type === 'hour') {
        return val + '时'
      }
      if (type === 'minute') {
        return val + '分'
      }
      return val
    },
    bookingTimeConfirm () {
      this.bookingTimeShow = false
      this.form.bookingTime = moment(this.bookingTime).format('yyyy-MM-DD HH:mm')
      console.log('this.form.bookingTime: ', this.form.bookingTime)
      console.log('this.bookingTime: ', this.bookingTime)
    },
    // 获取本地数据
    async _getProjectInfoCache () {
      try {
        // 拿本地存的项目信息
        const projectInfo = await getCreateProblemProjectInfoHandler()
        console.log('projectInfo _getProjectInfoCache: ', projectInfo)
        // 赋值给查询参数
        if (projectInfo && projectInfo.checkList && projectInfo.checkList.length > 0) {
          if (this.$refs.selectProject) {
            this.$refs.selectProject.setInitData(this.lodash.cloneDeep(projectInfo))
            this.$refs.selectProject.submit(false)
          }
        }
      } catch (error) {
        console.error('_getProjectInfoCache error: ', error)
      }
    },
    /* 问题项选择确认 */
    setProblemList (list) {
      console.log('list: ', list)
      this.form.problemRecordList.problemList = list
      this.changeGrade(list)
    },
    // 下面的问题项是和项目关联的，所以选择位置后不用清空问题项
    setPosition (item, parent) {
      console.log('item, parent: ', item, parent)
      this.form.problemRecordList.address = parent + '/' + item.name
      this.form.problemRecordList.addressId = item.code
    },
    // 选择所在位置-自查公区
    selectPosition () {
      if (!this.form.projectCode) {
        this.$toast('请先选择所在项目')
        return
      }
      this.selectPositionShow = true
    },
    selectProblemItem () {
      if (!this.form.problemRecordList.address) {
        this.$toast('请先选择所在位置')
        return
      }
      this.problemItemsShow = true
    },
    myFieldOfEquipmentSelect () {
      // 选择设施设备之后清空主要部件故障原因
      this.form.problemRecordList.problemList = []
    },
    scanSuccess (res) {
      console.log('res: ', res)
      if (!res || !res.length) return
      const scanInfo = res[0]
      this.form.projectCode = scanInfo.projectCode
      this.form.projectName = scanInfo.projectName
      if (!scanInfo.pointTreePointResps || !scanInfo.pointTreePointResps.length) {
        return
      }
      this.form.problemRecordList.addressId = scanInfo.pointTreePointResps[0].code
      this.form.problemRecordList.addressType = scanInfo.pointTreePointResps[0].type
      this.form.problemRecordList.address = `${scanInfo.name}/${scanInfo.pointTreePointResps[0].name}`
      this.form.categoryCode = scanInfo.pointTreePointResps[0].categoryCode // 主要部件原因过滤字段
      this.form.problemRecordList.problemList = []
    },
    async selectProjectConfim (projectInfo) {
      if (
        projectInfo &&
        this.form.projectCode === projectInfo.checkList[0].deptCode
      ) {
        return
      }
      // 重置form
      this.form = this.lodash.cloneDeep(this.$options.data().form)
      // 图片需要清空
      this.$refs.offlineUpload.clearAllFile()
      const { checkList } = projectInfo
      if (!checkList || checkList.length === 0) {
        this.form.projectCode = ''
        this.form.projectName = ''
        return
      }
      this.form.projectCode = checkList[0].deptCode
      this.form.projectName = checkList[0].deptName
      console.log(this.form)
      await setCreateProblemProjectInfoHandler({ // 选择后保存到手机
        params: projectInfo
      })
    },
    // 手动创建工单只有在线才能创建
    async submitOnline () {
      if (!this.isOffline) {
        this.$EB.$emit(this.$EBT.NO_NETWORK_WARN)
        return
      }
      const form = this.lodash.cloneDeep(this.form)
      if (!form.projectCode) {
        this.$toast('请选择所在项目')
        return
      }
      if (!form.problemRecordList.addressId) {
        const type = (this.config[this.type].selectPos || this.config[this.type].selectHouse) ? '所在位置' : '设施设备'
        this.$toast(`请选择${type}`)
        return
      }
      if (form.problemRecordList.problemList.length === 0) {
        const type = this.config[this.type].selectProblem ? '问题项' : '主要部件故障原因'
        this.$toast(`请选择${type}`)
        return
      }
      if (form.problemRecordList.attachmentList.length === 0) {
        this.$toast('请上传图片')
        return
      }
      // 组装上传参数
      // 添加dataSource
      form.dataSource = this.config[this.type].dataSource
      this.config[this.type].acceptChannel && (form.acceptChannel = this.config[this.type].acceptChannel)
      // 添加orderType
      form.orderType = this.config[this.type].orderType
      if (this.type === 'ZC_SB' && this.isCJ) {
        form.orderNature = 6 // 意为承接查验创建的工单性质为6
        form.dataSource = '承接查验'
      }
      const { problemRecordList, ...moreVal } = form
      const problemRecordListCopy = this.lodash.cloneDeep(problemRecordList)
      problemRecordListCopy.grade = form.grade
      const nanoidRandom = Math.ceil(Math.random() * 10000)
      // 选择问题项的类型
      if (this.config[this.type].selectProblem) {
        // 品控提交需要对参数进行处理
        problemRecordListCopy.id = nanoidRandom
        problemRecordListCopy.addressType = 1
        problemRecordListCopy.createTime = moment().format(
          'YYYY-MM-DD HH:mm:ss'
        )
        console.log('problemRecordListCopy.problemList: ', this.lodash.cloneDeep(problemRecordListCopy.problemList))
        problemRecordListCopy.problemList.forEach((item) => {
          if (item.questLabel && Array.isArray(item.questLabel)) {
            item.questLabel = JSON.stringify(item.questLabel)
          } else {
            item.questLabel = JSON.stringify([])
          }
          item.rectifyPeopls =
            Object.keys(item.rectifyPeopls).length > 0
              ? JSON.stringify(item.rectifyPeopls)
              : undefined
        })
      } else {
        // 自查设施设备创建问题工单
        problemRecordListCopy.addressType = 3
        problemRecordListCopy.problemList = _fmWrapProblemList({}, problemRecordListCopy.problemList, nanoidRandom, form.grade)
      }
      console.log('problemRecordListCopy: ', problemRecordListCopy)
      const params = { problemRecordList: [problemRecordListCopy], ...moreVal }
      // sprint20221125 ： 存在工单状态为：待分派、已接单、处理中的工单（工单性质：常规+品检），则提交时弹出提示框，并阻止提交事件；
      // 封装查询处理中工单接口参数 20221218 目前只要求任务分类是品质的任务工单记录问题时才会查询处理中工单
      // const processingOrderParams = {
      //   addressId: problemRecordListCopy.addressId,
      //   addressType: problemRecordListCopy.addressType
      // }
      // if (this.type === 'PK') {
      //   processingOrderParams.problemIds = problemRecordListCopy.problemList.map(i => i.problemId)
      // } else {
      //   processingOrderParams.mainPartCodes = problemRecordListCopy.problemList.map(i => i.mainPartCode)
      // }
      // // 在线情况下会去查找处理中工单
      // const data = await this.$http.post(
      //   this.$api.orderFindProcessing,
      //   processingOrderParams,
      //   false
      // )
      // console.log('data: ', data)
      // if (data && data.length > 0) {
      //   this.$dialog
      //     .confirm({
      //       title: '提示',
      //       message: '该位置存在相同问题正在处理中，确定进入查看详情！',
      //       cancelButtonText: '继续提交',
      //       confirmButtonText: '确定'
      //     })
      //     .then(() => {
      //       this.paramsCopy = this.lodash.cloneDeep(params)
      //       // 点确认
      //       this.$router.push({
      //         name: 'processingList',
      //         params: {
      //           list: data
      //         }
      //       })
      //     })
      //     .catch(() => {
      //       // 点关闭
      //       // 弹出提示框点击取消后自动触发提交事件；
      //       this.submitOrderInfo(params)
      //     })
      // } else {
      //   this.submitOrderInfo(params)
      // }
      this.submitOrderInfo(params)
    },
    async submitOrderInfo (params) {
      console.log('创建工单时submitOrderInfo: ', this.lodash.cloneDeep(params))
      // const offlineImageKeys = this.lodash.cloneDeep(params.problemRecordList[0].attachmentList)
      // params.problemRecordList[0].attachmentList = await uploadPhotoByImgKeys(params.problemRecordList[0].attachmentList)
      const submitToast = this.$toast.loading({
        message: '正在提交',
        duration: 0, // 持续展示 toast
        forbidClick: true,
        overlay: true
      })
      try {
        await this.$apiOffline({
          methods: 'post',
          api: this.config[this.type].createApi,
          params,
          offlineApiHandlers: ProblemRecordCreateOfflineApiHandlers,
          showLoading: false
        })
        // await mobile.deleteImgsInCache(offlineImageKeys)

        this.$store.commit('changeDashBoardRefreshMixin')
        submitToast.clear()
        this.$toast.success({
          message: '创建成功',
          duration: 1000,
          forbidClick: true,
          onClose: this.goBack
        })
      } catch (error) {
        submitToast.clear()
        console.error('error: ', error)
      }
      this.paramsCopy = null
    },
    goBack () {
      this.$router.back()
    },
    speakRes (text) {
      this.form.problemRecordList.remark += text
    },
    // (problemList) => (form.problemRecordList.problemList = problemList)
    malfunctionReasonSelectConfirm (problemList) {
      this.form.problemRecordList.problemList = problemList
      this.changeGrade(problemList)
    },
    changeGrade (list) {
      if (list.length >= 0) {
        const { grade } = list[0]
        const gradeParse = JSON.parse(grade)
        if (gradeParse && Array.isArray(gradeParse)) {
          this.form.grade = JSON.stringify(gradeParse[0])
          this.gradeValue = gradeParse[0].value
        }
      } else {
        this.form.grade = ''
        this.gradeValue = ''
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import url("./index.less");
</style>
