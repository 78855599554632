<template>
  <van-popup position="right"
             :overlay="false"
             v-model="_show"
             :style="{ width: '100%', height: '100%' }"
             @opened="initPage"
             class="problem-record">
    <div class="problem-check-wrapper">
      <my-nav :title="title" :self-back="true" @back="goBack"></my-nav>
      <div class="scroll-wrapper">
        <div class="scroll-box" v-if="isOffline || copyList.length > 0">
          <div class="tab"
              ref="bigReason">
            <!-- <vuescroll :ops="ops" ref="bigReason"> -->
            <div class="tab-cell">
              <div class="tab-item"
                  :class="{ active: item.isSelected }"
                  v-for="(item, index) of bigReasonList"
                  :key="item.problemTypeCode"
                  @click="selectBigReason(item, index)">
                {{ item.problemTypename }}
              </div>
            </div>
            <!-- </vuescroll> -->
            <p class="tab-line"></p>
          </div>
          <div class="check-box" v-if="smallReasonList.length > 0">
            <div class="check-list"
                v-for="(item, index) in smallReasonList"
                :key="item.problemId">
              <div class="check-wrapper first-check-wrapper">
                <div class="check-left">
                  <i class="check-icon"
                    :class="`multi-${item.check}`"
                    @click="firstCheck(index)"></i>
                  <p class="name">{{ item.problemName }}{{setFirstCount(item)}}</p>
                </div>
                <div class="check-right"
                    @click="showSecond(index,item.questLabel)"
                    :class="{ down: !item.show }"></div>
              </div>
              <div class="second-check-wrapper check-wrapper"
                  v-if="item.questLabel && item.questLabel.length > 0">
                <transition name="van-slide-down">
                  <div class="secon-transition"
                      v-if="item.show">
                    <div class="second-check-list"
                        v-for="(list, secondIndex) in item.questLabel"
                        :key="secondIndex"
                        @click="checkSecond(index, secondIndex)">
                      <i class="check-icon"
                        :class="{'second-check':list.check}"></i>
                      <p class="name">{{ list.name }}</p>
                    </div>
                  </div>
                </transition>
              </div>
              <div class="check-person">
                <van-cell title="整改人"
                          :class="{personActive:item.rectifyPeopls && item.rectifyPeopls.name}"
                          :value="
                    (item.rectifyPeopls && item.rectifyPeopls.name) || '请选择'
                  "
                          is-link
                          @click="showCheckPearson(item,index)" />
              </div>
            </div>
          </div>
        </div>
        <div class="default-wrapper" v-else>
          <p class="default-bg" />
          <p class="tex">
            当前暂无网络，请确认是否已经提前下载数据，或在有网络后再次记录问题。
          </p>
        </div>
      </div>
      <div class="fixed-buttom"
           style="z-index: 3000">
        <div class="left-area"
             @click="showDetail">
          <span class="total-num">{{ `共${problemList.length}项` }}</span>
          <span class="icon-arrow"
                :class="{ down: detailShow }"></span>
        </div>
        <div class="bottom-btn"
             @click="confirm">确定</div>
      </div>
      <van-popup v-model="detailShow"
                 position="bottom"
                 class="point-detail">
        <div class="popup-title">
          <span>{{ `已选择${problemList.length}个问题项` }}</span>
          <span class="clear"
                @click="clearList">清空</span>
        </div>
        <div class="list-scroll">
          <!-- <vuescroll :ops="ops2" ref="popupList"> -->
          <div class="popup-list"
               v-for="(item, index) of problemList"
               :key="index">
            <div class="items-info">
              <div class="problem-title">{{ item.parentName }}/{{ item.problemName }}</div>
              <div class="items-sort">
                <span class="items-name"
                      v-for="(item2, index2) of item.questLabel"
                      :key="index2">
                  <i v-if="index2 !== 0"> | </i>{{ item2 }}
                </span>
              </div>
            </div>
            <div class="items-oprate">
              <!-- <span class="items-point"
                ><em v-if="item.deductPoint != 0">-</em>
                {{ `${item.deductPoint}` }}</span
              > -->
              <span class="delete-icon"
                    @click="deleteItems(index,item)"></span>
            </div>
          </div>
          <div class="no-data"
               v-if="problemList.length === 0">暂无数据</div>
          <!-- </vuescroll> -->
        </div>
        <div class="popup-mat"></div>
      </van-popup>
      <OfflineSelectPerson :listData="selectPearsonList"
                           :show.sync="selectPearsonShow"
                           :value="selectPearsonItem"
                           @handleSelect="handlePerson"></OfflineSelectPerson>
    </div>
  </van-popup>
</template>

<script>
import OfflineSelectPerson from './OfflineSelectPerson'
import api from '@/api/index'
export default {
  name: 'ProblemItems',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    projectCodeProp: {
      type: String,
      required: true
    },
    orderDetail: {
      type: Object,
      default: () => {}
    },
    problemData: {
      type: Array,
      default: () => []
    },
    radio: { // 单选 / 多选
      type: Boolean,
      default: false
    },
    reallyType: {
      type: String,
      default: ''
    }
  },
  computed: {
    _show: {
      get () {
        return this.show
      },
      set (val) {
        !val && this.$emit('update:show', false)
      }
    },
    totalPoint () {
      let total = 0
      this.problemList.forEach((item) => {
        total += item.deductPoint * 1
      })
      return total
    },
    isOffline () {
      return this.$store.getters.getIsOffline
    }
  },
  watch: {
    isOffline () {
      this._show && this.initPage()
    }
  },
  data () {
    return {
      title: '选择问题项',
      bigReasonList: [], // 考评项大类
      smallReasonList: [], // 考评项小类
      detailShow: false, // 扣分详情显示
      currentTabIndex: 0, // 当前所选的考评项大类序号
      listShow: true, // 列表显示
      problemList: [], // 用户操作完成之后最终要构建的值，需要传递到父组件使用
      selectPearsonShow: false,
      selectPearsonIndex: 0,
      selectPearsonItem: {},
      selectPearsonList: [],
      copyList: [],
      projectCode: ''
    }
  },
  methods: {
    initPage () {
      console.log('this.projectCodeProp: ', this.projectCodeProp)
      console.log('this.projectCode: ', this.projectCode)
      console.log('this.copyList: ', this.copyList)
      console.log('this.problemData: ', this.problemData)
      if (this.projectCodeProp === this.projectCode && this.copyList.length !== 0) return
      Object.assign(this.$data, this.$options.data())
      if (this.problemData.length > 0) {
        this.problemList = this.problemData.map(item => {
          return {
            problemId: item.problemId * 1,
            problemName: item.problemName,
            questLabel: item.questLabel,
            rectifyPeopls: item.rectifyPeopls
          }
        })
      }
      this.projectCode = this.projectCodeProp
      this._initData()
    },
    async _initData () {
      if (this.isOffline) {
        this._getCheckData()
      } else {
        const copyList = this.lodash.cloneDeep(this.orderDetail?.problemItemDataResps) || []
        this.copyList = copyList
        console.log('problemItemDataResps', this.lodash.cloneDeep(this.copyList))
        this._setCheckData()
      }
    },
    async _getCheckData () {
      let httpToast = this.$toast.loading({
        message: '请求中...',
        forbidClick: true
      })
      let params = {}
      if (this.reallyType === '4') {
        params.code = 'questionCategory14'
      }
      this.$http.get(`${api.getProblemData}/${this.projectCode}`, params).then(res => {
        this.copyList = res
        this._setCheckData()
        this._initProlemList()
        httpToast.clear()
      })
    },
    // 设置选择的问题列表数据
    _setCheckData () {
      if (this.problemData && this.problemData.length > 0) {
        this.bigReasonList = this.copyList.map(
          (item) => {
            if (this.problemData && this.problemData.length > 0) {
              if (item.problemItemList && item.problemItemList.length > 0 && item.problemItemList.find(i => i.problemId === this.problemData[0].problemId * 1)) {
                item.isSelected = true
                this._setSmallList(item)
              }
            } else {
              item.isSelected = false
            }
            return item
          }
        )
      } else {
        this.bigReasonList = this.copyList.map(
          (item, index) => {
            // 默认选中第一项
            if (index !== 0) {
              item.isSelected = false
            } else {
              item.isSelected = true
              this._setSmallList(item)
            }
            return item
          }
        )
      }
    },
    _setSmallList (item) {
      if (item.problemItemList) {
        this.smallReasonList = item.problemItemList.map((problemItem) => {
          this.$set(problemItem, 'check', 'default')
          if (problemItem.questLabel && typeof problemItem.questLabel === 'string') {
            let data = []
            JSON.parse(problemItem.questLabel).forEach((label) => {
              data.push({
                name: label,
                check: false
              })
            })
            problemItem.questLabel = data
            problemItem.rectifyPeopls = {}
            this.$set(problemItem, 'show', true)
          } else {
            this.$set(problemItem, 'show', false)
            problemItem.rectifyPeopls = {}
          }
          if (this.problemList && this.problemList.length > 0) {
            // 处理一级选中状态
            this.problemList.forEach(problem => {
              const problemQuestLabel = problem.questLabel.join(',')
              console.log('problemQuestLabel: ', problemQuestLabel)
              if (problem.problemId === problemItem.problemId) {
                console.log('problem: ', problem)
                // 找到相同的
                console.log('找到相同的: ')
                let questLabelString = (problemItem.questLabel && problemItem.questLabel.map(labelItem => labelItem.name).join(',')) || ''
                console.log('questLabelString: ', questLabelString)
                console.log('problemQuestLabel === questLabelString: ', problemQuestLabel === questLabelString)
                if (problemQuestLabel === questLabelString) {
                  this.$set(problemItem, 'check', 'all')
                  this.$set(problemItem, 'show', true)
                } else if (problem.questLabel.length > 0) {
                  this.$set(problemItem, 'check', 'check')
                  this.$set(problemItem, 'show', true)
                } else if (problemItem.questLabel.length === 0) {
                  this.$set(problemItem, 'check', 'all')
                }
                // 回显整改人
                this.$set(problemItem, 'rectifyPeopls', problem.rectifyPeopls)
                console.log('problemItem: ', problemItem)
                problemItem.questLabel && problemItem.questLabel.forEach(questItem => {
                  questItem.check = !!problem.questLabel.includes(questItem.name)
                })
              }
            })
          }
          return problemItem
        })
      }
    },
    // 二级选择显示隐藏
    showSecond (index, list) {
      if (!list) return
      this.smallReasonList[index].show = !this.smallReasonList[index].show
    },
    clearAllOtherCheck (index) {
      if (this.radio) {
        this.bigReasonList.forEach((item, bigIndex) => {
          if (bigIndex !== this.currentTabIndex) {
            item.problemItemList && item.problemItemList.forEach(problem => {
              problem.check = 'default'
              if (problem.questLabel && problem.questLabel instanceof Array && problem.questLabel.length > 0) {
                problem.questLabel.forEach((label) => {
                  label.check = false
                })
              }
            })
          }
        })
        this.smallReasonList.forEach((item, idx) => {
          if (idx !== index) {
            this.smallReasonList[idx].check = 'default'
            if (this.smallReasonList[idx].questLabel && this.smallReasonList[idx].questLabel.length > 0) {
              this.smallReasonList[idx].questLabel.forEach((item) => {
                item.check = false
              })
            }
          }
        })
      }
    },
    // 一级问题选择
    firstCheck (index) {
      // 单选先把其他的全部清空
      this.clearAllOtherCheck(index)
      if (this.smallReasonList[index].check === 'default' || this.smallReasonList[index].check === 'check') {
        if (this.smallReasonList[index].questLabel && this.smallReasonList[index].questLabel.length > 0) {
          this.smallReasonList[index].questLabel.forEach((item) => {
            item.check = true
          })
        }
        this.smallReasonList[index].check = 'all'
      } else {
        if (this.smallReasonList[index].questLabel && this.smallReasonList[index].questLabel.length > 0) {
          this.smallReasonList[index].questLabel.forEach((item) => {
            item.check = false
          })
        }
        this.smallReasonList[index].check = 'default'
      }
      this._initProlemList()
    },
    // 二级问题标签选择
    checkSecond (index, secondIndex) {
      this.clearAllOtherCheck(index)
      this.smallReasonList[index].questLabel[secondIndex].check = !this
        .smallReasonList[index].questLabel[secondIndex].check
      let status = true
      let text = 'default'
      this.smallReasonList[index].questLabel.forEach((item) => {
        if (item.check) {
          text = 'check'
        } else {
          status = false
          if (text === 'check') {
            text = 'check'
          } else {
            text = 'default'
          }
        }
      })
      if (status && text === 'check') {
        text = 'all'
      }
      this.smallReasonList[index].check = text
      this._initProlemList()
    },
    // 设置选中的数据
    _initProlemList () {
      this.problemList = []
      this.bigReasonList.forEach(item => {
        item.problemItemList && item.problemItemList.forEach(list => {
          if (list.check && list.check !== 'default') {
            console.log('list: ', list)
            let data = []
            if (list.questLabel && list.questLabel.length > 0) {
              list.questLabel.forEach(problemItem => {
                if (problemItem.check) {
                  data.push(problemItem.name)
                }
              })
            }
            this.problemList.push({
              bigCategoryCode: list.bigCategoryCode,
              bigCategoryName: list.bigCategoryName,
              smallCategoryCode: list.smallCategoryCode,
              smallCategoryName: list.smallCategoryName,
              parentName: item.problemTypename,
              problemId: list.problemId,
              problemName: list.problemName,
              questLabel: data,
              rectifyPeopls: list.rectifyPeopls,
              grade: list.grade,
              rectifyLimit: list.rectifyLimit,
              flowQuickInputs: list.flowQuickInputs || []
            })
            console.log('this.problemList: ', this.problemList)
          }
        })
      })
    },
    // 显示整改人弹框并重置数据
    showCheckPearson (item, index) {
      this.selectPearsonIndex = index
      this.selectPearsonShow = true
      this.selectPearsonItem = item.rectifyPeopls
      this.selectPearsonList = item.rectifyRoleList
    },
    // 修改人选择之后回调
    handlePerson (item) {
      this.smallReasonList[this.selectPearsonIndex].rectifyPeopls = item
      this._initProlemList()
    },
    // 删除选择数据后重置选择树数据
    _resetTreeData (deleteItem) {
      this.bigReasonList.forEach(item => {
        item.problemItemList && item.problemItemList.forEach(list => {
          if (list.problemId === deleteItem.problemId) {
            list.check = 'default'
            if (list.questLabel && list.questLabel.length > 0) {
              list.questLabel.forEach(labelItem => {
                labelItem.check = false
              })
            }
          }
        })
      })
    },
    /* 选择考评项大类之后的操作 */
    selectBigReason (item, idx) {
      this.currentTabIndex = idx
      this.bigReasonList.forEach((item) => {
        item.isSelected = false
      })
      this.bigReasonList[idx].isSelected = true
      this._setSmallList(item)
    },
    // test (val, detail) {
    // },
    /* 显示扣分详情 */
    showDetail () {
      this.detailShow = !this.detailShow
    },
    /* 删除对应的扣分项 */
    deleteItems (idx, item) {
      this.$dialog
        .confirm({
          title: '确定删除该问题项吗？'
        })
        .then(() => {
          this.problemList.splice(idx, 1)
          // 取消被删除的对应考评项的选中
          this._resetTreeData(item)
          this.$emit('result', this.problemList)
        })
    },
    /* 清空所有扣分项 */
    clearList () {
      this.$dialog
        .confirm({
          title: '确定清空所有问题项吗？'
        })
        .then(() => {
          this.problemList = []
          this.detailShow = false
          // 重新刷新页面，取消被删除的对应考评项的选中
          this._setCheckData()
          this.$emit('result', this.problemList)
        })
    },
    /* 返回-退出组件 */
    goBack () {
      this._show = false
    },
    /* 点击确认，传递用户全部操作结果 */
    confirm () {
      this.$emit('result', this.problemList)
      this.goBack()
    },
    setFirstCount (item) {
      let text = ''
      if (item.questLabel && item.questLabel.length > 0) {
        let count = 0
        for (const list of item.questLabel) {
          if (list.check) {
            count += 1
          }
        }
        text = `(${count}/${item.questLabel.length})`
      }
      return text
    },
    clearAllCheckProblem () {
      Object.assign(this.$data, this.$options.data())
      this.$emit('result', [])
    }
  },
  components: {
    // vuescroll
    OfflineSelectPerson
  }
}
</script>

<style lang="less" scoped>
.problem-check-wrapper {
  height: 100vh;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  .check-box {
    padding-bottom: 70px;
    background-color: #f8f8f8;
    .check-list {
      padding: 0 16px;
      background-color: #fff;
      & + .check-list {
        margin-top: 8px;
      }
      .first-check-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 48px;
        .check-left {
          display: flex;
          flex: 1;
          box-sizing: border-box;
          overflow: hidden;
          .multi-check {
            background: url("../../../asset//multi-check.png") no-repeat;
            background-size: 100% 100%;
          }
          .multi-all {
            background: url("../../../asset//multi-all.png") no-repeat;
            background-size: 100% 100%;
          }
        }
        .check-right {
          flex: 0 0 22px;
          flex-shrink: 0;
          height: 22px;
          background: url("../../../asset//check-up.png") no-repeat;
          background-size: 100% 100%;
          transform: rotate(0deg);
          transition: all 0.3s;
          &.down {
            transform: rotate(180deg);
            transition: all 0.3s;
          }
        }
      }
      .secon-transition {
        overflow: hidden;
      }
      .second-check-wrapper {
        padding-left: 32px;
        overflow: hidden;
        .second-check-list {
          display: flex;
          height: 48px;
          align-items: center;
          justify-content: space-between;
        }
      }
      .check-person {
        margin-left: 32px;
        border-top: 1px solid #f5f5f5;
        /deep/ .van-cell {
          padding-left: 0;
          font-size: 16px;
          font-weight: 400;
          color: #222;
          height: 54px;
          display: flex;
          align-items: center;
          .van-icon {
            color: #c5c5c5;
          }
          .van-cell__value {
            font-size: 16px;
            font-weight: 400;
            color: #999;
          }
          &.personActive {
            .van-cell__value {
              color: #222;
            }
          }
          .van-cell__right-icon{
            // font-size: 22px;
            // top: -1px;
          }
        }
      }
      .check-wrapper {
        .check-icon {
          flex: 0 0 24px;
          flex-shrink: 0;
          height: 24px;
          background: url("../../../asset//multi-default.png") no-repeat;
          background-size: 100%;
          &.second-check {
            background: url("../../../asset//multi-all.png") no-repeat;
            background-size: 100% 100%;
          }
        }
        .name {
          flex: 1;
          font-size: 16px;
          font-weight: 400;
          color: #333333;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          box-sizing: border-box;
          padding: 0 8px;
        }
      }
    }
  }
   .scroll-wrapper {
      width: 100%;
      flex: 1;
      // height: calc(100vh - 108px - env(safe-area-inset-bottom));
      overflow-y: scroll;
      // -webkit-overflow-scrolling: touch;
      .scroll-box {
        height: calc(100% + 1px);
      }
    }
}
.problem-record {
  // font-family: PingFangSC-Medium, PingFang SC;
  i {
    font-style: normal;
  }
  p {
    margin: 0;
  }

  .tab {
    width: 100vw;
    height: 56px;
    box-sizing: border-box;
    padding: 0 16px;
    white-space: nowrap;
    overflow: auto;
    background: #fff;
    position: relative;
    .tab-line {
      width: calc(100% - 32px);
      height: 1px;
      background-color: #f5f5f5;
      position: absolute;
      bottom: 0;
      left: 16px;
    }
    &::-webkit-scrollbar {
      width: 0;
      border-radius: 0;
      height: 0;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 0;
      background-color: #bfbfbf;
    }
    .tab-cell {
      // display: flex;
      // white-space: nowrap;
      // align-items: center;
      display: inline-block;
      position: relative;
      top: 16px;
      .tab-item {
        height: 28px;
        border-radius: 14px;
        color: #666666;
        background: #f6f6f6;
        line-height: 28px;
        padding: 0 12px;
        display: inline-block;
        font-size: 14px;
        box-sizing: border-box;
        &.active {
          background: rgba(51, 102, 254, 0.1);
          border: 1px solid #3366fe;
          color: #3366fe;
          line-height: 26px;
        }
      }
      .tab-item + .tab-item {
        margin-left: 12px;
      }
    }
  }
  .tree-select {
    padding: 0 16px;
    font-size: 16px;
    .tree-title {
      padding: 14px 0;
    }
    .tree-item {
      padding: 10px 0;
      position: relative;
      left: 30px;
    }
  }
  .tree-cell {
    font-size: 16px;
    color: #333;
    padding: 6px 0 12px;
    border-top: 1px solid #f5f5f5;
    .point {
      display: flex;
      margin-top: 6px;
      align-items: center;
      .my-stepper {
        width: 60px;
        font-size: 16px;
        padding: 3px 0;
        // height: 28px;
        outline: none;
        border: 1px solid #e8e8e8;
        border-radius: 4px;
        text-align: center;
        line-height: 16px;
        &::-webkit-input-placeholder {
          color: #999999;
          font-size: 16px;
        }
      }
    }
  }
  .bottom-mat {
    height: 80px;
    width: 100%;
  }
  .fixed-buttom {
    // position: fixed;
    // bottom: 0;
    // left: 0;
    flex-shrink: 0;
    width: 100%;
    height: 80px;
    border-top: 1px solid #e5e5e5;
    display: flex;
    justify-content: space-between;
    background: #ffffff;
    padding: 12px 16px 0;
    .left-area {
      font-size: 16px;
      display: flex;
      align-items: center;
      height: 40px;
      font-weight: 601;
      span + span {
        margin-left: 4px;
      }
      .total-num {
        color: #222;
      }
      .total-point {
        color: #3366fe;
      }
      .icon-arrow {
        background: url("../../../asset//icon_arrow_24pt@2x.png") no-repeat;
        background-size: contain;
        display: inline-block;
        width: 24px;
        height: 24px;
        transition-duration: 0.3s;
        &.down {
          transform: rotate(180deg);
        }
      }
    }
    .bottom-btn {
      width: 120px;
      height: 40px;
      background: #3366fe;
      border-radius: 4px;
      font-size: 16px;
      color: #ffffff;
      text-align: center;
      line-height: 40px;
    }
  }
  .point-detail {
    min-height: 200px;
    // max-height: 400px;
    .popup-title {
      height: 48px;
      border-bottom: 1px solid #e8e8e8;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 16px;
      font-size: 16px;
      color: #999999;
      .clear {
        color: #3366fe;
      }
    }
    .popup-mat {
      height: 80px;
      width: 100%;
    }
    .list-scroll {
      max-height: 300px;
      overflow: auto;
      .popup-list {
        width: 340px;
        margin: 0 auto;
        padding: 6px 0;
        min-height: 68px;
        font-size: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .items-info {
          color: #222222;
          width: 260px;
          .problem-title {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .items-sort {
            color: #8b8f9a;
            font-size: 14px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .items-oprate {
          display: flex;
          align-items: center;
          .items-point {
            color: #3366fe;
          }
          .delete-icon {
            margin-left: 12px;
            display: inline-block;
            background: url("../../../asset//icon_delete_grey@2x.png") no-repeat;
            background-size: contain;
            width: 24px;
            height: 24px;
            cursor: pointer;
          }
        }
      }
      .popup-list + .popup-list {
        border-top: 1px solid #eeeeee;
      }
      .no-data {
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        color: #999999;
      }
    }
  }
}
.default-wrapper {
  margin-top: 145px;
  text-align: center;
  .default-bg {
    width: 142px;
    height: 100px;
    background: url("../../../asset/default-list-bg.png") no-repeat;
    background-size: 100% 100%;
    margin: 0 auto;
  }
  .tex {
    margin-top: 32px;
    margin-bottom: 0;
    padding: 0 50px;
    margin-top: 16px;
    color: #999;
    font-size: 14px;
  }
}
</style>
