var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "preview-wrapper" },
    [
      _vm.imgList.length > 0
        ? [
            _c("div", [_vm._v(_vm._s(_vm.title))]),
            _vm._l(_vm.imgList, function (list, index) {
              return _c("van-image", {
                key: index,
                staticClass: "photo",
                attrs: { width: "40", height: "40", radius: "4", src: list },
                on: {
                  click: function ($event) {
                    return _vm.imgPreview(index)
                  },
                },
              })
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }