<template>
    <van-popup v-model="_show" position="right" :overlay="false"
      :style="{ width: '100%', height: '100%' }" :duration="animateDuration" @open="selectProjectOpenHandler" @close="selectProjectCloseHandler">
      <div class="select-project-wrapper">
        <my-nav title="项目列表" :self-back="true" @back="goBack">
          <div slot="right" class="multi-wrapper" @click="checkMulti" v-if="projectList.length > 0 && projectList[0].deptType === '3' && selectType==='checkbox'">
              <i class="multi-icon" :class="`multi-${_initMulti()}`" @click="menuShow = true"></i>
              <p class="title">全选</p>
            </div>
        </my-nav>
        <van-search v-model="searchValue" placeholder="请输入关键字" @search="_getProjectList"
          @clear="clearSearch" class="search-wrapper" />
        <div class="project-list-wrapper">
          <div class="project-list" v-for="item in projectList" :key="item.deptCode"
            @click="changeProjectCheck(item)">
            <p class="name">{{ item.deptName }}</p>
            <i class="right-icon" v-if="item.deptType !== '3'"></i>
            <i class="check-icon" v-if="showCheck(item)"></i>
          </div>
        </div>
        <div class="router-wrapper">
          <div class="list-wrapper">
            <div class="router-list" v-for="(item, index) in routerList" :key="item.deptCode">
              <p class="name" :class="{ last: index === routerList.length - 1 }"
                @click="checkRouter(index < routerList.length - 1, item)">
                {{ item.deptName }}
              </p>
              <i class="router-icon" v-if="index < routerList.length - 1"></i>
            </div>
          </div>
        </div>
        <div class="submit-wrapper" @click="submit">
          <p class="count" v-if="selectType==='checkbox'">({{ checkList.length }})</p>
          <p class="text">确定</p>
        </div>
      </div>
    </van-popup>
</template>

<script type='text/ecmascript-6'>
import api from '@/api'
export default {
  name: '',
  components: {},
  props: {
    show: {
      type: Boolean,
      default: false
    },
    selectType: {
      type: String,
      default: 'checkbox'
    }
  },
  data () {
    return {
      searchValue: '',
      projectList: [],
      checkCode: '',
      checkList: [],
      routerList: [],
      animateDuration: 0.3 // 默认弹窗动画时间0.3s
    }
  },
  computed: {
    isOffline () {
      return this.$store.getters.getIsOffline
    },
    _show: {
      get () {
        return this.show
      },
      set (val) {
        this.$emit('update:show', val)
      }
    }
  },
  watch: {
    _show (val) {
      val && this._getProjectList()
    }
  },
  methods: {
    setInitData (projectInfo) {
      this.searchValue = projectInfo.searchValue || ''
      this.checkCode = projectInfo.checkCode || ''
      this.checkList = projectInfo.checkList || []
      this.routerList = projectInfo.routerList || []
    },
    // popup打开
    selectProjectOpenHandler () {
      window.history.pushState(null, null, '#') // 模拟新的一页history记录
      window.addEventListener('popstate', this.popstateHandler)
    },
    popstateHandler (e) {
      this.animateDuration = 0 // 防止侧滑出现多次动画
      this._show = false
    },
    // popup关闭
    selectProjectCloseHandler () {
      window.removeEventListener('popstate', this.popstateHandler, false)
    },
    _getProjectList () {
      console.log('getPJLI')
      if (!this.isOffline) return
      this.$http
        .get(api.getOrg, {
          parent_code: this.checkCode,
          name: this.searchValue
        })
        .then((res) => {
          this.projectList = res
        })
    },
    goBack () {
      window.history.back()
      this._show = false
    },
    clearSearch () {
      this._getProjectList()
    },
    changeProjectCheck (item) {
      console.log(item, 'ccck')
      if (item.deptType !== '3') {
        this.checkCode = item.deptCode
        this._clearSearchValue()
        this._getProjectList()
        this.routerList.push(item)
      } else {
        if (this.selectType === 'checkbox') {
          if (this._isCheckArr(item, this.checkList)) {
            this.checkList.splice(this._getCheckIndex(item), 1)
          } else {
            this.checkList.push(item)
          }
        } else {
          if (this._isCheckArr(item, this.checkList)) {
            this.checkList.splice(this._getCheckIndex(item), 1)
          } else {
            this.checkList.splice(0, 1, item)
          }
        }
      }
    },
    showCheck (item) {
      if (item.deptType === '3' && this._isCheckArr(item)) {
        return true
      }
    },
    checkRouter (boolean, item) {
      if (boolean) {
        this.routerList.splice(this.routerList.indexOf(item) + 1)
        this.checkCode = item.deptCode
        this._clearSearchValue()
        this._getProjectList()
      }
    },
    _clearSearchValue () {
      this.searchValue = ''
    },
    _initMulti () {
      let text = ''
      let status = true
      for (let item of this.projectList) {
        this.projectList.forEach(() => {})
        if (this._isCheckArr(item)) {
          text = 'check'
        } else {
          status = false
          if (text === 'check') {
            text = 'check'
          } else {
            text = 'default'
          }
        }
      }
      if (status && text === 'check') {
        text = 'all'
      }
      return text
    },
    checkMulti () {
      let data = JSON.parse(JSON.stringify(this.checkList))
      if (this._initMulti() === 'default') {
        this.checkList = [...this.checkList, ...this.projectList]
      } else if (this._initMulti() === 'check') {
        data.forEach((item, index) => {
          if (this._isCheckArr(item, this.projectList)) {
            this.checkList.splice(index, 1)
          }
        })
        this.checkList = [...this.checkList, ...this.projectList]
      } else {
        data.forEach((item) => {
          this.checkList.splice(this.projectList.indexOf(item), 1)
        })
      }
    },
    _isCheckArr (item, data) {
      let list
      if (!data) {
        list = this.checkList
      } else {
        list = data
      }
      for (const checkItem of list) {
        if (item.deptCode === checkItem.deptCode) {
          return true
        }
      }
    },
    _getCheckIndex (item) {
      let checkIndex = 0
      this.checkList.forEach((checkItem, index) => {
        if (item.deptCode === checkItem.deptCode) {
          checkIndex = index
        }
      })
      return checkIndex
    },
    /**
     * 因为存在有保存的默认项目，初始化时会执行这一步
     * routerBack 是否返回上一页
     */
    async submit (routerBack = true) {
      if (this.selectType === 'radio' && this.checkList.length === 0) {
        this.$toast('请选择项目')
        return
      }
      const obj = {
        searchValue: this.searchValue,
        checkCode: this.checkCode,
        checkList: this.lodash.cloneDeep(this.checkList), // 避免变量污染
        routerList: this.routerList
      }
      console.log('submit', obj)
      this.$emit('result', obj)
      routerBack && this.goBack()
    }
  }
}
</script>
<style lang='less' scoped>
.select-project-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  p {
    margin: 0;
  }
  .icon-back {
    height: 24px;
    width: 24px;
    background: url("../asset/icon_back_black@2x.png") no-repeat;
    background-size: cover;
  }
  .multi-wrapper {
    display: flex;
    .multi-icon {
      width: 24px;
      height: 24px;
    }
    .multi-default {
      background: url("../asset/multi-default.png") no-repeat;
      background-size: 100% 100%;
    }
    .multi-check {
      background: url("../asset/multi-check.png") no-repeat;
      background-size: 100% 100%;
    }
    .multi-all {
      background: url("../asset/multi-all.png") no-repeat;
      background-size: 100% 100%;
    }
    .title {
      font-size: 16px;
      font-weight: 400;
      color: #222222;
      line-height: 22px;
    }
  }
  .search-wrapper {
    width: 100%;
    padding: 6px 16px;
    /deep/ .van-search__content {
      background-color: #f5f6f6;
    }
  }
  .project-list-wrapper {
    width: 100%;
    flex: 1;
    overflow-y: scroll;
    .project-list {
      height: 48px;
      display: flex;
      padding: 0 16px;
      align-items: center;
      justify-content: space-between;
      .name {
        font-size: 16px;
        font-weight: 400;
        color: #222222;
        line-height: 24px;
      }
      .right-icon {
        width: 16px;
        height: 16px;
        background: url("../asset/right-icon.png") no-repeat;
        background-size: 100% 100%;
      }
      .check-icon {
        width: 24px;
        height: 24px;
        background: url("../asset/icon_right_blue@2x.png") no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .router-wrapper {
    min-height: 24px;
    padding: 8px 0px;
    flex-shrink: 0;
    box-shadow: 0px 10px 10px 2px rgba(0, 0, 0, 0.9);
    // position: fixed;
    // bottom: 8px;
    // left: 0;
    width: 100%;
    background-color: #fff;
    .list-wrapper {
      display: flex;
      padding: 0 16px;
      width: 100%;
      flex-wrap: wrap;
      line-height: 20px;
    }
    .router-list {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      margin-bottom: 4px;
      .name {
        font-size: 16px;
        font-weight: 400;
        color: #3366fe;
        line-height: 16px;
        &.last {
          color: #8b8f9a;
        }
      }
      .router-icon {
        width: 16px;
        height: 16px;
        margin: 0 6px;
        background: url("../asset/right-icon.png") no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .submit-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 64px;
    height: 64px;
    background: linear-gradient(90deg, #62a9ff 0%, #3370fe 100%);
    box-shadow: 0px 2px 6px 0px rgba(51, 102, 254, 0.4);
    border-radius: 50%;
    text-align: center;
    overflow: hidden;
    position: fixed;
    right: 16px;
    bottom: 76px;
    .count {
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      line-height: 1;
    }
    .text {
      font-size: 16px;
      font-weight: 400;
      color: #ffffff;
      line-height: 1;
      margin-top: 5px;
    }
  }
}
</style>
