var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dashboard" },
    [
      _c(
        "my-nav",
        {
          attrs: { title: _vm.navTitle, "self-back": true },
          on: { back: _vm.toBack },
        },
        [
          _c(
            "div",
            {
              staticClass: "icon-wrapper",
              attrs: { slot: "right" },
              slot: "right",
            },
            [
              _c(
                "van-popover",
                {
                  attrs: {
                    "get-container": ".dashboard",
                    trigger: "click",
                    placement: "bottom-end",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "reference",
                      fn: function () {
                        return [
                          _vm.isZG
                            ? _c("div", { staticClass: "icon-screen" })
                            : _vm._e(),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                  model: {
                    value: _vm.queryIssueZGShow,
                    callback: function ($$v) {
                      _vm.queryIssueZGShow = $$v
                    },
                    expression: "queryIssueZGShow",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "queryOrderZGPop" },
                    [
                      _c(
                        "van-cell-group",
                        [
                          _c("span", { staticClass: "label" }, [
                            _vm._v("报事人"),
                          ]),
                          _c("van-field", {
                            attrs: { label: "", placeholder: "请输入报事人" },
                            model: {
                              value: _vm.searchParams.reportUserName,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.searchParams,
                                  "reportUserName",
                                  $$v
                                )
                              },
                              expression: "searchParams.reportUserName",
                            },
                          }),
                          _c("span", { staticClass: "label" }, [
                            _vm._v("所在位置"),
                          ]),
                          _c("van-field", {
                            attrs: { label: "", placeholder: "请输入所在位置" },
                            model: {
                              value: _vm.searchParams.address,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchParams, "address", $$v)
                              },
                              expression: "searchParams.address",
                            },
                          }),
                          _c("span", { staticClass: "label" }, [
                            _vm._v("原因大类"),
                          ]),
                          _c("van-field", {
                            attrs: {
                              name: "bigCategoryName",
                              readonly: "",
                              clickable: "",
                              "right-icon": "arrow",
                              label: "",
                              placeholder: "请选择原因大类",
                            },
                            on: {
                              click: function ($event) {
                                _vm.selectReasonShow = true
                              },
                            },
                            model: {
                              value: _vm.searchParams.bigCategoryName,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.searchParams,
                                  "bigCategoryName",
                                  $$v
                                )
                              },
                              expression: "searchParams.bigCategoryName",
                            },
                          }),
                          _c("span", { staticClass: "label" }, [
                            _vm._v("原因细类"),
                          ]),
                          _c("van-field", {
                            attrs: {
                              name: "smallCategoryName",
                              readonly: "",
                              clickable: "",
                              "right-icon": "arrow",
                              label: "",
                              placeholder: "请选择原因细类",
                            },
                            on: { click: _vm.selectFineReason },
                            model: {
                              value: _vm.searchParams.smallCategoryName,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.searchParams,
                                  "smallCategoryName",
                                  $$v
                                )
                              },
                              expression: "searchParams.smallCategoryName",
                            },
                          }),
                          _c("span", { staticClass: "label" }, [
                            _vm._v("工单编号"),
                          ]),
                          _c("van-field", {
                            attrs: { label: "", placeholder: "请输入工单编号" },
                            model: {
                              value: _vm.searchParams.orderNo,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchParams, "orderNo", $$v)
                              },
                              expression: "searchParams.orderNo",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "btn" },
                        [
                          _c(
                            "van-button",
                            {
                              staticStyle: { height: "30px" },
                              on: { click: _vm.resetZGQueryParams },
                            },
                            [_vm._v("重置")]
                          ),
                          _c(
                            "van-button",
                            {
                              staticStyle: {
                                height: "30px",
                                "margin-left": "10px",
                              },
                              attrs: { type: "primary" },
                              on: { click: _vm.getInitList },
                            },
                            [_vm._v("查询")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm.isPK || _vm.isFM || _vm.isZG
                ? _c("div", {
                    staticClass: "icon-code",
                    on: { click: _vm.searchCode },
                  })
                : _vm._e(),
              _vm.isOffline && _vm.isCJ
                ? _c("micro-text", {
                    attrs: {
                      ptt: true,
                      black: true,
                      "style-prop": { marginRight: "18px" },
                    },
                    on: { speakRes: _vm.speakRes },
                  })
                : _vm._e(),
              _vm.isFM || _vm.isPK
                ? _c("div", {
                    staticClass: "icon-screen",
                    on: { click: _vm.showPoint },
                  })
                : _vm._e(),
              _c("van-popover", {
                attrs: {
                  trigger: "click",
                  placement: "bottom-end",
                  actions: _vm.filterEquipAndAreaActions,
                },
                on: { select: _vm.handleFilterEquipAndArea },
                scopedSlots: _vm._u([
                  {
                    key: "reference",
                    fn: function () {
                      return [
                        _vm.isCJ
                          ? _c("div", { staticClass: "icon-screen" })
                          : _vm._e(),
                      ]
                    },
                    proxy: true,
                  },
                ]),
                model: {
                  value: _vm.filterEquipAndAreaPopShow,
                  callback: function ($$v) {
                    _vm.filterEquipAndAreaPopShow = $$v
                  },
                  expression: "filterEquipAndAreaPopShow",
                },
              }),
              _vm.isFM || _vm.isPK || _vm.isCJ
                ? _c("div", {
                    staticClass: "icon_history",
                    on: { click: _vm.showHistory },
                  })
                : _vm._e(),
              _c("van-popover", {
                attrs: {
                  trigger: "click",
                  placement: "bottom-end",
                  actions: _vm.createIssueActions,
                },
                on: { select: _vm.handleCreateFMProgramRecord },
                scopedSlots: _vm._u([
                  {
                    key: "reference",
                    fn: function () {
                      return [
                        _vm.isZG
                          ? _c("div", { staticClass: "icon_create" })
                          : _vm._e(),
                      ]
                    },
                    proxy: true,
                  },
                ]),
                model: {
                  value: _vm.createIssueShow,
                  callback: function ($$v) {
                    _vm.createIssueShow = $$v
                  },
                  expression: "createIssueShow",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c("DashboardProjectSelect", {
        ref: "DashboardProjectSelect",
        on: { selectProject: _vm.selectProject },
      }),
      _vm.isPK && _vm.calendarRenderAble
        ? _c("Calendar", {
            ref: "Calendar",
            attrs: { searchParams: _vm.searchParams },
            on: {
              "update:searchParams": function ($event) {
                _vm.searchParams = $event
              },
              "update:search-params": function ($event) {
                _vm.searchParams = $event
              },
            },
          })
        : _vm._e(),
      _vm.isPK ? _c("p", { staticClass: "bg-line" }) : _vm._e(),
      [
        _c(
          "van-sticky",
          {
            attrs: {
              "offset-top": +_vm.navBarHeight + _vm.calendarInfoHeight - 1,
            },
          },
          [
            _vm.isFM
              ? [
                  _c(
                    "section",
                    {
                      staticClass:
                        "bg-white flex items-center justify-between workorder_wrapper",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "py-12px px-16px" },
                        [
                          _c(
                            "van-popover",
                            {
                              attrs: { trigger: "click" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "reference",
                                    fn: function () {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-16px font-medium leading-22px text-[#222]",
                                          },
                                          [_vm._v("工单类型")]
                                        ),
                                        _c("van-icon", {
                                          attrs: { name: "arrow-down" },
                                        }),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                1162684506
                              ),
                              model: {
                                value: _vm.showPopover,
                                callback: function ($$v) {
                                  _vm.showPopover = $$v
                                },
                                expression: "showPopover",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "divide-x" },
                                _vm._l(
                                  _vm.actionsOptionsFM,
                                  function (item, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass:
                                          "py-16px pl-17px space-x-16px relative",
                                        on: {
                                          click: function ($event) {
                                            return _vm.changeOrderType(item)
                                          },
                                        },
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(" " + _vm._s(item.text)),
                                        ]),
                                        _vm.orderTypeSelect(item.id)
                                          ? _c("van-icon", {
                                              staticClass:
                                                "absolute text-[#3366fe] right-12px top-1/2 transform -translate-y-1/2",
                                              attrs: { name: "success" },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "space-x-12px flex pr-12px" }, [
                        _c(
                          "div",
                          { staticClass: "rounded-4px bg-[#f5f5f5] text-13px" },
                          _vm._l(_vm.filterOptionsFM, function (item, index) {
                            return _c(
                              "span",
                              {
                                key: index,
                                staticClass: "switch_bth",
                                class: {
                                  switch_bth_active:
                                    item.value === _vm.searchParams.myHandle,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.changeFilter(item)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(item.label))]
                            )
                          }),
                          0
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "currentBtn rounded-4px bg-[#f5f5f5] text-13px p-6px",
                            class: {
                              switch_bth_active: !!_vm.searchParams.today,
                            },
                            on: { click: _vm.changeToday },
                          },
                          [_vm._v(" 当天 ")]
                        ),
                      ]),
                    ]
                  ),
                ]
              : _vm._e(),
            _vm.isCJ
              ? [
                  _c(
                    "section",
                    {
                      staticClass:
                        "bg-white flex items-center justify-between workorder_wrapper",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "py-12px px-16px" },
                        [
                          _c(
                            "van-popover",
                            {
                              attrs: { trigger: "click" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "reference",
                                    fn: function () {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-16px font-medium leading-22px text-[#222]",
                                          },
                                          [_vm._v("工单类型")]
                                        ),
                                        _c("van-icon", {
                                          attrs: { name: "arrow-down" },
                                        }),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                1162684506
                              ),
                              model: {
                                value: _vm.showPopover,
                                callback: function ($$v) {
                                  _vm.showPopover = $$v
                                },
                                expression: "showPopover",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "divide-x" },
                                _vm._l(
                                  _vm.actionsOptionsCJ,
                                  function (item, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass:
                                          "py-16px pl-17px space-x-16px relative",
                                        on: {
                                          click: function ($event) {
                                            return _vm.changeOrderType(item)
                                          },
                                        },
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(" " + _vm._s(item.text)),
                                        ]),
                                        _vm.orderTypeSelect(item.id)
                                          ? _c("van-icon", {
                                              staticClass:
                                                "absolute text-[#3366fe] right-12px top-1/2 transform -translate-y-1/2",
                                              attrs: { name: "success" },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "space-x-12px flex pr-12px" }, [
                        _c(
                          "div",
                          { staticClass: "rounded-4px bg-[#f5f5f5] text-13px" },
                          _vm._l(_vm.filterOptionsCJ, function (item, index) {
                            return _c(
                              "span",
                              {
                                key: index,
                                staticClass: "switch_bth",
                                class: {
                                  switch_bth_active:
                                    item.value === _vm.searchParams.myHandle,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.changeFilter(item)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(item.label))]
                            )
                          }),
                          0
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "currentBtn rounded-4px bg-[#f5f5f5] text-13px p-6px",
                            class: {
                              switch_bth_active: !!_vm.searchParams.today,
                            },
                            on: { click: _vm.changeToday },
                          },
                          [_vm._v(" 当天 ")]
                        ),
                      ]),
                    ]
                  ),
                ]
              : _vm._e(),
            _vm.isZG
              ? [
                  _c(
                    "section",
                    {
                      staticClass:
                        "bg-white flex items-center justify-between workorder_wrapper",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "py-12px px-16px" },
                        [
                          _c(
                            "van-popover",
                            {
                              attrs: { trigger: "click" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "reference",
                                    fn: function () {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-16px font-medium leading-22px text-[#222]",
                                          },
                                          [_vm._v("工单类型")]
                                        ),
                                        _c("van-icon", {
                                          attrs: { name: "arrow-down" },
                                        }),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                1162684506
                              ),
                              model: {
                                value: _vm.showPopover,
                                callback: function ($$v) {
                                  _vm.showPopover = $$v
                                },
                                expression: "showPopover",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "divide-x" },
                                _vm._l(
                                  _vm.actionsOptionsZG,
                                  function (item, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass:
                                          "py-16px pl-17px space-x-16px relative",
                                        on: {
                                          click: function ($event) {
                                            return _vm.changeOrderType(item)
                                          },
                                        },
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(" " + _vm._s(item.text)),
                                        ]),
                                        _vm.orderTypeSelect(item.id)
                                          ? _c("van-icon", {
                                              staticClass:
                                                "absolute text-[#3366fe] right-12px top-1/2 transform -translate-y-1/2",
                                              attrs: { name: "success" },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "space-x-12px flex pr-12px" }, [
                        _c(
                          "div",
                          { staticClass: "rounded-4px bg-[#f5f5f5] text-13px" },
                          _vm._l(_vm.filterOptionsZG, function (item, index) {
                            return _c(
                              "span",
                              {
                                key: index,
                                staticClass: "switch_bth",
                                class: {
                                  switch_bth_active:
                                    item.value === _vm.searchParams.dataScope,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.changeFilter(item)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(item.label))]
                            )
                          }),
                          0
                        ),
                      ]),
                    ]
                  ),
                ]
              : _vm._e(),
            _c(
              "van-tabs",
              {
                staticClass: "state-count-tab shadow-md",
                attrs: {
                  animated: "",
                  color: "#3366FE",
                  "title-active-color": "#3366FE",
                  "title-inactive-color": "#7C7F8A",
                  "line-height": "2px",
                  "line-width": "56px",
                },
                on: { change: _vm.tabChange, disabled: _vm.tabDisabledHandler },
                model: {
                  value: _vm.searchParams.status,
                  callback: function ($$v) {
                    _vm.$set(_vm.searchParams, "status", $$v)
                  },
                  expression: "searchParams.status",
                },
              },
              [
                _c("van-tab", {
                  attrs: { disabled: !_vm.isOffline || _vm.loading, name: 1 },
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function () {
                        return [
                          _c("div", { staticClass: "tab-class" }, [
                            _c("div", [_vm._v("全部")]),
                            _c("div", [
                              _vm._v(_vm._s(`（${_vm.count.allCount}）`)),
                            ]),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
                !_vm.isJK
                  ? _c("van-tab", {
                      attrs: {
                        disabled: !_vm.isOffline || _vm.loading,
                        name: 2,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "title",
                            fn: function () {
                              return [
                                _c("div", { staticClass: "tab-class" }, [
                                  _c("div", [_vm._v("待领取")]),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(`（${_vm.count.toReceiveCount}）`)
                                    ),
                                  ]),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        3901304322
                      ),
                    })
                  : _vm._e(),
                _vm.isPK || _vm.isFM || _vm.isCJ
                  ? _c("van-tab", {
                      attrs: { disabled: _vm.loading, name: 3 },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "title",
                            fn: function () {
                              return [
                                _c("div", { staticClass: "tab-class" }, [
                                  _c("div", [_vm._v("未完成")]),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        `（${
                                          _vm.isOffline
                                            ? _vm.count.noFinishCount
                                            : _vm.offlineCount
                                        }）`
                                      )
                                    ),
                                  ]),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        1850502783
                      ),
                    })
                  : _vm._e(),
                _vm.isJK
                  ? _c("van-tab", {
                      attrs: { disabled: _vm.loading, name: 3 },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "title",
                            fn: function () {
                              return [
                                _c("div", { staticClass: "tab-class" }, [
                                  _c("div", [_vm._v("待检查")]),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(`（${_vm.count.toCheckCount}）`)
                                    ),
                                  ]),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        3044839642
                      ),
                    })
                  : _vm._e(),
                _vm.isPK || _vm.isFM || _vm.isCJ
                  ? _c("van-tab", {
                      attrs: {
                        disabled: !_vm.isOffline || _vm.loading,
                        name: 4,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "title",
                            fn: function () {
                              return [
                                _c("div", { staticClass: "tab-class" }, [
                                  _c("div", [_vm._v("已完成")]),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(`（${_vm.count.finishedCount}）`)
                                    ),
                                  ]),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        3755666651
                      ),
                    })
                  : _vm._e(),
                _vm.isZG
                  ? _c("van-tab", {
                      attrs: {
                        disabled: !_vm.isOffline || _vm.loading,
                        name: 3,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "title",
                            fn: function () {
                              return [
                                _c("div", { staticClass: "tab-class" }, [
                                  _c("div", [_vm._v("待处理")]),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(`（${_vm.count.toHandleCount}）`)
                                    ),
                                  ]),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        2329876401
                      ),
                    })
                  : _vm._e(),
                _vm.isZG
                  ? _c("van-tab", {
                      attrs: { disabled: _vm.loading, name: 4 },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "title",
                            fn: function () {
                              return [
                                _c("div", { staticClass: "tab-class" }, [
                                  _c("div", [_vm._v("处理中")]),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        `（${
                                          _vm.isOffline
                                            ? _vm.count.noFinishCount
                                            : _vm.offlineCount
                                        }）`
                                      )
                                    ),
                                  ]),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        997690118
                      ),
                    })
                  : _vm._e(),
                _vm.isPK || _vm.isFM || _vm.isCJ || _vm.isZG
                  ? _c("van-tab", {
                      attrs: {
                        disabled: !_vm.isOffline || _vm.loading,
                        name: 5,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "title",
                            fn: function () {
                              return [
                                _c("div", { staticClass: "tab-class" }, [
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(_vm.isZG ? "已完成" : "已关闭")
                                    ),
                                  ]),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        `（${
                                          _vm.isZG
                                            ? _vm.count.finishedCount
                                            : _vm.count.closedCount
                                        }）`
                                      )
                                    ),
                                  ]),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        235258470
                      ),
                    })
                  : _vm._e(),
                _vm.isJK
                  ? _c("van-tab", {
                      attrs: {
                        disabled: !_vm.isOffline || _vm.loading,
                        name: 6,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "title",
                            fn: function () {
                              return [
                                _c("div", { staticClass: "tab-class" }, [
                                  _c("div", [_vm._v(_vm._s("待复核"))]),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(`（${_vm.count.toRecheckCount}）`)
                                    ),
                                  ]),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        2697896560
                      ),
                    })
                  : _vm._e(),
              ],
              1
            ),
          ],
          2
        ),
        [
          _c(
            "van-pull-refresh",
            {
              on: { refresh: _vm.onRefresh },
              model: {
                value: _vm.refreshing,
                callback: function ($$v) {
                  _vm.refreshing = $$v
                },
                expression: "refreshing",
              },
            },
            [
              _c(
                "van-list",
                {
                  attrs: {
                    finished: _vm.finished,
                    "finished-text":
                      _vm.list.length > 0
                        ? "- 没有更多数据了 -"
                        : "您没有处理的工单",
                    offset: 50,
                    "immediate-check": false,
                  },
                  on: {
                    load: function ($event) {
                      return _vm.onLoad("list")
                    },
                  },
                  model: {
                    value: _vm.loading,
                    callback: function ($$v) {
                      _vm.loading = $$v
                    },
                    expression: "loading",
                  },
                },
                [
                  _c("CardList", {
                    ref: "CardList",
                    attrs: { list: _vm.list },
                    on: { expandMore: _vm.cardListExpandMore },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
      ],
      _c("div", { staticClass: "fix-btn" }, [
        _vm.isFM || _vm.isCJ
          ? _c("i", {
              staticClass: "created-icon icon",
              on: {
                click: function ($event) {
                  return _vm.handleCreateFMProgramRecord({ type: "ZC_SB" })
                },
              },
            })
          : _vm._e(),
        _c("i", {
          staticClass: "upload-icon icon",
          on: {
            click: function ($event) {
              return _vm.$router.push("/OfflineList")
            },
          },
        }),
        _c("i", {
          staticClass: "download-icon icon",
          on: { click: _vm.downLoadOfflineData },
        }),
      ]),
      _c(
        "van-popup",
        {
          staticClass: "circle-wrapper",
          attrs: { "close-on-click-overlay": false },
          model: {
            value: _vm.downloadShow,
            callback: function ($$v) {
              _vm.downloadShow = $$v
            },
            expression: "downloadShow",
          },
        },
        [
          _c("p", { staticClass: "title" }, [
            _vm._v("正在同步数据，请不要离开!"),
          ]),
          _c("van-circle", {
            attrs: {
              rate: 30,
              speed: 100,
              color: "#3366FE",
              "layer-color": "#EBECF0",
              "stroke-width": "70",
              text: "下载数据中",
            },
            model: {
              value: _vm.donloadCircle,
              callback: function ($$v) {
                _vm.donloadCircle = $$v
              },
              expression: "donloadCircle",
            },
          }),
          _c("p", { staticClass: "desc" }, [_vm._v("正在下载数据…")]),
        ],
        1
      ),
      _c(
        "van-popup",
        {
          staticClass: "circle-wrapper success-wrapper",
          attrs: { "close-on-click-overlay": false },
          model: {
            value: _vm.downloadSuccessShow,
            callback: function ($$v) {
              _vm.downloadSuccessShow = $$v
            },
            expression: "downloadSuccessShow",
          },
        },
        [
          _c("p", { staticClass: "title" }, [_vm._v("恭喜您，下载完成!!")]),
          _c("div", { staticClass: "success-icon" }),
          _c("p", { staticClass: "desc" }, [_vm._v("下载完成")]),
        ]
      ),
      _vm.FifterEquipmentShow
        ? _c("FifterEquipmentPop", {
            attrs: {
              show: _vm.FifterEquipmentShow,
              projectCodes: _vm.searchParams.projectCodes,
              isAssembly: true,
            },
            on: {
              "update:show": function ($event) {
                _vm.FifterEquipmentShow = $event
              },
            },
          })
        : _vm._e(),
      _c("select-reason", {
        attrs: { "data-val": _vm.searchParams, IsSmall: "" },
        on: { result: _vm.setReason },
        model: {
          value: _vm.selectReasonShow,
          callback: function ($$v) {
            _vm.selectReasonShow = $$v
          },
          expression: "selectReasonShow",
        },
      }),
      _vm.selectSmallReasonShow
        ? _c("select-reason", {
            attrs: {
              "data-val": _vm.searchParams,
              bigReasonId: _vm.bigCategoryId,
              IsSmall: "",
              noShowBtn: "",
            },
            on: { result: _vm.setSmallReason },
            model: {
              value: _vm.selectSmallReasonShow,
              callback: function ($$v) {
                _vm.selectSmallReasonShow = $$v
              },
              expression: "selectSmallReasonShow",
            },
          })
        : _vm._e(),
      _c("AcceptCheckGroupPop", {
        ref: "AcceptCheckGroupPop",
        attrs: {
          show: _vm.AcceptCheckGroupPopShow,
          "project-code": _vm.searchParams.projectCodes[0],
        },
        on: {
          confirm: _vm.AcceptCheckGroupPopConfirm,
          "update:show": function ($event) {
            _vm.AcceptCheckGroupPopShow = $event
          },
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }