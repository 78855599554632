<template>
  <van-popup v-model="showPicker" position="bottom" @close="close">
    <van-picker
      show-toolbar
      :columns="dictionaryList"
      @confirm="onConfirm"
      value-key="name"
      @cancel="close"
    />
  </van-popup>
</template>

<script>

import api from '@/api'

export default {
  name: 'BackToReportSysReasonSelect', // 工单退回
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showPicker: false,
      dictionaryList: [] // 退回原因字典
    }
  },
  watch: {
    show (show) {
      if (show) {
        this.showPicker = show
      }
    }
  },
  created () {
    this.getReturnReason()
  },
  methods: {
    /* 获取数据字典 */
    async getReturnReason () {
      const data = await this.$http.get(api.getDicList, {
        dicCode: 'returnReason'
      })
      this.dictionaryList = data
      this.dictionaryList.forEach((item, index) => {
        if (item.status === 0) { // 删除禁用的项
          this.dictionaryList.splice(index, 1)
        }
      })
    },
    onConfirm (val) {
      this.$emit('confirm', val)
      this.close()
    },
    close () {
      this.showPicker = false
      this.$emit('update:show', false)
    }
  }
}
</script>

<style lang="less" scoped>
</style>
