var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "OrderSpotCheck" },
    [
      _c("my-nav", {
        attrs: { blue: "", title: "工单抽查", "self-back": true },
        on: { back: _vm.goBack },
      }),
      _c(
        "van-sticky",
        { attrs: { "offset-top": _vm.navBarHeight } },
        [
          _c("DashboardProjectSelect", {
            ref: "DashboardProjectSelect",
            on: { selectProject: _vm.selectProject },
          }),
          _c(
            "van-tabs",
            {
              staticClass: "orderStatusTab",
              attrs: {
                animated: "",
                color: "#3366FE",
                "title-active-color": "#3366FE",
                "title-inactive-color": "#7C7F8A",
                "line-height": "2px",
                "line-width": "56px",
              },
              on: { change: _vm.tabChange },
              model: {
                value: _vm.searchParams.status,
                callback: function ($$v) {
                  _vm.$set(_vm.searchParams, "status", $$v)
                },
                expression: "searchParams.status",
              },
            },
            [
              _c("van-tab", {
                attrs: { name: 1 },
                scopedSlots: _vm._u([
                  {
                    key: "title",
                    fn: function () {
                      return [
                        _c("div", { staticClass: "tab-class" }, [
                          _c("div", [_vm._v("全部")]),
                          _c("div", [
                            _vm._v(_vm._s(`（${_vm.count["total"]}）`)),
                          ]),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c("van-tab", {
                attrs: { name: 2 },
                scopedSlots: _vm._u([
                  {
                    key: "title",
                    fn: function () {
                      return [
                        _c("div", { staticClass: "tab-class" }, [
                          _c("div", [_vm._v("待办")]),
                          _c("div", [
                            _vm._v(_vm._s(`（${_vm.count["toCheck"]}）`)),
                          ]),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c("van-tab", {
                attrs: { name: 3 },
                scopedSlots: _vm._u([
                  {
                    key: "title",
                    fn: function () {
                      return [
                        _c("div", { staticClass: "tab-class" }, [
                          _c("div", [_vm._v("已抽查")]),
                          _c("div", [
                            _vm._v(_vm._s(`（${_vm.count["spotChecked"]}）`)),
                          ]),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c("van-tab", {
                attrs: { name: 4 },
                scopedSlots: _vm._u([
                  {
                    key: "title",
                    fn: function () {
                      return [
                        _c("div", { staticClass: "tab-class" }, [
                          _c("div", [_vm._v("已过期")]),
                          _c("div", [
                            _vm._v(_vm._s(`（${_vm.count["expired"]}）`)),
                          ]),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "van-pull-refresh",
        {
          on: { refresh: _vm.onRefresh },
          model: {
            value: _vm.refreshing,
            callback: function ($$v) {
              _vm.refreshing = $$v
            },
            expression: "refreshing",
          },
        },
        [
          _c(
            "van-list",
            {
              attrs: {
                "immediate-check": false,
                offset: 50,
                finished: _vm.finished,
                "finished-text":
                  _vm.list.length > 0
                    ? "- 没有更多数据了 -"
                    : "您没有处理的工单",
              },
              on: {
                load: function ($event) {
                  return _vm.onLoad("list")
                },
              },
              model: {
                value: _vm.loading,
                callback: function ($$v) {
                  _vm.loading = $$v
                },
                expression: "loading",
              },
            },
            _vm._l(_vm.list, function (item, index) {
              return _c(
                "div",
                { key: index, staticClass: "order" },
                [
                  _c("div", { staticClass: "order_header" }, [
                    _c("div", { staticClass: "left" }, [
                      _c("span", [_vm._v(_vm._s(item.orderNo))]),
                      _c(
                        "span",
                        {
                          staticClass: "copy",
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              $event.stopPropagation()
                              return _vm.copyOrderNumber(item.orderNo)
                            },
                          },
                        },
                        [_vm._v(" 复制 ")]
                      ),
                    ]),
                    _c("span", { staticClass: "status" }, [
                      _vm._v(_vm._s(_vm.orderStatusName(item.status))),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "order_main" },
                    [
                      _c(
                        "van-row",
                        [
                          _c("van-col", { attrs: { span: "6" } }, [
                            _vm._v("抽查名称："),
                          ]),
                          _c("van-col", { attrs: { span: "18" } }, [
                            _c("span", [_vm._v(_vm._s(item.ruleName))]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "van-row",
                        [
                          _c("van-col", { attrs: { span: "6" } }, [
                            _vm._v("抽查项分类："),
                          ]),
                          _c("van-col", { attrs: { span: "18" } }, [
                            _vm._v(_vm._s(item.classifyName)),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "van-row",
                        [
                          _c("van-col", { attrs: { span: "6" } }, [
                            _vm._v("抽查项目："),
                          ]),
                          _c("van-col", { attrs: { span: "18" } }, [
                            _vm._v(_vm._s(item.projectName)),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "van-row",
                        [
                          _c("van-col", { attrs: { span: "6" } }, [
                            _vm._v("开始时间："),
                          ]),
                          _c("van-col", { attrs: { span: "18" } }, [
                            _vm._v(_vm._s(item.planStart)),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "van-row",
                        [
                          _c("van-col", { attrs: { span: "6" } }, [
                            _vm._v("结束时间："),
                          ]),
                          _c("van-col", { attrs: { span: "18" } }, [
                            _vm._v(_vm._s(item.planEnd)),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "van-row",
                        [
                          _c("van-col", { attrs: { span: "6" } }, [
                            _vm._v("执行人："),
                          ]),
                          _c("van-col", { attrs: { span: "18" } }, [
                            _vm._v(_vm._s(item.executorName)),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "van-collapse",
                    {
                      model: {
                        value: item.activeNames,
                        callback: function ($$v) {
                          _vm.$set(item, "activeNames", $$v)
                        },
                        expression: "item.activeNames",
                      },
                    },
                    [
                      _c(
                        "van-collapse-item",
                        {
                          attrs: { name: "1" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "right-icon",
                                fn: function () {
                                  return [
                                    !item.loading && !item.activeNames.length
                                      ? _c("van-icon", {
                                          attrs: { name: "arrow-down" },
                                        })
                                      : _vm._e(),
                                    !item.loading && item.activeNames.length
                                      ? _c("van-icon", {
                                          attrs: { name: "arrow-up" },
                                        })
                                      : _vm._e(),
                                    item.loading
                                      ? _c("img", {
                                          staticClass: "loading-svg",
                                          attrs: {
                                            src: require("@/asset/loading.svg"),
                                            alt: "",
                                          },
                                        })
                                      : _vm._e(),
                                  ]
                                },
                                proxy: true,
                              },
                              {
                                key: "title",
                                fn: function () {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "collapse-title",
                                        on: {
                                          click: function ($event) {
                                            return _vm.loadTaskList(item, index)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "count-list" },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "count-label" },
                                              [_vm._v("任务数量:")]
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "count" },
                                              [_vm._v(_vm._s(item.orderNum))]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "count-list" },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "count-label" },
                                              [_vm._v("已抽查:")]
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "count" },
                                              [_vm._v(_vm._s(item.checkedNum))]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        _vm._l(item.taskList, function (task, i) {
                          return _c(
                            "div",
                            { key: i, staticClass: "collapse-content" },
                            [
                              _c("div", { staticClass: "header" }, [
                                _c(
                                  "span",
                                  {
                                    staticClass: "text-[#3366fe]",
                                    on: {
                                      click: function ($event) {
                                        return _vm.routerToOrderDetail(task)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(task.orderNo))]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass: "copy",
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        $event.stopPropagation()
                                        return _vm.copyOrderNumber(task.orderNo)
                                      },
                                    },
                                  },
                                  [_vm._v(" 复制 ")]
                                ),
                              ]),
                              _c(
                                "van-row",
                                { staticClass: "mb-8px" },
                                [
                                  _c("van-col", { attrs: { span: "8" } }, [
                                    _vm._v("作业方式："),
                                  ]),
                                  _c(
                                    "van-col",
                                    {
                                      staticClass: "text-[#222222]",
                                      attrs: { span: "16" },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(task.operateType)),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "van-row",
                                { staticClass: "mb-8px" },
                                [
                                  _c("van-col", { attrs: { span: "8" } }, [
                                    _vm._v("任务处理人："),
                                  ]),
                                  _c(
                                    "van-col",
                                    {
                                      staticClass: "text-[#222222]",
                                      attrs: { span: "16" },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(task.handlerName)),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "van-row",
                                [
                                  _c("van-col", { attrs: { span: "24" } }, [
                                    _vm._v("抽查点位/设施设备列表："),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "table",
                                {
                                  staticClass: "table",
                                  staticStyle: { width: "100%" },
                                  attrs: { cellspacing: "0", border: "0" },
                                },
                                _vm._l(
                                  task.taskItems.slice(0, task.taskShowNum),
                                  function (position, pi) {
                                    return _c(
                                      "tr",
                                      {
                                        key: pi,
                                        staticClass: "list-tr",
                                        class: {
                                          lastrow:
                                            index === _vm.tableData.length - 1,
                                        },
                                      },
                                      [
                                        _c("td", [
                                          _vm._v(
                                            " " +
                                              _vm._s(position.itemName) +
                                              " "
                                          ),
                                        ]),
                                        _c(
                                          "td",
                                          {
                                            staticClass: "lastCol button",
                                            staticStyle: { width: "120px" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleClickTaskItem(
                                                  position,
                                                  task,
                                                  item
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  position.status === 2
                                                    ? position.isPass === 1
                                                      ? "合格"
                                                      : "不合格"
                                                    : position.status === 1
                                                    ? "抽查"
                                                    : ""
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                              task.taskShowNum < task.taskItems.length
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "viewMore",
                                      on: {
                                        click: function ($event) {
                                          return _vm.viewMore(task)
                                        },
                                      },
                                    },
                                    [
                                      _c("span", [_vm._v("查看更多")]),
                                      _c("van-icon", {
                                        attrs: { name: "arrow-down" },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            0
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }