var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "create-work" },
    [
      _c("my-nav", {
        attrs: { title: "创建工单", "self-back": true },
        on: { back: _vm.goBack },
      }),
      _c("div", { staticClass: "separator_block" }),
      _c(
        "van-tabs",
        {
          attrs: {
            animated: "",
            color: "#3366FE",
            sticky: "",
            "offset-top": "44",
            "title-active-color": "#3366FE",
            "title-inactive-color": "#7C7F8A",
            "line-height": "2px",
            "line-width": "70px",
          },
          on: { click: _vm.clickTabs },
          model: {
            value: _vm.tabActive,
            callback: function ($$v) {
              _vm.tabActive = $$v
            },
            expression: "tabActive",
          },
        },
        [
          _c("van-tab", { attrs: { title: "自查报事" } }),
          _c("van-tab", { attrs: { title: "业主报事" } }),
        ],
        1
      ),
      _vm.tabActive === 1
        ? _c("van-field", {
            staticClass: "form-input",
            attrs: {
              readonly: "",
              value: _vm.form.reportUserName,
              label: "报事业主",
              required: "",
              "right-icon": "arrow",
              "input-align": "right",
              placeholder: "请选择",
            },
            on: { click: _vm.clickReportUser },
          })
        : _vm._e(),
      _vm.tabActive === 1
        ? _c("van-field", {
            staticClass: "form-input",
            attrs: {
              value: _vm.form.reportUserPhone,
              label: "报事业主电话",
              required: "",
              "input-align": "right",
              placeholder: "请输入业主电话",
            },
          })
        : _vm._e(),
      _c("van-field", {
        staticClass: "form-input",
        attrs: {
          readonly: "",
          value: _vm.form.incidentPlaceName,
          label: "报事位置",
          required: "",
          "right-icon": "arrow",
          "input-align": "right",
          placeholder: "请选择",
        },
        on: { click: _vm.clickIncidetPlace },
      }),
      _c("van-field", {
        staticClass: "form-textarea",
        attrs: {
          name: "remark",
          label: "报事描述",
          rows: "3",
          required: "",
          type: "textarea",
          placeholder: "请描述报事内容，如1单元楼栋单元门异响",
        },
        scopedSlots: _vm._u(
          [
            _vm.isOffline
              ? {
                  key: "right-icon",
                  fn: function () {
                    return [
                      _c("micro-text", { on: { speakRes: _vm.speakRes } }),
                    ]
                  },
                  proxy: true,
                }
              : null,
          ],
          null,
          true
        ),
        model: {
          value: _vm.form.remark,
          callback: function ($$v) {
            _vm.$set(_vm.form, "remark", $$v)
          },
          expression: "form.remark",
        },
      }),
      _c(
        "div",
        { staticStyle: { padding: "0 16px 16px", background: "#fff" } },
        [
          _c("common-upload", {
            ref: "offlineUpload",
            attrs: {
              "max-count": 6,
              file: _vm.form.attachmentList,
              "project-name": _vm.form.projectName,
              required: false,
              "enable-album": true,
              "enable-edit": true,
              "enable-watermark": false,
            },
            on: {
              "update:file": function ($event) {
                return _vm.$set(_vm.form, "attachmentList", $event)
              },
            },
          }),
        ],
        1
      ),
      _vm.tabActive === 1
        ? _c("van-field", {
            staticClass: "form-input",
            attrs: {
              readonly: "",
              label: "是否投诉",
              required: "",
              "right-icon": "arrow",
              "input-align": "right",
              placeholder: "请选择",
            },
            on: { click: () => (_vm.showIsTouSu = true) },
            model: {
              value: _vm.form.isTouSuName,
              callback: function ($$v) {
                _vm.$set(_vm.form, "isTouSuName", $$v)
              },
              expression: "form.isTouSuName",
            },
          })
        : _vm._e(),
      _c(
        "van-popup",
        {
          attrs: { position: "bottom" },
          model: {
            value: _vm.showIsTouSu,
            callback: function ($$v) {
              _vm.showIsTouSu = $$v
            },
            expression: "showIsTouSu",
          },
        },
        [
          _c("van-picker", {
            attrs: {
              "show-toolbar": "",
              columns: _vm.isTouSuList,
              "value-key": "name",
            },
            on: {
              confirm: _vm.showIsTouSuOK,
              cancel: function ($event) {
                _vm.showIsTouSu = false
              },
            },
          }),
        ],
        1
      ),
      _c("van-field", {
        staticClass: "form-input",
        attrs: {
          readonly: "",
          clickable: "",
          label: "报事类型",
          required: "",
          "right-icon": "arrow",
          "input-align": "right",
          placeholder: "请选择",
        },
        on: { click: () => (_vm.showReportType = true) },
        model: {
          value: _vm.form.drClassName,
          callback: function ($$v) {
            _vm.$set(_vm.form, "drClassName", $$v)
          },
          expression: "form.drClassName",
        },
      }),
      _c(
        "van-popup",
        {
          attrs: { position: "bottom" },
          model: {
            value: _vm.showReportType,
            callback: function ($$v) {
              _vm.showReportType = $$v
            },
            expression: "showReportType",
          },
        },
        [
          _c("van-picker", {
            attrs: {
              "show-toolbar": "",
              columns: _vm.reportTypeList,
              "value-key": "name",
            },
            on: {
              confirm: _vm.showReportTypeOK,
              cancel: function ($event) {
                _vm.showReportType = false
              },
            },
          }),
        ],
        1
      ),
      _c("van-field", {
        staticClass: "form-input",
        attrs: {
          readonly: "",
          clickable: "",
          value: _vm.form.bigCategoryName,
          label: "报事类别",
          required: "",
          "right-icon": "arrow",
          "input-align": "right",
          placeholder: "请选择",
        },
        on: { click: _vm.bigCategoryClick },
      }),
      _c("van-field", {
        staticClass: "form-input",
        attrs: {
          readonly: "",
          clickable: "",
          value: _vm.form.smallCategoryName,
          label: "报事细类",
          required: _vm.form.drClass === 2,
          "right-icon": "arrow",
          "input-align": "right",
          placeholder: "请选择",
        },
        on: { click: _vm.smallCorpTypeClick },
      }),
      _c("van-field", {
        staticClass: "form-input",
        attrs: {
          readonly: "",
          clickable: "",
          value: _vm.form.infoSource,
          label: "报事方式",
          required: "",
          "right-icon": "arrow",
          "input-align": "right",
          placeholder: "请选择",
        },
        on: { click: () => (_vm.showReportWay = true) },
      }),
      _c(
        "van-popup",
        {
          attrs: { position: "bottom" },
          model: {
            value: _vm.showReportWay,
            callback: function ($$v) {
              _vm.showReportWay = $$v
            },
            expression: "showReportWay",
          },
        },
        [
          _c("van-picker", {
            attrs: { "show-toolbar": "", columns: _vm.reportWayList },
            on: {
              confirm: _vm.showReportWayOK,
              cancel: function ($event) {
                _vm.showReportWay = false
              },
            },
          }),
        ],
        1
      ),
      _c("van-field", {
        staticClass: "form-input",
        attrs: {
          readonly: "",
          clickable: "",
          value: _vm.form.handlerName,
          label: "处理人",
          required: _vm.form.drClass === 2,
          "right-icon": _vm.form.handlerName ? "clear" : "arrow",
          "input-align": "right",
          placeholder: "请选择",
          clearable: "",
        },
        on: {
          click: _vm.handlerClick,
          "click-right-icon": _vm.clearHandlerName,
        },
      }),
      _c(
        "van-popup",
        {
          attrs: { position: "bottom" },
          model: {
            value: _vm.showHandler,
            callback: function ($$v) {
              _vm.showHandler = $$v
            },
            expression: "showHandler",
          },
        },
        [
          _c("van-picker", {
            attrs: {
              "show-toolbar": "",
              "value-key": "userName",
              columns: _vm.handlerList,
            },
            on: {
              confirm: _vm.showHandlerOK,
              cancel: function ($event) {
                _vm.showHandler = false
              },
            },
          }),
        ],
        1
      ),
      _c("van-field", {
        staticClass: "form-input",
        attrs: {
          readonly: "",
          name: "grade",
          value: _vm.form.bookingTime,
          label: "预约时间",
          required: "",
          "right-icon": "arrow",
          "input-align": "right",
          placeholder: "请选择",
        },
        on: {
          click: function ($event) {
            _vm.bookingTimeShow = true
          },
        },
      }),
      _c(
        "van-popup",
        {
          attrs: { position: "bottom", "safe-area-inset-bottom": "" },
          model: {
            value: _vm.bookingTimeShow,
            callback: function ($$v) {
              _vm.bookingTimeShow = $$v
            },
            expression: "bookingTimeShow",
          },
        },
        [
          _c("van-datetime-picker", {
            attrs: {
              type: "datetime",
              title: "选择预约时间",
              "min-date": _vm.minBookingTimeDate,
              "columns-order": [
                "year",
                "month",
                "day",
                "hour",
                "minute",
                "second",
              ],
              formatter: _vm.formatter,
            },
            on: {
              cancel: function ($event) {
                _vm.bookingTimeShow = false
              },
              confirm: _vm.bookingTimeConfirm,
            },
            model: {
              value: _vm.bookingTime,
              callback: function ($$v) {
                _vm.bookingTime = $$v
              },
              expression: "bookingTime",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "button_row" },
        [
          _c(
            "van-button",
            {
              staticClass: "button",
              attrs: {
                type: "primary",
                "loading-type": "spinner",
                "loading-text": "提交中...",
              },
              on: { click: _vm.createSubmit },
            },
            [_vm._v("提交")]
          ),
        ],
        1
      ),
      _c("SelectReportPosition", {
        ref: "selectProject",
        attrs: {
          incidentSource: _vm.form.incidentSource,
          show: _vm.selectProjectShow,
        },
        on: {
          "update:show": function ($event) {
            _vm.selectProjectShow = $event
          },
          result: _vm.selectProjectConfim,
        },
      }),
      _c("SelectReasonTree", {
        ref: "SelectReasonTreeRef",
        attrs: {
          title: _vm.selectReasonTitle,
          show: _vm.selectReasonShow,
          projectCode: _vm.form.projectCode,
          isBigType: _vm.isBigType,
          duty: _vm.form.duty,
          typeCode: _vm.typeCode,
          incidentPlace: _vm.form.incidentPlace,
          drClass: _vm.form.drClass,
        },
        on: {
          "update:show": function ($event) {
            _vm.selectReasonShow = $event
          },
          result: _vm.selectReasonConfim,
        },
      }),
      _c("SelectIncidetPlace", {
        ref: "SelectIncidetPlace",
        attrs: {
          show: _vm.selectIncidetPlaceShow,
          projectCode: _vm.form.projectCode,
          projectName: _vm.form.projectName,
        },
        on: {
          "update:show": function ($event) {
            _vm.selectIncidetPlaceShow = $event
          },
          result: _vm.selectIncidetPlaceConfim,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }