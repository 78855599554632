var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "van-popup",
    {
      style: { width: "100%", height: "100%" },
      attrs: {
        position: "right",
        overlay: false,
        duration: _vm.animateDuration,
      },
      on: {
        open: _vm.selectProjectOpenHandler,
        close: _vm.selectProjectCloseHandler,
      },
      model: {
        value: _vm._show,
        callback: function ($$v) {
          _vm._show = $$v
        },
        expression: "_show",
      },
    },
    [
      _c(
        "div",
        { staticClass: "select-project-wrapper" },
        [
          _c("my-nav", {
            attrs: {
              title:
                _vm.confirmType === "member" ? "选择报事客户" : "选择所在位置",
              "self-back": true,
            },
            on: { back: _vm.goBack },
          }),
          _c("van-search", {
            staticClass: "search-wrapper",
            attrs: { placeholder: "请输入关键字" },
            on: { search: _vm.customerSearch, clear: _vm.clearSearch },
            model: {
              value: _vm.searchValue,
              callback: function ($$v) {
                _vm.searchValue = $$v
              },
              expression: "searchValue",
            },
          }),
          _vm.showLevel === "project"
            ? _c(
                "div",
                { staticClass: "project-list-wrapper" },
                _vm._l(_vm.projectList, function (item) {
                  return _c(
                    "div",
                    {
                      key: item.deptCode,
                      staticClass: "project-list",
                      on: {
                        click: function ($event) {
                          return _vm.changeProjectCheck(item)
                        },
                      },
                    },
                    [
                      _c("p", { staticClass: "name" }, [
                        _vm._v(_vm._s(item.deptName)),
                      ]),
                      _c("i", { staticClass: "right-icon" }),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
          _vm.showLevel === "groops"
            ? _c(
                "div",
                { staticClass: "project-list-wrapper" },
                _vm._l(_vm.buildingCodeOptions, function (item) {
                  return _c(
                    "div",
                    {
                      key: item.buildNo,
                      staticClass: "project-list",
                      on: {
                        click: function ($event) {
                          return _vm.changeGroopCheck(item)
                        },
                      },
                    },
                    [
                      _c("p", { staticClass: "name" }, [
                        _vm._v(_vm._s(item.buildName)),
                      ]),
                      _c("i", { staticClass: "right-icon" }),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
          _vm.showLevel === "builds"
            ? _c(
                "div",
                { staticClass: "project-list-wrapper" },
                _vm._l(_vm.buildingCodeOptions, function (item) {
                  return _c(
                    "div",
                    {
                      key: item.buildNo,
                      staticClass: "project-list",
                      on: {
                        click: function ($event) {
                          return _vm.changeBuildCheck(item)
                        },
                      },
                    },
                    [
                      _c("p", { staticClass: "name" }, [
                        _vm._v(_vm._s(item.buildName)),
                      ]),
                      _c("i", { staticClass: "right-icon" }),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
          _vm.showLevel === "units"
            ? _c(
                "div",
                { staticClass: "project-list-wrapper" },
                _vm._l(_vm.unitsList, function (item) {
                  return _c(
                    "div",
                    {
                      key: item.unitNo,
                      staticClass: "project-list",
                      on: {
                        click: function ($event) {
                          return _vm.changeUnitCheck(item)
                        },
                      },
                    },
                    [
                      _c("p", { staticClass: "name" }, [
                        _vm._v(_vm._s(item.unitName)),
                      ]),
                      _c("i", { staticClass: "right-icon" }),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
          _vm.showLevel === "floors"
            ? _c(
                "div",
                { staticClass: "project-list-wrapper" },
                _vm._l(_vm.floorsList, function (item) {
                  return _c(
                    "div",
                    {
                      key: item.floorNo,
                      staticClass: "project-list",
                      on: {
                        click: function ($event) {
                          return _vm.changeFloorCheck(item)
                        },
                      },
                    },
                    [
                      _c("p", { staticClass: "name" }, [
                        _vm._v(_vm._s(item.floorName)),
                      ]),
                      _c("i", { staticClass: "right-icon" }),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
          _vm.showLevel === "house"
            ? _c(
                "div",
                { staticClass: "project-list-wrapper" },
                _vm._l(_vm.houseList, function (item) {
                  return _c(
                    "div",
                    {
                      key: item.houseId,
                      staticClass: "project-list",
                      on: {
                        click: function ($event) {
                          return _vm.changeHouseCheck(item)
                        },
                      },
                    },
                    [
                      _c("p", { staticClass: "name" }, [
                        _vm._v(_vm._s(item.houseName)),
                      ]),
                      _vm.confirmType === "member"
                        ? _c("i", { staticClass: "right-icon" })
                        : _vm._e(),
                      _vm.confirmType === "house" &&
                      _vm.selectHouse &&
                      _vm.selectHouse.houseId === item.houseId
                        ? _c("i", { staticClass: "check-icon" })
                        : _vm._e(),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
          _vm.showLevel === "member"
            ? _c(
                "div",
                { staticClass: "project-list-wrapper" },
                [
                  _c(
                    "van-tabs",
                    [
                      _c(
                        "van-tab",
                        { attrs: { title: "客户列表" } },
                        _vm._l(_vm.memberList, function (item) {
                          return _c(
                            "div",
                            {
                              key:
                                item.memberName +
                                item.houseCode +
                                item.houseId +
                                Math.random(),
                              staticClass: "project-list",
                              on: {
                                click: function ($event) {
                                  return _vm.changeMemberCheck(item)
                                },
                              },
                            },
                            [
                              _c("p", { staticClass: "name" }, [
                                _vm._v(
                                  _vm._s(
                                    `${item.memberName} ${item.linkmanMobile} ${item.relationShip}`
                                  )
                                ),
                              ]),
                              _vm.selectMember &&
                              _vm.selectMember.memberName === item.memberName &&
                              _vm.selectMember.houseId === item.houseId
                                ? _c("i", { staticClass: "check-icon" })
                                : _vm._e(),
                            ]
                          )
                        }),
                        0
                      ),
                      _c(
                        "van-tab",
                        { attrs: { title: "近期报事工单" } },
                        [
                          _c(
                            "van-list",
                            {
                              attrs: {
                                finished:
                                  _vm.threeMonthReportEventOrdersLoadAll,
                                "finished-text": "没有更多了",
                              },
                              on: {
                                load: _vm.threeMonthReportEventOrdersLoadingOnLoad,
                              },
                              model: {
                                value: _vm.threeMonthReportEventOrdersLoading,
                                callback: function ($$v) {
                                  _vm.threeMonthReportEventOrdersLoading = $$v
                                },
                                expression:
                                  "threeMonthReportEventOrdersLoading",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "order-list" },
                                _vm._l(
                                  _vm.threeMonthReportEventOrders,
                                  function (order) {
                                    return _c("problemOrderCardVue", {
                                      key: order.id,
                                      staticStyle: { "margin-top": "8px" },
                                      attrs: { order: order },
                                      on: { click: _vm.clickOrder },
                                    })
                                  }
                                ),
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.routerListShow
            ? _c("div", { staticClass: "router-wrapper" }, [
                _c(
                  "div",
                  { staticClass: "list-wrapper" },
                  _vm._l(_vm.routerList, function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "router-list" },
                      [
                        _c(
                          "p",
                          {
                            staticClass: "name",
                            class: {
                              last: index === _vm.routerList.length - 1,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.checkRouter(
                                  index < _vm.routerList.length - 1,
                                  item
                                )
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(item.label) + " ")]
                        ),
                        index < _vm.routerList.length - 1
                          ? _c("i", { staticClass: "router-icon" })
                          : _vm._e(),
                      ]
                    )
                  }),
                  0
                ),
              ])
            : _vm._e(),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.confirmType === "member" && _vm.showLevel === "member",
                  expression:
                    "confirmType === 'member' && showLevel === 'member'",
                },
              ],
              staticClass: "submit-wrapper",
              on: { click: _vm.submit },
            },
            [_c("p", { staticClass: "text" }, [_vm._v("确定")])]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.confirmType === "house" && _vm.showLevel === "house",
                  expression:
                    " confirmType === 'house' && showLevel === 'house'",
                },
              ],
              staticClass: "submit-wrapper",
              on: { click: _vm.submit },
            },
            [_c("p", { staticClass: "text" }, [_vm._v("确定")])]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }