var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "multi-select-wrapper" },
    [
      !_vm.detail
        ? _c(
            "div",
            {
              staticClass: "select-wrapper",
              on: { click: _vm.showPickerPopup },
            },
            [
              _c("div", { staticClass: "value-wrapper" }, [
                _vm.activeListName.length === 0
                  ? _c("span", { staticClass: "placeholder" }, [
                      _vm._v("请选择"),
                    ])
                  : _c(
                      "div",
                      { staticClass: "tag-list" },
                      _vm._l(_vm.activeListName, function (item, index) {
                        return _c(
                          "van-tag",
                          {
                            key: index,
                            staticClass: "mr-8",
                            attrs: {
                              size: "large",
                              color: "rgb(51 102 254 / 10%)",
                              "text-color": "#3366FE",
                              disabled: _vm.detail,
                            },
                          },
                          [_vm._v(_vm._s(item))]
                        )
                      }),
                      1
                    ),
              ]),
              _c("van-icon", { attrs: { name: "right-icon" } }),
            ],
            1
          )
        : _c("div", { staticClass: "select-wrapper bg-[#f7f8fa]" }, [
            _vm.activeListName.length === 0
              ? _c("span", { staticClass: "placeholder" }, [_vm._v("请选择")])
              : _vm._e(),
          ]),
      _c(
        "van-popup",
        {
          attrs: { round: "", position: "bottom" },
          model: {
            value: _vm.showPicker,
            callback: function ($$v) {
              _vm.showPicker = $$v
            },
            expression: "showPicker",
          },
        },
        [
          _c("div", { staticClass: "popup-header" }, [
            _c(
              "p",
              { staticClass: "btn close", on: { click: _vm.handleClose } },
              [_vm._v("取消")]
            ),
            _c("p", { staticClass: "text" }, [_vm._v("多选")]),
            _c(
              "p",
              { staticClass: "btn submit", on: { click: _vm.handleSubmit } },
              [_vm._v("确定")]
            ),
          ]),
          _c("div", { staticClass: "popup-content" }, [
            _c(
              "div",
              { staticClass: "list-wrapper" },
              _vm._l(_vm.selectList, function (item) {
                return _c(
                  "div",
                  {
                    key: item[_vm.selectObj.key],
                    staticClass: "content-list",
                    class: {
                      active: _vm.selectActiveList.includes(
                        item[_vm.selectObj.key]
                      ),
                    },
                    on: {
                      click: function ($event) {
                        return _vm.changeActive(item)
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(item[_vm.selectObj.val]) + " ")]
                )
              }),
              0
            ),
          ]),
          _c(
            "div",
            { staticClass: "popup-footer" },
            [
              _c(
                "van-button",
                { attrs: { type: "default" }, on: { click: _vm.reset } },
                [_vm._v("重置")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }