var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "van-popup",
    {
      staticClass: "slelect-person",
      style: { width: "100%", height: "85%" },
      attrs: { position: "bottom" },
      on: { "click-overlay": _vm.closePopup },
      model: {
        value: _vm.popupShow,
        callback: function ($$v) {
          _vm.popupShow = $$v
        },
        expression: "popupShow",
      },
    },
    [
      _c("div", { staticClass: "select-pearson-wrapper" }, [
        _c("div", { staticClass: "select-position-header clear" }, [
          _vm._v(" 整改人" + _vm._s(_vm.listData.length) + " "),
          _c(
            "div",
            {
              staticClass: "btn-close pull-right",
              on: { click: _vm.closePopup },
            },
            [
              _c("van-icon", {
                staticStyle: { "vertical-align": "top" },
                attrs: { name: "cross", color: "#000", size: "16px" },
              }),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "select-positon-content" },
          [
            _c(
              "vuescroll",
              { ref: "vsposition", attrs: { ops: _vm.ops } },
              [
                _c(
                  "van-collapse",
                  {
                    model: {
                      value: _vm.activeNames,
                      callback: function ($$v) {
                        _vm.activeNames = $$v
                      },
                      expression: "activeNames",
                    },
                  },
                  _vm._l(_vm.listData, function (item, index) {
                    return _c(
                      "van-collapse-item",
                      {
                        key: index,
                        attrs: {
                          title: `${item.rectifyRoleName}(${item.rectifyPeopleList.length})`,
                        },
                      },
                      _vm._l(item.rectifyPeopleList, function (user) {
                        return _c(
                          "div",
                          {
                            key: user.rectifyCode,
                            staticClass: "select-item",
                            on: {
                              click: function ($event) {
                                return _vm.selectPearson(user)
                              },
                            },
                          },
                          [
                            _vm._v(" " + _vm._s(user.rectifyName) + " "),
                            _vm.value && _vm.value.code === user.rectifyCode
                              ? _c("van-icon", {
                                  staticClass: "success-icon pull-right",
                                  attrs: { name: "success", size: "24px" },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      }),
                      0
                    )
                  }),
                  1
                ),
                _vm.listData.length === 0
                  ? _c("div", { staticClass: "no-data" }, [_vm._v("暂无数据")])
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }