var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ReportTousuList" },
    [
      _c("my-nav", { attrs: { title: "投诉还原详情" } }),
      !_vm.incidentTouSu || _vm.incidentTouSu.length === 0
        ? _c("div", { staticClass: "empty" }, [
            _c("div", { staticClass: "img" }),
            _c("span", [_vm._v("暂无数据")]),
          ])
        : _c(
            "div",
            { staticClass: "ReportTousuList-content" },
            _vm._l(_vm.incidentTouSu, function (item, index) {
              return _c("div", { key: index, staticClass: "tousu-item" }, [
                _c("div", { staticClass: "mt-4px" }, [
                  _c("span", { staticClass: "label" }, [_vm._v("投诉性质：")]),
                  _c("span", [_vm._v(_vm._s(item.complaintType) + "投诉")]),
                ]),
                _c("div", { staticClass: "mt-4px" }, [
                  _c("span", { staticClass: "label" }, [_vm._v("投诉等级：")]),
                  _c("span", [_vm._v(_vm._s(item.touSuDegree))]),
                ]),
                _c("div", { staticClass: "mt-4px" }, [
                  _c("span", { staticClass: "label" }, [_vm._v("操作人：")]),
                  _c("span", [_vm._v(_vm._s(item.userName))]),
                ]),
                _c("div", { staticClass: "mt-4px" }, [
                  _c("span", { staticClass: "label" }, [_vm._v("操作时间：")]),
                  _c("span", [_vm._v(_vm._s(item.dateTime))]),
                ]),
                _c("div", { staticClass: "mt-4px" }, [
                  _c("div", { staticClass: "label" }, [
                    _vm._v("投诉还原内容："),
                  ]),
                  _c("div", [_vm._v(_vm._s(item.touSuReasons))]),
                ]),
                item.touSuImg && item.touSuImg.length > 0
                  ? _c("div", { staticClass: "mt-4px" }, [
                      _c("div", { staticClass: "label" }, [_vm._v("图片：")]),
                      _c(
                        "div",
                        _vm._l(item.touSuImg, function (img, index) {
                          return _c("van-image", {
                            key: index,
                            staticClass: "photo mr-6px mt-6px",
                            attrs: {
                              width: "40",
                              height: "40",
                              radius: "4",
                              src: img,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.imgPreview(index, item.touSuImg)
                              },
                            },
                          })
                        }),
                        1
                      ),
                    ])
                  : _vm._e(),
              ])
            }),
            0
          ),
      _vm.addAble
        ? _c("div", { staticClass: "ReportTousuList-footer" }, [
            _c(
              "div",
              { staticClass: "add-btn", on: { click: _vm.routerToAdd } },
              [_vm._v("新增投诉还原")]
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }