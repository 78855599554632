var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "van-popup",
    {
      style: { width: "100%", height: "100%" },
      attrs: {
        position: "right",
        overlay: false,
        duration: _vm.animateDuration,
      },
      on: {
        open: _vm.selectProjectOpenHandler,
        close: _vm.selectProjectCloseHandler,
      },
      model: {
        value: _vm._show,
        callback: function ($$v) {
          _vm._show = $$v
        },
        expression: "_show",
      },
    },
    [
      _c(
        "div",
        { staticClass: "select-project-wrapper" },
        [
          _c(
            "my-nav",
            {
              attrs: { title: "项目列表", "self-back": true },
              on: { back: _vm.goBack },
            },
            [
              _vm.projectList.length > 0 &&
              _vm.projectList[0].deptType === "3" &&
              _vm.selectType === "checkbox"
                ? _c(
                    "div",
                    {
                      staticClass: "multi-wrapper",
                      attrs: { slot: "right" },
                      on: { click: _vm.checkMulti },
                      slot: "right",
                    },
                    [
                      _c("i", {
                        staticClass: "multi-icon",
                        class: `multi-${_vm._initMulti()}`,
                        on: {
                          click: function ($event) {
                            _vm.menuShow = true
                          },
                        },
                      }),
                      _c("p", { staticClass: "title" }, [_vm._v("全选")]),
                    ]
                  )
                : _vm._e(),
            ]
          ),
          _c("van-search", {
            staticClass: "search-wrapper",
            attrs: { placeholder: "请输入关键字" },
            on: { search: _vm._getProjectList, clear: _vm.clearSearch },
            model: {
              value: _vm.searchValue,
              callback: function ($$v) {
                _vm.searchValue = $$v
              },
              expression: "searchValue",
            },
          }),
          _c(
            "div",
            { staticClass: "project-list-wrapper" },
            _vm._l(_vm.projectList, function (item) {
              return _c(
                "div",
                {
                  key: item.deptCode,
                  staticClass: "project-list",
                  on: {
                    click: function ($event) {
                      return _vm.changeProjectCheck(item)
                    },
                  },
                },
                [
                  _c("p", { staticClass: "name" }, [
                    _vm._v(_vm._s(item.deptName)),
                  ]),
                  item.deptType !== "3"
                    ? _c("i", { staticClass: "right-icon" })
                    : _vm._e(),
                  _vm.showCheck(item)
                    ? _c("i", { staticClass: "check-icon" })
                    : _vm._e(),
                ]
              )
            }),
            0
          ),
          _c("div", { staticClass: "router-wrapper" }, [
            _c(
              "div",
              { staticClass: "list-wrapper" },
              _vm._l(_vm.routerList, function (item, index) {
                return _c(
                  "div",
                  { key: item.deptCode, staticClass: "router-list" },
                  [
                    _c(
                      "p",
                      {
                        staticClass: "name",
                        class: { last: index === _vm.routerList.length - 1 },
                        on: {
                          click: function ($event) {
                            return _vm.checkRouter(
                              index < _vm.routerList.length - 1,
                              item
                            )
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(item.deptName) + " ")]
                    ),
                    index < _vm.routerList.length - 1
                      ? _c("i", { staticClass: "router-icon" })
                      : _vm._e(),
                  ]
                )
              }),
              0
            ),
          ]),
          _c(
            "div",
            { staticClass: "submit-wrapper", on: { click: _vm.submit } },
            [
              _vm.selectType === "checkbox"
                ? _c("p", { staticClass: "count" }, [
                    _vm._v("(" + _vm._s(_vm.checkList.length) + ")"),
                  ])
                : _vm._e(),
              _c("p", { staticClass: "text" }, [_vm._v("确定")]),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }