<template v-cloak>
  <van-popup v-model="popupShow" position="bottom" :style="{ width: '100%', height: '85%' }"
    @click-overlay="closePopup" class="slelect-person">
    <div class="select-pearson-wrapper">
      <div class="select-position-header clear">
        整改人{{listData.length}}
        <div @click="closePopup" class="btn-close pull-right">
          <van-icon name="cross" style="vertical-align: top" color="#000" size="16px" />
        </div>
      </div>
      <div class="select-positon-content">
        <vuescroll :ops="ops" ref="vsposition">
          <van-collapse v-model="activeNames">
            <van-collapse-item v-for="(item, index) in listData"
              :title="`${item.rectifyRoleName}(${item.rectifyPeopleList.length})`" :key="index">
              <div class="select-item" @click="selectPearson(user)"
                v-for="user in item.rectifyPeopleList" :key="user.rectifyCode">
                {{ user.rectifyName }}
                <van-icon v-if="value && value.code === user.rectifyCode" name="success" size="24px"
                  class="success-icon pull-right" />
              </div>
            </van-collapse-item>
          </van-collapse>
          <div class="no-data" v-if="listData.length === 0">暂无数据</div>
        </vuescroll>
      </div>
    </div>
  </van-popup>
</template>

<script type='text/ecmascript-6'>
import vuescroll from 'vuescroll/dist/vuescroll-slide'
export default {
  name: '',
  components: { vuescroll },
  props: {
    listData: {
      type: Array,
      default: () => []
    },
    value: {
      type: Object,
      default: () => {}
    },
    show: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    show: function (newval) {
      this.popupShow = newval
    }
  },
  data () {
    return {
      popupShow: false,
      ops: {
        vuescroll: {
          mode: 'slide'
        },
        scrollPanel: {
          scrollingX: false,
          scrollingY: true
        },
        rail: {
          size: '4px',
          keepShow: false
        },
        bar: {
          size: '4px',
          background: '#eee'
        }
      },
      activeNames: []
    }
  },
  created () {},
  mounted () {
    this.popupShow = this.show
  },
  methods: {
    closePopup () {
      this.$emit('update:show', false)
    },
    selectPearson (item) {
      this.$emit('handleSelect', { code: item.rectifyCode, name: item.rectifyName })
      this.closePopup()
    }
  }
}
</script>
<style  lang='less' scoped>
.select-pearson-wrapper {
  height: 100%;
}
.select-position-header {
  padding: 15px 16px;
  font-size: 16px;
  // font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #222222;
  line-height: 22px;
  border-bottom: 0.5px solid #e8e8e8;
  box-shadow: 0px 0px 0px 0px #e8e8e8;
}
.select-positon-content {
  height: calc(100% - 55px);
  padding-bottom: 16px;
  box-sizing: border-box;
  .select-item {
    line-height: 48px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #222222;
    .success-icon {
      color: #3d69f5;
      margin-top: 14px;
    }
    .fork {
      background: url("../../../asset/icon_right_blue@2x.png") no-repeat;
      background-size: contain;
      width: 24px;
      height: 24px;
    }
  }
}
.btn-close {
  padding: 5px 0px 5px 5px;
  margin-top: -2px;
  height: 26px;
  box-sizing: border-box;
}
.slelect-person {
  z-index: 3001 !important;
}
</style>
