var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("van-field", {
        staticClass: "form-input",
        attrs: {
          readonly: "",
          name: "houseName",
          label: _vm.label,
          required: _vm.required,
          "right-icon": "arrow",
          "input-align": "right",
          placeholder:
            _vm.confirmType === "member"
              ? "按房间或姓名查找"
              : "选择问题房间（可非当前客户）",
        },
        on: { click: _vm.selectReportCustomer },
        model: {
          value: _vm.houseName,
          callback: function ($$v) {
            _vm.houseName = $$v
          },
          expression: "houseName",
        },
      }),
      _c("ReportCustomerSelectPop", {
        ref: "ReportCustomerSelectPop",
        attrs: {
          "choose-house": _vm.house,
          "confirm-type": _vm.confirmType,
          "router-list-prop": _vm.routerList,
          "select-type": "radio",
          show: _vm.ReportCustomerPopShow,
        },
        on: {
          router: _vm.routerConfirm,
          result: _vm.reportCustomerConfirm,
          "update:show": function ($event) {
            _vm.ReportCustomerPopShow = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }