<template>
  <van-popup
    v-model="filterPopShow"
    :close-on-popstate="false"
    position="right"
    :overlay="false"
    :style="{ width: '100%', height: '100%' }"
    :duration="animateDuration"
  >
    <div class="report-filter-popup">
      <my-nav blue title="筛选" :self-back="true" @back="goBack"></my-nav>
      <div>
        <van-field-picker
          label="报事类型"
          name="drClassName"
          v-model="formData.drClassName"
          :columns="drClassColumns"
          :real-value.sync="formData.drClass"
        ></van-field-picker>
        <van-field
          label="创建时间"
          placeholder="开始时间-结束时间"
          input-align="right"
          readonly
          is-link
          :value="formData.admiManTime"
          clickable
          @click="showCalendar = true"
        ></van-field>
        <van-calendar
          v-model="showCalendar"
          color="#3366FE"
          :min-date="new Date(1990, 0, 1)"
          type="range"
          @confirm="onCalendarConfirm"
        />
        <!-- <van-field
          readonly
          input-align="right"
          clickable
          name="field.positionName"
          is-link
          :value="formData.positionName"
          label="报事位置"
          placeholder="请选择报事位置"
          @click="selectPositionShow = true"
        /> -->
        <!-- <SelectReportPosition
          :incidentSource="'公区'"
          :show.sync="selectPositionShow"
          @result="selectPositionConfim"
          ref="selectProject"
        ></SelectReportPosition> -->
        <van-field-picker
          label="是否投诉"
          name="isTousu"
          v-model="formData.isTousuName"
          :columns="yesOrNoColumns"
          :real-value.sync="formData.isTousu"
        ></van-field-picker>
        <van-field-picker
          label="投诉等级"
          name="complaintlevelCode"
          v-model="formData.complaintlevelCode"
          :columns="tousuClassColumns"
          :real-value.sync="formData.complaintlevelCode"
        ></van-field-picker>
        <van-field
          type="digit"
          input-align="right"
          label="报事电话"
          placeholder="请输入"
          v-model="formData.phone"
        ></van-field>
        <van-field
          type="text"
          input-align="right"
          label="处理人"
          placeholder="请输入"
          v-model="formData.dealMan"
        ></van-field>
        <van-field-picker
          label="报事渠道"
          name="incidentChannel"
          v-model="formData.incidentChannel"
          :columns="incidentChannelColumns"
          :real-value.sync="formData.incidentChannel"
        ></van-field-picker>
        <van-field-picker
          label="是否逾期"
          name="isBeoverdue"
          v-model="formData.isBeoverdueName"
          :columns="yesOrNoColumns"
          :real-value.sync="formData.isBeoverdue"
        ></van-field-picker>
        <van-field-picker
          label="是否收费"
          name="isDueAmount"
          v-model="formData.isDueAmountName"
          :columns="yesOrNoColumns"
          :real-value.sync="formData.isDueAmount"
        ></van-field-picker>
      </div>
      <div class="footer-btn">
        <van-button class="btn resolve" @click="handleReset">重置</van-button>
        <van-button class="btn reject" @click="handleApplyConfirm"
          >确定</van-button
        >
      </div>
    </div>
  </van-popup>
</template>

<script type='text/ecmascript-6'>
import vanFieldPicker from '../../components/van-field-picker.vue'

// import SelectReportPosition from '../../CreateOrder/components/selectReportPosition.vue'
export default {
  name: '',
  components: {
    vanFieldPicker
    // SelectReportPosition
  },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      animateDuration: 0.3, // 默认弹窗动画时间0.3s
      formData: {
        drClass: undefined,
        drClassName: undefined,
        admiManStartTime: undefined,
        admiManEndTime: undefined,
        admiManTime: undefined,
        isTousuName: undefined,
        isTousu: undefined,
        localePosition: undefined,
        roomID: undefined,
        complaintlevelCode: undefined,
        incidentChannel: undefined,
        phone: undefined,
        isBeoverdueName: undefined,
        isBeoverdue: undefined,
        dealMan: undefined,
        isDueAmount: undefined,
        isDueAmountName: undefined,
        isFiniteAmount: undefined,
        isFiniteAmountName: undefined,
        positionName: undefined
      },
      showCalendar: false,
      drClassColumns: [
        { text: '书面', value: 1 },
        { text: '口派', value: 2 }
      ],
      yesOrNoColumns: [
        { text: '是', value: 1 },
        { text: '否', value: 0 }
      ],
      tousuClassColumns: [
        { text: '一般投诉', value: '一般投诉' },
        { text: '升级投诉', value: '升级投诉' },
        { text: '重大投诉', value: '重大投诉' }
      ],
      incidentChannelColumns: [
        { text: 'ERP', value: 'ERP' },
        { text: '呼叫中心', value: '呼叫中心' },
        { text: '品控系统', value: '品控系统' },
        { text: '东E管', value: '东E管' },
        { text: '东驿站', value: '东驿站' },
        { text: '同心原', value: '同心原' },
        { text: '原管家', value: '原管家' },
        { text: 'IVR', value: 'IVR' },
        { text: '远程监控', value: '远程监控' }
      ],
      localePositionColumns: [],
      projectCode: '100069',
      selectPositionShow: false
    }
  },
  computed: {
    filterPopShow: {
      get () {
        return this.show
      },
      set (val) {
        this.$emit('update:show', val)
      }
    },
    createTime () {
      if (this.formatDate.admiManStartTime && this.formatDate.admiManEndTime) {
        return `${this.formatDate.admiManStartTime} - ${this.formatDate.admiManEndTime}`
      } else {
        return null
      }
    }
  },
  mounted () {},
  methods: {
    initParams (params) {
      console.log('initParams params: ', params)
      const keys = Object.keys(this.formData)
      for (const key of keys) {
        if (params[key]) {
          this.formData[key] = params[key]
        }
      }
      this.setHasDot()
    },
    // selectPositionConfim (result) {
    //   const position = result.checkPositionList[0]
    //   console.log('position: ', position)
    //   if (position.regionalID) {
    //     this.formData.regionalID = position.regionalID
    //     this.formData.positionName = position.regionalPlace
    //   }
    //   if (position.roomID) {
    //     this.formData.roomID = position.roomID
    //     this.formData.positionName = position.roomSign
    //   }
    // },
    goBack () {
      this.filterPopShow = false
    },
    handleApplyConfirm () {
      this.$emit('confirm', this.formData)
      this.setHasDot()
      this.goBack()
    },
    handleReset () {
      this.formData = this.$options.data().formData
    },
    onCalendarConfirm (date) {
      const [start, end] = date
      this.showCalendar = false
      this.formData.admiManStartTime = this.formatDate(start)
      this.formData.admiManEndTime = this.formatDate(end)
      this.formData.admiManTime = `${this.formData.admiManStartTime} - ${this.formData.admiManEndTime}`
      console.log('this.formData: ', this.formData)
    },
    formatDate (date) {
      return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
    },
    setHasDot () {
      this.$parent.hasFilter = !this.lodash.every(this.formData, this.lodash.isEmpty)
    }
  }
}
</script>
<style lang='less' scoped>
.report-filter-popup {
  background-color: #f2f2f2;
  font-size: 14px;
  height: 100%;
  ::v-deep .van-cell__right-icon {
    padding-top: 4px;
  }
}
.footer-btn {
  position: fixed;
  z-index: 1;
  display: flex;
  width: 100%;
  bottom: 0;
  flex-shrink: 0;
  background-color: #ffffff;
  padding-top: 16px;
  font-size: 16px;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  justify-content: space-around;
  & > .btn {
    width: 164px;
    border-radius: 6px;
    &.resolve {
      color: #3366fe;
      border: 1px solid #3366fe;
      background-color: #ffffff;
    }
    &.reject {
      background-color: #3366fe;
      color: #ffffff;
    }
  }
}
</style>
