var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "van-popup",
    {
      attrs: { position: "bottom" },
      on: { close: _vm.close },
      model: {
        value: _vm.showPicker,
        callback: function ($$v) {
          _vm.showPicker = $$v
        },
        expression: "showPicker",
      },
    },
    [
      _c("van-picker", {
        attrs: {
          "show-toolbar": "",
          columns: _vm.dictionaryList,
          "value-key": "name",
        },
        on: { confirm: _vm.onConfirm, cancel: _vm.close },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }