var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "van-popup",
    {
      style: { width: "100%", height: "85%" },
      attrs: { position: "bottom" },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c("div", { staticClass: "select-position-header clear" }, [
        _vm._v(" " + _vm._s(_vm.title) + " "),
        _c(
          "div",
          {
            staticClass: "btn-close pull-right",
            on: {
              click: function ($event) {
                _vm.show = false
              },
            },
          },
          [
            _c("van-icon", {
              staticStyle: { "vertical-align": "top" },
              attrs: { name: "cross", color: "#000", size: "16px" },
            }),
          ],
          1
        ),
      ]),
      _vm.dataList && _vm.dataList.length > 0
        ? _c(
            "div",
            { staticClass: "select-positon-content" },
            _vm._l(_vm.dataList, function (item, index) {
              return _c(
                "div",
                {
                  key: item.code,
                  staticClass: "action-cell",
                  on: {
                    click: function ($event) {
                      return _vm.selectItems(item, index)
                    },
                  },
                },
                [
                  _c("div", [_vm._v(_vm._s(item.name))]),
                  _c("div", { class: { "icon-fork": item.isforked } }),
                ]
              )
            }),
            0
          )
        : _c("div", { staticClass: "select-positon-content no-data" }, [
            _vm.isOffline
              ? _c("span", [_vm._v("无" + _vm._s(_vm.title))])
              : _c("span", [
                  _vm._v("当前无网络，请提前在有网络的情况下下载离线包！"),
                ]),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }