import http from './base/index'

// 移动端抽查工单列表
const getSpotCheckOrderList = async function (params) {
  return http.post('/radish/spotCheckOrder/mobile/list', params)
}
// 移动端抽查工单count
const getSpotCheckOrderCount = async function (params) {
  return http.post('/radish/spotCheckOrder/mobile/count', params)
}
// 抽查工单的所有任务工单列表接口
const getTaskOrderListBySpotCheck = async function (params) {
  return http.post(`/radish/spotCheckOrder/task/list`, params)
}
// 抽查工单的所有任务工单列表接口
const getSpotCheckTaskItemDetail = async function (params) {
  return http.post(`/radish/spotCheckOrder/taskitem/detail`, params)
}
// 抽查工单的所有任务工单列表接口
const getSpotCheckTaskItemList = async function (params) {
  return http.get(`/radish/spotCheckOrder/item/detail`, params)
}
// 提交抽查工单
const potTaskOrder = async function (params) {
  return http.post(`/radish/spotCheckOrder/submit/item`, params)
}
export {
  getSpotCheckOrderList,
  getSpotCheckOrderCount,
  getTaskOrderListBySpotCheck,
  getSpotCheckTaskItemDetail,
  getSpotCheckTaskItemList,
  potTaskOrder
}
