<template>
  <div class="OrderSpotCheckDetail">
    <my-nav title="抽查"></my-nav>
    <div class="wrapper" v-for="(option, index) in detail.options" :key="index">
      <van-row class="mb-8px">
        <van-col class="text-[#8B8F9A]" span="6">点位名称：</van-col>
        <van-col class="text-[#222222]" span="18">
          {{ detail.itemName }}
        </van-col>
      </van-row>
      <van-row class="mb-8px">
        <van-col class="text-[#8B8F9A]" span="6">名称：</van-col>
        <van-col class="text-[#222222]" span="18">
          {{ option.name }}
        </van-col>
      </van-row>
      <van-row class="mb-8px">
        <van-col class="text-[#8B8F9A]" span="24">说明：</van-col>
        <van-col class="text-[#222222]" span="24">
          {{ option.optionSecondTitle }}
        </van-col>
      </van-row>
      <van-row class="mb-8px">
        <van-col class="text-[#8B8F9A]" span="24">示例图片：</van-col>
        <van-col
          class="text-[#222222]"
          span="24"
          v-if="
            option.secondTitleAttachments &&
            option.secondTitleAttachments.length
          "
        >
          <van-image
            width="40"
            height="40"
            radius="4"
            class="mr-10px mt-10px"
            v-for="(image, imgIndex) of option.secondTitleAttachments"
            @click="imgPreview(option.secondTitleAttachments, imgIndex)"
            :key="imgIndex"
            :src="image.url"
          />
        </van-col>
        <van-col class="text-[#222222]" span="24" v-else>-</van-col>
      </van-row>
      <van-row
        class="mb-8px"
        v-for="(typeOption, optionIndex) in option.typeOptions"
        :key="optionIndex"
      >
        <template v-if="[3, 4, 5, 6, 10].includes(typeOption.type)">
          <van-col class="text-[#8B8F9A]" span="6">执行结果：</van-col>
          <van-col class="text-[#222222]" span="18">
            {{ typeOption.val || "-" }}
          </van-col>
        </template>
        <template v-if="typeOption.type === 2">
          <van-col class="text-[#8B8F9A]" span="6">执行备注：</van-col>
          <van-col class="text-[#222222]" span="18">
            {{ typeOption.val || "-" }}
          </van-col>
        </template>
        <template v-if="[7, 8, 9].includes(typeOption.type)">
          <van-col class="text-[#8B8F9A]" span="24"
            >{{
              typeOption.type === 7
                ? "执行"
                : typeOption.type === 8
                ? "事前"
                : "事后"
            }}图片：</van-col
          >
          <van-col span="24">
            <van-image
              width="40"
              height="40"
              radius="4"
              class="mr-10px mt-10px"
              v-for="(image, imgIndex) of typeOption.optionAttachments"
              @click="handleClickImage(typeOption, imgIndex, option)"
              :key="imgIndex"
              :src="image.url"
            />
          </van-col>
        </template>
      </van-row>
      <van-row
        class="mb-8px"
        v-if="option.questionList && option.questionList.length"
      >
        <van-col class="text-[#8B8F9A]" span="24">问题整改：</van-col>
        <van-col class="text-[#3366FE]" span="24">
          <div @click="routerToOrderDetail(question)" v-for="(question, qIndex) in option.questionList" :key="qIndex">
            {{ question.orderNo }}
          </div>
        </van-col>
      </van-row>
      <div class="btn-wrapper flex justify-end" v-if="option.spotCheckItems">
        <van-button @click="handleOption(option)" v-if="option.status" type="primary" plain style="width: 80px; height: 24px; border-radius: 4px" color="#3366fe" size="mini">查看</van-button>
        <van-button
          v-else
          type="primary"
          style="width: 80px; height: 24px; border-radius: 4px"
          color="#3366fe"
          size="mini"
          @click="handleOption(option)"
          >填写结果</van-button
        >
      </div>
    </div>
    <van-dialog
      v-model="addEditPicktureDialogShow"
      title="查看图片"
      show-cancel-button
      style="width: 90vw"
      getContainer=".OrderSpotCheckDetail"
      :before-close="
        (action, done) => beforeAddEditPicktureDialogClose(action, done)
      "
    >
      <img
        v-if="editPictureOfflineImageBase"
        :src="editPictureOfflineImageBase"
        width="100%"
        style="object-fit: contain; max-height: 400px"
        alt=""
      />
      <van-field
        readonly
        clickable
        :required="true"
        label="添加抽查项"
        class="form-input"
        right-icon="arrow"
        input-align="right"
        placeholder="请选择"
        @click="spotCheckItemPickerShow = true"
        :value="spotCheckItemPickerValue"
      />
      <van-popup v-model="spotCheckItemPickerShow" position="bottom">
        <van-picker
          title="选择抽查项"
          show-toolbar
          :columns="spotCheckItems"
          @cancel="spotCheckItemPickerShow = false"
          @confirm="spotCheckItemPickerSelect"
          value-key="spotCheckConfigName"
        >
        </van-picker>
      </van-popup>
    </van-dialog>
  </div>
</template>

<script>
import { getSpotCheckTaskItemList, getSpotCheckTaskItemDetail } from '@/api/orderSpotCheck'
import { ImagePreview } from 'vant'
import mobile from '@/utils/mobile'

export default {
  data () {
    return {
      detail: {},
      editPictureKey: undefined,
      editPictureOfflineImageBase: undefined,
      editPictureOptionCodeTemp: undefined,
      addEditPicktureDialogShow: false,
      spotCheckItemPickerShow: false,
      spotCheckItemPickerValue: '',
      spotCheckItemPickerCheckIdTemp: undefined,
      spotCheckItems: [],
      spotOrderNo: '',
      taskOrderNo: ''
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (from.name === 'OrderSpotCheckHandle' && to.query.next) {
        vm.$router.replace({
          name: 'OrderSpotCheckDetail',
          query: {
            ...to.query,
            itemId: to.query.nextNofinishItemId
          }
        })
      }
    })
  },
  mounted () {
    const { itemId, orderId, spotOrderNo, taskOrderNo } = this.$route.query
    console.log('itemId, orderId : ', itemId, orderId)
    this.itemId = itemId
    this.orderId = orderId
    this.spotOrderNo = spotOrderNo
    this.taskOrderNo = taskOrderNo
    this.getTaskItemDetail()
    this.loadTaskItemList()
  },
  methods: {
    async loadTaskItemList () {
      const data = await getSpotCheckTaskItemList({
        spotOrderNo: this.spotOrderNo,
        taskOrderNo: this.taskOrderNo
      })
      const nextNofinishItemId = data.find(item => item.itemId !== this.itemId * 1 && item.status === 1)?.itemId
      this.nextNofinishItemId = nextNofinishItemId
    },
    routerToOrderDetail (order) {
      this.$router.push({
        path: '/OrderDetail',
        query: {
          orderId: order.id
        }
      })
    },
    spotCheckItemPickerSelect (obj) {
      console.log('spotCheckItemPickerSelect obj: ', obj)
      this.spotCheckItemPickerValue = obj.spotCheckConfigName
      this.spotCheckItemPickerCheckIdTemp = obj.checkId
      this.spotCheckItemPickerShow = false
    },
    beforeAddEditPicktureDialogClose (action, done) {
      console.log('action, done: ', action, done)
      if (action === 'cancel') mobile.deleteImgsInCache([this.editPictureKey])
      if (action === 'confirm') {
        if (!this.spotCheckItemPickerValue) {
          done(false)
          return this.$toast('请选择抽查项')
        }
        const options = this.lodash.cloneDeep(this.detail.options)
        const optionIndex = options.findIndex(i => i.code === this.editPictureOptionCodeTemp)
        const spotCheckItem = options[optionIndex].spotCheckItems.find(i => i.checkId === this.spotCheckItemPickerCheckIdTemp)
        console.log('spotCheckItem: ', spotCheckItem)
        if (!spotCheckItem.spotCheckAttachments) spotCheckItem.spotCheckAttachments = []
        spotCheckItem.spotCheckAttachments.push(this.editPictureKey)
        this.detail.options[optionIndex] = options[optionIndex]
        this.$toast('添加成功')
      }
      this.spotCheckItems = []
      this.editPictureKey = ''
      this.spotCheckItemPickerValue = ''
      this.spotCheckItemPickerShow = false
      this.editPictureOfflineImageBase = undefined
      done()
    },
    async handleClickImage (typeOption, index, option) {
      console.log('typeOption, index : ', typeOption, index)
      // this.imgPreview(typeOption.optionAttachments, index)
      const image = typeOption.optionAttachments[index]
      const data = await mobile.editPicture({ url: image.url, enableWatermark: 'NO', projectName: '', key: '' })
      this.editPictureKey = data[0]
      this.editPictureOfflineImageBase = await mobile.getSaveDataBase64(data[0])
      this.spotCheckItems = option.spotCheckItems
      this.editPictureOptionCodeTemp = option.code
      this.addEditPicktureDialogShow = true
    },
    imgPreview (list, index) {
      ImagePreview({
        images: list.map((i) => i.url),
        startPosition: index
      })
    },
    async getTaskItemDetail () {
      const data = await getSpotCheckTaskItemDetail({
        itemId: this.itemId * 1,
        relationId: this.orderId * 1
      })
      data.options.forEach(option => {
        option.status = option.spotCheckItems.every(i => i.checkResult || i.checkResult === 0)
      })
      this.detail = data
    },
    handleOption (option) {
      const isFinalNofinishOption = this.detail.options.filter(i => i.code !== option.code).every(i => i.status)
      this.$router.push({
        name: 'OrderSpotCheckHandle',
        params: {
          spotCheckItems: option.spotCheckItems.map(i => {
            return {
              ...i,
              id: this.orderId * 1,
              itemId: this.detail.itemId,
              checkCode: option.code
            }
          }),
          isFinalNofinishOption,
          nextNofinishItemId: this.nextNofinishItemId
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
