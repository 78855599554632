<template>
  <div class="OrderChargeDetail">
    <my-nav title="收费详情"></my-nav>
    <div class="detail-wrapper">
      <div class="charge-fee">应收合计：300元</div>
      <div class="charge-list">
        <div
          class="charge-item"
          v-for="(item, index) in chargeList"
          :key="index"
        >
          <div class="charge-head">
            <span>费用项目：{{ item.project }}</span>
            <div>
              <span>编辑</span>
              <span>删除</span>
            </div>
          </div>
          <van-row>
            <van-col :span="6">费用标准：</van-col>
            <van-col :span="18">{{ item.standrad }}</van-col>
          </van-row>
          <van-row>
            <van-col :span="6">费用：</van-col>
            <van-col :span="18">{{ item.fee + "元" }}</van-col>
          </van-row>
          <van-row>
            <van-col :span="6">费用备注：</van-col>
            <van-col :span="18">{{ item.remark }}</van-col>
          </van-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      chargeList: [
        {
          project: '特约服务费',
          standrad: '桶装水费',
          fee: 0.01,
          remark: '备注备注备注备注备注备注备注备注备注备注备注备注'
        },
        {
          project: '特约服务费',
          standrad: '桶装水费',
          fee: 0.01,
          remark: '备注备注备注备注备注备注备注备注备注备注备注备注'
        },
        {
          project: '特约服务费',
          standrad: '桶装水费',
          fee: 0.01,
          remark: '备注备注备注备注备注备注备注备注备注备注备注备注'
        },
        {
          project: '特约服务费',
          standrad: '桶装水费',
          fee: 0.01,
          remark: '备注备注备注备注备注备注备注备注备注备注备注备注'
        },
        {
          project: '特约服务费',
          standrad: '桶装水费',
          fee: 0.01,
          remark: '备注备注备注备注备注备注备注备注备注备注备注备注'
        }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
@import url("./index.less");
</style>
