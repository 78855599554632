<template>
  <div class="ReportTousuAdd">
    <my-nav title="新增投诉还原"></my-nav>
    <div class="ReportTousuAdd-content">
      <van-field-picker
        label="投诉性质"
        name="complaintType"
        required
        v-model="formData.complaintType"
        :columns="complaintTypeColumns"
        :real-value.sync="formData.complaintType"
      ></van-field-picker>
      <van-field-picker
        label="投诉等级"
        name="touSuDegree"
        required
        v-model="formData.touSuDegree"
        :columns="touSuDegreeColumns"
        :real-value.sync="formData.touSuDegree"
      ></van-field-picker>
      <van-field
        label="投诉还原内容"
        name="touSuReasons"
        required
        show-word-limit
        v-model="formData.touSuReasons"
        type="textarea"
        rows="4"
        class="form-textarea tousuTextArea"
        placeholder="请输入投诉内容"
        auto-size
      ></van-field>
      <div class="px-10px bg-white">
        <common-upload
          :file.sync="formData.attachments"
          :enable-watermark="false"
          :enable-album="true"
          :enable-edit="true"
          :required="false"
          :max-count="6"
        ></common-upload>
      </div>
    </div>
    <div class="ReportTousuAdd-footer">
      <div class="cancel btn" @click="cancel">取消</div>
      <div class="save btn" @click="save">保存</div>
    </div>
  </div>
</template>

<script>
import vanFieldPicker from '../components/van-field-picker.vue'
import { incidentTousuAdd } from '@/api/report'
import { uploadPhotoByImgKeys } from '@/plugins/offlineMixin/utils/offlineImgTool'
export default {
  components: {
    vanFieldPicker
  },
  data () {
    return {
      formData: {
        complaintType: '有效',
        touSuDegree: '一般投诉',
        touSuReasons: '',
        attachments: []
      },
      complaintTypeColumns: [{ text: '有效', value: '有效' }, { text: '无效', value: '无效' }],
      touSuDegreeColumns: [{ text: '一般投诉', value: '一般投诉' }, { text: '升级投诉', value: '升级投诉' }, { text: '重大投诉', value: '重大投诉' }]
    }
  },
  methods: {
    cancel () {
      this.$router.go(-1)
    },
    async save () {
      if (!this.formData.complaintType) this.$toast('请选择投诉性质')
      if (!this.formData.touSuDegree) this.$toast('请选择投诉等级')
      if (!this.formData.touSuReasons) this.$toast('请输入投诉还原')
      const formData = JSON.parse(JSON.stringify(this.formData))
      if (formData.attachments && formData.attachments.length > 0) {
        const images = await uploadPhotoByImgKeys(this.formData.attachments)
        formData.touSuImg = images.map((i) => i.url).join(',')
      }
      formData.twOrderId = this.$route.query.twOrderId
      formData.projectCode = this.$route.query.projectCode
      delete formData.attachments
      const result = await incidentTousuAdd(formData)
      console.log('result: ', result)
      this.$toast('新增成功')
      this.$router.back()
    }
  }
}
</script>

<style lang="less" scoped>
@import url(./index.less);
</style>
