var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "offline-list-wrapper" },
    [
      _c("my-nav", { attrs: { title: "待上传离线任务列表" } }, [
        _c(
          "p",
          {
            staticClass: "submit-btn",
            class: { disabled: _vm.submitLoading },
            attrs: { slot: "right" },
            on: { click: _vm.handleSubmitAll },
            slot: "right",
          },
          [_vm._v("全部提交")]
        ),
      ]),
      _vm.list.length > 0
        ? _c(
            "div",
            { staticClass: "list-wrapper" },
            _vm._l(_vm.list, function (item, index) {
              return _c("div", { key: index }, [
                _c("div", { staticClass: "order-cell" }, [
                  _c("div", { staticClass: "order-cell-title" }, [
                    _c(
                      "div",
                      { staticClass: "flex-box" },
                      [
                        _c("div", { staticClass: "order-number" }, [
                          _vm._v(_vm._s(item.model.order_no)),
                        ]),
                        _c(
                          "van-tag",
                          {
                            staticClass: "copy-tag",
                            class: "copyOrderNo" + index,
                            attrs: {
                              plain: "",
                              type: "primary",
                              color: "#999",
                              "data-clipboard-action": "copy",
                              "data-clipboard-text": item.model.order_no,
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.copyOrderNumber(item.model.order_no)
                              },
                            },
                          },
                          [_vm._v("复制")]
                        ),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "btn-wrapper" }, [
                      _c(
                        "p",
                        {
                          staticClass: "btn del",
                          class: { disabled: _vm.submitLoading },
                          on: {
                            click: function ($event) {
                              return _vm.handleDelete(item, index)
                            },
                          },
                        },
                        [_vm._v("删除")]
                      ),
                      _c(
                        "p",
                        {
                          staticClass: "btn",
                          class: { disabled: _vm.submitLoading },
                          on: {
                            click: function ($event) {
                              return _vm.handleSubmit(item, index)
                            },
                          },
                        },
                        [_vm._v("提交")]
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "order-body" },
                    [
                      _c(
                        "van-row",
                        { staticClass: "order-body-cell" },
                        [
                          _c(
                            "van-col",
                            { staticClass: "sub-key", attrs: { span: 6 } },
                            [_vm._v("工单属性:")]
                          ),
                          _c(
                            "van-col",
                            {
                              staticClass: "order-sub-flex",
                              attrs: { span: 18 },
                            },
                            [
                              _c(
                                "van-tag",
                                {
                                  staticClass: "mr-8",
                                  attrs: {
                                    type: "success",
                                    size: "medium",
                                    color:
                                      _vm.getOrderTypeConf.taskOrderTypes.includes(
                                        item.model.order_type
                                      )
                                        ? ""
                                        : "#45A2F4",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(item.model.order_type_name) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "van-tag",
                                {
                                  staticClass: "mr-8",
                                  staticStyle: {
                                    background: "rgba(190, 69, 244, 0.1)",
                                  },
                                  attrs: {
                                    plain: "",
                                    type: "primary",
                                    size: "medium",
                                    color: "#BE45F4",
                                  },
                                },
                                [_vm._v(_vm._s(item.model.order_nature_name))]
                              ),
                              item.model.grade
                                ? _c(
                                    "van-tag",
                                    {
                                      staticClass: "mr-8",
                                      attrs: {
                                        plain: "",
                                        type: "primary",
                                        size: "medium",
                                        color: JSON.parse(item.model.grade)
                                          .color,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            JSON.parse(item.model.grade).value
                                          )
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.getOrderTypeConf.taskOrderTypes.includes(
                        item.model.order_type
                      )
                        ? _c(
                            "van-row",
                            {
                              staticClass: "order-body-cell",
                              attrs: { gutter: "10" },
                            },
                            [
                              _c(
                                "van-col",
                                { staticClass: "sub-key", attrs: { span: 6 } },
                                [_vm._v("处理方式:")]
                              ),
                              _c(
                                "van-col",
                                {
                                  staticClass: "order-sub-flex",
                                  attrs: { span: 18 },
                                },
                                [
                                  _c(
                                    "van-tag",
                                    {
                                      staticClass: "mr-8",
                                      attrs: {
                                        size: "medium",
                                        color: "rgb(51 102 254 / 10%)",
                                        "text-color": "#3366FE",
                                      },
                                    },
                                    [
                                      _c("span", { staticClass: "font-bold" }, [
                                        _vm._v(
                                          _vm._s(item.model.hander_type_name)
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "van-tag",
                                    {
                                      attrs: {
                                        color: "rgb(51 102 254 / 10%)",
                                        "text-color": "#3366FE",
                                        size: "medium",
                                      },
                                    },
                                    [
                                      _c("span", { staticClass: "font-bold" }, [
                                        _vm._v(
                                          _vm._s(
                                            item.model.in_order === 1
                                              ? "有序"
                                              : "无序"
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "van-row",
                        { staticClass: "order-body-cell" },
                        [
                          _c(
                            "van-col",
                            { staticClass: "sub-key", attrs: { span: 6 } },
                            [_vm._v("所在项目:")]
                          ),
                          _c(
                            "van-col",
                            { staticClass: "sub-value", attrs: { span: 18 } },
                            [
                              _c("span", [
                                _vm._v(_vm._s(item.model.project_name)),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "van-row",
                        { staticClass: "order-body-cell" },
                        [
                          _c(
                            "van-col",
                            { staticClass: "sub-key", attrs: { span: 8 } },
                            [_vm._v("计划开始时间:")]
                          ),
                          _c(
                            "van-col",
                            { staticClass: "sub-value", attrs: { span: 16 } },
                            [
                              _c("span", [
                                _vm._v(_vm._s(item.model.plan_start)),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "van-row",
                        { staticClass: "order-body-cell" },
                        [
                          _c(
                            "van-col",
                            { staticClass: "sub-key", attrs: { span: 8 } },
                            [_vm._v("计划结束时间:")]
                          ),
                          _c(
                            "van-col",
                            { staticClass: "sub-value", attrs: { span: 16 } },
                            [_c("span", [_vm._v(_vm._s(item.model.plan_end))])]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "van-row",
                        { staticClass: "order-body-cell" },
                        [
                          _c(
                            "van-col",
                            { staticClass: "sub-key", attrs: { span: 6 } },
                            [_vm._v("工单描述:")]
                          ),
                          _c(
                            "van-col",
                            { staticClass: "sub-value", attrs: { span: 18 } },
                            [_c("span", [_vm._v(_vm._s(item.model.remark))])]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.getOrderTypeConf.taskOrderTypes.includes(
                    item.model.order_type
                  )
                    ? _c("div", { staticClass: "offlineSubmitNum" }, [
                        _vm._v(" 点位/设施设备数量："),
                        _c("b", [
                          _vm._v(_vm._s(item.orderTaskItemExtModelList.length)),
                        ]),
                        _vm._v("   |  已离线完成："),
                        _c("b", [
                          _vm._v(
                            _vm._s(
                              _vm.offlineSubmitPatroItemNum(
                                item.orderTaskItemExtModelList
                              )
                            )
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  item.model.offlineSubmitMessage
                    ? _c(
                        "div",
                        { staticClass: "status_info" },
                        [
                          _c("van-icon", {
                            attrs: { name: "info-o", size: "20", color: "red" },
                          }),
                          _c("span", { staticClass: "messege" }, [
                            _vm._v(_vm._s(item.model.offlineSubmitMessage)),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ])
            }),
            0
          )
        : _c("no-data", { attrs: { tex: "暂无上传的离线数据" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }