<template>
  <div class="orderCheck">
    <!-- 导航栏 -->
    <my-nav
      color="blue"
      title="工单审核"
      :self-back="true"
      @back="exitWeb"
    ></my-nav>
    <div class="select-project-input-wrapper">
      <div class="input-wrapper" @click="selectProjectShow = true">
        <p class="info">已选项目：</p>
        <p class="value" ref="valueLength">
          {{ searchParams.searchProjectCodeName }}
        </p>
      </div>
    </div>

    <selectReportPosition
      mode="project"
      :incidentSource="'公区'"
      :show.sync="selectProjectShow"
      @result="selectProjectConfim"
      ref="selectProject"
    ></selectReportPosition>
    <van-sticky :offset-top="navBarHeight - 3">
      <!-- <van-tabs
        animated
        color="#3366FE"
        title-active-color="#3366FE"
        title-inactive-color="#7C7F8A"
        line-height="2px"
        @change="tabChange"
        v-model="searchParams.status"
        class="status-tabs"
      >
        <van-tab name="待审核">
          <template v-slot:title>
            <div class="tab-class">
              <div>待审核</div>
              <div>{{ `（${count.toCheckCount}）` }}</div>
            </div>
          </template>
        </van-tab>
        <van-tab name="审核通过">
          <template v-slot:title>
            <div class="tab-class">
              <div>审核通过</div>
              <div>{{ `（${count.checkPassCount}）` }}</div>
            </div>
          </template>
        </van-tab>
        <van-tab name="审核驳回">
          <template v-slot:title>
            <div class="tab-class">
              <div>审核驳回</div>
              <div>{{ `（${count.checkFailCount}）` }}</div>
            </div>
          </template>
        </van-tab>
      </van-tabs> -->
      <section class="tag-wrapper">
        <span
          class="ordercheck-type"
          :class="{ active: searchParams.profession === item }"
          @click="changeProfession(item)"
          v-for="(item, index) in checkTypes"
          :key="index"
          >{{ item }}</span
        >
      </section>
    </van-sticky>
    <template>
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :finished="finished"
          :finished-text="
            list.length > 0 ? '- 没有更多数据了 -' : '您没有处理的工单'
          "
          @load="onLoad('list')"
          :offset="50"
          :immediate-check="false"
        >
          <div
            class="order"
            @click="handleClickOrderItem(item)"
            v-for="(item, index) in list"
            :key="index"
          >
            <div class="order_header">
              <div class="left">
                <span
                  class="order_type"
                  :class="{
                    zhixu: item.belongProfessional === '秩序',
                    huanjing: item.belongProfessional === '环境',
                    gongcheng: item.belongProfessional === '工程',
                    kefu: item.belongProfessional === '客服',
                  }"
                ></span>
                <span>{{ item.belongProfessional }}</span>
              </div>
              <span class="status">{{ item.orderStatusName }}</span>
            </div>
            <div class="order_main">
              <van-row>
                <van-col span="5">工单编号：</van-col>
                <van-col
                  @click.stop="routerToReportOrderDetail(item.incidentID)"
                  class="text-[#3366FE]"
                  span="19"
                  >{{ item.incidentNum }}</van-col
                >
              </van-row>
              <van-row>
                <van-col span="5">报事位置：</van-col>
                <van-col span="19">{{ item.roomSign }}</van-col>
              </van-row>
              <van-row>
                <van-col span="5">报事描述：</van-col>
                <van-col class="order_disc" span="19">{{
                  item.incidentContent
                }}</van-col>
              </van-row>
              <van-row>
                <van-col span="5">报事时间：</van-col>
                <van-col span="19">{{ item.incidentDate }}</van-col>
              </van-row>
              <van-row>
                <van-col span="5">申请人：</van-col>
                <van-col span="19">{{ item.userName }}</van-col>
              </van-row>
            </div>
            <div
              class="tag"
              :class="{
                green: item.approvalType === '协助',
                orange: item.approvalType === '转派' || item.approvalType === '改派',
                red: item.approvalType === '废弃',
                grey: item.approvalType === '非关',
                blue: item.approvalType === '延期'
              }"
            >
              {{ item.approvalType }}
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </template>
  </div>
</template>

<script>
import mobile from '@/utils/mobile'
import { getApproveList } from '@/api/report'
import selectReportPosition from '../CreateOrder/components/selectReportPosition.vue'

export default {
  components: {
    selectReportPosition
  },
  data () {
    return {
      searchParams: {
        limit: 10,
        current: 1,
        auditState: '审核中',
        deptCode: '',
        profession: '',
        projectCode: '',
        projectName: '',
        searchProjectCode: '',
        searchProjectCodeName: ''
      },
      currentPage: 1,
      finished: false,
      refreshing: false,
      loading: false,
      list: [],
      navBarHeight: 0,
      count: {
        toCheckCount: 0,
        checkPassCount: 0,
        checkFailCount: 0
      },
      checkTypes: ['延期', '协助', '改派', '废弃', '关闭'],
      selectProjectShow: false
    }
  },
  mounted () {
    const _this = this
    this.$nextTick(() => {
      _this.navBarHeight = document.querySelector('.van-nav-bar')?.offsetHeight // 头部高度
    })
    // this.getCurrentProjectAndSet()
    this.getInitList()
  },
  methods: {
    changeProfession (profession) {
      if (this.searchParams.profession === profession) {
        this.searchParams.profession = ''
      } else {
        this.searchParams.profession = profession
      }
      this.getInitList()
    },
    async getCurrentProjectAndSet () {
      try {
        const result = await mobile.getCurrentProject()
        console.log('getCurrentProject result: ', result)
        if (result && result.CommID && result.CommID !== '0') {
          this.searchParams.projectCode = result.CommID
          this.searchParams.deptCode = result.OrganCode
          this.searchParams.searchProjectCode = result.CommID
          this.searchParams.searchProjectCodeName = result.CommName
          this.getInitList()
        } else {
          this.alertNoProjectAndBack()
        }
      } catch (error) {
        this.$notify({
          type: 'warning',
          message: error.message
        })
        this.alertNoProjectAndBack()
      }
    },
    alertNoProjectAndBack () {
      this.$dialog
        .alert({
          title: '提示',
          message: '请返回首页选择项目！',
          confirmButtonColor: '#3366FE',
          confirmButtonText: '知道了'
        })
        .then(() => {
          this.exitWeb()
        })
    },
    async selectProject (list) {
      this.searchParams.projectCodes = list.length > 0 ? list : []
      // 初始化参数
      this.getInitList()
    },
    exitWeb () {
      mobile.backWeb()
    },
    onSearch () {
      this.getInitList()
    },
    onSearchClear () {
      this.getInitList()
    },
    tabChange () {
      this.getInitList()
    },
    onRefresh () {
      this.getInitList()
    },
    getInitList () {
      this.searchParams.current = 1
      this.finished = false
      this.list = []
      this.onLoad()
    },
    selectProjectConfim (result) {
      this.searchParams.searchProjectCode = result.organCode
      this.searchParams.searchProjectCodeName = result.organName
      console.log('this.searchParams: ', this.searchParams)
      this.getInitList()
    },
    async onLoad () {
      try {
        console.log('getApproveList: ', getApproveList)
        this.loading = true
        const result = await getApproveList(this.searchParams)
        console.log('result: ', result)
        if (result.data && result.data.length > 0) {
          this.list = this.list.concat(result.data)
        }
        if (result.data.length === 0) {
          this.finished = true
        }
        this.searchParams.current++
      } catch (error) {
        this.finished = true
      } finally {
        this.refreshing = false
        this.loading = false
      }
    },
    intoCirculation (button, order) {
      console.log('button: ', button)
      console.log('order: ', order)
      this.circulationShow = true
      this.circulationTitle = button.buttonName
      this.currentFieldConfig = button.fieldConfig
      this.circulationOrder = order
    },
    filterPopupConfirm (formData) {
      console.log('formData: ', formData)
      Object.assign(this.searchParams, formData)
      this.getInitList()
    },
    handleClickOrderItem (item) {
      const params = {
        route: 'web',
        params: {
          url: item.detailUrl.replace('https', 'http'),
          title: '审核详情'
        }
      }
      mobile.pushNative(params)
    },
    routerToReportOrderDetail (id) {
      this.$router.push({
        name: 'ReportOrderDetail',
        query: {
          id,
          readonly: 1
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import url("./index.less");
</style>
