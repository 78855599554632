var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "van-dialog",
    {
      style: { width: "92vw" },
      attrs: {
        "show-cancel-button": "",
        "confirm-button-text": "确认添加",
        "close-on-popstate": false,
        title: "新增费项",
        getContainer: "#app",
        "before-close": (action, done) => _vm.beforeDialogClose(action, done),
      },
      on: { open: _vm.dialogOpen },
      model: {
        value: _vm._show,
        callback: function ($$v) {
          _vm._show = $$v
        },
        expression: "_show",
      },
    },
    [
      _c(
        "van-form",
        {
          ref: "FeeForm",
          staticClass: "FeeForm circulation_field",
          attrs: {
            "validate-first": "",
            "validate-trigger": "onSubmit",
            "show-error-message": false,
          },
        },
        [
          _c("van-field", {
            attrs: {
              readonly: "",
              required: true,
              name: "costName",
              "right-icon": "arrow-down",
              rules: [{ required: true, message: "请选择费项" }],
              value: _vm.formData.costName,
              label: "费项",
              placeholder: "请选择费项",
            },
            on: {
              click: function ($event) {
                _vm.selectFeeShow = true
              },
            },
          }),
          _c(
            "van-popup",
            {
              style: { height: "50%" },
              attrs: { "get-container": "#app", round: "", position: "bottom" },
              model: {
                value: _vm.selectFeeShow,
                callback: function ($$v) {
                  _vm.selectFeeShow = $$v
                },
                expression: "selectFeeShow",
              },
            },
            [
              _c("van-picker", {
                attrs: {
                  "show-toolbar": "",
                  "value-key": "costName",
                  columns: _vm.feeTree,
                },
                on: {
                  cancel: function ($event) {
                    _vm.selectFeeShow = false
                  },
                  confirm: _vm.onFeeConfirm,
                },
              }),
            ],
            1
          ),
          _c("van-field", {
            attrs: {
              readonly: "",
              "right-icon": "arrow-down",
              required: true,
              name: "stanId",
              rules: [{ required: true, message: "请选择费项标准" }],
              value: _vm.formData.stanName,
              label: "费项标准",
              placeholder: "请选择费项标准",
            },
            on: { click: _vm.openSelectFeeStan },
          }),
          _c(
            "van-popup",
            {
              style: { height: "50%" },
              attrs: { "get-container": "#app", round: "", position: "bottom" },
              model: {
                value: _vm.selectFeeStanShow,
                callback: function ($$v) {
                  _vm.selectFeeStanShow = $$v
                },
                expression: "selectFeeStanShow",
              },
            },
            [
              _c("van-picker", {
                attrs: {
                  "show-toolbar": "",
                  "value-key": "stanName",
                  columns: _vm.feeStan,
                },
                on: {
                  cancel: function ($event) {
                    _vm.selectFeeStanShow = false
                  },
                  confirm: _vm.onFeeStanConfirm,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "fee-count" },
            [
              _c(
                "van-field",
                {
                  attrs: {
                    rules: [
                      {
                        validator: _vm.labourAmountValidator,
                        message: "请输入费用金额",
                      },
                    ],
                    formatter: _vm.formatter,
                    type: "number",
                    name: "labourAmount",
                    label: "费用金额(元)",
                    placeholder: "人工费用",
                    "show-error-message": false,
                  },
                  model: {
                    value: _vm.formData.labourAmount,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "labourAmount", $$v)
                    },
                    expression: "formData.labourAmount",
                  },
                },
                [
                  _c("template", { slot: "label" }, [
                    _c("span", { staticClass: "text-[#4e5969]" }, [
                      _c("span", { staticClass: "text-[#ee0a24]" }, [
                        _vm._v("* "),
                      ]),
                      _vm._v("费用金额（元）"),
                    ]),
                  ]),
                ],
                2
              ),
              _c("van-field", {
                attrs: {
                  rules: [
                    {
                      validator: _vm.materialAmountValidator,
                      message: "请输入费用金额",
                    },
                  ],
                  formatter: _vm.formatter,
                  name: "materialAmount",
                  type: "number",
                  label: "",
                  placeholder: "耗材费用",
                  "show-error-message": false,
                },
                model: {
                  value: _vm.formData.materialAmount,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "materialAmount", $$v)
                  },
                  expression: "formData.materialAmount",
                },
              }),
            ],
            1
          ),
          _c("van-field", {
            attrs: {
              label: "费项备注",
              name: "feesMemo",
              "show-word-limit": "",
              type: "textarea",
              rows: 4,
              placeholder: "请输入备注",
              "auto-size": "",
            },
            model: {
              value: _vm.formData.feesMemo,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "feesMemo", $$v)
              },
              expression: "formData.feesMemo",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }