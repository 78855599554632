var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "history-list-wrapper" },
    [
      _c(
        "my-nav",
        {
          attrs: {
            title: _vm.isFM ? "近3天处理记录" : "近3天处理品控任务记录",
          },
        },
        [
          _c("div", {
            staticClass: "icon-sampleResultsList",
            attrs: { slot: "right" },
            on: { click: _vm.toSampleResultsList },
            slot: "right",
          }),
        ]
      ),
      _vm.historyList.length > 0
        ? _c(
            "div",
            { staticClass: "list-wrapper" },
            _vm._l(_vm.historyList, function (item) {
              return _c(
                "div",
                {
                  key: item.id,
                  staticClass: "card-list",
                  class: { disabled: !_vm.isOffline && !item.hasOfflineData },
                  on: {
                    click: function ($event) {
                      return _vm.goDetail(item)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "card-title" },
                    [
                      _c("div", { staticClass: "title-info" }, [
                        _c("p", { staticClass: "num" }, [
                          _vm._v(_vm._s(item.order_no)),
                        ]),
                        _c(
                          "p",
                          {
                            staticClass: "copy",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                $event.stopPropagation()
                                return _vm.copyOrderNumber(item.order_no)
                              },
                            },
                          },
                          [_vm._v("复制")]
                        ),
                      ]),
                      !_vm.isOffline && !item.hasOfflineData
                        ? _c(
                            "van-tag",
                            { attrs: { type: "primary", color: "#666666" } },
                            [_vm._v("离线包无工单数据")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("p", { staticClass: "card-desc" }, [
                    _vm._v(_vm._s(item.remark)),
                  ]),
                  _c("div", { staticClass: "card-time" }, [
                    _c("p", { staticClass: "label" }, [
                      _vm._v("最后处理时间:"),
                    ]),
                    _c("p", { staticClass: "val" }, [
                      _vm._v(_vm._s(item.lastChangeTime)),
                    ]),
                  ]),
                ]
              )
            }),
            0
          )
        : _c("no-data", { attrs: { tex: "暂无近三天的操作数据" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }