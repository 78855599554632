<template>
  <div class="ReportOrderDetail">
    <my-nav title="工单详情"></my-nav>
    <van-tabs
      animated
      color="#3366FE"
      sticky
      offset-top="44"
      title-active-color="#3366FE"
      title-inactive-color="#7C7F8A"
      line-height="2px"
      line-width="187px"
      v-model="active"
    >
      <van-tab>
        <template v-slot:title>
          <div class="tab-class">
            <div>工单信息</div>
          </div>
        </template>
        <div class="scroll-detail-wrapper mb-10px">
          <div class="order-cell-title">
            <div class="flex-box">
              <div class="order-number">{{ detail.order_no }}</div>
              <van-tag
                plain
                type="primary"
                color="#999"
                @click="copyOrderNumber(detail.order_no)"
                class="copy-tag mr-8px"
                >复制</van-tag
              >
              <van-tag v-if="detail.isTouSu === 1" type="danger">投诉</van-tag>
            </div>
            <div class="status color-blue">{{ detail.node_name }}</div>
          </div>
          <div class="sub-title">报事人信息</div>
          <van-row class="order-body-cell">
            <van-col :span="6" class="sub-key">报事人：</van-col>
            <van-col :span="18" class="flex-box sub-value">
              <span>{{ detail.reportUserName }}</span>
              <span class="hpone-icon" v-if="detail.reportUserPhone"></span>
              <span
                class="color-blue"
                v-if="detail.reportUserPhone"
                @click="takePhone(detail.reportUserPhone)"
                >{{ detail.reportUserPhone }}</span
              >
              <van-tag
                plain
                v-if="detail.reportUserLevel"
                type="primary"
                color="#F47A45"
                class="ml-8px"
                >{{ detail.reportUserLevel }}</van-tag
              >
            </van-col>
          </van-row>
          <van-row class="order-body-cell" gutter="10">
            <van-col :span="6" class="sub-key">项目名称：</van-col>
            <van-col :span="18" class="sub-value">
              <span>{{ detail.project_name }}</span>
            </van-col>
          </van-row>
          <van-row class="order-body-cell" gutter="10">
            <van-col :span="6" class="sub-key">报事位置：</van-col>
            <van-col :span="18" class="sub-value">
              <span>{{ detail.addressName }}</span>
            </van-col>
          </van-row>
          <van-row class="order-body-cell" gutter="10">
            <van-col :span="6" class="sub-key">报事时间：</van-col>
            <van-col :span="18" class="sub-value">
              <span>{{ detail.create_time }}</span>
            </van-col>
          </van-row>
          <van-row class="order-body-cell" gutter="10">
            <van-col :span="6" class="sub-key">预约时间：</van-col>
            <van-col :span="18" class="sub-value">
              <span>{{ detail.bookingTime }}</span>
            </van-col>
          </van-row>
          <div class="sub-title">报事信息</div>
          <van-row class="order-body-cell" gutter="10">
            <van-col :span="6" class="sub-key">报事来源：</van-col>
            <van-col :span="18" class="sub-value">
              <span>{{ detail.incidentSource }}</span>
            </van-col>
          </van-row>
          <van-row class="order-body-cell" gutter="10">
            <van-col :span="6" class="sub-key">报事责任：</van-col>
            <van-col :span="18" class="sub-value">
              <span>{{ detail.duty }}</span>
            </van-col>
          </van-row>
          <van-row class="order-body-cell" gutter="10">
            <van-col :span="6" class="sub-key">报事类型：</van-col>
            <van-col :span="18" class="flex-box sub-value">
              <span>{{
                detail.drClassName
              }}</span>
              <van-tag
                v-if="reverseButton"
                type="primary"
                class="ml-8px"
                color="#ECF1FF"
                text-color="#3366FE"
                @click="intoCirculation(reverseButton, true)"
                >{{reverseButton.buttonName}}</van-tag
              >
            </van-col>
          </van-row>
          <van-row class="order-body-cell" gutter="10">
            <van-col :span="6" class="sub-key">报事方式：</van-col>
            <van-col :span="18" class="sub-value">
              <span>{{ detail.infoSource }}</span>
            </van-col>
          </van-row>
          <van-row class="order-body-cell">
            <van-col :span="6" class="sub-key">接待人：</van-col>
            <van-col :span="18" class="flex-box sub-value">
              <span>{{ detail.admiMan }}</span>
              <span class="hpone-icon" v-if="detail.admiPhone"></span>
              <span
                class="color-blue"
                v-if="detail.admiPhone"
                @click="takePhone(detail.admiPhone)"
                >{{ detail.admiPhone }}</span
              >
            </van-col>
          </van-row>
          <van-row class="order-body-cell">
            <van-col :span="6" class="sub-key">处理人：</van-col>
            <van-col :span="18" class="flex-box sub-value">
              <span>{{ detail.handler_name }}</span>
              <span class="hpone-icon" v-if="detail.handler_phone"></span>
              <span
                class="color-blue"
                v-if="detail.handler_phone"
                @click="takePhone(detail.handler_phone)"
                >{{ detail.handler_phone }}</span
              >
              <van-tag
                type="primary"
                class="ml-8px"
                v-if="detail.isTouSu === 1 && detail.node_name === '处理中' && detail.arriveData"
                @click="handleRouterToTousuList"
                >投诉还原</van-tag
              >
            </van-col>
          </van-row>
          <van-row class="order-body-cell" gutter="10">
            <van-col :span="6" class="sub-key">报事类别：</van-col>
            <van-col :span="18" class="sub-value">
              <span>{{ detail.category_name }}</span>
            </van-col>
          </van-row>
          <van-row class="order-body-cell" gutter="10">
            <van-col :span="6" class="sub-key">报事细类：</van-col>
            <van-col :span="18" class="sub-value">
              <span>{{ detail.small_category_name }}</span>
            </van-col>
          </van-row>

          <div class="sub-title">报事内容</div>
          <van-row class="order-body-cell" gutter="10">
            <van-col :span="6" class="sub-key">报事描述：</van-col>
            <van-col :span="18" class="sub-value">
              <div>{{ detail.remark }}</div>
              <div
                v-if="
                  detail.attachment_list && detail.attachment_list.length > 0
                "
                class="detail_image_list"
              >
                <van-image
                  width="40"
                  height="40"
                  radius="4"
                  class="photo"
                  v-for="(list, index) of detail.attachment_list"
                  @click="imgPreview(index, detail.attachment_list)"
                  :key="index"
                  :src="list.url"
                />
              </div>
            </van-col>
          </van-row>
        </div>
        <div
          class="scroll-detail-wrapper mb-10px"
          v-if="detail.incidentFree && detail.incidentFree.length"
        >
          <div class="sub-title">收费信息</div>
          <van-row class="order-body-cell" gutter="10">
            <van-col :span="6" class="sub-key">是否收费：</van-col>
            <van-col :span="18" class="sub-value">
              <span>是</span>
            </van-col>
          </van-row>
          <van-row class="order-body-cell" gutter="10">
            <van-col :span="6" class="sub-key">收费金额：</van-col>
            <van-col :span="18" class="sub-value">
              <div class="text-[#3366FE]">
                {{ detail.feesAmount }}元
              </div>
              <div v-for="(item, index) in detail.incidentFree" :key="index">
                <span>{{ item.costName }}</span>
                <span>-{{ item.stanName }}</span>
                <span class="text-[#3366FE] ml-4px">¥{{ item.dueAmount }}</span>
                <span class="text-[#3366FE] ml-4px">{{ item.paysItem }}</span>
              </div>
            </van-col>
          </van-row>
        </div>
        <div
          class="scroll-detail-wrapper mb-10px"
          v-if="detail.incidentTouSu"
        >
          <div class="sub-title flex justify-between items-center">
            <span>投诉信息</span>
            <span class="text-[#3366FE] text-[16px]" @click="handleRouterToTousuList">更多&gt;</span>
          </div>
          <van-row class="order-body-cell" gutter="10">
            <van-col :span="6" class="sub-key">是否投诉：</van-col>
            <van-col :span="18" class="sub-value">
              <span>{{ detail.isTouSu === 1 ? "是" : "否" }}</span>
            </van-col>
          </van-row>
          <van-row class="order-body-cell" gutter="10">
            <van-col :span="6" class="sub-key">投诉性质：</van-col>
            <van-col :span="18" class="sub-value">
              <span>{{ detail.incidentTouSu.complaintType }}</span>
            </van-col>
          </van-row>
          <van-row class="order-body-cell" gutter="10">
            <van-col :span="6" class="sub-key">投诉等级：</van-col>
            <van-col :span="18" class="sub-value">
              <span>{{ detail.incidentTouSu.touSuDegree }}</span>
            </van-col>
          </van-row>
          <van-row class="order-body-cell" gutter="10">
            <van-col :span="6" class="sub-key">投诉还原：</van-col>
            <van-col :span="18" class="sub-value">
              <div>{{ detail.incidentTouSu.touSuReasons }}</div>
              <div
                v-if="
                  detail.incidentTouSu.touSuImg && detail.incidentTouSu.touSuImg.length > 0
                "
                class="detail_image_list"
              >
                <van-image
                  width="40"
                  height="40"
                  radius="4"
                  class="photo"
                  v-for="(img, index) of detail.incidentTouSu.touSuImg"
                  @click="imgPreview(index, detail.incidentTouSu.touSuImg)"
                  :key="index"
                  :src="img"
                />
              </div>
            </van-col>
          </van-row>
        </div>
        <div
          class="scroll-detail-wrapper"
          v-if="detail.incidentProcessList && detail.incidentProcessList.length"
        >
          <div class="sub-title flex justify-between items-center">
            <span>审核信息</span>
          </div>
          <myTableVue
            :columns="tableColumns"
            :tableData="detail.incidentProcessList"
          ></myTableVue>
        </div>
      </van-tab>
      <van-tab>
        <template v-slot:title>
          <div class="tab-class">
            <div>跟进信息</div>
          </div>
        </template>
        <div class="scroll-detail-wrapper">
          <van-steps
            direction="vertical"
            active-color="#3366fe"
            class="stpe-component"
          >
            <van-step
              class="step"
              v-for="(item, index) of logList"
              :key="index"
            >
              <div slot="active-icon" class="step-active-dot"></div>
              <div class="step-cell">
                <template v-if="item.currNodeId !== item.preNodeId">
                  <span class="color-blue">{{ item.preNodeName }}</span>
                  <em v-if="item.preNodeName" class="icon-arrow-blue"></em>
                  <span
                    class="color-blue"
                    :class="{ 'ml-24': item.preNodeName }"
                    >{{ item.curNodeName }}</span
                  >
                </template>
                <template v-else>
                  <span class="color-blue">{{ item.curNodeName }}</span>
                </template>
                <span class="color-gray"
                  >「{{ item.handlerName
                  }}<span v-if="item.handleMobile"
                    >({{ item.handleMobile }})</span
                  >」</span
                >
              </div>
              <div v-if="item.cuiban" style="margin: 4px 0">
                <van-tag
                  plain
                  color="#FD8D37"
                  size="medium"
                  style="background: rgba(253, 141, 55, 0.1)"
                >
                  <span class="font-bold">催办</span>
                </van-tag>
              </div>
              <div class="step-cell time color-gray">
                {{ item.createTime }}
              </div>
              <div class="step-cell color-black">
                {{ item.content }}
              </div>
              <div class="order-photos" v-if="item.attachments && item.attachments.length">
                <van-image
                  width="40"
                  height="40"
                  radius="4"
                  class="photo"
                  v-for="(list, index) of item.attachments"
                  @click="imgPreview(index, item.attachments)"
                  :key="index"
                  :src="list.url"
                />
              </div>
              <div class="file-list">
                <div
                  class="list"
                  v-for="(file, index) of item.fileList"
                  :key="file.id + index"
                >
                  <i class="file-icon"></i>
                  <span class="file-name">{{ file.name }}</span>
                </div>
              </div>
            </van-step>
          </van-steps>
        </div>
      </van-tab>
    </van-tabs>
    <div class="bottom-area" :style="`--column:${Math.min(buttonList.length,3)} `" v-if="!readonly && buttonList && buttonList.length > 0">
      <div
        class="button"
        @click="intoCirculation(item)"
        v-for="(item, index) of buttonList"
        :key="index"
      >
        {{ item.buttonName }}
      </div>
    </div>
    <van-overlay
      :show="phoneShow"
      @click="phoneShow = false"
      class-name="overlay"
      z-index="9999"
    >
      <div class="wrapper" @click.stop>
        <div class="phone-lay">
          <div class="phone-cell" @click="phoneOperate(1)">
            拨打{{ stashPhone }}
          </div>
          <div class="phone-cell" @click="phoneOperate(2)">取消</div>
        </div>
      </div>
    </van-overlay>
    <circulation @refresh="getOrderDetail" :is-reverse="isReverseButton" v-if="circulationShow" @ok="getOrderDetail" :order="detail" :title="circulationTitle" :field-config="currentFieldConfig" :show.sync="circulationShow"></circulation>
  </div>
</template>

<script>
import { findOrderDetail } from '@/api/report'
import mobile from '@/utils/mobile'
import { ImagePreview } from 'vant'
import myTableVue from '../components/my-table.vue'
import circulation from '../components/circulation.vue'

export default {
  data () {
    return {
      circulationShow: false,
      active: 0,
      orderId: '',
      detail: {},
      logList: [],
      buttonList: [],
      buttonInitList: [],
      phoneShow: false,
      stashPhone: false,
      tableColumns: [
        {
          label: '类型',
          tableDataprop: 'approvalType'
        },
        {
          label: '发起时间',
          tableDataprop: 'createTime'
        },
        {
          label: '状态',
          tableDataprop: 'auditStatus'
        }
      ],
      reverseButton: null,
      currentFieldConfig: '',
      circulationTitle: '跟进',
      readonly: false,
      isReverseButton: false,
      isFollowup: false
    }
  },
  mounted () {
    const { id, readonly, isFollowup } = this.$route.query
    const { buttons } = this.$route.params
    console.log('buttons: ', buttons)
    this.buttonInitList = buttons || []
    console.log('readonly: ', readonly)
    this.orderId = id
    this.isFollowup = isFollowup
    this.readonly = readonly
    // this.orderId = '100091000000165490'
    this.getOrderDetail(this.buttonInitList.length === 0)
  },
  components: {
    myTableVue,
    circulation
  },
  computed: {
    computedAmountTotal () {
      return function (array) {
        return array.reduce((sum, item) => {
          sum += item.dueAmount * 1 ? item.dueAmount * 1 : 0
          return sum
        }, 0)
      }
    }
  },
  methods: {
    handleRouterToTousuList () {
      this.$router.push({
        name: 'ReportTousuList',
        query: {
          projectCode: this.detail.project_code,
          twOrderId: this.orderId,
          addAble: this.detail.node_name === '处理中' ? '1' : '0'
        }
      })
    },
    setWrapperBottomPadding () {
      this.$nextTick(() => {
        const orderDetail = document.querySelector('.ReportOrderDetail')
        const bottomArea = document.querySelector('.bottom-area')
        orderDetail.style.paddingBottom = `${bottomArea.clientHeight}px`
      })
    },
    async getOrderDetail (buttonReset = true) {
      const result = await findOrderDetail(this.orderId, this.isFollowup)
      console.log('result: ', result)
      this.initOrderDetail(result, buttonReset)
    },
    initOrderDetail (result, buttonReset) {
      this.detail = result.model
      this.logList = result.trace_info
      const buttonList = buttonReset ? (result.model.route_list || []) : this.buttonInitList
      this.buttonList = buttonList.filter(item => !item.forbidden && item.buttonName !== '转口派' && item.buttonName !== '转书面')
      if (this.detail.isBeoverdue === 1 && this.detail.node_name === '处理中') {
        const completeButton = this.buttonList.find(i => i.buttonName === '完成')
        if (completeButton) completeButton.buttonName = '超时完成'
      }
      this.reverseButton = (result.model.route_list || []).find(item => item.buttonName === '转口派' || item.buttonName === '转书面')
      if (this.detail.incidentTouSu) {
        this.detail.touSuDegree = this.detail.incidentTouSu.touSuDegree
        this.detail.complaintType = this.detail.incidentTouSu.complaintType
      }
      if (this.detail.incidentTouSu && this.detail.incidentTouSu.touSuImg && this.detail.incidentTouSu.touSuImg.length) {
        this.detail.incidentTouSu.touSuImg = this.detail.incidentTouSu.touSuImg.split(',')
      }
      this.setWrapperBottomPadding()
    },
    /* 复制订单号 */
    copyOrderNumber (orderNo) {
      this.$copyText(orderNo).then(
        (e) => {
          this.$notify({
            type: 'success',
            message: '复制成功',
            background: '#13CE66'
          })
        },
        (e) => {
          this.$notify({
            type: 'warning',
            message: '复制成功',
            background: '#FFC82C'
          })
        }
      )
    },
    /* 唤起手机拨号 */
    takePhone (phoneNum) {
      this.stashPhone = phoneNum
      if (mobile.platform === 'IOS') {
        window.location.href = 'tel:' + this.stashPhone
      } else {
        this.phoneShow = true
      }
    },
    /* 拨号调用 */
    phoneOperate (type) {
      if (type === 1) {
        window.location.href = 'tel:' + this.stashPhone
      } else {
        this.phoneShow = false
      }
    },
    /* 图片查看器 */
    imgPreview (index, attachmentList) {
      let imgs = []
      if (typeof attachmentList[0] === 'string') {
        imgs = attachmentList
      } else {
        imgs = attachmentList.map(item => item.url)
      }
      ImagePreview({
        images: imgs,
        startPosition: index
      })
    },
    intoCirculation (button, isReverseButton = false) {
      if (this.detail.isTouSu === 1 && (button.buttonName === '完成' || button.buttonName === '超时完成') && !this.detail.incidentTouSu) {
        this.$dialog.confirm({
          title: '提示',
          message: '投诉类工单，请先添加投诉还原!',
          confirmButtonText: '去添加',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$router.push({
            name: 'ReportTousuAdd',
            query: {
              projectCode: this.detail.project_code,
              twOrderId: this.orderId
            }
          })
        })
        return
      }
      this.circulationShow = true
      this.circulationTitle = button.buttonName
      this.currentFieldConfig = button.fieldConfig
      this.isReverseButton = isReverseButton
    }
  }
}
</script>

<style lang="less" scoped>
@import url("./index.less");
</style>
