<template>
  <div class="OrderSpotCheckHandle">
    <my-nav title="抽查结果"></my-nav>
    <div class="content">
      <div
        v-for="(item, index) in spotCheckItems"
        :key="index"
        class="checkItem"
      >
        <div class="title">
          <div class="name">
            {{ index + 1 }}、{{ item.spotCheckConfigName }}
          </div>
          <van-radio-group
            class="radio-group"
            v-model="item.checkResult"
            direction="horizontal"
            v-if="!checked"
          >
            <van-radio checked-color="#07c160" :name="1">合格</van-radio>
            <van-radio checked-color="#ee0a24" :name="0">不合格</van-radio>
          </van-radio-group>
          <div v-else>
            <van-tag
              style="height: 24px"
              v-if="item.checkResult === 1"
              type="success"
              >合格</van-tag
            >
            <van-tag
              style="height: 24px"
              v-else-if="item.checkResult === 0"
              type="danger"
              >不合格</van-tag
            >
          </div>
        </div>
        <van-field
          v-model="item.checkConclusion"
          rows="3"
          autosize
          :readonly="checked"
          type="textarea"
          style="background-color: #f7f8fa; border-radius: 4px; margin-top: 6px"
          placeholder="请输入抽查结论"
          show-word-limit
        />
        <div class="mt-10px mb-10px" v-if="!checked && showImgAfterLoad && item.spotCheckAttachments && item.spotCheckAttachments.length">
          <van-image
            width="40"
            height="40"
            radius="4"
            class="mr-10px mt-10px"
            v-for="(image, imgIndex) of item.spotCheckAttachments"
            @click="imgPreview(item.spotCheckAttachments, imgIndex)"
            :key="image"
            :src="offlineImageBase64[image]"
          />
        </div>
        <div v-if="checked">
          <van-image
            width="40"
            height="40"
            radius="4"
            class="mr-10px mt-10px"
            v-for="(image, imgIndex) of item.spotCheckAttachments"
            @click="imgPreview(item.spotCheckAttachments, imgIndex)"
            :key="image"
            :src="image.url"
          />
        </div>
      </div>
      <div class="pt-10px pb-10px flex items-center">
        结果：
        <van-tag
          style="height: 24px"
          v-if="checkResultComp === '合格'"
          type="success"
          >合格</van-tag
        >
        <van-tag
          style="height: 24px"
          v-else-if="checkResultComp === '不合格'"
          type="danger"
          >不合格</van-tag
        >
        <span v-else>-</span>
      </div>
    </div>
    <div class="footer">
        <van-button class="btn cancel" @click="routerBack">取消</van-button>
        <van-button
          class="btn save"
          :loading="submitLoading"
          v-if="!checked"
          @click="handleSave"
          >{{ isFinalNofinishOption ? (nextNofinishItemId ? '保存并继续' : '保存') : '保存' }}</van-button
        >
      </div>
  </div>
</template>

<script>
import mobile from '@/utils/mobile'
import { ImagePreview } from 'vant'
import { potTaskOrder } from '@/api/orderSpotCheck'
import {
  uploadPhotoByImgKeys
} from '@/plugins/offlineMixin/utils/offlineImgTool'

export default {
  data () {
    return {
      submitLoading: false,
      spotCheckItems: [],
      showImgAfterLoad: false,
      offlineImageBase64: {},
      checked: false,
      isFinalNofinishOption: false, // 是否是最后一个完成的检查项
      nextNofinishItemId: undefined, // 下一个未完成的点位id itemId
      isDoneSubmit: false
    }
  },
  mounted () {
    const { spotCheckItems, isFinalNofinishOption, nextNofinishItemId } = this.$route.params
    console.log('spotCheckItems: ', spotCheckItems)
    if (spotCheckItems && spotCheckItems.length) {
      this.spotCheckItems = spotCheckItems
      this.checked = this.spotCheckItems.every(
        (i) => i.checkResult || i.checkResult === 0
      )
    }
    this.isFinalNofinishOption = isFinalNofinishOption
    console.log('this.isFinalNofinishOption: ', this.isFinalNofinishOption)
    this.nextNofinishItemId = nextNofinishItemId
    console.log('this.nextNofinishItemId: ', this.nextNofinishItemId)
    if (!this.checked) this.loadImageByOfflineKey()
  },
  beforeRouteLeave (to, from, next) {
    console.log('this.nextNofinishItemId: ', this.nextNofinishItemId)
    console.log('this.isFinalNofinishOption: ', this.isFinalNofinishOption)
    console.log('this.isDoneSubmit: ', this.isDoneSubmit)
    if (this.nextNofinishItemId && this.isFinalNofinishOption && this.isDoneSubmit) {
      to.query.nextNofinishItemId = this.nextNofinishItemId
      to.query.next = true
    }
    next()
  },
  computed: {
    checkResultComp () {
      if (
        this.spotCheckItems.some((i) => !i.checkResult && i.checkResult !== 0)
      ) { return '' }
      return this.spotCheckItems.every((i) => i.checkResult === 1)
        ? '合格'
        : '不合格'
    }
  },
  methods: {
    async loadImageByOfflineKey () {
      for (const spotCheckItem of this.spotCheckItems) {
        if (
          spotCheckItem.spotCheckAttachments &&
          spotCheckItem.spotCheckAttachments.length > 0
        ) {
          for (const key of spotCheckItem.spotCheckAttachments) {
            this.offlineImageBase64[key] = await mobile.getSaveDataBase64(key)
          }
        }
      }
      this.showImgAfterLoad = true
    },
    routerBack () {
      this.$router.back()
    },
    async handleSave () {
      if (!this.checkResultComp) {
        return this.$notify({
          type: 'warning',
          message: '请完整填写后提交'
        })
      }
      this.submitLoading = true
      try {
        const submitBody = this.lodash.cloneDeep(this.spotCheckItems)
        for (const spotCheckItem of submitBody) {
          if (spotCheckItem.spotCheckAttachments && spotCheckItem.spotCheckAttachments.length > 0) {
            spotCheckItem.spotCheckAttachments = await uploadPhotoByImgKeys(spotCheckItem.spotCheckAttachments)
            spotCheckItem.spotCheckAttachments = spotCheckItem.spotCheckAttachments.map(i => {
              return {
                ...i,
                type: i.fileType
              }
            })
          }
        }
        console.log('submitBody: ', submitBody)
        await potTaskOrder(submitBody)
        this.$toast('保存成功')
        this.isDoneSubmit = true
        this.$EB.$emit(this.$EBT.ORDER_SPOTCHECK_REFRESH)
        setTimeout(() => {
          this.$router.back()
        }, 1500)
      } catch (error) {
        console.error('error: ', error)
      } finally {
        this.submitLoading = false
      }
    },
    imgPreview (list, index) {
      let images = []
      if (typeof list[0] === 'string') {
        images = list.map((i) => this.offlineImageBase64[i])
      } else if (list[0].url) {
        images = list.map((i) => i.url)
      } else {
        return
      }
      ImagePreview({
        images,
        startPosition: index
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
