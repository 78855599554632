var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "van-popup",
    {
      style: { width: "100%", height: "100%" },
      attrs: {
        "close-on-popstate": false,
        position: "right",
        overlay: false,
        duration: _vm.animateDuration,
      },
      model: {
        value: _vm.filterPopShow,
        callback: function ($$v) {
          _vm.filterPopShow = $$v
        },
        expression: "filterPopShow",
      },
    },
    [
      _c(
        "div",
        { staticClass: "report-filter-popup" },
        [
          _c("my-nav", {
            attrs: { blue: "", title: "筛选", "self-back": true },
            on: { back: _vm.goBack },
          }),
          _c(
            "div",
            [
              _c("van-field-picker", {
                attrs: {
                  label: "报事类型",
                  name: "drClassName",
                  columns: _vm.drClassColumns,
                  "real-value": _vm.formData.drClass,
                },
                on: {
                  "update:realValue": function ($event) {
                    return _vm.$set(_vm.formData, "drClass", $event)
                  },
                  "update:real-value": function ($event) {
                    return _vm.$set(_vm.formData, "drClass", $event)
                  },
                },
                model: {
                  value: _vm.formData.drClassName,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "drClassName", $$v)
                  },
                  expression: "formData.drClassName",
                },
              }),
              _c("van-field", {
                attrs: {
                  label: "创建时间",
                  placeholder: "开始时间-结束时间",
                  "input-align": "right",
                  readonly: "",
                  "is-link": "",
                  value: _vm.formData.admiManTime,
                  clickable: "",
                },
                on: {
                  click: function ($event) {
                    _vm.showCalendar = true
                  },
                },
              }),
              _c("van-calendar", {
                attrs: {
                  color: "#3366FE",
                  "min-date": new Date(1990, 0, 1),
                  type: "range",
                },
                on: { confirm: _vm.onCalendarConfirm },
                model: {
                  value: _vm.showCalendar,
                  callback: function ($$v) {
                    _vm.showCalendar = $$v
                  },
                  expression: "showCalendar",
                },
              }),
              _c("van-field-picker", {
                attrs: {
                  label: "是否投诉",
                  name: "isTousu",
                  columns: _vm.yesOrNoColumns,
                  "real-value": _vm.formData.isTousu,
                },
                on: {
                  "update:realValue": function ($event) {
                    return _vm.$set(_vm.formData, "isTousu", $event)
                  },
                  "update:real-value": function ($event) {
                    return _vm.$set(_vm.formData, "isTousu", $event)
                  },
                },
                model: {
                  value: _vm.formData.isTousuName,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "isTousuName", $$v)
                  },
                  expression: "formData.isTousuName",
                },
              }),
              _c("van-field-picker", {
                attrs: {
                  label: "投诉等级",
                  name: "complaintlevelCode",
                  columns: _vm.tousuClassColumns,
                  "real-value": _vm.formData.complaintlevelCode,
                },
                on: {
                  "update:realValue": function ($event) {
                    return _vm.$set(_vm.formData, "complaintlevelCode", $event)
                  },
                  "update:real-value": function ($event) {
                    return _vm.$set(_vm.formData, "complaintlevelCode", $event)
                  },
                },
                model: {
                  value: _vm.formData.complaintlevelCode,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "complaintlevelCode", $$v)
                  },
                  expression: "formData.complaintlevelCode",
                },
              }),
              _c("van-field", {
                attrs: {
                  type: "digit",
                  "input-align": "right",
                  label: "报事电话",
                  placeholder: "请输入",
                },
                model: {
                  value: _vm.formData.phone,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "phone", $$v)
                  },
                  expression: "formData.phone",
                },
              }),
              _c("van-field", {
                attrs: {
                  type: "text",
                  "input-align": "right",
                  label: "处理人",
                  placeholder: "请输入",
                },
                model: {
                  value: _vm.formData.dealMan,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "dealMan", $$v)
                  },
                  expression: "formData.dealMan",
                },
              }),
              _c("van-field-picker", {
                attrs: {
                  label: "报事渠道",
                  name: "incidentChannel",
                  columns: _vm.incidentChannelColumns,
                  "real-value": _vm.formData.incidentChannel,
                },
                on: {
                  "update:realValue": function ($event) {
                    return _vm.$set(_vm.formData, "incidentChannel", $event)
                  },
                  "update:real-value": function ($event) {
                    return _vm.$set(_vm.formData, "incidentChannel", $event)
                  },
                },
                model: {
                  value: _vm.formData.incidentChannel,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "incidentChannel", $$v)
                  },
                  expression: "formData.incidentChannel",
                },
              }),
              _c("van-field-picker", {
                attrs: {
                  label: "是否逾期",
                  name: "isBeoverdue",
                  columns: _vm.yesOrNoColumns,
                  "real-value": _vm.formData.isBeoverdue,
                },
                on: {
                  "update:realValue": function ($event) {
                    return _vm.$set(_vm.formData, "isBeoverdue", $event)
                  },
                  "update:real-value": function ($event) {
                    return _vm.$set(_vm.formData, "isBeoverdue", $event)
                  },
                },
                model: {
                  value: _vm.formData.isBeoverdueName,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "isBeoverdueName", $$v)
                  },
                  expression: "formData.isBeoverdueName",
                },
              }),
              _c("van-field-picker", {
                attrs: {
                  label: "是否收费",
                  name: "isDueAmount",
                  columns: _vm.yesOrNoColumns,
                  "real-value": _vm.formData.isDueAmount,
                },
                on: {
                  "update:realValue": function ($event) {
                    return _vm.$set(_vm.formData, "isDueAmount", $event)
                  },
                  "update:real-value": function ($event) {
                    return _vm.$set(_vm.formData, "isDueAmount", $event)
                  },
                },
                model: {
                  value: _vm.formData.isDueAmountName,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "isDueAmountName", $$v)
                  },
                  expression: "formData.isDueAmountName",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "footer-btn" },
            [
              _c(
                "van-button",
                { staticClass: "btn resolve", on: { click: _vm.handleReset } },
                [_vm._v("重置")]
              ),
              _c(
                "van-button",
                {
                  staticClass: "btn reject",
                  on: { click: _vm.handleApplyConfirm },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }