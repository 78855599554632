<template>
  <span class="score">
    <span class="txt" v-if="showTxt">得分</span>
    <span class="first">{{ score }}</span>
    <span class="line"></span>
    <span class="last">{{ total }}</span>
  </span>
</template>

<script>
export default {
  name: 'score',
  props: {
    total: {
      type: Number || String,
      required: true
    },
    score: {
      type: Number || String,
      required: true
    },
    showTxt: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="less" scoped>
.score {
  background-color: #fd8d37;
  padding-left: 6px;
  padding-right: 6px;
  height: 24px;
  border-radius: 4px;
  display: inline-block;
  line-height: 24px;
  font-size: 12px;
  color: #ffffff;
  & > span {
    font-size: 14px;
    &.first {
      margin-right: 4px;
    }
    &.last {
      margin-left: 4px;
    }
    &.txt {
      margin-right: 4px;
    }
  }
  .line {
    display: inline-block;
    height: 11px;
    width: 1px;
    background-color: #ffffff;
  }
}
</style>
