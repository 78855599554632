<template>
<div>
  <Cascader ref="cascaderRef" title="故障指导" :initList="displayList" :cb-select="selectItem" root-name="故障指导"
            leaf-comp-name="MalfunctionGuideDetailComp" :cb-go-back="()=>$router.back()" @search="handleSearch"/>
            </div>
</template>

<script>
import Cascader from '@/components/Cascader'
import api from '@/api/index'
import offLineMixin from '@/plugins/offlineMixin'
import MalfListGuideOfflineApiHandlers from '@/plugins/offlineMixin/offlineApiHandlers/MalfListGuideOfflineApiHandlers'
import MalfCompListGuideOfflineApiHandlers from '@/plugins/offlineMixin/offlineApiHandlers/MalfCompListGuideOfflineApiHandlers'

export default {
  name: 'MalfunctionGuide',
  components: { Cascader },
  mixins: [offLineMixin],
  data () {
    return {
      pointCode: undefined,
      mainPart: undefined,
      displayList: [],
      allCausesList: [],
      treeChild: [],
      searchVal: []
    }
  },
  mounted () {
    const pointCode = this.$route.query.pointCode
    const mainPart = this.$route.query.mainPart
    const pointType = this.$route.query.pointType
    this.pointCode = pointCode
    this.mainPart = mainPart
    this.pointType = pointType
    console.log('是否展示主要部件页面isDisplayParts: ', this.$route.query.isDisplayParts)
    if (this.$route.query.isDisplayParts === 'true') { // 需要展示主要部件页面
      console.log(111111)
      this.$apiOffline({
        methods: 'get',
        api: api.getAllCausesByPoint,
        params: { pointCode, pointType, status: 1, mainPart },
        offlineApiHandlers: MalfCompListGuideOfflineApiHandlers
      }).then(res => {
        console.log('主要部件接口123: ', res)
        if (!res) return
        this.allCausesList = res
        this.displayList = res.reduce((arr, item, index) => {
          arr.push({ code: item.code, codeName: item.name, children: [], isComp: true })
          return arr
        }, [])
        console.log('this.displayList111: ', this.displayList)
      })
    } else { // 不需要展示主要部件页面
      this.$apiOffline({
        methods: 'get',
        api: api.findListByPoint,
        params: { pointCode, pointType, status: 1, mainPart },
        offlineApiHandlers: MalfListGuideOfflineApiHandlers
      }).then(res => {
        if (!res) return
        this.allCausesList = res
        this.displayList = res.reduce((arr, item, index) => {
          const newList = this.transformCausesList(this.allCausesList)
          newList.forEach(i => {
            if (item.causesName === i.codeName) { arr.push({ code: item.causesId, codeName: item.causesName, children: i.children }) }
          })
          return arr
        }, [])
        console.log('this.displayList222: ', this.displayList)
      })
    }
  },
  methods: {
    selectItem (item) {
      console.log('item.isComp: ', item.isComp)
      if (item.isComp) {
        this.$apiOffline({
          methods: 'get',
          api: api.findListByPoint,
          params: { pointCode: this.pointCode, pointType: this.pointType, status: 1, mainPart: item.code },
          offlineApiHandlers: MalfListGuideOfflineApiHandlers
        }).then(res => {
          const newList = this.transformCausesList(res)
          this.$set(this.displayList[item.index], 'children', newList)
        })
        return true
      }
    },
    transformCausesList (source) {
      let arr = []
      source.forEach(item => {
        const { causesId, causesName, name, breakdownGuides, ...moreVal } = item
        let children
        if (breakdownGuides && breakdownGuides.length > 0) {
          children = this.transformCausesList(breakdownGuides)
        }
        arr.push({ code: causesId, codeName: name || causesName, children, ...moreVal })
        // console.log(123)
      })
      return arr
    },
    handleSearch ({ searchValue }) {
      if (searchValue) {
        this.$apiOffline({
          methods: 'get',
          api: api.findListByPoint,
          params: { pointCode: this.pointCode, pointType: this.pointType, status: 1, mainPart: this.mainPart },
          offlineApiHandlers: MalfListGuideOfflineApiHandlers
        }).then(res => {
          const newList = this.transformCausesList(res)
          // 先递归获取所有子节点 然后匹配搜索的值 渲染
          this.treeChild = []
          this.searchVal = []
          this.tree(newList)
          this.treeChild.forEach(i => {
            if (i.codeName.indexOf(searchValue) !== -1) {
              this.searchVal.push(i)
            }
          })
          this.$refs.cascaderRef.setSearchList(this.searchVal
            // newList.reduce((arr, item) => {
            // if (item.codeName.indexOf(searchValue) !== -1) {
            //   return arr.concat(item.children)
            // }
            // return arr
          // }, [])
          )
        })
      }
    },
    // 所有的子节点
    tree (tree) {
      tree.forEach(item => {
        if (!item.children) {
          this.treeChild.push(item)
        } else {
          this.tree(item.children)
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
