var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Cascader", {
        ref: "cascaderRef",
        attrs: {
          title: "故障指导",
          initList: _vm.displayList,
          "cb-select": _vm.selectItem,
          "root-name": "故障指导",
          "leaf-comp-name": "MalfunctionGuideDetailComp",
          "cb-go-back": () => _vm.$router.back(),
        },
        on: { search: _vm.handleSearch },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }