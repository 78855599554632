<template v-cloak>
  <div class="multi-select-wrapper">
    <div class="select-wrapper" @click="showPickerPopup" v-if="!detail">
      <div class="value-wrapper">
        <span class="placeholder" v-if="activeListName.length === 0"
          >请选择</span
        >
        <div class="tag-list" v-else>
          <van-tag
            size="large"
            color="rgb(51 102 254 / 10%)"
            text-color="#3366FE"
            class="mr-8"
            v-for="(item, index) in activeListName"
            :key="index"
            :disabled="detail"
            >{{ item }}</van-tag
          >
          <!-- <span class="placeholder" v-if="activeListName.length > 3"
            >共{{ activeListName.length }}条</span
          > -->
        </div>
      </div>
      <van-icon name="right-icon" />
    </div>
    <div class="select-wrapper bg-[#f7f8fa]" v-else>
      <span class="placeholder" v-if="activeListName.length === 0">请选择</span>
    </div>
    <van-popup v-model="showPicker" round position="bottom">
      <div class="popup-header">
        <p class="btn close" @click="handleClose">取消</p>
        <p class="text">多选</p>
        <p class="btn submit" @click="handleSubmit">确定</p>
      </div>
      <div class="popup-content">
        <div class="list-wrapper">
          <div
            class="content-list"
            v-for="item in selectList"
            :key="item[selectObj.key]"
            @click="changeActive(item)"
            :class="{ active: selectActiveList.includes(item[selectObj.key]) }"
          >
            {{ item[selectObj.val] }}
          </div>
        </div>
      </div>
      <div class="popup-footer">
        <van-button type="default" @click="reset">重置</van-button>
      </div>
    </van-popup>
  </div>
</template>

<script type='text/ecmascript-6'>
export default {
  name: '',
  components: {},
  props: {
    selectValue: {
      type: [Array, String],
      require: true,
      default: ''
    },
    selectList: {
      type: Array,
      require: true,
      default: () => []
    },
    selectObj: {
      type: Object,
      default: () => {
        return {
          key: 'code',
          val: 'name'
        }
      }
    },
    detail: { // 判断是否禁用 详情时禁用 除处理中状态外均为详情
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      value: '',
      showPicker: false,
      activeList: [],
      activeListName: [],
      selectActiveList: [],
      selectActiveListName: []
    }
  },
  created () {},
  mounted () {
    if (this.selectValue) {
      this._setDefaultValue()
    }
  },
  methods: {
    // 根据内容回填
    _setDefaultValue () {
      let { key, val } = this.selectObj
      this.selectList.forEach((item) => {
        if (this.selectValue.includes(item[key])) {
          this.activeList.push(item[key])
          this.activeListName.push(item[val])
        }
      })
      this.selectActiveList = JSON.parse(JSON.stringify(this.activeList))
      this.selectActiveListName = JSON.parse(JSON.stringify(this.activeListName))
    },
    showPickerPopup () {
      if (this.detail) return
      this.selectActiveList = JSON.parse(JSON.stringify(this.activeList))
      this.selectActiveListName = JSON.parse(JSON.stringify(this.activeListName))
      this.showPicker = true
    },
    // 选择多选
    changeActive (item) {
      let key = item[this.selectObj.key]
      let val = item[this.selectObj.val]
      if (this.selectActiveList.includes(key)) {
        this.selectActiveList.splice(this.selectActiveList.indexOf(key), 1)
        this.selectActiveListName.splice(this.selectActiveListName.indexOf(val), 1)
      } else {
        this.selectActiveList.push(key)
        this.selectActiveListName.push(val)
      }
      // if (this.selectActiveList.length === 0) {
      //   this.$emit('update:selectValue', '')
      // } else {
      //   this.$emit('update:selectValue', this.selectActiveList)
      // }
    },
    handleClose () {
      this.showPicker = false
    },
    handleSubmit () {
      // if (this.selectActiveList.length === 0) {
      //   return this.$toast('请先选择再确定')
      // }
      this.activeList = this.selectActiveList
      this.activeListName = this.selectActiveListName
      if (this.activeList.length === 0) {
        this.$emit('update:selectValue', '')
      } else {
        this.$emit('update:selectValue', this.activeList)
      }

      this.$emit('confirm')
      this.showPicker = false
    },
    reset () {
      this.selectActiveList = []
      this.selectActiveListName = []
      this.$emit('update:selectValue', '')
    }
  }
}
</script>
<style  lang='less' scoped>
.multi-select-wrapper {
  p {
    margin: 0;
  }
  .select-wrapper {
    min-height: 40px;
    box-sizing: border-box;
    padding: 0 12px 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: .5px solid #c5c5c5;
    border-radius: 2px;
    .value-wrapper{
      width: 100%;
    }
    .placeholder {
      color: #999;
      font-size: 16px;
    }
    .tag-list {
      padding-right: 7px;
      width: calc(100% + 12px);
      margin-left: -12px;
      .van-tag {
        padding: 4px 12px;
        font-size: 16px;
        color: #3366fe;
        height: 30px;
        box-sizing: border-box;
        margin-right: 0;
        margin-left: 12px;
        margin-bottom: 5px;
      }
      .placeholder {
        margin-left: 5px;
      }
    }
  }
  .van-popup {
    .popup-header {
      display: flex;
      height: 46px;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      border-bottom: .5px solid #e5e5e5;
      padding: 0 12px;
      .btn {
        font-size: 15px;
        color: #3366fe;
      }
      .text {
        font-size: 16px;
        color: #222;
        font-weight: 601;
      }
    }
    .popup-content {
      padding: 12px;
      height: 223px;
      overflow-y: scroll;
      .list-wrapper {
        width: calc(100% + 14px);
        display: flex;
        flex-wrap: wrap;
      }
      .content-list {
        width: 109px;
        height: 36px;
        margin-right: 12px;
        margin-bottom: 12px;
        background: #f5f5f5;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        color: #333;
        &.active {
          position: relative;
          background: rgba(51, 102, 254, 0.1);
          color: #3366fe;
          &:after {
            content: "";
            display: block;
            width: 20px;
            height: 17px;
            position: absolute;
            right: 0;
            bottom: 0;
            background: url("~@/asset/select-active-icon.png") no-repeat;
            background-size: 100% 100%;
          }
        }
      }
    }
    .popup-footer {
      padding: 18px 0;
      text-align: center;
      .van-button {
        width: 110px;
        height: 36px;
        border-radius: 4px;
        font-size: 18px;
        color: #222;
      }
    }
  }
}
</style>
