var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "type-list-wrapper" },
    _vm._l(_vm.listData, function (item, index) {
      return _c(
        "div",
        {
          key: item.code,
          staticClass: "type-list",
          class: {
            "more-form": item.typeOptions && item.typeOptions.length > 1,
          },
        },
        [
          !item.isHidden
            ? [
                _vm.$route.query.checkNo
                  ? _c(
                      "div",
                      {
                        staticClass: "absolute right-10px top-4px z-10",
                        on: {
                          click: function ($event) {
                            return _vm.goDetails(item)
                          },
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: _vm.calcImg(item),
                            alt: "",
                            width: "62px",
                          },
                        }),
                      ]
                    )
                  : _vm._e(),
                _vm.listData.length > 1
                  ? _c("div", { staticClass: "line-wrapper" }, [
                      _vm.setActive(item)
                        ? _c("p", {
                            staticClass: "default-icon",
                            class: { error: item.isError },
                          })
                        : _c("p", { staticClass: "active-icon" }),
                      _c("p", {
                        staticClass: "line",
                        class: {
                          active: !_vm.setActive(item),
                          "hide-line":
                            index === _vm.listData.length - 1 ||
                            _vm.orderDetail.model.order_type === 16,
                        },
                      }),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass: "type-detail",
                    class: { "no-line": _vm.listData.length <= 1 },
                  },
                  [
                    item.showName
                      ? [
                          _c("div", { staticClass: "list-title" }, [
                            _c(
                              "p",
                              {
                                staticClass: "title",
                                class: { error: item.isError },
                              },
                              [_vm._v(" " + _vm._s(item.name) + " ")]
                            ),
                            _vm.nodeCode === "chulizhong" &&
                            _vm.taskType !== 2 &&
                            _vm.taskType !== 3 &&
                            !_vm.detail &&
                            !(
                              _vm.orderDetail.model.order_type === 16 &&
                              item.name === "结果记录"
                            )
                              ? _c(
                                  "p",
                                  {
                                    staticClass: "title-btn",
                                    on: {
                                      click: function ($event) {
                                        return _vm.goProblem(index)
                                      },
                                    },
                                  },
                                  [_vm._v(" 问题记录 ")]
                                )
                              : _vm._e(),
                          ]),
                          _c("div", { staticClass: "list-title" }),
                          _c("p", { staticClass: "desc" }, [
                            _vm._v(" " + _vm._s(item.optionSecondTitle) + " "),
                          ]),
                          _vm.showImgAfterLoad &&
                          item.secondTitleAttachments &&
                          item.secondTitleAttachments.length > 0
                            ? _c(
                                "div",
                                { staticClass: "order-photos" },
                                _vm._l(
                                  item.secondTitleAttachments,
                                  function (
                                    secondTitleImg,
                                    secondTitleImgIndex
                                  ) {
                                    return _c("van-image", {
                                      key: secondTitleImgIndex,
                                      staticClass: "photo",
                                      attrs: {
                                        width: "40",
                                        height: "40",
                                        radius: "4",
                                        src: _vm.isOffline
                                          ? secondTitleImg.url
                                          : _vm.offlineImageBase[
                                              secondTitleImg.url
                                            ],
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.imgPreview(
                                            secondTitleImgIndex,
                                            item.secondTitleAttachments
                                          )
                                        },
                                      },
                                    })
                                  }
                                ),
                                1
                              )
                            : _vm._e(),
                        ]
                      : _vm._e(),
                    _vm._l(item.typeOptions, function (typeList, typeIndex) {
                      return _c(
                        "div",
                        {
                          key: typeList.type + typeIndex,
                          staticClass: "type-conent",
                        },
                        [
                          !_vm.status
                            ? [
                                typeList.type === 1
                                  ? _c("van-field", {
                                      staticClass: "mt10",
                                      class: {
                                        "type-required":
                                          typeList.required === 1,
                                        mt8: typeIndex > 0,
                                      },
                                      attrs: {
                                        type: "text",
                                        placeholder: "请输入",
                                        disabled: _vm.detail,
                                      },
                                      on: {
                                        blur: function ($event) {
                                          return _vm.onlyUpdate(
                                            index,
                                            typeIndex
                                          )
                                        },
                                      },
                                      model: {
                                        value: typeList.val,
                                        callback: function ($$v) {
                                          _vm.$set(typeList, "val", $$v)
                                        },
                                        expression: "typeList.val",
                                      },
                                    })
                                  : _vm._e(),
                                typeList.type === 2
                                  ? _c("van-field", {
                                      staticClass: "textarea mt10 noBorder",
                                      class: {
                                        "type-required":
                                          typeList.required === 1,
                                        mt8: typeIndex > 0,
                                      },
                                      attrs: {
                                        rows: "4",
                                        type: "textarea",
                                        placeholder: "请输入",
                                        "show-word-limit": "",
                                        disabled: _vm.detail,
                                      },
                                      on: {
                                        blur: function ($event) {
                                          return _vm.onlyUpdate(
                                            index,
                                            typeIndex
                                          )
                                        },
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          _vm.isOffline && !_vm.detail
                                            ? {
                                                key: "right-icon",
                                                fn: function () {
                                                  return [
                                                    _c("micro-text", {
                                                      attrs: {
                                                        "self-click": true,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.microClick(
                                                            index,
                                                            typeIndex
                                                          )
                                                        },
                                                        speakRes: _vm.speakRes,
                                                      },
                                                    }),
                                                  ]
                                                },
                                                proxy: true,
                                              }
                                            : null,
                                        ],
                                        null,
                                        true
                                      ),
                                      model: {
                                        value: typeList.val,
                                        callback: function ($$v) {
                                          _vm.$set(typeList, "val", $$v)
                                        },
                                        expression: "typeList.val",
                                      },
                                    })
                                  : _vm._e(),
                                typeList.type === 3
                                  ? _c("PIckerSelect", {
                                      staticClass: "mt10",
                                      class: {
                                        "type-required":
                                          typeList.required === 1,
                                        mt8: typeIndex > 0,
                                      },
                                      attrs: {
                                        selectValue: typeList.val,
                                        detail: _vm.detail,
                                        selectList: typeList.options,
                                      },
                                      on: {
                                        "update:selectValue": function (
                                          $event
                                        ) {
                                          return _vm.$set(
                                            typeList,
                                            "val",
                                            $event
                                          )
                                        },
                                        "update:select-value": function (
                                          $event
                                        ) {
                                          return _vm.$set(
                                            typeList,
                                            "val",
                                            $event
                                          )
                                        },
                                        confirm: function ($event) {
                                          return _vm.changTime(index, typeIndex)
                                        },
                                      },
                                    })
                                  : _vm._e(),
                                typeList.type === 4
                                  ? _c("MultiSelect", {
                                      staticClass: "mt10",
                                      class: {
                                        "type-required":
                                          typeList.required === 1,
                                        mt8: typeIndex > 0,
                                      },
                                      attrs: {
                                        selectValue: typeList.val,
                                        detail: _vm.detail,
                                        selectList: typeList.options,
                                      },
                                      on: {
                                        "update:selectValue": function (
                                          $event
                                        ) {
                                          return _vm.$set(
                                            typeList,
                                            "val",
                                            $event
                                          )
                                        },
                                        "update:select-value": function (
                                          $event
                                        ) {
                                          return _vm.$set(
                                            typeList,
                                            "val",
                                            $event
                                          )
                                        },
                                        confirm: function ($event) {
                                          return _vm.changTime(index, typeIndex)
                                        },
                                      },
                                    })
                                  : _vm._e(),
                                typeList.type === 5 && !_vm.detail
                                  ? _c("van-field", {
                                      staticClass: "mt10",
                                      class: {
                                        "type-required":
                                          typeList.required === 1,
                                        mt8: typeIndex > 0,
                                      },
                                      attrs: {
                                        type: "number",
                                        placeholder: "请输入数字",
                                        formatter: _vm.floatNum,
                                        "format-trigger": "onBlur",
                                      },
                                      on: {
                                        blur: function ($event) {
                                          return _vm.onlyUpdate(
                                            index,
                                            typeIndex
                                          )
                                        },
                                      },
                                      model: {
                                        value: typeList.val,
                                        callback: function ($$v) {
                                          _vm.$set(typeList, "val", $$v)
                                        },
                                        expression: "typeList.val",
                                      },
                                    })
                                  : _vm._e(),
                                typeList.type === 5 && _vm.detail
                                  ? _c("van-field", {
                                      staticClass: "mt10 bg-[#f7f8fa]",
                                      attrs: {
                                        type: "number",
                                        placeholder: "请输入数字",
                                        disabled: _vm.detail,
                                      },
                                      model: {
                                        value: typeList.val,
                                        callback: function ($$v) {
                                          _vm.$set(typeList, "val", $$v)
                                        },
                                        expression: "typeList.val",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                typeList.type === 6
                                  ? _c(
                                      "van-radio-group",
                                      {
                                        staticClass: "mt10",
                                        class: {
                                          "type-required":
                                            typeList.required === 1,
                                          mt8: typeIndex > 0,
                                        },
                                        attrs: {
                                          direction: "horizontal",
                                          disabled: _vm.detail,
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.changTime(
                                              index,
                                              typeIndex,
                                              typeList
                                            )
                                          },
                                        },
                                        model: {
                                          value: typeList.val,
                                          callback: function ($$v) {
                                            _vm.$set(typeList, "val", $$v)
                                          },
                                          expression: "typeList.val",
                                        },
                                      },
                                      _vm._l(typeList.options, function (item) {
                                        return _c(
                                          "van-radio",
                                          {
                                            key: item.code,
                                            class: {
                                              "type-required":
                                                typeList.required === 1,
                                              mt8: typeIndex > 0,
                                            },
                                            attrs: { name: item.code },
                                            scopedSlots: _vm._u(
                                              [
                                                !_vm.detail
                                                  ? {
                                                      key: "icon",
                                                      fn: function (props) {
                                                        return [
                                                          _c("img", {
                                                            staticClass:
                                                              "img-icon",
                                                            attrs: {
                                                              width: "22px",
                                                              height: "22px",
                                                              src: props.checked
                                                                ? require("@/asset/radio-active.png")
                                                                : require("@/asset/radio-default.png"),
                                                            },
                                                          }),
                                                        ]
                                                      },
                                                    }
                                                  : null,
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [_vm._v(_vm._s(item.name) + " ")]
                                        )
                                      }),
                                      1
                                    )
                                  : _vm._e(),
                                typeList.type === 10
                                  ? _c("van-stepper", {
                                      staticClass: "mt-10px mb-10px",
                                      attrs: {
                                        theme: "round",
                                        "button-size": "22",
                                        disabled: _vm.detail,
                                        max: typeList.singleScore,
                                        min: typeList.lowestScore,
                                        step: typeList.kjld,
                                        "input-width": "60px",
                                        "decimal-length": 1,
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.changTime(index, typeIndex)
                                        },
                                      },
                                      model: {
                                        value: typeList.val,
                                        callback: function ($$v) {
                                          _vm.$set(typeList, "val", $$v)
                                        },
                                        expression: "typeList.val",
                                      },
                                    })
                                  : _vm._e(),
                                typeList.type === 8
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "upload-box mt10",
                                        class: {
                                          "type-required":
                                            typeList.required === 1,
                                          mt8: typeIndex > 0,
                                        },
                                      },
                                      [
                                        _c("common-upload", {
                                          attrs: {
                                            disabled: _vm.detail,
                                            "project-name": _vm.projectName,
                                            file: typeList.val,
                                            text: "事前图片",
                                            "order-id": _vm.orderId,
                                          },
                                          on: {
                                            "update:file": function ($event) {
                                              return _vm.$set(
                                                typeList,
                                                "val",
                                                $event
                                              )
                                            },
                                            change: function ($event) {
                                              return _vm.changTime(
                                                index,
                                                typeIndex
                                              )
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                typeList.type === 7
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "upload-box mt10",
                                        class: {
                                          "type-required":
                                            typeList.required === 1,
                                          mt8: typeIndex > 0,
                                        },
                                      },
                                      [
                                        _c("common-upload", {
                                          attrs: {
                                            "order-id": _vm.orderId,
                                            disabled: _vm.detail,
                                            file: typeList.val,
                                            "project-name": _vm.projectName,
                                          },
                                          on: {
                                            "update:file": function ($event) {
                                              return _vm.$set(
                                                typeList,
                                                "val",
                                                $event
                                              )
                                            },
                                            change: function ($event) {
                                              return _vm.changTime(
                                                index,
                                                typeIndex
                                              )
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                typeList.type === 9
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "upload-box mt10",
                                        class: {
                                          "type-required":
                                            typeList.required === 1,
                                          mt8: typeIndex > 0,
                                        },
                                      },
                                      [
                                        _c("common-upload", {
                                          attrs: {
                                            "order-id": _vm.orderId,
                                            disabled: _vm.detail,
                                            "project-name": _vm.projectName,
                                            file: typeList.val,
                                            text: "事后图片",
                                          },
                                          on: {
                                            "update:file": function ($event) {
                                              return _vm.$set(
                                                typeList,
                                                "val",
                                                $event
                                              )
                                            },
                                            change: function ($event) {
                                              return _vm.changTime(
                                                index,
                                                typeIndex
                                              )
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            : [
                                ![2, 3, 4, 6, 7, 8, 9].includes(typeList.type)
                                  ? _c("p", { staticClass: "name" }, [
                                      _vm._v(" " + _vm._s(typeList.val) + " "),
                                    ])
                                  : _vm._e(),
                                typeList.type === 6 || typeList.type === 3
                                  ? _c("p", {
                                      staticClass: "name",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.formatArrKey(
                                            typeList.options,
                                            typeList.val
                                          )
                                        ),
                                      },
                                    })
                                  : _vm._e(),
                                typeList.type === 2
                                  ? _c("p", {
                                      staticClass: "name",
                                      domProps: {
                                        innerHTML: _vm._s(typeList.val),
                                      },
                                    })
                                  : _vm._e(),
                                [7, 8, 9].includes(typeList.type)
                                  ? _c(
                                      "div",
                                      { staticClass: "order-photos" },
                                      [
                                        _c("OfflinePreviewImg", {
                                          attrs: {
                                            title: _vm.getTitle(typeList.type),
                                            list: typeList.val,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                typeList.type === 4
                                  ? _c(
                                      "div",
                                      { staticClass: "tag-wrapper" },
                                      _vm._l(
                                        typeList.val,
                                        function (tagItem, tagIndex) {
                                          return _c(
                                            "div",
                                            {
                                              key: tagIndex,
                                              staticClass: "tag-list",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.formatArrKey(
                                                      typeList.options,
                                                      tagItem
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    )
                                  : _vm._e(),
                              ],
                        ],
                        2
                      )
                    }),
                  ],
                  2
                ),
              ]
            : _vm._e(),
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }