var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "van-popup",
    {
      staticClass: "problem-record",
      style: { width: "100%", height: "100%" },
      attrs: { position: "right", overlay: false },
      on: { opened: _vm.initPage },
      model: {
        value: _vm._show,
        callback: function ($$v) {
          _vm._show = $$v
        },
        expression: "_show",
      },
    },
    [
      _c(
        "div",
        { staticClass: "problem-check-wrapper" },
        [
          _c("my-nav", {
            attrs: { title: _vm.title, "self-back": true },
            on: { back: _vm.goBack },
          }),
          _c("div", { staticClass: "scroll-wrapper" }, [
            _vm.isOffline || _vm.copyList.length > 0
              ? _c("div", { staticClass: "scroll-box" }, [
                  _c("div", { ref: "bigReason", staticClass: "tab" }, [
                    _c(
                      "div",
                      { staticClass: "tab-cell" },
                      _vm._l(_vm.bigReasonList, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: item.problemTypeCode,
                            staticClass: "tab-item",
                            class: { active: item.isSelected },
                            on: {
                              click: function ($event) {
                                return _vm.selectBigReason(item, index)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(item.problemTypename) + " ")]
                        )
                      }),
                      0
                    ),
                    _c("p", { staticClass: "tab-line" }),
                  ]),
                  _vm.smallReasonList.length > 0
                    ? _c(
                        "div",
                        { staticClass: "check-box" },
                        _vm._l(_vm.smallReasonList, function (item, index) {
                          return _c(
                            "div",
                            { key: item.problemId, staticClass: "check-list" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "check-wrapper first-check-wrapper",
                                },
                                [
                                  _c("div", { staticClass: "check-left" }, [
                                    _c("i", {
                                      staticClass: "check-icon",
                                      class: `multi-${item.check}`,
                                      on: {
                                        click: function ($event) {
                                          return _vm.firstCheck(index)
                                        },
                                      },
                                    }),
                                    _c("p", { staticClass: "name" }, [
                                      _vm._v(
                                        _vm._s(item.problemName) +
                                          _vm._s(_vm.setFirstCount(item))
                                      ),
                                    ]),
                                  ]),
                                  _c("div", {
                                    staticClass: "check-right",
                                    class: { down: !item.show },
                                    on: {
                                      click: function ($event) {
                                        return _vm.showSecond(
                                          index,
                                          item.questLabel
                                        )
                                      },
                                    },
                                  }),
                                ]
                              ),
                              item.questLabel && item.questLabel.length > 0
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "second-check-wrapper check-wrapper",
                                    },
                                    [
                                      _c(
                                        "transition",
                                        { attrs: { name: "van-slide-down" } },
                                        [
                                          item.show
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "secon-transition",
                                                },
                                                _vm._l(
                                                  item.questLabel,
                                                  function (list, secondIndex) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: secondIndex,
                                                        staticClass:
                                                          "second-check-list",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.checkSecond(
                                                              index,
                                                              secondIndex
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "check-icon",
                                                          class: {
                                                            "second-check":
                                                              list.check,
                                                          },
                                                        }),
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass: "name",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(list.name)
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                { staticClass: "check-person" },
                                [
                                  _c("van-cell", {
                                    class: {
                                      personActive:
                                        item.rectifyPeopls &&
                                        item.rectifyPeopls.name,
                                    },
                                    attrs: {
                                      title: "整改人",
                                      value:
                                        (item.rectifyPeopls &&
                                          item.rectifyPeopls.name) ||
                                        "请选择",
                                      "is-link": "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.showCheckPearson(item, index)
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                ])
              : _c("div", { staticClass: "default-wrapper" }, [
                  _c("p", { staticClass: "default-bg" }),
                  _c("p", { staticClass: "tex" }, [
                    _vm._v(
                      " 当前暂无网络，请确认是否已经提前下载数据，或在有网络后再次记录问题。 "
                    ),
                  ]),
                ]),
          ]),
          _c(
            "div",
            { staticClass: "fixed-buttom", staticStyle: { "z-index": "3000" } },
            [
              _c(
                "div",
                { staticClass: "left-area", on: { click: _vm.showDetail } },
                [
                  _c("span", { staticClass: "total-num" }, [
                    _vm._v(_vm._s(`共${_vm.problemList.length}项`)),
                  ]),
                  _c("span", {
                    staticClass: "icon-arrow",
                    class: { down: _vm.detailShow },
                  }),
                ]
              ),
              _c(
                "div",
                { staticClass: "bottom-btn", on: { click: _vm.confirm } },
                [_vm._v("确定")]
              ),
            ]
          ),
          _c(
            "van-popup",
            {
              staticClass: "point-detail",
              attrs: { position: "bottom" },
              model: {
                value: _vm.detailShow,
                callback: function ($$v) {
                  _vm.detailShow = $$v
                },
                expression: "detailShow",
              },
            },
            [
              _c("div", { staticClass: "popup-title" }, [
                _c("span", [
                  _vm._v(_vm._s(`已选择${_vm.problemList.length}个问题项`)),
                ]),
                _c(
                  "span",
                  { staticClass: "clear", on: { click: _vm.clearList } },
                  [_vm._v("清空")]
                ),
              ]),
              _c(
                "div",
                { staticClass: "list-scroll" },
                [
                  _vm._l(_vm.problemList, function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "popup-list" },
                      [
                        _c("div", { staticClass: "items-info" }, [
                          _c("div", { staticClass: "problem-title" }, [
                            _vm._v(
                              _vm._s(item.parentName) +
                                "/" +
                                _vm._s(item.problemName)
                            ),
                          ]),
                          _c(
                            "div",
                            { staticClass: "items-sort" },
                            _vm._l(item.questLabel, function (item2, index2) {
                              return _c(
                                "span",
                                { key: index2, staticClass: "items-name" },
                                [
                                  index2 !== 0
                                    ? _c("i", [_vm._v(" | ")])
                                    : _vm._e(),
                                  _vm._v(_vm._s(item2) + " "),
                                ]
                              )
                            }),
                            0
                          ),
                        ]),
                        _c("div", { staticClass: "items-oprate" }, [
                          _c("span", {
                            staticClass: "delete-icon",
                            on: {
                              click: function ($event) {
                                return _vm.deleteItems(index, item)
                              },
                            },
                          }),
                        ]),
                      ]
                    )
                  }),
                  _vm.problemList.length === 0
                    ? _c("div", { staticClass: "no-data" }, [
                        _vm._v("暂无数据"),
                      ])
                    : _vm._e(),
                ],
                2
              ),
              _c("div", { staticClass: "popup-mat" }),
            ]
          ),
          _c("OfflineSelectPerson", {
            attrs: {
              listData: _vm.selectPearsonList,
              show: _vm.selectPearsonShow,
              value: _vm.selectPearsonItem,
            },
            on: {
              "update:show": function ($event) {
                _vm.selectPearsonShow = $event
              },
              handleSelect: _vm.handlePerson,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }