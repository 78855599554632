<template>
  <div class="OrderCheckDetail">
    <my-nav title="审核详情"></my-nav>
    <van-tabs
      animated
      color="#3366FE"
      sticky
      swipeable
      offset-top="44"
      class="OrderCheckDetailTabs"
      title-active-color="#3366FE"
      title-inactive-color="#7C7F8A"
      line-height="2px"
      line-width="90px"
      v-model="active"
    >
      <van-tab title="审核详情">
        <div class="ordercheck-iframe bg-red px-16px">
          <iframe src="https://tw02.dongyuanwuye.com/MobilePropertyNew/Web/OaAuditPages/Incident/Incident_Transmit_DY.html?IID=051e7a5c191b498fa4eae63e7753b0b5&IncidentID=100093000000000198&CommID=100093&CorpID=1000&LoginCode=601781&Url=http://crm.dongyuanwuye.com/HM/M_Main/HC/Service.ashx" frameborder="0"></iframe>
          <!-- <iframe src="https://ant.dongyuanwuye.com/login" width="100%" height="400px" frameborder="0"></iframe> -->
          <!-- <iframe src="http://www.baidu.com" frameborder="0"></iframe> -->
        </div>
      </van-tab>
      <van-tab title="工单详情">
        <div class="scroll-detail-wrapper bg-white px-16px">
          <div class="order-cell-title">
            <div class="flex-box">
              <div class="order-number">{{ orderDetail.order_no }}</div>
              <van-tag
                plain
                type="primary"
                color="#999"
                class="nature-tag ml-4px"
                >协助</van-tag
              >
            </div>
            <div class="status color-blue">{{ orderDetail.node_name }}</div>
          </div>
          <div class="order-body-title">报事信息</div>
          <van-row class="order-body-cell" gutter="10">
            <van-col :span="6" class="sub-key">工单属性:</van-col>
            <van-col :span="18" class="order-sub-flex">
              <!-- 工单类型Tag -->
              <van-tag
                type="success"
                :color="'#45A2F4'"
                size="medium"
                class="mr-8"
              >
                {{ orderDetail.order_type_name }}
              </van-tag>
              <!-- 工单性质Tag -->
              <van-tag
                :style="`background:${orderNatureConf.tagBacColor}`"
                plain
                type="primary"
                size="medium"
                class="mr-8"
                :color="orderNatureConf.tagColor"
              >
                {{ orderDetail.order_nature_name }}</van-tag
              >
            </van-col>
          </van-row>
          <van-row class="order-body-cell" gutter="10">
            <van-col :span="6" class="sub-key">所在项目:</van-col>
            <van-col :span="18" class="sub-value">
              <span>重庆D7</span>
            </van-col>
          </van-row>
          <van-row class="order-body-cell" gutter="10">
            <van-col :span="6" class="sub-key">报事来源:</van-col>
            <van-col :span="18" class="sub-value">
              <span>重庆D7</span>
            </van-col>
          </van-row>
          <van-row class="order-body-cell" gutter="10">
            <van-col :span="6" class="sub-key">报事责任:</van-col>
            <van-col :span="18" class="sub-value">
              <span>重庆D7</span>
            </van-col>
          </van-row>
          <van-row class="order-body-cell" gutter="10">
            <van-col :span="6" class="sub-key">所在项目:</van-col>
            <van-col :span="18" class="sub-value">
              <span>重庆D7</span>
            </van-col>
          </van-row>
          <van-row class="order-body-cell" gutter="10">
            <van-col :span="6" class="sub-key">报事渠道:</van-col>
            <van-col :span="18" class="sub-value">
              <span>重庆D7</span>
            </van-col>
          </van-row>
          <van-row class="order-body-cell" gutter="10">
            <van-col :span="6" class="sub-key">报事方式:</van-col>
            <van-col :span="18" class="sub-value">
              <span>重庆D7</span>
            </van-col>
          </van-row>
          <van-row class="order-body-cell" gutter="10">
            <van-col :span="6" class="sub-key">创建人:</van-col>
            <van-col :span="18" class="sub-value">
              <span>重庆D7</span>
              <span class="hpone-icon" v-if="orderDetail.create_phone"></span>
              <span
                class="color-blue"
                v-if="orderDetail.create_phone"
                @click="takePhone(orderDetail.create_phone)"
                >{{ orderDetail.create_phone }}</span
              >
            </van-col>
          </van-row>
          <van-row class="order-body-cell" gutter="10">
            <van-col :span="6" class="sub-key">报事人:</van-col>
            <van-col :span="18" class="sub-value">
              <span>重庆D7</span>
              <span class="hpone-icon" v-if="orderDetail.create_phone"></span>
              <span
                class="color-blue"
                v-if="orderDetail.create_phone"
                @click="takePhone(orderDetail.create_phone)"
                >{{ orderDetail.create_phone }}</span
              >
            </van-col>
          </van-row>
          <van-row class="order-body-cell" gutter="10">
            <van-col :span="6" class="sub-key">客户名称:</van-col>
            <van-col :span="18" class="sub-value">
              <span>重庆D7</span>
              <span class="hpone-icon" v-if="orderDetail.create_phone"></span>
              <span
                class="color-blue"
                v-if="orderDetail.create_phone"
                @click="takePhone(orderDetail.create_phone)"
                >{{ orderDetail.create_phone }}</span
              >
            </van-col>
          </van-row>
          <van-row class="order-body-cell" gutter="10">
            <van-col :span="6" class="sub-key">报事时间:</van-col>
            <van-col :span="18" class="sub-value">
              <span>重庆D7</span>
            </van-col>
          </van-row>
          <van-row class="order-body-cell" gutter="10">
            <van-col :span="6" class="sub-key">报事位置:</van-col>
            <van-col :span="18" class="sub-value">
              <span>重庆D7</span>
            </van-col>
          </van-row>
          <van-row class="order-body-cell" gutter="10">
            <van-col :span="6" class="sub-key">报事类别:</van-col>
            <van-col :span="18" class="sub-value">
              <span>重庆D7</span>
            </van-col>
          </van-row>
          <van-row class="order-body-cell" gutter="10">
            <van-col :span="6" class="sub-key">原因细类:</van-col>
            <van-col :span="18" class="sub-value">
              <span>重庆D7</span>
            </van-col>
          </van-row>
          <van-row class="order-body-cell" gutter="10">
            <van-col :span="6" class="sub-key">预约处理时间:</van-col>
            <van-col :span="18" class="sub-value">
              <span>重庆D7</span>
            </van-col>
          </van-row>
          <van-row class="order-body-cell" gutter="10">
            <van-col :span="6" class="sub-key">关闭类型:</van-col>
            <van-col :span="18" class="sub-value">
              <span>重庆D7</span>
            </van-col>
          </van-row>
          <van-row class="order-body-cell" gutter="10">
            <van-col :span="6" class="sub-key">工单描述:</van-col>
            <van-col :span="18" class="sub-value">
              <span>重庆D7</span>
              <!-- v-if 意为 attachment_list存在且长度大于0 就显示图片 -->
              <div
                v-if="
                  orderDetail.attachment_list &&
                  orderDetail.attachment_list.length > 0
                "
                class="detail_image_list"
              >
                <van-image
                  width="40"
                  height="40"
                  radius="4"
                  class="photo"
                  v-for="(list, index) of orderDetail.attachment_list"
                  @click="imgPreview(index, orderDetail.attachment_list)"
                  :key="index"
                  :src="list.url"
                />
              </div>
            </van-col>
          </van-row>
        </div>
      </van-tab>
      <van-tab title="跟进进程">
        <div class="scroll-detail-wrapper bg-white">
          <van-steps
            direction="vertical"
            active-color="#969799"
            class="stpe-component"
          >
            <van-step
              class="step"
              v-for="(item, index) of logList"
              :key="index"
            >
              <div class="step-cell">
                <template v-if="item.currNodeId !== item.preNodeId">
                  <span class="color-blue">{{ item.preNodeName }}</span>
                  <em v-if="item.preNodeName" class="icon-arrow-blue"></em>
                  <span
                    class="color-blue"
                    :class="{ 'ml-24': item.preNodeName }"
                    >{{ item.curNodeName }}</span
                  >
                </template>
                <template v-else>
                  <span class="color-blue">{{ item.curNodeName }}</span>
                </template>
                <span class="color-gray"
                  >「{{ item.handlerName
                  }}<span v-if="item.handleMobile"
                    >({{ item.handleMobile }})</span
                  >」</span
                >
              </div>
              <div v-if="item.cuiban" style="margin: 4px 0">
                <van-tag
                  plain
                  color="#FD8D37"
                  size="medium"
                  style="background: rgba(253, 141, 55, 0.1)"
                >
                  <span class="font-bold">催办</span>
                </van-tag>
              </div>
              <div class="step-cell time color-gray">
                {{ item.createTime }}
              </div>
              <div class="step-cell color-black">
                <template v-if="!item.create">
                  {{ item.device
                  }}<span v-if="item.device !== 'APP'">端</span>处理：
                </template>
                {{ item.content }}
              </div>
              <div class="order-photos">
                <van-image
                  width="40"
                  height="40"
                  radius="4"
                  class="photo"
                  v-for="(list, index) of item.imgList"
                  @click="imgPreview(index, item.imgList)"
                  :key="index"
                  :src="list.url"
                />
              </div>
              <div class="file-list">
                <div
                  class="list"
                  v-for="(file, index) of item.fileList"
                  :key="file.id + index"
                >
                  <i class="file-icon"></i>
                  <span class="file-name">{{ file.name }}</span>
                </div>
              </div>
            </van-step>
          </van-steps>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { ImagePreview } from 'vant'
import { mapGetters } from 'vuex'
import mobile from '@/utils/mobile'
export default {
  data () {
    return {
      active: 0,
      detail: {
        order_no: 'C2021010500000001',
        node_name: '待审核',
        applyTitle: '标题标题标题标题标题标题标题标题标题标题标题标题标题',
        imgList: [],
        fileList: []
      },
      checkTypes: [
        { name: '延期审核', code: '1', checked: true },
        { name: '协助审核', code: '2' },
        { name: '转派审核', code: '3' },
        { name: '废弃审核', code: '4' },
        { name: '非正常关闭审核', code: '5' }
      ],
      code: '5',
      form: {
        checkOpinion: '',
        assistingProfession: '',
        assistingProfessionName: '',
        assistingName: '',
        assisting: '',
        reportResponseName: '',
        reportResponse: '',
        reasonTypeName: '',
        reasonType: '',
        majorName: '',
        major: '',
        transferredName: '',
        transferred: ''
      },
      AssistingProfessioColumns: [],
      AssistingProfessioShowPicker: false,
      AssistingColumns: [],
      AssistingShowPicker: false,
      reportResponseColumns: [],
      reportResponseShowPicker: false,
      reasonTypeColumns: [],
      reasonTypeShowPicker: false,
      majorColumns: [],
      transferredColumns: [],
      transferredShowPicker: false,

      logList: [],
      orderDetail: {
        order_type_name: '设施设备巡检',
        order_nature_name: '常规',
        order_nature: 0,
        order_no: 'T20200831220700002',
        node_name: '处理中'
      }
    }
  },
  mounted () {
    const { checkOrder } = this.$route.params
    this.detail = checkOrder
  },
  methods: {
    /* 唤起手机拨号 */
    takePhone (phoneNum) {
      this.stashPhone = phoneNum
      if (mobile.platform === 'IOS') {
        window.location.href = 'tel:' + this.stashPhone
      } else {
        this.phoneShow = true
      }
    },
    /* 图片查看器 */
    imgPreview (index, attachmentList) {
      ImagePreview({
        images: attachmentList.map((i) => i.url),
        startPosition: index
      })
    }
  },
  computed: {
    ...mapGetters(['getOrderTypeConf', 'getOrderNatureDic']),
    checkType () {
      return this.checkTypes.find((item) => item.code === this.code) || {}
    },
    orderNatureConf () {
      return (
        this.getOrderNatureDic.find(
          (item) => item.order_nature === this.orderDetail.order_nature
        ) || {}
      )
    }
  }
}
</script>

<style lang="less" scoped>
@import url("./index.less");
</style>
