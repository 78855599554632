<template>
  <div class="offline-list-wrapper">
    <my-nav title="工单查找结果"></my-nav>
    <!-- list长度大于0 或 在刷新状态 -->
    <van-pull-refresh v-if="list.length > 0 || codeLoading" class="list-wrapper" v-model="codeRefreshing" @refresh="onRefreshCode" :disabled="!isOffline">
      <van-list
        v-model="codeLoading"
        :finished="codeFinished"
        :finished-text="codeRefreshing ? '' : (list.length > 0 ? '- 没有更多数据了 -' : '您没有处理的工单')"
        @load="getList"
        :offset="50"
        :immediate-check="false"
      >
      <CardList :list="list" :qr-code="qrCode[0]"></CardList>
      </van-list>
    </van-pull-refresh>
    <no-data v-else tex="暂无任务">
      <p class="tex desc" v-if="!isOffline">
        当前暂无网络，请确认是否已经提前下载数据，或在有网络后再次确认是否无任务。
      </p>
    </no-data>
  </div>
</template>

<script type='text/ecmascript-6'>
import CardList from '@/components/CardList'
import OfflinePluginMixin from '@/plugins/offlineMixin'
import { getCodeListOffline, getCodeListOfflineByVoice } from '@/plugins/offlineMixin/offlineApiHandlers/CodeOrderListOfflineApiHandlers'
import { mapGetters } from 'vuex'
import moment from 'moment'
export default {
  name: 'CodeOrderList',
  components: { CardList },
  mixins: [OfflinePluginMixin],
  data () {
    return {
      list: [],
      offlineShow: false,
      codeLoading: true,
      codeFinished: false,
      codeRefreshing: false,
      codeCurrentPage: 1,
      firstGet: false,
      codes: [],
      qrCode: [],
      voiceTxt: '',
      isNeedOnlyOneOrderToDetail: true // 当工单只有一个时直接跳转工单详情
    }
  },
  computed: {
    ...mapGetters(['getOrderTypeConf']),
    isPK () {
      return this.$store.getters.getIsPK
    },
    isZG () {
      return this.$store.getters.getIsZG
    },
    isOffline () {
      return this.$store.getters.getIsOffline
    },
    pageType () {
      return this.$store.getters.getPageType
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      // 如果是工单详情返回，isNeedOnlyOneOrderToDetail设为false
      if (from.name === 'OrderDetail') {
        vm.isNeedOnlyOneOrderToDetail = false
      }
    })
  },
  mounted () {
    console.log('isNeedOnlyOneOrderToDetail', this.isNeedOnlyOneOrderToDetail)
    const { codes = '', qrCode = '', voiceTxt = '' } = this.$route.query
    if (codes) this.codes = codes.split(',')
    if (qrCode) this.qrCode = qrCode.split(',')
    this.voiceTxt = voiceTxt
    console.log('this.codes: ', this.codes)
    console.log('this.qrCode: ', this.qrCode)
    console.log('this.voiceTxt: ', this.voiceTxt)
    this.getList()
  },
  methods: {
    initPage () {
      console.log('initPage: ')
      this.list = []
      this.codeCurrentPage = 1
      this.getList({ isNetStatusChange: true })
    },
    async getList (params = {}) {
      const { isNetStatusChange = false } = params
      console.log('是否是由于网络变化执行的 isNetStatusChange: ', isNetStatusChange)
      this.codeLoading = true
      let api = ''
      let paramsApi = ''
      let offlineApiHandlers = ''

      // 分扫码 和 语音进到此页面
      if (this.codes.length > 0 || this.qrCode.length > 0) {
        api = this.$api.getCodeList
        paramsApi = { codes: this.codes, qrCode: this.qrCode, limit: 5, page: this.codeCurrentPage, pageType: this.pageType }
        offlineApiHandlers = getCodeListOffline
      } else if (this.voiceTxt) {
        api = this.$api.getCodeListByVoice
        paramsApi = { voiceContent: this.voiceTxt, limit: 5, page: this.codeCurrentPage, pageType: this.pageType }
        offlineApiHandlers = getCodeListOfflineByVoice
      }

      try {
        const res = await this.$apiOffline({
          methods: 'post',
          api,
          params: paramsApi,
          offlineApiHandlers,
          showLoading: false
        })
        console.log('res.records: ', res.records)
        this.list = this.list.concat(res.records)
        this.handleProcessLimit(this.list)
        // 直接跳转到详情页面的
        const orderNum = this.list.reduce((num, item) => {
          if (item.id) {
            num++
          } else {
            num += item.orders.length
          }
          return num
        }, 0)
        if (!isNetStatusChange && this.isNeedOnlyOneOrderToDetail && orderNum === 1) {
          this.onlyOneOrderToOrderDetail()
        }
        // 结束标志  注：离线只触发一次加载
        if (!res.records || res.records.length === 0 || !this.isOffline) {
          this.codeFinished = true
          return
        }
        this.codeCurrentPage++
      } catch (error) {
        this.codeFinished = true
      } finally {
        this.codeRefreshing = false
        this.codeLoading = false
      }
    },
    handleProcessLimit (list) {
      try {
        list.forEach(record => {
          /* 意为 普通工单 && 不是已完成和已完结 && planEndTime存在 */
          if (this.getOrderTypeConf.normalOrderTypes.includes(record.orderType) && !(record.templateNodeCode === 'yiwancheng' || record.templateNodeCode === 'yiwanjie') && record.planEndTime) {
            const m1 = moment(record.planEndTime) // 计划结束时间
            const m2 = moment() // 现在的时间
            var duration = moment.duration(m1.diff(m2)) // 现在-计划结束时间
            var minutes = duration.asMinutes()
            if (minutes >= 0) {
              console.log('剩余', minutes)
              record.remainingTime = `「剩余${this.$moment.formatMinutes(Math.floor(minutes))}」`
            } else {
              console.log('超期', minutes)
              record.overdueTime = `「超期${this.$moment.formatMinutes(Math.floor(minutes * -1))}」`
            }
          }
        })
      } catch (error) {
        console.error('error: ', error)
      }
    },
    onlyOneOrderToOrderDetail () {
      console.log('this.list: ', this.list)
      let orderId
      // 标准下的单
      if (this.list[0].orders?.length === 1) {
        console.log('onlyOneOrderToOrderDetail 执行goDetail 1: ')
        orderId = this.list[0].orders[0].id
      }
      // 不是标准下的单
      if (!this.list[0].orders || this.list[0].orders.length === 0) {
        console.log('onlyOneOrderToOrderDetail 执行goDetail 2: ')
        orderId = this.list[0].id
      }
      console.log('orderId: ', orderId)
      /* 如果是只有一个工单，需要带上扫的二维码 */
      this.$router.push({
        path: '/OrderDetail', query: { orderId, qrCode: this.qrCode[0] }
      })
    },
    onRefreshCode () {
      console.log('onRefreshCode!!!@@##')
      this.codeCurrentPage = 1
      this.codeFinished = false
      this.isNeedOnlyOneOrderToDetail = false
      this.list = []
      this.getList({
        refresh: true
      })
    }
  }
}
</script>
<style lang='less' scoped>
@import url('./index.less');
</style>
