<template>
    <div class="no-auth">
        <my-nav :title="'远程监控'" :self-back="true" @back="toBack"></my-nav>
        <div class="content">
            <div class="img-content"></div>
            <span>暂无该板块权限</span>
        </div>
    </div>
</template>

<script>
import mobile from '@/utils/mobile'
export default {
  name: 'noAuth',
  data () {
    return {
      noAuthImg: require('@/asset/no-auth.png')
    }
  },
  methods: {
    // 首页回页面
    toBack () {
      mobile.backWeb()
    }
  }
}
</script>

<style lang="less" scoped>
.no-auth{
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &>.content{
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 100px;
        font-size: 20px;
        color: #bbbbbb;
        &>.img-content{
            width: 200px;
            height: 220px;
            background-image: url('../../asset/no-auth.png');
            background-size: contain;
            margin-bottom: 30px;
        }
    }
}
</style>
