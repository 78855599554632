var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "vant-table" }, [
    _c(
      "table",
      {
        staticClass: "table",
        staticStyle: { width: "100%" },
        style: _vm.styleObject,
        attrs: { cellspacing: "0", border: "0" },
      },
      [
        _c(
          "tr",
          _vm._l(_vm.columns, function (item, index) {
            return _c(
              "th",
              {
                key: index,
                staticClass: "th",
                class: { lastCol: index === _vm.columns.length - 1 },
              },
              [_vm._v(" " + _vm._s(item.label) + " ")]
            )
          }),
          0
        ),
        _vm._l(_vm.tableData, function (item, index) {
          return _c(
            "tr",
            {
              key: index,
              staticClass: "list-tr",
              class: { lastrow: index === _vm.tableData.length - 1 },
            },
            _vm._l(_vm.columns, function (context, i) {
              return _c(
                "td",
                { key: i, class: { lastCol: i === _vm.columns.length - 1 } },
                [_vm._v(" " + _vm._s(item[context.tableDataprop]) + " ")]
              )
            }),
            0
          )
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }