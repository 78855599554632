<template v-cloak>
  <van-collapse v-model="activeNames" accordion @change="handleChangeCollapse">
    <div class="standard-list-wrapper block">
      <template v-for="(item, index) in list">
        <!-- 单个显示的工单   判断条件 id存在-->
        <problem-order-card ref="container" :order="item" v-if="item.id" :key="index" @click="handleClick"></problem-order-card>
        <!-- 标准下的工单 判断条件 v-else -->
        <div v-else ref="container" class="block standard-list relative" :key="index + 'R'">
          <van-collapse-item :name="index">
            <template #title>
              <van-sticky :container="container[index]" :offset-top="activeNames === index ? stickyTop : 0" :z-index="activeNames === index ? 1 : -990">
                <div class="title-wrappper order-item-cal">
                  <div class="standrad-head" @click.stop="()=>{}">
                    <div class="block absolute right-20px top-66px">
                      <img v-if="isFM" :src="calcCardImg(item)" width="32" alt="" />
                    </div>
                    <div class="block standard-list-title">
                      <p class="name">{{ item.standardName }}</p>
                      <div class="block title-fast">
                        <span class="fast-list">{{ item.handleType }}</span>
                        <span class="fast-list">{{ item.inOrder }}</span>
                      </div>
                    </div>
                    <div class="block info-list">
                      <span class="info-label">任务属性:</span>
                      <span class="info-val">{{ item.attributeName }}</span>
                    </div>
                    <div class="block info-list">
                      <span class="info-label">任务分类:</span>
                      <span class="info-val">{{ item.classifyName }}</span>
                    </div>
                    <div class="block info-list">
                      <span class="info-label">所在项目:</span>
                      <span class="info-val">{{ item.projectName }}</span>
                    </div>
                  </div>
                  <div class="block bg-white count-wrapper" @click.capture="()=>{}">
                    <div class="count-wrapper-left">
                      <div class="count-list">
                        <span class="count-label">任务数量:</span>
                        <span class="count">{{ item.taskNum }}</span>
                      </div>
                      <div class="count-list">
                        <span class="count-label">即将到期:</span>
                        <span class="count mature">{{ item.matureNum }}</span>
                      </div>
                    </div>
                    <van-icon name="arrow-down" class="count-wrapper-arrow" :class="{'expand': index === activeNames}"/>
                  </div>
                </div>

              </van-sticky>
            </template>

            <span
              class="block order-list-wrapper"
              v-for="(orderItem, orderIndex) in ordersSliceComp(item)"
              :key="orderIndex"
              @click="handleClick(orderItem)"
            >
              <span class="block order-list-title">
                <div class="title-info">
                  <p class="num">{{ orderItem.orderNo }}</p>
                  <p
                    class="copy"
                    @click.prevent.stop="copyOrderNumber(orderItem.orderNo)"
                  >
                    复制
                  </p>
                  <span v-if="isCJ && orderItem.isExpiredName" style="margin-left:8px"><van-tag type="warning">{{orderItem.isExpiredName}}</van-tag> </span>
                </div>
                <p class="status">{{ orderItem.templateNodeName }}</p>
              </span>
              <span class="block info-list order-list">
                <span class="info-label">处理人:</span>
                <span class="info-val">{{ orderItem.handlerName }}</span>
              </span>
              <span class="block info-list">
                <span class="info-label">计划开始时间:</span>
                <span class="info-val">{{ orderItem.planStart }}</span>
              </span>
              <span class="block info-list">
                <span class="info-label">计划结束时间:</span>
                <span class="info-val">{{ orderItem.planEnd }}</span>
              </span>
              <span class="block info-list" v-if="orderItem.taskAddr">
                <span class="info-label">任务位置:</span>
                <span class="info-val">{{ orderItem.taskAddr }}</span>
              </span>
            </span>
            <template v-if="item.expandLevel">
              <span
                class="show_more"
                @click="expandMore(index)"
                v-if="item.expandLevel * expandCount < item.orders.length"
              >
                <span>点击加载更多</span>
                <span class="iconfont icon-arrow-down"></span>
              </span>
              <span class="show_more" v-else>
                <span>没有更多数据了</span>
              </span>
            </template>
          </van-collapse-item>
        </div>
      </template>
    </div>
  </van-collapse>
</template>

<script type="text/ecmascript-6">
import problemOrderCard from './problemOrderCard.vue'
// import mobile from '@/utils/mobile'

export default {
  name: 'CardList',
  components: { problemOrderCard },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    qrCode: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      activeNames: -1,
      expandCount: 10,
      navBarHeight: 0,
      calendarInfoHeight: 0,
      stickyTop: 0,
      projectEleSelectHeight: 0,
      container: [],
      workorderWrapperHeight: 0,
      dashboardEle: null,
      calendarWrapperHeight: 0
    }
  },
  computed: {
    isFM () {
      return this.$store.getters.getIsFM
    },
    isPK () {
      return this.$store.getters.getIsPK
    },
    isZG () {
      return this.$store.getters.getIsZG
    },
    isCJ () {
      return this.$store.getters.getIsCJ
    },
    isOffline () {
      return this.$store.getters.getIsOffline
    },
    ordersSliceComp () {
      return function (record) {
        return record.expandLevel ? record.orders.slice(0, record.expandLevel * this.expandCount) : record.orders
      }
    }

  },
  mounted () {
    this.calStickyTopOffset()
  },
  methods: {
    handleChangeCollapse (activeNames) {
      if (!activeNames && activeNames !== 0) return
      console.log('activeNames: ', activeNames)
      // 计算应该滚动的高度
      setTimeout(() => {
        const ordersAll = document.querySelectorAll('.order-item-cal')
        let scrollTop = 0 // 初始高度
        if (this.isPK) { // FM项目选择 + 日历 - 日历底部信息 + 间距30
          scrollTop += this.projectEleSelectHeight + this.calendarWrapperHeight - this.calendarInfoHeight + 30
        }
        if (this.isFM || this.isCJ) { // FM项目选择 + 间距20 + 当前这个item的margin top 16
          scrollTop += this.projectEleSelectHeight + 36
          // if (mobile.getPlatform() === 'IOS') scrollTop += 20
        }
        console.log('scrollTop 初始: ', scrollTop)
        for (let index = 0; index < ordersAll.length; index++) {
          const element = ordersAll[index]
          if (index < activeNames) {
            console.log('element.clientHeight: ', element.clientHeight)
            scrollTop += element.clientHeight
            console.log('scrollTop: ', scrollTop)
            scrollTop = scrollTop + 16 // margin值
          }
        }
        this.dashboardEle.scrollTo(0, scrollTop)
        console.log('scrollTop: ', scrollTop)
      }, 500)
    },
    expandMore (index) {
      this.$emit('expandMore', index)
    },
    resetContanierRef () {
      const _this = this
      this.$nextTick(() => {
        setTimeout(() => {
          _this.container = _this.$refs.container
        }, 1000)
      })
    },
    calStickyTopOffset () {
      const _this = this
      this.$nextTick(() => {
        setTimeout(() => {
          // set
          _this.dashboardEle = document.querySelector('.dashboard')
          console.log('_this.dashboardEle: ', _this.dashboardEle)
          _this.navBarHeight = document.querySelector('.van-nav-bar')?.offsetHeight // 头部高度
          console.log('_this.navBarHeight顶部标题高度: ', _this.navBarHeight)
          _this.projectEleSelectHeight = document.querySelector('.select-project-input-wrapper')?.offsetHeight // 项目选择高度
          console.log('projectEleSelectHeight项目选择高度: ', _this.projectEleSelectHeight)
          _this.calendarWrapperHeight = document.querySelector('.calendar-wrapper')?.offsetHeight // 日历整体高度
          console.log('calendarWrapperHeight日历整体高度: ', _this.calendarWrapperHeight)
          _this.calendarInfoHeight = document.querySelector('.calendar-info')?.offsetHeight // 日历信息高度
          console.log('_this.calendarInfoHeight: ', _this.calendarInfoHeight)
          _this.vanTabsHeight = document.querySelector('.van-tabs__wrap')?.offsetHeight // tabs高度
          console.log(' _this.vanTabsHeight: ', _this.vanTabsHeight)

          _this.workorderWrapperHeight = document.querySelector('.workorder_wrapper')?.offsetHeight // 工单类型高度
          console.log('_this.workorderWrapperHeight工单类型高度: ', _this.workorderWrapperHeight)
          if (_this.isPK) {
            _this.stickyTop = _this.navBarHeight + _this.calendarInfoHeight + _this.vanTabsHeight - 1
          }
          if (_this.isFM || _this.isCJ) {
            _this.stickyTop = _this.navBarHeight + _this.workorderWrapperHeight + _this.vanTabsHeight - 1
          }
          console.log('stickyTop: ', _this.stickyTop)
        }, 1500)
      })
    },
    calcCardImg (item) { // 判断返回每张卡片上的图
      const orderType = item.orderType || ''
      // ordernature==4 显示升级的图片
      if (item.orderNature === 4) return require('@/asset/orderIcon/icon_task-upgrade.png')
      switch (orderType) {
        case 8: // 公区-巡检 蓝色图标
          return require('@/asset/orderIcon/icon_xunjian task.png')
        case 10: // 公区-保养  绿色图标
          return require('@/asset/orderIcon/icon_baoyang task.png')
        // case 9: // 升级
        //   return require('@/asset/orderIcon/icon_task-upgrade.png')
        case 3: // 设备工单
        case 6:
          return require('@/asset/orderIcon/icon_task-problem.png')
          // return require('@/asset/orderIcon/icon_gongqu task.png')
        case 1: // 公区工单
        case 4:
          return require('@/asset/orderIcon/icon_gongqu task.png')
        default:
          return ''
      }
    },
    /**
     * 点击
     */
    async handleClick (item) {
      console.log('handleClick item: ', item)
      const query = { orderId: item.id, orderNo: item.orderNo }
      // 如果是工单查找结果页
      if (this.$route.name === 'CodeOrderList') {
        query.isFromScan = this.$route.query.isFromScan
      }
      if (this.qrCode) {
        query.qrCode = this.qrCode
      }
      this.$router.push({
        path: '/OrderDetail', query
      })
    },
    /* 复制订单号 */
    copyOrderNumber (orderNo) {
      this.$copyText(orderNo).then(
        (e) => {
          this.$notify({
            type: 'success',
            message: '复制成功',
            background: '#13CE66'
          })
        },
        (e) => {
          this.$notify({
            type: 'warning',
            message: '复制成功',
            background: '#FFC82C'
          })
        }
      )
    },
    copyTNum (num) {
      this.$copyText(num).then(
        (e) => {
          this.$notify({ type: 'success', message: '复制成功', background: '#13CE66' })
        },
        (e) => {
          this.$notify({ type: 'warning', message: '复制成功', background: '#FFC82C' })
        }
      )
    }
  }
}
</script>
<style lang="less" scoped>
.copy_btn {
  padding-top: 2px;
  border: 1px solid #c5c5c5;
  @apply inline-flex items-center justify-center px-4px text-[#c5c5c5] text-13px;
}
.standard-list-wrapper {
  padding: 0px 6px 6px;
  ::v-deep .van-cell{
    position: relative;
    padding: 0px;
    .van-cell__right-icon{
      // display: none;
      position: absolute;
      right: 10px;
      bottom: 10px;
      z-index: 1;
      display: none;
    }
  }
  .title-wrappper{
    background-color: #ffffff;
    padding: 10px;
  }
  .info-list {
    display: flex;
    height: 28px;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    color: #8b8f9a;
    .info-label {
      flex-shrink: 0;
    }
    .info-val {
      margin-left: 16px;
      color: #222222;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  p {
    margin: 0;
  }
  .standard-list {
    border-radius: 8px;
    background-color: #fff;
    overflow: hidden;
    // padding: 12px 10px 6px;
    line-height: 26px;
    margin-top: 16px;
    .standrad-head{
      background-color: #ffffff;
      // padding: 10px 20px;
      // width: 100%;
      padding-bottom: 8px;
    }
    .standard-list-title {
      height: 26px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 6px;
      .name {
        font-size: 18px;
        font-weight: 601;
        color: #222222;
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-right: 8px;
      }
      .title-fast {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        height: 26px;
        max-width: 86px;
        .fast-list {
          padding: 0 6px;
          height: 20px;
          background: rgba(51, 102, 254, 0.1);
          border-radius: 2px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 11px;
          font-weight: 500;
          color: #3366fe;
          white-space: nowrap;
          & + .fast-list {
            margin-left: 8px;
          }
        }
      }
    }

    /deep/ .van-collapse-item {
      // padding: 0;
      // margin-top: 6px;
      // border-top: 1px solid #f5f5f5;
      // .van-cell {
      //   padding: 0;
      //   height: 32px;
      //   align-items: center;
      //   position: relative;
      //   top: 6px;
      // }
      .van-cell--clickable:active{
        background-color: #ffffff
      }
      .count-wrapper {
        border-top: 1px solid #f5f5f5;
        padding-top: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .count-wrapper-left{
          display: flex;
          flex-wrap: nowrap;
        }
        .count-wrapper-arrow{
          transition: all 0.3s linear;
          &.expand{
            transform: rotate(180deg);
          }
        }
        &:active{
          background-color: #f2f3f5;
        }
        .count-list {
          display: flex;
          & + .count-list {
            margin-left: 23px;
          }
          .count-label {
            font-size: 16px;
            font-weight: 400;
            color: #8b8f9a;
          }
          .count {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background-color: #28c469;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            margin-left: 16px;
            &.mature {
              background-color: #f44a45;
            }
          }
        }
      }
      .van-collapse-item__content {
        padding: 0px 8px !important;
        .show_more {
          margin-top: 8px;
          width: 100%;
          background-color: #fff;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
        .order-list-wrapper {
          background: #f2f2f2;
          border-radius: 6px;
          padding: 14px 10px 6px;
          margin-bottom: 8px;
          .order-list-title {
            display: flex;
            justify-content: space-between;
            height: 20px;
            align-items: center;
            margin-bottom: 6px;
            .title-info {
              display: flex;
              align-items: center;
              flex-shrink: 0;
              height: 20px;
              .num {
                color: #222;
                font-weight: 500;
              }
              .copy {
                width: 34px;
                height: 18px;
                box-sizing: border-box;
                display: flex;
                justify-content: center;
                padding-top: 1px;
                align-items: center;
                border-radius: 2px;
                border: 1px solid #c5c5c5;
                font-size: 13px;
                color: #999999;
                margin-left: 12px;
                flex-shrink: 0;
              }
            }
            .status {
              margin-right: 6px;
              font-size: 14px;
              font-weight: 400;
              color: #3366fe;
            }
          }
          .info-list {
            height: 32px;
          }
        }
      }
    }
  }
}
::v-deep .van-sticky--fixed{
  padding: 0px 6px;
  // &>.title-wrappper{
  //   width: 97%;
  //   margin: 0 auto;
  // }

}
</style>
