<template v-cloak>
  <div class="calendar-wrapper">
    <div class="calendar-week">
      <p class="week-list" v-for="item in weekList" :key="item">{{ item }}</p>
    </div>
    <div class="day-scroll" ref="scrollWrapper"
      :style="{ 'overflow-y': offline ? 'scroll' : 'hidden' }">
      <div class="day-wrapper" ref="dayWrapper" @click.prevent="changeActive" @touchend.stop="touchEnd" @touchstart="touchStart">
        <div class="day-list" v-for="item in dateList" :key="item.days" :data-index="item.days"
          :class="{ active: item.days === info.checkDate }">
          <div class="list-content">
            <p class="day" :data-index="item.days">{{ item.day }}</p>
            <p class="desc" :data-index="item.days">
              {{ item.day === 1 ? `${parseInt(item.month)}月` : item.lunar }}
            </p>
          </div>
          <i class="day-count" :class="{ active: (isOffline && item.status === 2) }" :data-index="item.days"></i>
        </div>
      </div>
    </div>
    <van-sticky :offset-top="navBarHeight - 1">
      <div class="calendar-info bg-white">
        <div class="info">
          <p class="time">{{ info.checkDate }}</p>
          <p class="week">周{{ activeWeek }}</p>
          <i class="day" :class="isToday()"></i>
        </div>
        <div class="info-btn">
          <p class="btn tab" :class="{ active: info.myHandle === 1||info.myHandle === 2 }">
            <span :class="{ not_active: info.myHandle !== 1 }" @click="changeMyHandle(1)">我处理</span>
            |
            <span :class="{ not_active: info.myHandle !== 2 }" @click="changeMyHandle(2)">同岗位</span>
          </p>
          <p class="btn err" :class="{ active: info.nowFlag === 1 }" @click="changeNowFlag">
            当前时段
          </p>
        </div>
      </div>
    </van-sticky>
  </div>
</template>

<script>
import solarLunar from 'solarlunar'
import moment from 'moment'
export default {
  name: 'Calendar',
  components: {},
  props: {
    searchParams: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      dateList: [],
      weekList: ['一', '二', '三', '四', '五', '六', '日'],
      activeWeek: '',
      preIndex: 0,
      scrollTop: 0,
      nextIndex: 0,
      timeInterVal: null,
      oldStartDays: '',
      oldStartDate: '',
      oldDateLength: 0,
      initClick: true,
      offline: true,
      navBarHeight: 0
    }
  },
  computed: {
    isOffline () {
      return this.$store.getters.getIsOffline
    },
    info: {
      get () {
        return this.searchParams
      },
      set (info) {
        console.log('set info: ', info)
        this.$emit('update:searchParams', info)
      }
    }
  },
  watch: {
    'searchParams.projectCodes': { // 当首页项目变化时，重新请求日历状态
      handler: function () {
        this.isOffline && this._resetGetWeekStatus()
      },
      deep: true
    }
  },
  created () {},
  mounted () {
    console.log('日历mounted: ')
    console.log('this.searchParams: ', this.lodash.cloneDeep(this.info))
    this._initCalendar()
    this.$nextTick(() => {
      this.navBarHeight = document.querySelector('.van-nav-bar').offsetHeight
    })
  },
  activated () {
    this.setLastScrollTop()
  },
  methods: {
    // 初始化日历状态
    async _resetGetWeekStatus () {
      console.log('_resetGetWeekStatus')
      let res = await this.$http.post(this.$api.activeList, {
        startDate: this.dateList[0].days,
        endDate: this.dateList[this.dateList.length - 1].days,
        projectCodes: this.info.projectCodes
      }, false)
      this.dateList.forEach((item, index) => {
        this.$set(item, 'status', res.length === 0 ? undefined : res[index].status)
      })
    },
    changeMyHandle (value) {
      if (value === this.info.myHandle) {
        this.info.myHandle = 0
      } else {
        this.info.myHandle = value
      }
      this.$parent.getInitList()
    },
    changeNowFlag () {
      this.info.nowFlag = this.info.nowFlag === 1 ? 0 : 1
      this.$parent.getInitList()
    },
    // 根据滑动来设置位置
    _setDay (val, str) {
      console.log('_setDay: ')
      let {
        forStart,
        forLength,
        startMonth,
        endMonth,
        endYear,
        startYear
      } = this._getDayArr(val)
      let data = []
      for (let i = forStart; i <= forLength; i++) {
        // 获取周的开始时间和当前周开始时间所对应的月的天数
        let startMonthDay = moment(
          `${startYear}-${startMonth}`,
          'YYYY-MM'
        ).daysInMonth()
        let day
        let days
        let month
        let year = startYear
        // 如果天数超过了那个月的天数就用当前的值减去当前月的天数然后把月份+1并且当月份为12月的时候就把年设置为endYear
        if (i > startMonthDay) {
          day = i - startMonthDay
          month = endMonth
          if (startMonth === 12) {
            year = endYear
          }
        } else {
          day = i
          month = startMonth
          year = startYear
        }
        // 给天数不住10的自动补0
        if (month < 10) {
          month = `0${month}`
        }
        if (day < 10) {
          days = `${year}-${month}-0${day}`
        } else {
          days = `${year}-${month}-${day}`
        }
        let lunar = this.getLunarDay(year, month, day)
        // 获取周几
        let week = this._setWeeks(moment(days, 'YYYY-MM-DD').weekday())
        if (str === 'pre') {
          data.push({
            day,
            days,
            week,
            month,
            lunar
          })
        } else {
          this.dateList.push({
            day,
            days,
            week,
            month,
            lunar
          })
        }
      }
      if (str === 'pre') {
        this.dateList = [...data, ...this.dateList]
      }
    },
    // 获取周的开始结束时间并处理成可以循环的数据
    _getDayArr (val) {
      let forStart
      let forLength
      let { startDay, startMonth, startYear } = this._getWeekStartDay(val)
      let { endDay, endMonth, endYear } = this._getWeekEndDay(val)
      // 当开始天数>结束天数时那么就跳月了，就把结束天数+开始月的天数
      if (startDay > endDay) {
        forLength =
          endDay +
          moment(
            `${startYear}-${startMonth}-${startDay}`,
            'YYYY-MM-DD'
          ).daysInMonth()
        forStart = startDay
      } else {
        forStart = startDay
        forLength = endDay
      }
      return {
        forStart,
        forLength,
        endMonth,
        endYear,
        startYear,
        startMonth
      }
    },
    async _initCalendar () {
      // 获取本日
      const startDate = moment().format('YYYY-MM-DD')
      // 显示周几
      this.activeWeek = this._setWeeks(moment(startDate, 'YYYY-MM-DD').weekday())
      this._setDay()
      this._setNextWeek()
      this.forNumberPre(2)
    },
    setLastScrollTop () {
      console.log('this.scrollTop: ', this.scrollTop)
      let scrollWrapper = this.$refs.scrollWrapper
      scrollWrapper.scrollTop = this.scrollTop
    },
    saveScrollTop () {
      console.log('saveScrollTop: ')
      let scrollWrapper = this.$refs.scrollWrapper
      console.log('scrollWrapper.scrollTop', scrollWrapper.scrollTop)
      this.scrollTop = scrollWrapper.scrollTop
    },
    // 获取日期的订单状态
    async _getDayStatus () {
      console.log('_getDayStatus: ')
      if (!this.isOffline) {
        return
      }
      let startDate = this.dateList[0].days
      let endDate = this.dateList[this.dateList.length - 1].days
      let dateData = JSON.parse(JSON.stringify(this.dateList))
      if (this.oldStartDate) {
        endDate = moment(
          moment(this.oldStartDate, 'YYYY-MM-DD').valueOf() - 24 * 3600 * 1000
        ).format('YYYY-MM-DD')
        dateData = dateData.splice(0, dateData.length - this.oldDateLength)
      }
      let res = await this.$http.post(this.$api.activeList, {
        startDate,
        endDate,
        projectCodes: this.info.projectCodes
      }, false)
      dateData.forEach((item, index) => {
        this.$set(this.dateList[index], 'status', res.length === 0 ? 0 : res[index].status)
      })
      this.oldStartDate = startDate
      this.oldDateLength = this.dateList.length
    },
    forNumberPre (num) {
      console.log('forNumberPre: ')
      for (let index = 0; index < num; index++) {
        this._setPreWeek()
      }
      this.$nextTick(() => {
        let scrollWrapper = this.$refs.scrollWrapper
        if (scrollWrapper.scrollTop < 10) {
          scrollWrapper.scrollTop = num * 50 + scrollWrapper.scrollTop
        }
        this._getDayStatus()
      })
    },
    // 获取下一个周
    _setNextWeek () {
      console.log('_setNextWeek: ')
      this.nextIndex++
      this._setDay(this.nextIndex)
      this._initTop()
    },
    // 获取上一个周
    _setPreWeek () {
      this.preIndex++
      // this.preIndex = 0
      this._setDay(-this.preIndex, 'pre')
      this._initTop()
    },
    touchStart (e) {
      // clearInterval(this.timeInterVal)
      if (!this.isOffline) {
        this.offline = false
        e.preventDefault()
        this.$EB.$emit(this.$EBT.NO_NETWORK_WARN)
      } else {
        this.offline = true
      }
    },
    touchEnd () {
      let scrollWrapper = this.$refs.scrollWrapper
      if (this.initClick) {
        this.initClick = false
        return
      }
      if (scrollWrapper.scrollTop <= 50) {
        this.forNumberPre(4)
      }
    },
    // 设置距离顶部的距离
    _initTop () {
      this.$nextTick(() => {
        let dayWrapper = this.$refs.dayWrapper
        let scrollWrapper = this.$refs.scrollWrapper
        scrollWrapper.scrollTop = dayWrapper.offsetHeight - scrollWrapper.scrollTop - 108
      })
    },
    // 获取周开始时间
    _getWeekStartDay (val) {
      // 这样是年月日的格式
      let startWeekDate = moment()
        .week(moment().week() + val)
        .startOf('week')
        .format('YYYY-MM-DD')
      // 获取本周
      let weekArr = startWeekDate.split('-')
      let startYear = parseInt(weekArr[0])
      let startMonth = parseInt(weekArr[1])
      let startDay = parseInt(weekArr[2]) + 1
      if (startDay > moment(startWeekDate, 'YYYY-MM-DD').daysInMonth()) {
        startDay = startDay - moment(startWeekDate, 'YYYY-MM-DD').daysInMonth()
        if (startMonth === 12) {
          startYear += 1
          startMonth = 1
        } else {
          startMonth += 1
        }
      }
      return { startDay, startMonth, startYear }
    },
    // 获取周结束时间
    _getWeekEndDay (val) {
      const endWeekDate = moment()
        .week(moment().week() + val)
        .endOf('week')
        .format('YYYY-MM-DD')
      let weekArr = endWeekDate.split('-')
      let endYear = parseInt(weekArr[0])
      let endMonth = parseInt(weekArr[1])
      let endDay = parseInt(weekArr[2]) + 1
      if (endDay > moment(endWeekDate, 'YYYY-MM-DD').daysInMonth()) {
        endDay = endDay - moment(endWeekDate, 'YYYY-MM-DD').daysInMonth()
        if (endMonth === 12) {
          endYear += 1
          endMonth = 1
        } else {
          endMonth += 1
        }
      }
      return {
        endDay,
        endMonth,
        endYear
      }
    },
    // 星期几转为大写
    _setWeeks (val) {
      switch (val) {
        case 1:
          return '一'
        case 2:
          return '二'
        case 3:
          return '三'
        case 4:
          return '四'
        case 5:
          return '五'
        case 6:
          return '六'
        case 0:
          return '日'
      }
    },
    // 判断是否是今天昨天 明天
    isToday () {
      let toDay = moment().format('YYYY-MM-DD')
      if (this.info.checkDate === toDay) {
        return 'jin'
      }
    },
    // 设置高亮天
    async changeActive (e) {
      if (!e.target.dataset.index) return
      if (!this.isOffline) {
        this.$EB.$emit(this.$EBT.NO_NETWORK_WARN)
        return
      }
      this.activeWeek = this._setWeeks(moment(this.info.checkDate, 'YYYY-MM-DD').weekday())
      this.info.checkDate = e.target.dataset.index
      this.$parent.getInitList()
    },
    getLunarDay (solarYear, solarMonth, solarDay) {
      const lunar2solarData = solarLunar.solar2lunar(solarYear, parseInt(solarMonth), solarDay)
      return lunar2solarData.dayCn
    },
    getBit (m, n) {
      // 也是拷贝的，不是很明白这段代码干嘛的，不过很明显是处理二进制数据的
      return 29 + ((m >> n) & 1)
    }
  }
}
</script>
<style  lang='less' scoped>
.calendar-wrapper {
  background-color: #fff;
  padding-top: 10px;
  p {
    margin: 0;
  }
  .calendar-tab {
    position: relative;
    height: 44px;
    box-sizing: border-box;
    border-bottom: 1px solid #f5f5f5;
    .tab-wrapper {
      display: flex;
      padding: 8px 0;
      .btn {
        margin-left: 90px;
        font-size: 16px;
        font-weight: 400;
        color: #8b8f9a;
        line-height: 22px;
        &.active {
          color: #3366fe;
          &.err {
            color: #f44a45;
          }
        }
        & + .btn {
          margin-left: 67px;
        }
      }
    }
    .line {
      width: 20px;
      height: 2px;
      border-radius: 1px;
      transition: all 0.3s;
      position: absolute;
      top: 34px;
      &.line1 {
        background-color: #3366fe;
        transform: translateX(112px);
      }
      &.line2 {
        background-color: #f44a45;
        transform: translateX(243px);
      }
    }
  }
  .calendar-info {
    display: flex;
    height: 48px;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    box-sizing: border-box;
    border-bottom: 1px solid #f5f5f5;
    // margin-bottom: 15px;
    border-top: 1px solid #f5f5f5;
    .info {
      display: flex;
      font-size: 16px;
      font-weight: 400;
      color: #222222;
      line-height: 22px;
      .week {
        margin-left: 6px;
      }
      .day {
        margin-left: 10px;
      }
    }
    .info-btn {
      display: flex;
      .btn {
        width: 60px;
        height: 24px;
        background: #f5f5f5;
        border-radius: 2px;
        font-size: 13px;
        font-weight: 400;
        color: #666666;
        display: flex;
        align-items: center;
        justify-content: center;
        &.active {
          background-color: rgba(51, 102, 254, 0.1);
          color: #3366fe;
        }
        & .not_active {
          color: #666666;
        }
        &.err.active {
          background-color: rgba(244, 74, 69, 0.1);
          color: #f44a45;
        }
        & + .btn {
          margin-left: 14px;
        }
      }
      .tab {
        width: 90px;
      }
    }
    .day {
      width: 24px;
      height: 24px;
      &.jin {
        background: url("../../../asset/today-icon.png") no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .calendar-week {
    display: flex;
    padding: 0 9px;
    margin-left: -17px;
    margin-bottom: 14px;
    .week-list {
      flex: 0 0 36px;
      text-align: center;
      margin-left: 17px;
      font-size: 13px;
      font-weight: 400;
      color: #999999;
      line-height: 18px;
    }
  }
  .day-scroll {
    width: 100%;
    height: 108px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch; /* ios5+ */
    transition: all 0.3s;
  }
  .day-wrapper {
    display: flex;
    flex-wrap: wrap;
    padding: 0 9px;
    margin-left: -17px;
    .day-list {
      flex: 0 0 36px;
      text-align: center;
      margin-left: 17px;
      padding-bottom: 8px;
      height: 54px;
      box-sizing: border-box;
      .list-content {
        overflow: hidden;
        width: 100%;
        height: 36px;
        border-radius: 50%;
        .day {
          font-size: 16px;
          font-weight: 500;
          color: #222222;
          line-height: 1;
          margin-top: 3px;
        }
        .desc {
          font-size: 11px;
          font-weight: 400;
          color: #222222;
          line-height: 1;
          // margin-top: 5px;
        }
      }
      .day-count {
        display: block;
        text-align: center;
        margin: 2px auto 0;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #d8d8d8;
        &.active {
          background: linear-gradient(90deg, #62a9ff 0%, #3370fe 100%);
        }
      }
      &.active {
        .list-content {
          background: linear-gradient(90deg, #62a9ff 0%, #3370fe 100%);
          p {
            color: #fff;
          }
        }
      }
    }
  }
}
</style>
