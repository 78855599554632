<template>
  <div class="processingList">
    <my-nav title="处理中工单"> </my-nav>
    <div class="list-wrapper">
      <div v-for="(item, index) in list" :key="index" class="list-content">
        <problem-order-card
          :order="item"
          @click="routerToOrderDetail"
        ></problem-order-card>
        <van-icon
          name="circle"
          color="#1989fa"
          size="30px"
          v-if="!item.isChoosed"
          class="choose-icon"
          @click="chooseOrder(index)"
        />
        <van-icon
          name="passed"
          color="#1989fa"
          size="30px"
          v-else
          class="ml-6px"
          @click="chooseOrder(index)"
        />
      </div>
    </div>
    <div class="oprateBtnWrapper">
      <div class="oprateBtn blue" @click="ignore">忽略</div>
      <div class="oprateBtn yellow" @click="cancelOrder">关闭</div>
    </div>
  </div>
</template>

<script>
import problemOrderCard from '@/components/problemOrderCard.vue'
import { mapState } from 'vuex'

export default {
  name: 'processingList',
  data () {
    return {
      list: []
    }
  },
  components: {
    problemOrderCard
  },
  computed: {
    ...mapState({
      processingList: state => state.pageFormCache.processingList
    })
  },
  mounted () {
    const { list } = this.$route.params
    list.forEach((order) => {
      order.isChoosed = false
    })
    this.list = list
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (from.name === 'OrderDetail') {
        vm.list = vm.processingList
      }
    })
  },
  beforeRouteLeave (to, from, next) {
    if (to.name === 'OrderDetail') {
      this.$store.commit('setProcessingList', this.list)
    } else {
      this.$store.commit('setProcessingList', [])
    }
    next()
  },
  methods: {
    chooseOrder (index) {
      const list = this.lodash.cloneDeep(this.list)
      list[index].isChoosed = !list[index].isChoosed
      this.list = list
    },
    routerToOrderDetail (order) {
      this.$router.push({
        path: '/OrderDetail', query: { orderId: parseInt(order.id) }
      })
    },
    async cancelOrder () {
      if (this.list.filter(item => item.isChoosed).length === 0) {
        return this.$toast('请选择需要关闭的工单！')
      }
      // 组装参数
      const closeOrderNos = this.list.filter(item => item.isChoosed).map(it => {
        return it.orderNo
      })
      this.$store.commit('setCloseOrderNos', closeOrderNos)
      this.ignore()
    },
    ignore () {
      this.$store.commit('setIsBackFromProcessingListByIgnore', true)
      this.$router.back()
    }
  }
}
</script>

<style lang="less" scoped>
@import url("./index.less");
</style>
