var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", { staticClass: "score" }, [
    _vm.showTxt
      ? _c("span", { staticClass: "txt" }, [_vm._v("得分")])
      : _vm._e(),
    _c("span", { staticClass: "first" }, [_vm._v(_vm._s(_vm.score))]),
    _c("span", { staticClass: "line" }),
    _c("span", { staticClass: "last" }, [_vm._v(_vm._s(_vm.total))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }