<template v-cloak>
  <div class="picker-select-wrapper">
    <van-field
      readonly
      clickable
      :value="value"
      placeholder="请选择"
      @click="showPicker = true"
      right-icon="right-icon"
      :disabled = detail
    />
    <van-popup v-model="showPicker" round position="bottom" :disabled = detail>
      <van-picker
        show-toolbar
        :columns="columns"
        @cancel="showPicker = false"
        @confirm="onConfirm"
      >
       <div slot="columns-top" class="blank-top"></div>
      <div slot="columns-bottom" class="blank-bottom"></div>
      </van-picker>
    </van-popup>
  </div>
</template>

<script type='text/ecmascript-6'>
export default {
  name: '',
  components: {},
  props: {
    selectValue: {
      type: String,
      require: true,
      default: ''
    },
    selectList: {
      type: Array,
      require: true,
      default: () => []
    },
    selectObj: {
      type: Object,
      default: () => {
        return {
          key: 'code',
          val: 'name'
        }
      }
    },
    detail: { // 判断是否禁用 详情时禁用 除处理中状态外均为详情
      type: Boolean,
      default: false
    }
  },
  watch: {
    selectValue: function () {
      this._setDefaultValue()
    }
  },
  data () {
    return {
      value: '',
      showPicker: false,
      columns: []
    }
  },
  created () {},
  mounted () {
    this._setListData()
    if (this.selectValue) {
      this._setDefaultValue()
    }
  },
  methods: {
    // 设置初始化单选数据
    _setListData () {
      this.columns = []
      this.selectList.length > 0 &&
        this.selectList.forEach((item) => {
          this.columns.push(item[this.selectObj.val])
        })
    },
    // 当有初始值时把key转换为val
    _setDefaultValue () {
      let { key, val } = this.selectObj
      for (let item of this.selectList) {
        if (item[key] === this.selectValue) {
          this.value = item[val]
          break
        }
      }
    },
    // 设置传出去的值
    onConfirm (value) {
      for (let item of this.selectList) {
        if (item[this.selectObj.val] === value) {
          this.$emit('update:selectValue', item[this.selectObj.key])
          break
        }
      }
      this.$emit('confirm', value)
      this.value = value
      this.showPicker = false
    }
  }
}
</script>
<style  lang='less' scoped>
.picker-select-wrapper {
  .van-field {
    padding: 0;
    /deep/ .van-field__body {
      height: 40px;
      border: .5px solid #c5c5c5;
      border-radius: 2px;
      padding: 5px 12px;
      box-sizing: border-box;
    }
  }
  /deep/ .van-picker{
    .van-picker__toolbar{
      box-sizing: border-box;
      height: 46px;
      border-bottom: 1px solid #E5E5E5;
      &>button{
        padding: 0 12px;
        font-size: 16px;
        color: #3366FE;
      }
    }
    .blank-top{
      height: 15px;
    }
    .blank-bottom{
      height: 16px;
    }
    .van-picker-column__item{
      font-size: 21px;
    }
    .van-picker-column__item--selected{
      color: #222;
    }
    // .van-picker__mask{
    //   // background-size: 100% 125px!important;
    // }
    // .van-picker__columns{
    //   // height: 295px !important;
    // }
    // .van-picker__frame{
    //   // top: 45%;
    // }
  }
}
</style>
