<template>
  <div
    class="px-10px pb-16px mt-16px problemOrder order-item-cal"
    :class="{ isOverdueTime: order.overdueTime }"
    @click="handleClick"
  >
    <span
      class="block absolute right-20px top-66px"
      style="position: absolute; right: 22px; top: 66px"
    >
      <img :src="order | calcCardImg" width="32" alt="" />
    </span>
    <span class="block py-12px flex justify-between items-center">
      <div class="space-x-12px" style="display: flex; align-items: center">
        <span class="font-semibold text-18px text-[#222]">{{
          order.orderNo
        }}</span>
        <span class="copy_btn" @click.prevent.stop="copyTNum(order.orderNo)"
          >复制</span
        >
      </div>
      <span class="text-[#3366FE]">
        {{ order.orderStatusName }}
      </span>
    </span>
    <van-divider dashed class="!m-0" />
    <span class="block info-list">
      <span class="info-label">所在项目:</span>
      <span class="info-val">{{ order.projectName }}</span>
    </span>
    <span class="block flex text-16px leading-24px">
      <span class="info-label flex-shrink-0 text-[#8b8f9a]">所在位置:</span>
      <span
        class="leading-24px text-[#222] !ml-16px"
        style="margin-right: 35px"
        >{{ addressNameComp(order) }}</span
      >
    </span>
    <span class="block info-list">
      <span class="info-label">创建时间:</span>
      <span class="info-val">{{ order.createTime }}</span>
    </span>
    <span class="block flex text-16px leading-24px">
      <span class="info-label flex-shrink-0 text-[#8b8f9a]">工单描述:</span>
      <span class="leading-24px text-[#222] !ml-16px">{{ order.remark }}</span>
    </span>
    <span class="block info-list" v-if="order.handlerName">
      <span class="info-label">处理人:</span>
      <span class="info-val" style="margin-left: 30px">{{
        order.handlerName
      }}</span>
    </span>
    <p class="block info-list-two">
      <span class="block info-label">处理时限:</span>
      <span class="block info-val">
        <span>{{ $moment.formatMinutes(order.processLimit) }}</span>
        <template
          v-if="
            !(
              order.templateNodeCode === 'yiwancheng' ||
              order.templateNodeCode === 'yiwanjie'
            )
          "
        >
          <br />
          <span class="color-green" v-if="order.remainingTime">{{
            order.remainingTime
          }}</span>
          <span class="color-red" v-if="order.overdueTime">{{
            order.overdueTime
          }}</span>
        </template>
      </span>
    </p>
    <!-- 问题分级标签 -->
    <span
      class="gradeTagBac"
      v-if="order.grade"
      :style="{
        'background-color': JSON.parse(order.grade).color,
        'width':gradeTagSize(order.grade),
        'height':gradeTagSize(order.grade),
      }"
    >
    {{JSON.parse(order.grade).value}}
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    order: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters(['getOrderTypeConf']),
    addressNameComp () {
      return function (item) {
        /* 自查公区问题工单所在位置需要组装显示 */
        if (this.getOrderTypeConf.publicAreaOrderTypes.includes(item.orderType)) {
          const addressNameCut = item.addressName ? item.addressName.split('「')[0] || '' : ''
          console.log('addressNameCut: ', addressNameCut)
          return addressNameCut
        }
        /* 自查设施设备工单所在位置直接显示addressName */
        return item.addressName || item.address
      }
    },
    gradeTagSize () {
      return function (grade) {
        const length = JSON.parse(grade).value.length
        console.log('length: ', length)
        switch (length) {
          case 1:
          case 2:
            return '80px'
          case 3:
            return '90px'
          case 4:
            return '100px'
          default:
            return '110px'
        }
      }
    }
  },
  methods: {
    handleClick () {
      this.$emit('click', this.order)
    }
  }
}
</script>

<style lang="less" scoped>
.problemOrder {
  background-color: #fff;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  &.isOverdueTime {
    background-color: rgba(236, 103, 23, 0.3);
  }
  .gradeTagBac {
    position: absolute;
    color: #fff;
    bottom: 0;
    right: 0;
    text-align: center;
    transform: translate(50%,50%) rotate(-45deg);
    transform-origin: center center;
    pointer-events: none;
  }
}
.info-list {
  display: flex;
  height: 28px;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  color: #8b8f9a;
  .info-label {
    flex-shrink: 0;
  }
  .info-val {
    margin-left: 16px;
    color: #222222;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.info-list-two {
  display: flex;
  align-items: flex-start;
  font-size: 16px;
  font-weight: 400;
  color: #8b8f9a;
  align-items: flex-start;
  .info-label {
    flex-shrink: 0;
  }
  .info-val {
    margin-left: 16px;
    color: #222222;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
p {
  margin: 0;
}
.copy_btn {
  padding-top: 2px;
  border: 1px solid #c5c5c5;
  @apply inline-flex items-center justify-center px-4px text-[#c5c5c5] text-13px;
}
</style>
