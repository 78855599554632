<template>
  <div class="vant-table">
    <table
      cellspacing="0"
      border="0"
      :style="styleObject"
      style="width: 100%"
      class="table"
    >
      <tr>
        <th
          class="th"
          v-for="(item, index) in columns"
          :key="index"
          :class="{ lastCol: index === columns.length - 1 }"
        >
          {{ item.label }}
        </th>
      </tr>
      <tr
        v-for="(item, index) in tableData"
        :key="index"
        class="list-tr"
        :class="{ lastrow: index === tableData.length - 1 }"
      >
        <td
          v-for="(context, i) in columns"
          :key="i"
          :class="{ lastCol: i === columns.length - 1 }"
        >
          {{ item[context.tableDataprop] }}
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
export default {
  name: 'TableVant',
  props: {
    styleObject: {
      type: Object,
      default: () => {}
    },
    tableData: {
      type: Array,
      default: () => []
    },
    columns: {
      type: Array,
      default: () => []
    }
  },
  created () {}
}
</script>

<style  scoped>
.table {
  color: #333333;
  border-radius: 0.185185rem;
  background-color: #fdf1ea;
}
.th {
  height: 1.074074rem;
  line-height: 1.074074rem;
  text-align: center;
}
.list-tr {
  height: 1.074074rem;
  line-height: 1.074074rem;
}
table th {
  border-top: 0;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-left: 0;
}
table td {
  border-top: 0;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-left: 0;
}
table tr.lastrow td {
  border-bottom: 0;
}

td.lastCol {
  border-right: 0;
}

th.lastCol {
  border-right: 0;
}
.list-tr:nth-child(2n) {
  /*background-color: #33333b;*/
}
td {
  text-align: center;
}
</style>
