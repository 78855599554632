<template>
  <div class="orderFlow common-page">
    <my-nav :title="title">
      <ScanIcon
        slot="right"
        v-if="
          getOrderTypeConf.equipmentOrderTypes.includes(
            orderDetail.order_type
          ) &&
          (buttonInfo.disposeTemplateNodeCode === 'yiwancheng' ||
            buttonInfo.buttonName === '升级') &&
          !orderDetail.problemOrderId
        "
        @scanSuccess="scanSuccess"
      />
    </my-nav>
    <div class="group-title color-blue" v-if="!buttonInfo.hiddenState">
      <span>{{ buttonInfo.nodeName }} </span>
      <span class="icon-arrow-blue" v-if="buttonInfo.disposeNodeName"></span>
      <span>{{ buttonInfo.disposeNodeName }}</span>
    </div>
    <van-form class="orderFlow-form">
      <MyFieldOfEquipmentSelect
        v-if="fieldState.equipType.show"
        :address.sync="form.itemName"
        :address-id.sync="form.itemCode"
        :really-type.sync="form.itemType"
        :show-location="true"
        :projectCode="projectCode"
        :required="fieldState.equipType.required"
        :editable="true"
        route-name="OrderCirculation"
        :categoryCode.sync="form.categoryCode"
        :reallyType.sync="form.reallyType"
      />
      <MyFieldOfMalfunctionReasonSelect
        v-if="fieldState.equipMalfReasonType.show"
        route-name="OrderCirculation"
        @confirm="malfunctionReasonSelect"
        :problem-list="form.problemOrders || orderDetail.problemOrders"
        :guide="false"
        :categoryCode="form.categoryCode"
        :reallyType="form.itemType"
        :required="fieldState.equipMalfReasonType.required"
      />
      <!-- 问题项 -->
      <van-field
        v-if="fieldState.selectProblemShow.show"
        readonly
        clickable
        label="问题项"
        label-width="150px"
        class="form-input"
        :required="fieldState.selectProblemShow.required"
        @click="selectProblemItem"
        right-icon="arrow"
        input-align="right"
        placeholder="请选择"
      />
      <div
        class="problem-list"
        v-if="fieldState.selectProblemShow.show && form.problemItemReqs && form.problemItemReqs.length > 0"
      >
        <div
          class="item"
          v-for="(item, index) of form.problemItemReqs"
          :key="index"
        >
          <p class="problem-title">
            {{
              `${item.parentName ? item.parentName + "/" : ""}${
                item.problemName || item.mainPartName
              }`
            }}
          </p>
          <p class="problem-info" v-if="item.questLabel.length > 0">
            <span v-for="(item2, index2) of item.questLabel" :key="index2">
              <i v-if="index2 !== 0">|</i
              >{{ item2.questLabelName || item2 }}</span
            >
          </p>
          <p
            class="problem-person"
            v-if="item.rectifyPeopls && item.rectifyPeopls.name"
          >
            整改人：{{ item.rectifyPeopls.name }}
          </p>
        </div>
      </div>
      <!-- 选择问题项(新建设备工单) -->
      <problem-items
        v-if="fieldState.selectProblemShow.show"
        :show.sync="problemItemsShow"
        :project-code-prop="projectCode"
        @result="setProblemList"
        :radio="true"
        ref="problemItems"
        :problem-data="form.problemItemReqs"
        :order-detail="getDetail"
      ></problem-items>
      <van-field
        v-if="fieldState.reasonType.show"
        readonly
        clickable
        :required="fieldState.reasonType.required"
        name="bigCategoryName"
        label="原因大类"
        class="form-input"
        right-icon="arrow"
        input-align="right"
        placeholder="请选择"
        @click="selectReason"
        :value="form.bigCategoryName"
      >
      </van-field>
      <van-field
        v-if="fieldState.effectiveType.show"
        readonly
        clickable
        :required="fieldState.effectiveType.required"
        name="effectiveType"
        label="有效升级"
        input-align="left"
        placeholder="请选择"
      >
        <template #input>
          <van-radio-group v-model="form.isEfficacy" direction="horizontal">
            <van-radio name="1">是</van-radio>
            <van-radio name="0">否</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field
        v-if="fieldState.backReason.show"
        class="mt-8"
        readonly
        clickable
        @click="backReportSysReasonSelectShow = true"
        :required="fieldState.backReason.required"
        :value="form.codeName"
        name="effectiveType"
        right-icon="arrow"
        label="退回原因"
        input-align="right"
        placeholder="请选择"
      >
      </van-field>
      <van-field
        v-if="fieldState.reasonFineType.show"
        readonly
        clickable
        :required="fieldState.reasonFineType.required"
        name="bigCategoryName"
        label="原因细类"
        class="form-input"
        right-icon="arrow"
        input-align="right"
        placeholder="请选择"
        @click="selectFineReason"
        :value="form.smallCategoryName"
      />
      <van-field
        v-if="fieldState.staff.show"
        readonly
        clickable
        :required="fieldState.staff.required"
        name="handler"
        label="处理人"
        class="form-input mt-8"
        right-icon="arrow"
        input-align="right"
        placeholder="请选择"
        @click="selectHandler"
        :value="form.handlerName"
      />
      <div class="question-tags mt-8" v-if="fieldState.problemTags.show">
        <div class="label">
          <div
            class="text"
            :class="{ required: fieldState.problemTags.required }"
          >
            问题标签
          </div>
          <div v-if="!questionTags.length">
            <span>无</span>
            <van-icon
              name="arrow"
              color="#969799"
              style="margin-left: 8px; visibility: hidden"
            />
          </div>
        </div>
        <div class="question-tags-cell" v-if="questionTags.length">
          <div
            class="my-tag"
            :class="{ active: item.select }"
            v-for="item of questionTags"
            :key="item.id"
            @click="selectTags(item)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
      <van-field
        v-if="fieldState.closeType.show"
        readonly
        clickable
        :required="fieldState.closeType.required"
        name="handler"
        label="关闭类型"
        class="form-input mt-8"
        right-icon="arrow"
        input-align="right"
        placeholder="请选择"
        @click="selectCloseType"
        :value="form.closeTypeName"
      />
      <van-field
        readonly
        clickable
        v-if="closeReasonCellShow"
        :required="true"
        name="handler"
        label="非正常关闭原因"
        label-width="auto"
        right-icon="arrow"
        input-align="right"
        placeholder="请选择"
        @click="selectCloseReason"
        :value="form.closeReasonName"
      />
      <!-- 超时原因，只有问题工单（包含公区工单1，4，客户工单3,6）才显示 升级工单不展示 -->
      <van-field
        v-if="timeoutSelectAble"
        readonly
        clickable
        :required="true"
        name="timeoutReason"
        label="超时原因"
        class="form-input mt-8"
        right-icon="arrow"
        input-align="right"
        placeholder="请选择"
        @click="timeoutReasonShow = true"
        :value="timeoutReasonValue"
      />
      <van-popup v-model="timeoutReasonShow" position="bottom">
        <van-picker
          title="选择超时原因"
          show-toolbar
          :columns="timeoutReasonList"
          @cancel="timeoutReasonShow = false"
          @confirm="timeoutReasonSelect"
          value-key="name"
        >
          <template #option="item">
            <span>{{ item.name }}</span>
          </template>
        </van-picker>
      </van-popup>
      <!-- 工单性质为「品检」的问题工单，在流转（已完成—>已完结）默认加载「问题原因」字段项，下拉选项来源数据字典「问题原因」，见图5，必填； -->
      <van-field
        readonly
        clickable
        @click="issueReasonShow = true"
        name="grade"
        :value="issueReasonValue"
        label="问题原因"
        class="form-input"
        right-icon="arrow"
        input-align="right"
        placeholder="请选择问题原因"
        :required="issueReasonRequired"
        v-if="issueReasonIF"
      />
      <!-- 承接查验  新增 整改前整改后图片展示 只有承接查验普通工单才显示 -->
      <div class="rectify-swiper" v-if="orderDetail.order_nature === 6 && buttonInfo.curTemplateNodeCode === 'yiwancheng' && buttonInfo.disposeTemplateNodeCode === 'yiwanjie'" >
        <div class="item">
          <div class="title">整改前</div>
          <!-- <div class="swiper" id="before-rectify-swiper"></div> -->
          <swiper @slideChange="onSlideChange_before" ref="beforeSwiper" :options="swiperOption" class="swiper-container">
            <swiper-slide class=".swiper-slide" v-for="(image,index) in beforeHandleAttachments" :key="index">
              <van-image
                width="100%"
                height="100%"
                class="photo"
                @click="handleClickSwiper(index, beforeHandleAttachments,'before')"
                :src="image.url"
              />
            </swiper-slide>
          </swiper>
        </div>
        <div class="item">
          <div  class="title">整改后</div>
          <!-- <div class="swiper" id="after-rectify-swiper"></div> -->
          <swiper v-show="afterHandleAttachments.length > 0" @slideChange="onSlideChange_after" :options="swiperOption"  ref="afterSwiper" class="swiper-container">
            <swiper-slide class=".swiper-slide" v-for="(image,index) in afterHandleAttachments" :key="index">
              <van-image
                width="100%"
                height="100%"
                class="photo"
                @click="handleClickSwiper(index, afterHandleAttachments,'after')"
                :src="image.url"
              />
            </swiper-slide>
          </swiper>
        </div>
      </div>
      <!-- 快捷输入 -->
      <van-field
        readonly
        clickable
        name="picker"
        class="mt-8"
        v-if="buttonInfo.buttonName !== '升级' && buttonInfo.buttonName !== '非设备'"
        :value="quickInput"
        right-icon="arrow"
        label="快捷输入"
        placeholder="点击选择进行快捷输入备注"
        @click="openQuickInput"
      />
      <van-popup v-model="quickInputPickerShow" position="bottom">
        <van-picker
          show-toolbar
          :columns="quickInputColumns"
          @confirm="onQuickInputConfirm"
          @cancel="quickInputPickerShow = false"
        />
      </van-popup>
      <van-field
        name="remark"
        v-model="form.desc"
        label="备注"
        rows="3"
        class="form-textarea"
        type="textarea"
        placeholder="请输入备注"
      >
        <template v-slot:right-icon v-if="isOffline">
          <micro-text
            @speakRes="speakRes"
          />
        </template>
      </van-field>
      <div class="photoUpload mt-8">
        <common-upload
          :file.sync="form.attachments"
          :enable-watermark="false"
          :enable-album="enableAlbum"
          :enable-edit="false"
          :order-id="orderDetail.id"
          :required="fieldState.attachmentsShow.required"
        ></common-upload>
      </div>
      <div class="bottom-mat"></div>
      <div class="bottom-area">
        <template>
          <van-button :disabled="submitLoading" class="button blue-reverse" @click="flowCancel">取消</van-button>
          <van-button :disabled="submitLoading" class="button blue" @click="flowSubmit">确定</van-button>
        </template>
      </div>
    </van-form>
    <div class="space"></div>
    <!-- 选择处理人 -->
    <select-handler
      v-if="selectHandlerShow"
      :order-detail="form"
      :button-info="buttonInfo"
      :listData="handlerData.roleUserSels"
      v-model="selectHandlerShow"
      @result="setHandler"
    ></select-handler>
    <!-- 选择原因类型 -->
    <select-reason
      v-if="fieldState.reasonType.show"
      v-model="selectReasonShow"
      :data-val="form"
      @result="setReason"
      IsSmall
    ></select-reason>
    <!-- 选择原因细类 -->
    <select-reason
      v-if="selectSmallReasonShow"
      v-model="selectSmallReasonShow"
      :data-val="form"
      :bigReasonId="bigCategoryId"
      @result="setSmallReason"
      IsSmall
      noShowBtn
    ></select-reason>
    <usual-select
      v-model="closeTypeShow"
      :list="closeType"
      @result="getCloseType"
      title="关闭类型"
    ></usual-select>
    <usual-select
      v-model="closeReasonShow"
      :list="improperReason"
      @result="getCloseReason"
      title="关闭原因"
    ></usual-select>
    <!-- 当不显示退回原因选择框时不加载这个弹窗 -->
    <BackToReportSysReasonSelect
      v-if="fieldState.backReason.show"
      :show.sync="backReportSysReasonSelectShow"
      @confirm="backReasonConfirm"
    />
    <!-- 问题原因选择器 -->
    <van-popup v-model="issueReasonShow" position="bottom">
      <van-picker
        show-toolbar
        title="选择问题原因"
        :columns="issueReasonList"
        :default-index="0"
        value-key="name"
        @cancel="issueReasonShow = false"
        @confirm="issueReasonSelectConfirm"
      />
    </van-popup>
  </div>
</template>

<script>
import SelectHandler from '@/components/SelectHandlerFlow'
import SelectReason from './components/SelectReason'
import UsualSelect from './components/UsualSelect'
import api from '@/api/index'
import moment from 'moment'
import MyFieldOfMalfunctionReasonSelect from '@/components/MyFieldOfMalfunctionReasonSelect/index'
import MyFieldOfEquipmentSelect from '@/components/MyFieldOfEquipmentSelect/index'
import BackToReportSysReasonSelect from './components/BackToReportSysReasonSelect'
import ScanIcon from '@/components/ScanIcon'
import ProblemItems from '@/pages/problemRecord/components/ProblemItems'
import { getDictionaryAllOfflineHandler } from '@/plugins/offlineMixin/offlineApiHandlers/DictionaryOfflineApiHandlers'
import {
  cleanOrderAfterOfflineSubmitHandler,
  cleanOrderAfterServerSubmitHandler,
  cleanOrderAfterReCirculation,
  questionOrderSubmitOfflineApiHandler
} from '@/plugins/offlineMixin/offlineApiHandlers/OrderSubmitOfflineApiHandlers'
import { setHistoryListDataHandler } from '@/plugins/offlineMixin/offlineApiHandlers/HistoryOrderListOfflineApiHandlers'
import { OFFLINE_REQUEST_SUCCESS, ONLINE_REQUEST_SUCCESS } from '@/plugins/offlineMixin/utils/StaticConstantCode'
import dashboardMixin from '@/plugins/offlineMixin/dashboardMixin'
import { cloneDeep } from 'lodash'
import { mapGetters } from 'vuex'
import {
  uploadPhotoByImgKeys
} from '@/plugins/offlineMixin/utils/offlineImgTool'
import mobile from '@/utils/mobile'

import { ImagePreview } from 'vant'
export default {
  name: 'OrderCirculation',
  mixins: [dashboardMixin],
  data () {
    return {
      title: 'orderDetail',
      fieldState: {
        /* 原因类型 */
        equipType: {
          show: false,
          required: false
        },
        equipMalfReasonType: {
          show: false,
          required: false
        },
        backReason: {
          show: false,
          required: false
        },
        reasonType: {
          show: false,
          required: false
        },
        effectiveType: {
          show: false,
          required: false
        },
        // 原因细类
        reasonFineType: {
          show: false,
          required: false
        },
        problemTags: {
          show: false,
          required: false
        },
        staff: {
          show: false,
          required: false
        },
        closeType: {
          show: false,
          required: false
        },
        selectProblemShow: {
          show: false,
          required: false
        },
        attachmentsShow: {
          show: true,
          required: false
        }
      },
      form: {
        handleEnpoint: 'APP',
        handlerName: undefined,
        handlerId: undefined,
        isEfficacy: undefined,
        problemOrders: undefined,
        problemItemReqs: undefined,
        itemCode: undefined, // fm 设施设备编码
        itemName: undefined, // fm 设施设备名称
        itemType: undefined, // fm 设施设备类型
        categoryCode: undefined, // 用于主要部件原因过滤
        addressType: undefined,
        desc: '',
        attachments: []
      },
      problemItemsShow: false, // 问题项show
      handlerData: {}, // 接口获取到的处理人数据
      selectHandlerShow: false, // 处理人显示
      selectReasonShow: false, // 原因类型显示
      selectSmallReasonShow: false, // 原因细类
      buttonInfo: {}, // 上级被点击的操作按钮的信息
      orderDetail: {}, // 工单详细信息
      probleTags: [], // 工单详细信息里面的问题标签
      fieldConfig: [], // 预加载的流转配置信息
      questionTags: [], // 问题标签
      ossParams: {}, // 阿里云oss参数
      rotefileList: [], // route节点信息
      submitFlag: true, // 提交验证的通过标志
      closeType: [], // 关闭类型
      improperReason: [], // 关闭类型对应的具体的关闭原因
      closeTypeShow: false, // 关闭类型选择框的显示逻辑
      closeReasonCellShow: false, // 关闭原因cell显示逻辑
      closeReasonShow: false, // 关闭类型选择框的显示逻辑
      backReportSysReasonSelectShow: false, // 退回天问报事转发工单的选择框显示逻辑
      handlerWithoutArrow: true, // 显示处理人cell的右边链接箭头
      bigCategoryId: undefined,
      projectCode: undefined, // 用来获取工单详情中传过来的的projectCodes
      timeoutReasonShow: false, // 超时原因picker显示隐藏
      timeoutReasonValue: '', // 超时原因文字显示name,
      timeoutReasonList: [],
      issueReasonList: [],
      issueReasonShow: false,
      issueReasonCode: null,
      issueReasonIF: false,
      issueReasonRequired: false,
      submitLoading: false,
      beforeHandleAttachments: [], // 整改前图片
      afterHandleAttachments: [], // 整改前图片
      swiperOption: {
        pagination: {
          el: '.swiper-pagination',
          clickable: false
        },
        centeredSlides: true,
        slidesPerView: 3,
        // loop: true,
        spaceBetween: 10
      },
      beforeSwiperIndex: 0,
      afterSwiperIndex: 0,
      quickInput: '', // 快捷输入model
      quickInputPickerShow: false, // 快捷输入选择器show
      quickInputColumns: [], // 快捷输入选项
      enableAlbum: true // 能否选相册
    }
  },
  computed: {
    ...mapGetters(['getDetail', 'getOrderTypeConf']),
    isOffline () {
      return this.$store.getters.getIsOffline
    },
    isZG () {
      return this.$store.getters.getIsZG
    },
    pageType () {
      return this.$store.getters.getPageType
    },
    canOfflineSubmit () {
      // 逆向流转或者正向流转时下个节点不是处理完成
      return (
        this.buttonInfo.buttonType === 2 &&
        this.buttonInfo.disposeTemplateNodeCode === 'yiwancheng'
      )
    },
    timeoutSelectAble () {
      // 超时原因出现条件：1、问题整改  2、问题工单1、4、3、6（公区工单1、4  设备工单3、6）  3、 plan_end超过当前时间
      const outTime =
        moment().valueOf() -
          moment(this.orderDetail.plan_end, 'YYYY-MM-DD HH:mm:ss').valueOf() >
        0
      return (
        this.getOrderTypeConf.normalOrderTypes.includes(
          this.orderDetail.order_type
        ) &&
        this.buttonInfo.buttonType === 2 &&
        this.buttonInfo.disposeTemplateNodeCode === 'yiwancheng' &&
        outTime
      )
    },
    issueReasonValue () {
      const issueReasonName = this.issueReasonList.find(item => {
        return item.code === this.issueReasonCode
      })
      return issueReasonName ? issueReasonName.name : null
    }
  },
  created () {
    /* 获取route节点信息 */
    // let dictionaryList = this.$storage.get('dictionaryList')
    // this.getRotefile(dictionaryList)
    this.title = this.$route.query.title || '工单流转'
  },
  mounted () {
    this.orderDetail = this.$storage.get('orderDetail')
    this.buttonInfo = this.$storage.get('buttonInfo')
    this.trace_info = this.$storage.get('trace_info')
    console.log('buttonInfo: ', this.buttonInfo)
    console.log('orderDetail: ', this.orderDetail)
    console.log('trace_info: ', this.trace_info)
    this.form.itemName = this.orderDetail.addressName
    this.form.itemType = this.orderDetail.addressType
    this.form.categoryCode = this.orderDetail.addressCategoryCode
    this.projectCode = this.orderDetail.project_code

    this.form.orderNo = this.orderDetail.order_no
    this.form.orderId = this.orderDetail.id
    this.enableAlbum = this.buttonInfo.disposeTemplateNodeCode === 'yiwancheng' ? (this.orderDetail.takePhotos === 0) : true
    console.log('this.enableAlbum: ', this.enableAlbum)
    this.beforeHandleAttachments = this.orderDetail.attachment_list
    if (this.trace_info) {
      const completeTraceInfo = this.trace_info.find(item => item.action === '完成')
      completeTraceInfo && (this.afterHandleAttachments = completeTraceInfo.attachments || [])
    }
    this.initIssueReason()
    if (this.buttonInfo.fieldConfig) {
      // 自查公区 的fieldConfig为空字符串
      if (typeof this.buttonInfo.fieldConfig === 'object') {
        this.fieldConfig = this.buttonInfo.fieldConfig
      } else {
        this.fieldConfig = JSON.parse(this.buttonInfo.fieldConfig)
      }
    }
    /* 存储原来的原因大类，原因细类的code和名称 */
    this.form.bigCategoryCode = this.orderDetail.big_category_code
    this.bigCategoryId = this.orderDetail.big_category_id
    this.form.smallCategoryCode = this.orderDetail.small_category_code
    this.form.bigCategoryName = this.orderDetail.big_category_name
    this.form.smallCategoryName = this.orderDetail.small_category_name

    /* 配置项的显示逻辑调用 */
    this.getfieldShow(this.fieldConfig)

    /* 当需要显示问题标签时 */
    if (this.fieldState.problemTags.show) {
      /* 获取当前工单默认已经有的问题标签，并将其赋值 */
      this.probleTags = this.$storage.get('probleTags')
      this.form.problemTags = this.probleTags.join()
      this.getLabels()
    }
    if (this.fieldState.staff.show) {
      this.getHandlerData(this.form, this.buttonInfo)
    }
    if (this.fieldState.selectProblemShow.show && this.getDetail.problemOrders && this.getDetail.problemOrders.length > 0) {
      console.log('this.getDetail.problemOrders: ', this.getDetail.problemOrders)
      const problemOrders = this.lodash.cloneDeep(this.getDetail.problemOrders)
      problemOrders.forEach(item => {
        item.rectifyPeopls = {
          code: this.orderDetail.handler_id,
          name: this.orderDetail.handler_name
        }
        item.questLabel = item.questLabel.length > 0 ? JSON.parse(item.questLabel) : []
      })
      this.form.problemItemReqs = problemOrders
      console.log('selectProblemShow this.form.problemItemReqs: ', this.form.problemItemReqs)
    }
    if (this.fieldState.equipMalfReasonType.show) {
      this.form.problemOrders = this.orderDetail.problemOrders
      console.log('equipMalfReasonType this.form.problemOrders: ', this.form.problemOrders)
    }
    this.getDictionaryitemReq()
    this.getQuickInputColumns()
  },
  watch: {
    'form.itemName' (val, old) {
      if (old) {
        this.form.problemOrders = []
      }
    }
  },
  methods: {
    openQuickInput () {
      if (this.quickInputColumns.length === 0) {
        this.$toast('无快捷输入选项')
      } else {
        this.quickInputPickerShow = true
      }
    },
    selectProblemItem () {
      this.problemItemsShow = true
    },
    /* 问题项选择确认 */
    setProblemList (list) {
      console.log('setProblemList list: ', list)
      const problemList = this.lodash.cloneDeep(list)
      this.quickInputColumns = list.reduce((arr, item) => {
        if (item.flowQuickInputs && item.flowQuickInputs.length > 0) {
          const quickInputs = item.flowQuickInputs.filter(quick => {
            return quick.orderStatusCode === `${this.orderDetail.template_node_code}1`
          }).map(quick => quick.content)
          arr = arr.concat(quickInputs)
        }
        return arr
      }, [])
      console.log('this.quickInputColumns : ', this.quickInputColumns)
      this.form.problemItemReqs = problemList.map(item => {
        this.form.bigCategoryCode = item.bigCategoryCode
        this.form.bigCategoryName = item.bigCategoryName
        this.form.smallCategoryCode = item.smallCategoryCode
        this.form.smallCategoryName = item.smallCategoryName
        delete item.flowQuickInputs
        return item
      })
      console.log('this.form: ', this.form)
      console.log('this.form.problemItemReqs: ', this.form.problemItemReqs)
    },
    malfunctionReasonSelect (problemOrders) {
      console.log('malfunctionReasonSelect problemOrders: ', problemOrders)
      this.form.problemOrders = problemOrders
    },
    async getQuickInputColumns () {
      if (this.isOffline) {
        const categoryCode = this.form.smallCategoryCode ? this.form.smallCategoryCode : this.form.bigCategoryCode
        console.log('categoryCode: ', categoryCode)
        const result = await this.$http.get(`${api.quickInputListReq}${categoryCode}/${this.orderDetail.template_node_code}1`)
        console.log('getQuickInputColumns result: ', result)
        this.quickInputColumns = result.map(item => item.content)
      } else {
        if (this.getDetail.flowQuickInputs && this.getDetail.flowQuickInputs.length > 0) {
          this.quickInputColumns = this.getDetail.flowQuickInputs.filter(quick => {
            return quick.orderStatusCode === `${this.orderDetail.template_node_code}1`
          }).map(quick => quick.content)
          console.log('getQuickInputColumns this.quickInputColumns: ', this.quickInputColumns)
        }
      }
    },
    // get
    onQuickInputConfirm (value) {
      console.log('value: ', value)
      const desc = this.form.desc
      this.form.desc = desc + value
      this.quickInputPickerShow = false
    },
    // 【问题原因】sprint20221125新增功能
    initIssueReason () {
      let show = false
      let required = false
      if (this.orderDetail.order_nature === 5) {
        // 跟进
        if (this.buttonInfo.buttonType === 1) {
          if (['daifenpai', 'yijiedan', 'chulizhong'].includes(this.buttonInfo.curTemplateNodeCode)) {
            show = true
          }
        } else if (this.buttonInfo.buttonType === 2 && this.buttonInfo.curTemplateNodeCode === 'yiwancheng' && this.buttonInfo.disposeTemplateNodeCode === 'yiwanjie') {
          // 流转（已完成—>已完结）
          show = true
          required = true
          // 工单性质为「品检」的问题工单,如在跟进过程中已提交过问题原因则自动加载并可重新选择
        }
        if (this.orderDetail.issueReason) {
          this.issueReasonCode = this.orderDetail.issueReason
        }
      }
      this.issueReasonIF = show
      this.issueReasonRequired = required
    },
    issueReasonSelectConfirm (issueReasonItem) {
      this.issueReasonCode = issueReasonItem.code
      this.issueReasonShow = false
    },
    scanSuccess (res) {
      if (!res || !res.length) return
      const scanInfo = res[0]
      if (!scanInfo.pointTreePointResps || !scanInfo.pointTreePointResps.length) { return }
      this.form.itemCode = scanInfo.pointTreePointResps[0].code
      this.form.reallyType = scanInfo.pointTreePointResps[0].type
      this.form.itemType = scanInfo.pointTreePointResps[0].type
      this.form.categoryCode = scanInfo.pointTreePointResps[0].categoryCode // 主要部件原因过滤字段
      this.form.itemName = `${scanInfo.name}/${scanInfo.pointTreePointResps[0].name}「${scanInfo.pointTreePointResps[0].location}」`
    },
    selectCompConfirm (list, listDetail) {
      this.$EB.$off(this.$EBT.equipmentFilter, this.selectCompConfirm)
      const detail = listDetail[0]
      this.form.itemCode = detail.code
      this.$set(this.form, 'itemName', detail.codeName)
    },
    /* 获取全部route流程配置信息 */
    getRotefile (list) {
      if (list) {
        list.forEach((item) => {
          if (item.dicCode === 'rotefile') {
            this.rotefileList.push(item)
          }
        })
      } else {
        return false
      }
    },
    /* 获取流程页面应该展示的字段,每个字段的校验逻辑，并在页面上进行动态的渲染 */
    getfieldShow (list) {
      if (list.length > 0) {
        list.forEach((item) => {
          if (item.name === 'attachments') {
            this.fieldState.attachmentsShow.required = !!item.ischeck
          }
          if (item.name === 'wentixiang') {
            this.fieldState.selectProblemShow.show = true
            if (item.ischeck) {
              this.fieldState.selectProblemShow.required = true
            } else {
              this.fieldState.selectProblemShow.required = false
            }
          }
          if (item.name === 'yuanyindalei') {
            this.fieldState.reasonType.show = true
            if (item.ischeck) {
              this.fieldState.reasonType.required = true
            } else {
              this.fieldState.reasonType.required = false
            }
          }
          if (item.name === 'yuanyinxilei') {
            this.fieldState.reasonFineType.show = true
            if (item.ischeck) {
              this.fieldState.reasonFineType.required = true
              this.fieldState.reasonType.required = true
            } else {
              this.fieldState.reasonFineType.required = false
            }
          }
          if (item.name === 'isEfficacy') {
            this.fieldState.effectiveType.show = true
            if (item.ischeck) {
              this.fieldState.effectiveType.required = true
            } else {
              this.fieldState.effectiveType.required = false
            }
          }
          if (item.name === 'sheshishebei') {
            this.fieldState.equipType.show = true
            if (item.ischeck) {
              this.fieldState.equipType.required = true
            } else {
              this.fieldState.equipType.required = false
            }
          }
          if (item.name === 'zhuyaobujianguzhangyuanyin') {
            this.fieldState.equipMalfReasonType.show = true
            if (item.ischeck) {
              this.fieldState.equipMalfReasonType.required = true
            } else {
              this.fieldState.equipMalfReasonType.required = false
            }
          }
          if (item.name === 'backReason') {
            this.fieldState.backReason.show = true
            if (item.ischeck) {
              this.fieldState.backReason.required = true
            } else {
              this.fieldState.backReason.required = false
            }
          }
          if (item.name === 'wentibiaoqian') {
            this.fieldState.problemTags.show = true
            if (item.ischeck) {
              this.fieldState.problemTags.required = true
            } else {
              this.fieldState.problemTags.required = false
            }
          }
          if (item.name === 'chuliren') {
            this.fieldState.staff.show = true
            if (item.ischeck) {
              this.fieldState.staff.required = true
            } else {
              this.fieldState.staff.required = false
            }
          }
          if (item.name === 'guanbileixing') {
            this.fieldState.closeType.show = true
            if (item.ischeck) {
              this.fieldState.closeType.required = true
            } else {
              this.fieldState.closeType.required = false
            }
          }
        })
      } else {
        return false
      }
    },
    /* 选择处理人 */
    selectHandler () {
      if (!this.handlerData.roleUserSels) {
        return false
      }
      this.selectHandlerShow = true
    },
    /* 处理人操作 */
    setHandler (item) {
      this.form.handlerName = item.userName
      this.form.handlerId = item.userId
    },
    /* 关闭类型 操作 */
    selectCloseType () {
      this.closeTypeShow = true
    },
    /* 关闭原因 操作 */
    selectCloseReason () {
      this.closeReasonShow = true
    },
    /* 选择原因类别 */
    selectReason () {
      this.selectReasonShow = true
    },
    // 选择原因细类
    selectFineReason () {
      if (!this.form.bigCategoryCode) {
        this.$toast('请先选择原因大类')
        return
      }
      this.selectSmallReasonShow = true
    },
    /* 选择原因操作 */
    setReason (item) {
      if (this.form.bigCategoryCode !== item.bigCategoryCode) {
        this.form.smallCategoryName = ''
        this.form.smallCategoryCode = ''
      }
      this.form.bigCategoryName = item.bigCategoryName
      this.form.bigCategoryCode = item.bigCategoryCode
      this.bigCategoryId = item.bigCategoryId
      // this.form.smallCategoryId = item.smallCategoryId
      this.getLabels()
      this.getQuickInputColumns()
    },
    // 选择原因细类
    setSmallReason (item) {
      this.form.smallCategoryName = item.smallCategoryName
      this.form.smallCategoryCode = item.smallCategoryCode
      this.getLabels()
      this.getQuickInputColumns()
    },
    /* 选择问题标签 */
    selectTags (item) {
      let stashArr = []
      if (item.select) {
        item.select = false
      } else {
        item.select = true
      }
      this.questionTags.forEach((each) => {
        if (each.select) {
          stashArr.push(each.name)
        }
      })
      this.form.problemTags = stashArr.join()
    },
    /* 选择关闭类型 */
    getCloseType (result) {
      if (result.name === '非正常关闭') {
        this.closeReasonCellShow = true
      } else {
        this.closeReasonCellShow = false
        this.form.closeReason = ''
        this.form.closeReasonName = ''
      }
      this.form.closeType = result.code
      this.form.closeTypeName = result.name
    },
    /* 选择关闭原因 */
    getCloseReason (result) {
      this.form.closeReason = result.code
      this.form.closeReasonName = result.name
    },
    /* 选择退回天问报事原因 */
    backReasonConfirm (result) {
      this.form.code = result.code
      this.form.codeName = result.name
    },
    /* 获取关闭类型和关闭类型的具体原因 */
    async getDictionaryitemReq () {
      const dictionaryList = await this.$apiOffline({
        methods: 'get',
        params: { dic_code: '' },
        api: this.$api.dictionaryitem,
        offlineApiHandlers: getDictionaryAllOfflineHandler
      })
      if (this.fieldState.closeType.show) {
        this.closeType = dictionaryList.filter(
          (item) => item.dicCode === 'closeType'
        )
        this.form.closeTypeName = '正常关闭'
        this.form.closeType = this.closeType.find(
          (i) => i.name === '正常关闭'
        ).code
        console.log('正常关闭this.form: ', this.form)
        // 关闭类型对应的具体的关闭原因
        this.improperReason = dictionaryList.filter(
          (item) => item.dicCode === 'improperReason'
        )
      }
      if (this.timeoutSelectAble) {
        this.timeoutReasonList = dictionaryList.filter(
          (item) => item.dicCode === 'timeoutReason'
        )
      }
      // 品检工单选择【问题原因】
      if (this.orderDetail.order_nature === 5) {
        this.issueReasonList = dictionaryList.filter(
          (item) => item.dicCode === 'issueReason'
        )
      }
    },
    /* 获取问题标签 */
    getLabels () {
      this.$http
        .get(api.getLabels, {
          orderNo: this.form.orderNo,
          bigCategoryCode: this.form.bigCategoryCode,
          smallCategoryCode: this.form.smallCategoryCode
        })
        .then((res) => {
          this.questionTags = res
          /* 回填问题标签 */
          this.probleTags.forEach((item) => {
            this.questionTags.forEach((item2) => {
              if (item === item2.name) {
                item2.select = true
              }
            })
          })
        })
    },
    /* 提前获取处理人 */
    getHandlerData (form, buttonInfo) {
      if (buttonInfo.buttonName === '升级') {
        this.$http
          .post(
            `${api.getUpgradeHandlerUsers}/${this.orderDetail.project_code}`
          )
          .then((res) => {
            this.handleUserFill(res)
          })
      } else {
        if (this.isOffline) {
          let requestParams = {}
          if (buttonInfo.buttonType === 3) {
            requestParams = {
              orderNo: form.orderNo,
              buttonName: buttonInfo.buttonName,
              bigCategoryCode: form.bigCategoryCode,
              smallCategoryCode: form.smallCategoryCode
            }
          } else {
            requestParams = {
              orderNo: form.orderNo,
              buttonName: buttonInfo.buttonName
            }
          }
          this.$http.get(api.gethandlerUsers, requestParams).then((res) => {
            this.handleUserFill(res)
          })
        } else {
          let { nodeHandlerSel } = this.buttonInfo
          this.handleUserFill(nodeHandlerSel)
        }
      }
    },
    handleUserFill (res) {
      if (!res) return
      this.handlerData = res
      let userInfo = this.$store.getters.getUserInfo
      if (userInfo.id === res.userId.toString()) {
        this.form.handlerName = res.userName
        this.form.handlerId = res.userId
      } else {
        (res.roleUserSels || []).forEach((sel) => {
          const { userSelList } = sel
          if (userSelList && userSelList.length > 0) {
            userSelList.forEach((item) => {
              if (userInfo.id === item.userId.toString()) {
                this.form.handlerName = item.userName
                this.form.handlerId = item.userId
              }
            })
          }
        })
      }
    },
    /* 提交前预处理 */
    beforeSubmit () {
      if (this.fieldState.selectProblemShow.required) {
        if (!this.form.problemItemReqs || this.form.problemItemReqs?.length === 0) {
          this.$toast('请选择问题项')
          this.submitFlag = false
          return
        }
      }
      if (this.fieldState.equipMalfReasonType.show) {
        if (!this.form.problemOrders || this.form.problemOrders?.length === 0) {
          this.$toast('请选择主要部件故障原因')
          this.submitFlag = false
          return
        }
      }
      if (this.fieldState.staff.required) {
        if (!(this.form.handlerId && this.form.handlerName)) {
          this.$toast('请选择处理人')
          this.submitFlag = false
          return false
        }
      }
      if (this.fieldState.reasonType.required) {
        if (!(this.form.bigCategoryCode || this.form.smallCategoryCode)) {
          this.$toast('请选择原因类型')
          this.submitFlag = false
          return false
        }
      }
      if (this.fieldState.effectiveType.required) {
        if (!(this.form.isEfficacy || this.form.isEfficacy)) {
          this.$toast('请选择升级类型')
          this.submitFlag = false
          return false
        }
      }
      if (this.fieldState.backReason.required) {
        if (!(this.form.code || this.form.code)) {
          this.$toast('请选择退回原因')
          this.submitFlag = false
          return false
        }
      }
      if (this.fieldState.reasonFineType.required) {
        if (!this.form.smallCategoryCode) {
          this.$toast('请选择原因细类')
          this.submitFlag = false
          return false
        }
      }
      if (this.fieldState.problemTags.required) {
        if (!this.form.problemTags) {
          this.$toast('请选择问题标签')
          this.submitFlag = false
          return false
        }
      }
      if (this.fieldState.closeType.required) {
        if (!this.form.closeType) {
          this.$toast('请选择关闭类型')
          this.submitFlag = false
          return false
        }
      }
      // 图片
      if (this.fieldState.attachmentsShow.required) {
        if (!this.form.attachments || !this.form.attachments.length) {
          this.$toast('请上传图片')
          this.submitFlag = false
          return false
        }
      }
      if (this.closeReasonCellShow && !this.form.closeReason) {
        this.$toast('请选择关闭原因')
        this.submitFlag = false
        return false
      }
      if (this.timeoutSelectAble && !this.form.timeoutReason) {
        this.$toast('请选择超时原因')
        this.submitFlag = false
        return false
      }
      if (this.issueReasonRequired && !this.issueReasonCode) {
        this.$toast('请选择问题原因')
        this.submitFlag = false
        return false
      }
    },
    /* 流程提交 */
    async flowSubmit () {
      let flowApi = api.OrderFlow
      let reqSuccessMsg = '工单流转成功'
      this.submitFlag = true
      this.beforeSubmit()
      if (!this.submitFlag) {
        this.submitLoading = false
        return
      }
      this.submitLoading = true
      try {
        if (this.issueReasonCode) {
          this.form.issueReason = this.issueReasonCode
          const issueReason = this.issueReasonList.find(i => i.code === this.form.issueReason)
          this.form.issueReasonName = issueReason.name || ''
        }
        this.form.commitTime = moment().format('YYYY-MM-DD HH:mm:ss')
        this.form.buttonName = this.buttonInfo.buttonName
        this.form.curNodeId = this.buttonInfo.nodeId
        const copyForm = cloneDeep(this.form)
        if (
          this.getOrderTypeConf.normalOrderTypes.includes(
            this.orderDetail.order_type
          )
        ) {
          if (this.fieldState.equipMalfReasonType.show) {
            // 自查设施设别问题工单（3）,客户设施设备（6） 流转/升级都要处理问题项
            if (copyForm.problemOrders?.length) {
            // this.form.problemOrders为空时 无法执行reduce方法
              const problemOrders = copyForm.problemOrders.reduce((arr, item) => {
                const index = arr.findIndex(
                  (fmItem) =>
                    fmItem.problemId === item.problemId &&
                  fmItem.mainCode === item.mainPartCode
                )
                if (index === -1) {
                  arr.push({
                    causeId: item.breakDownCauseId,
                    problemId: item.problemId,
                    problemName: item.problemName,
                    mainCode: item.mainPartCode,
                    mainPartName: item.mainPartName,
                    labels: [item.questLabel]
                  })
                } else {
                  arr[index].labels.push(item.questLabel)
                }
                return arr
              }, [])
              problemOrders.forEach((item) => {
                item.labels = item.labels.join(',')
              })
              copyForm.problemOrders = problemOrders
            }
          }
          if (this.fieldState.selectProblemShow.show) {
            if (copyForm.problemItemReqs?.length) {
              // this.form.problemItemReqs 无法执行reduce方法
              copyForm.problemItemReqs = copyForm.problemItemReqs.map(item => {
                return {
                  rectifyPeopls: Object.keys(item.rectifyPeopls).length > 0
                    ? JSON.stringify(item.rectifyPeopls)
                    : undefined,
                  problemId: item.problemId,
                  questLabel: (item.questLabel && Array.isArray(item.questLabel)) ? JSON.stringify(item.questLabel) : JSON.stringify([])
                }
              })
            }
          }
        }
        if (this.buttonInfo.buttonType === 100000) {
          flowApi = api.orderUpgrade
          reqSuccessMsg = '工单升级成功'
        }
        if (this.buttonInfo.buttonName === '非设备') {
          flowApi = api.orderBackReportSys
          if (this.buttonInfo.FM_ORDER_BACK) {
            flowApi = api.fmOrderBackReportSys
          }
          reqSuccessMsg = '工单退回成功'
        }
        if (!this.isOffline && !this.canOfflineSubmit) {
          this.$EB.$emit(this.$EBT.NO_NETWORK_WARN)
          return
        }
        // 加判断 离线--yes-->判断按键类型=2和处理状态'chulizhong' --yes--> 将form挂在resDetail,传给offline.js的orderDetail
        //                                                   --no--> 提示离线的消息
        this.isOffline && (copyForm.attachments = await uploadPhotoByImgKeys(copyForm.attachments))
        console.log('copyForm: ', copyForm)
        this.orderDetail.form = copyForm
        const result = await this.$apiOffline({
          api: flowApi,
          methods: 'post',
          params: { ...copyForm, buttonInfo: this.buttonInfo },
          offlineApiHandlers: this.canOfflineSubmit
            ? questionOrderSubmitOfflineApiHandler
            : null
        })
        console.log('>>>>>orderCirulation 中 流转完成返回result：', result)
        if (!this.canOfflineSubmit) {
        // 下个节点不是已完成, 只能走在线，并且记录在近三天
          await this.$apiOffline({
            params: this.orderDetail,
            offlineApiHandlers: setHistoryListDataHandler,
            forceOffline: true
          })
          // 逆扭转 处理中--->待处理
          if (this.buttonInfo.buttonType === 3) {
            await this.$apiOffline({
              params: [this.orderDetail.id],
              offlineApiHandlers: cleanOrderAfterReCirculation,
              forceOffline: true
            })
          }
        } else {
          const submitOrderList = [{
            orderId: this.orderDetail.id,
            isComplete: true,
            orderType: this.orderDetail.order_type
          }]
          // 问题工单和升级工单完成需要删除缓存和近三天记录
          const handler =
          result.code === OFFLINE_REQUEST_SUCCESS
            ? cleanOrderAfterOfflineSubmitHandler
            : cleanOrderAfterServerSubmitHandler
          await this.$apiOffline({
            params: submitOrderList,
            offlineApiHandlers: handler,
            forceOffline: true
          })
        }
        if (result.code === ONLINE_REQUEST_SUCCESS) {
          await mobile.delImgsByOrderId({ orderId: this.orderDetail.id })
        }
        // 返回刷新主页
        this.$store.commit('changeDashBoardRefreshMixin')
        if (result.code === OFFLINE_REQUEST_SUCCESS) {
          setTimeout(() => {
            this.$router.go(-2)
          }, 500)
        } else {
          const _this = this
          this.$toast.success({
            message: reqSuccessMsg,
            duration: 1000,
            forbidClick: true,
            onClose: () => {
              if (_this.buttonInfo.buttonName === '非设备') {
                _this.$router.go(-2)
              } else {
                _this.$router.back()
              }
            }
          })
        }
      } catch (error) {
        console.log('error: ', error)
        this.submitLoading = false
      }
    },
    /* 流程取消 */
    flowCancel () {
      this.$router.back()
    },
    speakRes (text) {
      this.form.desc += text
    },
    timeoutReasonSelect (timeoutReasons) {
      this.form.timeoutReason = timeoutReasons.code
      this.timeoutReasonValue = timeoutReasons.name
      this.timeoutReasonShow = false
    },
    handleClickSwiper (index, attachmentList, type = 'before') {
      if (type === 'before') {
        if (index === this.beforeSwiperIndex) {
          this.imgPreview(index, attachmentList)
        } else {
          this.$refs.beforeSwiper.swiper.slideTo(index)
        }
      } else {
        if (index === this.afterSwiperIndex) {
          this.imgPreview(index, attachmentList)
        } else {
          this.$refs.afterSwiper.swiper.slideTo(index)
        }
      }
    },
    imgPreview (index, attachmentList) {
      ImagePreview({
        images: attachmentList.map(item => item.url),
        startPosition: index
      })
    },
    onSlideChange_before () {
      this.beforeSwiperIndex = this.$refs.beforeSwiper.swiper.activeIndex
    },
    onSlideChange_after () {
      this.afterSwiperIndex = this.$refs.afterSwiper.swiper.activeIndex
    }

  },
  components: {
    ScanIcon,
    BackToReportSysReasonSelect,
    MyFieldOfEquipmentSelect,
    MyFieldOfMalfunctionReasonSelect,
    SelectHandler,
    SelectReason,
    UsualSelect,
    ProblemItems
  }
}
</script>

<style lang="less" scoped>
@import url("./index.less");
</style>
