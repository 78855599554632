<template>
  <van-icon name="scan" @click="scan" />
</template>

<script>
import scanUtils from '@/utils/scanCode'
export default {
  name: 'ScanIcon',
  props: {
    codeType: {
      type: String,
      default: 'SB_code'
    }
  },
  methods: {
    scan () {
      scanUtils.jump((info) => {
        console.log('info: ', info)
        if (!info || info.length === 0) {
          return this.$toast('无匹配二维码')
        }
        this.$emit('scanSuccess', info)
      }, undefined, this.codeType)
    }
  }
}
</script>

<style scoped>

</style>
