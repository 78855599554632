<template>
    <van-popup v-model="_show" position="right" :overlay="false" get-container="#app"
      :style="{ width: '100%', height: '100%' }" :duration="animateDuration" @open="selectProjectOpenHandler" @close="selectProjectCloseHandler">
      <div class="select-project-wrapper">
        <my-nav :title="title" :self-back="true" @back="goBack">
        </my-nav>
        <van-search v-model="searchValue" placeholder="请输入关键字" @search="_getDataList" class="search-wrapper" />
        <div>
          <div class="router-wrapper">
            <div class="list-wrapper">
              <span class="address_title" @click="changeAddress('back')">当前位置：</span>
              <span class="router-list" v-for="(item, index) in currentPosition" :key="index">
                <p class="name" :class="{ last: index === currentPosition.length - 1 }"
                  @click="changeAddress(index)">
                  {{ item.typeName }}
                </p>
                <i class="router-icon" v-if="index < currentPosition.length - 1"></i>
              </span>
            </div>
          </div>
          <div class="project-list-wrapper" v-if="!searchList.length">
            <div class="project-list" v-for="item in list" :key="item.organCode"
              @click="changeListCheck(item)">
              <div style="width:80%">
                <div v-if="item.isSel === '1'" class="mr-10px mt-5px float-left" @click="changeListCheck(item,'choose')">
                  <van-radio name="1"></van-radio>
                </div>
                <p class="name">{{ item.typeName }}</p>
              </div>
              <i class="right-icon" v-if="item.children.length !== 0"></i>
              <!-- <i class="check-icon" v-if="showCheck(item)"></i> -->
            </div>
          </div>
          <div class="project-list-wrapper" v-else>
            <div class="project-list" v-for="(item, index) in searchList" :key="index"
              @click="changeListCheck(item)">
              <p class="name">{{ item.typeName }}</p>
              <!-- <i class="check-icon" v-if="showCheck(item)"></i> -->
            </div>
          </div>
        </div>
        <!-- <div class="submit-wrapper" @click="submit">
          <p class="text">确定</p>
        </div> -->
      </div>
    </van-popup>
</template>

<script type='text/ecmascript-6'>
import { getTWIncidentBigCategory, getTWIncidentSmallCategory } from '@/api/report'
export default {
  name: '',
  components: {},
  props: {
    title: {
      type: String,
      default: ''
    },
    projectCode: {
      type: String,
      required: true,
      default: ''
    },
    show: {
      type: Boolean,
      default: false
    },
    duty: {
      type: String,
      default: ''
    },
    typeCode: { // 选择的原因大类
      type: String,
      default: undefined
    },
    isBigType: {
      type: Boolean,
      default: true
    },
    incidentPlace: { // 选择的公区或户内
      type: String,
      required: true,
      default: ''
    },
    drClass: { // 报事类型
      type: [Number, String],
      required: true,
      default: null
    }
  },
  data () {
    return {
      searchValue: '',
      searchList: [], // 搜索后过滤的list
      checkList: [], // 选择的list
      currentPosition: [], // 选择tree 路由
      list: [], // 列表
      ctList: [], // copy一份项目列表
      animateDuration: 0.3 // 默认弹窗动画时间0.3s
    }
  },
  computed: {
    isOffline () {
      return this.$store.getters.getIsOffline
    },
    _show: {
      get () {
        return this.show
      },
      set (val) {
        this.$emit('update:show', val)
      }
    }
  },
  watch: {
    _show (val) {
      val && this._getDataList()
    }
  },
  methods: {
    // popup打开
    selectProjectOpenHandler () {
      window.history.pushState(null, null, '#') // 模拟新的一页history记录
      window.addEventListener('popstate', this.popstateHandler)
    },
    popstateHandler (e) {
      this.animateDuration = 0 // 防止侧滑出现多次动画
      this._show = false
    },
    // popup关闭
    selectProjectCloseHandler () {
      window.removeEventListener('popstate', this.popstateHandler, false)
    },
    _getDataList (val) { // 获取数据
      this.list = []
      this.currentPosition = []
      this.searchList = []
      if (!val) {
        this.isBigType ? this.getBigClassData() : this.getsmallClassData()
        return
      } else {
        this.isBigType
          ? this.getBigClassData()
          : this.filterTree(this.ctList, val)
      }
      console.log('val: ', val)
      console.log('~~~~this.searchList~~~: ', this.searchList)
      this.searchList.forEach((item) => {
        item.typeName = item.fullName
      })
    },
    filterTree (list, val) { // 搜索过滤
      list.forEach((item) => {
        if (item.typeName.includes(val)) {
          this.searchList.push(item)
        } else {
          if (item.children?.length) {
            this.filterTree(item.children, val)
          }
        }
      })
    },
    async getBigClassData () { // 报事类别（原因大类）
      const obj = {
        drClass: this.drClass, // 报事类型
        incidentPlace: this.incidentPlace, // 报事位置
        projectCode: this.projectCode //
      }
      const res = await getTWIncidentBigCategory(obj)
      this.list = res
      this.ctList = JSON.parse(JSON.stringify(this.list))
    },
    async getsmallClassData () { // 获取报事细类数据
      const obj = {
        drClass: this.drClass, // 报事类型
        duty: this.duty, // 原因大类Duty
        incidentPlace: this.incidentPlace, // 报事位置
        projectCode: this.projectCode, // 报事位置
        typeCode: this.typeCode // 原因大类TypeCode
      }
      const res = await getTWIncidentSmallCategory(obj)
      this.list = res
      this.ctList = JSON.parse(JSON.stringify(this.list))
    },
    goBack () {
      window.history.back()
      this._show = false
    },
    // 点击列表
    changeListCheck (item, name) {
      this.currentPosition.push({
        typeID: item.typeID,
        typeName: item.typeName
      })
      if (item.children?.length && name !== 'choose') {
        this.list = item.children
      } else {
        const list = this.currentPosition.map((item) => item.typeName)
        const name = list.join('-')
        this.$emit('result', item, name)
        this.goBack()
      }
    },
    changeAddress (num) { // 点击当前位置
      console.log('num: ', num)
      if (num === 'back') {
        this.isBigType ? this.getBigClassData() : this.getsmallClassData()
        this.currentPosition = []
        return
      }
      this.getListTree(this.ctList, this.currentPosition[num].typeID)
      this.currentPosition.splice(num + 1)
    },
    getListTree (data, typeID) {
      return data.filter((i) => {
        if (typeID !== i.typeID && i.children?.length) {
          this.getListTree(i.children, typeID)
        }
        if (typeID === i.typeID) {
          this.list = i.children
        }
      })
    }
    /**
     * 因为存在有保存的默认项目，初始化时会执行这一步
     * routerBack 是否返回上一页
     */
    // async submit (routerBack = true) {
    //   if (this.checkList.length === 0) {
    //     this.$toast(`请选择报事`)
    //     return
    //   }
    //   const obj = {
    //     searchValue: this.searchValue,
    //     checkCode: this.checkCode,
    //     checkList: this.lodash.cloneDeep(this.checkList), // 避免变量污染
    //     routerList: this.routerList,
    //     checkPositionList: this.lodash.cloneDeep(this.checkPositionList),
    //     reportRouterList: this.reportRouterList,
    //     buildSNum: this.buildSNum,
    //     buildName: this.buildName,
    //     unitSNum: this.buildSNum,
    //     floorSNum: this.floorSNum,
    //     floorName: this.floorName
    //   }
    //   console.log('submit~~~~', obj)
    //   this.$emit('result', obj)
    //   routerBack && this.goBack()
    // }
  }
}
</script>
<style lang='less' scoped>
.select-project-wrapper {
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  p {
    margin: 0;
  }
  .icon-back {
    height: 24px;
    width: 24px;
    background: url("~@/asset/icon_back_black@2x.png") no-repeat;
    background-size: cover;
  }
  .multi-wrapper {
    display: flex;
    .multi-icon {
      width: 24px;
      height: 24px;
    }
    .multi-default {
      background: url("~@/asset/multi-default.png") no-repeat;
      background-size: 100% 100%;
    }
    .multi-check {
      background: url("~@/asset/multi-check.png") no-repeat;
      background-size: 100% 100%;
    }
    .multi-all {
      background: url("~@/asset/multi-all.png") no-repeat;
      background-size: 100% 100%;
    }
    .title {
      font-size: 16px;
      font-weight: 400;
      color: #222222;
      line-height: 22px;
    }
  }
  .search-wrapper {
    margin-top: 8px;
    padding: 6px 16px;
    /deep/ .van-search__content {
      background-color: #f5f6f6;
    }
  }
  .project-list-wrapper {
    max-height: calc(100vh - 44px - 100px);
    overflow-y: scroll;
    padding-bottom: 15px;
    .project-list {
      height: 48px;
      display: flex;
      padding: 0 16px;
      align-items: center;
      justify-content: space-between;
      .name {
        font-size: 16px;
        font-weight: 400;
        color: #222222;
        line-height: 24px;
      }
      .right-icon {
        width: 16px;
        height: 16px;
        background: url("~@/asset/right-icon.png") no-repeat;
        background-size: 100% 100%;
      }
      .check-icon {
        width: 24px;
        height: 24px;
        background: url("~@/asset/icon_right_blue@2x.png") no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .Position-list-wrapper {
    max-height: calc(100vh - 88px - 100px);
    overflow-y: scroll;
    padding-bottom: 15px;
    .project-list {
      height: 48px;
      display: flex;
      padding: 0 16px;
      align-items: center;
      justify-content: space-between;
      .name {
        font-size: 16px;
        font-weight: 400;
        color: #222222;
        line-height: 24px;
      }
      .right-icon {
        width: 16px;
        height: 16px;
        background: url("~@/asset/right-icon.png") no-repeat;
        background-size: 100% 100%;
      }
      .check-icon {
        width: 24px;
        height: 24px;
        background: url("~@/asset/icon_right_blue@2x.png") no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .router-wrapper {
    margin: 10px 0px;
    background-color: #fff;
    // padding-bottom: constant(safe-area-inset-bottom);
    // padding-bottom: env(safe-area-inset-bottom);
    .list-wrapper {
      height: 22px;
      line-height: 22px;
      display: flex;
      padding: 0 16px;
      width: 100%;
      font-size: 16px;
      overflow-x: auto;
    }
    .address_title {
        font-size: 16px;
        line-height: normal;
      }
    .router-list {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      .name {
        font-size: 16px;
        font-weight: 400;
        color: #3366fe;
        // line-height: 16px;
        &.last {
          color: #8b8f9a;
        }
      }
      .router-icon {
        width: 16px;
        height: 16px;
        margin: 0 6px;
        background: url("~@/asset/right-icon.png") no-repeat;
        background-size: 100% 100%;
      }
      .first-icon {
        margin-right: 8px;
        width: 16px;
        height: 16px;
        background: url("~@/asset/exchange.svg") no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .submit-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 64px;
    height: 64px;
    background: linear-gradient(90deg, #62a9ff 0%, #3370fe 100%);
    box-shadow: 0px 2px 6px 0px rgba(51, 102, 254, 0.4);
    border-radius: 50%;
    text-align: center;
    overflow: hidden;
    position: fixed;
    right: 16px;
    bottom: 76px;
    .count {
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      line-height: 1;
    }
    .text {
      font-size: 16px;
      font-weight: 400;
      color: #ffffff;
      line-height: 1;
      margin-top: 5px;
    }
  }
}
::v-deep .van-tabs {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  .van-tab__text--ellipsis {
    font-size: 16px;
  }
  & > div:first-of-type {
    flex-shrink: 0;
  }
  .van-tabs__content {
    flex: 1;
    overflow: hidden;
    font-size: 16px;
    .van-tabs__track {
      max-height: 100%;
      height: 100%;
      .van-tab__pane-wrapper {
        max-height: 100%;
        height: 100%;
        .van-tab__pane {
          max-height: 100%;
          height: 100%;
          overflow-y: auto !important;
        }
      }
    }
  }
}
::v-deep .van-radio__icon .van-icon {
  width: 15px;
  height: 15px;
  border: 1px solid #3366FE;
}
</style>
