var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ReportTousuAdd" },
    [
      _c("my-nav", { attrs: { title: "新增投诉还原" } }),
      _c(
        "div",
        { staticClass: "ReportTousuAdd-content" },
        [
          _c("van-field-picker", {
            attrs: {
              label: "投诉性质",
              name: "complaintType",
              required: "",
              columns: _vm.complaintTypeColumns,
              "real-value": _vm.formData.complaintType,
            },
            on: {
              "update:realValue": function ($event) {
                return _vm.$set(_vm.formData, "complaintType", $event)
              },
              "update:real-value": function ($event) {
                return _vm.$set(_vm.formData, "complaintType", $event)
              },
            },
            model: {
              value: _vm.formData.complaintType,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "complaintType", $$v)
              },
              expression: "formData.complaintType",
            },
          }),
          _c("van-field-picker", {
            attrs: {
              label: "投诉等级",
              name: "touSuDegree",
              required: "",
              columns: _vm.touSuDegreeColumns,
              "real-value": _vm.formData.touSuDegree,
            },
            on: {
              "update:realValue": function ($event) {
                return _vm.$set(_vm.formData, "touSuDegree", $event)
              },
              "update:real-value": function ($event) {
                return _vm.$set(_vm.formData, "touSuDegree", $event)
              },
            },
            model: {
              value: _vm.formData.touSuDegree,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "touSuDegree", $$v)
              },
              expression: "formData.touSuDegree",
            },
          }),
          _c("van-field", {
            staticClass: "form-textarea tousuTextArea",
            attrs: {
              label: "投诉还原内容",
              name: "touSuReasons",
              required: "",
              "show-word-limit": "",
              type: "textarea",
              rows: "4",
              placeholder: "请输入投诉内容",
              "auto-size": "",
            },
            model: {
              value: _vm.formData.touSuReasons,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "touSuReasons", $$v)
              },
              expression: "formData.touSuReasons",
            },
          }),
          _c(
            "div",
            { staticClass: "px-10px bg-white" },
            [
              _c("common-upload", {
                attrs: {
                  file: _vm.formData.attachments,
                  "enable-watermark": false,
                  "enable-album": true,
                  "enable-edit": true,
                  required: false,
                  "max-count": 6,
                },
                on: {
                  "update:file": function ($event) {
                    return _vm.$set(_vm.formData, "attachments", $event)
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "ReportTousuAdd-footer" }, [
        _c("div", { staticClass: "cancel btn", on: { click: _vm.cancel } }, [
          _vm._v("取消"),
        ]),
        _c("div", { staticClass: "save btn", on: { click: _vm.save } }, [
          _vm._v("保存"),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }